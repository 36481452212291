export const invoicePaymentStatus = {
  PAID: 'Paid',
  UNPAID: 'Unpaid',
};
export enum InvoiceStatusesEnum {
  PAID = 'paid',
  OPEN = 'open',
}
export const invoiceStatusTypes = [
  {
    name: 'Unpaid',
    value: InvoiceStatusesEnum.OPEN,
  },
  {
    name: 'Paid',
    value: InvoiceStatusesEnum.PAID,
  },
];
export const invoiceProductType = {
  COURSE_PURCHASE: 'course_purchase',
  SEATS_PURCHASE: 'seats_purchase',
};

export const invoiceCoursePurchaseType = {
  ADMIN_ENROLLMENT: 'admin_enrollment',
  SELF_ENROLLMENT: 'self_enrollment',
  OPEN_ENROLLMENT: 'OPEN_ENROLMENT',
};

export const invoiceSeatPurchaseTypes = {
  SEAT_REQUEST: 'SEAT_REQUEST',
  ADMIN_SEAT_PURCHASE: 'ADMIN_SEAT_PURCHASE',
};

export enum Tabs {
  Invoices = 'Invoices',
  Receipts = 'Receipts',
}

export enum CartItemTypes {
  COURSE = 'COURSE',
  DONATION = 'DONATION',
}
