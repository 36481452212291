/**
 * @description
 * Formats a school name for URL display
 * @param {String} name name to be formatted
 */
const formattedSchoolName = (name: string | null | undefined) => {
  let schoolName = 'N/A';

  if (name) {
    schoolName = name.replace(/\s/g, '-').replace(/[()]/g, '');
  }

  return schoolName;
};

export default formattedSchoolName;
