// @ts-nocheck
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';

export const LoadingBarsContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  text-align: center;
`;
export const LoadingBarsWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-top: 3.2rem;
  width: 100%;
`;
