// @ts-nocheck
import * as React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Link, withRouter } from 'react-router-dom';
import NoodleButton from 'components/uielements/Button';
import authActions from 'ducks/auth/actions';
import SearchBarTopNav from 'components/SearchBarTopNav';
import HamburgerMenu from 'components/HamburgerMenu';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';
import globalSearchActions from 'ducks/globalSearch/actions';
import { getAppSettings } from 'utils/privateLabel/index';
import { baseUrl } from 'settings/index';
import Navbar from '..';
import type { NavbarGuestProps, NavbarGuestState } from './NavbarGuest.types';
import NavbarSignedInWrapper from '../NavbarSignedIn/NavbarSignedIn.styles';

class NavbarGuest extends React.Component<NavbarGuestProps, NavbarGuestState> {
  constructor(props: any) {
    super(props);
    const {
      match: {
        params: { term = '' },
      },
    } = this.props;
    const hasTerm = term !== '';
    this.state = {
      toggled: hasTerm,
      search: term,
      innerWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onCloseSearchBar = (): void => {
    this.setState({
      toggled: false,
    });
  };

  onCleanSearch = (): void => {
    this.setState({
      search: '',
    });
  };

  onInputChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = e;
    this.setState({
      search: value,
    });
  };

  onSearch = (): void => {
    const { search } = this.state;
    const { history } = this.props;

    if (search.length) {
      history.push(`/search/${search}`);
    }
  };

  onEnterSearch = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  };

  onToggleSearchBar = (): void => {
    const { onClickSearchIcon } = this.props;
    const { toggled } = this.state;

    if (!toggled) {
      onClickSearchIcon();
    }

    this.setState({
      toggled: !toggled,
    });
  };

  handleResize = (): void => {
    const { innerWidth } = window;
    this.setState({
      innerWidth,
    });
  };

  renderButtons = (): React.ReactElement<any> => {
    const { onClickLoginButton, onClickSignUpButton } = this.props;
    const { innerWidth } = this.state;
    const size = innerWidth < 1000 ? 'small' : 'standard';
    const roundBtnSize = innerWidth < 1000 ? 'small' : 'medium';
    return (
      <>
        <Link to='/login' onClick={() => onClickLoginButton()}>
          <NoodleButton className='btn-transparent-secondary' size={size}>
            Log In
          </NoodleButton>
        </Link>
        <Link to='/partner-programs' onClick={() => onClickSignUpButton()}>
          <NoodleButton className='btn-round-noodle' size={roundBtnSize}>
            <span className='semi-bold'>Start my search</span>
          </NoodleButton>
        </Link>
      </>
    );
  };

  renderNavActions = (): React.ReactElement<any> => {
    const { search } = this.state;
    return (
      <>
        <div className='navbar__desktop'>
          <SearchBarTopNav
            onInputChange={this.onInputChange}
            onSearch={this.onSearch}
            onCloseSearchBar={this.onCloseSearchBar}
            onCleanSearch={this.onCleanSearch}
            search={search}
            toggled
            placeholder='Search'
          />
        </div>
        <div className='navbar__desktop'>
          <div className='navbar__container--guest'>
            {/* {this.renderButtons()} */}
            {this.renderHamburgerMenu()}
          </div>
        </div>
      </>
    );
  };

  renderHamburgerMenu = (): React.ReactElement<any> | null => {
    const { search, innerWidth } = this.state;
    return (
      <div className='navbar__menu__container'>
        {innerWidth < TABLET_SMALLER_WIDTH && (
          <div className='navbar__search-mobile-link'>
            <Link title='Global Search' aria-label='nav-global-search' to='/search/'>
              <i
                className='icon-search sb__icon sb__search animated fadeIn'
                role='link'
                tabIndex='0'
              />
            </Link>
          </div>
        )}
        <HamburgerMenu
          onInputChange={this.onInputChange}
          onCleanSearch={this.onCleanSearch}
          onSearch={this.onEnterSearch}
          search={search}
        />
      </div>
    );
  };

  handleOnClickHomeIcon = (): void => {
    const { onClickHomeIcon } = this.props;
    return onClickHomeIcon();
  };

  render() {
    const {
      match: { path },
    } = this.props;
    const { innerWidth } = this.state;
    const { globalPartner } = this.state;
    const { companyLogo, companyName } = getAppSettings() || globalPartner.data;
    const imgAltText = `${companyName} Logo`;
    const noNavUrls = ['search'];
    const isNoNavUrl = noNavUrls.some((el) => path.includes(el));
    return isNoNavUrl && innerWidth < TABLET_SMALLER_WIDTH ? null : (
      <Navbar>
        <NavbarSignedInWrapper>
          <div className='navbar__container'>
            <div>
              <a href={`${baseUrl}/`} onClick={this.handleOnClickHomeIcon}>
                <img
                  src={companyLogo}
                  className='navbar__logo'
                  alt={imgAltText}
                  aria-label={imgAltText}
                  title={imgAltText}
                  loading='lazy'
                  width='100%'
                  height='100%'
                />
              </a>
            </div>
            {this.renderNavActions()}
          </div>
        </NavbarSignedInWrapper>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  globalPartner: get(state, 'parterInstance.globalPartner', null),
});

const mapDispatchToProps = {
  onClickHomeIcon: () => authActions.clickOnHomeIcon(),
  onClickSearchIcon: () => authActions.clickOnSearchIcon(),
  onClickLoginButton: () => authActions.clickOnLoginButton(),
  onClickSignUpButton: () => authActions.clickOnSignUpButton(),
  onGetGlobalSearchResults: (payload) => globalSearchActions.getGlobalSearchRequest(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavbarGuest));
