import * as React from 'react';
import {
  StyledInput,
  StyledSearchIcon,
  StyledClearIcon,
  StyledButton,
  InputContainer,
} from './SearchInput.styles';

type SearchInputRef = {
  clearInput: () => void;
};

type SearchInputProps = {
  allowEmptySearch?: boolean;
  autoFocus?: boolean;
  className?: string;
  initValue?: string;
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  onClear?: (value: string) => void;
  placeholder: string;
  setParentSearchValue?: (value: string) => void;
  small?: boolean;
};

const SearchInput = React.forwardRef<SearchInputRef, SearchInputProps>(
  (
    {
      autoFocus,
      allowEmptySearch,
      className,
      initValue,
      onChange,
      onSearch,
      onClear,
      placeholder,
      setParentSearchValue,
      small,
    },
    ref,
  ) => {
    const [value, setValue] = React.useState<string>(initValue || '');
    const [isSearched, setIsSearched] = React.useState<boolean>(false);

    const hasText = (): boolean => Boolean(value && value.trim().length > 0);
    const displayClearIcon = hasText && isSearched && !!onClear;

    const handleChange = (event): void => {
      const { value: searchValue } = event.target;

      setValue(searchValue);
      if (onChange) {
        onChange(searchValue);
        setIsSearched(true);
      }

      if (setParentSearchValue) setParentSearchValue(searchValue);

      if (!searchValue && !!onClear) {
        onClear('');
        setIsSearched(false);
      }
    };

    const handleEnter = (event): void => {
      if (event.key === 'Enter' && (allowEmptySearch || hasText())) {
        onSearch(value);
        setIsSearched(true);
      }
    };

    const handleSearchClick = (): void => {
      if (allowEmptySearch || hasText()) {
        onSearch(value);
        setIsSearched(true);
      }
    };

    const handleClearClick = (): void => {
      setValue('');
      setIsSearched(false);
      if (onClear) onClear('');
      if (setParentSearchValue) setParentSearchValue('');
    };

    React.useImperativeHandle(ref, () => ({
      clearInput: handleClearClick,
    }));

    const buttonProps = displayClearIcon
      ? {
          'aria-label': 'Clear',
          title: 'Clear',
          onClick: handleClearClick,
          icon: <StyledClearIcon />,
        }
      : {
          'aria-label': 'Search',
          title: 'Search',
          onClick: handleSearchClick,
          icon: <StyledSearchIcon />,
        };

    return (
      <InputContainer className={className} small={small}>
        <StyledInput
          autoFocus={autoFocus}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        <StyledButton
          aria-label={buttonProps['aria-label']}
          title={buttonProps.title}
          onClick={buttonProps.onClick}
          onKeyPress={handleEnter}
        >
          {buttonProps.icon}
        </StyledButton>
      </InputContainer>
    );
  },
);

export default SearchInput;
