// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';

const LeftNavWrapper: StatelessFunctionalComponent<any> = styled.nav`
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;

  .lnav {
    &__icon {
      & > span > svg {
        height: 32px;
        width: 32px;
      }
    }

    &-link {
      width: 7.2rem;
      height: 4.8rem;
      display: block;
      margin-bottom: ${(props) => props.theme.noodera.spacing.x5};
      color: ${(props) => props.theme.palette.neutral.eighty};

      &:hover,
      &:focus {
        color: ${(props) => props.theme.palette.primary.hundred};

        svg path {
          fill: ${(props) => props.theme.palette.primary.hundred};
        }
      }

      &:focus {
        outline: unset;
        background-color: ${(props) => props.theme.palette.primary.ten};
      }
    }

    &__breadcrumb {
      margin-left: 5.03rem;
      border-left: 0.1rem solid ${(props) => props.theme.palette.greyscale.cloud};
      padding-left: 1rem;
      display: -webkit-box;
      max-width: 100%;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 0.32px;
      line-height: 24px;
    }

    &__search-title {
      width: 25rem;
      height: 5.8rem;
    }
  }
`;
export default LeftNavWrapper;
