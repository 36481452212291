import moment from 'moment';
import { DATE_FORMAT } from 'commons/constants';
import type { IsDateBetweenRangeParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a date is between others two using momentjs
 * Pre: fromValue < toValue; value, fromValue, toValue are valid dates
 */
const isDateBetweenRange = ({
  name,
  value,
  fromValue,
  toValue,
  outputName,
}: IsDateBetweenRangeParams): ValidationFieldError => {
  const errors = {};
  const date = moment(value, DATE_FORMAT);
  const fromDate = moment(fromValue, DATE_FORMAT);
  const toDate = moment(toValue, DATE_FORMAT);
  const condition = value && !date.isBetween(fromDate, toDate, 'days', '[]');
  errors[name] = condition
    ? {
        errorMsg: `${outputName} must be between ${fromValue} and ${toValue}`,
      }
    : {};
  return errors;
};

export default isDateBetweenRange;
