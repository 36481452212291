// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
// noinspection ES6PreferShortImport
import { getTheme } from 'settings/theme/index';
import ImageWithFallback from 'components/ImageWithFallback/index';

const {
  noodera: { spacing, fontTypes },
  palette: { primary, neutral },
} = getTheme();
export const Container: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > img {
    outline: 2px solid ${neutral.sixty};
  }

  &:hover {
    background: ${({ disabled }) => (disabled ? 'transparent' : neutral.ten)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    & > img {
      outline-color: ${primary.hundred};
    }
  }

  &:focus {
    background: ${({ disabled }) => (disabled ? 'transparent' : primary.ten)};

    & > img {
      outline-color: ${neutral.sixty};
    }
  }

  &:active {
    background: ${({ disabled }) => (disabled ? 'transparent' : neutral.twenty)};

    & > img {
      outline-color: ${neutral.sixty};
    }
  }
`;
export const Label: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4Medium};
  color: ${neutral.hundred};
  margin: 0;
`;
export const IconHolder: StatelessFunctionalComponent<any> = styled.span`
  padding: ${spacing.x8} ${spacing.x7};
  background-color: ${neutral.ten};
  border-radius: ${spacing.x6};
  margin-left: ${spacing.x8};
`;
export const Subtitle: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font5};
  color: ${neutral.hundred};
  margin: 0;
`;
export const Image: StatelessFunctionalComponent<any> = styled(ImageWithFallback)`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: ${spacing.x7};
`;
export const StyledActionWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-left: auto;
  > div > div {
    &:hover {
      background: transparent;
      color: ${neutral.eighty};
    }
  }
  .tag__text {
    color: ${({ disabled }) => (disabled ? neutral.forty : neutral.eighty)};
    > span {
      margin-left: 8px;
    }
  }
  svg {
    width: ${spacing.x5};
    height: ${spacing.x5};
    path {
      fill: ${({ disabled }) => (disabled ? neutral.forty : neutral.eighty)};
    }
  }
`;
