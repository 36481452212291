import get from 'lodash.get';
import moment from 'moment';
import JsonApi from 'utils/jsonApi';
import { urlComposer } from 'utils/string';
import { adminConstants } from 'commons/constants';
import type { GetUsersBodyType, RoleTypeListObject } from 'commons/types';

export default class AdminUser {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getAllUsers({ page, email }: GetUsersBodyType): Promise<any> {
    const nameFilter = email
      ? `{"name":"name","op":"ilike","val":"%25${encodeURIComponent(email)}%25"}`
      : '';
    const emailFilter = email
      ? `{"name":"email","op":"ilike","val":"%25${encodeURIComponent(email)}%25"}`
      : '';
    const filter = (nameFilter || emailFilter) && `[{"or":[${nameFilter},${emailFilter}]}]`;
    const composerOptions = {
      filter,
      includes: 'roles',
      sorting: adminConstants.DEFAULT_SORTING,
      page,
    };
    const urlString = urlComposer('/users', composerOptions);
    const response = await this.jsonApi.get(urlString);
    const users = get(response, 'entities.user', {});
    const count = get(response, 'entities.count', {});
    const roles = get(response, 'entities.role', {});
    return {
      users,
      count,
      roles,
    };
  }

  async getUserById(id: string): Promise<any> {
    const includes: string[] = ['roles'];
    const response = await this.jsonApi.get(`/users/${id}?include=${includes.toString()}`);
    const user = get(response, `entities.user.${id}`, {});
    user.roles = get(response, 'entities.role', {});
    user.createdAt = moment(user.createdAt).format('MM/DD/YYYY');
    return user;
  }

  async updateUser(user: any): Promise<any> {
    const userData = await this.jsonApi.post('/users', {
      user,
    });
    return get(userData, `entities.user.${user.id}`);
  }

  async updateRoles(user: any, roles: RoleTypeListObject): Promise<any> {
    const userData = await this.jsonApi.post(
      '/users',
      {
        user,
      },
      true,
      roles,
      'role',
    );
    return get(userData, `entities.user.${user.id}`);
  }

  async getUserRoles(): Promise<RoleTypeListObject> {
    const response = await this.jsonApi.get('/roles');
    const roles = get(response, 'entities.role', {});
    return roles;
  }
}
