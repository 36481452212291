import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type { PostType, GroupPostPayloadType } from 'commons/types';

export default class Post {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  static parsePostData(
    postTypeObj: Record<string, any>,
    postData: Record<string, any>,
  ): PostType[] {
    const [postTypeId] = Object.keys(postTypeObj);
    const postTypeItem = postTypeObj[postTypeId];
    postTypeItem.post = get(postData, `entities.post.${postTypeItem.postId}`, {});
    return [postTypeItem];
  }

  async createGroupPost(postGroup: GroupPostPayloadType): Promise<PostType[]> {
    const postData = await this.jsonApi.post('/posts/groups?include=post', {
      postGroup,
    });
    const postGroupObj = get(postData, 'entities.postGroup', {});
    return Post.parsePostData(postGroupObj, postData);
  }

  async getGroupPosts(groupId: number, lastUpdate?: string): Promise<PostType[]> {
    const baseUrl = '/posts/groups?include=post&sort=-updated_at&filter=[';
    const baseFilter = `{"name": "group_id","op": "eq","val": "${groupId}"}`;
    const url = lastUpdate
      ? `${baseUrl}{"and":[${baseFilter},{"name": "updated_at","op": "gt","val": "${lastUpdate}"}]}]`
      : `${baseUrl}${baseFilter}]`;
    const postData = await this.jsonApi.get(url);
    const postTypeIds = Object.keys(get(postData, 'entities.postGroup', {}));
    const postContentObj = get(postData, 'entities.post', {});
    return postTypeIds.map((postId) => {
      const currentPost = get(postData, `entities.postGroup.${postId}`, {});
      return Object.assign(currentPost, {
        post: postContentObj[currentPost.postId],
      });
    });
  }
}
