// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { Wrapper } from 'components/TeamSidebar/TeamSidebar.styles';
import { TABLET_SMALL_WIDTH } from 'commons/constants';
import { getTheme } from 'settings/theme';

const {
  noodera: { fontTypes, spacing },
} = getTheme();
export const StreamFeed: StatelessFunctionalComponent<any> = styled.div`
  > div:first-child {
    margin-bottom: ${spacing.x5};
  }
  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-bottom: ${spacing.x1};
    > div {
      border-radius: 0;
    }
  }
`;
export const SectionFeed: StatelessFunctionalComponent<any> = styled.div`
  margin-top: 0;
  h1 {
    ${fontTypes.font4Medium};
    margin: 0 0 ${spacing.x5};
  }
  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-bottom: ${spacing.x3};
    h1 {
      margin-top: ${spacing.x7};
    }
    > div {
      margin-left: ${spacing.x8};
    }
  }
`;
export const SidebarWrapper: StatelessFunctionalComponent<any> = styled(Wrapper)`
  > div {
    padding: ${spacing.x4};
    > div:first-child {
      margin-bottom: ${spacing.x4};
    }
  }
`;
