// @ts-nocheck
import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import NooderaButton from 'components/NooderaButton';
import type { AppBarProps } from './AppBar.types';
import { StyledAppBar, StyledTitle } from './AppBar.styles';

const AppBar = ({ title, onClose }: AppBarProps): React.ReactElement<any> => (
  <StyledAppBar>
    <Toolbar>
      <StyledTitle variant='h6'>{title}</StyledTitle>
      <NooderaButton.RoundCTA size='small' onClick={onClose} darkBackground aria-label='Close'>
        <CloseIcon />
      </NooderaButton.RoundCTA>
    </Toolbar>
  </StyledAppBar>
);

export default AppBar;
