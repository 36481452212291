// @ts-nocheck
import * as React from 'react';
import { StyledLink, TabsContainer } from './Tabs.styles';
import type { TabsProps } from './Tabs.types';

const Tab = ({ linkTo, children, isActive, theme, id }: TabsProps): React.ReactElement<any> => {
  const tabProps = {
    theme,
    isActive,
    role: 'tab',
    'aria-selected': isActive,
    tabIndex: isActive ? 0 : -1,
    id: id || `${linkTo}-tab`,
    'aria-controls': id ? `${id}-panel` : `${linkTo}-panel`, // Use provided ID or generate one based on linkTo
  };

  return (
    <StyledLink to={linkTo} {...tabProps}>
      {children}
    </StyledLink>
  );
};

const Tabs = ({
  theme = 'light',
  children,
  setActiveTab = () => {},
}: TabsProps): React.ReactElement<any> => {
  const renderChildren = () =>
    children.map((child, idx) => {
      const tabProps = {
        theme,
        isActive: setActiveTab(child),
        role: 'tablist',
        key: idx,
      };

      if (idx === children.length - 1) {
        return React.cloneElement(child, {
          ...tabProps,
        });
      }

      return React.cloneElement(child, {
        ...tabProps,
      });
    });

  return <TabsContainer theme={theme}>{renderChildren()}</TabsContainer>;
};

Tabs.Tab = Tab;
export default Tabs;
