import type {
  PartnerProgramGroupObject,
  PartnerProgramObject,
  PartnerProgramRequestInfoPayload,
  RequestedProgramsObject,
  SorterOption,
  GetPartnerProgramsRequest,
} from 'commons/types';
import type { Course } from 'commons/types/courseTypes';
import {
  getProgramsTypes,
  getProgramGroupsTypes,
  requestInfoTypes,
  selectProgramTypes,
  updateSorterOptionsTypes,
  programTypes,
  getPartnerProgramsTypes,
  getSavedProgramsTypes,
} from './types';

export const partnerProgramsActions = {
  getProgramsRequest: (payload: string) => ({
    type: getProgramsTypes.REQUEST,
    payload,
  }),
  getProgramsSuccess: (payload: PartnerProgramObject[]) => ({
    type: getProgramsTypes.SUCCESS,
    payload,
  }),
  getProgramsFailed: (payload: string) => ({
    type: getProgramsTypes.FAILED,
    payload,
  }),
  getPartnerProgramsRequest: (payload?: GetPartnerProgramsRequest) => ({
    type: getPartnerProgramsTypes.REQUEST,
    payload: payload || {},
  }),
  getPartnerProgramsSuccess: (payload: Record<string, any>[]) => ({
    type: getPartnerProgramsTypes.SUCCESS,
    payload,
  }),
  getPartnerProgramsFailure: (payload: string) => ({
    type: getPartnerProgramsTypes.FAILURE,
    payload,
  }),
  getProgramGroupsRequest: (payload: SorterOption[]) => ({
    type: getProgramGroupsTypes.REQUEST,
    payload,
  }),
  getProgramGroupsSuccess: (payload: PartnerProgramGroupObject[]) => ({
    type: getProgramGroupsTypes.SUCCESS,
    payload,
  }),
  getProgramGroupsFailed: (payload: string) => ({
    type: getProgramGroupsTypes.FAILED,
    payload,
  }),
  selectProgram: (payload: PartnerProgramObject) => ({
    type: selectProgramTypes.SELECT,
    payload,
  }),
  requestInfoRequest: (payload: PartnerProgramRequestInfoPayload) => ({
    type: requestInfoTypes.REQUEST,
    payload,
  }),
  requestInfoSuccess: (payload: RequestedProgramsObject) => ({
    type: requestInfoTypes.SUCCESS,
    payload,
  }),
  requestInfoFailed: (payload: string) => ({
    type: requestInfoTypes.FAILED,
    payload,
  }),
  resetProgramList: () => ({
    type: selectProgramTypes.RESET,
  }),
  toggleRequestInfoModal: () => ({
    type: requestInfoTypes.TOGGLE_MODAL,
  }),
  updateSorterOptions: (payload: SorterOption[]) => ({
    type: updateSorterOptionsTypes.UPDATE,
    payload,
  }),
  setPrograms: (payload: PartnerProgramObject[]) => ({
    type: programTypes.SET_PROGRAMS,
    payload,
  }),
  getSavedProgramsRequest: () => ({
    type: getSavedProgramsTypes.GET_SAVED_PROGRAMS_REQUEST,
  }),
  getSavedProgramsSuccess: (payload: Course[]) => ({
    type: getSavedProgramsTypes.GET_SAVED_PROGRAMS_SUCCESS,
    payload,
  }),
  getSavedProgramsFailure: (payload: string) => ({
    type: getSavedProgramsTypes.GET_SAVED_PROGRAMS_FAILURE,
    payload,
  }),
};
