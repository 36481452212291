import type { SchedulerObject } from 'commons/types';
import {
  getTimekitProjectTypes,
  getTimekitProjectListTypes,
  deleteTimekitProjectTypes,
  createTimekitProjectTypes,
  updateTimekitProjectTypes,
  adminSchedulerTypes,
} from './types';

export const adminSchedulerActions = {
  getTimekitProjectRequest: (payload: string) => ({
    type: getTimekitProjectTypes.REQUEST,
    payload,
  }),
  getTimekitProjectSuccess: (payload: SchedulerObject) => ({
    type: getTimekitProjectTypes.SUCCESS,
    payload,
  }),
  getTimekitProjectFailed: (payload: string) => ({
    type: getTimekitProjectTypes.FAILED,
    payload,
  }),
  deleteTimekitProjectRequest: (payload: string) => ({
    type: deleteTimekitProjectTypes.REQUEST,
    payload,
  }),
  deleteTimekitProjectSuccess: (payload: SchedulerObject) => ({
    type: deleteTimekitProjectTypes.SUCCESS,
    payload,
  }),
  deleteTimekitProjectFailed: (payload: string) => ({
    type: deleteTimekitProjectTypes.FAILED,
    payload,
  }),
  getTimekitProjectListRequest: () => ({
    type: getTimekitProjectListTypes.REQUEST,
  }),
  getTimekitProjectListSuccess: (payload: SchedulerObject) => ({
    type: getTimekitProjectListTypes.SUCCESS,
    payload,
  }),
  getTimekitProjectListFailed: (payload: string) => ({
    type: getTimekitProjectListTypes.FAILED,
    payload,
  }),
  createTimekitProjectRequest: (payload: any) => ({
    type: createTimekitProjectTypes.REQUEST,
    payload,
  }),
  createTimekitProjectSuccess: (payload: SchedulerObject) => ({
    type: createTimekitProjectTypes.SUCCESS,
    payload,
  }),
  createTimekitProjectFailed: (payload: string) => ({
    type: createTimekitProjectTypes.FAILED,
    payload,
  }),
  updateTimekitProjectRequest: (payload: any) => ({
    type: updateTimekitProjectTypes.REQUEST,
    payload,
  }),
  updateTimekitProjectSuccess: (payload: SchedulerObject) => ({
    type: updateTimekitProjectTypes.SUCCESS,
    payload,
  }),
  updateTimekitProjectFailed: (payload: string) => ({
    type: updateTimekitProjectTypes.FAILED,
    payload,
  }),
  changeAdminSchedulerEditMode: (payload: boolean) => ({
    type: adminSchedulerTypes.CHANGE_EDIT_MODE,
    payload,
  }),
  setupAdminSchedulerCreateMode: () => ({
    type: adminSchedulerTypes.SET_UP_CREATE,
  }),
};
