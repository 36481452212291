import * as Sentry from '@sentry/react';
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type { GlobalSearchPayload } from 'commons/types';

export default class GlobalSearch {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getAll({ term, page, elementsPerPage, contentType }: GlobalSearchPayload): Promise<any> {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set('term', term);

      if (contentType) {
        searchParams.set('index', String(contentType));
      }

      if (page && elementsPerPage) {
        searchParams.set('limit', String(elementsPerPage));
        searchParams.set('offset', String(page * elementsPerPage - elementsPerPage));
      }

      const response: any = await this.jsonApi.get(
        `/global_search/v3?${searchParams.toString()}`,
        false,
      );
      const counts = get(response, 'counts', {});
      const results = response?.results;
      // Remove counts for non existant search results
      Object.entries(counts).forEach(([k, v]) => !v && delete counts[k]);
      return {
        searchResults: results,
        counts,
      };
    } catch (err) {
      Sentry.captureException(err);
      throw new Error(err);
    }
  }
}
