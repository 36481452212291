// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { neutral, greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();
export const StyledInput: StatelessFunctionalComponent<any> = styled.input`
  ${fontTypes.font4};
  border: 0;
  width: 100%;
  margin-right: ${spacing.x7};

  ::placeholder {
    color: ${neutral.seventy};
  }
`;
export const StyledSearchIcon: StatelessFunctionalComponent<any> = styled(SearchIcon)`
  &&& {
    color: ${({ theme }) => theme.palette.neutral.eighty};
    width: 24px;
    height: 24px;
  }
`;
export const StyledClearIcon: StatelessFunctionalComponent<any> = styled(ClearIcon)`
  &&& {
    color: ${({ theme }) => theme.palette.neutral.eighty};
    width: 20px;
    height: 20px;
  }
`;
export const InputContainer: StatelessFunctionalComponent<{
  small?: boolean;
}> = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  background-color: ${greyscale.white};
  border-radius: 8px;
  border: 1px solid ${neutral.forty};
  padding: ${spacing.x7} ${spacing.x7} ${spacing.x7} ${spacing.x6};
  height: ${({ small }) => (small ? 32 : 48)}px;

  & > div {
    display: flex;
    flex-grow: 1;
  }
`;
export const StyledButton: StatelessFunctionalComponent<any> = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: auto;
`;
