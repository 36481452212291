export const getCookie = (name: string) => {
  // eslint-disable-next-line no-useless-escape
  const escape = (s) => s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');

  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
  return match ? match[1] : null;
};

// To make cookies work for dev env:
const getCookieDomain = () => {
  const { host, hostname } = window.location;
  if (host.includes('localhost:3000')) {
    return 'localhost';
  }
  if (host.includes('noodle.com')) {
    return '.noodle.com';
  }
  return hostname.substring(hostname.indexOf('.') + 1);
};

export function setCookie(name: string, value: string, expires: number | string) {
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}; max-age=${expires}; domain=${getCookieDomain()}; path=/;`;
}

export function deleteCookie(name: string) {
  document.cookie = `${encodeURIComponent(name)}=; max-age=0; domain=${getCookieDomain()}; path=/;`;
}

export const getPartnerCookieKey = (prefix: string, partnerId: string): string => {
  if (!prefix || !partnerId) {
    console.error('Prefix and partnerId are required for getPartnerCookieKey');
    return '';
  }

  return `${prefix}_${partnerId.toLowerCase()}`;
};

export const getPartnerCookie = (cookieName: string, partnerId: string): string | undefined => {
  if (!partnerId) {
    return undefined;
  }

  const partnerCookieName = getPartnerCookieKey(cookieName, partnerId);
  return getCookie(partnerCookieName);
};

export const setPartnerCookie = (
  partnerId: string,
  cookieName: string,
  value: string,
  expiresIn: number,
): void => {
  const partnerCookieName = getPartnerCookieKey(cookieName, partnerId);
  setCookie(partnerCookieName, value, expiresIn);
};

export const deletePartnerCookie = (partnerId: string, cookieName: string): void => {
  const partnerCookieName = getPartnerCookieKey(cookieName, partnerId);
  deleteCookie(partnerCookieName);
};
