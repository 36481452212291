import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import { urlComposer } from 'utils/string';
import { groupsTabs } from 'commons/constants';
import type { GroupObject, GetGroupsSuccessPayload, GetGroupsRequestPayload } from 'commons/types';

const { BROWSE, MY_GROUPS } = groupsTabs;

const getCallParams = (tab: string, search: string, searchId: string, userId: string) => {
  const searchFilter = `{"name":"name","op":"ilike","val":"%25${search}%25"}`;

  switch (tab) {
    case BROWSE:
      return {
        endpoint: '/groups',
        filter: `[${searchFilter}]`,
      };

    case MY_GROUPS:
      return {
        endpoint: '/groups',
        filter: `[{"name": "members", "op": "any", "val": {"name": "id", "op": "eq", "val":${userId}}}, ${searchFilter}]`,
      };

    default:
      return {};
  }
};

export default class Group {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getAll(payload: GetGroupsRequestPayload): Promise<GetGroupsSuccessPayload> {
    const { page, search = '', tab, searchId = '', userId = '' } = payload;
    const { endpoint, filter } = getCallParams(tab, search, searchId, userId);
    const urlString = urlComposer(endpoint, {
      page,
      filter,
    });
    const response = await this.jsonApi.get(urlString);
    const groups = get(response, 'entities.group', {});
    const count = get(response, 'entities.count', 0);
    const list: GroupObject[] = Object.values(groups) as any;
    return {
      list,
      count,
    };
  }

  async getById(id: number): Promise<any> {
    const response = await this.jsonApi.get(`/groups/${id}?include=members`);
    const group = get(response, `entities.group.${id}`, {});
    group.members = get(response, 'entities.user', {});
    return group;
  }

  async joinGroup({ groupId }: { groupId: number }): Promise<any> {
    return this.jsonApi.post(
      '/groups/relationships/members',
      {
        type: 'group',
        id: groupId,
      },
      false,
    );
  }

  async leaveGroup({ groupId }: { groupId: number }): Promise<any> {
    return this.jsonApi.delete('/groups/relationships/members', {
      data: {
        type: 'group',
        id: groupId,
      },
    });
  }
}
