import * as React from 'react';
import Badge from 'components/Badge';
import { ShoppingCartInterface } from 'commons/types/shoppingCartTypes';
import {
  ShoppingCartIconContainer,
  StyledLink,
  StyledShoppingCartIcon,
} from './ShoppingCartIcon.styles';

type ShoppingCartIconProps = {
  shoppingCartData?: ShoppingCartInterface;
};

const ShoppingCartIcon = ({ shoppingCartData }: ShoppingCartIconProps): React.ReactElement<any> => (
  <StyledLink to='/cart'>
    <ShoppingCartIconContainer>
      <StyledShoppingCartIcon />
      {shoppingCartData?.cartItemsCount > 0 ? (
        <Badge offsetTop={-7} offsetRight={-7}>
          {shoppingCartData?.cartItemsCount}
        </Badge>
      ) : null}
    </ShoppingCartIconContainer>
  </StyledLink>
);

export default ShoppingCartIcon;
