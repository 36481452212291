// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { Swiper } from 'swiper/react';
import Icon from 'components/uielements/Icon/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants';

export const NooderaSwiperContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .swiper-container {
    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      margin-bottom: 22px;
    }
  }

  .icon-arrow_left + .swiper-container {
    margin: -10px 0;
  }
`;
export const StyledSwiper: StatelessFunctionalComponent<any> = styled(Swiper)`
  width: 100%;

  & .swiper-slide {
    min-width: 275px;
    @media only screen and (min-width: ${TABLET_SMALL_WIDTH}px) {
      width: 275px;
    }
  }
`;
export const NavigationArrow: StatelessFunctionalComponent<any> = styled(Icon)`
  font-size: 18px;
`;
