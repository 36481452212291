import type { SchedulerObject, MeetingObject } from 'commons/types';
import {
  createBookingTypes,
  getSchedulerAvailabilityTypes,
  getSchedulerTypes,
  schedulerTypes,
  getSchedulerListTypes,
  getAoISchedulerTypes,
  meetingTypes,
} from './types';

export const schedulerActions = {
  getSchedulerRequest: (payload: string) => ({
    type: getSchedulerTypes.REQUEST,
    payload,
  }),
  getSchedulerSuccess: (payload: SchedulerObject) => ({
    type: getSchedulerTypes.SUCCESS,
    payload,
  }),
  getSchedulerFailed: (payload: string) => ({
    type: getSchedulerTypes.FAILED,
    payload,
  }),
  getAoISchedulerRequest: (payload: string) => ({
    type: getAoISchedulerTypes.REQUEST,
    payload,
  }),
  getAoISchedulerSuccess: (payload: any) => ({
    type: getAoISchedulerTypes.SUCCESS,
    payload,
  }),
  getAoISchedulerFailed: (payload: string) => ({
    type: getAoISchedulerTypes.FAILED,
    payload,
  }),
  getSchedulerListRequest: () => ({
    type: getSchedulerListTypes.REQUEST,
  }),
  getSchedulerListSuccess: (payload: Record<string, any>) => ({
    type: getSchedulerListTypes.SUCCESS,
    payload,
  }),
  getSchedulerListFailed: (payload: string) => ({
    type: getSchedulerListTypes.FAILED,
    payload,
  }),
  getSchedulerAvailabilityRequest: (payload: string) => ({
    type: getSchedulerAvailabilityTypes.REQUEST,
    payload,
  }),
  getSchedulerAvailabilitySuccess: (payload: SchedulerObject) => ({
    type: getSchedulerAvailabilityTypes.SUCCESS,
    payload,
  }),
  getSchedulerAvailabilityFailed: (payload: string) => ({
    type: getSchedulerAvailabilityTypes.FAILED,
    payload,
  }),
  createBookingRequest: (payload: any) => ({
    type: createBookingTypes.REQUEST,
    payload,
  }),
  createBookingSuccess: (payload: SchedulerObject) => ({
    type: createBookingTypes.SUCCESS,
    payload,
  }),
  createBookingFailure: (payload: string) => ({
    type: createBookingTypes.FAILURE,
    payload,
  }),
  createBookingReset: () => ({
    type: createBookingTypes.RESET,
  }),
  setSchedulerTimezone: (payload: any) => ({
    type: schedulerTypes.SET_SCHEDULER_TIMEZONE,
    payload,
  }),
  clickMeetingHour: (payload: string) => ({
    type: schedulerTypes.CLICK_MEETING_HOUR,
    payload,
  }),
  getMeetings: (payload: number) => ({
    type: meetingTypes.GET_MEETING_REQUEST,
    payload,
  }),
  getMeetingsSuccess: (payload: MeetingObject) => ({
    type: meetingTypes.GET_MEETING_SUCCESS,
    payload,
  }),
  getMeetingsFailed: (payload: string) => ({
    type: meetingTypes.GET_MEETING_FAILURE,
    payload,
  }),
};
