// @ts-nocheck
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import api from 'lib/Api';
import type { Saga } from 'redux-saga';
import type { GetAdminNoodlingsRequestPayload, GetSuggestionsRequestPayload } from 'commons/types';
import { getAdminNoodlingByIdTypes, getAdminNoodlingsTypes, getSuggestionsTypes } from './types';
import { adminNoodlingActions, adminNoodlingFiltersActions } from './actions';

export function* getNoodlingByIdSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const noodling = yield call([api.adminNoodling, 'getById'], payload);
    yield put(adminNoodlingActions.getNoodlingByIdSuccess(noodling));
  } catch (error) {
    yield put(adminNoodlingActions.getNoodlingByIdFailed(error));
  }
}
export function* getNoodlingsSaga({
  payload,
}: {
  payload: GetAdminNoodlingsRequestPayload;
}): Saga<void> {
  try {
    yield delay(300);
    const response = yield call([api.adminNoodling, 'getNoodlings'], payload);
    const currentPage = payload.page - 1;
    yield put(adminNoodlingActions.getNoodlingsSuccess({ ...response, currentPage }));
  } catch (error) {
    yield put(adminNoodlingActions.getNoodlingsFailed(error));
  }
}
export function* getSuggestionsSaga({
  payload,
}: {
  payload: GetSuggestionsRequestPayload;
}): Saga<void> {
  try {
    yield delay(300);
    const suggestions = yield call([api.adminNoodling, 'getSuggestions'], payload);
    yield put(adminNoodlingFiltersActions.getSuggestionsSuccess(suggestions));
  } catch (error) {
    yield put(
      adminNoodlingFiltersActions.getSuggestionsFailed(
        'There was an error fetching the suggestions data',
      ),
    );
  }
}
export default function* adminNoodlingSaga(): Saga<void> {
  yield takeLatest(getAdminNoodlingByIdTypes.REQUEST, getNoodlingByIdSaga);
  yield takeLatest(getAdminNoodlingsTypes.REQUEST, getNoodlingsSaga);
  yield takeLatest(getSuggestionsTypes.REQUEST, getSuggestionsSaga);
}
