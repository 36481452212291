// @ts-nocheck
import type { TeamsState } from 'commons/types/teamTypes';
import {
  getTeamTypes,
  getTeamsTypes,
  getMyTeamsTypes,
  getTeamPreviewTypes,
  getTeamSavedResourcesTypes,
  inviteMembersTypes,
  teamTypes,
  saveContentTypes,
} from './types';

export const initialState: TeamsState = {
  team: {
    isLoading: false,
    data: {},
    error: '',
  },
  teams: {
    isLoading: false,
    data: [],
    error: '',
  },
  myTeams: {
    isLoading: false,
    data: [],
    error: '',
  },
  saveContent: {
    isLoading: false,
    success: false,
    error: '',
  },
  preview: {
    isLoading: false,
    data: [],
    error: '',
  },
  savedResources: {
    isLoading: false,
    data: null,
    error: '',
  },
  selectedTeam: undefined,
  isSelectTeamModalOpen: false,
  isSelectedTeamModalSaveMode: false,
  isTeamModalSaveOrUnsaveMode: false,
  inviteMembers: {
    isLoading: false,
    success: false,
    error: '',
  },
  savedCourses: {
    isLoading: false,
    data: [],
    error: '',
  },
  selectedFilterTeam: null,
};
type Action = {
  type: string;
  payload?: any;
};

const teamReducer = (state: TeamsState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getTeamsTypes.GET_TEAMS_REQUEST: {
      return { ...state, teams: { ...initialState.teams, isLoading: true } };
    }

    case getTeamsTypes.GET_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getTeamsTypes.GET_TEAMS_FAILURE: {
      return { ...state, teams: { ...initialState.teams, error: payload } };
    }

    case getMyTeamsTypes.GET_MY_TEAMS_REQUEST: {
      return { ...state, myTeams: { ...initialState.myTeams, isLoading: true } };
    }

    case getMyTeamsTypes.GET_MY_TEAMS_SUCCESS: {
      return {
        ...state,
        myTeams: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getMyTeamsTypes.GET_MY_TEAMS_FAILURE: {
      return { ...state, myTeams: { ...initialState.myTeams, error: payload } };
    }

    case getTeamTypes.GET_TEAM_REQUEST: {
      return { ...state, team: { ...initialState.team, isLoading: true } };
    }

    case getTeamTypes.GET_TEAM_SUCCESS: {
      return {
        ...state,
        team: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getTeamTypes.GET_TEAM_FAILURE: {
      return { ...state, team: { ...initialState.team, error: payload } };
    }

    case getTeamPreviewTypes.GET_TEAM_PREVIEW_REQUEST: {
      return { ...state, preview: { ...initialState.preview, isLoading: true } };
    }

    case getTeamPreviewTypes.GET_TEAM_PREVIEW_SUCCESS: {
      return {
        ...state,
        preview: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getTeamPreviewTypes.GET_TEAM_PREVIEW_FAILURE: {
      return { ...state, preview: { ...initialState.preview, error: payload } };
    }

    case saveContentTypes.SAVE_CONTENT_REQUEST: {
      return { ...state, saveContent: { ...initialState.saveContent, isLoading: true } };
    }

    case saveContentTypes.SAVE_CONTENT_SUCCESS: {
      return {
        ...state,
        saveContent: {
          isLoading: false,
          success: payload,
        },
      };
    }

    case saveContentTypes.SAVE_CONTENT_FAILURE: {
      return {
        ...state,
        saveContent: { ...initialState.saveContent, error: payload, success: false },
      };
    }

    case teamTypes.SELECT_TEAM: {
      return {
        ...state,
        selectedTeam: payload,
        preview: { ...initialState.preview, isLoading: false },
      };
    }

    case teamTypes.SELECT_FILTER_TEAM: {
      return { ...state, selectedFilterTeam: payload };
    }

    case getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_REQUEST: {
      return { ...state, savedResources: { ...initialState.savedResources, isLoading: true } };
    }

    case getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_SUCCESS: {
      return {
        ...state,
        savedResources: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_FAILURE: {
      return { ...state, savedResources: { ...initialState.savedResources, error: payload } };
    }

    case inviteMembersTypes.INVITE_MEMBERS_REQUEST: {
      return {
        ...state,
        inviteMembers: {
          isLoading: true,
          success: false,
        },
      };
    }

    case inviteMembersTypes.INVITE_MEMBERS_SUCCESS: {
      return {
        ...state,
        inviteMembers: {
          isLoading: false,
          success: true,
        },
      };
    }

    case inviteMembersTypes.INVITE_MEMBERS_FAILURE: {
      return {
        ...state,
        inviteMembers: {
          error: payload,
          success: false,
          isLoading: false,
        },
      };
    }

    case inviteMembersTypes.INVITE_MEMBERS_RESET: {
      return { ...state, inviteMembers: initialState.inviteMembers };
    }

    case teamTypes.TOGGLE_SELECT_TEAM_MODAL: {
      return {
        ...state,
        isSelectTeamModalOpen: payload?.isOpen,
        isSelectedTeamModalSaveMode: payload?.isSaveMode,
        isTeamModalSaveOrUnsaveMode: payload?.isTeamModalSaveOrUnsaveMode,
      };
    }

    default:
      return state;
  }
};

export default teamReducer;
