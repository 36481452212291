// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';

const {
  palette: { alphas, greyscale },
  noodera: { fontTypes },
} = getTheme();
export const ImageItemContainer: StatelessFunctionalComponent<any> = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ImgItem: StatelessFunctionalComponent<any> = styled.img`
  width: 100%;
  height: 7.2rem;
  object-fit: cover;
`;
export const SelectedImageOverlay: StatelessFunctionalComponent<any> = styled.div`
  background: ${alphas.black60};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const SelectedImageContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
`;
export const CheckIconWrapper: StatelessFunctionalComponent<any> = styled(SvgImageWrapper)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  & svg path {
    fill: ${greyscale.white};
  }
`;
export const ImageAuthor: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font5};

  text-align: center;
  margin-top: 0.5rem;
  max-width: 15rem;
`;
