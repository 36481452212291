// @ts-nocheck
import * as React from 'react';
// Icons
import LockIcon from '@mui/icons-material/Lock';
import GroupIcon from '@mui/icons-material/Group';
import RadioButton from 'components/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from 'ducks/product/actions';
import type { SessionTypeProps } from './SessionType.types';
import {
  GeneralContainer,
  HintContainer,
  ParagraphTwo,
  Section,
  TermsConditionsContainer,
  Title,
} from './SessionType.styles';

const SessionType = ({ data, onChange }: SessionTypeProps): React.ReactElement<any> | null => {
  const handleChange = (section, value): void => {
    const newItems = { ...data };
    Object.keys(newItems).forEach((property) => {
      newItems[property] = property === value;
    });
    onChange(newItems);
  };

  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product.data);
  const taPrivate = product.find((p) => p.productCode === 'ta_session_private');
  const taPrivatePrice = taPrivate?.price / 100;
  const taPublic = product.find((p) => p.productCode === 'ta_session_public');
  const taPublicPrice = taPublic?.price / 100;
  const MAX_MEMBERS = 10;
  const { acceptedMembersCount } = useSelector((state) => state.section.preview.data);
  // 2 decimals
  const taPublicPricePerMember = Number(taPublicPrice / acceptedMembersCount).toFixed(2);
  const taPublicMinPrice = taPublicPrice / MAX_MEMBERS;

  const getBookPrice = (): number => {
    if (acceptedMembersCount > 1 && acceptedMembersCount < 10) {
      return Number(taPublicPricePerMember);
    }

    return taPublicMinPrice;
  };

  const sessionItems = [
    {
      name: 'Private Session',
      value: 'private',
      subtitle: `A 1:1 meeting with the TA. Pay $${taPrivatePrice} for an hour of assistance.`,
      linkText: '',
      icon: <LockIcon />,
    },
    {
      name: 'Public Session',
      value: 'public',
      subtitle: `Allow other team members to join, splitting the fee among the ones choosing it. Pay ${
        acceptedMembersCount > 1 ? `$${getBookPrice()}-` : ''
      }$${taPublicPrice}* for an hour of assistance.`,
      linkText: '',
      icon: <GroupIcon />,
    },
  ];
  React.useEffect(() => {
    dispatch(productActions.getProductRequest());
  }, []);

  const getpriceInfoMembers = (): React.ReactElement<any> => {
    if (acceptedMembersCount > 1 && acceptedMembersCount < 10) {
      return (
        <>
          *You’ll pay <span data-testid='bookPrice'>${getBookPrice()}</span>,{' '}
          {acceptedMembersCount === 2 ? (
            <>if the other member</>
          ) : (
            <>if the other {acceptedMembersCount - 1} members </>
          )}{' '}
          in the team join your session. Public sessions support up to {MAX_MEMBERS} members.
        </>
      );
    }

    if (acceptedMembersCount >= 10) {
      return (
        <>
          *You’ll pay <span data-testid='bookPrice'>${getBookPrice()}</span>, if the other members
          in the team join your session. Public sessions support up to {MAX_MEMBERS} members.
        </>
      );
    }

    return <>*This will be splitted, only if new team members join your session.</>;
  };

  return taPrivatePrice && taPublicPrice ? (
    <Section>
      <GeneralContainer>
        <Title>Select a Session Type</Title>
        <RadioButton.CustomContent
          name='Privacy'
          items={sessionItems}
          size='medium'
          onChange={handleChange}
        />
      </GeneralContainer>
      <HintContainer>
        <ParagraphTwo>{getpriceInfoMembers()}</ParagraphTwo>
      </HintContainer>
      <TermsConditionsContainer>
        <a href='https://www.noodle.com/terms-of-service' rel='noopener noreferrer' target='_blank'>
          Terms and Conditions
        </a>
      </TermsConditionsContainer>
    </Section>
  ) : null;
};

export default SessionType;
