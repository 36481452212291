// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'lib/Api';
import type { Saga } from 'redux-saga';
import { sendCalendarSessionTypes } from './types';
import { admingGeneralActions } from './actions';

export function* sendCalendarSessionSaga({ payload }: { payload: string }): Saga<void> {
  try {
    yield call([api.adminGeneral, 'sendCalendarSession'], payload);
    yield put(admingGeneralActions.sendCalendarSessionSuccess());
  } catch (error) {
    yield put(admingGeneralActions.sendCalendarSessionFailed(error));
  }
}
export default function* adminGeneralSaga(): Saga<void> {
  yield takeLatest(sendCalendarSessionTypes.REQUEST, sendCalendarSessionSaga);
}
