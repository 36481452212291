// @ts-nocheck
import * as React from 'react';
import { matchPath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tabs from 'components/Tabs';
import NooderaButton from 'components/NooderaButton';
import NooderaModal from 'components/NooderaModal';
import CreateTeam from 'screens/CreateTeam';
import { useWidth } from 'utils/customHooks';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { TABLET_WIDTH } from 'commons/constants/size';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { getIsPrivateLabel } from 'index';
import { TabsContainer, TabsExtraActions, TabsNavigationContainer } from './TabsNavigation.styles';
import type { TabsNavigationProps } from './TabsNavigation.types';

const { Tab } = Tabs;

const TabsNavigation = ({
  theme = 'light',
  className,
  customTabs,
}: TabsNavigationProps): React.ReactElement<any> | null => {
  const width = useWidth();
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const [isOpen, setIsOpen] = React.useState(false);

  const getTabsArr = (): Record<string, any>[] => {
    if (customTabs) {
      return customTabs;
    }

    if (getIsPrivateLabel()) {
      return [];
    }

    if (pathname.includes('/teams')) {
      // TODO: This should be turned into a constant to reuse in different
      // Noodera components
      if (pathname.includes('/teams/preview')) {
        const {
          params: { teamId },
        } = matchPath(pathname, {
          path: '/teams/preview/:teamId',
        });
        return [
          {
            linkTo: `/teams/preview/${teamId}/about`,
            title: 'About',
          },
          {
            linkTo: `/teams/preview/${teamId}/members`,
            title: 'Members',
          },
        ];
      }

      return [
        {
          linkTo: '/teams/browse',
          title: 'Browse',
        },
        {
          linkTo: '/teams/saved',
          title: 'Saved',
        },
      ];
    }

    if (pathname.includes('/resources')) {
      return [
        {
          linkTo: '/resources/browse',
          title: 'Browse',
        },
        {
          linkTo: '/resources/saved',
          title: 'Saved',
        },
      ];
    }

    if (isLoggedIn && (pathname === '/' || pathname === '/tab/team')) {
      return [
        {
          linkTo: `/`,
          title: 'Feed',
        },
        {
          linkTo: `/tab/team`,
          title: 'Team',
        },
      ];
    }

    if (pathname.includes('/courses/saved') || pathname.includes('/courses/browse')) {
      return [
        {
          linkTo: '/courses/browse',
          title: 'Browse',
        },
        ...(isNonNoodleInstance
          ? []
          : [
              {
                linkTo: '/courses/saved',
                title: 'Saved',
              },
            ]),
      ];
    }

    return [];
  };

  const handleTeamClick = (): void => (isLoggedIn ? setIsOpen(!isOpen) : history.push('/signup'));

  const renderExtraActions = (): React.ReactElement<any> | React.ReactElement<any>[] | null =>
    width >= TABLET_WIDTH && pathname.includes('/teams') && !pathname.includes('/programs') ? (
      <>
        <NooderaButton.CTA
          size='medium'
          onClick={handleTeamClick}
          icon={<PlusIcon />}
          aria-label='Create team'
          aria-haspopup='true'
          aria-expanded={isOpen}
        >
          Team
        </NooderaButton.CTA>
        <NooderaModal isOpen={isOpen} title='Create a Team' onClose={() => setIsOpen(false)}>
          <CreateTeam />
        </NooderaModal>
      </>
    ) : null;

  const renderTabs = (): React.ReactElement<any> | null => {
    const tabs = getTabsArr();

    if (!tabs?.length) {
      return null;
    }

    return (
      <TabsNavigationContainer className={className} role='navigation'>
        <TabsContainer>
          <Tabs
            theme={theme}
            setActiveTab={(child) => child.props.linkTo.includes(pathname)}
            role='tablist'
          >
            {tabs?.map(({ linkTo, title }) => (
              <Tab
                key={title}
                linkTo={linkTo}
                role='tab'
                aria-selected={linkTo.includes(pathname) ? 'true' : 'false'}
              >
                {title}
              </Tab>
            ))}
          </Tabs>
        </TabsContainer>
        {/* TODO: We should probably add some mechanism to add actions left or right aligned
        for the Tabs Container */}
        <TabsExtraActions>{renderExtraActions()}</TabsExtraActions>
      </TabsNavigationContainer>
    );
  };

  return renderTabs();
};

export default TabsNavigation;
