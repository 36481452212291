// @ts-nocheck
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type {
  MeetingDetailsType,
  TASchedulerBookingPayload,
  JoinSplitPayload,
} from 'commons/types/teacherAssistantType';

export default class TeacherAssistant {
  jsonApi: JsonApi;

  basePath = '/teacher_assistants';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getMeetingDetail(payload: string): Promise<MeetingDetailsType> {
    try {
      const response = await this.jsonApi.get(`${this.basePath}/sessions/${payload}`, false);
      const meetingDetails = get(response, 'data.attributes', {});
      const userSpareTaSessionTransactions = get(response, 'data.userSpareTaSessionTransactions');
      return { ...meetingDetails, userSpareTaSessionTransactions };
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ TeacherAssistant ~ getMeetingDetail ~ err', err);
      throw err;
    }
  }

  async joinSplitCost(payload: JoinSplitPayload): Promise<any> {
    const { id, start } = payload;
    const customHeader = start
      ? {
          headers: {
            'X-User-Timezone-Offset-Minutes': new Date(start).getTimezoneOffset(),
          },
        }
      : {};

    try {
      return await this.jsonApi.post(
        `${this.basePath}/sessions/${id}/join`,
        '',
        false,
        null,
        '',
        customHeader,
      );
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ joinSplitCost ~ err', err);
      // We're probably gonna add sentry here
      throw err;
    }
  }

  async getTASchedulerAvailability(payload: string): Promise<any> {
    const response = await this.jsonApi.get(`/v2${this.basePath}/${payload}/availability`, false);
    const responseObj = get(response, 'data', {});
    return responseObj;
  }

  async createTASchedulerBooking(payload: TASchedulerBookingPayload): Promise<any> {
    const { slug, start, end, timezone, what, description, sectionId, isPublic } = payload;
    const data = {
      type: 'teacher_assistant_session',
      attributes: {
        start,
        end,
        selected_timezone: timezone,
        what,
        description,
        section_id: sectionId,
        is_public: isPublic,
      },
    };

    try {
      const response = await this.jsonApi.post(`${this.basePath}/${slug}/sessions`, data, false);
      const responseObj = get(response, 'entities.teacherAssistantSession', {});
      return responseObj;
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ createTASchedulerBooking ~ err', err);
      // We're probably gonna add sentry here
      throw err;
    }
  }
}
