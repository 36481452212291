// @ts-nocheck
import {
  createLandingPageTypes,
  getLandingPageTypes,
  getParentsSuggestionsTypes,
  landingPageListTypes,
  updateLandingPageTypes,
  updateCriteriaTypes,
  landingPageSuggestionsTypes,
  landingPageEditModeTypes,
  landingPageCriteriaTypes,
} from './types';

const initState = {
  editMode: false,
  list: [],
  parentsSuggestions: [],
  suggestions: [],
};

const getSuggestion = (suggestions, searchValue) =>
  suggestions.filter((value) => value.name.includes(searchValue));

const landingPagesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case createLandingPageTypes.SET_UP: {
      return { ...state, editMode: true, isLoading: false, isLoadingSuggestions: false };
    }

    case getLandingPageTypes.REQUEST:
    case landingPageListTypes.REQUEST: {
      return { ...state, editMode: false, error: null, isLoading: true };
    }

    case createLandingPageTypes.REQUEST:
    case updateLandingPageTypes.REQUEST: {
      return { ...state, error: null, isSaving: true };
    }

    case getParentsSuggestionsTypes.REQUEST: {
      return { ...state, error: null, isLoadingSuggestions: true };
    }

    case getLandingPageTypes.FAILED:
    case landingPageListTypes.FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case createLandingPageTypes.FAILED:
    case updateLandingPageTypes.FAILED: {
      return { ...state, error: payload, isSaving: false };
    }

    case getParentsSuggestionsTypes.FAILED: {
      return { ...state, error: payload, isLoadingSuggestions: false };
    }

    case getLandingPageTypes.SUCCESS:
    case landingPageListTypes.SUCCESS: {
      return { ...state, criteria: {}, error: null, isLoading: false, ...payload };
    }

    case createLandingPageTypes.SUCCESS:
    case updateLandingPageTypes.SUCCESS: {
      return { ...state, editMode: false, error: null, isSaving: false, ...payload };
    }

    case getParentsSuggestionsTypes.SUCCESS: {
      return { ...state, isLoadingSuggestions: false, ...payload };
    }

    case landingPageListTypes.SET_CURRENT_PAGE: {
      return { ...state, currentPage: payload };
    }

    case landingPageSuggestionsTypes.FILTER: {
      return { ...state, suggestions: getSuggestion(state.parentsSuggestions, payload) };
    }

    case landingPageSuggestionsTypes.CLEAR: {
      return { ...state, suggestions: [] };
    }

    case landingPageEditModeTypes.CHANGE: {
      return { ...state, editMode: payload };
    }

    case updateCriteriaTypes.REQUEST: {
      return { ...state, criteria: { ...state.criteria, isSaving: true } };
    }

    case updateCriteriaTypes.SUCCESS: {
      return {
        ...state,
        ...payload,
        criteria: {
          editMode: false,
          isSaving: false,
        },
      };
    }

    case updateCriteriaTypes.FAILED: {
      return { ...state, criteria: { ...state.criteria, isSaving: false }, error: payload };
    }

    case landingPageCriteriaTypes.EDIT_MODE: {
      return { ...state, criteria: { ...state.criteria, editMode: payload } };
    }

    default:
      return state;
  }
};

export default landingPagesReducer;
