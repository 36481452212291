export const getTimekitProjectTypes = {
  REQUEST: 'GET_TIMEKIT_PROJECT_REQUEST',
  SUCCESS: 'GET_TIMEKIT_PROJECT_SUCCESS',
  FAILED: 'GET_TIMEKIT_PROJECT_FAILED',
};
export const deleteTimekitProjectTypes = {
  REQUEST: 'DELETE_TIMEKIT_PROJECT_REQUEST',
  SUCCESS: 'DELETE_TIMEKIT_PROJECT_SUCCESS',
  FAILED: 'DELETE_TIMEKIT_PROJECT_FAILED',
};
export const getTimekitProjectListTypes = {
  REQUEST: 'GET_TIMEKIT_PROJECT_LIST_REQUEST',
  SUCCESS: 'GET_TIMEKIT_PROJECT_LIST_SUCCESS',
  FAILED: 'GET_TIMEKIT_PROJECT_LIST_FAILED',
};
export const createTimekitProjectTypes = {
  REQUEST: 'CREATE_TIMEKIT_PROJECT_REQUEST',
  SUCCESS: 'CREATE_TIMEKIT_PROJECT_SUCCESS',
  FAILED: 'CREATE_TIMEKIT_PROJECT_FAILED',
};
export const updateTimekitProjectTypes = {
  REQUEST: 'UPDATE_TIMEKIT_PROJECT_REQUEST',
  SUCCESS: 'UPDATE_TIMEKIT_PROJECT_SUCCESS',
  FAILED: 'UPDATE_TIMEKIT_PROJECT_FAILED',
};
export const adminSchedulerTypes = {
  CHANGE_EDIT_MODE: 'CHANGE_EDIT_MODE',
  SET_UP_CREATE: 'SET_UP_CREATE',
};
