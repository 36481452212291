// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
// noinspection ES6PreferShortImport
import { getTheme } from 'settings/theme/index';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';
// components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

import SvgImageWrapper from 'components/SvgImage/SvgImage.style';

const {
  noodera: { spacing, fontTypes },
  palette: { neutral },
} = getTheme();
export const StyledDialog: StatelessFunctionalComponent<any> = styled(Dialog)`
  && {
    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      & .MuiDialog-paperWidthSm {
        width: 100%;
        max-width: 100%;
        margin: 16px;
      }
    }
  }
`;
export const Content: StatelessFunctionalComponent<any> = styled.div`
  && {
    ${({ hasAppBar }) => (hasAppBar ? 'margin-top: 56px;' : '')}
    height: 100%;
    width: 100%;
  }
`;
export const SmallContent: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: ${spacing.x4};
    border-radius: 8px;
    width: 480px;

    & > div {
      width: 100%;
      height: auto;
      text-align: center;
    }

    & > div:first-child {
      margin-bottom: ${spacing.x6};
    }

    & > div > * {
      margin-bottom: ${spacing.x6};
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      && {
        width: 100%;
        padding: ${spacing.x5};

        & > .lottie {
          width: 300px;
        }
      }
    }
  }
`;
export const ModalContent: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 311px;
    height: 152px;

    & > * {
      display: flex;
      flex-grow: 1;
    }
  }
`;
export const ModalTitle: StatelessFunctionalComponent<any> = styled.div`
  && {
    ${fontTypes.font3Medium}
    color: ${neutral.hundred};
  }
`;
export const ModalImage: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: center;
  }
`;
export const ModalDescription: StatelessFunctionalComponent<any> = styled.div`
  && {
    ${fontTypes.font4}
    color: ${neutral.hundred};
    text-align: center;
  }
`;
export const ModalButtons: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    gap: ${spacing.x6};

    & > button {
      width: max-content;
      min-width: 84px;
      justify-content: center;
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      & > button {
        width: 100%;
      }
    }
  }
`;
export const ModalLinkText: StatelessFunctionalComponent<any> = styled.span`
  && {
    ${fontTypes.font4Medium}
    color: ${(props) => props.theme.palette.primary.hundred};
    font-weight: bold;
  }
`;
export const MediumDialog: StatelessFunctionalComponent<any> = styled(Dialog)`
  && {
    & .MuiPaper-root {
      width: 848px;
      min-width: 848px;
      border-radius: 16px;
    }

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      & .MuiPaper-root {
        border-radius: 0;
        margin: 0;
        height: 100vh;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
`;
export const StyledDialogTitle: StatelessFunctionalComponent<any> = styled(DialogTitle)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    ${fontTypes.font3Medium};
    color: ${neutral.hundred};
    padding: ${spacing.x6} ${spacing.x4};

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      ${fontTypes.font4Medium};
      height: 48px;
    }
  }
`;
export const StyledDialogActions: StatelessFunctionalComponent<any> = styled(DialogActions)`
  && {
    display: flex;
    justify-content: center;
    height: 80px;
    background-color: ${neutral.five};

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      padding: 16px;

      & > button {
        width: 100%;
      }
    }
  }
`;

export const SmallModalTopIcon = styled(SvgImageWrapper)`
  color: ${(props) => props.theme.palette.primary.hundred};

  svg {
    height: 32px;
    width: 32px;
  }
`;

export const ModalCloseIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;
