// @ts-nocheck
import type { Saga } from 'redux-saga';
import { put, takeEvery, call } from 'redux-saga/effects';
import api from 'lib/Api';
import { cardTypesConstants, likeFunctions } from 'commons/constants';
import type { DeleteLikePostPayload, LikePostPayload } from 'commons/types';
import { likePostTypes } from './types';
import { likeActions } from './actions';

const getRequestFunction = (objectType: string): string => {
  switch (objectType) {
    case cardTypesConstants.ARTICLE:
      return likeFunctions.INTERNAL_ARTICLE;

    case cardTypesConstants.ARTICLE_SYNDICATED:
      return likeFunctions.ARTICLE;

    default:
      return likeFunctions.POST;
  }
};

export function* likeFeedPost({ payload }: LikePostPayload): Saga<void> {
  try {
    const { id, objectType } = payload;
    const requestFunction = `create${getRequestFunction(objectType)}`;
    const response = yield call([api.like, requestFunction], {
      id,
    });
    const newLikeId = Object.keys(response)[0];
    yield put(likeActions.likePostSuccess({ ...payload, likeId: newLikeId }));
  } catch (error) {
    yield put(likeActions.likePostFail('There was an error creating the new like.'));
  }
}
export function* deleteLikeFeedPost({ payload }: DeleteLikePostPayload): Saga<void> {
  try {
    const { id, likeId, objectType } = payload;
    const requestFunction = `delete${getRequestFunction(objectType)}`;
    yield call([api.like, requestFunction], {
      id,
      likeId,
    });
    yield put(likeActions.deleteLikePostSuccess(payload));
  } catch (error) {
    yield put(likeActions.deleteLikePostFail('There was an error removing the like'));
  }
}
export default function* feedSaga(): Saga<void> {
  yield takeEvery(likePostTypes.REQUEST, likeFeedPost);
  yield takeEvery(likePostTypes.DELETE_REQUEST, deleteLikeFeedPost);
}
