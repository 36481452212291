import * as React from 'react';

/**
 * @description
 * Separates a string into an array where each element it's a paragraph
 * A new paragraph is created for each '.[CAPITAL-LETTER]' found
 * (doesn't add a new one if the word is an acronym like U.S.)
 * @param {String} originalText Text to be parsed
 */
export const addParagraphs = (originalText: string): string[] => {
  const splitedText = originalText.split(/(?=[.!?][A-Z][^.A-Z])|(?=[.!?][A-Z]{2})/g);
  const lastTextIndex = splitedText.length - 1;
  const parsedText = [splitedText[0]];
  splitedText.forEach((t, i) => {
    if (i > 0) {
      parsedText.push(`${t.substr(1)}`);
    }

    if (i < lastTextIndex) {
      parsedText[i] += '.';
    }
  });
  return parsedText;
};
export const createParagraphs = (text: string, className = ''): React.ReactElement<any>[] => {
  const splitedText = text.split('\n');
  const lastPosition = splitedText.length - 1;

  const renderBreakline = (index: number): React.ReactElement<any> | null =>
    lastPosition === index ? null : <br />;

  return splitedText.map((paragraph, i) => (
    <React.Fragment key={paragraph}>
      <span className={className}>{paragraph}</span>
      {renderBreakline(i)}
    </React.Fragment>
  ));
};
