// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale },
} = getTheme();
const LinkButtonWrapper: StatelessFunctionalComponent<any> = styled.div`
  & > button:hover {
    text-decoration: none;
  }

  & > button:focus {
    text-decoration: none;
  }

  & svg {
    ${(props) => (props.iconPosition === 'left' ? 'margin-right: 1rem' : 'margin-left: 1rem')}
  }

  & i {
    ${(props) => (props.iconPosition === 'left' ? 'margin-right: 1rem' : 'margin-left: 1rem')}
  }

  & svg path {
    fill: ${greyscale.mirage} !important;
    transition: all 0.3s;
  }

  .link-button {
    font-size: 1.6rem !important;

    &__inactive {
      display: inline-flex;
      align-items: center;
    }

    &__active {
      display: inline-flex;
      align-items: center;

      color: ${greyscale.mirage} !important;
    }
  }
`;
export default LinkButtonWrapper;
