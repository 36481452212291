import type { SchedulerObject } from 'commons/types';
import {
  getPartnerProgramTypes,
  getPartnerProgramListTypes,
  deletePartnerProgramTypeTypes,
  addPartnerProgramTypeTypes,
  getPartnerProgramTypeListTypes,
  adminPartnerProgramTypes,
} from './types';

export const adminPartnerProgramActions = {
  getPartnerProgramRequest: (payload: string) => ({
    type: getPartnerProgramTypes.REQUEST,
    payload,
  }),
  getPartnerProgramSuccess: (payload: any) => ({
    type: getPartnerProgramTypes.SUCCESS,
    payload,
  }),
  getPartnerProgramFailed: (payload: string) => ({
    type: getPartnerProgramTypes.FAILED,
    payload,
  }),
  deletePartnerProgramTypeRequest: (payload: string) => ({
    type: deletePartnerProgramTypeTypes.REQUEST,
    payload,
  }),
  deletePartnerProgramTypeSuccess: (payload: SchedulerObject) => ({
    type: deletePartnerProgramTypeTypes.SUCCESS,
    payload,
  }),
  deletePartnerProgramTypeFailed: (payload: string) => ({
    type: deletePartnerProgramTypeTypes.FAILED,
    payload,
  }),
  getPartnerProgramListRequest: (payload: string) => ({
    type: getPartnerProgramListTypes.REQUEST,
    payload,
  }),
  getPartnerProgramListSuccess: (payload: Record<string, any>[]) => ({
    type: getPartnerProgramListTypes.SUCCESS,
    payload,
  }),
  getPartnerProgramListFailed: (payload: string) => ({
    type: getPartnerProgramListTypes.FAILED,
    payload,
  }),
  getPartnerProgramTypeListRequest: () => ({
    type: getPartnerProgramTypeListTypes.REQUEST,
  }),
  getPartnerProgramTypeListSuccess: (payload: SchedulerObject) => ({
    type: getPartnerProgramTypeListTypes.SUCCESS,
    payload,
  }),
  getPartnerProgramTypeListFailed: (payload: string) => ({
    type: getPartnerProgramTypeListTypes.FAILED,
    payload,
  }),
  addPartnerProgramTypeRequest: (payload: any) => ({
    type: addPartnerProgramTypeTypes.REQUEST,
    payload,
  }),
  addPartnerProgramTypeSuccess: (payload: any) => ({
    type: addPartnerProgramTypeTypes.SUCCESS,
    payload,
  }),
  addPartnerProgramTypeFailed: (payload: string) => ({
    type: addPartnerProgramTypeTypes.FAILED,
    payload,
  }),
  filterSuggestions: (payload: boolean) => ({
    type: adminPartnerProgramTypes.FILTER_SUGGESTIONS,
    payload,
  }),
  clearSuggestions: () => ({
    type: adminPartnerProgramTypes.CLEAR_FILTER,
  }),
};
