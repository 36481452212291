export const setSessionStorageData = (key: string, data: any): void => {
  if (!window.sessionStorage || !key || !data) return;
  sessionStorage.setItem(key, JSON.stringify(data));
};
export const getSessionStorageData = (key: string): any => {
  if (!window.sessionStorage || !key) return null;

  try {
    const data = sessionStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  } catch (error) {
    console.error('Error: ', error);
    return null;
  }
};
export const removeSessionStorageData = (key: string): void => {
  if (!window.sessionStorage || !key) return;
  sessionStorage.removeItem(key);
};
export const removeSessionStorageDataStartWith = (keys: string[]): void => {
  if (!window.sessionStorage) return;
  const keysToRemove = [];
  Object.keys(sessionStorage).forEach((storageKey) => {
    keys.forEach((receivedKey) => {
      if (storageKey.toLowerCase().startsWith(receivedKey.toLowerCase())) {
        keysToRemove.push(storageKey);
      }
    });
  });
  keysToRemove.forEach((keyToRemove) => sessionStorage.removeItem(keyToRemove));
};
