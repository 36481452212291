// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';

const {
  noodera: { spacing },
} = getTheme();
export const ImageListContainer: StatelessFunctionalComponent<any> = styled.div`
  display: grid;
  grid-gap: ${spacing.x5};
  grid-template-columns: repeat(auto-fit, minmax(86px, 1fr));
  margin-top: ${spacing.x3};

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  }
`;
