export const getMeetingDetailTypes = {
  GET_MEETING_DETAIL_REQUEST: 'GET_MEETING_DETAIL_REQUEST',
  GET_MEETING_DETAIL_SUCCESS: 'GET_MEETING_DETAIL_SUCCESS',
  GET_MEETING_DETAIL_FAILURE: 'GET_MEETING_DETAIL_FAILURE',
};
export const joinSplitCostTypes = {
  JOIN_SPLIT_COST_REQUEST: 'JOIN_SPLIT_COST_REQUEST',
  JOIN_SPLIT_COST_SUCCESS: 'JOIN_SPLIT_COST_SUCCESS',
  JOIN_SPLIT_COST_FAILURE: 'JOIN_SPLIT_COST_FAILURE',
  JOIN_SPLIT_COST_RESET: 'JOIN_SPLIT_RESET',
};
export const getSchedulerAvailabilityTypes = {
  GET_TA_SCHEDULER_AVAILABILITY_REQUEST: 'GET_TA_SCHEDULER_AVAILABILITY_REQUEST',
  GET_TA_SCHEDULER_AVAILABILITY_SUCCESS: 'GET_TA_SCHEDULER_AVAILABILITY_SUCCESS',
  GET_TA_SCHEDULER_AVAILABILITY_FAILURE: 'GET_TA_SCHEDULER_AVAILABILITY_FAILURE',
};
export const createTASchedulerBookingTypes = {
  CREATE_TA_SCHEDULER_BOOKING_REQUEST: 'CREATE_TA_SCHEDULER_BOOKING_REQUEST',
  CREATE_TA_SCHEDULER_BOOKING_SUCCESS: 'CREATE_TA_SCHEDULER_BOOKING_SUCCESS',
  CREATE_TA_SCHEDULER_BOOKING_FAILURE: 'CREATE_TA_SCHEDULER_BOOKING_FAILURE',
};
export const schedulerBookingTypes = {
  RESET_BOOKING_STATUS: 'RESET_BOOKING_STATUS',
};
