// @ts-nocheck
import * as React from 'react';
import Action from 'components/Action';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import TeamPlaceholder from 'assets/images/placeholder-team.png';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import type { TeamListItemProps } from './TeamListItem.types';
import {
  Container,
  Image,
  Label,
  Subtitle,
  StyledActionWrapper,
  IconHolder,
} from './TeamListItem.styles';

const TeamListItem = ({
  data,
  canUnsave,
  withActionButton,
  onClick,
}: TeamListItemProps): React.ReactElement<any> => {
  const isCanUnsaveDefined = withActionButton && canUnsave !== undefined;
  let actionIcon = <AddIcon />;
  if (isCanUnsaveDefined) actionIcon = canUnsave ? <CheckIcon /> : <LockOutlinedIcon />;
  const isDisabled = withActionButton && canUnsave === false;
  const { image, name, teamType, acceptedMembersCount } = data;
  const isPrivate = !data.public;
  return (
    <Container onClick={onClick} disabled={isDisabled}>
      <Image src={image} srcFallback={TeamPlaceholder} alt='Team' />
      <div>
        <Label>
          {name}
          {isPrivate && (
            <IconHolder>
              <LockIcon />
            </IconHolder>
          )}
          {teamType === 'section' && (
            <IconHolder>
              <SchoolIcon />
            </IconHolder>
          )}
        </Label>
        <Subtitle>
          {acceptedMembersCount} {acceptedMembersCount === 1 ? 'member' : 'members'}
        </Subtitle>
      </div>
      {withActionButton ? (
        <StyledActionWrapper disabled={canUnsave === false}>
          <Action
            name={isCanUnsaveDefined ? 'Saved' : 'Save'}
            icon={<SvgImageWrapper>{actionIcon}</SvgImageWrapper>}
            align='right'
          />
        </StyledActionWrapper>
      ) : null}
      {/* TODO: Badge for notifications missing */}
    </Container>
  );
};

export default TeamListItem;
