// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';
import { getTheme } from 'settings/theme';

const {
  palette: { alphas, neutral, primary },
  noodera: { fontTypes },
  boxShadow,
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  & > div {
    background-color: transparent;
    color: ${neutral.eighty};

    & > .tag__text {
      ${fontTypes.font5};

      & > svg {
        font-size: 24px;
      }
    }

    &:hover {
      background-color: ${primary.ten};
      color: ${primary.hundred};
    }

    &:active {
      color: ${primary.hundredTwenty};
    }

    &:focus {
      color: ${alphas.black60};
      background-color: ${primary.forty};
      border-radius: 4px;
      box-shadow: ${boxShadow.focus};
    }

    &:disabled {
      color: ${neutral.sixty};
    }
  }

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    && {
      & > div {
        padding: 0;
      }
    }
  }
`;
