// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
// Material UI
import { getTheme } from 'settings/theme/index';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
// Components
import MaterialCard from 'components/MaterialCard';
import { TABLET_WIDTH } from 'commons/constants/size';

const {
  palette: { primary, neutral, greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();
export const StyledRadio: StatelessFunctionalComponent<any> = styled(Radio)`
  && {
    & .MuiSvgIcon-root {
      font-size: 16px;
    }

    &.Mui-checked {
      color: ${primary.hundred};

      & .PrivateRadioButtonIcon-layer-6 {
        transform: scale(1.2);
      }
    }

    &.Mui-disabled {
      color: ${neutral.sixty};

      & .MuiIconButton-label {
        background-color: ${neutral.twenty};
        border-radius: 50%;
        height: 10px;
        width: 10px;
      }
    }

    &:hover {
      background-color: transparent;
    }

    &.MuiRadio-colorSecondary.Mui-checked:hover {
      background-color: transparent;
    }
  }
`;

/* Selectors */
export const StyledFormControl: StatelessFunctionalComponent<any> = styled(FormControl)`
  && {
    width: 100%;
  }
`;
export const StyledFormControlLabel: StatelessFunctionalComponent<any> = styled(FormControlLabel)`
  && {
    margin: 0;

    & .MuiIconButton-root {
      padding: ${spacing.x7};
    }

    & .MuiTypography-root {
      ${fontTypes.font4};
    }
  }
`;

/* Selector Custom Content */
export const StyledCard: StatelessFunctionalComponent<any> = styled(MaterialCard)`
  && {
    border-radius: initial;
    min-width: 343px;
    min-height: 84px;
    height: auto;
    padding: ${spacing.x6} ${spacing.x5};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: ${(props) =>
      props.isActive ? `1px solid ${neutral.sixty}` : `1px solid ${neutral.ten}`};
    background: ${(props) => (props.isDisabled ? neutral.ten : greyscale.white)};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'initial')};
    margin-bottom: ${spacing.x6};

    & > * {
      display: flex;
      flex-grow: 1;
    }

    a {
      pointer-events: initial;
    }

    & .MuiSvgIcon-root {
      color: ${(props) => (props.isDisabled ? neutral.eighty : primary.hundred)};
    }

    &:hover {
      transform: none;
      transition: width 0s;
      border: ${(props) =>
        props.isDisabled ? `1px solid ${neutral.ten}` : `1px solid ${primary.hundred}`};
    }

    & .MuiTypography-body1 {
      width: 100%;
    }

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
      min-width: 100%;
    }
  }
`;
export const StyledFormControlContent: StatelessFunctionalComponent<any> = styled(FormControlLabel)`
  && {
    margin: 0;

    & .MuiIconButton-root {
      padding: 0;
    }

    & .Mui-disabled {
      background-color: ${neutral.ten};
      pointer-events: none;
    }
  }
`;
export const LabelContent: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    margin-left: ${spacing.x7};

    & > * {
      display: flex;
      flex-grow: 1;
      color: ${neutral.hundred};
    }
  }
`;
export const TextContent: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;
export const Title: StatelessFunctionalComponent<any> = styled(Typography)`
  && {
    ${fontTypes.font4Medium};
  }
`;
export const Subtitle: StatelessFunctionalComponent<any> = styled(Typography)`
  && {
    ${fontTypes.font5};
  }
`;
export const BoldText: StatelessFunctionalComponent<any> = styled.span`
  && {
    ${fontTypes.font5};
    color: ${primary.hundred};
  }
`;
export const IconWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    color: ${primary.hundred};
    justify-content: flex-end;

    & .MuiSvgIcon-root {
      font-size: ${spacing.x5};
    }
  }
`;

/* Selector Custom Payment */
export const ImageWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    align-items: center;
    justify-content: flex-end;
    width: 72px;
    height: 24px;
    padding: 6px;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;
