import { Dropdown } from 'components/Dropdown';
import { teamActions } from 'ducks/team/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useGetSelectedTeamInfo, useIsDesktop } from 'utils/customHooks';
import Breadcrumb from 'components/Breadcrumb/index';
import {
  DropdownContainer,
  HomeTitleContainer,
  MainTitle,
  NavigationContainer,
} from './HomeTitle.styles';

export const HomeTitle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const { pathname } = useLocation();

  const { teamName, isAllTeams } = useGetSelectedTeamInfo();

  const isSelectTeamModalOpen = useSelector((state) => state.team.isSelectTeamModalOpen);

  const handleChange = (): void => {
    dispatch(
      teamActions.toggleSelectTeamModal({
        isOpen: true,
        isTeamModalSaveOrUnsaveMode: false,
      }),
    );
  };

  if (pathname === '/') {
    return (
      <HomeTitleContainer>
        <MainTitle>Home</MainTitle>
        <NavigationContainer>
          <DropdownContainer>
            <Dropdown isOpen={!isSelectTeamModalOpen} title={teamName} onChange={handleChange} />
          </DropdownContainer>
          {!isDesktop && !isAllTeams && (
            <InfoOutlinedIcon onClick={() => history.push('/tab/team')} />
          )}
        </NavigationContainer>
      </HomeTitleContainer>
    );
  }

  if (pathname === '/tab/team') {
    return (
      <HomeTitleContainer>
        <Breadcrumb
          breadcrumbTrail={[
            {
              title: 'Home',
              url: '/',
            },
          ]}
        />
      </HomeTitleContainer>
    );
  }

  return null;
};
