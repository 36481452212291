import * as React from 'react';
import { StepperWrapper, Step, Line, Circle, LabelContainer } from './Stepper.styles';
import type { StepperProps } from './Stepper.types';

const Stepper = ({ activeStep, steps }: StepperProps) => (
  <StepperWrapper>
    {steps.map((step, index) => (
      <Step key={step.label}>
        <Line complete={index <= activeStep} />
        <Circle complete={index >= activeStep} />
        <LabelContainer> {step.label} </LabelContainer>
        <Line complete={index < activeStep} />
      </Step>
    ))}
  </StepperWrapper>
);

export default Stepper;
