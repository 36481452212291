// @ts-nocheck
import { call, put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import {
  getOnboardingOccupationTypes,
  getOnboardingQuestionTypes,
  insertAnswerTypes,
  getOnboardingAnswersTypes,
} from './types';
import { onboardingActions } from './actions';

const { DANGER } = alertTypesConstants;
export function* getOccupationsSaga(): Saga<void> {
  try {
    const response = yield call([api.onboarding, 'getOnboardingOccupations']);
    yield put(onboardingActions.getOnboardingOccupationSuccess(response));
  } catch (error) {
    const message = 'There was an error fetching the occupations.';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(onboardingActions.getOnboardingOccupationFailure(error));
  }
}
export function* getOnboardingQuestionSaga(): Saga<void> {
  try {
    const response = yield call([api.onboarding, 'getOnboardingQuestion']);
    yield put(onboardingActions.getOnboardingQuestionSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'error getting onboarding question',
        type: DANGER,
      }),
    );
    yield put(onboardingActions.getOnboardingQuestionFailed(error));
  }
}
export function* insertAnswerSaga({ payload }: any): Saga<void> {
  try {
    const answers = yield select((state) => state.onboarding.answers.data);
    const data = payload;
    data.answers = answers;
    const response = yield call([api.onboarding, 'insertAnswer'], data);
    yield put(onboardingActions.insertAnswerSuccess(response));
  } catch (error) {
    yield put(onboardingActions.insertAnswerFailure(error));
  }
}
export function* getOnboardingAnswersSaga(): Saga<void> {
  try {
    const response = yield call([api.onboarding, 'getOnboardingAnswers']);
    yield put(onboardingActions.getOnboardingAnswersSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'error getting onboarding answers',
        type: DANGER,
      }),
    );
    yield put(onboardingActions.getOnboardingAnswersFailure(error));
  }
}
export default function* onboardingSaga(): Saga<void> {
  yield takeLatest(
    getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_REQUEST,
    getOccupationsSaga,
  );
  yield takeLatest(
    getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_REQUEST,
    getOnboardingQuestionSaga,
  );
  yield takeLatest(insertAnswerTypes.INSERT_ANSWER_REQUEST, insertAnswerSaga);
  yield takeLatest(
    getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_REQUEST,
    getOnboardingAnswersSaga,
  );
}
