import * as React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { isEnabledPremiumEnroll } from 'settings/';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { useGetSelectedTeamInfo, useIsInstanceAdmin } from 'utils/customHooks';

import { teacherAssistantActions } from 'ducks/teacherAssistant/actions';
import { sectionActions } from 'ducks/section/actions';

import serverErrorAnim from 'assets/images/server-error.json';
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg';
import { ReactComponent as ExclamationIcon } from 'assets/svg/exclamation.svg';
// Components
import UserTag from 'components/UserTag';
import NooderaButton from 'components/NooderaButton/index';
import BecomePremiumModal from 'components/BecomePremiumModal';
import NooderaModal from 'components/NooderaModal/index';
import Breadcrumb from 'components/Breadcrumb/index';
import HireTA from 'screens/HireTA/index';
import { MainTitle } from 'screens/Dashboard/components/HomeTitle/HomeTitle.styles';

import type { Course } from 'commons/types/courseTypes';
import type { MeetingObject } from 'commons/types/schedulerTypes';
import type { Section } from 'commons/types/sectionTypes';

import EditProfileCard from './EditProfileCard/EditProfileCard';
import TaSessionsCard from './TaSessionsCard/TaSessionsCard';
import PaidCard from './PaidCard/PaidCard';
import CourseCard from './CourseCard';
import CardSection from './CardSection';

import {
  DashboardWrapper,
  DashboardTag,
  MeetingWrapper,
  PaidCourseIcon,
  Title,
  CardsContainer,
  DashboardSubtitle,
  DashboardCardCount,
  CourseDashboardContainer,
  CourseDashboardHeader,
} from './SectionFeed.styles';

const paidPlanDetails = [
  'Certificate on completion',
  'Schedule and launch video sessions with other learners',
  'Collaborate with annotations on course content',
  'Access to instructional support and assignment grading (for additional fees)',
];

interface SectionFeedProps {
  currentCourse: Course | null;
  meetings: MeetingObject[] | null;
  currentSection: Section;
  isPremium?: boolean;
  price: number;
  showEditProfileCard?: boolean;
  myCourses?: Course[];
  courses?: Course[];
  isExtended?: boolean;
}

const SectionFeed: React.FC<SectionFeedProps> = ({
  currentCourse,
  myCourses,
  courses,
  meetings,
  currentSection,
  isPremium,
  price,
  showEditProfileCard = false,
  isExtended = false,
}) => {
  const history = useHistory();
  const { isAllTeams, isGeneralTeam } = useGetSelectedTeamInfo();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [carouselItems, setCarouselItems] = React.useState([]);
  const teacherAssistants = useSelector(
    (state) => state.courses.preview.data?.teacherAssistantsCollection,
  );
  const [isTAModal, setTAModal] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorModalDescription, setErrorModalDescription] = React.useState('');
  const [teacherAssistantData, setTeacherAssistantData] = React.useState(null);
  const isInstanceAdmin = useIsInstanceAdmin();
  const {
    id: sectionId,
    brightspaceCourseSlug,
    userSpareTaSessionTransactions,
  } = useSelector((state) => state.section.preview.data);

  // TODO: Timezone should be dynamic in the future
  const getMeetingDate = (date): string => {
    if (date) {
      return `${moment.tz(date, 'America/New_York').format('ddd')}, ${moment(date).format(
        'MMM Do YYYY',
      )}`;
    }

    return '';
  };

  // TODO: Timezone should be dynamic in the future
  const getMeetingTime = (date): string => {
    if (date) {
      return `${moment.tz(date, 'America/New_York').format('h:mm a')} - EST`;
    }

    return '';
  };

  const renderEditProfileCard = (): React.ReactNode => {
    if (showEditProfileCard) {
      return <EditProfileCard />;
    }

    return null;
  };

  const renderPaidCard = (): React.ReactNode => {
    const isUpgradeable =
      isPremium === false &&
      isEnabledPremiumEnroll &&
      currentSection &&
      currentCourse?.mode.map((v) => v.toLowerCase()).includes('premium') &&
      currentCourse?.priceInCents !== 0;

    if (isUpgradeable) {
      return <PaidCard onClick={() => setIsOpenModal(!isOpenModal)} />;
    }

    return null;
  };

  const renderCourses = (): React.ReactNode => {
    if (isInstanceAdmin) {
      return courses.map((course) => <CourseCard course={course} />);
    }

    if (isGeneralTeam || isAllTeams) {
      return myCourses.map((course) => {
        const courseStartDate = course?.courseSchedule?.scheduleStartDate || course.startDate;

        return <CourseCard course={course} startDate={courseStartDate} />;
      });
    }

    const currentCourseStartDate =
      currentCourse?.courseSchedule?.scheduleStartDate ||
      currentSection?.startDate ||
      currentCourse?.startDate;

    return currentSection && currentCourse ? (
      <CourseCard course={currentCourse} startDate={currentCourseStartDate} />
    ) : null;
  };

  const renderMeeting = (): React.ReactElement<any>[] => {
    if (meetings && meetings.length && currentSection) {
      return meetings?.map(({ teacherAssistant: { avatar, name }, booking: { event, id } }) => (
        <MeetingWrapper
          key={`meeting-${event.start}`}
          role='region'
          aria-label={`Meeting details for ${name} on ${getMeetingDate(
            event.start,
          )}, ${getMeetingTime(event.start)}`}
        >
          <DashboardTag
            style={{
              width: 'auto',
            }}
            inline
          >
            Upcoming TA Session
          </DashboardTag>
          <UserTag
            img={avatar.url}
            title={name}
            alt={`Profile picture of ${name}`}
            subtitle={getMeetingDate(event.start)}
            optionalText={getMeetingTime(event.start)}
          />
          {/* TODO: Add this back after demo */}
          <Link to={`/meeting/${id}`} tabIndex={-1}>
            <NooderaButton size='small' tabIndex={0} aria-label='View meeting details'>
              View Details
            </NooderaButton>
          </Link>
        </MeetingWrapper>
      ));
    }

    return [];
  };

  const handleConfirmationModal = (): void => {
    setConfirmationModal(false);
    setTAModal(false);
  };

  const handleConfirmation = (): void => {
    setConfirmationModal(true);
    dispatch(teacherAssistantActions.resetBookingSchedulerStatus());
    dispatch(sectionActions.getSectionPreviewRequest(sectionId));
  };

  const handleErrorModal = (): void => {
    setIsErrorModalOpen(false);
    setTAModal(false);
  };

  const handleError = (sessionType: 'private' | 'public'): void => {
    setErrorModalDescription(
      sessionType === 'private'
        ? "However, your payment was successful. Try booking again, you won't be charged twice."
        : "However, your payment was successful. Try booking again or joining a meeting in the next 24 hours, and you won't be charged twice. After that, you'll be refunded.",
    );
    setIsErrorModalOpen(true);
    dispatch(teacherAssistantActions.resetBookingSchedulerStatus());
  };

  const renderHireTAModal = (): React.ReactNode =>
    teacherAssistantData && teacherAssistantData.slug ? (
      <NooderaModal
        isOpen={isTAModal}
        title={`Hire ${teacherAssistantData.name}` || ''}
        onClose={() => setTAModal(false)}
      >
        <HireTA
          courseSlug={brightspaceCourseSlug}
          publicSessionProductCode={teacherAssistantData?.publicSessionProductCode}
          privateSessionProductCode={teacherAssistantData?.privateSessionProductCode}
          userSpareTaSessionTransactions={userSpareTaSessionTransactions}
          onSubmit={handleConfirmation}
          onError={handleError}
        />
      </NooderaModal>
    ) : null;

  const handleTASection = (teacherAssistant: any): void => {
    setTAModal(true);
    dispatch(teacherAssistantActions.getTASchedulerAvailability(teacherAssistant.slug));
    setTeacherAssistantData(teacherAssistant);
  };

  const renderTaSessions = (): React.ReactNode => {
    if (isPremium && currentSection && !isNonNoodleInstance) {
      return teacherAssistants?.map((teacherAssistant) => (
        <TaSessionsCard
          iconUrl={teacherAssistant.media.url}
          onClick={() => handleTASection(teacherAssistant)}
        />
      ));
    }

    return null;
  };

  const filteredCarouselItems = carouselItems?.filter((i) => i).flat();

  React.useEffect(() => {
    // Reset carousel items when navigating other sections
    setCarouselItems([]);
    setCarouselItems([
      renderCourses(),
      renderPaidCard(),
      ...renderMeeting(),
      renderTaSessions(),
      renderEditProfileCard(),
    ]);
  }, [
    currentSection,
    showEditProfileCard,
    currentCourse,
    myCourses,
    meetings,
    isPremium,
    isInstanceAdmin,
    courses,
  ]);

  if (isExtended) {
    const allCourses = [...myCourses];

    if (isInstanceAdmin) {
      courses.forEach((generalCourse) => {
        if (!allCourses.find((course) => course.slug === generalCourse.slug)) {
          allCourses.push(generalCourse);
        }
      });
    }

    const courseStatusMap = allCourses.reduce((acc, course) => {
      const status = course.courseStatus?.toLowerCase() || 'registered';

      // Reassign status courses array to the acc object if it's already there otherwise default to empty array and set the key
      (acc[status] = acc[status] || []).push(course);
      return acc;
    }, {} as Record<string, Course[]>);

    const statusInfo: Record<string, { title: string; ariaLabel: string }> = {
      registered: { title: 'Not Started', ariaLabel: 'Not started courses' },
      active: { title: 'In Progress', ariaLabel: 'Courses in progress' },
      completed: { title: 'Completed', ariaLabel: 'Completed courses' },
    };

    const groupedCoursesByStatus = Object.entries(courseStatusMap)
      .map(([key, groupedCourses]) => {
        const info = statusInfo[key];
        return info ? { ...info, courses: groupedCourses } : null;
      })
      .filter(Boolean);

    return (
      <CourseDashboardContainer role='region' aria-label='Course Dashboard'>
        <CourseDashboardHeader role='banner'>
          <Breadcrumb
            breadcrumbTrail={[
              {
                title: 'Home',
                url: '/',
              },
            ]}
          />
          <MainTitle role='heading' aria-level={1}>
            Dashboard
          </MainTitle>
        </CourseDashboardHeader>
        {groupedCoursesByStatus.map(({ title, ariaLabel, courses: coursesArr }) => (
          <CardSection
            title={title}
            cardsLength={coursesArr?.length}
            role='list'
            aria-label={ariaLabel}
          >
            {coursesArr?.map((course) => {
              const courseStartDate = course?.courseSchedule?.scheduleStartDate || course.startDate;

              return <CourseCard course={course} startDate={courseStartDate} role='listitem' />;
            })}
          </CardSection>
        ))}
      </CourseDashboardContainer>
    );
  }

  return (
    <DashboardWrapper role='region' aria-label='Team dashboard'>
      {filteredCarouselItems.length > 0 && (
        <>
          <Title>{isAllTeams || isGeneralTeam ? '' : 'Team '}Dashboard </Title>
          <DashboardSubtitle>
            <DashboardCardCount aria-live='polite'>
              {Math.min(5, filteredCarouselItems.length)}{' '}
              {filteredCarouselItems.length === 1 ? 'card' : 'cards'} out of{' '}
              {filteredCarouselItems.length}
            </DashboardCardCount>
            {(isGeneralTeam || isAllTeams) && (
              <NooderaButton
                size='small'
                onClick={() => history.push('/dashboard')}
                role='button'
                aria-label='View all'
              >
                View All
              </NooderaButton>
            )}
          </DashboardSubtitle>
          <CardsContainer>{filteredCarouselItems.slice(0, 5)}</CardsContainer>
        </>
      )}
      <BecomePremiumModal
        isOpen={isOpenModal}
        price={price}
        icon={<PaidCourseIcon />}
        planDetails={paidPlanDetails}
        cardTitle='Premium'
        courseData={currentCourse}
        sectionId={currentSection?.id}
      />
      {renderHireTAModal()}
      <NooderaModal.Small
        isOpen={confirmationModal}
        icon={<SuccessIcon />}
        title='Your Session is Booked!'
        description='Feel free to reschedule the session at any point from your team dashboard.'
        btnText='Back to Home'
        onClose={handleConfirmationModal}
      />
      <NooderaModal.Small
        isOpen={isErrorModalOpen}
        icon={<ExclamationIcon />}
        imageContent={serverErrorAnim}
        title='The booking failed'
        description={errorModalDescription}
        btnText='Got It!'
        onClose={handleErrorModal}
      />
    </DashboardWrapper>
  );
};

export default SectionFeed;
