const THERE_WAS_AN_ERROR = 'There was an error';
export const PARTNER_INSTANCE = {
  privateLabelErrorMessage: `${THERE_WAS_AN_ERROR} fetching the list of invitations`,
  sendInviteErrorMessage: `${THERE_WAS_AN_ERROR} sending the invitations`,
  instanceAdminForbiddenInviteErrorMessage:
    'You do not have enough seats to add this many users, please contact your university admin.',
  universityAdminForbiddenInviteErrorMessage:
    'This corporate partner does not have enough seats remaining to add this many users, please make more seats available to this partner',
  tokenVerificationErrorMsg: `${THERE_WAS_AN_ERROR} verifying your invitation`,
  tokenVerificationSuccessMsg: `Your invitation was successfully verified`,
  sectionDetailsErrorMessge: `${THERE_WAS_AN_ERROR} retrieving data from the selected team`,
  partnerInstanceErrorMessage: 'There was an error fetching data.',
  updateSectionErrorMsg: 'There was an error fetching data.',
  invalidCSVErrMsg: 'Invalid CSV file. No emails found in the file.',
  fillAllFieldsErr: 'Please fill out all required fields',
  sectionNameLengthErrorMessage: 'Team name should be between 3 and 64 characters',
  partnerIdErrorMessage: 'An instance with this name already exists.',
  genericMemberCreationError: 'There was an error adding the member.',
  unknownError: 'An unknown error has occurred',
  numberNamingError: "Users' names cannot include numbers",
  lengthNamingError: "Users' names should be between 1 and 16 characters",
  existingMemberError: 'A user with this email has already been created',
  invalidEmailError: 'Invalid email',
  negativeSeatsError: "Number of seats can't be less than the number of active memberships",
};

export const PARTNER_INSTANCE_MODE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const ADMIN_ENROLLMENT_TYPE = {
  TEAM_ENROLLMENT: 'TEAM_ENROLLMENT',
  SOLO_LEARNER_ENROLLMENT: 'SOLO_LEARNER_ENROLLMENT',
};
