import { getTheme } from 'settings/theme/index';
// Retrieved flow types from
// https://flow.org/en/docs/react/types/#toc-react-statelessfunctionalcomponent
const { palette, fonts } = getTheme();

/*
  @description
  Background mixin for styling the background color of a button
  @param {string} bgColor background color in normal state
  @param {string} hoverColor background color in hover state
*/
export const backgroundColor = (bgColor: string, hoverColor: string): string => `
  background-color: ${bgColor};
  border-color: ${bgColor};
  &:hover {
    background-color: ${hoverColor};
    border-color: ${hoverColor}
  }
`;

/*
  @description
  Generic styles for social buttons
*/
export const socialButton = (): string => `
  border-radius: 0.6rem;
  color: ${palette.greyscale.white};
  font-family: ${fonts.primary};
  font-size: 1.6rem;
  font-weight: 600;
  height: 3.2rem;
  line-height: 2.4rem;
  width: 11.4rem;
   @media only screen and (max-width: 500px) {
     font-size: 1.4rem
     padding: 0 .2rem
  }
`;

/*
  @description
  Button Size mixin for applying styles for each size
  @param {size} size size of the button (small-large)
*/
export const buttonSize = (size: string): string => {
  let retStyle = '';

  if (size === 'small') {
    retStyle = `
      max-width: 7rem;
      font-size: 1.2rem;
      height: 2.4rem;
      padding: 0 .6rem;
    `;
  }

  if (size === 'standard') {
    retStyle = `
      font-size: 1.4rem;
      height: 3rem;
      padding: 0 1.2rem;
    `;
  }

  if (size === 'medium') {
    retStyle = `
      font-size: 1.6rem;
      height: 3.6rem;
      padding: 0 1.8rem;
    `;
  }

  if (size === 'large') {
    retStyle = `
      font-size: 1.8rem;
      height: 4.2rem;
      padding: 0 2.4rem;
    `;
  }

  return retStyle;
};
export const backgroundAndBorderColor = (bgColor: string, borderColor: string): string => `
    background: ${bgColor};
    border-color: ${borderColor};
`;
export const defaultButtonStyle = (): string => `
    border-radius: 0.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    height: 3rem;
    letter-spacing: -0.02rem;
    padding: 0 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 500px) {
      font-size: 1.6rem
      height: 2.4rem;
      padding: 0 .6em;
    }
`;
export const addButtonStyleByType = (type: string): string => `&.btn-${type} {
      ${backgroundColor(palette[type].default, palette[type][Object.keys(palette[type])[1]])};
      color: ${palette.greyscale.white};
  }`;
