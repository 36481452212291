import * as React from 'react';
import type { SectionInformationProps } from './SectionInformation.types';
import { Wrapper, ImageWrapper, TextWrapper, Title, Text } from './SectionInformation.styles';

const SectionInformation = ({
  icon,
  title,
  description,
  className,
}: SectionInformationProps): React.ReactElement<any> => (
  <Wrapper className={className}>
    <ImageWrapper>{icon}</ImageWrapper>
    <TextWrapper>
      <Title>{title}</Title>
      <Text>{description}</Text>
    </TextWrapper>
  </Wrapper>
);

export default SectionInformation;
