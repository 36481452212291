import * as React from 'react';
import { Country, State } from 'country-state-city';
import dashToCamelCase from 'utils/string/dash-to-camel-case';
import type { BillingProps } from './Billing.types';
import {
  Container,
  StyledNoodleSelect,
  StyledNoodleTextField,
  Title,
  Wrapper,
} from './Billing.styles';

const countries = Country.getAllCountries();
const parsedCountries = countries.map((country) => ({
  name: country?.name,
  value: country?.isoCode,
}));
const usStates = State.getStatesOfCountry('US');
const parsedUsStates = usStates.map((state) => ({
  name: state?.name,
  value: state?.isoCode,
}));

const Billing = ({ data, onChange, showState }: BillingProps): React.ReactElement<any> => {
  const handleInputChange = ({ currentTarget: { value, name } }): void => {
    const camelizedName = dashToCamelCase(name);
    onChange({ ...data, [camelizedName]: value });
  };

  const handleSelectChange = ({ target: { value, name } }): void => {
    const camelizedName = dashToCamelCase(name);
    onChange({ ...data, [camelizedName]: value });
  };

  return (
    <Wrapper>
      <Container>
        <Title>Billing Information</Title>
        <StyledNoodleTextField
          name='first-name'
          value={data?.firstName}
          variant='outlined'
          autoComplete='off'
          placeholder='First Name'
          onChange={handleInputChange}
        />
        <StyledNoodleTextField
          name='last-name'
          value={data?.lastName}
          variant='outlined'
          autoComplete='off'
          placeholder='Last Name'
          onChange={handleInputChange}
        />
        <StyledNoodleTextField
          name='address'
          value={data?.address}
          variant='outlined'
          autoComplete='off'
          placeholder='Address'
          onChange={handleInputChange}
        />
        <StyledNoodleSelect
          name='country'
          placeholder='Country'
          value={data?.country}
          items={parsedCountries}
          onChange={handleSelectChange}
        />
        {showState && (
          <StyledNoodleSelect
            name='state'
            placeholder='State'
            value={data?.state}
            items={parsedUsStates}
            onChange={handleSelectChange}
          />
        )}
        <StyledNoodleTextField
          name='city'
          value={data?.city}
          variant='outlined'
          autoComplete='off'
          placeholder='City'
          onChange={handleInputChange}
        />
      </Container>
    </Wrapper>
  );
};

export default Billing;
