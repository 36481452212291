// @ts-nocheck
import JsonApi from 'utils/jsonApi';
import get from 'lodash.get';
import type { SchedulerObject } from 'commons/types';

export default class Scheduler {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getScheduler(schedulerName: string): Promise<SchedulerObject> {
    if (schedulerName === 'default') {
      const response = await this.jsonApi.get('/calendar/timekit/default_calendar');
      // TODO: no id so "undefined" object properties
      const scheduler = get(response, 'entities.undefined.undefined.scheduler', {});
      return scheduler;
    }

    // For future taxonomy support
    const response = await this.jsonApi.get('/calendar/timekit/default_calendar');
    // TODO: no id so "undefined" object properties
    const scheduler = get(response, 'entities.undefined.undefined.scheduler', {});
    return scheduler;
  }

  async getAoIScheduler(slug: string): Promise<Record<string, any>> {
    const response = await this.jsonApi.get(`/interests/schedulers/${slug}`);
    const schedulerObj = get(response, 'entities.areaOfInterestScheduler', {});
    const scheduler = Object.values(schedulerObj)[0];
    return scheduler;
  }

  async getSchedulerList(): Promise<any[]> {
    const response = await this.jsonApi.get('/interests/schedulers');
    const schedulerListObject = get(response, 'entities.areaOfInterestScheduler', {});
    const schedulerList = Object.values(schedulerListObject);
    return schedulerList;
  }

  async getSchedulerAvailability(projectId: string): Promise<SchedulerObject> {
    const response = await this.jsonApi.post(
      '/calendar/timekit/availability',
      {
        projectId,
      },
      false,
    );
    return response;
  }

  async createBooking(bookingObj: any): Promise<SchedulerObject> {
    const response = await this.jsonApi.post('/calendar/timekit/bookings', bookingObj, false);
    const scheduler = get(response, 'entities.scheduler.1', {});
    return scheduler;
  }

  async getMeetings(sectionId: number): Promise<Record<string, any>> {
    const response = await this.jsonApi.get(
      `/calendar/timekit/sections/${sectionId}/bookings`,
      false,
    );
    return response;
  }
}
