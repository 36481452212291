export const getUsersTypes = {
  REQUEST: 'USERS_GET_REQUEST',
  SUCCESS: 'USERS_GET_SUCCESS',
  FAILED: 'USERS_GET_FAILED',
};
export const getUserByIdTypes = {
  REQUEST: 'USER_GET_BY_ID_REQUEST',
  SUCCESS: 'USER_GET_BY_ID_SUCCESS',
  FAILED: 'USER_GET_BY_ID_FAILED',
};
export const updateUserTypes = {
  REQUEST: 'USER_UPDATE_REQUEST',
  SUCCESS: 'USER_UPDATE_SUCCESS',
  FAILED: 'USER_UPDATE_FAILED',
};
export const updateUserRolesTypes = {
  REQUEST: 'USER_ROLE_UPDATE_REQUEST',
  SUCCESS: 'USER_ROLE_UPDATE_SUCCESS',
  FAILED: 'USER_ROLE_UPDATE_FAILED',
};
export const getUserRolesTypes = {
  REQUEST: 'USER_ROLE_GET_REQUEST',
  SUCCESS: 'USER_ROLE_GET_SUCCESS',
  FAILED: 'USER_ROLE_GET_FAILED',
};
