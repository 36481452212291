// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/uielements/Icon/index';
import * as React from 'react';

const {
  noodera: { fontTypes, spacing },
} = getTheme();
export const BreadcrumbLink = styled((props) => <Link {...props} />)`
  ${fontTypes.font5Medium};
  color: ${(props) => props.theme.palette.primary.hundred};
`;
export const BreadcrumbIcon = styled(Icon)`
  margin: ${spacing.x8};
  font-size: 0.8rem;
  font-weight: bold;
  vertical-align: middle;
`;
export const BreadcrumbIconMobile: StatelessFunctionalComponent<any> = styled(BreadcrumbIcon)`
  margin-left: 0;
  color: ${(props) => props.theme.palette.primary.hundred};
`;
export const MobileBreadcrumbContainer: StatelessFunctionalComponent<any> = styled.span`
  ${(props) => props.theme.underlineLink}
`;
