// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes },
  palette: { primary, greyscale },
} = getTheme();
export const StepperWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: 100%;
  & > div {
    :first-child {
      hr:first-of-type {
        opacity: 0;
      }
    }
    :last-child {
      hr:last-of-type {
        opacity: 0;
      }
    }
  }
`;
export const Circle: StatelessFunctionalComponent<any> = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  ${(props) =>
    props.complete ? `border: 1px solid ${greyscale.white};` : `background: ${greyscale.white};`}
`;
export const Step: StatelessFunctionalComponent<any> = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 60px;
`;
export const LabelContainer: StatelessFunctionalComponent<any> = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;
  color: ${greyscale.white};
  text-align: center;
  overflow: hidden;
  ${fontTypes.font6Medium}
  text-transform: uppercase;
`;
export const Line: StatelessFunctionalComponent<any> = styled.hr`
  width: calc(50% - 8px);
  height: 1px;
  background-color: ${(props) => (props.complete ? `${greyscale.white};` : `${primary.sixty}`)};
  border-color: ${(props) => (props.complete ? `${greyscale.white};` : `${primary.sixty}`)};
`;
