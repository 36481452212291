import moment from 'moment';
import type { ValidationFunctionParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a date is valid using momentjs
 * Pre: if the value is '' it won't have errors
 */
const isValidDate = ({
  name,
  value,
  outputName,
}: ValidationFunctionParams): ValidationFieldError => {
  const errors = {};
  errors[name] =
    value !== '' && !moment(value, 'MM/DD/YYYY').isValid()
      ? {
          errorMsg: `Not a valid ${outputName}`,
        }
      : {};
  return errors;
};

export default isValidDate;
