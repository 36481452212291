// @ts-nocheck
import * as React from 'react';
import { globalSearchConstants } from 'commons/constants';
import { useWidth } from 'utils/customHooks';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';
import { getTheme } from 'settings/theme';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as SearchNavbarIcon } from 'assets/svg/search_navbar.svg';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import SearchBarTopNavWrapper, { ContainerSearchButtonTopBar } from './SearchBarTopNav.style';
import type { SearchBarProps } from './SearchBarTopNav.types';

const {
  palette: { primary },
} = getTheme();

const SearchBarTopNav = ({
  autoFocus,
  placeholder,
  search = '',
  onSearch,
  onInputChange,
  onCleanSearch,
  maxLength = 255,
  // TODO: Fix this... don't want to touch class components
  // so just doing stupid property here
  hideMobile = true,
}: SearchBarProps) => {
  const width = useWidth();
  const [error, setError] = React.useState<string>('');

  const enterHandler = (e: KeyboardEvent, onSearchFunc: (...args: Array<any>) => any) => {
    if (search.length >= globalSearchConstants.SEARCH_MIN_LENGTH && e.key === 'Enter') {
      onSearchFunc();
    } else if (search.length < globalSearchConstants.SEARCH_MIN_LENGTH) {
      setError(globalSearchConstants.SEARCH_LENGTH_ERROR);
    }
  };

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (error && error.length) {
      setError('');
    }

    if (maxLength && e.target.value.length >= maxLength) {
      setError('Too many characters');
    }

    onInputChange(e);
  };

  const handleCleanSearch = () => {
    if (error && error.length) {
      setError('');
    }

    onCleanSearch();
  };

  return hideMobile && width < TABLET_SMALLER_WIDTH ? null : (
    <div>
      <SearchBarTopNavWrapper>
        <div className='sb__wrapper_left'>
          <input
            autoFocus={autoFocus}
            type='text'
            value={search}
            placeholder={placeholder}
            className='sb__input'
            onChange={handleChange}
            onKeyPress={(e) => enterHandler(e, onSearch)} // what's wrong with you HTML. Doesn't resize small enough for mobile if
            // I don't add size={5}
            size={5}
          />
        </div>
        <div className='sb__wrapper_right'>
          <span>{error}</span>
          <ContainerSearchButtonTopBar>
            {search ? (
              <button
                className='sb__close-btn'
                onClick={handleCleanSearch}
                onKeyPress={handleCleanSearch}
                type='button'
              >
                <SvgImageWrapper color={primary.twenty}>
                  <CloseIcon />
                </SvgImageWrapper>
              </button>
            ) : (
              <button
                className='sb__search-btn'
                onClick={onSearch}
                onKeyPress={onSearch}
                type='button'
              >
                <SvgImageWrapper color={primary.twenty}>
                  <SearchNavbarIcon />
                </SvgImageWrapper>
              </button>
            )}
          </ContainerSearchButtonTopBar>
        </div>
      </SearchBarTopNavWrapper>
    </div>
  );
};

export default SearchBarTopNav;
