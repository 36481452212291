import type { QuestionData, QuestionState } from 'commons/types/onboardingTypes';
import {
  getOnboardingQuestionTypes,
  getOnboardingOccupationTypes,
  insertAnswerTypes,
  getOnboardingAnswersTypes,
} from './types';

export const initialState: QuestionState = {
  answers: {
    isLoading: false,
    data: [],
    error: '',
  },
  insert: {
    isLoading: false,
    data: [],
    error: '',
  },
  occupations: {
    isLoading: false,
    data: [],
    error: '',
  },
  question: {
    isLoading: false,
    data: [],
    error: '',
  },
};
type Action = {
  type: string;
  payload?: QuestionData[];
};

const onboardingReducer = (state: QuestionState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_REQUEST: {
      return { ...state, question: { ...initialState.question, isLoading: true } };
    }

    case getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_SUCCESS: {
      return {
        ...state,
        question: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_FAILURE: {
      return { ...state, question: { ...initialState.question, error: payload } };
    }

    case getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_REQUEST: {
      return { ...state, occupations: { ...initialState.occupations, isLoading: true } };
    }

    case getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_SUCCESS: {
      return {
        ...state,
        occupations: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_FAILED: {
      return { ...state, occupations: { ...initialState.occupations, error: payload } };
    }

    case insertAnswerTypes.INSERT_ANSWER_REQUEST: {
      return {
        ...state,
        answers: {
          data: [...(state.answers.data || []), payload],
        },
        insert: { ...initialState.insert, isLoading: true },
      };
    }

    case insertAnswerTypes.INSERT_ANSWER_SUCCESS: {
      return {
        ...state,
        insert: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case insertAnswerTypes.INSERT_ANSWER_FAILURE: {
      return { ...state, insert: { ...initialState.insert, error: payload } };
    }

    case getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_REQUEST: {
      return { ...state, answers: { ...initialState.answers, isLoading: true } };
    }

    case getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_SUCCESS: {
      return {
        ...state,
        answers: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_FAILURE: {
      return { ...state, answers: { ...initialState.answers, error: payload } };
    }

    default:
      return state;
  }
};

export default onboardingReducer;
