// @ts-nocheck
import * as React from 'react';
import { useSelector } from 'react-redux';
import getNavLinksPerRole from 'utils/navigation/getNavLinksPerRole';
import { useIsInstanceAdmin, useIsUniversityAdmin } from 'utils/customHooks';
import NavbarSeparator from 'components/NavbarSeparator/index';
import { getPartnerId } from 'utils/privateLabel/index';
import NavLink from 'components/NavLink/index';

import { MobileNavbarContainer } from './MobileNavbar.styles';

const MobileNavbar = (): React.ReactElement<any> => {
  const selected: string = useSelector((state) => state.router.location.pathname);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isInstanceAdmin = useIsInstanceAdmin();
  const isUniversityAdmin = useIsUniversityAdmin();
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const partnerMicrosite = globalPartner?.micrositeLandingPageUrl;
  const partnerMicrositeEnabled = globalPartner?.isMicrositeEnabled;
  const pathname = useSelector((state) => state.router.location.pathname);
  const partnerId = getPartnerId() || globalPartner?.partnerId;

  const renderItem = (item: any): React.ReactElement<any> => {
    const linkPathname = item?.path || '';

    if (isInstanceAdmin && pathname.includes('/instance')) {
      item.path = linkPathname.replace(':partnerId', partnerId);
    }

    if (item.name === 'separator') {
      return <NavbarSeparator.Mobile key='nav-separator' />;
    }

    return (
      <NavLink.Mobile
        key={item.name}
        selected={item.name !== 'Home' ? selected.includes(pathname) : selected === pathname}
        item={item}
      />
    );
  };

  const renderLinks = (): React.ReactElement<any>[] => {
    let navLinks = getNavLinksPerRole(
      isInstanceAdmin && pathname.includes('/instance') ? 'admin' : null,
      isUniversityAdmin,
    );

    if (
      !isLoggedIn &&
      partnerMicrosite &&
      partnerMicrositeEnabled &&
      pathname.includes('/courses')
    ) {
      navLinks = navLinks.filter((navLink) => navLink.name !== 'Home');
    }

    return navLinks.map(renderItem);
  };

  return <MobileNavbarContainer>{renderLinks()}</MobileNavbarContainer>;
};

export default MobileNavbar;
