import React from 'react';
import { ReactComponent as PersonalizeYourFeedIcon } from 'assets/svg/personalize_your_feed.svg';
import { useHistory } from 'react-router';
import BaseCard from '../BaseCard';
import { EditProfileTitle, EditProfileLink } from './EditProfileCard.styles';

const EditProfileCard = () => {
  const history = useHistory();

  return (
    <BaseCard icon={<PersonalizeYourFeedIcon />}>
      <EditProfileTitle>Update Your Profile</EditProfileTitle>
      <EditProfileLink
        onClick={() => history.push('/onboarding')}
        role='button'
        aria-label='Edit questions and answers'
        tabIndex={0}
      >
        Edit Questions and Answers
      </EditProfileLink>
    </BaseCard>
  );
};

export default EditProfileCard;
