// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setSessionStorageData, removeSessionStorageData } from 'utils/sessionStorage';
import alertActions from 'ducks/alert/actions';
import { partnerInstanceActions } from 'ducks/partnerInstance/actions';
import { alertTypesConstants } from 'commons/constants';
import { COURSE, ENROLL_COURSE_PATH } from 'commons/constants/course';
import type { GetCoursePayload } from 'commons/types/courseTypes';
import { LMS } from 'commons/types/courseTypes';
import api from 'lib/Api';
import { downloadFile } from 'utils/browser/index';
import { isNonNoodleInstance } from 'utils/privateLabel';
import { sectionActions } from 'ducks/section/actions';
import { courseActions, enrollActions } from './actions';
import {
  getCoursePreviewTypes,
  enrollTypes,
  getMyCoursesTypes,
  getCoursesTypes,
  getSavedCoursesTypes,
  getEnrolledSectionsTypes,
  unsaveCourseTypes,
  getCertificateTypes,
} from './types';

const { SUCCESS, DANGER } = alertTypesConstants;
export function* enrollCourseSaga(): Saga {
  try {
    const isLoggedIn = yield select((state) => state.auth.isLoggedIn);

    if (isLoggedIn) {
      yield put(enrollActions.enrollModalSuccess());
    } else {
      const pathname = yield select((state) => state.router.location.pathname);
      setSessionStorageData(ENROLL_COURSE_PATH, pathname);
      yield put(push('/signup'));
    }
  } catch (error) {
    console.error('error', error);
  }
}
export function* enrollCourseCleanupSaga(): Saga {
  removeSessionStorageData(ENROLL_COURSE_PATH);
  if (isNonNoodleInstance) {
    yield put(sectionActions.resetJoinSelfEnrolledSection());
    yield put(partnerInstanceActions.resetAddMembersToSection());
  } else {
    yield put(sectionActions.resetJoinSection());
  }
}
export function* getMyCoursesSaga({ payload }: { payload: any }): Saga<void> {
  try {
    const response = yield call([api.course, 'getMyCourses'], payload);
    yield put(courseActions.getMyCoursesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: COURSE.coursesErrorMessage,
        type: DANGER,
      }),
    );
    yield put(courseActions.getMyCoursesFailure(error));
  }
}
export function* getCoursesSaga(): Saga<void> {
  try {
    const response = yield call([api.course, 'getCourses']);
    yield put(courseActions.getCoursesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: COURSE.coursesErrorMessage,
        type: DANGER,
      }),
    );
    yield put(courseActions.getCoursesFailure(error));
  }
}
export function* getSavedCoursesSaga(): Saga<void> {
  try {
    const response = yield call([api.course, 'getSavedCourses']);
    yield put(courseActions.getSavedCoursesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: COURSE.coursesErrorMessage,
        type: DANGER,
      }),
    );
    yield put(courseActions.getSavedCoursesFailure(error));
  }
}

export function* getEnrolledSectionsSaga({ payload }: any): Saga<void> {
  try {
    const response = yield call([api.course, 'getEnrolledSections'], payload);
    yield put(enrollActions.getEnrolledSectionsSuccess(response));
  } catch (error) {
    yield put(enrollActions.getEnrolledSectionsFailure(error));
  }
}

export function* getCoursePreviewSaga({ payload }: GetCoursePayload): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const response = yield call([api.course, 'getCoursePreview'], {
      courseSlug: payload?.slug,
      userId,
      excludeAttributes: payload?.excludeAttributes,
    });

    const lmsCourseIdMapping = {
      [LMS.Brightspace]: { brightspaceOrgUnitId: response?.brightspaceOrgUnitId },
      [LMS.Moodle]: { moodleCourseId: response?.moodleCourseId },
      [LMS.Canvas]: { canvasCourseId: response?.canvasCourseId },
    };

    if (lmsCourseIdMapping[response?.lms]) {
      yield put(
        enrollActions.getEnrolledSectionsRequest({
          ...lmsCourseIdMapping[response?.lms],
          lms: response.lms,
        }),
      );
    }

    yield put(courseActions.getCoursePreviewSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: COURSE.coursesErrorMessage,
        type: DANGER,
      }),
    );
    yield put(courseActions.getCoursePreviewFailure(error.message));
    yield put(push('/404'));
  }
}

// TODO: Refactor this to accept any kind of contentful entry.
export function* removeCourseSaga(payload: { payload: number; type: string }): Saga<void> {
  const { payload: saveContentId } = payload;

  try {
    yield call([api.course, 'removeCourse'], saveContentId);
    yield put(
      alertActions.setAlert({
        message: COURSE.deleteCourseSuccessMessage,
        type: SUCCESS,
      }),
    );
    yield put(courseActions.removeCourseSuccess());
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: COURSE.deleteCourseErrorMessage,
        type: DANGER,
      }),
    );
    yield put(courseActions.removeCourseFailure(error));
  }
}
export function* getCertificateSaga({ payload }: { payload: string; type: string }): Saga<void> {
  try {
    const response = yield call([api.course, 'getCertificateByUUID'], payload);
    yield put(courseActions.getCertificateSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.message,
        type: DANGER,
      }),
    );
    yield put(courseActions.getCertificateFailure(error.message));
  }
}
export function* downloadCertificateSaga({
  payload,
}: {
  payload: string;
  type: string;
}): Saga<void> {
  try {
    const response = yield call([api.course, 'downloadCertificate'], payload);
    yield call(downloadFile, response, `Certificate.pdf`);
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.message,
        type: DANGER,
      }),
    );
  }
}
export default function* coursesSaga(): Saga<void> {
  yield takeLatest(getCoursePreviewTypes.GET_COURSE_PREVIEW_REQUEST, getCoursePreviewSaga);
  yield takeLatest(enrollTypes.ENROLL_MODAL_REQUEST, enrollCourseSaga);
  yield takeLatest(enrollTypes.ENROLL_MODAL_RESET, enrollCourseCleanupSaga);
  yield takeLatest(getMyCoursesTypes.GET_MY_COURSES_REQUEST, getMyCoursesSaga);
  yield takeLatest(getCoursesTypes.GET_COURSES_REQUEST, getCoursesSaga);
  yield takeLatest(getSavedCoursesTypes.GET_SAVED_COURSES_REQUEST, getSavedCoursesSaga);
  yield takeLatest(getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_REQUEST, getEnrolledSectionsSaga);
  yield takeLatest(unsaveCourseTypes.UNSAVE_COURSE_REQUEST, removeCourseSaga);
  yield takeLatest(getCertificateTypes.GET_CERTIFICATE_REQUEST, getCertificateSaga);
  yield takeLatest(getCertificateTypes.DOWNLOAD_CERTIFICATE, downloadCertificateSaga);
}
