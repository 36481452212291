// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import ImagePicker from 'components/ImagePicker';

const {
  noodera: { fontTypes, spacing },
} = getTheme();
export const TeamImageContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
`;
export const StyledTitle: StatelessFunctionalComponent<any> = styled.h3`
  ${fontTypes.font4Medium};
  margin-bottom: ${spacing.x6};
`;
export const StyledImagePicker: StatelessFunctionalComponent<any> = styled(ImagePicker)`
  && input {
    ::placeholder {
      text-transform: none;
    }
  }
`;
