import { getCounselorTypes } from './types';

export const counselorActions = {
  getCounselorRequest: (payload: string) => ({
    type: getCounselorTypes.REQUEST,
    payload,
  }),
  getCounselorSuccess: (payload: any) => ({
    type: getCounselorTypes.SUCCESS,
    payload,
  }),
  getCounselorFailed: (payload: string) => ({
    type: getCounselorTypes.FAILED,
    payload,
  }),
};
