// @ts-nocheck
import { likePostTypes } from 'ducks/like/types';
import { groupsTabs } from 'commons/constants';
import {
  getGroupsTypes,
  getGroupByIdTypes,
  joinGroupTypes,
  leaveGroupTypes,
  postGroupTypes,
  getGroupPostsTypes,
} from './types';

const { BROWSE, MY_GROUPS } = groupsTabs;
const initialState = {
  [BROWSE]: {},
  [MY_GROUPS]: {},
  isLoading: false,
  groupsIsMember: [],
  group: {},
};

const myGroups = (groups, state) => {
  const { groupsIsMember = [] } = state;

  // We get the groups member array updated
  if (groups.length > 0) {
    groups.forEach((group) => {
      if (group && group.connected && !groupsIsMember.includes(group.id)) {
        groupsIsMember.push(group.id);
      }
    });
  }

  return groupsIsMember;
};

const onLikeUpdate = (state, action, isLikedByUser, diff) => {
  let newState = state;

  if (state && state.group && state.group.feed) {
    const { id, likeId } = action.payload;
    const { feed = [] } = state.group;
    newState = {
      ...state,
      group: {
        ...state.group,
        feed: feed.map((item) =>
          item.id === id || item.postId === id
            ? {
                ...item,
                post: {
                  ...item.post,
                  likeId: likeId || item.post.likeId,
                  likesCount: (item.post.likesCount || 0) + diff,
                  likedByUser: isLikedByUser,
                },
              }
            : item,
        ),
      },
    };
  }

  return newState;
};

const groupsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getGroupByIdTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getGroupsTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case joinGroupTypes.REQUEST:
    case leaveGroupTypes.REQUEST: {
      return { ...state, isLoadingJoinLeave: true };
    }

    case getGroupByIdTypes.SUCCESS: {
      return { ...state, group: { ...payload, feed: [] }, isLoading: false };
    }

    case getGroupsTypes.SUCCESS: {
      const { tab, count, list, currentPage } = payload;
      const groupsIsMember = myGroups(list, state);
      return {
        ...state,
        [tab]: { ...state[tab], list, count, currentPage },
        isLoading: false,
        groupsIsMember,
      };
    }

    case getGroupPostsTypes.SUCCESS:
    case postGroupTypes.SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          feed: [...payload.feed, ...state.group.feed],
          lastUpdate: payload.lastUpdate,
        },
      };
    }

    case joinGroupTypes.SUCCESS: {
      const { groupsIsMember = [], group: groupById = {} } = state;
      const leaveJoinFlag = true;

      if (!groupsIsMember.includes(payload)) {
        groupsIsMember.push(payload);
      }

      if (groupById && groupById.id && payload && groupById.id.toString() === payload.toString()) {
        groupById.connected = leaveJoinFlag;
      }

      return {
        ...state,
        isLoadingJoinLeave: false,
        groupsIsMember: [...groupsIsMember],
        group: { ...groupById },
      };
    }

    case leaveGroupTypes.SUCCESS: {
      const { groupsIsMember = [], group: groupById = {} } = state;
      const leaveJoinFlag = false;

      if (groupById && groupById.id && payload && groupById.id.toString() === payload.toString()) {
        groupById.connected = leaveJoinFlag;
      }

      return {
        ...state,
        isLoadingJoinLeave: false,
        groupsIsMember: groupsIsMember.filter((g) => g !== payload),
        group: { ...groupById },
      };
    }

    case likePostTypes.SUCCESS: {
      return onLikeUpdate(state, action, true, 1);
    }

    case likePostTypes.DELETE_SUCCESS: {
      return onLikeUpdate(state, action, false, -1);
    }

    case getGroupPostsTypes.FAILED:
    case getGroupByIdTypes.FAILED:
    case getGroupsTypes.FAILED:
    case postGroupTypes.FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case joinGroupTypes.FAILED:
    case leaveGroupTypes.FAILED: {
      return { ...state, error: payload, isLoadingJoinLeave: false };
    }

    default:
      return state;
  }
};

export default groupsReducer;
