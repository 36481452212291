import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  palette: { greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();

export const Container = styled.div<{ orientation: string }>`
  display: inline-flex;
  align-items: center;
  flex-direction: ${(props) => (props.orientation === 'right' ? 'row-reverse' : 'row')};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-left: 15px;
  }
`;
export const LogoContainer = styled.div<{ orientation: string }>`
  display: inline-block;
  width: ${spacing.x2};
  height: ${spacing.x2};
  margin-right: ${spacing.x6};
  margin-left: ${(props) => (props.orientation === 'right' ? spacing.x6 : 0)};
`;
export const DescriptionContainer = styled.div<{ orientation: string }>`
  display: inline-block;
  padding: ${spacing.x9} 0;
  text-align: ${(props) => (props.orientation === 'right' ? 'right' : 'left')};

  & > span {
    color: ${greyscale.white};
  }
`;
export const Title = styled.span`
  ${fontTypes.font6MediumCaps};
  text-transform: uppercase;
  margin-bottom: ${spacing.x7};
`;
export const Name = styled.span`
  ${fontTypes.font5};
  line-height: 20px;
`;
export const StyledImage = styled.img<{ isCircle?: boolean }>`
  width: 48px;
  height: 48px;
  clip-path: ${(props) => (props.isCircle ? 'circle()' : 'none')};
`;
