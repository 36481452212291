import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionFeed from 'components/SectionFeed';
import { useIsAdmin, useIsInstanceAdmin } from 'utils/customHooks';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { courseActions } from 'ducks/courses/actions';
import { surveyActions } from 'ducks/survey/actions';

const GetSectionFeed = ({ isExtended = false }): React.ReactElement<{ isExtended?: boolean }> => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const [showEditProfileCard, setShowEditProfileCard] = React.useState(!isAdmin);
  const isPremium = useSelector((state) => state.section.preview.data?.isPremium);
  const currentCourse = useSelector((state) => state.courses.preview.data);
  const meetings = useSelector((state) => state.scheduler.meetings);
  const { data: myCourses, isLoading: isMyCoursesLoading } = useSelector(
    (state) => state.courses.myCourses,
  );
  const { data: courses } = useSelector((state) => state.courses.courses);
  const selectedSection = useSelector((state) => state.section.selectedSection || null);
  const premiumPrice = currentCourse?.priceInCents / 100;
  const isInstanceAdmin = useIsInstanceAdmin();

  const {
    data: onboardingSurvey,
    isLoading: onboardingSurveyIsLoading,
    error: onboardingSurveyError,
  } = useSelector((state) => state.survey?.onboardingSurvey);

  React.useEffect(() => {
    if (!isMyCoursesLoading) {
      dispatch(courseActions.getMyCoursesRequest({ include: 'course_progress' }));
    }
  }, []);

  React.useEffect(() => {
    if (isInstanceAdmin) {
      dispatch(courseActions.getCoursesRequest());
    }
  }, [isInstanceAdmin]);

  React.useEffect(() => {
    if (
      globalPartner?.hasOnboardingSurvey &&
      isNonNoodleInstance &&
      !onboardingSurvey &&
      !onboardingSurveyIsLoading &&
      !onboardingSurveyError
    ) {
      dispatch(surveyActions.getOnboardingSurveyRequest());
    }
  }, []);

  React.useEffect(() => {
    if (!globalPartner?.hasOnboardingSurvey || onboardingSurveyError !== null) {
      setShowEditProfileCard(false);
    }
  }, [onboardingSurveyError, globalPartner]);

  return (
    <div aria-live='polite'>
      <SectionFeed
        isPremium={isPremium}
        myCourses={myCourses}
        courses={courses}
        currentCourse={currentCourse}
        meetings={meetings}
        currentSection={selectedSection}
        price={premiumPrice}
        showEditProfileCard={showEditProfileCard}
        isExtended={isExtended}
      />
    </div>
  );
};

export default GetSectionFeed;
