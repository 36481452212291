import * as React from 'react';
import NavbarWrapper from './Navbar.style';
import type { NavbarProps } from './Navbar.types';

class Navbar extends React.Component<NavbarProps> {
  static Left = ({ children, className }: NavbarProps) => (
    <div className={className}>{children}</div>
  );

  static Center = ({ children, className }: NavbarProps) => (
    <div className={className}>{children}</div>
  );

  static Right = ({ children, className = '' }: NavbarProps) => (
    <div className={`${className}`}>{children}</div>
  );

  static Icon = ({ children, style }: NavbarProps) => (
    <div className='navbar__icon' style={style}>
      {children}
    </div>
  );

  render() {
    const { children } = this.props;
    return (
      <NavbarWrapper>
        <div className='navbar__content'>{children}</div>
      </NavbarWrapper>
    );
  }
}

export default Navbar;
