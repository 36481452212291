// @ts-nocheck
/* eslint-disable class-methods-use-this */

/* eslint-disable no-useless-catch */
import { connect } from 'getstream';
import { camelizeKeys } from 'humps';
import get from 'lodash.get';
// noinspection ES6PreferShortImport
import { getStreamConfig } from 'settings/index';
import JsonApi from 'utils/jsonApi';
// eslint-disable-next-line import/no-cycle
import { axios } from '../index';

const getStreamConnect = (token: string) =>
  connect(getStreamConfig.apiKey, token, getStreamConfig.appId);

const POSTS_PER_CALL = 10;
const COMMENTS_PER_CALL = 2;
export default class GetStream {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getUserToken(): Promise<string> {
    const tokenObj = await this.jsonApi.get('/stream/token/user');
    return get(tokenObj, 'token', '');
  }

  // Just taking activityId for now, maybe we can extend it for pagination etc in the future.
  async getCommentsByActivity({ activityId, lastCommentId }: Record<string, any>): Promise<string> {
    try {
      const userToken: string = localStorage.getItem('getStreamUserToken') ?? '';
      const client = getStreamConnect(userToken);
      const comments = await client.reactions.filter({
        activity_id: activityId,
        kind: 'comment',
        limit: COMMENTS_PER_CALL,
        id_lt: lastCommentId || '',
      });
      return { ...comments, activityId };
    } catch (err) {
      throw err;
    }
  }

  async getUserFeedPosts({ idLt }: Record<string, any>): Promise<any> {
    try {
      const response = await this.jsonApi.get(
        `/stream/users/activities?limit=${POSTS_PER_CALL}&id_lt=${idLt || ''}`,
      );
      const results = response?.results
        ?.filter((item) => item.verb === 'post')
        .map((post) => ({
          ...post,
          actor: camelizeKeys(post?.actor),
          comments: {
            results: post?.latest_reactions?.comment?.slice(0, 2) || [],
          },
          time: post?.time.replace(/\+.*/, ''),
        }));
      return { ...response, results };
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 52 ~ GetStream ~ err', err);
      throw err;
    }
  }

  async getTeamFeedPosts({ teamId, idLt }: Record<string, any>): Promise<any> {
    try {
      const response = await axios.get(
        `/stream/teams/${teamId}/activities?limit=${POSTS_PER_CALL}&id_lt=${idLt || ''}`,
      );
      const results = response?.data?.results
        ?.filter((item) => item.verb === 'post')
        .map((post) => ({
          ...post,
          actor: camelizeKeys(post?.actor),
          comments: {
            results: post?.latest_reactions?.comment?.slice(0, 2) || [],
          },
          time: post?.time.replace(/\+.*/, ''),
        }));
      return { ...response.data, results };
    } catch (err) {
      throw err;
    }
  }

  async createPost({ teamId, activity }: Record<string, any>): Promise<any> {
    try {
      const { attachments, object } = activity;
      const apiPayload = {
        attachments,
        object,
      };
      return await axios.post(`/stream/teams/${teamId}/posts`, apiPayload, false);
    } catch (err) {
      throw err;
    }
  }

  // TODO: Improve typings after Demo.
  async getUserNotifications(userId: number): Promise<any> {
    try {
      const userToken: string = localStorage.getItem('getStreamUserToken') ?? '';
      const client = getStreamConnect(userToken);
      const feed = client.feed('notification', userId.toString());
      return await feed.get();
    } catch (err) {
      throw err;
    }
  }
}
