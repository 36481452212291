import { generateDropdownNumberRange } from 'components/uielements/Dropdown/utils';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import type { DropdownItem } from '../types';

export const imagesUrl = `${process.env.REACT_APP_CLOUDFRONT_URL || ''}/logos/schools`;
export const types = {
  EXTERNAL: 'external',
  GROUPS: 'groups',
  LANDING_PAGE: 'landingPage',
  PROFILE: 'profile',
  SCHOOLS: 'schools',
  SCHOOL: 'school',
};
export const CALENDLY_EVENTS = {
  DATE_TIME_SELECTED: 'calendly.date_and_time_selected',
  EVENT_SCHEDULED: 'calendly.event_scheduled',
  EVENT_TYPE_VIEWED: 'calendly.event_type_viewed',
};
export const NOODLE_URL = 'noodle.com';
const REACT_APP_CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL || '';
export const requestInfoDisclaimer = (
  schoolName: string,
): string => `By submitting this form, you agree that a representative of ${schoolName},
  Noodle Education Inc., and/or its Education Network
  can contact you at this number and e-mail address provided -
  potentially using automated technology (including text/SMS messages) or a prerecorded message.
  Your consent is not an obligation to purchase any educational programs or services.`;
export const SUCCESS = 'SUCCESS';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const CURRENT_YEAR: number = new Date().getFullYear();
export const DATE_FORMAT = 'MM/DD/YYYY';
export const EDUCATION_LEVELS: DropdownItem[] = [
  {
    value: 'HS',
    label: 'HS Diploma or GED',
  },
  {
    value: 'SOME_0_23',
    label: 'Some College (0-23 credits)',
  },
  {
    value: 'SOME_24_47',
    label: 'Some College (24-47 credits)',
  },
  {
    value: 'AS',
    label: 'Associate Degree',
  },
  {
    value: 'BS',
    label: "Bachelor's Degree",
  },
  {
    value: 'MS',
    label: "Master's Degree",
  },
  {
    value: 'PHD',
    label: 'Doctoral Degree',
  },
];
export const GRADUATE_SCHOOL = 'GRADUATE_SCHOOL';
export const EDUCATION_TYPES = {
  GRADUATE_SCHOOL: 'Graduate',
  UNDERGRAD: 'Undergraduate',
  OTHER: 'Other',
};
export const FLAG_INAPPROPRIATE_MAIL_TO = 'contentflag@noodle.com';
export const GED_YEARS: DropdownItem[] = generateDropdownNumberRange(1950, CURRENT_YEAR);
export const GENERIC_ERROR = 'An error has been ocurred, please try again';
export const MAX_BIRTHDATE = `12/31/${CURRENT_YEAR}`;
export const MIN_BIRTHDATE = '01/01/1900';
export const MAX_GED_YEAR: number = CURRENT_YEAR + 5;
export const PAGINATION_LIMIT = 25;
export const SEARCH_MIN_LENGTH = 2;
export const NOODLE_DEFAULT_IMAGE = `${REACT_APP_CLOUDFRONT_URL}/defaults/noodle_default_image.jpg`;
export const NOODLE_DEFAULT_IMAGE_MOBILE = `${REACT_APP_CLOUDFRONT_URL}/defaults/noodle_default_image_mobile.jpg`;
export const OPERATIING_SYSTEMS = {
  IPHONE: 'iPhone',
};
export const SPACING_TAG = '<p/>';
export const globalSearchConstants = {
  SEARCH_LENGTH_ERROR: `Enter at least ${SEARCH_MIN_LENGTH} characters`,
  SEARCH_MIN_LENGTH,
};
// export const hamburgerMenuConstants = {
//   TITLE: `${companyName} is all about you!`,
//   SUB_TITLE: 'You need to create a profile to see your tailored content',
// };
export const getSignupTerms = (termsOfUseLink?: string) => {
  if (isNonNoodleInstance) {
    return termsOfUseLink
      ? `I have read and agreed to the
    <a style="cursor: pointer;" href=${termsOfUseLink}  rel='noopener nofollow noreferrer'>terms and conditions</a>
    `
      : SPACING_TAG;
  }
  const { signup } = getAppSettings();

  return `<div>${signup.terms} & ${signup.privacy}</div><p>${signup.text}</p>`;
};

export const notFoundConstants = {
  NOT_FOUND: '404',
  TEXT: 'Looks like this page is playing hooky today.',
};
export const defaultUserProfile = {
  firstName: 'No Name',
  lastName: '',
  picture: '',
  termsOfService: false,
};
export const paginationConstants = {
  GLOBAL_SEARCH_PER_PAGE_WEB: 20,
  GLOBAL_SEARCH_PER_PAGE_MOBILE: 20,
};
export const keyCodeConstants = {
  ENTER: 13,
  ARROW_DOWN: 40,
  ARROW_UP: 38,
};
export const debounceConstants = {
  GET_GROUPS_DELAY: 300,
  GET_EXPERTS_DELAY: 300,
  GET_SCHOOLS_DELAY: 300,
  ONBOARDING_SELECT_OPTION_DELAY: 300,
  POST_ROI_DATA_DELAY: 300,
  SEARCH_DELAY: 300,
};
export const AVATAR_TYPES = {
  SCHOOL: 'School',
  GROUP: 'Group',
};
export const alertIconsConstants = {
  CHECK: 'check',
  EXCLAMATION: 'exclamation',
};
export const alertTypesConstants = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
};
export const buttonConstants = {
  BUTTON: 'button',
  CTA: 'CTA',
  MEDIUM: 'medium',
};
export const robotsConstants = {
  NOINDEX_FOLLOW: 'noindex, follow, max-image-preview:large',
  NOINDEX_NOFOLLOW: 'noindex, nofollow',
  INDEX_FOLLOW: 'index, follow, max-image-preview:large',
};
export const relConstants = {
  NOREFERRER_NOOPENER_NOFOLLOW: 'noreferrer noopener nofollow',
};
export const accessibilityConstants = {
  globalSearchLink: {
    title: 'Search',
    ariaLabel: 'Search',
  },
  menu: {
    title: 'Menu',
    ariaLabel: 'Menu',
  },
  closeMenu: {
    title: 'Close Menu',
    ariaLabel: 'Close Menu',
  },
  getLoginHomeLink(companyName: string) {
    return {
      title: `Go to ${companyName} homepage`,
      ariaLabel: `Go to ${companyName} homepage`,
    };
  },
  notifications: {
    title: 'Notifications',
    ariaLabel: 'Notifications',
  },
};
export const mainTitleAllowedRoutes = [
  '/courses/browse',
  '/courses/saved',
  '/teams/browse',
  '/teams/saved',
];

export const CHROME_COOKIE_MAX_AGE = 1707109200;
// 86400 seconds = 1 day
export const AUTH0_DEFAULT_SESSION_AGE = 86400;
