import styled from 'styled-components';
import type { StyleWrapperProps } from 'commons/types';

const LoaderWrapper = styled.div<StyleWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 2.5rem;

  img {
    animation: fadeInOut 2s infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export default LoaderWrapper;
