// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { noodle },
} = getTheme();
const ScrollSliderWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;

  & * {
    flex-shrink: 0;
  }

  .scroll-slider {
    &__container {
      &::-webkit-scrollbar {
        display: none;
      }

      display: flex;
      flex: 1;
      align-items: center;
      overflow-y: hidden;
      white-space: nowrap;
      scroll-behavior: smooth;
      position: relative;
    }

    &__children {
      margin: auto;
    }

    &__navigation-icon-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 2rem;
      color: ${noodle.primary};
      padding-right: 1.2rem;
    }

    &__navigation-icon-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 2rem;
      color: ${noodle.primary};
      padding-left: 1.2rem;
    }
  }
`;
export default ScrollSliderWrapper;
