// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from '../../settings/theme';

const {
  noodera: { spacing, fontTypes },
  palette,
} = getTheme();
export const Container: StatelessFunctionalComponent<any> = styled.span`
  padding: ${spacing.x8} ${spacing.x7};
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.background ? `${props.background} !important` : palette.neutral.ten};
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;
  cursor: default;
  z-index: 1;
  ${(props) => props.enableHover && 'cursor: pointer;'}
  ${(props) =>
    !props.hasText && `background: none; border: none; padding: ${spacing.x9} ${spacing.x7};`};
`;
export const ContainerIcon: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  ${(props) => (props.hasText ? `margin-right: ${spacing.x8};` : `margin-right: 0;`)};

  & > span {
    display: flex;
  }
`;
export const Text: StatelessFunctionalComponent<any> = styled.p`
  display: inline-block;
  color: ${(props) => props.textColor || palette.neutral.hundred};
  ${fontTypes.font5Medium};
  text-align: left;
  margin-bottom: 0;
  letter-spacing: 0;
  white-space: nowrap;
`;
