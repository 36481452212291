// @ts-nocheck
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from 'components/Stepper';
import NooderaButton from 'components/NooderaButton';
import { useStepper } from 'utils/customHooks';
// Screens
import Privacy from 'screens/CreateTeam/Privacy';
import TeamImage from 'screens/CreateTeam/TeamImage';
import CreateDetails from 'screens/CreateTeam/CreateDetails';
import InviteMembers from 'screens/CreateTeam/InviteMembers';
import { teamActions } from 'ducks/team/actions';
import { Header, Content, ButtonWrapper, ButtonContainer } from './CreateTeam.styles';

const steps = [
  {
    label: 'Privacy',
    key: 'privacyScreen',
  },
  {
    label: 'Details',
    key: 'createDetails',
  },
  {
    label: 'Image',
    key: 'teamImage',
  },
  {
    label: 'Invite',
    key: 'inviteMembers',
  },
];

const CreateTeam = () => {
  const { activeStep, handleNext } = useStepper(steps.length);
  const dispatch = useDispatch();
  const isSuccessInviteMembers = useSelector((state) => state.team.inviteMembers.success);
  const [createTeamObj, setCreateTeamObj] = React.useState({
    privacyScreen: {
      privacy: {
        public: false,
        private: true,
      },
      visibility: {
        visible: false,
        hidden: false,
      },
    },
    inviteMembers: [],
    createDetails: {
      name: '',
      about: '',
    },
    teamImage: {
      urls: {
        full: '',
        raw: '',
        regular: '',
        small: '',
        thumb: '',
      },
      userLink: '',
      userName: '',
      caption: '',
      attribution: '',
    },
  });

  const handleChange = (data: any): void => {
    const key = steps[activeStep - 1]?.key;
    const newObj = { ...createTeamObj };
    newObj[key] = data;
    setCreateTeamObj({ ...createTeamObj, ...newObj });
  };

  const handleSubmit = (): void => {
    dispatch(teamActions.createTeamRequest(createTeamObj));
  };

  // it's calling on the first 3 step, 4th step if final and avoiding validation
  const validateStepData = (): boolean => {
    const {
      privacyScreen: { privacy, visibility },
      teamImage,
      createDetails,
    } = createTeamObj;

    switch (activeStep) {
      case 1:
        if ((privacy.public || privacy.private) && (visibility.visible || visibility.hidden))
          return true;
        break;

      case 2:
        if (Object.values(createDetails).every((item) => item)) return true;
        break;

      case 3:
        if (teamImage?.urls?.regular) return true;
        break;

      default:
        return false;
    }

    return false;
  };

  React.useEffect(() => {
    if (isSuccessInviteMembers) {
      dispatch(teamActions.inviteMembersReset());
    }
  }, [isSuccessInviteMembers, createTeamObj.inviteMembers]);

  const renderScreen = (): React.ReactElement<any> | string | null => {
    switch (activeStep) {
      case 1:
        return <Privacy data={createTeamObj?.privacyScreen} onChange={handleChange} />;

      case 2:
        return <CreateDetails data={createTeamObj?.createDetails} onChange={handleChange} />;

      case 3:
        return <TeamImage data={createTeamObj?.teamImage} onChange={handleChange} />;

      case 4:
        return <InviteMembers data={createTeamObj?.inviteMembers} onChange={handleChange} />;

      default:
        return null;
    }
  };

  const renderStepsButton = (): React.ReactElement<any> | null =>
    activeStep === steps.length ? (
      <ButtonContainer>
        <NooderaButton.CTA size='large' onClick={handleSubmit}>
          Finish
        </NooderaButton.CTA>
      </ButtonContainer>
    ) : (
      <ButtonContainer>
        <NooderaButton.CTA size='large' onClick={handleNext} isDisabled={!validateStepData()}>
          Next
        </NooderaButton.CTA>
      </ButtonContainer>
    );

  return (
    <>
      <Header>
        <Stepper activeStep={activeStep} steps={steps} />
      </Header>
      <Content>{renderScreen()}</Content>
      <ButtonWrapper>{renderStepsButton()}</ButtonWrapper>
    </>
  );
};

export default CreateTeam;
