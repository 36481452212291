// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import 'assets/css/custom-animations.css';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALLER_WIDTH, TABLET_WIDTH } from 'commons/constants/size';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import Separator from 'components/uielements/Separator/index';

const {
  palette: { greyscale, noodle, neutral, primary },
  noodera: { fontTypes: nooderaFontTypes, spacing: nooderaSpacing },
  fontColors,
  spacing,
  fontTypes,
  noodera,
} = getTheme();
export const HamburgerMenuClose: StatelessFunctionalComponent<any> = styled.div`
  &&& {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${neutral.eighty};
      }
    }
  }
`;
export const ContainerCloseExplore: StatelessFunctionalComponent<any> = styled.div`
  top: 19px;
  position: absolute;
  right: 24px;
`;
export const ContainerExplore: StatelessFunctionalComponent<any> = styled.div`
  &&& {
    a,
    a:visited,
    a:hover {
      color: inherit;
    }

    div.hamburger-menu__subheader__container {
      &&&,
      &:hover,
      &:focus {
        svg path {
          fill: inherit;
        }
      }
    }
  }
`;
export const AuthButtons: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 16px;

  a {
    display: inline-block;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
const HamburgerMenuWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin: 0 0.8rem;

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    margin: 0 0.6rem;
  }

  .hamburger-menu {
    overflow: hidden;
    max-height: 100vh;
    right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    visibility: hidden;
    width: 336px;
    z-index: 2;
    background: ${greyscale.white};
    padding-left: ${spacing.x4};
    padding-right: ${spacing.x4};
    box-shadow: -6px 0 3px rgba(0, 0, 0, 0.15);
    animation-duration: 300ms;

    &__right-chevron {
      cursor: pointer;
      margin-left: ${spacing.x5};

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: ${neutral.eighty};
        }
      }
    }

    &__user-avatar {
      height: 4.8rem;
      width: auto;
      border-radius: 50%;
      margin-right: ${noodera.spacing.x6};
      color: red;
    }

    &__nav-links {
      &__container {
        display: flex;
        flex-direction: column;
        margin-top: ${spacing.x4};
        align-items: center;
      }

      &__user {
        display: flex;
        margin-bottom: 2rem;

        & > img {
          margin-right: ${nooderaSpacing.x6};
        }

        & > span {
          ${nooderaFontTypes.font4Medium};
          color: ${fontColors.primary};
        }
      }

      &__action {
        background: none;
        display: flex;
        margin-left: auto;
        border: none;
        padding: 0;

        span svg path {
          fill: ${neutral.eighty};
        }
      }
    }

    &__header {
      &__container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin: ${spacing.x5} 0;
      }

      &__title {
        ${nooderaFontTypes.font3Medium};
        margin: 0;
      }
    }

    &__subheader {
      &__content {
        display: flex;
        align-items: center;

        &__text {
          margin-left: ${spacing.x5};
        }

        & > svg {
          font-size: 5.2rem;
        }
      }

      &__container {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          svg {
            width: ${spacing.x1};
            height: ${spacing.x1};
            max-width: ${spacing.x1};
            max-height: ${spacing.x1};
          }
        }

        & i {
          font-size: 2.4rem;
          width: 24px;
          height: 24px;
        }
      }

      &__title {
        ${nooderaFontTypes.font4Medium};
        margin-bottom: 0;
      }

      &__subtitle {
        ${fontTypes.font4};
        margin-bottom: 0;
      }
    }

    &__button {
      &--login {
        margin-bottom: 2rem;
      }
    }

    &__footer {
      &__container {
        margin-top: ${nooderaSpacing.x5};
      }

      &__text-container {
        margin-bottom: ${spacing.x4};

        & .hamburger-menu__footer__text {
          ${nooderaFontTypes.font4};
        }
      }

      &__title {
        ${nooderaFontTypes.font4Medium};
      }
    }

    &__icon {
      margin-left: 3rem;
      cursor: pointer;
      height: 2.4rem;
      width: 2.4rem;
      outline-style: none;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      z-index: 3;
      -webkit-transition: 0.5s ease-in-out;
      &--container {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
      span {
        display: block;
        position: absolute;
        height: 0.2rem;
        width: 100%;
        background: ${greyscale.smoke};
        border-radius: 0.9rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.6s ease-in-out;
        -webkit-transition: 0.6s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
        transform-origin: left center;
      }
      span:nth-child(2) {
        top: 1rem;
        transform-origin: left center;
      }
      span:nth-child(3) {
        top: 2rem;
        transform-origin: left center;
      }

      &.open span {
        background: ${greyscale.smoke} !important;
      }

      &.open span:nth-child(1) {
        background: ${fontColors.primary};
        top: 0.3rem;
        transform: rotate(47deg);
      }
      &.open span:nth-child(2) {
        background: ${fontColors.primary};
        width: 0;
        opacity: 0;
      }
      &.open span:nth-child(3) {
        background: ${fontColors.primary};
        transform: rotate(-47deg);
      }
    }

    &__icons {
      &__wrapper {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        max-height: 28%;
        &--guest {
          justify-content: flex-end;
        }
      }
    }

    &__sub-title {
      color: ${noodle.secondary};
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.9rem;
      letter-spacing: 0.032rem;
      margin: 2rem 0 3rem 0;
      text-align: center;
      width: 70%;
    }
    &__title {
      color: ${noodle.secondary};
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 3rem;
      letter-spacing: 0.032rem;
      text-align: center;
      width: 80%;
    }
  }

  @media screen and (max-height: 600px) {
    .hamburger-menu {
      &__footer {
        position: relative;
        bottom: auto;
      }
    }
  }

  @media screen and (max-height: 675px) {
    .hamburger-menu {
      overflow-y: auto;
      height: 92%;
    }
  }

  @media screen and (max-height: 500px) {
    .hamburger-menu {
      height: 88%;
    }
  }

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    .hamburger-menu {
      width: 100%;
      left: 0;
      padding-left: ${spacing.x4};
      padding-right: ${spacing.x4};
    }
  }
`;
export const FullNameProfile: StatelessFunctionalComponent<any> = styled.span`
  align-self: center;
  ${noodera.fontTypes.font4Medium};
`;
export const StyledLogout: StatelessFunctionalComponent<any> = styled.span`
  ${noodera.fontTypes.font5};
  color: ${neutral.eighty};
  margin-right: 8px;
  align-self: center;
`;
export const StyledSvgLogoutWrapper: StatelessFunctionalComponent<any> = styled(SvgImageWrapper)`
  &&& {
    display: flex;
    align-items: center;
    height: 2.4rem;
  }
`;
export const StyledSeparator: StatelessFunctionalComponent<any> = styled(Separator)`
  &&& {
    margin: ${nooderaSpacing.x5} 0;
  }
`;
export const StyledExternalLinksList: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: none;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
      display: flex;
      margin: ${nooderaSpacing.x5} 0;
      justify-content: space-around;
    }

    a {
      ${noodera.fontTypes.font4};
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${neutral.hundred};
      text-align: center;

      &:hover {
        svg path {
          fill: none;
        }
      }

      &:active {
        svg path {
          fill: none;
        }
      }

      &:focus {
        svg {
          background-color: transparent;
          path {
            fill: none;
          }
        }
      }
    }

    svg,
    img {
      width: ${nooderaSpacing.x2};
      height: ${nooderaSpacing.x2};
      margin-bottom: ${nooderaSpacing.x6};
    }
  }
`;
export const ExternalLink: StatelessFunctionalComponent<any> = styled.a`
  &&& {
    svg path {
      fill: ${primary.hundred};
    }
    &:hover {
      svg path {
        fill: ${primary.hundred};
      }
    }
    &:active {
      svg path {
        fill: ${primary.hundred};
      }
    }
    &:focus {
      svg path {
        fill: ${primary.hundred};
      }
    }
  }
`;
export default HamburgerMenuWrapper;
