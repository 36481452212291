import type { Course, EnrolledSection } from 'commons/types/courseTypes';
import {
  getCoursePreviewTypes,
  courseTypes,
  enrollTypes,
  getMyCoursesTypes,
  getCoursesTypes,
  getSavedCoursesTypes,
  getEnrolledSectionsTypes,
  unsaveCourseTypes,
  getCertificateTypes,
} from './types';

type GetEnrolledSectionsTypesValues =
  (typeof getEnrolledSectionsTypes)[keyof typeof getEnrolledSectionsTypes];

type EnrolledSectionsPayload =
  | { brightspaceOrgUnitId: number; lms: Course['lms'] }
  | { moodleCourseId: number; lms: Course['lms'] }
  | { canvasCourseId: number; lms: Course['lms'] };

interface GetEnrolledSectionsRequest {
  (payload: { brightspaceOrgUnitId: number; lms: Course['lms'] }): {
    type: GetEnrolledSectionsTypesValues;
    payload: EnrolledSectionsPayload;
  };
}

const getEnrolledSectionsRequest: GetEnrolledSectionsRequest = (payload) => ({
  type: getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_REQUEST,
  payload,
});

export const enrollActions = {
  enrollModalRequest: () => ({
    type: enrollTypes.ENROLL_MODAL_REQUEST,
  }),
  enrollModalSuccess: () => ({
    type: enrollTypes.ENROLL_MODAL_SUCCESS,
  }),
  enrollModalFailure: () => ({
    type: enrollTypes.ENROLL_MODAL_FAILURE,
  }),
  enrollModalReset: () => ({
    type: enrollTypes.ENROLL_MODAL_RESET,
  }),
  getEnrolledSectionsRequest,
  getEnrolledSectionsSuccess: (payload: EnrolledSection) => ({
    type: getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_SUCCESS,
    payload,
  }),
  getEnrolledSectionsFailure: (payload: string) => ({
    type: getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_FAILURE,
    payload,
  }),
};
export const courseActions = {
  selectCourse: (payload: Course | null) => ({
    type: courseTypes.SELECT_COURSE,
    payload,
  }),
  getCoursePreviewRequest: (payload: any) => ({
    type: getCoursePreviewTypes.GET_COURSE_PREVIEW_REQUEST,
    payload,
  }),
  getCoursePreviewSuccess: (payload: Course) => ({
    type: getCoursePreviewTypes.GET_COURSE_PREVIEW_SUCCESS,
    payload,
  }),
  getCoursePreviewFailure: (payload: string) => ({
    type: getCoursePreviewTypes.GET_COURSE_PREVIEW_FAILURE,
    payload,
  }),
  getMyCoursesRequest: (payload?: Record<string, any>) => ({
    type: getMyCoursesTypes.GET_MY_COURSES_REQUEST,
    payload,
  }),
  getMyCoursesSuccess: (payload: Course[]) => ({
    type: getMyCoursesTypes.GET_MY_COURSES_SUCCESS,
    payload,
  }),
  getMyCoursesFailure: (payload: string) => ({
    type: getMyCoursesTypes.GET_MY_COURSES_FAILURE,
    payload,
  }),
  getSavedCoursesRequest: () => ({
    type: getSavedCoursesTypes.GET_SAVED_COURSES_REQUEST,
  }),
  getSavedCoursesSuccess: (payload: Course[]) => ({
    type: getSavedCoursesTypes.GET_SAVED_COURSES_SUCCESS,
    payload,
  }),
  getSavedCoursesFailure: (payload: string) => ({
    type: getSavedCoursesTypes.GET_SAVED_COURSES_FAILURE,
    payload,
  }),
  getCoursesRequest: () => ({
    type: getCoursesTypes.GET_COURSES_REQUEST,
  }),
  getCoursesSuccess: (payload: Course[]) => ({
    type: getCoursesTypes.GET_COURSES_SUCCESS,
    payload,
  }),
  getCoursesFailure: (payload: string) => ({
    type: getCoursesTypes.GET_COURSES_FAILURE,
    payload,
  }),
  removeCourse: (payload: number) => ({
    type: unsaveCourseTypes.UNSAVE_COURSE_REQUEST,
    payload,
  }),
  removeCourseSuccess: () => ({
    type: unsaveCourseTypes.UNSAVE_COURSE_SUCCESS,
  }),
  removeCourseFailure: (payload: string) => ({
    type: unsaveCourseTypes.UNSAVE_COURSE_FAILURE,
    payload,
  }),
  getCertificateRequest: (payload: string) => ({
    type: getCertificateTypes.GET_CERTIFICATE_REQUEST,
    payload,
  }),
  getCertificateSuccess: (payload: Record<string, any>) => ({
    type: getCertificateTypes.GET_CERTIFICATE_SUCCESS,
    payload,
  }),
  getCertificateFailure: (payload: string) => ({
    type: getCertificateTypes.GET_CERTIFICATE_FAILURE,
    payload,
  }),
  downloadCertificate: (payload: string) => ({
    type: getCertificateTypes.DOWNLOAD_CERTIFICATE,
    payload,
  }),
};
