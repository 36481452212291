// @ts-nocheck
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// @ts-expect-error
import 'moment-timezone';
import NoodleButton from 'components/uielements/Button';
import { consolidate2dArray } from 'utils/arrayHelpers';
import { DATE_FORMAT, TIME_FORMAT, US_TIMEZONES } from 'commons/constants/scheduler';
import { useWidth } from 'utils/customHooks';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';
import ScrollSlider from 'components/ScrollSlider';
import LinkButton from 'components/LinkButton';
import { schedulerActions } from 'ducks/scheduler/actions';
import { ReactComponent as GlobeIcon } from 'assets/svg/globe.svg';
import type {
  ScheduleProps,
  AvailableDateObj,
  AvailabilityItem,
  DateTimeScheduleProps,
  TimeZoneProps,
} from './Schedule.types';
import { NoodleButtonSchedule } from './Schedule.style';

const Schedule = ({ availabilityData, hasArrowNav, getDateTimeValue }: ScheduleProps) => {
  const width = useWidth();
  const dispatch = useDispatch();
  const [isTimezoneSelectOpen, setIsTimezoneSelectOpen] = React.useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = React.useState({});
  const [availableDates, setAvailableDates] = React.useState<AvailableDateObj[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<string>(moment().format(DATE_FORMAT));
  const [selectedTime, setSelectedTime] = React.useState({
    start: '',
    end: '',
    resources: [],
  });
  const [numOfShowableTimes, setNumOfShowableTimes] = React.useState<number>(16);
  // If mobile size, show 30 days worth of days
  const [numOfShowableDates, setNumOfShowableDates] = React.useState<number>(
    width > TABLET_SMALL_WIDTH ? 4 : 30,
  );
  const userTimezoneObj = useSelector((state) => state.scheduler.timezone);
  React.useEffect(() => {
    const getDefaultTimezone = () => {
      if (userTimezoneObj && userTimezoneObj.isUSBased) {
        return setSelectedTimezone(userTimezoneObj);
      }

      return setSelectedTimezone({
        name: 'Eastern Time',
        value: 'America/New_York',
      });
    };

    getDefaultTimezone();
  }, [userTimezoneObj]);
  React.useEffect(() => {
    // Converting into a nicer array to work with
    if (availabilityData && availabilityData.length) {
      setSelectedDate(moment(availabilityData[0].start).format(DATE_FORMAT));
      const dateArr = availabilityData.map((date: any) => {
        const displayDate = moment(date.start).format(DATE_FORMAT);
        return {
          date: displayDate,
          availability: [date],
        };
      });
      const consolidatedArr = consolidate2dArray(dateArr, 'date', 'availability');
      // Adding some disabled dates to have the hours uniform
      const niceArr = consolidatedArr.map((dateObj) => ({
        ...dateObj,
        availability: dateObj.availability.reduce((acc, value, idx) => {
          const meetingLength = moment(value.end).diff(moment(value.start), 'minutes');

          if (idx === 0 && moment(value.start).minutes() === meetingLength) {
            acc.push({
              start: moment(value.start).minutes(0),
              end: value.start,
              disabled: true,
            });
            acc.push(value);
            return acc;
          }

          // TODO: Big block of code to fill in missing timeslots in between available hours
          // This is pretty horrible but it looks like it works.
          const prevTimeSlotEnd = acc.length ? acc[acc.length - 1].end : null;

          if (prevTimeSlotEnd && !moment(prevTimeSlotEnd).isSame(moment(value.start))) {
            const timeSlotsMissing = Math.floor(
              moment(value.start).diff(moment(prevTimeSlotEnd), 'minutes') / meetingLength,
            );

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < timeSlotsMissing; i++) {
              acc.push({
                start: moment(prevTimeSlotEnd).add(meetingLength * i, 'minutes'),
                end: moment(prevTimeSlotEnd).add(meetingLength * (i + 2), 'minutes'),
                disabled: true,
              });
            }

            acc.push(value);
            return acc;
          }

          acc.push(value);
          return acc;
        }, []),
      }));
      setAvailableDates(niceArr);
    }
  }, [availabilityData]);

  const handleSelectClick = (): void => setIsTimezoneSelectOpen(!isTimezoneSelectOpen);

  const handleDateClick = (date: string): void => {
    getDateTimeValue('');
    setSelectedDate(date);
  };

  const handleClickToday = (): void => {
    const today = moment().format(DATE_FORMAT);
    setSelectedDate(today);
  };

  const handleTimeClick = (date: DateTimeScheduleProps): void => {
    getDateTimeValue(date, selectedTimezone);
    setSelectedTime(date);
  };

  const handleTimezoneClick = (timezoneObj: TimeZoneProps): void => {
    getDateTimeValue('');
    setSelectedTimezone(timezoneObj);
    setIsTimezoneSelectOpen(false);
  };

  const renderTimezoneSelect = (): React.ReactElement<any> | null =>
    isTimezoneSelectOpen ? (
      <div className='new-scheduler__timezone-container'>
        {US_TIMEZONES.map((timezoneObj) => (
          <NoodleButton
            key={timezoneObj.name}
            onClick={() => handleTimezoneClick(timezoneObj)}
            className='btn-ghost-primary'
          >
            {timezoneObj.name} ({moment.tz(moment(), timezoneObj.value).format(TIME_FORMAT)})
          </NoodleButton>
        ))}
      </div>
    ) : null;

  // TODO: Fix typing here!
  const renderAvailableDates = (): any => {
    if (!availableDates || !availableDates.length) {
      return null;
    }

    return availableDates.slice(0, numOfShowableDates).map((date: any) => {
      if (date.date === moment().format(DATE_FORMAT)) {
        return (
          <NoodleButton
            onClick={handleClickToday}
            className={
              selectedDate === moment().format(DATE_FORMAT)
                ? 'btn-round-noodle'
                : 'btn-round-noodle-transparent'
            }
          >
            <span className='semi-bold'>Today</span>
          </NoodleButton>
        );
      }

      return (
        <NoodleButton
          onClick={() => handleDateClick(date.date)}
          className={
            selectedDate === date.date ? 'btn-round-noodle' : 'btn-round-noodle-transparent'
          }
        >
          <span className='semi-bold'>{date.date}</span>
        </NoodleButton>
      );
    });
  };

  const renderAvailableHours = (): React.ReactElement<any> | null => {
    const selectedDateAvailability = availableDates.find(
      (dateObj) => dateObj.date === selectedDate,
    );

    if (selectedDateAvailability) {
      const currentDateSelected: AvailableDateObj | null | undefined = availableDates.find(
        (dateObj) => dateObj.date === selectedDate,
      );
      const availableHours =
        currentDateSelected &&
        currentDateSelected.availability
          .slice(0, numOfShowableTimes)
          .map((date: AvailabilityItem) => (
            <NoodleButtonSchedule
              isSelected={selectedTime.start === date.start}
              key={date.start}
              onClick={() => {
                handleTimeClick(date);
                dispatch(schedulerActions.clickMeetingHour(date.start));
              }}
              className='btn-ghost-primary new-scheduler__hour-btn'
              size='small'
              disabled={date.disabled}
            >
              {moment.tz(date.start, selectedTimezone.value).format(TIME_FORMAT)}
            </NoodleButtonSchedule>
          ));
      return <div className='new-scheduler__time-select'>{availableHours}</div>;
    }

    return <span>No times available on this date!</span>;
  };

  const renderTimezoneButton = (): React.ReactElement<any> | null => {
    if (!selectedTimezone) {
      return null;
    }

    const timezoneBtnText = `${selectedTimezone.name} (${moment
      .tz(moment(), selectedTimezone.value)
      .format(TIME_FORMAT)})`;
    return (
      <div className='new-scheduler__timezone-select__btn-container'>
        <LinkButton
          onClick={handleSelectClick}
          icon={<GlobeIcon />}
          isActive={isTimezoneSelectOpen}
        >
          {timezoneBtnText}
        </LinkButton>
        {isTimezoneSelectOpen && (
          <NoodleButton
            onClick={handleSelectClick}
            className='new-scheduler__timezone-select__btn-container__cancelBtn btn-transparent'
          >
            Cancel
          </NoodleButton>
        )}
      </div>
    );
  };

  const renderMoreHoursBtn = (): React.ReactElement<any> | null => {
    const availableDatesArr = availableDates.find((dateObj) => dateObj.date === selectedDate);

    if (!availableDatesArr) {
      return null;
    }

    return availableDatesArr.availability.length &&
      availableDatesArr.availability.length > numOfShowableTimes ? (
      <NoodleButton
        onClick={() => setNumOfShowableTimes(numOfShowableTimes + 16)}
        className='btn-transparent new-scheduler__more-times-btn'
      >
        More
      </NoodleButton>
    ) : null;
  };

  const renderMoreDatesBtn = (): React.ReactElement<any> | null =>
    availableDates.length > numOfShowableDates ? (
      <NoodleButton
        onClick={() => setNumOfShowableDates(numOfShowableDates + 4)}
        className='btn-transparent new-scheduler__more-btn'
      >
        More
      </NoodleButton>
    ) : null;

  const renderDateSelect = (): React.ReactElement<any> =>
    width > TABLET_SMALL_WIDTH || hasArrowNav ? (
      <div className='new-scheduler__date-select'>
        <ScrollSlider>
          {renderAvailableDates()}
          {renderMoreDatesBtn()}
        </ScrollSlider>
      </div>
    ) : (
      <div className='new-scheduler__date-select'>
        {renderAvailableDates()}
        {renderMoreDatesBtn()}
      </div>
    );

  return (
    <div className='new-scheduler__container'>
      {renderDateSelect()}
      <div className='new-scheduler__bottom-container'>
        <div className='new-scheduler__timezone-select'>
          {renderTimezoneButton()}
          {renderTimezoneSelect()}
        </div>
        {renderAvailableHours()}
        {renderMoreHoursBtn()}
      </div>
    </div>
  );
};

export default Schedule;
