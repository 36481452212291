// @ts-nocheck
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStepper } from 'utils/customHooks';
import { teacherAssistantActions } from 'ducks/teacherAssistant/actions';
// Components
import Stepper from 'components/Stepper';
import NooderaButton from 'components/NooderaButton';
import Schedule from 'components/Schedule/index';
import type { DateTimeScheduleProps } from 'components/Schedule/Schedule.types';
import ScheduleContainer from 'components/Schedule/Schedule.style';
import { delay } from 'utils/async/index';
import type { HireTAProps, BookPayload, StepsButtonProps } from './HireTA.types';
import { Header, Content, ButtonWrapper, ButtonContainer, ScheduleTitle } from './HireTA.styles';
// Screens
import Payment from './Payment';
import SessionType from './SessionType';
import Purpose from './Purpose';

const HireTA = ({
  onSubmit,
  onError,
  courseSlug,
  privateSessionProductCode,
  publicSessionProductCode,
  userSpareTaSessionTransactions,
}: HireTAProps) => {
  const dispatch = useDispatch();
  const selectedSection = useSelector((state) => state.section.selectedSection);
  // TODO: We shouldn't be querying the state directly from here if we want this "component"
  // to be reusable. We should pass the course object and get it all from there.
  const teacherAssistants = useSelector(
    (state) => state.courses.preview.data.teacherAssistantsCollection,
  );
  const user = useSelector((state) => state.user);
  const booking = useSelector((state) => state.teacherAssistant.booking);
  const product = useSelector((state) => state.product?.product.data);
  const schedulerAvailability = useSelector(
    (state) => state.teacherAssistant.availability.data.availability,
  );
  const [hireTA, setHireTA] = React.useState({
    sessionType: {
      public: false,
      private: false,
    },
    purpose: {
      sectionType: '',
      textDetails: '',
    },
    scheduling: {
      start: '',
      end: '',
      resources: [],
      timezone: {},
    },
    paymentMVP: {},
  });
  React.useEffect(() => {
    if (booking.success) {
      onSubmit();
    }

    if (booking.error) {
      const sessionType = hireTA?.sessionType?.public ? 'public' : 'private';
      onError(sessionType);
    }
  }, [booking]);
  let productCode = null;

  if (hireTA?.sessionType?.public) {
    productCode = publicSessionProductCode;
  }

  if (hireTA?.sessionType?.private) {
    productCode = privateSessionProductCode;
  }

  const sessionPrice = product?.find((p) => p.productCode === productCode);
  const price = sessionPrice?.price / 100;

  const hasValidSparePayment = (): boolean => {
    if (!userSpareTaSessionTransactions) {
      return false;
    }

    if (productCode === privateSessionProductCode) {
      return userSpareTaSessionTransactions.taSessionPrivate != null;
    }

    if (productCode === publicSessionProductCode) {
      return (
        userSpareTaSessionTransactions.taSessionPublic?.validUntil != null &&
        new Date(userSpareTaSessionTransactions.taSessionPublic.validUntil) > new Date()
      );
    }

    return false;
  };

  const steps = [
    {
      label: 'Session',
      key: 'sessionType',
    },
    {
      label: 'Purpose',
      key: 'purpose',
    },
    {
      label: 'Scheduling',
      key: 'scheduling',
    },
  ];

  if (!hasValidSparePayment() && !booking.isLoading) {
    steps.push({
      label: 'Payment',
      key: 'paymentMVP',
    });
  }

  const { activeStep, handleNext } = useStepper(steps.length);
  const purposeOptions = [
    {
      name: 'General help understanding a concept covered in this course',
      value: 'General help understanding a concept covered in this course',
    },
    {
      name: 'Difficulty with a specific assignment',
      value: 'Difficulty with a specific assignment',
    },
  ];

  const getBookingPayload = (): BookPayload | null => {
    const { purpose, scheduling, sessionType } = hireTA;

    if (selectedSection && teacherAssistants) {
      return {
        customer: {
          name: user.profile.firstName,
          last_name: user.profile.lastName,
          email: user.email,
          phone: '78066990',
          timezone: scheduling.timezone.value,
        },
        meta: {
          section_id: selectedSection.id,
        },
        start: scheduling.start,
        end: scheduling.end,
        timezone: scheduling.timezone.value,
        resource_id: teacherAssistants[0].timekitResourceId,
        project_id: teacherAssistants[0].privateTimekitProjectId,
        graph: 'instant',
        what: purpose.sectionType,
        description: purpose.textDetails,
        isPublic: sessionType.public,
      };
    }

    return null;
  };

  const handleChange = (data: any): void => {
    const key = steps[activeStep - 1]?.key;
    const newObj = { ...hireTA };
    newObj[key] = data;
    setHireTA({ ...hireTA, ...newObj });
  };

  const handleSchedule = (date: DateTimeScheduleProps, timezone: any): void => {
    const newObj = { ...hireTA };
    newObj.scheduling = { ...date, timezone };
    setHireTA({ ...hireTA, ...newObj });
  };

  const handleSubmit = (delayMs = 600): void => {
    // TODO: Adding a delay so the BE webhook has at least 600 ms to process new payments.
    // We might want to add a BE endpoint that we can poll in order to make sure
    // the payment has been completed before booking a session here.
    delay(delayMs)
      .then(() => {
        const payload = getBookingPayload();

        if (payload) {
          const { start, end, what, description, meta, isPublic, timezone } = payload;
          const data = {
            slug: teacherAssistants[0].slug,
            start,
            end,
            timezone,
            what,
            description,
            sectionId: meta.section_id,
            isPublic,
          };
          dispatch(teacherAssistantActions.createTASchedulerBooking(data));
        }
      })
      .catch((err) => {
        console.error('🚀 ~ file: index.js ~ line 135 ~ delay ~ err', err);
      });
  };

  const validateStepData = (): boolean => {
    const { sessionType, purpose, scheduling } = hireTA;

    switch (activeStep) {
      case 1:
        return sessionType.public || sessionType.private;

      case 2:
        return !!(purpose.sectionType && purpose.textDetails);

      case 3:
        if (scheduling.start && scheduling.end && scheduling.timezone) {
          return true;
        }

        break;

      case 4:
        return true;

      default:
        return false;
    }

    return false;
  };

  const renderScreen = (): React.ReactElement<any> | string | null => {
    switch (activeStep) {
      case 1:
        return <SessionType data={hireTA?.sessionType} onChange={handleChange} />;

      case 2:
        return <Purpose data={hireTA.purpose} items={purposeOptions} onChange={handleChange} />;

      case 3:
        return (
          <div>
            <ScheduleTitle>Schedule a 1 hour call</ScheduleTitle>
            <ScheduleContainer>
              <Schedule
                availabilityData={schedulerAvailability}
                getDateTimeValue={handleSchedule}
              />
            </ScheduleContainer>
          </div>
        );

      case 4:
        // TODO: The stripe payment payload is sending unitId right now.
        // We will most likely switch this to sectionId in the future.
        // Also there will be differentiation between private vs public session.
        return (
          <Payment
            courseSlug={courseSlug}
            onFinish={handleSubmit}
            sessionType={hireTA?.sessionType}
            productCode={productCode}
            ctaText={`Pay $${price}`}
          />
        );

      default:
        return null;
    }
  };

  const StepsButton = ({ text, onClick }: StepsButtonProps): React.ReactElement<any> => (
    <ButtonContainer>
      <NooderaButton.CTA
        size='large'
        onClick={onClick}
        isDisabled={!validateStepData() || booking.isLoading}
      >
        {text}
      </NooderaButton.CTA>
    </ButtonContainer>
  );

  const renderStepsButton = (): React.ReactElement<any> | null => {
    if (activeStep === steps.length) {
      if (hasValidSparePayment()) {
        return <StepsButton text='Book session' onClick={() => handleSubmit(0)} />;
      }

      return null;
    }

    return <StepsButton text='Next' onClick={handleNext} />;
  };

  return (
    <>
      <Header>
        <Stepper activeStep={activeStep} steps={steps} />
      </Header>
      <Content>{renderScreen()}</Content>
      <ButtonWrapper>{renderStepsButton()}</ButtonWrapper>
    </>
  );
};

export default HireTA;
