// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';

const LoaderWrapper: StatelessFunctionalComponent<any> = styled.div`
  height: 100%;
  background: ${(props) => props.backgroundColor};

  img {
    animation: fadeInOut 2s infinite;
    left: 50%;
    position: absolute;
    top: 50vh;
    transform: translate(-50%, -50%);
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
export default LoaderWrapper;
