import styled from 'styled-components';

import NooderaButton from 'components/NooderaButton';

export const ProgressPercentageIndicator = styled.span`
  ${({ theme }) => theme.noodera.fontTypes.font6}
  color: white;
`;

export const CourseTitle = styled.span`
  ${({ theme }) => theme.noodera.fontTypes.font4Medium}

  font-weight: 600;
  color: white;
  max-height: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CourseSubtext = styled.span`
  ${({ theme }) => theme.noodera.fontTypes.font5}

  font-weight: 500;
  color: white;
`;

export const CoursePaymentPendingTagContainer = styled.div`
  display: inline-block;
`;

export const CertificateButton = styled(NooderaButton)`
  margin-top: ${({ theme }) => theme.noodera.spacing.x8};
`;
