import type {
  Team,
  CreateTeamPayload,
  TeamResource,
  ApproveOrJoinPayload,
  TeamModalPayload,
  SaveContentPayload,
  SavedContent,
  TeamInvitePayload,
  TeamPreviewPayload,
} from 'commons/types/teamTypes';
import {
  getTeamTypes,
  getMyTeamsTypes,
  getTeamsTypes,
  joinTeamByInviteTypes,
  getTeamPreviewTypes,
  joinTeamRequestTypes,
  createTeamTypes,
  getTeamSavedResourcesTypes,
  inviteMembersTypes,
  approveJoinPetitionTypes,
  denyJoinPetitionTypes,
  teamTypes,
  cancelTeamRequestTypes,
  saveContentTypes,
} from './types';

export const teamActions = {
  getTeamSavedResourcesRequest: (payload?: string) => ({
    type: getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_REQUEST,
    payload,
  }),
  getTeamSavedResourcesSuccess: (payload: TeamResource[]) => ({
    type: getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_SUCCESS,
    payload,
  }),
  getTeamSavedResourcesFailure: (payload: string) => ({
    type: getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_FAILURE,
    payload,
  }),
  getTeamsRequest: () => ({
    type: getTeamsTypes.GET_TEAMS_REQUEST,
  }),
  getTeamsSuccess: (payload: Team[]) => ({
    type: getTeamsTypes.GET_TEAMS_SUCCESS,
    payload,
  }),
  getTeamsFailure: (payload: string) => ({
    type: getTeamsTypes.GET_TEAMS_FAILURE,
    payload,
  }),
  getMyTeamsRequest: () => ({
    type: getMyTeamsTypes.GET_MY_TEAMS_REQUEST,
  }),
  getMyTeamsSuccess: (payload: Team[]) => ({
    type: getMyTeamsTypes.GET_MY_TEAMS_SUCCESS,
    payload,
  }),
  getMyTeamsFailure: (payload: string) => ({
    type: getMyTeamsTypes.GET_MY_TEAMS_FAILURE,
    payload,
  }),
  getTeamRequest: () => ({
    type: getTeamTypes.GET_TEAM_REQUEST,
  }),
  getTeamSuccess: (payload: Team) => ({
    type: getTeamTypes.GET_TEAM_SUCCESS,
    payload,
  }),
  getTeamFailure: (payload: string) => ({
    type: getTeamTypes.GET_TEAM_FAILURE,
    payload,
  }),
  selectTeam: (payload: Team | null) => ({
    type: teamTypes.SELECT_TEAM,
    payload,
  }),
  selectFilter: (payload: Team | null) => ({
    type: teamTypes.SELECT_FILTER_TEAM,
    payload,
  }),
  getTeamPreviewRequest: (payload: TeamPreviewPayload | null) => ({
    type: getTeamPreviewTypes.GET_TEAM_PREVIEW_REQUEST,
    payload,
  }),
  getTeamPreviewSuccess: (payload: Team) => ({
    type: getTeamPreviewTypes.GET_TEAM_PREVIEW_SUCCESS,
    payload,
  }),
  getTeamPreviewFailure: (payload: string) => ({
    type: getTeamPreviewTypes.GET_TEAM_PREVIEW_FAILURE,
    payload,
  }),
  joinTeamByInviteRequest: (payload: ApproveOrJoinPayload) => ({
    type: joinTeamByInviteTypes.JOIN_TEAM_BY_INVITE_REQUEST,
    payload,
  }),
  joinTeamByInviteSuccess: () => ({
    type: joinTeamByInviteTypes.JOIN_TEAM_BY_INVITE_SUCCESS,
  }),
  joinTeamByInviteFailure: (payload: string) => ({
    type: joinTeamByInviteTypes.JOIN_TEAM_BY_INVITE_FAILURE,
    payload,
  }),
  joinTeamRequestRequest: (payload: number) => ({
    type: joinTeamRequestTypes.JOIN_TEAM_REQUEST_REQUEST,
    payload,
  }),
  joinTeamRequestSuccess: () => ({
    type: joinTeamRequestTypes.JOIN_TEAM_REQUEST_SUCCESS,
  }),
  joinTeamRequestFailure: (payload: string) => ({
    type: joinTeamRequestTypes.JOIN_TEAM_REQUEST_FAILURE,
    payload,
  }),
  cancelTeamRequestRequest: (payload: ApproveOrJoinPayload) => ({
    type: cancelTeamRequestTypes.CANCEL_TEAM_REQUEST_REQUEST,
    payload,
  }),
  cancelTeamRequestSuccess: () => ({
    type: cancelTeamRequestTypes.CANCEL_TEAM_REQUEST_SUCCESS,
  }),
  cancelTeamRequestFailure: (payload: string) => ({
    type: cancelTeamRequestTypes.CANCEL_TEAM_REQUEST_FAILURE,
    payload,
  }),
  createTeamRequest: (payload: CreateTeamPayload) => ({
    type: createTeamTypes.CREATE_TEAM_REQUEST,
    payload,
  }),
  createTeamSuccess: () => ({
    type: createTeamTypes.CREATE_TEAM_SUCCESS,
  }),
  createTeamFailure: (payload: string) => ({
    type: createTeamTypes.CREATE_TEAM_FAILURE,
    payload,
  }),
  inviteMembersRequest: (payload: TeamInvitePayload) => ({
    type: inviteMembersTypes.INVITE_MEMBERS_REQUEST,
    payload,
  }),
  inviteMembersSuccess: () => ({
    type: inviteMembersTypes.INVITE_MEMBERS_SUCCESS,
  }),
  inviteMembersFailure: (payload: string) => ({
    type: inviteMembersTypes.INVITE_MEMBERS_FAILURE,
    payload,
  }),
  inviteMembersReset: () => ({
    type: inviteMembersTypes.INVITE_MEMBERS_RESET,
  }),
  approveJoinPetitionRequest: (payload: ApproveOrJoinPayload) => ({
    type: approveJoinPetitionTypes.APPROVE_JOIN_PETITION_REQUEST,
    payload,
  }),
  approveJoinPetitionSuccess: () => ({
    type: approveJoinPetitionTypes.APPROVE_JOIN_PETITION_SUCCESS,
  }),
  approveJoinPetitionFailure: (payload: string) => ({
    type: approveJoinPetitionTypes.APPROVE_JOIN_PETITION_FAILURE,
    payload,
  }),
  denyJoinPetitionRequest: (payload: ApproveOrJoinPayload) => ({
    type: denyJoinPetitionTypes.DENY_JOIN_PETITION_REQUEST,
    payload,
  }),
  denyJoinPetitionSuccess: () => ({
    type: denyJoinPetitionTypes.DENY_JOIN_PETITION_SUCCESS,
  }),
  denyJoinPetitionFailure: (payload: string) => ({
    type: denyJoinPetitionTypes.DENY_JOIN_PETITION_FAILURE,
    payload,
  }),
  toggleSelectTeamModal: (payload: TeamModalPayload) => ({
    type: teamTypes.TOGGLE_SELECT_TEAM_MODAL,
    payload,
  }),
  saveContent: (payload: SaveContentPayload) => ({
    type: saveContentTypes.SAVE_CONTENT_REQUEST,
    payload,
  }),
  saveContentSuccess: (payload: SavedContent) => ({
    type: saveContentTypes.SAVE_CONTENT_SUCCESS,
    payload,
  }),
  saveContentFailure: (payload: string) => ({
    type: saveContentTypes.SAVE_CONTENT_FAILURE,
    payload,
  }),
};
