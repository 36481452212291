import type { UserConnectionType } from 'commons/types';
import { connectionTypes } from './types';

export const userConnectionActions = {
  getUserConnectionsRequest: () => ({
    type: connectionTypes.REQUEST,
  }),
  getUserConnectionsSuccess: (payload: UserConnectionType[]) => ({
    type: connectionTypes.SUCCESS,
    payload,
  }),
  getUserConnectionsFailed: (payload: string) => ({
    type: connectionTypes.FAILED,
    payload,
  }),
  postUserConnectionRequest: (entityId: number, targetUser?: any) => ({
    type: connectionTypes.CONNECTION_REQUEST,
    payload: {
      entityId,
      targetUser,
    },
  }),
  postUserConnectionSuccess: (payload: UserConnectionType) => ({
    type: connectionTypes.CONNECTION_SUCCESS,
    payload,
  }),
  postUserConnectionFailed: (payload: string) => ({
    type: connectionTypes.CONNECTION_FAILED,
    payload,
  }),
  acceptUserConnectionRequest: (payload: UserConnectionType) => ({
    type: connectionTypes.ACCEPT_CONNECTION_REQUEST,
    payload,
  }),
  acceptUserConnectionSuccess: (payload: UserConnectionType) => ({
    type: connectionTypes.ACCEPT_CONNECTION_SUCCESS,
    payload,
  }),
  acceptUserConnectionFailed: (payload: string) => ({
    type: connectionTypes.ACCEPT_CONNECTION_FAILED,
    payload,
  }),
  rejectCancelUserConnectionRequest: (payload: UserConnectionType) => ({
    type: connectionTypes.REJECT_CANCEL_CONNECTION_REQUEST,
    payload,
  }),
  rejectCancelUserConnectionSuccess: (payload: UserConnectionType) => ({
    type: connectionTypes.REJECT_CANCEL_CONNECTION_SUCCESS,
    payload,
  }),
  rejectCancelUserConnectionFailed: (payload: string) => ({
    type: connectionTypes.REJECT_CANCEL_CONNECTION_FAILED,
    payload,
  }),
};
