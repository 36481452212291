import * as React from 'react';
import NoodleButton from 'components/uielements/Button';
import Icon from 'components/uielements/Icon';
import LinkButtonWrapper from './LinkButton.style';
import type { LinkButtonProps } from './LinkButton.types';

const LinkButton = ({
  className,
  onClick,
  children,
  icon,
  iconName,
  iconPosition = 'left',
  isActive = true,
}: LinkButtonProps): React.ReactElement<any> | null => {
  const renderIcon = (): React.ReactElement<any> | null =>
    iconName ? <Icon className='chevron-button__chevron' icon={iconName} /> : icon || null;

  return (
    <LinkButtonWrapper iconPosition={iconPosition} className={className}>
      <NoodleButton
        color='link'
        onClick={onClick}
        className={`btn-transparent ${isActive ? 'link-button__active' : 'link-button__inactive'}`}
      >
        {iconPosition === 'left' && renderIcon()}
        {children}
        {iconPosition === 'right' && renderIcon()}
      </NoodleButton>
    </LinkButtonWrapper>
  );
};

export default LinkButton;
