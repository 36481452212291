// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, retry } from 'redux-saga/effects';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import { TEACHER_ASSISTANT } from 'commons/constants/teacherAssistant';
import api from 'lib/Api';
import { schedulerActions } from 'ducks/scheduler/actions';
import { teacherAssistantActions } from './actions';
import {
  getMeetingDetailTypes,
  joinSplitCostTypes,
  getSchedulerAvailabilityTypes,
  createTASchedulerBookingTypes,
} from './types';

const { SUCCESS, DANGER } = alertTypesConstants;
type GetMeetingDetailSagaPayload = {
  payload: string;
};
type CreateTASchedulerBookingPayload = {
  payload: Record<string, any>;
};
export function* getMeetingDetailSaga({ payload }: GetMeetingDetailSagaPayload): Saga<void> {
  try {
    const response = yield call([api.teacherAssistant, 'getMeetingDetail'], payload);
    yield put(teacherAssistantActions.getMeetingDetailSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEACHER_ASSISTANT.TeacherAssistantErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teacherAssistantActions.getMeetingDetailFailure(error));
  }
}
export function* joinSplitCostSaga({ payload }: GetMeetingDetailSagaPayload): Saga<void> {
  try {
    const SECOND = 1000;
    const response = yield retry(5, 0.5 * SECOND, [api.teacherAssistant, 'joinSplitCost'], payload);

    if (response) {
      yield put(teacherAssistantActions.joinSplitCostSuccess());
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEACHER_ASSISTANT.joinSplitCostErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teacherAssistantActions.joinSplitCostFailure(error));
  }
}
export function* getTASchedulerAvailabilitySaga({ payload }: any): Saga<void> {
  try {
    const response = yield call([api.teacherAssistant, 'getTASchedulerAvailability'], payload);
    yield put(teacherAssistantActions.getTASchedulerAvailabilitySuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error getting TA Availability!',
        type: DANGER,
      }),
    );
    yield put(teacherAssistantActions.getTASchedulerAvailabilityFailure(error));
  }
}
export function* createTASchedulerBookingSaga({
  payload,
}: CreateTASchedulerBookingPayload): Saga<void> {
  try {
    const response = yield call([api.teacherAssistant, 'createTASchedulerBooking'], payload);
    yield put(
      alertActions.setAlert({
        message: 'Booking scheduling successful',
        type: SUCCESS,
      }),
    );
    yield put(teacherAssistantActions.createTASchedulerBookingSuccess(response));
    const { sectionId } = Object.values(response)[0] as any;
    yield put(schedulerActions.getMeetings(sectionId));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error booking your meeting!',
        type: DANGER,
      }),
    );
    yield put(teacherAssistantActions.createTASchedulerBookingFailure(error));
  }
}
export default function* teacherAssistantSaga(): Saga<void> {
  yield takeLatest(getMeetingDetailTypes.GET_MEETING_DETAIL_REQUEST, getMeetingDetailSaga);
  yield takeLatest(joinSplitCostTypes.JOIN_SPLIT_COST_REQUEST, joinSplitCostSaga);
  yield takeLatest(
    getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_REQUEST,
    getTASchedulerAvailabilitySaga,
  );
  yield takeLatest(
    createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_REQUEST,
    createTASchedulerBookingSaga,
  );
}
