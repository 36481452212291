const THERE_WAS_AN_ERROR = 'There was an error';
export const COURSE = {
  coursesErrorMessage: `${THERE_WAS_AN_ERROR} fetching all courses`,
  courseByIdErrorMessage: `${THERE_WAS_AN_ERROR} fetching course by ID`,
  saveCourseSuccessMessage: `Course Saved`,
  saveCourseErrorMessage: `${THERE_WAS_AN_ERROR} saving a course`,
  deleteCourseErrorMessage: `${THERE_WAS_AN_ERROR} deleting a course`,
  deleteCourseSuccessMessage: `Unsaved`,
};
export const ENROLL_COURSE_PATH = 'enrollCoursePath';
