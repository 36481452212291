// @ts-nocheck
import * as React from 'react';
import { ReactComponent as SearchIcon } from 'assets/svg/search_icon.svg';
import { SearchBarWrapper } from './NooderaSearchBar.styles';
import type { NooderaSearchBarProps } from './NooderaSearchBar.types';

const NooderaSearchBar = ({
  searchText,
  onInputChange,
  placeholder = 'Search',
  isDisabled = false,
  id,
}: NooderaSearchBarProps) => {
  const [focused, setFocused] = React.useState(false);

  const onFocusChange = () => {
    setFocused(!focused);
  };

  return (
    <SearchBarWrapper
      role='search'
      onBlur={onFocusChange}
      onFocus={onFocusChange}
      focused={focused}
      isDisabled={isDisabled}
    >
      <label htmlFor={id}>
        <input
          type='text'
          id={id}
          placeholder={placeholder}
          disabled={isDisabled}
          value={searchText || null}
          onChange={onInputChange}
        />
      </label>
      {!isDisabled && <SearchIcon />}
    </SearchBarWrapper>
  );
};

export default NooderaSearchBar;
