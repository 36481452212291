import type { HasMaxLengthParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a value is lesser or equal to a given length
 */
const hasMaxLength = ({
  name,
  value,
  outputName = 'This field',
  maxLength,
  inputType = 'characters',
}: HasMaxLengthParams): ValidationFieldError => {
  const errors = {};
  errors[name] =
    value && value.length > maxLength
      ? {
          errorMsg: `${outputName} must be ${maxLength} ${inputType} maximum`,
        }
      : {};
  return errors;
};

export default hasMaxLength;
