import isEmptyObject from './is-empty-object';
import type { ValidationFieldError } from './Validation.types';
import type { FormIsTouched } from '../form/Form.types';

const isFormCompleted = (errors: ValidationFieldError, isTouched: FormIsTouched): boolean => {
  const fields = Object.keys(isTouched);
  const allFieldsChecked = fields.filter((field) => !isTouched[field]);
  const noErrors = fields.filter((field) => !isEmptyObject(errors[field]));
  return allFieldsChecked.length === 0 && noErrors.length === 0;
};

export default isFormCompleted;
