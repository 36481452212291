import React from 'react';
import {
  CardSectionCardsList,
  CardSectionContainer,
  CardSectionHeader,
  CardSectionSubtitle,
  CardSectionTitle,
} from './CardSection.styles';

interface CardSectionProps {
  cardsLength: number;
  title: string;
  children: React.ReactNode;
  [key: string]: any;
}

const CardSection: React.FC<CardSectionProps> = ({
  title,
  cardsLength,
  children,
  ...extraProps
}) => (
  <CardSectionContainer {...extraProps}>
    <CardSectionHeader>
      <CardSectionTitle>{title}</CardSectionTitle>
      <CardSectionSubtitle>
        {cardsLength} {cardsLength === 1 ? 'card' : 'cards'}
      </CardSectionSubtitle>
    </CardSectionHeader>
    <CardSectionCardsList>{children}</CardSectionCardsList>
  </CardSectionContainer>
);

export default CardSection;
