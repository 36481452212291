// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing },
  palette: { primary },
} = getTheme();
export const Header: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${primary.hundred};
    height: 96px;

    & > div {
      width: 670px;
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      height: 76px;
      padding: ${spacing.x6} ${spacing.x3} ${spacing.x3};
    }
  }
`;
export const Content: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.x3};
  margin-bottom: 20px;

  & > div {
    width: 392px;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    margin: 0;
    padding: ${spacing.x5};
  }
`;
export const ButtonWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
`;
export const ButtonContainer: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: center;
    width: 392px;

    & > .btn {
      flex-grow: 1;
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      padding: ${spacing.x6} ${spacing.x5};
    }
  }
`;
