// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import alertActions from 'ducks/alert/actions';
import type {
  GetTeamFeedPostsPayload,
  ActivityQueryInterface,
  CreatePostPayload,
} from 'commons/types/getStreamTypes';
import { alertTypesConstants } from 'commons/constants';
import api from 'lib/Api';
import { getStreamErrorMessages, NOTIFICATION_POLL_TIME } from 'commons/constants/getStream';
import { getStreamActions } from './actions';
import {
  createPostTypes,
  getTeamFeedPostsTypes,
  getUserFeedPostsTypes,
  getUserTokenTypes,
  updateActivityCommentsTypes,
  getUserNotificationsTypes,
  getStreamTypes,
  getMoreCommentsTypes,
  getMorePostsTypes,
} from './types';

const { DANGER, SUCCESS } = alertTypesConstants;
type getTeamFeedPostsSagaPayload = {
  payload: GetTeamFeedPostsPayload;
};
type getUserFeedPostsSagaPayload = {
  payload: GetTeamFeedPostsPayload;
};
type getMoreCommentsSagaPayload = {
  payload: ActivityQueryInterface;
};
type createPostSagaPayload = {
  payload: CreatePostPayload;
};
export function* getUserFeedPostsSaga({ payload }: getUserFeedPostsSagaPayload): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const userToken = localStorage.getItem('getStreamUserToken');

    if (!userToken) {
      const response = yield call([api.getStream, 'getUserToken']);
      localStorage.setItem('getStreamUserToken', response);
    }

    const apiPayload = {
      userId,
      activityQuery: payload,
    };
    const response = yield call([api.getStream, 'getUserFeedPosts'], apiPayload);
    yield put(getStreamActions.getUserFeedPostsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.userFeedPosts,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.getUserFeedPostsFailure(error));
  }
}
export function* getTeamFeedPostsSaga({ payload }: getTeamFeedPostsSagaPayload): Saga<void> {
  try {
    const apiPayload = {
      teamId: payload.teamId,
    };
    const response = yield call([api.getStream, 'getTeamFeedPosts'], apiPayload);
    yield put(getStreamActions.getTeamFeedPostsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.teamPosts,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.getTeamFeedPostsFailure(error));
  }
}
export function* getMorePostsSaga({ payload }: any): Saga<void> {
  try {
    const { userId, teamId, idLt } = payload;
    const isUserFeed = !!userId;
    const method = isUserFeed ? 'getUserFeedPosts' : 'getTeamFeedPosts';
    const apiPayload = isUserFeed
      ? {
          userId,
          idLt,
        }
      : {
          teamId,
          idLt,
        };
    const response = yield call([api.getStream, method], apiPayload);
    yield put(getStreamActions.getMorePostsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.teamPosts,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.getMorePostsFailure(error));
  }
}
export function* getMoreCommentsSaga({ payload }: getMoreCommentsSagaPayload): Saga<void> {
  try {
    const userToken = localStorage.getItem('getStreamUserToken');

    if (!userToken) {
      const response = yield call([api.getStream, 'getUserToken']);
      localStorage.setItem('getStreamUserToken', response);
    }

    const response = yield call([api.getStream, 'getCommentsByActivity'], payload);
    yield put(getStreamActions.getMoreCommentsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.updateActivityComments,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.getMoreCommentsFailure(error));
  }
}
export function* updateActivityCommentsSaga({ payload }: Record<string, any>): Saga<void> {
  try {
    const response = yield call([api.getStream, 'getCommentsByActivity'], payload);
    yield put(getStreamActions.updateActivityCommentsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.updateActivityComments,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.updateActivityCommentsFailure(error));
  }
}
export function* createPostSaga({ payload }: createPostSagaPayload): Saga<void> {
  try {
    const apiPayload = {
      teamId: payload?.teamId,
      activity: payload?.activity,
    };
    const response = yield call([api.getStream, 'createPost'], apiPayload);

    if (response) {
      const teamFeedPayload = {
        payload: {
          teamId: payload?.teamId,
        },
      };
      yield put(getStreamActions.createPostSuccess(response));
      yield put(getStreamActions.toggleCreatePostModal(false));
      yield put(
        alertActions.setAlert({
          message: getStreamErrorMessages.createPostSuccess,
          type: SUCCESS,
        }),
      );
      yield call(getTeamFeedPostsSaga, teamFeedPayload);
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: getStreamErrorMessages.createPostError,
        type: DANGER,
      }),
    );
    yield put(getStreamActions.createPostFailure(error));
  }
}
export function* getUserNotificationsSaga(): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const response = yield call([api.getStream, 'getUserNotifications'], userId);
    yield put(getStreamActions.getUserNotificationsSuccess(response));
  } catch (error) {
    yield put(getStreamActions.getUserNotificationsFailure(error));
    console.error('getUserNotificationsSaga: ', error);
  }
}
export function* pollNotifications(): Saga<void> {
  try {
    const isLoggedIn = yield select((state) => state.auth.isLoggedIn);

    if (isLoggedIn) {
      while (true) {
        yield getUserNotificationsSaga();
        yield delay(NOTIFICATION_POLL_TIME);
      }
    }
  } catch (error) {
    console.error('Polling error: ', error);
  }
}
export function* getUserTokenSaga(): Saga<void> {
  try {
    const response = yield call([api.getStream, 'getUserToken']);
    localStorage.setItem('getStreamUserToken', response);
    yield pollNotifications();
    yield put(getStreamActions.getUserTokenSuccess(response));
  } catch (error) {
    yield put(getStreamActions.getUserTokenFailure(error));
  }
}
export default function* getStreamSaga(): Saga<void> {
  yield takeLatest(getStreamTypes.START_POLLING_NOTIFICATIONS, pollNotifications);
  yield takeLatest(getUserTokenTypes.GET_USER_TOKEN_REQUEST, getUserTokenSaga);
  yield takeLatest(getUserFeedPostsTypes.GET_USER_FEED_POSTS_REQUEST, getUserFeedPostsSaga);
  yield takeLatest(getMoreCommentsTypes.GET_MORE_COMMENTS_REQUEST, getMoreCommentsSaga);
  yield takeLatest(getMorePostsTypes.GET_MORE_POSTS_REQUEST, getMorePostsSaga);
  yield takeLatest(getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_REQUEST, getTeamFeedPostsSaga);
  yield takeLatest(createPostTypes.CREATE_POST_REQUEST, createPostSaga);
  yield takeLatest(
    updateActivityCommentsTypes.UPDATE_ACTIVITY_COMMENTS_REQUEST,
    updateActivityCommentsSaga,
  );
  yield takeLatest(
    getUserNotificationsTypes.GET_USER_NOTIFICATIONS_REQUEST,
    getUserNotificationsSaga,
  );
}
