import styled from 'styled-components';
import { getTheme } from 'settings/theme';

const {
  palette: { greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();

export const TitleContainer = styled.div`
  ${fontTypes.font3Medium};
  background: ${greyscale.white};
  padding: ${spacing.x5};
`;
