import * as Sentry from '@sentry/react';
import JsonApi from 'utils/jsonApi';
import { axios } from '../index';

export default class Survey {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async submitSurveyAnswers({
    questions,
    surveyId,
  }: {
    questions: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    }[];
    surveyId: number;
  }) {
    try {
      const response = await this.jsonApi.post(
        `/surveys/${surveyId}/take-survey`,
        {
          questions,
        },
        false,
        null,
        '',
        {},
        true,
      );
      return response;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      return err;
    }
  }

  async submitSurveyAnswer({
    answer,
    surveyId,
  }: {
    answer: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    };
    surveyId: number;
  }) {
    try {
      const response = await axios.post(`/surveys/${surveyId}/upsert_answer`, {
        question_id: answer.questionId,
        option_id: answer.optionId,
      });
      return response?.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      return err;
    }
  }

  async getOnboardingSurvey() {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        'surveys/onboarding-private-label',
        false,
      );

      return {
        ...response?.data,
      };
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }
}
