import styled from '@emotion/styled';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes },
  palette: { primary },
  underlineLink,
} = getTheme();

export const StarIconWrapper = styled.div(() => ({
  '& > svg': {
    height: '48px',
    width: '48px',
    fill: 'rgb(102, 173, 194)',
  },
}));

export const PaidTitle = styled.span`
  ${fontTypes.font4Medium}
  font-weight: 600;
  color: black;
`;

export const PaidLink = styled.span`
  ${fontTypes.font5Medium}
  ${underlineLink}
  width: max-content;
  font-weight: 700;
  color: ${primary.hundred};
`;
