import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes, spacing },
} = getTheme();

export const DropdownContainer = styled.div`
  max-width: 100%;
`;

export const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  & svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: ${spacing.x7};
  }
`;

export const StyledTitle = styled.h2`
  ${fontTypes.font4Medium};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  white-space: break-spaces;
`;

export const StyledSummary = styled.h2`
  ${fontTypes.font3Medium};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const StyledSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
