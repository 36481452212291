/**
 * @description
 * Converts camelCase text to normal-case
 * Pre: Text must be in camelCase case
 * @param {String} text Text to be parsed
 */
const camelToNormalCase = (text: string): string =>
  text.replace(/([A-Z])/g, (x) => ` ${x.toLowerCase()}`);

export default camelToNormalCase;
