import React from 'react';
import BaseCard from '../BaseCard';
import { TaSessionsTitle, TaSessionsLink, TaSessionsIcon } from './TaSessionsCard.styles';

const TaSessionsCard = ({ iconUrl, onClick }: { iconUrl: string; onClick: () => void }) => (
  <BaseCard icon={<TaSessionsIcon src={iconUrl} alt='Teacher Assistant' />}>
    <TaSessionsTitle>Get Help With a Course</TaSessionsTitle>
    <TaSessionsLink onClick={onClick}>Book a TA Session</TaSessionsLink>
  </BaseCard>
);

export default TaSessionsCard;
