import type { UniversityState } from 'commons/types/universityTypes';
import { getUniversitiesTypes, getUniversityTypes } from './types';

export const initialState: UniversityState = {
  universities: {
    isLoading: false,
    data: [],
    error: '',
  },
  university: {
    isLoading: false,
    data: null,
    error: '',
  },
};
type Action = {
  type: string;
  payload: any;
};

const universityReducer = (state: UniversityState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getUniversitiesTypes.REQUEST: {
      return { ...state, universities: { ...state.universities, isLoading: true } };
    }

    case getUniversitiesTypes.SUCCESS: {
      return { ...state, universities: { ...state.universities, isLoading: false, data: payload } };
    }

    case getUniversitiesTypes.FAILURE: {
      return {
        ...state,
        universities: {
          isLoading: false,
          error: payload,
          data: '',
        },
      };
    }

    case getUniversityTypes.REQUEST: {
      return { ...state, university: { ...state.university, isLoading: true } };
    }

    case getUniversityTypes.SUCCESS: {
      return { ...state, university: { ...state.university, isLoading: false, data: payload } };
    }

    case getUniversityTypes.FAILURE: {
      return {
        ...state,
        university: {
          isLoading: false,
          error: payload,
          data: '',
        },
      };
    }

    default:
      return state;
  }
};

export default universityReducer;
