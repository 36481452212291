import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import type {
  GlobalPartner,
  Membership,
  PartnerInstance,
  PartnerInstanceSection,
  UserDetails,
} from 'commons/types';
import { partnerInstanceActions } from 'ducks/partnerInstance/actions';
import { usersActions } from 'ducks/user/actions';
import { useIsUniversityAdmin, useServerPagination } from 'utils/customHooks/index';
import { getAppSettings, getPartnerId } from 'utils/privateLabel/index';
import { deleteCookie, setCookie } from 'utils/cookies';
import { baseUrl } from 'settings';
import { CHROME_COOKIE_MAX_AGE } from 'commons/constants';

export const useGetPartnerInstanceDetails = ({ partnerId }: { partnerId: string }) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { data: paginatedMemberships, isMembershipsLoading } = useSelector(
    (state) => state.partnerInstance.paginatedMemberships,
  );
  const {
    data: partnerInstance,
    isLoading: isPartnerInstanceLoading,
  }: {
    data: PartnerInstance | null;
    isLoading: boolean;
  } = useSelector((state) => state.partnerInstance.partnerInstance);

  const { pageContent: memberships } = useServerPagination({
    response: paginatedMemberships,
  });

  const refreshData = () => {
    dispatch(
      partnerInstanceActions.getPaginatedPartnerMembershipsRequest({
        partnerId,
        actualPage: 1,
      }),
    );
    dispatch(partnerInstanceActions.getPartnerInstanceRequest(partnerId));
  };

  React.useEffect(() => {
    if (
      (!memberships || !memberships.every((membership) => membership.partnerId === partnerId)) &&
      !isMembershipsLoading
    ) {
      dispatch(
        partnerInstanceActions.getPaginatedPartnerMembershipsRequest({
          partnerId,
          actualPage: 1,
        }),
      );
    }
  }, [memberships, isMembershipsLoading, partnerId]);

  React.useEffect(() => {
    if (
      (!partnerInstance || partnerInstance.partnerId !== partnerId) &&
      !isPartnerInstanceLoading
    ) {
      dispatch(partnerInstanceActions.getPartnerInstanceRequest(partnerId));
    }
  }, [partnerInstance, isPartnerInstanceLoading, partnerId]);

  const membershipCount = partnerInstance ? partnerInstance.membersCount : null;
  const remainingSeats = partnerInstance ? partnerInstance.remainingSeats : null;
  const mode = partnerInstance ? partnerInstance.mode : null;
  const totalEnrollments = partnerInstance ? partnerInstance.totalEnrollments : null;

  return {
    membershipCount,
    remainingSeats,
    partnerInstance,
    memberships,
    mode,
    totalEnrollments,
    isMembershipsLoading,
    isPartnerInstanceLoading,
    refreshData,
    activeMembersCount: partnerInstance ? partnerInstance?.activeMembersCount : null,
    neverLoggedInCount: partnerInstance ? partnerInstance?.neverLoggedInCount : null,
  };
};

export const useGetMemberData = ({
  partnerId,
  memberId,
}: {
  partnerId: string;
  memberId: string;
}) => {
  // @ts-ignore
  const history = useHistory();
  // @ts-ignore
  const dispatch = useDispatch();

  const [hasRequestedMember, setHasRequestedMember] = React.useState(false);

  const {
    isLoading: isMembershipsLoading,
    data: memberships,
  }: {
    isLoading: boolean;
    data: Membership[];
  } = useSelector((state) => state.partnerInstance.memberships);
  const {
    isLoading: isPartnerInstanceLoading,
    data: partnerInstance,
  }: {
    isLoading: boolean;
    data: PartnerInstance;
  } = useSelector((state) => state.partnerInstance.partnerInstance);

  const membership = memberships?.find((member) => String(member.userId) === String(memberId));

  React.useEffect(() => {
    dispatch(
      partnerInstanceActions.getPartnerMembershipsRequest({
        partnerId,
        memberId,
      }),
    );
    setHasRequestedMember(true);
  }, [setHasRequestedMember]);

  React.useEffect(() => {
    if (!isMembershipsLoading && hasRequestedMember && !membership) {
      history.push('/404');
    }
  }, [memberships, isMembershipsLoading, membership, hasRequestedMember]);

  React.useEffect(() => {
    if (!partnerInstance) {
      dispatch(partnerInstanceActions.getPartnerInstanceRequest(partnerId));
    }
  }, [partnerInstance]);

  return {
    isMembershipsLoading,
    membership,
    isPartnerInstanceLoading,
    partnerInstance,
  };
};

export const useGetUserDetails = ({
  userId,
  partnerId,
}: {
  userId?: string | number;
  partnerId?: string;
}) => {
  // @ts-ignore
  const dispatch = useDispatch();
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
  }: {
    data: UserDetails;
    isLoading: boolean;
    error: any;
  } = useSelector((state) => state.user.userDetails);
  React.useEffect(() => {
    if (partnerId && userId !== null && userId !== undefined) {
      dispatch(
        usersActions.getUserDetailsRequest({
          partnerId,
          userId,
        }),
      );
    }
  }, [userId, partnerId]);
  return {
    userDetails,
    isUserDetailsLoading,
    userDetailsError,
  };
};

export const useGetSectionData = (sectionId: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore
  const {
    data,
    // @ts-ignore
    isLoading,
    error,
  }: {
    data: PartnerInstanceSection;
    error: Record<string, any>;
  } = useSelector((state) => state.partnerInstance.section);

  if (error) {
    history.push('/404');
  }

  React.useEffect(() => {
    dispatch(
      partnerInstanceActions.getSectionByIdRequest({
        sectionId,
      }),
    );
  }, [sectionId]);
  return {
    section: data,
    isLoading,
  };
};

export const useGetLearners = ({
  sectionLearners,
  memberships,
}: {
  sectionLearners?: {
    id: string;
  }[];
  memberships: Membership[];
}) => {
  // Merging memberships and section learner information together as it doesn't
  // make sense to have learner_status on the memberships model on the BE
  // @ts-ignore
  const learners = sectionLearners
    ? memberships.reduce((acc, curr) => {
        const member = sectionLearners.find(
          ({ id: sectionLearnerId }) => String(curr.userId) === String(sectionLearnerId),
        );

        if (member) {
          acc.push({
            ...curr,
            ...member,
            id: curr.id,
          });
        }

        return acc;
      }, [])
    : [];

  return {
    learners,
  };
};

export const useFetchPartnerInstancesData = () => {
  const dispatch = useDispatch();
  const isUniversityAdmin = useIsUniversityAdmin();
  // @ts-ignore
  const { partnerId: partnerIdParam } = useParams();
  const {
    data: partnerInstances,
  }: {
    data: PartnerInstance[];
    isLoading: boolean;
    error: any;
  } = useSelector((state) => state.partnerInstance.partnerInstances);
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);

  const config = getAppSettings() || globalPartner;
  // @ts-ignore
  const { partnerId } = getPartnerId() || config.partnerId;
  const partnerInstancesList = isUniversityAdmin // flow things
    ? (partnerInstances as any)?.filter((instance) => instance.isActive)
    : [
        {
          name: config.companyName,
          partnerId,
        },
      ];

  React.useEffect(() => {
    if (isUniversityAdmin && !partnerInstancesList?.length) {
      dispatch(partnerInstanceActions.getPartnerInstancesRequest());
    }
  }, []);

  const {
    data: selectedPartnerInstance,
    isLoading: isPartnerInstanceLoading,
    error: partnerInstanceError,
  }: {
    data: PartnerInstance | null;
    isLoading: boolean;
    error: any;
  } = useSelector((state) => state.partnerInstance.partnerInstance);

  React.useEffect(() => {
    if (
      (!selectedPartnerInstance || selectedPartnerInstance.partnerId !== partnerIdParam) &&
      !isPartnerInstanceLoading &&
      !partnerInstanceError &&
      partnerIdParam
    ) {
      dispatch(partnerInstanceActions.getPartnerInstanceRequest(partnerIdParam));
    }
  }, [selectedPartnerInstance, partnerIdParam, isPartnerInstanceLoading, partnerInstanceError]);

  const selectedInstance =
    partnerInstances?.find((partnerInstance) => partnerInstance.partnerId === partnerIdParam) ||
    partnerInstances?.find((partnerInstance) => partnerInstance.partnerId === partnerId);

  return {
    selectedPartnerInstance,
    selectedInstance,
    partnerInstancesList,
  };
};

/**
 * Custom hook to set or delete cookies related to the private label instance when the window gains focus.
 * This is to avoid ID issues with the Social App when the user switches between tabs or is logged into multiple instances.
 *
 * @param partner - The partner object containing information about the partner, including IDs and URLs.
 *                  It should conform to the `GlobalPartner` type.
 *
 * @remarks
 * - The `partnerId` and `partnerUrl` cookies are always set with a maximum age suitable for Chrome.
 * - If the `partner` object contains a `parentPartnerId`, a cookie with that ID is set.
 * - If the `partner` object does not contain a `parentPartnerId`, the `parentPartnerId` cookie is deleted.
 * - This hook uses the `focus` event to trigger the cookie setting/deletion, ensuring it runs when the window is in focus.
 */
export const useSetPrivateInstanceSocialAppCookiesOnFocus = (partner: GlobalPartner): void => {
  const handleFocus = (): void => {
    setCookie('partnerId', String(partner?.partnerId), CHROME_COOKIE_MAX_AGE);
    // Setting this to max chrome cookie age as this is being used for Moodle authentication
    // purposes, and does not impact auth whatsoever
    setCookie('partnerUrl', String(baseUrl), CHROME_COOKIE_MAX_AGE);

    if (partner?.parentPartnerId) {
      setCookie('parentPartnerId', String(partner?.parentPartnerId), CHROME_COOKIE_MAX_AGE);
    } else {
      deleteCookie('parentPartnerId');
    }
  };

  React.useEffect(() => {
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [partner]);
};
