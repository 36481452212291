import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import { PAGINATION_LIMIT } from 'commons/constants';
import type {
  GetAllLandingPagesParams,
  LandingPageObject,
  LandingPageListResponse,
} from 'commons/types';

export default class LandingPage {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getAll({
    page = 1,
    pageSize,
    search,
  }: GetAllLandingPagesParams): Promise<LandingPageListResponse> {
    const filter = search
      ? `&filter=[{"or": [{"name": "title", "op": "ilike", "val": "%25${search}%25"},{"name": "slug", "op": "like", "val": "%25${search}%25"}]}]`
      : '';
    const paginationLimit = pageSize === undefined ? PAGINATION_LIMIT : pageSize;
    const query = `/articles/l?page[number]=${page}&page[size]=${paginationLimit}${filter}`;
    const response = await this.jsonApi.get(query);
    const landingPagesObject = get(response, 'entities.landingPage', {});
    const count = get(response, 'entities.count', 0);
    const landingPages: LandingPageObject[] = Object.values(landingPagesObject) as any;
    return {
      list: landingPages,
      count,
    };
  }

  async getBySlug(slug: string): Promise<{
    landingPage: LandingPageObject;
  }> {
    const param = slug || 'articles';
    const response = await this.jsonApi.get(`/articles/l/${param}?include=children`);
    const landingPage: LandingPageObject = Object.values(
      get(response, 'entities.landingPage', {}),
    )[0] as any;

    if (!landingPage) {
      throw new Error('Page does not exist');
    }

    const children = get(response, 'entities.landingPageChild', {});
    landingPage.children = Object.values(children) as any;
    return {
      landingPage,
    };
  }

  async create(landingPage: LandingPageObject): Promise<{
    landingPage: LandingPageObject;
  }> {
    const landingPageData = await this.jsonApi.post('/articles/l', {
      landingPage,
    });
    const createdLandingPage: LandingPageObject = Object.values(
      get(landingPageData, 'entities.landingPage', {}),
    )[0] as any;
    return {
      landingPage: createdLandingPage,
    };
  }

  async update(landingPage: LandingPageObject): Promise<{
    landingPage: LandingPageObject;
  }> {
    const { id } = landingPage;
    const landingPageData = await this.jsonApi.post('/articles/l', {
      landingPage,
    });
    return {
      landingPage: get(landingPageData, `entities.landingPage.${id}`),
    };
  }
}
