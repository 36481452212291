import type { GlobalSearchPayload } from 'commons/types';
import { getGlobalSearchTypes, globalSearchTypes } from './types';

const globalSearchActions = {
  getGlobalSearchRequest: (payload: GlobalSearchPayload) => ({
    type: getGlobalSearchTypes.REQUEST,
    payload,
  }),
  getGlobalSearchSuccess: (payload: any) => ({
    type: getGlobalSearchTypes.SUCCESS,
    payload,
  }),
  getGlobalSearchFail: (payload: string) => ({
    type: getGlobalSearchTypes.FAILED,
    payload,
  }),
  setCurrentPage: (payload: number) => ({
    type: globalSearchTypes.RESULTS_PAGE,
    payload,
  }),
  setCurrentTaxonomy: (payload: string) => ({
    type: globalSearchTypes.SET_CURRENT_TAXONOMY,
    payload,
  }),
  resetSearchResults: () => ({
    type: globalSearchTypes.RESET_SEARCH,
  }),
};
export default globalSearchActions;
