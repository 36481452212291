import JsonApi from 'utils/jsonApi';
import get from 'lodash.get';

export default class AdminPartnerProgram {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getPartnerProgramTypes(): Promise<any> {
    const response = await this.jsonApi.get('/noodle_partners_programs_types?page[size]=1000');
    const partnerProgramTypesObj = get(response, 'entities.noodlePartnersProgramTypes', {});
    const partnerProgramTypes = Object.values(partnerProgramTypesObj) || [];
    return partnerProgramTypes;
  }

  async getPartnerPrograms(payload: string): Promise<any[]> {
    const filter = payload
      ? `filter=[{"or": [{"name": "program_category", "op": "ilike", "val": "%25${payload}%25"}, {"name": "program_name", "op": "ilike", "val": "%25${payload}%25"}]}]`
      : '';
    const response = await this.jsonApi.get(`/noodle_partners_programs?${filter}`);
    const partnerProgramsObj = get(response, 'entities.noodlePartnersProgram', {});
    const partnerPrograms: Record<string, any>[] = Object.values(partnerProgramsObj);
    return partnerPrograms;
  }

  async getPartnerProgram(payload: string): Promise<any> {
    const response = await this.jsonApi.get(`/noodle_partners_programs/${payload}`);
    const partnerProgramObj = get(response, 'entities.noodlePartnersProgram', {});
    const partnerProgram: Record<string, any> = Object.values(partnerProgramObj)[0];
    const programTypesRes = await this.jsonApi.get(
      `/noodle_partners_programs/${payload}/relationships/noodle_partners_program_types`,
    );
    const partnerProgramTypesArr = get(programTypesRes, 'entities.noodlePartnersProgramTypes', {});
    const partnerProgramTypes = Object.values(partnerProgramTypesArr) || [];
    return { ...partnerProgram, partnerProgramTypes };
  }

  async addPartnerProgramType(payload: Record<string, any>): Promise<any> {
    const response = await this.jsonApi.post(
      `/noodle_partners_programs/${payload.partnerProgramId}/relationships/noodle_partners_program_types`,
      payload.data,
      false,
    );
    const partnerProgramType = get(response, 'entities.partnerProgram.1', {});
    return partnerProgramType;
  }

  async deletePartnerProgramType(payload: Record<string, any>): Promise<any> {
    const response = await this.jsonApi.delete(
      `/noodle_partners_programs/${payload.partnerProgramId}/relationships/noodle_partners_program_types`,
      {
        data: payload.data,
      },
    );
    const partnerProgramType = get(response, 'entities.partnerProgram.1', {});
    return partnerProgramType;
  }
}
