import * as React from 'react';

// Components
import Avatar from 'components/Avatar';
import { ReactComponent as UserPlaceholderIcon } from 'assets/svg/user_placeholder.svg';

// Styles
import {
  UserTagWrapper,
  UserTagImageContainer,
  UserTagDetailsContainer,
  UserTagTitle,
  UserTagSubtitle,
  StyledSvgImageWrapper,
} from './UserTag.styles';

interface UserTagProps {
  img: string;
  className?: string;
  title: string;
  subtitle?: React.ReactElement<any> | string;
  optionalText?: string;
  size?: string;
  customFont?: string;
  colorTitle?: string;
  icon?: React.ReactElement<any>;
  id?: string;
  isHidden?: boolean;
  alt?: string;
}

const UserTag: React.FC<UserTagProps> = ({
  img,
  title,
  subtitle,
  optionalText,
  size = '48px',
  alt = '',
  customFont,
  colorTitle,
  className,
  icon = <UserPlaceholderIcon />,
  id,
  isHidden,
}) => {
  const AvatarComp: React.FC = () =>
    img ? (
      <Avatar
        objectFit={isHidden ? 'contain' : 'cover'}
        height={48}
        width={48}
        img={img}
        alt={alt}
      />
    ) : (
      <StyledSvgImageWrapper>{icon}</StyledSvgImageWrapper>
    );

  return (
    <UserTagWrapper id={id} className={className}>
      <UserTagImageContainer size={size}>
        <AvatarComp />
      </UserTagImageContainer>
      <UserTagDetailsContainer>
        <UserTagTitle customFont={customFont} colorTitle={colorTitle}>
          {title}
        </UserTagTitle>
        {subtitle && <UserTagSubtitle className='user-tag__subtitle'>{subtitle}</UserTagSubtitle>}
        {optionalText ? <UserTagSubtitle>{optionalText}</UserTagSubtitle> : null}
      </UserTagDetailsContainer>
    </UserTagWrapper>
  );
};

export default UserTag;
