export const getUserTokenTypes = {
  GET_USER_TOKEN_REQUEST: 'GET_USER_TOKEN_REQUEST',
  GET_USER_TOKEN_SUCCESS: 'GET_USER_TOKEN_SUCCESS',
  GET_USER_TOKEN_FAILURE: 'GET_USER_TOKEN_FAILURE',
};
export const getTeamFeedPostsTypes = {
  GET_TEAM_FEED_POSTS_REQUEST: 'GET_TEAM_FEED_POSTS_REQUEST',
  GET_TEAM_FEED_POSTS_SUCCESS: 'GET_TEAM_FEED_POSTS_SUCCESS',
  GET_TEAM_FEED_POSTS_FAILURE: 'GET_TEAM_FEED_POSTS_FAILURE',
};
export const getUserFeedPostsTypes = {
  GET_USER_FEED_POSTS_REQUEST: 'GET_USER_FEED_POSTS_REQUEST',
  GET_USER_FEED_POSTS_SUCCESS: 'GET_USER_FEED_POSTS_SUCCESS',
  GET_USER_FEED_POSTS_FAILURE: 'GET_USER_FEED_POSTS_FAILURE',
};
export const getMoreCommentsTypes = {
  GET_MORE_COMMENTS_REQUEST: 'GET_MORE_COMMENTS_REQUEST',
  GET_MORE_COMMENTS_SUCCESS: 'GET_MORE_COMMENTS_SUCCESS',
  GET_MORE_COMMENTS_FAILURE: 'GET_MORE_COMMENTS_FAILURE',
};
export const getMorePostsTypes = {
  GET_MORE_POSTS_REQUEST: 'GET_MORE_POSTS_REQUEST',
  GET_MORE_POSTS_SUCCESS: 'GET_MORE_POSTS_SUCCESS',
  GET_MORE_POSTS_FAILURE: 'GET_MORE_POSTS_FAILURE',
};
export const createPostTypes = {
  CREATE_POST_REQUEST: 'CREATE_POST_REQUEST',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',
};
export const updateActivityCommentsTypes = {
  UPDATE_ACTIVITY_COMMENTS_REQUEST: 'UPDATE_ACTIVITY_COMMENTS_REQUEST',
  UPDATE_ACTIVITY_COMMENTS_SUCCESS: 'UPDATE_ACTIVITY_COMMENTS_SUCCESS',
  UPDATE_ACTIVITY_COMMENTS_FAILURE: 'UPDATE_ACTIVITY_COMMENTS_FAILURE',
};
export const getStreamTypes = {
  TOGGLE_CREATE_POST_MODAL: 'TOGGLE_CREATE_POST_MODAL',
  TOGGLE_CREATE_COMMENT_STATUS: 'TOGGLE_CREATE_COMMENT_STATUS',
  START_POLLING_NOTIFICATIONS: 'START_POLLING_NOTIFICATIONS',
};
export const getUserNotificationsTypes = {
  GET_USER_NOTIFICATIONS_REQUEST: 'GET_USER_NOTIFICATIONS_REQUEST',
  GET_USER_NOTIFICATIONS_SUCCESS: 'GET_USER_NOTIFICATIONS_SUCCESS',
  GET_USER_NOTIFICATIONS_FAILURE: 'GET_USER_NOTIFICATIONS_FAILURE',
};
