// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';

const {
  palette: { blue, greyscale, red },
  noodera,
  fontColors,
} = getTheme();
const SearchBarTopNavWrapper: StatelessFunctionalComponent<any> = styled.div`
  animation-delay: 300ms;
  animation-duration: 200ms;
  align-items: center;
  border: 0.1rem solid ${greyscale.silver};
  border-radius: 0.4rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 4.2rem;
  justify-content: space-between;
  padding-left: 1rem;
  width: 100%;

  &&& {
    outline-style: none;
  }

  .sb {
    &__close {
      animation-delay: 750ms;
      animation-duration: 100ms;
      cursor: pointer;
      font-size: 2.7rem;
      font-weight: 100;
      outline-style: none;
    }
    &__icon {
      color: ${greyscale.smoke};
      cursor: pointer;
      margin-right: 1rem;
    }
    &__input {
      flex: 1;
      border: none;
      caret-color: ${blue.regatta};
      font-size: 1.6rem;
      height: 3.5rem;
      outline-style: none;
    }
    &__search {
      align-items: center;
      animation-delay: 500ms;
      animation-duration: 100ms;
      border-radius: 0 0.8rem 0.8rem 0;
      color: ${fontColors.primary};
      display: flex;
      font-size: 2.7rem;
      height: 4.2rem;
    }
    &__wrapper_left {
      align-items: center;
      display: flex;
      flex: 10;
      flex-direction: row;
      justify-content: flex-start;
    }
    &__wrapper_right {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      span {
        color: ${red.default};
      }
    }
    @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
      &__wrapper_left {
        input {
          background: transparent;
        }
      }

      &__search-btn,
      &__close-btn {
        background: transparent;
        border: none;
        margin-right: ${noodera.spacing.x7};
      }
    }
  }
`;
export const ContainerSearchButtonTopBar: StatelessFunctionalComponent<any> = styled.div`
  button {
    background: none;
    border-color: transparent;
  }
`;
export default SearchBarTopNavWrapper;
