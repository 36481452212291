import styled from 'styled-components';
import { TABLET_SMALL_WIDTH } from 'commons/constants';
import NoodleTextField from 'components/NoodleTextField';

export const HeaderTitle = styled.h1`
  ${({ theme }) => theme.noodera.fontTypes.font3Medium}
  margin: ${({ theme }) => theme.noodera.spacing.x5} 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${({ theme }) => theme.noodera.spacing.x6};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${({ theme }) => theme.noodera.spacing.x6};
    padding-bottom: ${({ theme }) => theme.noodera.spacing.x1};
  }
`;

export const StyledContainer = styled.div`
  padding: 0 ${({ theme }) => theme.noodera.spacing.x3} EXTERNAL_FRAGMENT_0;
  min-width: 768px;
  max-width: 768px;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: 0;
    width: 100%;
    min-width: auto;
  }
`;

export const UpdateUserContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormHeaderTitle = styled.h2`
  ${({ theme }) => theme.noodera.fontTypes.font3Medium};
  margin-top: ${({ theme }) => theme.noodera.spacing.x4};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x4};
`;

export const StyledInputHeader = styled.p`
  ${({ theme }) => theme.noodera.fontTypes.font4Medium};
  color: ${({ theme }) => theme.palette.neutral.eighty};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x7};
`;

export const InputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x3};
  margin-top: ${({ theme }) => theme.noodera.spacing.x3};
`;

export const StyledNoodleTextField = styled(NoodleTextField.Controlled)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x3};
`;

export const Label = styled.label`
  display: block;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
`;

export const HintTextContainer = styled.p`
  ${({ theme }) => theme.noodera.fontTypes.font5}
  color: ${(props) => props.theme.palette.greyscale.osloGray};
`;
