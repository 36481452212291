// @ts-nocheck
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import { urlComposer, camelToUnderdashCase, underdashToCamelCase } from 'utils/string';
import { fullName } from 'utils/user/name';
import { EDUCATION_TYPES_ENUM, adminNoodlingConstants } from 'commons/constants';
import type {
  AdminNoodlingResponseObject,
  CategoryObject,
  GetAdminNoodlingsRequestPayload,
  GetAdminNoodlingsSuccessPayload,
  GetSuggestionsRequestPayload,
  NoodlingAssociations,
  RawAdminNoodlingAssociation,
  AdminNoodlingRecommendation,
  SuggestionType,
  SuggestionGetConfig,
} from 'commons/types';

const {
  FILTER_ENTITIES: { REGION, TAXONOMY },
  PERSONA_FIELDS: { PERSONA_REGION },
  RECOMMENDATION_TYPES_MAP,
} = adminNoodlingConstants;

const getPersonaFilter = (filters: Record<string, any>): string => {
  const parsedFilters = Object.keys(filters).map((key) => {
    const name = camelToUnderdashCase(key);
    const val = name === PERSONA_REGION ? filters[key].label : filters[key].value;
    return {
      name,
      op: 'eq',
      val,
    };
  });
  return parsedFilters.length > 0
    ? `[{"name":"persona","op":"has","val":{"and":${JSON.stringify(parsedFilters)}}}]`
    : '';
};

export const getTextContext = (
  recommendations: AdminNoodlingRecommendation[],
  type: string,
): string => {
  const item = recommendations.find(({ recommendationType }) => recommendationType === type);
  return item ? item.textContext : '';
};

const getSuggestionsConfig = (entity: string, searchValue: string): SuggestionGetConfig => {
  let urlParts = {};

  switch (entity) {
    case REGION:
      urlParts = {
        endpoint: '/personas/regions',
        filterField: 'region',
        fieldName: 'region',
      };
      break;

    case TAXONOMY:
      urlParts = {
        endpoint: '/taxonomies',
        filterField: 'display_name',
        fieldName: 'displayName',
      };
      break;

    default:
      break;
  }

  const { endpoint, filterField, fieldName } = urlParts;
  const page = '?page[size]=0';
  const filter = `&filter=[{"name":"${filterField}","op":"ilike","val":"%25${searchValue}%25"}]`;
  return {
    url: endpoint + page + filter,
    fieldName,
  };
};

export const parseAssociations = (
  rawAssociations: RawAdminNoodlingAssociation[],
  recommendations: AdminNoodlingRecommendation[],
): NoodlingAssociations => {
  const associations = {};
  rawAssociations.forEach(({ associationType, results }) => {
    const assocKey = underdashToCamelCase(associationType.toLowerCase());

    if (associations[assocKey]) {
      associations[assocKey].data.push(results[0]);
    } else {
      associations[assocKey] = {
        data: results,
        textContext: getTextContext(recommendations, RECOMMENDATION_TYPES_MAP[associationType]),
      };
    }
  });
  return associations;
};
export default class AdminNoodling {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getById(noodlingId: string): Promise<GetAdminNoodlingsSuccessPayload> {
    const includes = ['author', 'noodling_associations', 'persona', 'recommendations'].toString();
    const response = await this.jsonApi.get(
      urlComposer(`/noodlings/${noodlingId}`, {
        includes,
      }),
    );
    const noodling = get(response, `entities.noodling.${noodlingId}`, {});
    const { authorId, personaId } = noodling;
    const authorProfile = get(response, `entities.user.${authorId}.profile`, {});
    const associations: RawAdminNoodlingAssociation[] = Object.values(
      get(response, 'entities.noodlingAssociations', {}),
    ) as any;
    const recommendations: AdminNoodlingRecommendation[] = Object.values(
      get(response, 'entities.recommendation', {}),
    ) as any;
    noodling.persona = get(response, `entities.persona.${personaId}`, {});
    noodling.associations = parseAssociations(associations, recommendations);
    noodling.author = {
      value: fullName(authorProfile),
      link: `/profile/${authorId}`,
    };
    noodling.textContext = getTextContext(recommendations, RECOMMENDATION_TYPES_MAP.PERSONA);
    delete noodling.noodlingAssociations;
    delete noodling.recommendations;
    return noodling;
  }

  async getNoodlings({
    page = 1,
    filters = {},
  }: GetAdminNoodlingsRequestPayload): Promise<GetAdminNoodlingsSuccessPayload> {
    const includes = ['author', 'persona', 'persona.taxonomy'].toString();
    const filter = getPersonaFilter(filters);
    const url = urlComposer('/noodlings', {
      page,
      includes,
      filter,
    });
    const response = await this.jsonApi.get(url);
    const noodlings: AdminNoodlingResponseObject[] = Object.values(
      get(response, 'entities.noodling', {}),
    ) as any;
    const authors = get(response, 'entities.user', {});
    const personas = get(response, 'entities.persona', {});
    const taxonomies = get(response, 'entities.taxonomy', {});
    const list = noodlings.map(({ id, authorId, personaId }) => {
      const persona = personas[personaId] || {};
      const author = authors[authorId] || {};
      const taxonomy = taxonomies[persona.taxonomyId] || {};
      return {
        id,
        personaId,
        authorName: fullName(author.profile),
        educationType: EDUCATION_TYPES_ENUM[persona.educationType],
        taxonomy: taxonomy.displayName,
      };
    });
    const count = get(response, 'entities.count', 0);
    return {
      list,
      count,
    };
  }

  async getSuggestions({
    searchValue,
    entity,
  }: GetSuggestionsRequestPayload): Promise<SuggestionType[]> {
    const { url, fieldName } = getSuggestionsConfig(entity, searchValue);
    const response = await this.jsonApi.get(url);
    const entities: CategoryObject[] = Object.values(
      get(response, `entities.${entity}`, {}),
    ) as any;
    return entities.map(({ id, [fieldName]: name }) => ({
      id,
      name,
    }));
  }
}
