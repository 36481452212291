// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALL_WIDTH, CELLPHONE_LARGE_WIDTH } from 'commons/constants';

const {
  noodera: { spacing },
} = getTheme();
export const HomeWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 1024px;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin: ${spacing.x7} ${spacing.x5} 0;
    min-height: calc(100vh - 56px);
    height: unset;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    margin-bottom: ${spacing.x1};
  }
`;
export const HomeContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    overflow-x: visible;

    & > div > div:nth-child(1) > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    max-width: 100%;
  }
`;
export const HomeInnerContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  min-width: 362px;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    min-height: calc(100vh - 160px);
    min-width: 100%;

    & > div {
      padding: ${(props) => (props.team ? '32px 0 0' : 0)};
    }
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    min-width: 100%;
  }
`;
