// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

const {
  palette: { neutral },
  noodera: { fontTypes },
} = getTheme();

export const StyledDatePicker: React.FC<any> = styled(KeyboardDatePicker).attrs(() => ({
  KeyboardButtonProps: { 'aria-label': 'Change date' },
  leftArrowButtonProps: { 'aria-label': 'Previous Month' },
  rightArrowButtonProps: { 'aria-label': 'Next Month' },
  previousYearButtonProps: { 'aria-label': 'Previous Year' },
  nextYearButtonProps: { 'aria-label': 'Next Year' },
  TodayButtonProps: { 'aria-label': 'Select Today' },
}))`
  && {
    width: 100%;

    input:disabled {
      background-color: transparent;
    }

    .MuiOutlinedInput-root {
      border-radius: 8px;
      height: 48px;
    }
    .MuiInputBase-input {
      ${fontTypes.font4};
      color: ${neutral.eighty};

      &::focus {
        outline: 0;
      }
    }
    .MuiOutlinedInput-adornedEnd {
      padding: 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${neutral.forty};
    }
  }
`;
