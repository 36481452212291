// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import type { Saga } from 'redux-saga';
import { push } from 'connected-react-router';
import { alertTypesConstants } from 'commons/constants/index';
import { getCounselorTypes } from './types';
import { counselorActions } from './actions';

const { DANGER } = alertTypesConstants;
export function* getCounselorSaga({ payload }: { payload: Record<string, any> }): Saga<void> {
  try {
    const response = yield call([api.counselor, 'getCounselor'], payload);
    yield put(counselorActions.getCounselorSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the Counselor Page!',
        type: DANGER,
      }),
    );
    yield put(counselorActions.getCounselorFailed(error));
    yield put(push('/404'));
  }
}
export default function* counselorSaga(): Saga<void> {
  yield takeLatest(getCounselorTypes.REQUEST, getCounselorSaga);
}
