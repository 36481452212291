export const mergeArraysNotRepeated = (
  sourceArray: string[] | null,
  destinationArray: string[],
): string[] => {
  if (sourceArray && sourceArray.length) {
    sourceArray.forEach((element) => {
      if (!destinationArray.includes(element)) {
        destinationArray.push(element);
      }
    });
  }

  return destinationArray;
};
export const randomize = (array: any[]): any[] => {
  let i = array.length - 1;
  const newArray = [...array];

  for (i; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = temp;
  }

  return newArray;
};
export const consolidate2dArray = (arr: any[], name: string, value: string) => {
  const output = [];
  arr.forEach((item) => {
    const existing = output.filter((v) => v[name] === item[name]);

    if (existing.length) {
      const existingIndex = output.indexOf(existing[0]);
      output[existingIndex][value] = output[existingIndex][value].concat(item[value]);
    } else {
      if (typeof item[value] === 'string') {
        // eslint-disable-next-line no-param-reassign
        item[value] = [item[value]];
      }

      output.push(item);
    }
  });
  return output;
};
export const addStringToArrayNoDuplicates = (arr: string[], value: string): string[] => [
  ...new Set([...arr, value]),
];
export const consolidateTwoArraysOfObjects = (arrayOne, arrayTwo) => {
  // O(n) time complexity. Goes through each array once.
  const dictionary = {};
  arrayOne.forEach((object) => {
    if (!dictionary[object.id]) {
      dictionary[object.id] = object;
    }
  });

  arrayTwo.forEach((object) => {
    if (!dictionary[object.id]) {
      dictionary[object.id] = object;
    }
  });
  return Object.values(dictionary);
};
