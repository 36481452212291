// @ts-nocheck
import * as React from 'react';
// Icons
import LockIcon from '@mui/icons-material/Lock';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RadioButton from 'components/RadioButton';
import type { PrivacyProps } from './Privacy.types';
import { Section, Title } from './Privacy.styles';

const privacyItems = [
  {
    name: 'Private',
    value: 'private',
    subtitle:
      "Only members you invite, or approve, can access what's posted in the team. In order to protect the privacy of team members you can not change a public team to private once created.",
    linkText: '',
    icon: <LockIcon />,
  },
  {
    name: 'Public',
    value: 'public',
    subtitle: 'Public teams can only be created by Noodle Experts.',
    linkText: (
      <a
        href='https://info.noodle.com/expert-profile?hs_preview=GpdHXobD-5986925844'
        target='_blank'
        rel='noopener noreferrer'
      >
        Learn more
      </a>
    ),
    icon: <LanguageIcon />,
    disabled: true,
  },
];
const visibilityItems = [
  {
    name: 'Visible',
    value: 'visible',
    subtitle: 'Anyone can find this team under Browse, and request to join.',
    linkText: '',
    icon: <VisibilityIcon />,
    disabled: false,
  },
  {
    name: 'Hidden',
    value: 'hidden',
    subtitle: 'Only invited members can find this team.',
    linkText: '',
    icon: <VisibilityOffIcon />,
    disabled: false,
  },
];

const Privacy = ({ data, onChange }: PrivacyProps): React.ReactElement<any> | null => {
  const handleChange = (section, value): void => {
    const newItems = { ...data };
    Object.keys(newItems[section]).forEach((property) => {
      newItems[section][property] = property === value;
    });
    onChange(newItems);
  };

  const renderRadioButtons = (title, itemList, defaultSelected): React.ReactElement<any> | null => (
    <Section>
      <Title>{title}</Title>
      <RadioButton.CustomContent
        name={title}
        items={itemList}
        size='medium'
        onChange={handleChange}
        defaultValue={defaultSelected}
      />
    </Section>
  );

  const defaultSelectedPrivacyValue = 'private';
  return (
    <div>
      {renderRadioButtons('Privacy', privacyItems, defaultSelectedPrivacyValue)}
      {renderRadioButtons('Visibility', visibilityItems)}
    </div>
  );
};

export default Privacy;
