export const getUniversitiesTypes = {
  REQUEST: 'GET_UNIVERSITIES_REQUEST',
  SUCCESS: 'GET_UNIVERSITIES_SUCCESS',
  FAILURE: 'GET_UNIVERSITIES_FAILURE',
};
export const getUniversityTypes = {
  REQUEST: 'GET_UNIVERSITY_REQUEST',
  SUCCESS: 'GET_UNIVERSITY_SUCCESS',
  FAILURE: 'GET_UNIVERSITY_FAILURE',
};
