// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import { getCoursePreviewSaga } from 'ducks/courses/saga';
import api from 'lib/Api';
import {
  getOnboardingSurveyActionTypes,
  sendSurveyAnswerActionTypes,
  sendSurveyAnswersActionTypes,
} from './types';
import { surveyActions } from './actions';

const checkIsLastSurvey = (surveyList, surveyId) =>
  !!(
    surveyList.find((survey) => String(survey.id) === String(surveyId))?.percentageComplete === 100
  );

export function* sendSurveyAnswersSaga({
  payload,
}: {
  payload: {
    questions: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    }[];
    surveyId: number;
  };
}): Saga<void> {
  try {
    const surveys = yield select((state) => state.courses.preview?.data?.surveys) || [];
    const response = yield call([api.survey, 'submitSurveyAnswers'], payload);
    yield put(surveyActions.sendSurveyAnswersSuccess(response));
    const isLastSurvey = yield call(checkIsLastSurvey, surveys, payload.surveyId);

    if (isLastSurvey) {
      // Getting the current course slug to refetch the course once the survey has been completed.
      const courseSlug = yield select((state) => state.courses.preview?.data.slug);
      yield call(getCoursePreviewSaga, {
        payload: courseSlug,
      });
      // Get the certificateUUID and redirect to certificate page
      const certificateUUID = yield select(
        (state) => state.courses.preview?.data?.certificateUuid,
      ) || null;

      if (certificateUUID) {
        yield put(push(`/certificates/${certificateUUID}`));
      } else {
        yield put(push(`/`));
      }
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error saving your survey answers',
        type: alertTypesConstants.DANGER,
      }),
    );
    yield put(surveyActions.sendSurveyAnswersFailure(error.message));
  }
}

export function* getOnboardingSurveySaga(): Saga<void> {
  try {
    const survey = yield call([api.survey, 'getOnboardingSurvey']);
    yield put(surveyActions.getOnboardingSurveySuccess(survey));
  } catch (error) {
    yield put(surveyActions.getOnboardingSurveyFail(error));
  }
}

export function* submitSurveyAnswerSaga({
  payload,
}: {
  payload: {
    answer: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    };
    surveyId: number;
  };
}): Saga<void> {
  try {
    const answer = yield call([api.survey, 'submitSurveyAnswer'], payload);
    yield put(surveyActions.sendSurveyAnswerSuccess(answer));
  } catch (error) {
    yield put(surveyActions.sendSurveyAnswerFailure(error));
  }
}
export default function* surveySaga(): Saga<void> {
  yield takeLatest(sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_REQUEST, sendSurveyAnswersSaga);
  yield takeLatest(
    getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_REQUEST,
    getOnboardingSurveySaga,
  );
  yield takeLatest(sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_REQUEST, submitSurveyAnswerSaga);
  yield takeLatest(sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_SUCCESS, getOnboardingSurveySaga);
}
