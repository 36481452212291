// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing, fontTypes },
  palette: { primary, neutral },
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  background: ${primary.ten};
  padding: ${spacing.x5};
  width: 100%;

  & > * {
    display: flex;
    flex-grow: 1;
  }
`;
export const ImageWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    align-items: center;
    margin-right: 16px;

    svg {
      width: 3.2rem;
      height: 3.2rem;
      color: ${primary.hundred};
    }
  }
`;
export const TextWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    flex-direction: column;
  }
`;
export const Title: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4Medium};
  color: ${neutral.hundred};
  margin-bottom: ${spacing.x8};
`;
export const Text: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4};
  margin: 0;
`;
