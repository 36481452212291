import type { UserProfile, UserDetails, UserObject } from 'commons/types';
import {
  profileUpdateTypes,
  otherUserTypes,
  roleTypes,
  getUserDetailsTypes,
  getOwnUserTypes,
} from './types';

export const usersActions = {
  updateProfile: (
    id: string,
    profile: UserProfile,
  ): {
    type: string;
    payload: {
      profile: UserProfile;
      id: string;
    };
  } => ({
    type: profileUpdateTypes.REQUEST,
    payload: {
      profile,
      id,
    },
  }),
  updateProfileSuccess: (
    payload: UserProfile,
  ): {
    type: string;
    payload: UserProfile;
  } => ({
    type: profileUpdateTypes.SUCCESS,
    payload,
  }),
  updateProfileFailed: (): {
    type: string;
  } => ({
    type: profileUpdateTypes.FAILED,
  }),
  getUserDetailsRequest: (payload?: { partnerId?: string; userId?: number | string }) => ({
    type: getUserDetailsTypes.REQUEST,
    payload,
  }),
  getUserDetailsSuccess: (payload: UserDetails) => ({
    type: getUserDetailsTypes.SUCCESS,
    payload,
  }),
  getUserDetailsFailure: (payload: string) => ({
    type: getUserDetailsTypes.FAILURE,
    payload,
  }),
  getOwnUserRequest: () => ({
    type: getOwnUserTypes.REQUEST,
  }),
  getOwnUserSuccess: (payload: UserObject) => ({
    type: getOwnUserTypes.SUCCESS,
    payload,
  }),
  getOwnUserFailure: (payload: string) => ({
    type: getOwnUserTypes.FAILURE,
    payload,
  }),
  getOtherUserRequest: (payload: string) => ({
    type: otherUserTypes.REQUEST,
    payload,
  }),
  getOtherUserSuccess: (payload: UserObject) => ({
    type: otherUserTypes.SUCCESS,
    payload,
  }),
  getOtherUserFailed: (payload: string) => ({
    type: otherUserTypes.FAILED,
    payload,
  }),
  toggleStudentView: () => ({
    type: roleTypes.STUDENT,
  }),
  toggleExpertView: () => ({
    type: roleTypes.EXPERT,
  }),
};
