// @ts-nocheck
import Tag from 'components/Tag';
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALL_WIDTH, TABLET_WIDTH } from 'commons/constants';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import MaterialCard from 'components/MaterialCard/index';

const {
  noodera: { spacing, fontTypes },
  palette: { neutral, greyscale },
  boxShadow,
} = getTheme();
export const DashboardWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
      padding: 0 ${spacing.x4};
    }

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      padding: 0 ${spacing.x5};
      padding-bottom: ${spacing.x4};
    }
  }
`;
export const DashboardImage: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${greyscale.black} 100%),
    url(${(props) => props.img || ''});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${greyscale.white};
  padding: ${spacing.x5};
  filter: drop-shadow(${boxShadow.default});
`;
export const DashboardTag: StatelessFunctionalComponent<any> = styled(Tag)`
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid ${greyscale.white};
  border-radius: 12px;
  width: fit-content;
  padding: 0 ${spacing.x7};
  height: 24px;
`;
export const DashboardCardCount = styled.span`
  ${fontTypes.font5};
`;
export const DashboardSubtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.x4};
`;
export const CourseDashboardContainer = styled.div(() => ({
  margin: `0 ${spacing.x3}`,
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.x3,
}));

export const CourseDashboardHeader = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.x5,
}));
export const DashboardTextSection: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4};
`;
export const CourseStartDateText: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font6};
  text-align: right;
`;
export const DashboardTextSectionLast: StatelessFunctionalComponent<any> = styled.div`
  && {
    ${fontTypes.font5};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${spacing.x7};
  }
`;
export const PaidCourseIcon: StatelessFunctionalComponent<any> = styled(
  StarBorderPurple500OutlinedIcon,
)`
  &.MuiSvgIcon-root {
    font-size: 24px;
  }
`;
export const UpgradeWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.primary.ten};
  color: black;
  padding: ${spacing.x5};
  height: 100%;
  cursor: default;
`;
export const StyledIcon: StatelessFunctionalComponent<any> = styled(StarOutlineIcon)`
  && {
    font-size: 24px;
  }
`;
export const UpgradeTextSection: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-direction: column;
    color: ${neutral.hundred};
  }
`;
export const UpgradeTitle: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4Medium};
  margin: ${spacing.x8} 0;
`;
export const UpgradeSubtitle: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font5};

  & > span {
    color: ${(props) => props.theme.palette.primary.hundred};
    font-weight: bold;
  }
`;
export const Highlight: StatelessFunctionalComponent<any> = styled(MaterialCard)`
  && {
    background-color: ${(props) => props.theme.palette.primary.hundred || 'transparent'};
    border-radius: 8px;
    color: ${greyscale.white};
    display: block;
    height: 200px;
    width: 280px;
  }
`;
export const Content: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    max-width: 280px;
    margin-right: ${spacing.x5};
    margin-bottom: ${spacing.x5};

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    flex-wrap: wrap;

    & > div {
      margin: 0 0 10px 0;
    }
  }
`;
export const MeetingWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.primary.ten};
  color: black;
  padding: ${spacing.x5};
  filter: drop-shadow(${(props) => props.theme.boxShadow.default});
  height: 100%;
  cursor: default;

  & > div:first-child {
    color: ${greyscale.white};
    width: fit-content;
    padding: 0 ${spacing.x7};
    margin-bottom: ${spacing.x6};
  }

  & > a {
    margin-top: ${spacing.x6};
  }
`;
export const ImgTeacherAssistant: StatelessFunctionalComponent<any> = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const ContainerImgTeacherAssistant: StatelessFunctionalComponent<any> = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;
export const Title: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font4Medium};
  color: ${neutral.hundred};
  margin-bottom: ${spacing.x5};
`;
export const CardsContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.x6,
}));
