import { getProductTypes } from './types';

export const productActions = {
  getProductRequest: () => ({
    type: getProductTypes.GET_PRODUCTS_REQUEST,
  }),
  getProductSuccess: (payload: any) => ({
    type: getProductTypes.GET_PRODUCTS_SUCCESS,
    payload,
  }),
  getProductFailure: (payload: string) => ({
    type: getProductTypes.GET_PRODUCTS_FAILURE,
    payload,
  }),
};
