// @ts-nocheck
import * as React from 'react';
import HelperText from 'components/HelperText/index';
import capitalize from 'utils/string/capitalize';
import { useController } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import {
  CustomTextField,
  ErrorText,
  TopHelperText,
  Wrapper,
  StyledText,
} from './NoodleTextField.styles';
import type { NoodleTextFieldProps } from './NoodleTextField.types';

const NoodleTextField = ({
  topHelperText,
  bottomHelperText,
  className,
  maxLength,
  ...restProps
}: NoodleTextFieldProps) => (
  <Wrapper className={className}>
    {topHelperText && <HelperText {...topHelperText} />}
    <CustomTextField maxLength={maxLength} {...(restProps as any)} />
    {bottomHelperText && <HelperText {...bottomHelperText} />}
  </Wrapper>
);

const ControlledNoodleTextField = ({
  topHelperText,
  bottomHelperText,
  control,
  name,
  maxLength,
  error,
  className,
  startAdornment,
  ...restProps
}: NoodleTextFieldProps) => {
  const { field } = useController({
    control,
    name,
  });
  return (
    <Wrapper className={className}>
      {topHelperText && <TopHelperText> {topHelperText.text}</TopHelperText>}
      <CustomTextField
        name={name}
        error={error}
        maxLength={maxLength}
        {...(restProps as any)}
        {...field}
        InputProps={{
          inputProps: {
            'aria-label': name,
            maxLength,
          },
          startAdornment: startAdornment ? (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ) : null,
        }}
      />
      {maxLength ? (
        <StyledText>{maxLength - (field.value?.length || 0)} characters left</StyledText>
      ) : null}
      {/* Custom error since MUI's helper text doesn't work well with the design we want */}
      {error && (
        <ErrorText
          type={bottomHelperText ? 'right-aligned' : 'left-aligned'}
          text={capitalize(error)}
        />
      )}
      {!error && bottomHelperText && <HelperText {...bottomHelperText} />}
    </Wrapper>
  );
};

NoodleTextField.Controlled = ControlledNoodleTextField;
export default NoodleTextField;
