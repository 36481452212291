// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import { TEAM } from 'commons/constants/team';
import api from 'lib/Api';
import type {
  JoinTeamByInviteAPIPayload,
  CreateTeamSagaPayload,
  ApproveOrJoinSaga,
  TeamInvitePayload,
  TeamPreviewPayload,
} from 'commons/types/teamTypes';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { teamActions } from './actions';
import {
  getTeamTypes,
  getTeamsTypes,
  getMyTeamsTypes,
  joinTeamByInviteTypes,
  getTeamPreviewTypes,
  joinTeamRequestTypes,
  createTeamTypes,
  getTeamSavedResourcesTypes,
  inviteMembersTypes,
  approveJoinPetitionTypes,
  denyJoinPetitionTypes,
  cancelTeamRequestTypes,
  saveContentTypes,
} from './types';

const { DANGER, SUCCESS } = alertTypesConstants;
type GetTeamSagaPayload = {
  payload: TeamPreviewPayload;
};
type SaveCourseSagaPayload = {
  payload: {
    slug: string;
    teamId: number;
  };
};

export function* getTeamsSaga(): Saga<void> {
  try {
    const response = yield call([api.team, 'getTeams']);
    yield put(teamActions.getTeamsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.teamsErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.getTeamsFailure(error));
  }
}

export function* getTeamSaga({ payload }: GetTeamSagaPayload): Saga<void> {
  try {
    const response = yield call([api.team, 'getTeam'], payload);
    yield put(teamActions.getTeamSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.teamErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.getTeamFailure(error));
  }
}

export function* getMyTeamsSaga(): Saga<void> {
  try {
    const response = yield call([api.team, 'getMyTeams']);
    yield put(teamActions.getMyTeamsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.myTeamsErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.getMyTeamsFailure(error));
  }
}

export function* getTeamPreviewSaga({ payload }: GetTeamSagaPayload): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const response = yield call([api.team, 'getTeamPreview'], {
      teamId: Number(payload.teamId),
      userId,
      fetchAcceptedMembers: payload?.fetchAcceptedMembers,
    });
    yield put(teamActions.getTeamPreviewSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.teamErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.getTeamPreviewFailure(error));
  }
}

export function* getTeamSavedResourcesSaga({ payload }: { payload?: string }): Saga<void> {
  try {
    const response = yield call([api.team, 'getTeamSavedResources'], payload);
    yield put(teamActions.getTeamSavedResourcesSuccess(response));
  } catch (error) {
    const errorMessage = payload
      ? `${TEAM.getTeamSavedResourcesErrorMessage} ${payload}`
      : TEAM.getAllTeamsSavedResourcesErrorMessage;
    yield put(
      alertActions.setAlert({
        message: errorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.getTeamSavedResourcesFailure(error));
  }
}

export function* joinMemberSaga({ payload }: ApproveOrJoinSaga): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const apiPayload: JoinTeamByInviteAPIPayload = {
      userId,
      teamId: Number(payload),
    };
    const response = yield call([api.team, 'approveOrJoinTeam'], apiPayload);

    if (response) {
      yield put(teamActions.joinTeamByInviteSuccess());

      if (isNonNoodleInstance) {
        yield put(push(`/?section=${Number(payload)}`));
      } else {
        yield put(push('/onboarding'));
      }

      yield put(
        alertActions.setAlert({
          message: TEAM.joinTeamSuccessMessage,
          type: SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.joinTeamErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.joinTeamByInviteFailure(error));
  }
}

// approve button from TeamAbout
export function* approveJoinPetitionSaga({ payload }: ApproveOrJoinSaga): Saga<void> {
  try {
    const apiPayload: JoinTeamByInviteAPIPayload = {
      userId: payload.userId,
      teamId: payload.teamId,
    };
    yield call([api.team, 'approveOrJoinTeam'], apiPayload);
    yield put(teamActions.approveJoinPetitionSuccess());
    yield put(
      alertActions.setAlert({
        message: TEAM.approveTeamMembership,
        type: SUCCESS,
      }),
    );
    yield put(teamActions.getTeamPreviewRequest({ teamId: payload.teamId }));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.approveTeamMembershipErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.approveJoinPetitionFailure(error));
  }
}

// decline button from TeamAbout
export function* denyJoinPetitionSaga({ payload }: ApproveOrJoinSaga): Saga<void> {
  try {
    yield call([api.team, 'denyJoinTeam'], payload);
    yield put(teamActions.denyJoinPetitionSuccess());
    yield put(
      alertActions.setAlert({
        message: TEAM.denyTeamMembership,
        type: SUCCESS,
      }),
    );
    yield put(teamActions.getTeamPreviewRequest({ teamId: payload.teamId }));
    yield call(getTeamsSaga);
  } catch (error) {
    yield put(teamActions.denyJoinPetitionFailure(error));
    yield put(
      alertActions.setAlert({
        message: TEAM.denyTeamMembershipErrorMessage,
        type: DANGER,
      }),
    );
  }
}

// join button from TeamCard
export function* joinTeamRequestSaga({ payload }: GetTeamSagaPayload): Saga<void> {
  try {
    const userId = yield select((state) => state.user.id);
    const apiPayload: JoinTeamByInviteAPIPayload = {
      userId,
      teamId: payload,
    };
    const response = yield call([api.team, 'approveOrJoinTeam'], apiPayload);

    if (response) {
      yield put(teamActions.joinTeamRequestSuccess());
      yield put(
        alertActions.setAlert({
          message: TEAM.joinTeamRequestSuccessMessage,
          type: SUCCESS,
        }),
      );
      yield call(getTeamsSaga);
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.joinTeamRequestErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.joinTeamRequestFailure(error));
  }
}

// cancel button from TeamCard
export function* cancelTeamRequestSaga({ payload }: ApproveOrJoinSaga): Saga<void> {
  try {
    yield call([api.team, 'denyJoinTeam'], payload);
    yield put(teamActions.cancelTeamRequestSuccess());
    yield put(
      alertActions.setAlert({
        message: TEAM.cancelTeamRequestSuccessMessage,
        type: SUCCESS,
      }),
    );
    yield put(teamActions.getTeamPreviewRequest({ teamId: payload.teamId }));
    yield call(getTeamsSaga);
  } catch (error) {
    yield put(teamActions.cancelTeamRequestFailure(error));
    yield put(
      alertActions.setAlert({
        message: TEAM.cancelTeamRequestErrorMessage,
        type: DANGER,
      }),
    );
  }
}

export function* inviteMembersSaga({ payload }: { payload: TeamInvitePayload }): Saga<void> {
  const isOneMemberInviting = payload?.invitees.length === 1;

  try {
    const response = yield call([api.team, 'inviteMembers'], payload);

    if (response) {
      const membersNotInvited = payload?.invitees.filter((email) => !response.includes(email));
      yield put(teamActions.inviteMembersSuccess());

      if (membersNotInvited?.length) {
        const nonInvitedMembersText = membersNotInvited.join(', ');
        if (isOneMemberInviting) {
          yield put(
            alertActions.setAlert({
              message: TEAM.inviteMembersFailureMessage,
              type: DANGER,
            }),
          );
        } else {
          yield put(
            alertActions.setAlert({
              message: `Invites were sent successfully except for ${nonInvitedMembersText} as they are already enrolled in this course`,
              type: SUCCESS,
            }),
          );
        }
      } else {
        yield put(
          alertActions.setAlert({
            message: isOneMemberInviting
              ? TEAM.inviteMemberSuccessMessage
              : TEAM.inviteMembersSuccessMessage,
            type: SUCCESS,
          }),
        );
      }
    }
  } catch (error) {
    yield put(teamActions.inviteMembersFailure(error));
    yield put(
      alertActions.setAlert({
        message: TEAM.inviteMembersFailureMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.createTeamFailure(error));
  }
}

export function* createTeamSaga({ payload }: CreateTeamSagaPayload): Saga<void> {
  try {
    const response = yield call([api.team, 'createTeam'], payload);
    yield put(teamActions.createTeamSuccess());

    if (response) {
      const inviteMembersPayload = {
        payload: {
          invitees: payload?.inviteMembers,
          teamId: response?.id,
        },
      };
      const shouldInviteMembers = !!payload?.inviteMembers?.length;

      if (shouldInviteMembers) {
        yield call(inviteMembersSaga, inviteMembersPayload);
      }

      yield put(push(`/teams/preview/${response?.id}`));
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: TEAM.createTeamRequestErrorMessage,
        type: DANGER,
      }),
    );
    yield put(teamActions.createTeamFailure(error));
  }
}

export function* saveContentSaga({ payload }: SaveCourseSagaPayload): Saga<void> {
  try {
    const response = yield call([api.team, 'saveContent'], payload);
    yield put(
      alertActions.setAlert({
        message: 'Saved',
        type: SUCCESS,
      }),
    );
    yield put(teamActions.saveContentSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error while saving',
        type: DANGER,
      }),
    );
    yield put(teamActions.saveContentFailure(error));
  }
}

export default function* teamSaga(): Saga<void> {
  yield takeLatest(
    getTeamSavedResourcesTypes.GET_TEAM_SAVED_RESOURCES_REQUEST,
    getTeamSavedResourcesSaga,
  );
  yield takeLatest(getTeamsTypes.GET_TEAMS_REQUEST, getTeamsSaga);
  yield takeLatest(getTeamTypes.GET_TEAM_REQUEST, getTeamSaga);
  yield takeLatest(getMyTeamsTypes.GET_MY_TEAMS_REQUEST, getMyTeamsSaga);
  yield takeLatest(getTeamPreviewTypes.GET_TEAM_PREVIEW_REQUEST, getTeamPreviewSaga);
  yield takeLatest(joinTeamByInviteTypes.JOIN_TEAM_BY_INVITE_REQUEST, joinMemberSaga);
  yield takeLatest(approveJoinPetitionTypes.APPROVE_JOIN_PETITION_REQUEST, approveJoinPetitionSaga);
  yield takeLatest(denyJoinPetitionTypes.DENY_JOIN_PETITION_REQUEST, denyJoinPetitionSaga);
  yield takeLatest(joinTeamRequestTypes.JOIN_TEAM_REQUEST_REQUEST, joinTeamRequestSaga);
  yield takeLatest(cancelTeamRequestTypes.CANCEL_TEAM_REQUEST_REQUEST, cancelTeamRequestSaga);
  yield takeLatest(createTeamTypes.CREATE_TEAM_REQUEST, createTeamSaga);
  yield takeLatest(inviteMembersTypes.INVITE_MEMBERS_REQUEST, inviteMembersSaga);
  yield takeLatest(saveContentTypes.SAVE_CONTENT_REQUEST, saveContentSaga);
}
