/* eslint-disable global-require */

/* eslint-disable import/no-dynamic-require */
// noinspection ES6PreferShortImport

// Make sure to update the src/__mocks__/settings/theme/mockTheme.js when making changes to this file
const noodleTheme = {
  noodera: {
    spacing: {
      x1: '7.2rem',
      x2: '4.8rem',
      x3: '3.2rem',
      x4: '2.4rem',
      x5: '1.6rem',
      x6: '1.2rem',
      x7: '0.8rem',
      x8: '0.4rem',
      x9: '0.6rem',
      x10: '0.2rem',
    },
    fontTypes: {
      headline1: `
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 4rem;
        line-height: 4.8rem;
      `,
      headline2: `
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 4.8rem;
      `,
      font1: `
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 6rem;
        line-height: 7.2rem;
      `,
      font1Medium: `
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 6rem;
        line-height: 7.2rem;
      `,
      font2: `
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 4rem;
        line-height: 4.8rem;
      `,
      font2Medium: `
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 4rem;
        line-height: 4.8rem;
      `,
      font3: `
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: 2.4rem;
        line-height: 3.2rem;
      `,
      font3Medium: `
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 3.2rem;
      `,
      font4: `
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;
      `,
      font4Medium: `
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.4rem;
      `,
      font4Article: `
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.8rem;
      `,
      font5: `
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;
      `,
      font5Medium: `
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
      `,
      font6: `
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.6rem;
      `,
      font6Medium: `
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1rem; 
        line-height: 1.2rem;
      `,
      font6MediumCaps: `
        font-family: Inter, sans-serif;
        font-weight: 800;
        font-size: 1.2rem; 
        line-height: 1.6rem;
      `,
    },
  },
  boxShadow: {
    default: '0px 2px 10px rgba(0, 0, 0, 0.15)',
    active: '0px 1px 5px rgba(0, 0, 0, 0.4)',
    hover: '0px 2px 20px rgba(0, 0, 0, 0.2)',
    button: '0px 2px 4px rgba(20, 25, 33, 0.1)',
    focus: '0px 0px 2px 2px #99C9D6',
  },
  spacing: {
    x1: '4.8rem',
    x2: '3.6rem',
    x3: '2.4rem',
    x4: '1.6rem',
    x5: '1.2rem',
    x6: '1rem',
  },
  fontTypes: {
    headline1: `
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 4.8rem;
      line-height: 6rem;
    `,
    headline2: `
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 3.6rem;
      line-height: 4.5rem;
    `,
    headline3: `
      font-family: Inter, sans-serif;
      font-size: 2.4rem;
      line-height: 3rem;
    `,
    font3: `
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3rem;
    `,
    font4: `
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    font4Semi: `
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    font5: `
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: .03rem;
    `,
    font5Semi: `
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: .03rem;
      font-weight: 600;
    `,
    font3Bold: `
      font-family: Inter, sans-serif;
      font-size: 2.4rem;
      line-height: 3rem;
      font-weight: 700;
    `,
    font4Bold: `
      font-family: Inter, sans-serif;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: bold;
    `,
    font5Bold: `
      font-family: Inter, sans-serif;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: .03rem;
      font-weight: 700;
    `,
  },
  palette: {
    neutral: {
      five: '#F8F8F8',
      ten: '#F0F1F2',
      fifteen: '#F8F8F8',
      twenty: '#DBDDE0',
      forty: '#ADB1B8',
      sixty: '#80858F',
      seventy: '#6F757F',
      eighty: '#505763',
      hundred: '#232C3B',
    },
    primary: {
      ten: '#EBF6FA',
      twenty: '#CCE4EB',
      forty: '#99C9D6',
      sixty: '#66ADC2',
      eighty: '#3392AD',
      hundred: '#007799',
      hundredTwenty: '#005F81',
    },
    secondary: {
      ten: '#EEF2EC',
      twenty: '#DCE6D8',
      forty: '#BACCB1',
      sixty: '#97B38A',
      eighty: '#759963',
      hundred: '#52803C',
      hundredTwenty: '#426630',
    },
    accent: {
      one: '#CE4040',
      two: '#367E5E',
      three: '#00FF27',
      four: '#F3AA3C',
    },
    warning: {
      ten: '#FAECEC',
      twenty: '#F7E2E2',
      sixty: '#E28C8C',
      hundred: '#CE4040',
    },
    noodle: {
      primary: '#0B7EA2',
      secondary: '#52803C',
      skeleton: '#F2F2F2',
    },
    greyscale: {
      black: '#000000',
      // Style Name: Black
      cloud: '#dfdfe1',
      // Style Name: Cloud
      concrete: '#f2f2f2', // As per https://chir.ag/projects/name-that-color/#F2F2F2
      doveGray: '#666666',
      // As per http://chir.ag/projects/name-that-color/#666666
      iron: '#343439',
      // Style Name: Iron
      ironTwo: '#DBDDE0',
      // As per http://chir.ag/projects/name-that-color/#DBDDE0. We already have the `iron` color, so I've labelled it `ironTwo`
      light: '#8C969E',
      lighter: '#F8FAFD',
      mineShaft: '#333333',
      // As per http://chir.ag/projects/name-that-color/#333333
      mirage: '#151D24',
      // As per http://chir.ag/projects/name-that-color/#151D24
      outerSpace: '#2D343B',
      // As per http://chir.ag/projects/name-that-color/#2D343B
      osloGray: '#80858F',
      // As per http://chir.ag/projects/name-that-color/#80858F
      pewter: '#c5c5c8',
      // Style Name: Pewter
      riverBed: '#434B55',
      // As per http://chir.ag/projects/name-that-color/#434B55
      silver: '#ababaf',
      // Style Name: Silver
      smoke: '#66666d',
      // Style Name: Smoke
      snow: '#f3f3f9',
      // Style Name: Snow
      snowWhite: '#fafaff',
      // Style Name: Snow White
      snowWhiteVariation: '#fbfbfb',
      // Style Name: Snow White Variation
      white: '#ffffff',
      // Style Name: White
      whiteHover: '#E9ECEF',
      trout: '#505763', // As per http://chir.ag/projects/name-that-color/#505763
      alto: '#e0e0e0', // As per http://chir.ag/projects/name-that-color/#e0e0e0
      paleSky: '#6F757F', // As per http://chir.ag/projects/name-that-color/#6F757F
      athensGray: '#F4F4F5', // As per https://chir.ag/projects/name-that-color/#F4F4F5
    },
    social: {
      default: '#00b8e3',
      // 0: Default,
      gplus: '#438aff',
      // 1: Google Plus
      gplus05: '#367df2',
      // 2: Google Plus Darken 5%
      fb: '#4460a0',
      // 3: Facebook
      fb05: '#375393',
      // 4: Facebook Darken 5%
      linkedin: '#007ebb',
      // 5: Linkedin
      linkedin05: '#0071ae', // 6: Linkedin Darken 5%
    },
    alphas: {
      black20: 'rgba(0,0,0,0.20)',
      black60: 'rgba(0,0,0,0.60)',
      black5: 'rgba(0, 0, 0, 0.05)',
      blue20: 'rgba(46,97,149,0.20)',
      blue70: 'rgba(46,97,149,0.70)',
    },
    blue: {
      brightNavy: '#2E6195',
      // Style Name: Bright Navy
      geyser: '#CFD9E2',
      // As per http://chir.ag/projects/name-that-color/#CFD9E2
      hover: '#24b2e9',
      mystic: '#E3EAEF',
      // As per http://chir.ag/projects/name-that-color/#E3EAEF
      mysticLight: '#EFF3F5',
      // As per http://chir.ag/projects/name-that-color/#EFF3F5
      noodleBlue: '#00A2E1',
      opal: '#AAC4C5',
      // As per http://chir.ag/projects/name-that-color/#AAC4C5
      regatta: '#00bfe7',
      // Style Name: Regatta
      riverBed: '#414B56',
      // As per http://chir.ag/projects/name-that-color/#414B56
      sapphire: '#2F4E91',
      // As per http://chir.ag/projects/name-that-color/#2F4E91
      seagull: '#88D1F0',
      // As per http://chir.ag/projects/name-that-color/#88D1F0
      shipCove: '#7D7DC5',
      // As per http://chir.ag/projects/name-that-color/#7D7DC5
      twilightBlue: '#EFFBFF',
      // As per http://chir.ag/projects/name-that-color/#EFFBFF
      biscay: '#182C61',
      // As per http://chir.ag/projects/name-that-color/#182C61
      oxfordBlue: '#2C3A47',
      // As per http://chir.ag/projects/name-that-color/#2C3A47
      gigas: '#3B3B98',
      // As per http://chir.ag/projects/name-that-color/#3B3B98
      brightTurquoise: '#25CCF7',
      // As per http://chir.ag/projects/name-that-color/#25CCF7
      waterLeaf: '#9AECDB',
      // As per http://chir.ag/projects/name-that-color/#9AECDB
      dodgerBlue: '#1B9CFC',
      // As per http://chir.ag/projects/name-that-color/#1B9CFC
      turquoiseBlue: '#55E6C1',
      // As per http://chir.ag/projects/name-that-color/#55E6C1
      frenchPass: '#B8E9FE', // As per http://chir.ag/projects/name-that-color/#B8E9FE
      bayOfMany: '#244378', // As per http://chir.ag/projects/name-that-color/#244378
      blackSqueeze: '#E7F2F6', // As per http://chir.ag/projects/name-that-color/#E7F2F6
    },
    green: {
      default: '#008358',
      // Style Name: Green
      genoa: '#13785C',
      // As per http://chir.ag/projects/name-that-color/#13785C
      springGreen: '#0be881',
      // As per http://chir.ag/projects/name-that-color/#0be881
      tradewind: '#58B19F',
      // As per http://chir.ag/projects/name-that-color/#58B19F
      hover: '#035740',
      // Style Name: Hover Green
      lime: '#34A534',
      // As per http://chir.ag/projects/name-that-color/#C8E290
      yellowGreen: '#C8E290',
    },
    red: {
      albescentWhite: '#F7E2CF',
      // As per http://chir.ag/projects/name-that-color/#F7E2CF
      atomicTangerine: '#FF9662',
      // As per http://chir.ag/projects/name-that-color/#FF9662
      bittersweet: '#FD7272',
      // As per http://chir.ag/projects/name-that-color/#FD7272
      coral: '#F97F51',
      // As per http://chir.ag/projects/name-that-color/#F97F51
      default: '#d30000',
      geraldine: '#F98B9D',
      // As per http://chir.ag/projects/name-that-color/#F98B9D
      hitPink: '#FEA47F',
      // As per http://chir.ag/projects/name-that-color/#FEA47F
      hover: '#9b0000',
      orangeRoughy: '#BF5218',
      // As per http://chir.ag/projects/name-that-color/#BF5218
      persimmon: '#ff6348',
      // As per http://chir.ag/projects/name-that-color/#ff6348
      royalHealth: '#B33771',
      // As per http://chir.ag/projects/name-that-color/#B33771
      tawnyPort: '#6D214F',
      // As per http://chir.ag/projects/name-that-color/#6D214F
      violetRed: '#FC427B',
      // As per http://chir.ag/projects/name-that-color/#FC427B
      flushMahogany: '#CE4040', // As per http://chir.ag/projects/name-that-color/#CE4040
    },
    yellow: {
      candlelight: '#FFE01A',
      // As per http://chir.ag/projects/name-that-color/#FFE01A
      default: '#ffcb00',
      // Style Name: Yellow
      energyYellow: '#f6d845',
      // As per http://chir.ag/projects/name-that-color/#F6D845
      pineGlade: '#BDC581',
      // As per http://chir.ag/projects/name-that-color/#BDC581
      dairyCream: '#F8EFBA',
      // As per http://chir.ag/projects/name-that-color/#F8EFBA
      tulipTree: '#EAB543',
      // As per http://chir.ag/projects/name-that-color/#EAB543
      butterMilk: '#FEEFB8', // As per http://chir.ag/projects/name-that-color/##FEEFB8
    },
    pink: {
      default: '#DD559D',
      deluge: '#82589F',
      // As per http://chir.ag/projects/name-that-color/#82589F
      flushMahogany: '#CE4040',
      // As per http://chir.ag/projects/name-that-color/#CE4040
      lavender: '#C681DE', // As per http://chir.ag/projects/name-that-color/#C681DE
    },
  },
  fontColors: {
    primary: '#232C3B',
    subtle: '#646A75',
  },
  fonts: {
    primary: 'Inter, sans-serif',
    header: 'Inter, sans-serif',
  },
  ellipsis: (lineClamp: number, display = '-webkit-box') => `
    display: ${display};
    -webkit-line-clamp: ${lineClamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;`,
  cardAnimation: `
  transition: all 200ms;

  &:hover {
    transform: scale(1.02);
    transition: all 300ms;
  }

  &:active {
    transform: scale(0.97);
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);
    transition: all 300ms;
  }`,
  gradients: {
    noodleBlue: 'linear-gradient(90deg, #6078EA 0%, #17EAD9 100%)',
    limeGreen: 'linear-gradient(90deg, #3BB2B8 0%, #42E695 100%)',
    noodera: 'linear-gradient(90deg, #007799 0%, #367D5D 100%);',
  },
  overlays: {
    modal: 'rgba(0,0,0,0.70)',
  },
  underlineLink: `
    background-image: linear-gradient(120deg, #99C9D6 0%, #99C9D6 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 109%;
    transition: background-size 0.1s ease-in;
    overflow: auto;
    color: #007799;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;

    &:hover, &:focus {
      background-size: 100% 109%;
      background-image: linear-gradient(120deg, #CCE4EB 0%, #CCE4EB 100%);
      color: #005F81;
    }

    &:active {
      color: #3392AD;
      background-image: none;
    }
  `,
  underlineNeutralLink: `
    background-image: linear-gradient(120deg, #99C9D6 0%, #99C9D6 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 109%;
    transition: background-size 0.1s ease-in;
    overflow: auto;
    color: #232C3B;
    text-decoration: none;

    &:hover, &:focus {
      background-size: 100% 109%;
      background-image: linear-gradient(120deg, #CCE4EB 0%, #CCE4EB 100%);
      color: #005F81;
    }

    &:active {
      color: #3392AD;
      background-image: linear-gradient(120deg, #99C9D6 0%, #99C9D6 100%);
    }
  `,
  // This bool determines whether an instance has custom colors set,
  // and is used on components, like the NooderButton.CTA, to determine
  // whether gradients are used.
  isCustomTheme: false,
};
// Using a function in order to allow us importing other theme files.
// Otherwise we'd need to have a huge object with all the partner's styles in this only file.
// A full theme object is almost 400 lines long. So 400 lines per partner in this file.
export const getTheme = () => noodleTheme;

export default noodleTheme;
