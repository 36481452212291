// @ts-nocheck
import humps from 'humps';
import normalize from 'jsonapi-normalizer';
import lodashGet from 'lodash.get';
import type { Axios, JsonApiFormat, NormalizedJsonApi, Payload } from 'commons/types';

export default class JsonApi {
  axios: Axios;

  constructor(axios: Axios) {
    this.axios = axios;
  }

  static createRelationShipObject(
    relations: Record<string, any>,
    relationshipName: string,
  ): Record<string, any> {
    const snakeCaseRelations = humps.decamelizeKeys(relations);
    const [type] = Object.keys(snakeCaseRelations);
    const data = Object.keys(snakeCaseRelations[type]).map((id) => ({
      id,
      type: relationshipName,
    }));
    return {
      [type]: {
        data,
      },
    };
  }

  static convertRequestArray(request: Payload): JsonApiFormat {
    const snakeCasePayload = humps.decamelizeKeys(request);
    return {
      data: snakeCasePayload,
    };
  }

  static convertRequest(
    request: Payload,
    relationshipObject: Record<string, any> | null = null,
    relationshipName = '',
  ): JsonApiFormat {
    const snakeCasePayload = humps.decamelizeKeys(request);
    const [type] = Object.keys(snakeCasePayload);
    const requestAttributes = snakeCasePayload[type];
    const { id, ...attributes } = requestAttributes;
    const relationships = relationshipObject
      ? this.createRelationShipObject(relationshipObject, relationshipName)
      : {};
    const data = id
      ? {
          id: `${id}`,
          type,
          attributes,
          relationships,
        }
      : {
          type,
          attributes,
          relationships,
        };
    return {
      data,
    };
  }

  static convertResponse({
    data,
    isNormalized = true,
  }: {
    data: JsonApiFormat;
    isNormalized?: boolean;
  }): NormalizedJsonApi {
    if (!data || !data.data) {
      return data;
    }

    return humps.camelizeKeys(isNormalized ? normalize(data) : data);
  }

  async get(url: string, isNormalized = true): Promise<NormalizedJsonApi | any> {
    const response = await this.axios.get(url);
    const convertedResp = JsonApi.convertResponse({ ...response, isNormalized });

    if (!isNormalized) {
      return convertedResp;
    }

    if (response.data.meta && response.data.meta.count) {
      convertedResp.entities.count = response.data.meta.count;
    }

    if (response.data.links && response.data.links.self) {
      convertedResp.entities.link = response.data.links.self;
    }

    if (response.data.included) {
      convertedResp.entities.included = response.data.included;
    }

    return convertedResp;
  }

  /**
   * There two types of posts, the first one needs the format "data: { attributes: {stuff}, type }"
   * and the second that can be an array or object like this "data: { id, type }"
   * with the convertReq param and isArray validation we can perform both.
   * @param url
   * @param payload
   * @param convertReq
   * @param relationships
   * @param relationshipName
   * @param options
   * @returns {Promise<NormalizedJsonApi>}
   */
  async post(
    url: string,
    payload: Payload,
    convertReq = true,
    relationships: Record<string, any> | null = null,
    relationshipName = '',
    options: Record<string, any> = {},
    preserveStructure = false,
  ): Promise<NormalizedJsonApi> {
    const jsonApiRequest =
      Array.isArray(payload) || !convertReq
        ? JsonApi.convertRequestArray(payload)
        : JsonApi.convertRequest(payload, relationships, relationshipName);
    const payloadId = lodashGet(jsonApiRequest, 'data.id');
    const request =
      payloadId && convertReq
        ? await this.axios.patch(
            `${url}/${payloadId}`,
            preserveStructure ? jsonApiRequest.data : jsonApiRequest,
          )
        : await this.axios.post(
            url,
            preserveStructure ? jsonApiRequest.data : jsonApiRequest,
            options,
          );
    return JsonApi.convertResponse(request);
  }

  async delete(url: string, payload: Payload, wrapDataRequest = false): Promise<NormalizedJsonApi> {
    // wrapDataRequest Was added for a special case, the endpoint DELETE /connections
    // received an invalid payload with out this wrapping it in a data key.
    const jsonApiRequest = !wrapDataRequest
      ? JsonApi.convertRequestArray(payload)
      : {
          data: JsonApi.convertRequest(payload),
        };
    const request = await this.axios.delete(url, jsonApiRequest);
    return JsonApi.convertResponse(request);
  }

  async patch(url: string, payload: Payload): Promise<NormalizedJsonApi> {
    const jsonApiRequest = JsonApi.convertRequestArray(payload);
    const request = await this.axios.patch(url, jsonApiRequest);
    return JsonApi.convertResponse(request);
  }
}
