// @ts-nocheck
import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useOnClickOutside } from 'utils/customHooks';
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg';
import type { InputChipsProps, Items as ItemsType, OnDelete } from './InputChips.types';
import { Container, ActionContainer, Placeholder, PlaceholderWrapper } from './InputChips.styles';

const ChipList = ({ items, onDelete }: { items: ItemsType; onDelete: OnDelete }) => (
  <>
    {items.map((currentItem) => (
      <Chip
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        deleteIcon={
          <div>
            <DeleteIcon />
          </div>
        }
        key={currentItem}
        label={currentItem}
        onDelete={() => onDelete(currentItem)}
      />
    ))}
  </>
);

const InputChips = ({
  actionComponent,
  onDelete,
  items,
  placeholder,
  actionComponentRef,
}: InputChipsProps): React.ReactElement<any> => {
  const wrapperRef = React.useRef();
  const [isEditing, setEditing] = React.useState(false);
  useOnClickOutside(wrapperRef, () => {
    if (actionComponentRef.current && !actionComponentRef.current.value) {
      setEditing(false);
    }
  });
  React.useEffect(() => {
    if (isEditing && actionComponentRef && actionComponentRef.current) {
      actionComponentRef.current.focus();
    }
  }, [isEditing, actionComponentRef]);

  const handleFocus = () => {
    setEditing(true);
  };

  const showPlaceholder = !isEditing && items.length === 0;
  return (
    <Container isEditing={!showPlaceholder} ref={wrapperRef} onFocus={handleFocus}>
      {showPlaceholder && (
        <PlaceholderWrapper onClick={handleFocus}>
          <Placeholder> {placeholder}</Placeholder>
        </PlaceholderWrapper>
      )}
      <ChipList items={items} onDelete={onDelete} />
      {!showPlaceholder && <ActionContainer>{actionComponent}</ActionContainer>}
    </Container>
  );
};

export default InputChips;
