// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALLER_WIDTH } from 'commons/constants';
import { Link } from 'react-router-dom';

const {
  palette: { greyscale, primary, noodle, neutral, secondary },
  noodera: { fontTypes, spacing },
} = getTheme();
export const HeaderContainer: StatelessFunctionalComponent<any> = styled.header`
  position: ${({ showLongLogo }) => (showLongLogo ? 'fixed' : 'sticky')};
  top: 0;
  left: 0;
  z-index: 3;
  min-height: 5.6rem;
  max-height: 5.6rem;
  background: ${greyscale.white};
  box-shadow: 0 1px 0 ${neutral.twenty};
  display: flex;
  align-items: center;
  padding: 0 ${spacing.x4};
  justify-content: space-between;
  margin-left: 1px;
  width: calc(100% - 1px);
`;
export const HeaderTitleWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    align-items: center;

    & > h2,
    h4 {
      ${fontTypes.font4Medium};
      margin: 0;
    }

    & > div {
      display: flex;
      margin-left: ${spacing.x8};
      color: ${primary.hundred};

      & > [role='button'] {
        margin: 0;
      }
    }
  }
`;
export const HeaderActionsContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  align-items: center;

  & > div,
  & > a > div {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a:hover,
  a:focus {
    svg path {
      fill: ${primary.eighty};
    }
  }

  a:active {
    svg path {
      fill: ${primary.hundredTwenty};
    }
  }

  a:focus {
    svg {
      border-radius: 0.4rem;
      background-color: ${primary.ten};
    }
  }
`;
export const ActionsContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  height: 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
export const SearchLink: StatelessFunctionalComponent<any> = styled(Link)`
  display: inline-block;
  margin: 0 ${spacing.x7};
`;
export const BreadcrumbWrapper: StatelessFunctionalComponent<any> = styled.div`
  & a {
    ${fontTypes.font3Medium};
    text-decoration: none;
    color: ${noodle.primary};
  }

  .breadcrumb {
    &__icon {
      margin-left: ${spacing.x4};
      margin-right: ${spacing.x4};
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    .breadcrumb {
      &__container {
        padding-bottom: 9.6rem;
      }
    }
  }
`;
export const MainTitle: StatelessFunctionalComponent<any> = styled.h1`
  ${fontTypes.font4Medium};
  color: ${neutral.eighty};
`;
export const StyledExternalLinksList: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4};
  display: flex;
  height: 100%;
  max-height: 57px;
  margin-left: auto;
  a {
    display: flex;
    align-items: center;
    padding: 16px ${spacing.x3} 16px;
    color: ${neutral.hundred};
    border-right: 1px solid ${neutral.twenty};
    transition: 0.3s;
    &:first-child {
      border-left: 1px solid ${neutral.twenty};
    }
    &:hover {
      border-bottom: ${spacing.x9} solid ${primary.hundred};
    }
    &:focus {
      border: 1px solid ${secondary.hundred};
      border-bottom: ${spacing.x9} solid ${primary.hundred};
    }
  }
`;
export const StyledLogo: StatelessFunctionalComponent<any> = styled.img`
  max-height: 4.2rem;
`;
export const LoadingContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div {
    height: auto;
  }
`;
export const LoadingText: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font3};
  color: ${neutral.hundred};
`;
