import get from 'lodash.get';
import * as Sentry from '@sentry/react';
import JsonApi from 'utils/jsonApi';
import { randomize } from 'utils/arrayHelpers';
import type {
  GetProgramGroupsPayload,
  PartnerProgramGroupObject,
  PartnerProgramObject,
  PartnerProgramRequestInfoPayload,
  SorterOption,
} from 'commons/types';

const groupPartnerPrograms = (programs: PartnerProgramObject[]): PartnerProgramGroupObject[] => {
  const programGroupsObject = {};
  programs.forEach((p) => {
    if (!programGroupsObject[p.programCategory]) {
      programGroupsObject[p.programCategory] = {
        name: p.programCategory,
        programs: [],
      };
    }

    programGroupsObject[p.programCategory].programs.push(p);
  });
  const programGroups: PartnerProgramGroupObject[] = Object.values(programGroupsObject) as any;
  return programGroups;
};

const sortProgramGroups = (
  programGroups: PartnerProgramGroupObject[],
  sorterOptions: SorterOption[],
): PartnerProgramGroupObject[] => {
  const filteredSorterOptions = sorterOptions.filter((o) => o.value);
  const sortedPrograms = programGroups.sort((a) =>
    filteredSorterOptions.findIndex((o) => o.key === a.name) < 0 ? 1 : -1,
  );
  return sortedPrograms;
};

const updateSorterOptions = (
  sorterOptions: SorterOption[],
  programGroups: PartnerProgramGroupObject[],
): SorterOption[] =>
  sorterOptions.length === 0
    ? programGroups.map(({ name }) => ({
        label: name,
        key: name,
        value: false,
      }))
    : sorterOptions;

export default class PartnerPrograms {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getPrograms(taxonomyName: string): Promise<PartnerProgramObject[]> {
    const filter = `filter=[{"name":"program_category","op":"ilike","val":"%25${encodeURIComponent(
      taxonomyName,
    )}%25"}]`;
    const response = await this.jsonApi.get(`/noodle_partners_programs?${filter}`);
    const programs: PartnerProgramObject[] = Object.values(
      get(response, 'entities.noodlePartnersProgram', {}),
    ) as any;
    return randomize(programs);
  }

  async getProgramGroups(sorterOptions: SorterOption[] = []): Promise<GetProgramGroupsPayload> {
    const response = await this.jsonApi.get('/noodle_partners_programs?page[size]=0');
    const programs: PartnerProgramObject[] = Object.values(
      get(response, 'entities.noodlePartnersProgram', {}),
    ) as any;
    const programGroups = groupPartnerPrograms(programs);
    const sortedProgramGroups = sortProgramGroups(programGroups, sorterOptions);
    const updatedSorterOptions = updateSorterOptions(sorterOptions, sortedProgramGroups);
    return {
      programGroups: sortedProgramGroups,
      sorterOptions: updatedSorterOptions,
    };
  }

  async requestInfo(payload: PartnerProgramRequestInfoPayload): Promise<string> {
    const apiPayload = {
      attributes: payload,
      type: 'noodle_partners_lead_schema',
    };
    await this.jsonApi.post('/noodle_partners_leads', apiPayload, false);
    return 'Success!';
  }

  async getPartnerPrograms({ sort = '' }: Record<string, any>): Promise<Record<string, any>[]> {
    try {
      const response = await this.jsonApi.get(
        `/v2/partner_programs${sort ? '?sort=random' : ''}`,
        false,
      );
      const programs = get(response, 'data');
      return programs;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getSavedPrograms(): Promise<any> {
    const urlRequest = '/teams/saved-programs';
    const response = await this.jsonApi.get(urlRequest, false);
    const responseObj = get(response, 'data', {});
    const courses = responseObj.map((program) => ({ ...program?.attributes, id: program?.id }));
    return courses;
  }
}
