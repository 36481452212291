import styled from 'styled-components';

export const BaseCardContainer = styled.div<{ backgroundImage?: string }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.noodera.spacing.x6};
  padding-right: ${({ theme }) => theme.noodera.spacing.x7};
  gap: ${({ theme }) => theme.noodera.spacing.x6};
  min-height: 96px;
  border-radius: 8px;
  ${({ backgroundImage }) =>
    backgroundImage
      ? `background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url(${backgroundImage});`
      : `background-color: rgba(0, 119, 153, 0.1);`}
  background-size: cover;
  background-position: center;
  box-shadow: 0px 2px 5px #ddd;
  cursor: pointer;
`;

export const ProgressIconWrapper = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid #bdbebf;
  border-radius: 100%;
`;

export const CourseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.noodera.spacing.x8};
  flex: 1;
`;

export const CourseTitle = styled.span`
  ${({ theme }) => theme.noodera.fontTypes.font4Medium}
  font-weight: 600;
  color: white;
`;

export const CourseStartDate = styled.span`
  ${({ theme }) => theme.noodera.fontTypes.font5}
  font-weight: 500;
  color: white;
`;

export const AccessIconWrapper = styled.span<{ isInverted?: boolean }>`
  font-size: 16px;
  color: ${({ isInverted }) => (isInverted ? 'white' : 'black')};
`;
