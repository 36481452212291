// @ts-nocheck
import * as React from 'react';
import StripePayment from 'components/StripePayment/index';
import NooderaButton from 'components/NooderaButton';
import type { PaymentProps } from './Payment.types';
import { Container, Title, Wrapper } from './Payment.styles';

const Payment = ({
  onFinish,
  productCode,
  ctaText,
  courseSlug,
}: PaymentProps): React.ReactElement<any> | null => (
  <Wrapper>
    <Container>
      <Title>Payment Information</Title>
      <StripePayment
        submitElement={
          <NooderaButton.CTA isFullWidth type='submit'>
            {ctaText}
          </NooderaButton.CTA>
        }
        onPaymentSuccess={onFinish}
        paymentData={{
          product_code: productCode,
          course_slug: courseSlug,
        }}
      />
    </Container>
  </Wrapper>
);

export default Payment;
