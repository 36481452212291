import * as React from 'react';
import Tag from 'components/Tag';
import type { ActionProps } from './Action.types';
import { Wrapper } from './Action.styles';

const Action = ({
  name,
  icon,
  align,
  onClick,
  className,
}: ActionProps): React.ReactElement<any> => (
  <Wrapper onClick={onClick} className={className} role='button' aria-label={name}>
    <Tag inline>
      {align === 'right' ? (
        <>
          {name} {icon}
        </>
      ) : (
        <>
          {icon} {name}
        </>
      )}
    </Tag>
  </Wrapper>
);

export default Action;
