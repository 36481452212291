// @ts-nocheck
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  palette: { greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  background: ${greyscale.white};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`;
export const Container: StatelessFunctionalComponent<any> = styled.div`
  && {
    width: 100%;
    padding-top: ${spacing.x3};
    padding-bottom: ${spacing.x3};
    max-width: 432px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .MuiInputBase-root {
      button:focus,
      input:focus,
      select:focus,
      textarea:focus {
        height: 13px;
        border-radius: 8px;
      }

      & .bCaccn.bCaccn {
        border: none;
      }
    }

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      max-height: unset;
      padding: ${spacing.x3} ${spacing.x5};
    }
  }
`;
export const Title: StatelessFunctionalComponent<any> = styled.h4`
  ${fontTypes.font4Medium};
  margin-bottom: ${spacing.x6};
`;
