import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import { urlComposer } from 'utils/string';
import { fullName } from 'utils/user/name';
import { adminNoodlingConstants, noodlingConstants } from 'commons/constants';
import type {
  AdminNoodlingRecommendation,
  NoodlingAssociations,
  NoodlingAuthor,
  NoodlingObject,
  NoodlingSchoolGroups,
  RawAdminNoodlingAssociation,
  RawNoodlingAuthor,
} from 'commons/types';
import { getTextContext, parseAssociations } from '../AdminNoodling';

const { RECOMMENDATION_TYPES_MAP } = adminNoodlingConstants;
const { CATEGORY_COLORS_MAP, INSTITUTION_KEY, SCHOOL_KEY } = noodlingConstants;

const getAuthorData = ({ id, avatar, tagline, profile }: RawNoodlingAuthor): NoodlingAuthor => ({
  id,
  avatar,
  fullName: fullName(profile),
  tagline,
});

const parseSchools = (
  associations: NoodlingAssociations,
  schoolKey: string,
): NoodlingSchoolGroups => {
  const schoolgroups = {};
  Object.keys(associations)
    .filter((key) => key.startsWith(schoolKey))
    .forEach((key) => {
      const value = key.split(schoolKey)[1];
      const category = {
        value,
        color: CATEGORY_COLORS_MAP[value],
      };
      schoolgroups[key] = { ...associations[key], category };
    });
  return schoolgroups;
};

export default class Noodling {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getById(noodlingId: string): Promise<NoodlingObject> {
    const includes = ['author', 'noodling_associations', 'recommendations'].toString();
    const response = await this.jsonApi.get(
      urlComposer(`/noodlings/${noodlingId}`, {
        includes,
      }),
    );
    const noodling = get(response, `entities.noodling.${noodlingId}`, {});

    if (!noodling.id) {
      throw new Error('Noodling does not exist');
    }

    const { authorId } = noodling;
    const author = get(response, `entities.user.${authorId}`, {});
    const associations: RawAdminNoodlingAssociation[] = Object.values(
      get(response, 'entities.noodlingAssociations', {}),
    ) as any;
    const recommendations: AdminNoodlingRecommendation[] = Object.values(
      get(response, 'entities.recommendation', {}),
    ) as any;
    const noodlingAssociations = parseAssociations(associations, recommendations);
    noodling.author = getAuthorData(author);
    noodling.insights = getTextContext(recommendations, RECOMMENDATION_TYPES_MAP.PERSONA);
    noodling.schoolGroups = parseSchools(noodlingAssociations, SCHOOL_KEY);
    noodling.institutionGroups = parseSchools(noodlingAssociations, INSTITUTION_KEY);
    delete noodling.noodlingAssociations;
    delete noodling.recommendations;
    return noodling;
  }
}
