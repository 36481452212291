import * as React from 'react';
import MetaTags from 'react-meta-tags';
import { NOODLE_DEFAULT_IMAGE } from 'commons/constants';
import addHttpsToUrl from 'utils/string/image-url';
import type { MetaTagProps } from './MetaTags.types';

const renderNoIndexTag = (noIndex: boolean): React.ReactElement<any> =>
  noIndex ? (
    <meta id='meta-tag-no-index' name='robots' content='noindex' />
  ) : (
    <meta id='meta-tag-no-index' />
  );

const MetaTagsComponent = ({
  title,
  image,
  description,
  ogTitle,
  ogType,
  ogUrl,
  twitterCard,
  noIndex = false,
  robots,
}: MetaTagProps) => {
  const imageWithProtocol = addHttpsToUrl(image);
  const finalImage = imageWithProtocol || NOODLE_DEFAULT_IMAGE;
  return (
    <MetaTags>
      <title>{title}</title>
      <meta property='title' content={title} />
      <meta name='description' content={description} />

      {ogTitle && <meta property='og:title' content={ogTitle} />}
      {ogType && <meta property='og:type' content={ogType} />}
      <meta property='og:image' content={finalImage} />
      <meta property='og:site_name' content='Noodle' />
      <meta property='og:description' content={description} />
      {ogUrl && <meta property='og:url' content={ogUrl} />}

      {ogTitle && <meta name='twitter:title' content={ogTitle} />}
      {twitterCard && <meta name='twitter:card' content={twitterCard} />}
      <meta name='twitter:image' content={finalImage} />
      <meta name='twitter:site' content='@noodleeducation' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />

      {ogUrl && <link rel='canonical' href={ogUrl} />}
      {robots && <meta name='robots' content={robots} />}
      {renderNoIndexTag(noIndex)}
    </MetaTags>
  );
};

export default MetaTagsComponent;
