import * as React from 'react';
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const {
  noodera: { fontTypes, spacing },
  palette,
} = getTheme();
export const Wrapper = styled(Box)`
  && {
    border-radius: 8px;
    & .MuiInputLabel-root {
      ${fontTypes.font4Medium};
      color: ${palette.neutral.eighty};
      margin-bottom: ${spacing.x7};
    }

    & .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-top: -${spacing.x8};
    }

    .Mui-selected {
      background: ${palette.primary.hundred} !important;
    }
  }
`;

// Styling the main select component.
export const StyledMUISelect = styled(Select)`
  && {
    ${fontTypes.font4};
    display: flex;
    flex-grow: 1;
    border: 1px solid ${palette.neutral.forty};
    border-radius: 8px;
    height: ${({ size }) => (size === 'small' ? '32px' : '48px')};
    color: ${palette.greyscale.black};

    & .MuiOutlinedInput-notchedOutline {
      border: none;

      & > legend {
        display: none;
      }
    }

    /* Select option is tied to the select input text so doing this to remove
    the icon from the select view */
    & .MuiSelect-select > div {
      display: none;
    }
  }
`;

// Styling the menu outside the DOM here
export const StyledSelect = styled(({ className, ...props }) => (
  <StyledMUISelect
    {...props}
    MenuProps={{
      classes: { paper: className },
    }}
  />
))`
  && {
    max-height: 33%;
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  && {
    ${fontTypes.font4};

    /* Explicitly adding the styles so it doesn't conflict with the mui date picker */
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    min-height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px;

    > * {
      &:first-child {
        color: ${palette.neutral.eighty};
      }
    }

    :disabled {
      display: none;
    }
  }
`;
export const Placeholder = styled.span`
  && {
    ${fontTypes.font5};
    color: ${palette.neutral.seventy};
  }
`;

export const IconContainer = styled.div`
  &&& {
    display: inline-block;
    height: 24px;
    width: 24px;
    color: ${palette.accent.three};
    margin-right: ${spacing.x7};

    & svg {
      height: 24px;
      width: 24px;
    }
  }
`;

export const SExpandMoreIcon = styled(ExpandMoreIcon)`
  &&& {
    pointer-events: none;
    position: absolute;
    right: 10px;
    height: 24px;
    width: 24px;
    margin-top: 0;
  }
`;
