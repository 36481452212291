import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import dashboardActions from 'ducks/dashboard/actions';
import {
  useAddUserToCourseWaitlistMutation,
  useGetPartnerCoursePreviewQuery,
  useGetUsersCourseWaitlistRecordsQuery,
} from 'lib/Api';
import LoaderInline from 'components/uielements/LoaderInline';

const CourseWaitlist: React.FC = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const { isLoading: isCourseLoading, data: coursePreviewData } = useGetPartnerCoursePreviewQuery({
    slug: slug || null,
    partnerId: globalPartner?.partnerId,
  });
  const [addUserToCourseWaitlist] = useAddUserToCourseWaitlistMutation();
  const { data: usersCourseWaitlistData, isLoading: isUsersCourseWaitlistLoading } =
    useGetUsersCourseWaitlistRecordsQuery(
      coursePreviewData ? { courseContentfulIds: [coursePreviewData?.sys?.id] } : null,
    );
  const userWaitlistRecord =
    usersCourseWaitlistData && coursePreviewData
      ? usersCourseWaitlistData?.waitlists?.find(
          (element) => element.courseContentfulId === coursePreviewData?.sys?.id,
        )
      : null;

  if (!isLoggedIn) {
    history.push(`/login?returnUrl=/waitlist/${slug}`);
  }

  React.useEffect(() => {
    if (
      isLoggedIn &&
      !isCourseLoading &&
      !isUsersCourseWaitlistLoading &&
      !userWaitlistRecord &&
      slug &&
      coursePreviewData
    ) {
      addUserToCourseWaitlist({
        courseContentfulId: coursePreviewData?.sys?.id,
        userId,
      });
      history.push('/');
    } else if (slug && userWaitlistRecord) {
      dispatch(dashboardActions.setModalAction('userAlreadyOnWaitlist'));
      history.push('/');
    }
  }, [slug, isCourseLoading, isUsersCourseWaitlistLoading]);

  return <LoaderInline />;
};

export default CourseWaitlist;
