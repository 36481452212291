import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import Action from 'components/Action/index';
import type { SaveActionProps } from './SaveAction.types';

const SaveAction = ({
  isSaved = false,
  onClick,
  className,
}: SaveActionProps): React.ReactElement<any> => (
  <Action
    className={className}
    align='right'
    name={isSaved ? 'Saved' : 'Save'}
    icon={isSaved ? <DoneIcon /> : <AddIcon />}
    onClick={onClick}
  />
);

export default SaveAction;
