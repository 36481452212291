import * as React from 'react';
// Components
import RadioGroup from '@mui/material/RadioGroup';
import { Controller } from 'react-hook-form';
import type { RadioButtonProps } from './RadioButton.types';
import {
  StyledRadio,
  StyledCard,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormControlContent,
  LabelContent,
  Title,
  Subtitle,
  BoldText,
  TextContent,
  IconWrapper,
  ImageWrapper,
} from './RadioButton.styles';

const useDefaultValue = (
  defaultValue: string | typeof undefined,
  setDefaultValue: (...args: Array<any>) => any,
): void => {
  React.useEffect(() => {
    if (defaultValue) {
      setDefaultValue(defaultValue);
    }
  }, [defaultValue]);
};

const RadioButton = ({
  name,
  items,
  size,
  onChange,
  defaultValue,
}: RadioButtonProps): React.ReactElement<any> => {
  const [value, setValue] = React.useState('');
  useDefaultValue(defaultValue, setValue);

  const handleChange = (event): void => {
    const current = event.target.value;
    setValue(current);
    onChange(current);
  };

  const renderItems = (list): Array<React.ReactElement<any> | null> | null =>
    items?.length
      ? list.map((item) => (
          <StyledFormControlLabel
            label={item.name}
            value={item.value}
            control={<StyledRadio size={size} disabled={item.disabled} />}
          />
        ))
      : null;

  return (
    <StyledFormControl component='fieldset'>
      <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
        {renderItems(items)}
      </RadioGroup>
    </StyledFormControl>
  );
};

const CustomContent = ({
  name,
  items,
  size,
  onChange,
  defaultValue,
}: RadioButtonProps): React.ReactElement<any> => {
  const [value, setValue] = React.useState('');
  useDefaultValue(defaultValue, setValue);

  const handleChange = (event) => {
    const current = event.target.value;
    setValue(current);
    onChange(name.toLowerCase(), current);
  };

  const renderItems = (list): Array<React.ReactElement<any> | null> | null =>
    items?.length
      ? list.map((item) => (
          <StyledCard isActive={item.value === value} isDisabled={item.disabled} key={item.name}>
            <StyledFormControlContent
              data-testid={`privacy-label-${item.value}`}
              value={item.value}
              control={<StyledRadio size={size} />}
              label={
                <LabelContent>
                  <TextContent>
                    <Title>{item.name}</Title>
                    <Subtitle variant='body2' component='p'>
                      {item.subtitle} <BoldText>{item.linkText}</BoldText>
                    </Subtitle>
                  </TextContent>
                  <IconWrapper>{item.icon}</IconWrapper>
                </LabelContent>
              }
            />
          </StyledCard>
        ))
      : null;

  return (
    <StyledFormControl component='fieldset'>
      <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
        {renderItems(items)}
      </RadioGroup>
    </StyledFormControl>
  );
};

const CustomPayment = ({
  name,
  items,
  size,
  onChange,
}: RadioButtonProps): React.ReactElement<any> => {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    const current = event.target.value;
    setValue(current);
    onChange(current);
  };

  const renderItems = (list): Array<React.ReactElement<any> | null> | null =>
    items?.length
      ? list.map((item) => (
          <StyledCard isActive={item.value === value} isDisabled={item.disabled}>
            <StyledFormControlContent
              value={item.value}
              control={<StyledRadio size={size} />}
              label={
                <LabelContent>
                  <TextContent>
                    <Title>{item.name}</Title>
                  </TextContent>
                  <ImageWrapper>{item.icon}</ImageWrapper>
                </LabelContent>
              }
            />
          </StyledCard>
        ))
      : null;

  return (
    <StyledFormControl component='fieldset'>
      <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
        {renderItems(items)}
      </RadioGroup>
    </StyledFormControl>
  );
};

const ControlledCustomRadioButtons = ({
  control,
  name,
  items,
  size,
  onChange,
  defaultValue,
}: RadioButtonProps) => {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    const current = event.target.value;
    setValue(current);
    onChange(current);
  };

  return (
    <Controller
      render={({ field }) => {
        setValue(field?.value);
        return (
          <StyledFormControl component='fieldset'>
            <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
              {items.map((item) => (
                <StyledCard
                  isActive={item.value === value}
                  isDisabled={item.disabled}
                  key={item.name}
                  {...field}
                >
                  <StyledFormControlContent
                    data-testid={`privacy-label-${item.value}`}
                    value={item.value}
                    control={<StyledRadio size={size} />}
                    label={
                      <LabelContent>
                        <TextContent>
                          <Title>{item.name}</Title>
                          <Subtitle variant='body2' component='p'>
                            {item.subtitle} <BoldText>{item.linkText}</BoldText>
                          </Subtitle>
                        </TextContent>
                        <IconWrapper>{item.icon}</IconWrapper>
                      </LabelContent>
                    }
                  />
                </StyledCard>
              ))}
            </RadioGroup>
          </StyledFormControl>
        );
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

RadioButton.CustomContent = CustomContent;
RadioButton.CustomPayment = CustomPayment;
RadioButton.ControlledCustomRadioButtons = ControlledCustomRadioButtons;
export default RadioButton;
