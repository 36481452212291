import type { TeacherAssistantState } from '../../commons/types/teacherAssistantType';
import {
  getMeetingDetailTypes,
  joinSplitCostTypes,
  getSchedulerAvailabilityTypes,
  createTASchedulerBookingTypes,
  schedulerBookingTypes,
} from './types';

export const initialState: TeacherAssistantState = {
  availability: {
    isLoading: false,
    data: [],
    error: '',
  },
  booking: {
    success: false,
    isLoading: false,
    error: '',
  },
  joinSplitCost: {
    isLoading: false,
    success: false,
    error: '',
  },
  meetingDetails: {
    isLoading: false,
    data: [],
    error: '',
  },
};
type Action = {
  type: string;
  payload?: any;
};

const teacherAssistantReducer = (state: TeacherAssistantState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getMeetingDetailTypes.GET_MEETING_DETAIL_REQUEST: {
      return { ...state, meetingDetails: { ...initialState.meetingDetails, isLoading: true } };
    }

    case getMeetingDetailTypes.GET_MEETING_DETAIL_SUCCESS: {
      return {
        ...state,
        meetingDetails: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getMeetingDetailTypes.GET_MEETING_DETAIL_FAILURE: {
      return { ...state, meetingDetails: { ...initialState.meetingDetails, error: payload } };
    }

    case getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_REQUEST: {
      return { ...state, availability: { ...initialState.availability, isLoading: true } };
    }

    case getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        availability: { ...initialState.availability, isLoading: false, data: payload },
      };
    }

    case getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_FAILURE: {
      return {
        ...state,
        availability: { ...initialState.availability, isLoading: false, error: payload },
      };
    }

    case joinSplitCostTypes.JOIN_SPLIT_COST_REQUEST: {
      return {
        ...state,
        joinSplitCost: {
          isLoading: true,
          success: false,
        },
      };
    }

    case joinSplitCostTypes.JOIN_SPLIT_COST_SUCCESS: {
      return {
        ...state,
        joinSplitCost: {
          isLoading: false,
          success: true,
        },
      };
    }

    case joinSplitCostTypes.JOIN_SPLIT_COST_FAILURE: {
      return {
        ...state,
        joinSplitCost: {
          error: payload,
          success: false,
          isLoading: false,
        },
      };
    }

    case joinSplitCostTypes.JOIN_SPLIT_COST_RESET: {
      return { ...state, joinSplitCost: { ...initialState.joinSplitCost } };
    }

    case createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_REQUEST: {
      return { ...state, booking: { ...initialState.booking, isLoading: true } };
    }

    case createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_SUCCESS: {
      return { ...state, booking: { ...initialState.booking, isLoading: false, success: true } };
    }

    case createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_FAILURE: {
      return { ...state, booking: { ...initialState.booking, isLoading: false, error: payload } };
    }

    case schedulerBookingTypes.RESET_BOOKING_STATUS: {
      return { ...state, booking: { ...initialState.booking } };
    }

    default:
      return state;
  }
};

export default teacherAssistantReducer;
