export const adminConstants = {
  DEFAULT_SORTING: 'email',
  DEACTIVATING_USER_MODAL_TITLE: 'Are you sure?',
  DEACTIVATING_USER_MODAL_SUBTITLE: (userEmail = 'this user') =>
    `Deactivating ${userEmail} will disable their login, but maintain all their data in our DB`,
  CHANGE_EMAIL_TOOLTIP:
    'Need to change the email associated with your account? Please contact info@noodle.com',
  NO_USERS: 'No Users Found',
  NO_LANDING_PAGES: 'No Landing Pages Found',
  NO_TIMEKIT_PROJECTS: 'No TIMEKIT PROJECTS Found',
  NO_PARTNER_PROGRAMS: 'No PARTNER PROGRAMS Found',
  NO_SCHOOLS: 'No Schools Found',
};
export const adminDebounceConstants = {
  FILTER_INPUT_CHANGE_DELAY: 300,
  GET_SCHOOLS_DELAY: 300,
  GET_USERS_DELAY: 300,
  GET_LANDING_PAGES_DELAY: 300,
  GET_TIMEKIT_PROJECTS_DELAY: 300,
  GET_PARTNER_PROGRAMS_DELAY: 300,
  SUGGESTION_DELAY: 300,
  SEARCH_DELAY: 300,
};
export const adminNoodlingConstants = {
  FILTER_ENTITIES: {
    REGION: 'personaRegion',
    TAXONOMY: 'taxonomy',
  },
  PERSONA_FIELDS: {
    PERSONA_REGION: 'region',
  },
  RECOMMENDATION_TYPES_MAP: {
    GROUP: 'GROUPS',
    INSTITUTION: 'INSTITUTIONS',
    INSTITUTION_REACH: 'INSTITUTIONS_REACH',
    INSTITUTION_TARGET: 'INSTITUTIONS_TARGET',
    INSTITUTION_SAFETY: 'INSTITUTIONS_SAFETY',
    INSTITUTION_WILDCARD: 'INSTITUTIONS_WILDCARD',
    PERSONA: 'PERSONA',
    SCHOOL: 'SCHOOLS',
    SCHOOL_REACH: 'SCHOOLS_REACH',
    SCHOOL_TARGET: 'SCHOOLS_TARGET',
    SCHOOL_SAFETY: 'SCHOOLS_SAFETY',
    SCHOOL_WILDCARD: 'SCHOOLS_WILDCARD',
    VIDEO: 'VIDEOS',
  },
};
