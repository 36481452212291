import * as React from 'react';
import { useSelector } from 'react-redux';
import { getIsPrivateLabel } from 'index';

import { useGetShoppingCartQuery } from 'lib/Api';

import { useAutoSelect, useWidth } from 'utils/customHooks';
import { redirectWithUtmQueryStrings } from 'utils/urls';
import { getSessionStorageData } from 'utils/sessionStorage';
import { UTM_VALUES } from 'utils/heap';
import { isNonNoodleInstance, getAppSettings } from 'utils/privateLabel/index';

import NooderaButton from 'components/NooderaButton/index';
import NotificationHeader from 'components/NotificationHeader';
import Bell from 'components/Bell';
import HamburgerMenu from 'components/HamburgerMenu';
import AdminAction from 'components/AdminAction';
import NooderaModal from 'components/NooderaModal/index';
import LoaderInline from 'components/uielements/LoaderInline/index';
import { SearchLink } from 'components/Header/Header.styles';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { ReactComponent as NoodleSmallLogo } from 'assets/images/isotype.svg';
import { ReactComponent as NoodleLogo } from 'assets/svg/noodle_logo.svg';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import ShoppingCartIcon from 'components/ShoppingCartIcon';

import { accessibilityConstants, CELLPHONE_SMALL_WIDTH } from 'commons/constants';

import {
  ActionsContainer,
  LogoLink,
  MobileHeaderContainer,
  Heading,
  LoadingContainer,
  LoadingText,
  MobileHeaderActionsContainer,
  StyledLaunchIcon,
  CompanyLogo,
  StyledLogo,
} from './MobileHeader.styles';

const MobileHeader: React.FC = () => {
  const width = useWidth();
  const { data: shoppingCartData } = useGetShoppingCartQuery({ isTemporary: false });

  const isLoggedIn: boolean = useSelector((state) => state.auth.isLoggedIn);
  const unseenNotifications = useSelector((state) => state.getStream?.notifications?.data?.unseen);
  const { data: globalPartner, isLoading: isGlobalPartnerLoading } = useSelector(
    (state) => state.partnerInstance.globalPartner,
  );

  useAutoSelect();

  const biggerThanMobile = width > CELLPHONE_SMALL_WIDTH;
  const showNotificationsTitle = window.location.href.includes('/notifications');
  const isShoppingCartEnabled = globalPartner?.isShoppingCartEnabled;
  const utmParams: Record<string, string> = getSessionStorageData(UTM_VALUES);

  const [isLoadingModalOpen, setIsLoadingModalOpen] = React.useState(false);
  const [loadingModalText, setLoadingModalText] = React.useState('');

  const getLogo = (): React.ReactElement => {
    if (!isLoggedIn) {
      return isNonNoodleInstance ? (
        <CompanyLogo src={globalPartner?.companySmallLogo} />
      ) : (
        <NoodleLogo />
      );
    }

    return isNonNoodleInstance ? (
      <CompanyLogo src={globalPartner?.companySmallLogo} />
    ) : (
      <NoodleSmallLogo />
    );
  };

  const renderLogo = (): React.ReactElement => {
    const { companyLogo } = getAppSettings() || globalPartner;

    if (React.isValidElement(companyLogo)) {
      return companyLogo;
    }

    return <StyledLogo src={companyLogo} alt='company logo' title='Noodle' />;
  };

  const Menu: React.FC = () => {
    let customHref = '/';
    let customTarget = '_self';
    const partnerMicrosite = globalPartner?.micrositeLandingPageUrl;
    if (partnerMicrosite && globalPartner?.isMicrositeEnabled) {
      customHref = partnerMicrosite;
      customTarget = '_blank';
    }

    if (showNotificationsTitle) {
      return <NotificationHeader />;
    }
    if (isNonNoodleInstance && !isGlobalPartnerLoading) {
      return (
        <LogoLink href={customHref} target={customTarget}>
          {renderLogo()}
        </LogoLink>
      );
    }
    return (
      <LogoLink href=''>
        <SvgImageWrapper>{getLogo()}</SvgImageWrapper>
      </LogoLink>
    );
  };

  const Actions: React.FC = () => {
    const isPrivateLabel = getIsPrivateLabel();

    if (!isNonNoodleInstance) {
      return (
        <MobileHeaderActionsContainer>
          {!isNonNoodleInstance && !isLoggedIn ? (
            <a href='https://highered.noodle.com/' target='_blank' rel='noreferrer'>
              <NooderaButton size={biggerThanMobile ? 'medium' : 'small'}>
                {biggerThanMobile ? <StyledLaunchIcon /> : null}
                <span>For Universities</span>
              </NooderaButton>
            </a>
          ) : null}
          {isLoggedIn && <Bell count={unseenNotifications} />}
          <SearchLink
            title={accessibilityConstants.globalSearchLink.title}
            aria-label={accessibilityConstants.globalSearchLink.ariaLabel}
            to='/search'
          >
            <SearchIcon />
          </SearchLink>
          <HamburgerMenu />
        </MobileHeaderActionsContainer>
      );
    }
    if (!isLoggedIn) {
      return (
        <>
          {isShoppingCartEnabled ? <ShoppingCartIcon shoppingCartData={shoppingCartData} /> : null}
          <NooderaButton.CTA
            size='medium'
            onClick={() => {
              const { frontDomain = '' } = globalPartner;
              const authUrl = isPrivateLabel ? `${frontDomain}/login` : `${frontDomain}/signup`;

              redirectWithUtmQueryStrings(authUrl, utmParams);
            }}
          >
            {isPrivateLabel ? 'Log In' : 'Sign Up'}
          </NooderaButton.CTA>
        </>
      );
    }

    if (isShoppingCartEnabled) {
      return (
        <>
          <ShoppingCartIcon shoppingCartData={shoppingCartData} />
          <HamburgerMenu />
        </>
      );
    }

    return <HamburgerMenu />;
  };

  return (
    <MobileHeaderContainer>
      <Heading>
        <Menu />
        <ActionsContainer>
          <AdminAction
            setIsLoadingModalOpen={setIsLoadingModalOpen}
            setLoadingModalText={setLoadingModalText}
          />
          <Actions />
        </ActionsContainer>
      </Heading>
      <NooderaModal hasAppBar={false} isOpen={isLoadingModalOpen}>
        <LoadingContainer>
          <LoaderInline width='9.6rem' />
          <LoadingText>{loadingModalText}</LoadingText>
        </LoadingContainer>
      </NooderaModal>
    </MobileHeaderContainer>
  );
};

export default MobileHeader;
