// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import type { Saga } from 'redux-saga';
import { push } from 'connected-react-router';
import { alertTypesConstants } from 'commons/constants/index';
import { getExploreItemsTypes } from './types';
import { exploreActions } from './actions';

const { DANGER } = alertTypesConstants;
export function* getExploreItemsSaga(): Saga<void> {
  try {
    const response = yield call([api.explore, 'getExploreItems']);
    yield put(exploreActions.getExploreItemsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the explore menu items!',
        type: DANGER,
      }),
    );
    yield put(exploreActions.getExploreItemsFailed(error));
    yield put(push('/404'));
  }
}
export default function* schedulerSaga(): Saga<void> {
  yield takeLatest(getExploreItemsTypes.REQUEST, getExploreItemsSaga);
}
