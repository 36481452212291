import * as React from 'react';
import LoaderInline from 'components/uielements/LoaderInline/index';
import type { ImagePickerResultsProps } from './ImagePickerResults.types';
import ImagePickerPagination from '../ImagePickerPagination/index';
import { ImageListContainer } from './ImagePickerResults.styles';
import ImageItem from '../ImageItem/index';

const ImagePickerResults = ({
  isLoading,
  searchTerm,
  selectedPhoto,
  coverImages,
  coverImagesPage,
  onSelectPhoto,
  onSearchOtherPage,
}: ImagePickerResultsProps): React.ReactElement<any> | null => {
  const [wrapperRef, setWrapperRef] = React.useState(null);
  const amountOfImages = coverImages ? coverImages.length : 0;
  React.useEffect(() => {
    if (wrapperRef) {
      wrapperRef.scrollTo(0, 0);
    }
  }, [wrapperRef]);

  if (searchTerm && searchTerm.length > 2) {
    if (coverImages && coverImages.length > 0) {
      return (
        <>
          <ImageListContainer ref={(node: any) => setWrapperRef(node)}>
            {coverImages.map((coverImage) => (
              <ImageItem
                key={coverImage.id}
                image={coverImage}
                selectedPhoto={selectedPhoto}
                selectPhoto={onSelectPhoto}
              />
            ))}
          </ImageListContainer>
          <ImagePickerPagination
            coverImagesPage={coverImagesPage}
            amountOfImages={amountOfImages}
            onSearchOtherPage={onSearchOtherPage}
            wrapperRef={wrapperRef}
          />
        </>
      );
    }

    if (isLoading) {
      return (
        <>
          <div className='dialog__loader-row'>
            <div>
              <LoaderInline />
            </div>
          </div>
          <ImagePickerPagination
            coverImagesPage={coverImagesPage}
            amountOfImages={amountOfImages}
            onSearchOtherPage={onSearchOtherPage}
            wrapperRef={wrapperRef}
          />
        </>
      );
    }

    if (coverImages && coverImages.length === 0) {
      return (
        <div className='dialog__no-results-row'>
          <p className='text-content-body'>No images were found!</p>
        </div>
      );
    }
  }

  return null;
};

export default ImagePickerResults;
