// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing },
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    max-width: 502px;
    margin-bottom: ${spacing.x3};

    & > div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      &: first-child {
        margin-bottom: ${spacing.x3};
      }
    }
  }
`;
