/* eslint-disable no-useless-escape */
export const US_TIMEZONES = [
  {
    name: 'Eastern Time',
    value: 'America/New_York',
  },
  {
    name: 'Central Time',
    value: 'America/Chicago',
  },
  {
    name: 'Arizona Time',
    value: 'America/Phoenix',
  },
  {
    name: 'Mountain Time',
    value: 'America/Denver',
  },
  {
    name: 'Pacific Time',
    value: 'America/Los_Angeles',
  },
  {
    name: 'Alaska Time',
    value: 'America/Juneau',
  },
  {
    name: 'Hawaii Time',
    value: 'Pacific/Honolulu',
  },
];
export const TIME_FORMAT = 'hh:mma';
export const DATE_FORMAT = 'ddd MMM Do';
export const CALENDAR_EMBED_TITLE_DEFAULT = 'Schedule a free 20-minute call';
export const CALENDAR_EMBED_SUBTITLE_DEFAULT =
  'With a member of our University Partnerships team to discuss your school’s unique needs.';
