// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import Separator from 'components/uielements/Separator/index';

const {
  noodera: { spacing },
  palette: { neutral },
} = getTheme();
export const StyledSeparator: StatelessFunctionalComponent<any> = styled(Separator)`
  width: 4.8rem;
  margin: ${spacing.x4} auto;
`;
export const StyledMobileSeparator: StatelessFunctionalComponent<any> = styled.div`
  height: 4.8rem;
  width: 1px;
  background: ${neutral.twenty};
  margin: auto;
`;
