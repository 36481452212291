import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { rtkQApi } from 'lib/Api';

import adminGeneralReducer from './adminGeneral/reducer';
import adminNoodlingReducer from './adminNoodling/reducer';
import adminUserReducer from './adminUser/reducer';
import adminSchedulerReducer from './adminScheduler/reducer';
import adminPartnerProgramReducer from './adminPartnerProgram/reducer';
import alertReducer from './alert/reducer';
import authReducer from './auth/reducer';
import autosuggestReducer from './autosuggest/reducer';
import bookmarkReducer from './bookmarks/reducer';
import counselorReducer from './counselor/reducer';
import dashboardReducer from './dashboard/reducer';
import exploreReducer from './explore/reducer';
import likeReducer from './like/reducer';
import globalSearchReducer from './globalSearch/reducer';
import getStreamReducer from './getStream/reducer';
import groupsReducer from './groups/reducer';
import landingPagesReducer from './landingPages/reducer';
import modalReducer from './modal/reducer';
import noodlingReducer from './noodling/reducer';
import onboardingReducer from './onboarding/reducer';
import partnerProgramsReducer from './partnerPrograms/reducer';
import paymentReducer from './payment/reducer';
import partnerInstanceReducer from './partnerInstance/reducer';
import schedulerReducer from './scheduler/reducer';
import surveyReducer from './survey/reducer';
import universityReducer from './university/reducer';
import userConnectionReducer from './userConnection/reducer';
import userReducer from './user/reducer';
import teamReducer from './team/reducer';
import sectionReducer from './section/reducer';
import courseReducer from './courses/reducer';
import productReducer from './product/reducer';
import teacherAssistantReducer from './teacherAssistant/reducer';

const rootReducer = (history) =>
  combineReducers({
    adminGeneral: adminGeneralReducer,
    adminNoodling: adminNoodlingReducer,
    adminUser: adminUserReducer,
    adminScheduler: adminSchedulerReducer,
    adminPartnerProgram: adminPartnerProgramReducer,
    alert: alertReducer,
    auth: authReducer,
    autosuggest: autosuggestReducer,
    bookmark: bookmarkReducer,
    counselor: counselorReducer,
    courses: courseReducer,
    dashboard: dashboardReducer,
    explore: exploreReducer,
    getStream: getStreamReducer,
    groups: groupsReducer,
    likes: likeReducer,
    landingPages: landingPagesReducer,
    modal: modalReducer,
    noodling: noodlingReducer,
    onboarding: onboardingReducer,
    partnerPrograms: partnerProgramsReducer,
    payment: paymentReducer,
    partnerInstance: partnerInstanceReducer,
    product: productReducer,
    [rtkQApi.reducerPath]: rtkQApi.reducer,
    router: connectRouter(history),
    scheduler: schedulerReducer,
    searchResults: globalSearchReducer,
    section: sectionReducer,
    survey: surveyReducer,
    teacherAssistant: teacherAssistantReducer,
    team: teamReducer,
    university: universityReducer,
    user: userReducer,
    userConnection: userConnectionReducer,
  });

export default rootReducer;
