export const getTeamTypes = {
  GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
  GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
  GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
};
export const getTeamsTypes = {
  GET_TEAMS_REQUEST: 'GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',
};
export const getMyTeamsTypes = {
  GET_MY_TEAMS_REQUEST: 'GET_MY_TEAMS_REQUEST',
  GET_MY_TEAMS_SUCCESS: 'GET_MY_TEAMS_SUCCESS',
  GET_MY_TEAMS_FAILURE: 'GET_MY_TEAMS_FAILURE',
};
export const getTeamPreviewTypes = {
  GET_TEAM_PREVIEW_REQUEST: 'GET_TEAM_PREVIEW_REQUEST',
  GET_TEAM_PREVIEW_SUCCESS: 'GET_TEAM_PREVIEW_SUCCESS',
  GET_TEAM_PREVIEW_FAILURE: 'GET_TEAM_PREVIEW_FAILURE',
};
export const teamTypes = {
  SELECT_TEAM: 'SELECT_TEAM',
  SELECT_RESOURCE: 'SELECT_RESOURCE',
  TOGGLE_SELECT_TEAM_MODAL: 'TOGGLE_SELECT_TEAM_MODAL',
  SELECT_FILTER_TEAM: 'SELECT_FILTER_TEAM',
};
export const joinTeamByInviteTypes = {
  JOIN_TEAM_BY_INVITE_REQUEST: 'JOIN_TEAM_BY_INVITE_REQUEST',
  JOIN_TEAM_BY_INVITE_SUCCESS: 'JOIN_TEAM_BY_INVITE_SUCCESS',
  JOIN_TEAM_BY_INVITE_FAILURE: 'JOIN_TEAM_BY_INVITE_FAILURE',
};
export const joinTeamRequestTypes = {
  JOIN_TEAM_REQUEST_REQUEST: 'JOIN_TEAM_REQUEST_REQUEST',
  JOIN_TEAM_REQUEST_SUCCESS: 'JOIN_TEAM_REQUEST_SUCCESS',
  JOIN_TEAM_REQUEST_FAILURE: 'JOIN_TEAM_REQUEST_FAILURE',
};
export const cancelTeamRequestTypes = {
  CANCEL_TEAM_REQUEST_REQUEST: 'CANCEL_TEAM_REQUEST_REQUEST',
  CANCEL_TEAM_REQUEST_SUCCESS: 'CANCEL_TEAM_REQUEST_SUCCESS',
  CANCEL_TEAM_REQUEST_FAILURE: 'CANCEL_TEAM_REQUEST_FAILURE',
};
export const createTeamTypes = {
  CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
  CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
  CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',
};
export const getTeamSavedResourcesTypes = {
  GET_TEAM_SAVED_RESOURCES_REQUEST: 'GET_TEAM_SAVED_RESOURCES_REQUEST',
  GET_TEAM_SAVED_RESOURCES_SUCCESS: 'GET_TEAM_SAVED_RESOURCES_SUCCESS',
  GET_TEAM_SAVED_RESOURCES_FAILURE: 'GET_TEAM_SAVED_RESOURCES_FAILURE',
};
export const inviteMembersTypes = {
  INVITE_MEMBERS_REQUEST: 'INVITE_MEMBERS_REQUEST',
  INVITE_MEMBERS_SUCCESS: 'INVITE_MEMBERS_SUCCESS',
  INVITE_MEMBERS_FAILURE: 'INVITE_MEMBERS_FAILURE',
  INVITE_MEMBERS_RESET: 'INVITE_MEMBERS_RESET',
};
export const approveJoinPetitionTypes = {
  APPROVE_JOIN_PETITION_REQUEST: 'APPROVE_JOIN_PETITION_REQUEST',
  APPROVE_JOIN_PETITION_SUCCESS: 'APPROVE_JOIN_PETITION_SUCCESS',
  APPROVE_JOIN_PETITION_FAILURE: 'APPROVE_JOIN_PETITION_FAILURE',
};
export const denyJoinPetitionTypes = {
  DENY_JOIN_PETITION_REQUEST: 'DENY_JOIN_PETITION_REQUEST',
  DENY_JOIN_PETITION_SUCCESS: 'DENY_JOIN_PETITION_SUCCESS',
  DENY_JOIN_PETITION_FAILURE: 'DENY_JOIN_PETITION_FAILURE',
};
export const saveContentTypes = {
  SAVE_CONTENT_REQUEST: 'SAVE_CONTENT_REQUEST',
  SAVE_CONTENT_SUCCESS: 'SAVE_CONTENT_SUCCESS',
  SAVE_CONTENT_FAILURE: 'SAVE_CONTENT_FAILURE',
};
