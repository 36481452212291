export const loginTypes = {
  SUCCESS: 'LOGIN_SUCCESS',
  FAILED: 'LOGIN_FAILED',
};
export const socialTypes = {
  CHECK: 'LOGIN_SOCIAL_CHECK',
  REQUEST: 'LOGIN_SOCIAL_REQUEST',
  SUCCESS: 'LOGIN_SOCIAL_SUCCESS',
  FAILED: 'LOGIN_SOCIAL_FAILED',
};
export const profileUpdateTypes = {
  SUCCESS: 'USER_PROFILE_UPDATE_SUCCESS',
  REQUEST: 'USER_PROFILE_UPDATE_REQUEST',
  FAILED: 'USER_PROFILE_UPDATE_FAILED',
};
export const otherUserTypes = {
  REQUEST: 'USER_GET_OTHER_REQUEST',
  SUCCESS: 'USER_GET_OTHER_SUCCESS',
  FAILED: 'USER_GET_OTHER_FAILED',
};
export const roleTypes = {
  STUDENT: 'ROLE_STUDENT_TOGGLE',
  EXPERT: 'ROLE_EXPERT_TOGGLE',
};
export const getUserDetailsTypes = {
  REQUEST: 'GET_USER_DETAILS_REQUEST',
  SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  FAILURE: 'GET_USER_DETAILS_FAILURE',
};
export const getOwnUserTypes = {
  REQUEST: 'GET_OWN_USER_REQUEST',
  SUCCESS: 'GET_OWN_USER_SUCCESS',
  FAILURE: 'GET_OWN_USER_FAILURE',
};
