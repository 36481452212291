// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import api from 'lib/Api';
import type { Bookmarkable, GetBookmarkedItemsType } from 'commons/types';
import { alertTypesConstants } from 'commons/constants';
import alertActions from 'ducks/alert/actions';
import { bookmarkConstants } from 'commons/constants/bookmark';
import { getUser } from 'ducks/user/selector';
import {
  bookmarkTypes,
  bookmarkedItemsTypes,
  saveArticleTypes,
  removeArticleTypes,
  getSavedItemsTypes,
} from './types';
import { bookmarkActions } from './actions';

const { SUCCESS, DANGER } = alertTypesConstants;
type RemoveArticleSagaPayload = {
  payload: {
    saveContentId: number;
  };
};
export function* bookmarkItemSaga({ payload }: { payload: Bookmarkable }): Saga {
  try {
    const { bookmarkedByUser } = payload;
    const functionToCall = bookmarkedByUser ? 'unbookmark' : 'bookmark';
    const response = yield call([api.bookmark, functionToCall], payload);
    yield put(bookmarkActions.bookmarkSuccess(response));
  } catch (error) {
    yield put(bookmarkActions.bookmarkFailure(error.toString()));
  }
}
export function* getBookmarkedItemsSaga({ payload }: { payload: GetBookmarkedItemsType }): Saga {
  try {
    const { page, filter } = payload;
    const user = yield select(getUser);
    const response = yield call([api.bookmark, 'fetchBookmarkedItems'], {
      userId: user.id,
      page,
      filter,
    });
    const reachedEnd = response.bookmarkedItems.length === 0;
    yield put(
      bookmarkActions.getBookmarkedItemsSuccess({
        reachedEnd,
        ...response,
        page,
        filter,
      }),
    );
  } catch (error) {
    yield put(bookmarkActions.getBookmarkedItemsFailure(error.toString()));
  }
}
export function* saveArticleSaga({ payload }: any): Saga<void> {
  try {
    const response = yield call([api.bookmark, 'saveArticle'], payload);
    yield put(
      alertActions.setAlert({
        message: bookmarkConstants.saveArticleSuccessMessage,
        type: SUCCESS,
      }),
    );
    yield put(bookmarkActions.saveArticleSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: bookmarkConstants.saveArticleErrorMessage,
        type: DANGER,
      }),
    );
    yield put(bookmarkActions.saveArticleFailure(error));
  }
}
export function* getSavedItemsSaga({ payload }: any): Saga<void> {
  try {
    const response = yield call([api.bookmark, 'getSavedItems'], payload);
    yield put(bookmarkActions.getSavedItemsSuccess(response));
  } catch (error) {
    yield put(bookmarkActions.getSavedItemsFailure(error));
  }
}
// TODO: Refactor this to accept any kind of contentful entry.
export function* removeArticleSaga({ payload }: RemoveArticleSagaPayload): Saga<void> {
  try {
    const response = yield call([api.bookmark, 'removeArticle'], payload);
    yield put(
      alertActions.setAlert({
        message: bookmarkConstants.deleteArticleSuccessMessage,
        type: SUCCESS,
      }),
    );
    yield put(bookmarkActions.removeArticleSuccess(response.meta));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: bookmarkConstants.deleteArticleErrorMessage,
        type: DANGER,
      }),
    );
    yield put(bookmarkActions.removeArticleFailure(error));
  }
}
export default function* bookmarkSaga(): Saga {
  yield takeLatest(bookmarkTypes.REQUEST, bookmarkItemSaga);
  yield takeLatest(bookmarkedItemsTypes.REQUEST, getBookmarkedItemsSaga);
  yield takeLatest(getSavedItemsTypes.REQUEST, getSavedItemsSaga);
  yield takeLatest(saveArticleTypes.SAVE_ARTICLE_REQUEST, saveArticleSaga);
  yield takeLatest(removeArticleTypes.REMOVE_ARTICLE_REQUEST, removeArticleSaga);
}
