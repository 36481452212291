// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing },
} = getTheme();
export const LogoLink: StatelessFunctionalComponent<any> = styled.a`
  margin-bottom: ${spacing.x4};
  margin-top: ${spacing.x5};

  & > img {
    width: 4.8rem;
    height: 4.8rem;
  }
`;
export const LinksWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-top: ${({ showLongLogo }) => (showLongLogo ? '75px' : '0')};
`;
