import styled from 'styled-components';

import ImageWithFallback from 'components/ImageWithFallback';

import { CELLPHONE_LARGE_WIDTH } from 'commons/constants/size';

interface MaterialCardContainerProps {
  isHoverable?: boolean;
}

export const MaterialCardContainer = styled.div<MaterialCardContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.greyscale.white};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: 8px;
  overflow: hidden;
  cursor: ${(props) => (props.isHoverable ? 'pointer' : '')};
  height: 100%;

  &:hover {
    color: unset;
    box-shadow: ${({ theme }) => theme.boxShadow.hover};
  }

  &&& {
    &:active {
      box-shadow: ${({ theme }) => theme.boxShadow.active};
    }
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    min-height: auto;
  }
`;

export const ContainerImgMaterialCard = styled.div`
  overflow: hidden;
`;

export const CardTitle = styled.h3`
  ${({ theme }) => theme.noodera.fontTypes.font4};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x5};
`;

export const CardSubtitle = styled.p`
  ${({ theme }) => theme.noodera.fontTypes.font5};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x8};
`;

export const CardActions = styled.div`
  display: flex;
  align-content: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;

  span {
    ${({ theme }) => theme.noodera.fontTypes.font5};
    color: ${({ theme }) => theme.palette.neutral.eighty};
  }
`;

export const ContentContainer = styled.div`
  padding: ${({ theme }) => theme.noodera.spacing.x5};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardTags = styled.div`
  display: inline-flex;
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x5};

  & span {
    margin-right: ${({ theme }) => theme.noodera.spacing.x7};
  }
`;

export const TopRightAction = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.noodera.spacing.x4};
  right: 0;
  margin-right: ${({ theme }) => theme.noodera.spacing.x4};
`;

export const CardImage = styled(ImageWithFallback)`
  width: 100%;
  max-height: 16.8rem;
  min-height: 16.8rem;
  object-fit: cover;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
