// @ts-nocheck
import * as React from 'react';
import { ReactComponent as ExpandMoreIcon } from 'assets/svg/expand_more.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron_right.svg';
import {
  DropdownContainer,
  DropdownContent,
  StyledTitle,
  StyledSummary,
  StyledSummaryContainer,
  TitleContainer,
} from './Dropdown.styles';
import type { DropdownProps } from './Dropdown.types';
// @ts-expect-error
export const Dropdown = React.forwardRef(
  (
    { children, isOpen = true, title, className, onChange = () => {} }: DropdownProps,
    ref,
  ): React.ReactElement<any> => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(isOpen);

    const handleChange = (): void => {
      setIsDropdownOpen((oldIsDropdownOpen) => !oldIsDropdownOpen);

      if (onChange) {
        onChange(!isDropdownOpen);
      }
    };

    React.useEffect(() => {
      setIsDropdownOpen(isOpen);
    }, [isOpen]);

    return (
      <DropdownContainer className={className} ref={ref}>
        <DropdownContent
          onClick={handleChange}
          role='button'
          tabIndex={0}
          onKeyPress={handleChange}
          aria-expanded={isDropdownOpen}
        >
          <StyledTitle>{title}</StyledTitle>
          {isDropdownOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </DropdownContent>
        {isDropdownOpen ? children : null}
      </DropdownContainer>
    );
  },
);

export const DropdownWithSummary = React.forwardRef(
  (
    {
      children,
      isOpen = true,
      title,
      summary = null,
      className,
      onChange = () => {},
    }: DropdownProps,
    ref,
  ): React.ReactElement<any> => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(isOpen);

    const handleChange = (): void => {
      setIsDropdownOpen((oldIsDropdownOpen) => !oldIsDropdownOpen);

      if (onChange) {
        onChange(!isDropdownOpen);
      }
    };

    React.useEffect(() => {
      setIsDropdownOpen(isOpen);
    }, [isOpen]);

    return (
      <DropdownContainer className={className} ref={ref}>
        <DropdownContent
          onClick={handleChange}
          role='button'
          tabIndex={0}
          onKeyPress={handleChange}
          aria-expanded={isDropdownOpen}
        >
          <StyledSummaryContainer>
            <TitleContainer>
              <StyledTitle>{title}</StyledTitle>
              {isDropdownOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </TitleContainer>
            <StyledSummary>{summary}</StyledSummary>
          </StyledSummaryContainer>
        </DropdownContent>
        {isDropdownOpen ? children : null}
      </DropdownContainer>
    );
  },
);
