import * as Sentry from '@sentry/react';
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';

// Still in Use via Public NLP /courses/browse and <GuestHome/>
export default class University {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getUniversities(): Promise<Record<string, any>[]> {
    try {
      const response = await this.jsonApi.get(`/v2/universities`, false);
      const universities = get(response, 'data');
      return universities;
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 20 ~ University ~ getUniversities ~ err', err);
      Sentry.captureException(err);
      throw err;
    }
  }

  async getUniversity(payload: string): Promise<Record<string, any>> {
    try {
      const response = await this.jsonApi.get(`/universities/${payload}`);
      return response;
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 20 ~ University ~ getUniversity ~ err', err);
      Sentry.captureException(err);
      throw err;
    }
  }
}
