import type { Mask } from './String.types';

const getMaskedValue = (masks: Mask[], value: string) => {
  let maskedValue = value;
  masks.forEach((m) => {
    maskedValue = m.maskFunction({ ...m, value: maskedValue });
  });
  return maskedValue;
};

export default getMaskedValue;
