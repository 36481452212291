// Login Types
export const loginTypes = {
  REQUEST: 'LOGIN_REQUEST',
  SUCCESS: 'LOGIN_SUCCESS',
  FAILED: 'LOGIN_FAILED',
  RESET: 'LOGIN_RESET',
};

export const clickOnNavBarTypes = {
  CLICK_HOME: 'NAV_HOME_ICON_CLICK',
  CLICK_SEARCH: 'NAV_SEARCH_ICON_CLICK',
  CLICK_LOGIN: 'NAV_LOGIN_BUTTON_CLICK',
  CLICK_SIGN_UP: 'NAV_SIGN_UP_BUTTON_CLICK',
};
