import type { RoleTypeListObject } from 'commons/types';
import {
  getUserRolesTypes,
  getUsersTypes,
  getUserByIdTypes,
  updateUserRolesTypes,
  updateUserTypes,
} from './types';
import type { GetUsersRequestPayload, GetUsersSuccessPayload } from './AdminUser.types';

export const adminUserActions = {
  getUsersRequest: (payload: GetUsersRequestPayload) => ({
    type: getUsersTypes.REQUEST,
    payload,
  }),
  getUsersSuccess: (payload: GetUsersSuccessPayload) => ({
    type: getUsersTypes.SUCCESS,
    payload,
  }),
  getUsersFailed: (payload: string) => ({
    type: getUsersTypes.FAILED,
    payload,
  }),
  getUserByIdRequest: (payload: string) => ({
    type: getUserByIdTypes.REQUEST,
    payload,
  }),
  getUserByIdSuccess: (payload: any) => ({
    type: getUserByIdTypes.SUCCESS,
    payload,
  }),
  getUserByIdFailed: (payload: string) => ({
    type: getUserByIdTypes.FAILED,
    payload,
  }),
  updateUserRequest: (payload: any) => ({
    type: updateUserTypes.REQUEST,
    payload,
  }),
  updateUserSuccess: (payload: any) => ({
    type: updateUserTypes.SUCCESS,
    payload,
  }),
  updateUserFailed: (payload: string) => ({
    type: updateUserTypes.FAILED,
    payload,
  }),
  getUserRolesRequest: () => ({
    type: getUserRolesTypes.REQUEST,
  }),
  getUserRolesSuccess: (payload: RoleTypeListObject) => ({
    type: getUserRolesTypes.SUCCESS,
    payload,
  }),
  getUserRolesFailed: (payload: string) => ({
    type: getUserRolesTypes.FAILED,
    payload,
  }),
  updateUserRolesRequest: (user: any, roles: RoleTypeListObject) => ({
    type: updateUserRolesTypes.REQUEST,
    payload: {
      user,
      roles,
    },
  }),
  updateUserRolesSuccess: (payload: any) => ({
    type: updateUserRolesTypes.SUCCESS,
    payload,
  }),
  updateUserRolesFailed: (payload: string) => ({
    type: updateUserRolesTypes.FAILED,
    payload,
  }),
};
