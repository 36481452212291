// @ts-nocheck
import { connectionTypes } from './types';

const updateConnections = (state, payload, isReject) => {
  const connectionsCopy = state && state.connections ? [...state.connections] : [];
  const index = connectionsCopy.findIndex((conn) => conn.id.toString() === payload.id.toString());

  if (index >= 0) {
    connectionsCopy[index] = payload;
  } else if (!isReject) {
    connectionsCopy.push(payload);
  }

  return [...connectionsCopy];
};

const userConnectionReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case connectionTypes.SUCCESS: {
      if (payload && payload.length) {
        return { ...state, connections: [...payload] };
      }

      return state;
    }

    case connectionTypes.CONNECTION_SUCCESS: {
      const connections = updateConnections(state, payload);
      return { ...state, connections };
    }

    case connectionTypes.ACCEPT_CONNECTION_SUCCESS:
    case connectionTypes.REJECT_CANCEL_CONNECTION_SUCCESS: {
      const connections = updateConnections(state, payload, true);
      return { ...state, connections };
    }

    default:
      return state;
  }
};

export default userConnectionReducer;
