// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import api from 'lib/Api';
import { universityConstants } from 'commons/constants/university';
import { universityActions } from './actions';
import { getUniversitiesTypes, getUniversityTypes } from './types';

const { DANGER } = alertTypesConstants;
export function* getUniversitiesSaga(): Saga<void> {
  try {
    const response = yield call([api.university, 'getUniversities']);
    yield put(universityActions.getUniversitiesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: universityConstants.getUniversitiesErrorMessage,
        type: DANGER,
      }),
    );
    yield put(universityActions.getUniversitiesFailure(error));
  }
}
export function* getUniversitySaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.university, 'getUniversity'], payload);
    yield put(universityActions.getUniversitySuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: universityConstants.getUniversityErrorMessage,
        type: DANGER,
      }),
    );
    yield put(universityActions.getUniversityFailure(error));
  }
}
export default function* universitySaga(): Saga<void> {
  yield takeLatest(getUniversitiesTypes.REQUEST, getUniversitiesSaga);
  yield takeLatest(getUniversityTypes.REQUEST, getUniversitySaga);
}
