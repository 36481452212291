// @ts-nocheck
import * as React from 'react';
import { withRouter } from 'react-router';
import MetaTags from 'components/MetaTags';
import Text from 'components/uielements/Text';
import LoadingBars from 'components/LoadingBars';
import lightBulbImg from 'assets/images/Lightbulb.png';
import {
  MyRecommendationsLoadingWrapper,
  MyRecommendationsLoadingContainer,
} from './MyRecommendationsLoading.style';
import type { MyRecommendationsLoadingProps } from './MyRecommendationsLoading.types';

const titlesArr = [
  {
    title: 'Building your profile',
    percentage: 5,
  },
  {
    title: 'Matching you to experts',
    percentage: 5,
  },
  {
    title: 'Generating recommendations',
    percentage: 5,
  },
];
const REDIRECT_DELAY_MS = 14000;

const MyRecommendationsLoading = ({
  history,
}: MyRecommendationsLoadingProps): React.ReactElement<any> => {
  const [titles, setTitles] = React.useState(titlesArr);
  const [currentTitleIdx, setCurrentTitleIdx] = React.useState(0);
  React.useEffect(() => {
    setTimeout(() => {
      const newIndex = currentTitleIdx + 1;
      setTitles(
        titles.map((title) => {
          const newTitle = title;
          newTitle.percentage = 100;
          return newTitle;
        }),
      );

      if (titles.length - 1 < newIndex) {
        setCurrentTitleIdx(newIndex);
      }
    }, 1000);
    setTimeout(() => {
      history.push('/');
    }, REDIRECT_DELAY_MS);
    // TODO: This is terrible but works for now. Looks like the original
    // array is getting mutated.
    return () => {
      titlesArr.forEach((title) => {
        // eslint-disable-next-line no-param-reassign
        title.percentage = 5;
      });
    }; // Only needs to run at startup
    // eslint-disable-next-line
  }, []);
  return (
    <MyRecommendationsLoadingWrapper>
      <MetaTags title='Calculating noodlings...' />
      <MyRecommendationsLoadingContainer>
        <img
          className='my-recommendations-loading__img'
          src={lightBulbImg}
          alt='lightbulb'
          loading='lazy'
          width='100%'
          height='100%'
        />
        <Text className='my-recommendations-loading__header' isHeader size={3}>
          Personalized education advice,
        </Text>
        <Text className='my-recommendations-loading__header' isHeader size={3}>
          coming right up...
        </Text>
        <LoadingBars
          titles={titles}
          fontSize={2}
          transitionDuration={REDIRECT_DELAY_MS / titles?.length}
        />
      </MyRecommendationsLoadingContainer>
    </MyRecommendationsLoadingWrapper>
  );
};

export default withRouter(MyRecommendationsLoading);
