import { apiErrorMessages } from 'commons/constants/apiErrorMessages';
import { PARTNER_INSTANCE } from 'commons/constants/partnerInstance';
import { PartnerInstanceMembership } from 'commons/types/partnerInstanceTypes';
import get from 'lodash.get';
import { Member } from '../../commons/types';

type TeamMember = Member & { isUniversityAdmin: boolean; isCorporateAdmin: boolean };

export const getMembersFromResponse = (response: any, isAdmin: boolean) => {
  const possibleUsers = get(response, 'included', {});
  const pendingMembersDTO = isAdmin
    ? get(response, 'data.relationships.pendingJoinRequests.data', {})
    : [];
  const adminId = Number(get(response, 'data.relationships.admin.data.id', ''));
  const pendingMembersIds = pendingMembersDTO.map((user) => Number(user.id));
  const users = possibleUsers.filter((possibleUser) => possibleUser.type === 'user');
  const adminDTO = users.find((user) => Number(user.id) === adminId);
  const members = users.map(({ id, attributes }) => ({
    id,
    ...attributes,
    isAdmin: id === adminId,
    isPending: !!pendingMembersIds?.includes(id),
  }));
  // BE response does not always return admins anymore, so some error handling here.
  const admin = adminDTO
    ? {
        id: adminDTO.id,
        firstName: adminDTO.attributes.firstName,
        lastName: adminDTO.attributes.lastName,
        avatar: adminDTO.attributes.avatar,
      }
    : {};
  return {
    members,
    admin,
  };
};

export const getMembersListFromResponse = (team: any, globalPartner) => {
  const teamMembers = team
    ? team.members.map((member: Member) => {
        const isUniversityAdmin = !!member.roleNames.find(
          (role) => role === 'parent-instance-admin',
        );
        const isCorporateAdmin = !!member.roleNames.find((role) => role === 'instance-admin');

        const teamMember: TeamMember = {
          ...member,
          isCorporateAdmin,
          isUniversityAdmin,
          isHidden: isUniversityAdmin || isCorporateAdmin,
          isAdmin: isUniversityAdmin || isCorporateAdmin || member.isAdmin,
        };

        const memberMask = globalPartner;

        if (isUniversityAdmin) {
          teamMember.firstName = memberMask.parentCompanyName;
          teamMember.lastName = '';
        }

        if (isUniversityAdmin || isCorporateAdmin) {
          if (typeof memberMask.companySmallLogo === 'string') {
            teamMember.avatar = `${memberMask.companySmallLogo}`;
          } else {
            teamMember.avatarElement = `${memberMask.companySmallLogo}`;
            teamMember.avatar = '';
          }
        }

        return teamMember;
      })
    : [];

  const sortUniversityAdmin = (member: TeamMember) => (member.isUniversityAdmin ? -1 : 1);
  const sortCorporateAdmin = (member: TeamMember) => (member.isCorporateAdmin ? -1 : 1);

  const sortAdmin = (member: Member) => (member.isAdmin ? -1 : 1);
  const sortPremium = (member: Member) => (member.isPremium ? -1 : 1);

  return teamMembers
    .sort(sortPremium)
    .sort(sortAdmin)
    .sort(sortCorporateAdmin)
    .sort(sortUniversityAdmin);
};

const checkForNamingError = (errorDetails: any, errorCue: string) => {
  const { first_name: firstName = '', last_name: lastName = '' } = errorDetails;
  return (firstName + lastName).toString().toLowerCase().includes(errorCue);
};

const checkForNumberNamingError = (errors: any) =>
  errors.map((error) => error.detail).find((detail) => checkForNamingError(detail, 'numbers'));

const checkForLengthNamingError = (errors: any) =>
  errors.map((error) => error.detail).find((detail) => checkForNamingError(detail, 'length'));

const checkForExistingMembershipError = (errors: any) => {
  const validationMessage = apiErrorMessages.existingEmailError;
  return errors
    .map((error) => error.detail)
    .find((detail) => typeof detail === 'string' && detail === validationMessage);
};

const checkForInvalidEmailError = (errors: any) => {
  const validationMessage = apiErrorMessages.invalidEmailError;
  return errors
    .map((error) => error.detail)
    .find(
      (detail) => Object.keys(detail).includes('email') && detail.email[0] === validationMessage,
    );
};

const checkMembershipsExceededError = (errors: any) => {
  const validationMessage = apiErrorMessages.noSeatsRemainingError;
  return errors
    .map((error) => error.detail)
    .find((detail) => typeof detail === 'string' && detail.includes(validationMessage));
};

export const getMemberCreationError = (err: any, payload: PartnerInstanceMembership) => {
  const errors = err.response?.data?.errors ?? [];

  if (checkMembershipsExceededError(errors)) {
    return apiErrorMessages.noSeatsRemainingError;
  }

  if (checkForNumberNamingError(errors)) {
    return PARTNER_INSTANCE.numberNamingError;
  }
  if (checkForLengthNamingError(errors)) {
    return PARTNER_INSTANCE.lengthNamingError;
  }
  if (checkForExistingMembershipError(errors)) {
    return PARTNER_INSTANCE.existingMemberError;
  }

  if (checkForInvalidEmailError(errors)) {
    return `"${payload.email}" is not a valid email`;
  }

  return PARTNER_INSTANCE.genericMemberCreationError;
};

export const getSectionCreationUpdateErrorMessage = (err: any) => {
  const validationMessage = apiErrorMessages.sectionNameLengthError;
  const errors = err.response?.data?.errors ?? [];
  if (errors.map((error) => error.detail).find((error) => error === validationMessage)) {
    return PARTNER_INSTANCE.sectionNameLengthErrorMessage;
  }
  return PARTNER_INSTANCE.fillAllFieldsErr;
};

export const getParterCreationErroMessage = (error: any) => {
  if (error?.response?.data?.detail) {
    return error.response?.data?.detail;
  }

  return PARTNER_INSTANCE.fillAllFieldsErr;
};

export const checkForExistingPaymentError = (error: any) => {
  const validationMessage = apiErrorMessages.userAlreadyPaidError;
  let userAlreadyPaid = false;
  const { detail } = error.response?.data;
  if (detail && detail === validationMessage) {
    userAlreadyPaid = true;
  }

  return userAlreadyPaid;
};

export const checkForNumSeatsError = (err: any) => {
  const { error } = err.response?.data;

  return error === PARTNER_INSTANCE.negativeSeatsError;
};
