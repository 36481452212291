import { landingPagesErrorMessages } from 'commons/constants';

const { INVALID_CRITERIA_OBJECT } = landingPagesErrorMessages;
const validationError = Error(INVALID_CRITERIA_OBJECT);

const validateCriteriaFields = (fields: string[]) => {
  const validKeys = ['name', 'op', 'val'];

  if (fields.length !== validKeys.length) {
    throw validationError;
  }

  fields.forEach((k) => {
    if (!validKeys.includes(k)) {
      throw validationError;
    }
  });
};

const validateCriteriaFormat = (criteria: string) => {
  try {
    const criteriaArray = JSON.parse(criteria);
    const OR_KEY = 'or';
    criteriaArray.forEach((e) => {
      const keys = Object.keys(e);

      if (keys.includes(OR_KEY)) {
        e[OR_KEY].forEach((element) => validateCriteriaFields(Object.keys(element)));
      } else {
        validateCriteriaFields(keys);
      }
    });
  } catch (error) {
    throw validationError;
  }
};

export default validateCriteriaFormat;
