export enum CourseWaitlistOperationEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  StayOnList = 'STAY_ON_LIST',
}

export interface AddOrRemoveUsersInterface {
  userId: number;
  addOrRemoveUser: CourseWaitlistOperationEnum;
}

export interface UpdateWaitlistInterface {
  courseContentfulId: number;
  users: AddOrRemoveUsersInterface[];
}

export interface CourseWaitlistRecord {
  partnerId: string;
  courseContentfulId: string;
  userId: number;
  waitlistPosition: number;
  notifiedToEnrollAt: string;
}

export interface CourseWaitlistInterface {
  waitlist: CourseWaitlistRecord[];
  waitlistTotal: number;
}

export interface UsersCourseWaitlistsInterface {
  waitlists: CourseWaitlistRecord[];
  waitlistTotal: number;
}
