// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';

const {
  palette: { alphas },
  noodera: { spacing },
} = getTheme();
export const Element = styled.div`
  width: ${({ elWidth = '100%' }) => elWidth};
  height: ${({ elHeight = '2rem' }) => elHeight};
  background: ${({ background = alphas.black5 }) => background};
  border-radius: ${({ circle }) => (circle ? '50%' : '')};
  margin-bottom: ${({ noMargin }) => (noMargin ? '' : spacing.x8)};
`;
export const BaseCard = styled.div`
  box-shadow: 0 0 0 2px ${alphas.black5};
  border-radius: ${spacing.x7};
`;
export const Card = styled(BaseCard)`
  .skeleton__lines-wrap {
    padding: ${spacing.x4};
  }
`;
export const MiniCard = styled(BaseCard)`
  display: flex;
  .skeleton__lines-wrap {
    width: 80%;
    padding: ${spacing.x6} ${spacing.x6};
  }
`;
export const Post = styled(BaseCard)`
  padding: ${spacing.x5} ${spacing.x4};
  > div:last-child {
    margin-top: ${spacing.x4};
  }
`;
export const DashboardCard = styled(BaseCard)`
  max-width: 278px;
  padding: ${spacing.x3} ${spacing.x5} ${spacing.x5};
  .skeleton__btn {
    margin-top: ${spacing.x3};
  }
`;
export const EmptyCard = styled(BaseCard)`
  padding: ${spacing.x4} 0;
`;
export const User = styled.div`
  display: flex;
  margin-bottom: ${spacing.x5};
  > div:first-child {
    margin-right: ${spacing.x6};
  }
`;
export const Block = styled.div`
  margin-top: ${spacing.x5};
`;
