export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * @description
 * Takes binary data or text and downloads it for the user
 * @param fileData: raw data, binary data, text
 * @param filename: name with which the file gets downloaded to the user's device
 */
export const downloadFile = (fileData: any, filename?: string): void => {
  const url = window.URL.createObjectURL(new Blob([fileData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  (document as any).body.appendChild(link);
  link.click();
};

/**
 * @description
 * Takes binary data or text and downloads it for the user
 * @param faviconUrl: URL of the favicon to be set
 */
export const setFavicon = (faviconUrl: string): void => {
  let link: any = document.querySelector("link[rel~='icon']");
  let appleLink: any = document.querySelector("link[rel~='apple-touch-icon']");

  if (!link) {
    link = document.createElement('link');
    link.rel = 'shortcut icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  if (!appleLink) {
    appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(appleLink);
  }

  if (faviconUrl) {
    link.href = faviconUrl;
    appleLink.href = faviconUrl;
  } else {
    link.href = `${process.env?.PUBLIC_URL}/favicon.svg`;
    appleLink.href = `${process.env?.PUBLIC_URL}/favicon.svg`;
  }
};
