// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import type { Saga } from 'redux-saga';
import { getNoodlingByIdTypes } from './types';
import { noodlingActions } from './actions';

export function* getNoodlingByIdSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const noodling = yield call([api.noodling, 'getById'], payload);
    yield put(noodlingActions.getNoodlingByIdSuccess(noodling));
  } catch (error) {
    yield put(noodlingActions.getNoodlingByIdFailed(error));
    yield put(push('/404'));
  }
}
export default function* noodlingSaga(): Saga<void> {
  yield takeLatest(getNoodlingByIdTypes.REQUEST, getNoodlingByIdSaga);
}
