// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme';
import { TABLET_SMALLER_WIDTH } from 'commons/constants';

const {
  noodera: { fontTypes, spacing },
  palette: { red, neutral },
} = getTheme();

export const Container: StatelessFunctionalComponent<any> = styled.div`
  margin: 0 ${spacing.x7};
  position: relative;

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    svg {
      max-width: 2rem !important;
      max-height: 2rem !important;
    }
  }
`;

export const Badge: StatelessFunctionalComponent<any> = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1px;
  margin-top: -1px;
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  padding: 0 ${spacing.x8} 0 ${spacing.x8};
  text-align: center;
  background: ${red.flushMahogany};
  color: ${neutral.eighty};
  ${fontTypes.font6MediumCaps};
`;
