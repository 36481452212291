// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import type { UserProfile } from 'commons/types';
import { usersActions } from './actions';
import { otherUserTypes, profileUpdateTypes, getUserDetailsTypes, getOwnUserTypes } from './types';

export function* updateProfileSaga({
  payload,
}: {
  payload: {
    profile: UserProfile;
    id: string;
  };
}): Saga {
  try {
    const updatedProfile = yield call([api.user, 'updateProfile'], payload);
    yield put(usersActions.updateProfileSuccess(updatedProfile));
  } catch (err) {
    yield put(usersActions.updateProfileFailed());
  }
}

export function* getOtherUserSaga({ payload }: { payload: string }): Saga {
  try {
    const user = yield call([api.user, 'getById'], payload);
    yield put(usersActions.getOtherUserSuccess(user));
  } catch (error) {
    yield put(usersActions.getOtherUserFailed(error));
    yield put(push('/404'));
  }
}

export function* getOwnUserSaga(): Saga {
  try {
    const user = yield call([api.user, 'getOwnUser']);
    yield put(usersActions.getOwnUserSuccess({ user }));
  } catch (error) {
    yield put(usersActions.getOwnUserFailure(error));
  }
}

export function* getUserSectionsSaga({
  payload: { partnerId, userId },
}: {
  payload: {
    partnerId: string;
    userId: string | number;
  };
}): Saga {
  try {
    const response = yield call([api.user, 'getUserDetails'], {
      partnerId,
      userId,
    });
    yield put(usersActions.getUserDetailsSuccess(response));
  } catch (error) {
    yield put(usersActions.getUserDetailsFailure(error));
  }
}
export default function* userSaga(): Iterable<any> {
  yield takeEvery(profileUpdateTypes.REQUEST, updateProfileSaga);
  yield takeEvery(otherUserTypes.REQUEST, getOtherUserSaga);
  yield takeEvery(getOwnUserTypes.REQUEST, getOwnUserSaga);
  yield takeEvery(getUserDetailsTypes.REQUEST, getUserSectionsSaga);
}
