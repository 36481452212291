// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import { Link } from 'react-router-dom';

export const NavItemText: StatelessFunctionalComponent<any> = styled.span`
  ${(props) => props.theme.noodera.fontTypes.font6};

  ${(props) => props.selected && `color: ${props.theme.palette.primary.hundred}`}
`;
export const NavItemButton: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
`;
export const MobileNavLinkContainer: StatelessFunctionalComponent<any> = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: ${(props) => props.theme.noodera.spacing.x6} 0;
  color: ${(props) => props.theme.palette.neutral.eighty};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.primary.hundred};

    svg path {
      fill: ${(props) => props.theme.palette.primary.hundred};
    }
  }

  &:focus {
    outline: unset;
    background-color: ${(props) => props.theme.palette.primary.ten};
  }

  ${(props) =>
    props.selected &&
    `color: ${props.theme.palette.primary.hundred};

    &::before {
      content: "";
      border-top: 2px solid ${props.theme.palette.primary.hundred};
      width: 3.333rem;
      position: absolute;
      top: 0;
    }
  `}
`;
export const MobileItemName: StatelessFunctionalComponent<any> = styled.span`
  ${(props) => props.theme.noodera.fontTypes.font6};
  margin: 0;
`;
export const NavIconWrapper: StatelessFunctionalComponent<any> = styled(SvgImageWrapper)`
  width: 3.2rem;
  height: 3.2rem;
`;
