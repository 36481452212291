// @ts-nocheck
import * as Sentry from '@sentry/react';
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';

export default class Payment {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getStripeClientSecret(payload: string): Promise<string> {
    try {
      const response = await this.jsonApi.post(`/payment-intent`, payload, false);
      const clientSecret = get(response, 'clientSecret');
      return clientSecret;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  }

  async getStripeClientSecretByCourse(payload: string): Promise<string> {
    try {
      const response = await this.jsonApi.post(`/payment-intent/course/${payload}`, null, false);
      const clientSecret = get(response, 'clientSecret');
      return clientSecret;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  }
}
