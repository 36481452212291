import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import * as Sentry from '@sentry/react';
import { getMembersFromResponse } from 'utils/apiHelpers';
import type {
  CreateSectionPayload,
  Section as SectionType,
  SectionPreviewPayload,
  SectionUserFlags,
} from 'commons/types/sectionTypes';
import { getAppSettings } from 'utils/privateLabel/index';
import { LMS } from 'commons/types/courseTypes';

export default class Section {
  jsonApi: JsonApi;

  entity = 'entities.section';

  taEntity = 'entities.teacherAssistant';

  basePath = '/sections';

  userPath = '/users';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getMySections(): Promise<SectionType[]> {
    const response = await this.jsonApi.get(`${this.basePath}?my_sections=1`);
    const sectionsObj = get(response, this.entity, {});
    return (Object.values(sectionsObj) as any) || [];
  }

  async getSectionPreview(payload: SectionPreviewPayload): Promise<SectionType> {
    const adminResponse = await this.jsonApi.get(
      `${this.basePath}/${payload.sectionId}?fields[section]=user_id`,
    );
    const adminId = get(adminResponse, `entities.section[${payload.sectionId}].userId`, -1);
    const userIsAdmin = adminId === Number(payload.userId);
    const pendingMembersPath = userIsAdmin
      ? ',pending_join_requests,pending_internal_invites,pending_external_invites'
      : '';
    const response = await this.jsonApi.get(
      `${this.basePath}/${payload.sectionId}?include=admin,accepted_members${pendingMembersPath}`,
      false,
    );
    const section = get(response, 'data.attributes', {});
    const userFlags: SectionUserFlags = get(response, 'data.userFlags', {});
    const userSpareTaSessionTransactions = get(response, 'data.userSpareTaSessionTransactions');
    return {
      id: Number(payload.sectionId),
      ...section,
      ...getMembersFromResponse(response, userIsAdmin),
      userFlags,
      userSpareTaSessionTransactions,
    };
  }

  async createSection(payload: CreateSectionPayload): Promise<any> {
    try {
      const appSettings = getAppSettings();
      const { partnerId } = getAppSettings() || payload;
      if (!appSettings) {
        delete payload.partnerId;
      }
      const apiPayload = {
        attributes: { ...payload, partner_id: partnerId },
        type: 'section',
      };
      const response = await this.jsonApi.post(`${this.basePath}`, apiPayload, false);
      return Object.values(get(response, 'entities.section', {}))[0];
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 83 ~ Team ~ createTeam ~ err', err);
      Sentry.captureException(err);
      throw err;
    }
  }

  async getSections(payload?: any): Promise<any> {
    const queryParams = new URLSearchParams();

    if (payload) {
      const filters = [];

      if (payload.date) {
        const formattedDate = new Date(payload.date).toISOString();
        filters.push({
          name: 'start_date',
          op: 'ge',
          val: formattedDate,
        });
        filters.push({
          name: 'public',
          op: 'eq',
          val: true,
        });
      }

      if (payload.lms === LMS.Moodle) {
        filters.push({
          name: 'moodle_course_id',
          op: 'eq',
          val: payload.moodleCourseId,
        });
      } else {
        filters.push({
          name: 'brightspace_org_unit_id',
          op: 'eq',
          val: payload.unitId,
        });
      }

      queryParams.append('filter', JSON.stringify(filters));
      queryParams.append('include', 'admin');

      if (payload.numItems) {
        queryParams.append('page[size]', payload.numItems);
      }
    }
    if (!queryParams.has('page[size]')) {
      queryParams.set('page[size]', '0');
    }
    const response = await this.jsonApi.get(`${this.basePath}?${queryParams.toString()}`);
    const responseSectionObj = get(response, 'entities.section', {});
    const sections = (Object.values(responseSectionObj) as any) || [];
    const responseUserObj = get(response, 'entities.user', {});
    const users = (Object.values(responseUserObj) as any) || [];
    return sections.map((section) => {
      const userdata = users.find((user) => user.id === section.userId);
      return { ...section, admin: { ...userdata } };
    });
  }

  async joinSection(payload: any): Promise<any> {
    const { selectedSection, userId } = payload;
    const data = {
      type: 'user',
      id: userId,
    };
    const response = await this.jsonApi.post(
      `${this.basePath}/${selectedSection?.id}/relationships/members`,
      data,
      false,
    );
    return Object.values(get(response, '', {}));
  }
}
