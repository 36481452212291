// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  palette: { greyscale, neutral },
} = getTheme();
export const MobileNavbarContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  background: ${greyscale.white};
  box-shadow: 0px -1px 0px ${neutral.twenty};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 20;
`;
