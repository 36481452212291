import * as React from 'react';

export interface ImageWithFallbackProps {
  alt?: string;
  altFallback?: string;
  src: string;
  srcFallback?: string;
  className?: string;
  width?: string;
  height?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  alt,
  altFallback,
  src,
  srcFallback,
  className,
  width,
  height,
}) => {
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [imageAlt, setImageAlt] = React.useState<string | null>(null);

  React.useEffect(() => {
    const currentSrc = src || srcFallback;
    const currentAlt = src ? alt : altFallback;
    setImageSrc(currentSrc);
    setImageAlt(currentAlt);
  }, [src, srcFallback, alt, altFallback]);

  const handleError = () => {
    if (imageSrc !== srcFallback) {
      setImageSrc(srcFallback || '');
      setImageAlt(altFallback || '');
    } else {
      console.error('Fallback image is broken');
    }
  };

  return (
    <img
      className={className}
      alt={imageAlt || ''}
      src={imageSrc}
      onError={handleError}
      loading='lazy'
      width={width}
      height={height}
    />
  );
};

export default ImageWithFallback;
