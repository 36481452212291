export const courseTypes = {
  SELECT_COURSE: 'SELECT_COURSE',
};
export const getCoursePreviewTypes = {
  GET_COURSE_PREVIEW_REQUEST: 'GET_COURSE_PREVIEW_REQUEST',
  GET_COURSE_PREVIEW_SUCCESS: 'GET_COURSE_PREVIEW_SUCCESS',
  GET_COURSE_PREVIEW_FAILURE: 'GET_COURSE_PREVIEW_FAILURE',
};
export const unsaveCourseTypes = {
  UNSAVE_COURSE_REQUEST: 'UNSAVE_COURSE_REQUEST',
  UNSAVE_COURSE_SUCCESS: 'UNSAVE_COURSE_SUCCESS',
  UNSAVE_COURSE_FAILURE: 'UNSAVE_COURSE_FAILURE',
};
export const enrollTypes = {
  ENROLL_MODAL_REQUEST: 'ENROLL_MODAL_REQUEST',
  ENROLL_MODAL_SUCCESS: 'ENROLL_MODAL_SUCCESS',
  ENROLL_MODAL_FAILURE: 'ENROLL_MODAL_FAILURE',
  ENROLL_MODAL_RESET: 'ENROLL_MODAL_RESET',
};
export const getMyCoursesTypes = {
  GET_MY_COURSES_REQUEST: 'GET_MY_COURSES_REQUEST',
  GET_MY_COURSES_SUCCESS: 'GET_MY_COURSES_SUCCESS',
  GET_MY_COURSES_FAILURE: 'GET_MY_COURSES_FAILURE',
};
export const getCoursesTypes = {
  GET_COURSES_REQUEST: 'GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS: 'GET_COURSES_SUCCESS',
  GET_COURSES_FAILURE: 'GET_COURSES_FAILURE',
};
export const getSavedCoursesTypes = {
  GET_SAVED_COURSES_REQUEST: 'GET_SAVED_COURSES_REQUEST',
  GET_SAVED_COURSES_SUCCESS: 'GET_SAVED_COURSES_SUCCESS',
  GET_SAVED_COURSES_FAILURE: 'GET_SAVED_COURSES_FAILURE',
};
export const getEnrolledSectionsTypes = {
  GET_ENROLLED_SECTIONS_REQUEST: 'GET_ENROLLED_SECTIONS_REQUEST',
  GET_ENROLLED_SECTIONS_SUCCESS: 'GET_ENROLLED_SECTIONS_SUCCESS',
  GET_ENROLLED_SECTIONS_FAILURE: 'GET_ENROLLED_SECTIONS_FAILURE',
};
export const getCertificateTypes = {
  GET_CERTIFICATE_REQUEST: 'GET_CERTIFICATE_REQUEST',
  GET_CERTIFICATE_SUCCESS: 'GET_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_FAILURE: 'GET_CERTIFICATE_FAILURE',
  DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE',
};
