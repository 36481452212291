// @ts-nocheck
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import InputChips from 'components/InputChips';
import { Input } from 'components/InputChips/InputChips.styles';
import { inviteMembersLabels } from 'commons/constants/createTeam';
import { NO_SPACES } from 'commons/constants/regex';
import { addStringToArrayNoDuplicates } from 'utils/arrayHelpers';
import isValidEmail from 'utils/validation/is-valid-email';
import type { InviteMembersModalProps } from './InviteMembersInput.types';

const InviteMembersInput = ({
  className,
  data,
  placeholder,
  onChange,
}: InviteMembersModalProps): React.ReactElement<any> => {
  const [item, setItem] = React.useState('');
  const actionComponentRef = React.useRef(null);

  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.replace(NO_SPACES, '');
    setItem(value);
  };

  const handleDelete = (itemToDelete: string) => {
    const filteredItems = data.filter((current) => itemToDelete !== current);
    onChange(filteredItems);
  };

  const handleSpaceOrEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const spacebar = 32;
    const mobileSpacebar = 229;

    if (
      (event.which === spacebar || event.which === mobileSpacebar || event.key === 'Enter') &&
      isValidEmail(item)
    ) {
      const noDuplicatedEmails = addStringToArrayNoDuplicates(data, item);
      setItem('');
      onChange(noDuplicatedEmails);
    }
  };

  return (
    <InputChips
      className={className}
      actionComponentRef={actionComponentRef}
      placeholder={placeholder || inviteMembersLabels.inputPlaceholder}
      items={data ?? []}
      onDelete={handleDelete}
      actionComponent={
        <label htmlFor='mailid'>
          <Input
            id='mailid'
            ref={actionComponentRef}
            aria-label='invite-email-input'
            value={item}
            onChange={handleChange}
            onKeyDown={handleSpaceOrEnter}
          />
        </label>
      }
    />
  );
};

export default InviteMembersInput;
