import type { SendCalendarSessionParams } from 'commons/types';
import { sendCalendarSessionTypes } from './types';

export const admingGeneralActions = {
  sendCalendarSessionRequest: (payload: SendCalendarSessionParams) => ({
    type: sendCalendarSessionTypes.REQUEST,
    payload,
  }),
  sendCalendarSessionSuccess: () => ({
    type: sendCalendarSessionTypes.SUCCESS,
  }),
  sendCalendarSessionFailed: (payload: string) => ({
    type: sendCalendarSessionTypes.FAILED,
    payload,
  }),
};
