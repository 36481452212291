// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';

export const MyRecommendationsLoadingWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6.4rem);
  width: 100%;
`;
export const MyRecommendationsLoadingContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50rem;
  padding: 3.2rem;

  .my-recommendations-loading {
    &__img {
      width: 12.8rem;
      height: 12.8rem;
      margin-bottom: 3.2rem;
    }

    &__header {
      line-height: 2.4rem;
      white-space: nowrap;
    }
  }
`;
