import { all } from 'redux-saga/effects';
import adminGeneralSaga from './adminGeneral/saga';
import adminNoodlingSaga from './adminNoodling/saga';
import adminUserSaga from './adminUser/saga';
import adminSchedulerSaga from './adminScheduler/saga';
import adminPartnerProgramSaga from './adminPartnerProgram/saga';
import authSaga from './auth/saga';
import bookmarkSaga from './bookmarks/saga';
import counselorSaga from './counselor/saga';
import exploreSaga from './explore/saga';
import likeSaga from './like/saga';
import globalSearchSaga from './globalSearch/saga';
import getStreamSaga from './getStream/saga';
import groupSaga from './groups/saga';
import landingPagesSaga from './landingPages/saga';
import noodlingSaga from './noodling/saga';
import partnerProgramsSaga from './partnerPrograms/saga';
import paymentSaga from './payment/saga';
import partnerInstanceSaga from './partnerInstance/saga';
import schedulerSaga from './scheduler/saga';
import universitySaga from './university/saga';
import userConnectionSaga from './userConnection/saga';
import userSaga from './user/saga';
import teamSaga from './team/saga';
import sectionSaga from './section/saga';
import surveySaga from './survey/saga';
import coursesSaga from './courses/saga';
import teacherAssistantSaga from './teacherAssistant/saga';
import productsSaga from './product/saga';
import onboardingSaga from './onboarding/saga';

const rootSaga = function* rootSaga() {
  yield all([
    adminGeneralSaga(),
    adminNoodlingSaga(),
    adminUserSaga(),
    adminSchedulerSaga(),
    adminPartnerProgramSaga(),
    authSaga(),
    bookmarkSaga(),
    counselorSaga(),
    coursesSaga(),
    exploreSaga(),
    getStreamSaga(),
    globalSearchSaga(),
    groupSaga(),
    landingPagesSaga(),
    likeSaga(),
    noodlingSaga(),
    onboardingSaga(),
    partnerProgramsSaga(),
    paymentSaga(),
    partnerInstanceSaga(),
    productsSaga(),
    schedulerSaga(),
    sectionSaga(),
    surveySaga(),
    teacherAssistantSaga(),
    teamSaga(),
    universitySaga(),
    userConnectionSaga(),
    userSaga(),
  ]);
};

export default rootSaga;
