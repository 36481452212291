import {
  adminSchedulerTypes,
  createTimekitProjectTypes,
  getTimekitProjectListTypes,
  getTimekitProjectTypes,
  updateTimekitProjectTypes,
} from './types';

const initialState = {
  isLoading: false,
  errorMessage: '',
};

const AdminSchedulerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getTimekitProjectListTypes.REQUEST: {
      return {
        ...state,
        projects: {
          isLoading: true,
        },
      };
    }

    case getTimekitProjectListTypes.SUCCESS: {
      return {
        ...state,
        projects: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getTimekitProjectListTypes.FAILED: {
      return {
        ...state,
        projects: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case getTimekitProjectTypes.REQUEST: {
      return {
        ...state,
        project: {
          isLoading: true,
        },
        createMode: false,
      };
    }

    case getTimekitProjectTypes.SUCCESS: {
      return {
        ...state,
        project: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getTimekitProjectTypes.FAILED: {
      return {
        ...state,
        project: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case updateTimekitProjectTypes.REQUEST: {
      return {
        ...state,
        project: {
          isLoading: true,
        },
      };
    }

    case updateTimekitProjectTypes.SUCCESS: {
      return {
        ...state,
        project: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case updateTimekitProjectTypes.FAILED: {
      return {
        ...state,
        project: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case createTimekitProjectTypes.SUCCESS: {
      return { ...state, editMode: false, isLoading: false };
    }

    case adminSchedulerTypes.CHANGE_EDIT_MODE: {
      return { ...state, editMode: payload, createMode: false };
    }

    case adminSchedulerTypes.SET_UP_CREATE: {
      return { ...state, editMode: true, isLoading: false, createMode: true };
    }

    default:
      return state;
  }
};

export default AdminSchedulerReducer;
