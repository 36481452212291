// @ts-nocheck
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';
import type { StatelessFunctionalComponent } from 'react';

export const getNooderaButtonSize = (theme: any, size = 'default', isDisabled = false) => {
  switch (size) {
    case 'small':
      return `
        ${isDisabled ? theme.noodera.fontTypes.font4 : theme.noodera.fontTypes.font4Medium};
        padding: ${theme.noodera.spacing.x10} ${theme.noodera.spacing.x6};
        height: 2.4rem;

        display: flex;
        justify-content: center;
        align-items: center;
      `;

    case 'medium':
      return `
        ${isDisabled ? theme.noodera.fontTypes.font4 : theme.noodera.fontTypes.font4Medium};
        padding: ${theme.noodera.spacing.x8} ${theme.noodera.spacing.x5};
        height: 3.2rem;
      `;

    default:
      return `
        ${isDisabled ? theme.noodera.fontTypes.font4 : theme.noodera.fontTypes.font4Medium};
        padding: ${theme.noodera.spacing.x6} ${theme.noodera.spacing.x5};
        height: 4.8rem;
      `;
  }
};
export const getNooderaButtonRoundSize = (theme: any, size = 'default'): string => {
  switch (size) {
    case 'small':
      return `
        width: 2.4rem;
        height: 2.4rem;
        padding: ${theme.noodera.spacing.x8};
        border-radius: 12px;

        & > svg {
          font-size: 1.6rem;        
        }
      `;

    case 'medium':
      return `
        width: 3.2rem;
        height: 3.2rem;
        padding: ${theme.noodera.spacing.x7};
        border-radius: 16px;

        & > svg {
          font-size: 1.6rem;     
        }
      `;

    default:
      return `
        width: 4.8rem;
        height: 4.8rem;
        padding: ${theme.noodera.spacing.x6};
        border-radius: 28px;

        & > svg {
          font-size: 2.4rem;        
        }
      `;
  }
};

const getButtonCss = (isDisabled: boolean, theme: any, isActive?: boolean): string =>
  isDisabled
    ? `
      background: ${theme.palette.neutral.five};
      border: 1px solid ${theme.palette.neutral.forty}; 
      color: ${theme.palette.neutral.eighty};
      cursor: not-allowed;
      pointer-events: none;
      line-height: normal;
      
      svg path {
        fill: ${theme.palette.neutral.eighty};
      }
    `
    : `
      background: ${theme.palette.greyscale.white};
      border: 1px solid ${theme.palette.primary.hundred};
      color: ${theme.palette.primary.hundred};
  
      &:hover {
        background: ${theme.palette.primary.ten};
      }
  
      &:focus {
        background: ${theme.palette.primary.ten};
        box-shadow: ${theme.boxShadow.focus};
      }

      &${isActive ? `&&` : `:active `} {
      background: ${theme.palette.primary.twenty};
      border-color: ${theme.palette.primary.hundredTwenty};
      color: ${theme.palette.primary.hundredTwenty};
      box-shadow: unset !important; //to override bootstrap/scss/mixins/_buttons.scss
    }
  
    `;

const getActiveCss = (isActive: boolean, theme: any): string | null =>
  isActive
    ? `
      background: ${theme.palette.primary.twenty};
      border: 1px solid ${theme.palette.primary.hundredTwenty}; 
      color: ${theme.palette.primary.hundredTwenty};
      font-weight: 600;
  `
    : null;

const getCtaCss = (isDisabled: boolean, theme: any): string =>
  isDisabled
    ? `
        background: ${theme.palette.neutral.twenty}; 
        color: ${theme.palette.neutral.eighty};
        cursor: not-allowed;
        pointer-events: none;
        font-weight: 400;
        line-height: normal;
            
        svg path {
          fill: ${theme.palette.neutral.eighty};
        }
      `
    : `
        border: none;
        background: linear-gradient(90deg, ${theme.palette.primary.hundred} 0%,
           ${theme.isCustomTheme ? theme.palette.primary.hundred : '#367d5d'} 100%);
        color: ${theme.palette.greyscale.white};
            
        svg path {
          fill: ${theme.palette.greyscale.white};
        }

        svg path {
          fill: ${theme.palette.greyscale.white};
        }

        &:hover { 
          background: ${theme.palette.secondary.hundred};
        }

        &:focus { 
          background: ${theme.palette.secondary.hundred};
          box-shadow: ${theme.boxShadow.focus};
        }
        
        &:active { 
          background: ${theme.palette.primary.hundred};
          box-shadow: unset !important; //to override bootstrap/scss/mixins/_buttons.scss
        }
    `;

const getRoundDarkCss = (isDisabled: boolean, theme: any): string =>
  isDisabled
    ? `
        background: ${theme.palette.neutral.twenty}; 
        color: ${theme.palette.neutral.sixty};
        border: 1px solid ${theme.palette.neutral.forty};
        cursor: not-allowed;
        pointer-events: none;
        font-weight: 400;
        line-height: normal;
            
        svg path {
          fill: ${theme.palette.neutral.sixty};
        }
      `
    : `
        background: ${theme.palette.alphas.black60};
        border: 1px solid ${theme.palette.greyscale.white};
        color: ${theme.palette.greyscale.white};

        &:hover { 
          border: 1px solid ${theme.palette.primary.forty};
        }

        &:focus { 
          box-shadow: ${theme.boxShadow.focus};
        }
        
        &:active { 
          background: ${theme.palette.alphas.black20};
          box-shadow: unset !important; //to override bootstrap/scss/mixins/_buttons.scss
            
          svg path {
            fill: ${theme.palette.primary.twenty};
          }
        }
    `;

export const IconForButton: StatelessFunctionalComponent<any> = styled.span`
  margin-right: ${(props) => props.theme.noodera.spacing.x6};
  line-height: normal;
  vertical-align: top;
`;
const TextButton: StatelessFunctionalComponent<any> = styled(Button)`
  &&& {
    ${(props) => getNooderaButtonSize(props.theme, props.size, props.isDisabled)};
    ${(props) => getActiveCss(props.isActive, props.theme)};
    ${(props) => props.isFullWidth && `width: 100%;`}
    border-radius: 12px;
    transition: none;
    border: none;
  }
`;
export const StyledButton: StatelessFunctionalComponent<any> = styled(TextButton)`
  &&& {
    ${(props) => getButtonCss(props.isDisabled, props.theme, props.isActive)};
  }
`;
export const TextLinkButton: StatelessFunctionalComponent<any> = styled(Button)`
  &&& {
    color: ${({ theme }) => theme.palette.primary.hundred};
    ${({ theme }) => theme.noodera.fontTypes.font5Medium};
    ${(props) => props.theme.underlineLink};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.primary.forty};
    border: none;
    border-radius: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;
export const RejectButton: StatelessFunctionalComponent<any> = styled(StyledButton)`
  &&& {
    color: ${({ theme }) => theme.palette.warning.hundred};
    border: 1px solid ${({ theme }) => theme.palette.warning.hundred};
  }
`;
export const StyledCTA: StatelessFunctionalComponent<any> = styled(TextButton)`
  &&& {
    ${(props) => getCtaCss(props.isDisabled, props.theme)};
  }
`;
const RoundButton: StatelessFunctionalComponent<any> = styled(Button)`
  &&& {
    ${(props) => getNooderaButtonRoundSize(props.theme, props.size)};
    ${(props) => getActiveCss(props.isActive, props.theme)};
    transition: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      ${(props) => props.darkBackground && 'background: initial;'};
      ${(props) =>
        props.darkBackground && `border: 1px solid ${props.theme.palette.greyscale.white}`};
    }
  }
`;
export const StyledRound: StatelessFunctionalComponent<any> = styled(RoundButton)`
  &&& {
    ${(props) => getButtonCss(props.isDisabled, props.theme)};
  }
`;
export const StyledRoundCTA: StatelessFunctionalComponent<any> = styled(RoundButton)`
  &&& {
    ${(props) => getCtaCss(props.isDisabled, props.theme)};
  }
`;
export const StyledRoundCTADark: StatelessFunctionalComponent<any> = styled(RoundButton)`
  &&& {
    ${(props) => getRoundDarkCss(props.isDisabled, props.theme)};
  }
`;
