// @ts-nocheck
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import type { Saga } from 'redux-saga';
import { alertTypesConstants } from 'commons/constants/index';
import {
  createBookingTypes,
  getAoISchedulerTypes,
  getSchedulerAvailabilityTypes,
  getSchedulerListTypes,
  getSchedulerTypes,
  meetingTypes,
} from './types';
import { schedulerActions } from './actions';

const { DANGER } = alertTypesConstants;
export function* getSchedulerSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'getScheduler'], payload);
    yield put(schedulerActions.getSchedulerSuccess(response));
  } catch (error) {
    yield put(schedulerActions.getSchedulerFailed(error));
  }
}
export function* getAoISchedulerSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'getAoIScheduler'], payload);
    yield put(schedulerActions.getAoISchedulerSuccess(response));
  } catch (error) {
    yield put(schedulerActions.getAoISchedulerFailed(error));
    yield put(push('/404'));
  }
}
export function* getSchedulerListSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'getSchedulerList'], payload);
    yield put(schedulerActions.getSchedulerListSuccess(response));
  } catch (error) {
    yield put(schedulerActions.getSchedulerListFailed(error));
  }
}
export function* getSchedulerAvailabilitySaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'getSchedulerAvailability'], payload);
    yield put(schedulerActions.getSchedulerAvailabilitySuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error querying the calendar!',
        type: DANGER,
      }),
    );
    yield put(schedulerActions.getSchedulerAvailabilityFailed(error));
  }
}
export function* createBookingSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'createBooking'], payload);
    // Tracking, keeping it by Jermaine's request
    const meetingBookedCustomEvent = new CustomEvent('meeting-booked', {
      bubbles: true,
    });
    document.dispatchEvent(meetingBookedCustomEvent);
    yield put(schedulerActions.createBookingSuccess(response));
    yield put(schedulerActions.createBookingReset());
    const selectedSectionId = yield select((state) => state.section?.selectedSection?.id);

    if (selectedSectionId) {
      yield put(schedulerActions.getMeetings(selectedSectionId));
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error booking your meeting!',
        type: DANGER,
      }),
    );
    yield put(schedulerActions.createBookingFailure(error));
  }
}
export function* getMeetingsSaga({ payload }: { payload: number }): Saga<void> {
  try {
    const response = yield call([api.scheduler, 'getMeetings'], payload);
    yield put(schedulerActions.getMeetingsSuccess(response.data));
  } catch (error) {
    yield put(schedulerActions.getMeetingsFailed(error));
  }
}
export default function* schedulerSaga(): Saga<void> {
  yield takeLatest(getSchedulerTypes.REQUEST, getSchedulerSaga);
  yield takeLatest(getAoISchedulerTypes.REQUEST, getAoISchedulerSaga);
  yield takeLatest(getSchedulerListTypes.REQUEST, getSchedulerListSaga);
  yield takeLatest(getSchedulerAvailabilityTypes.REQUEST, getSchedulerAvailabilitySaga);
  yield takeLatest(createBookingTypes.REQUEST, createBookingSaga);
  yield takeLatest(meetingTypes.GET_MEETING_REQUEST, getMeetingsSaga);
}
