export const getOnboardingOccupationTypes = {
  GET_ONBOARDING_OCCUPATION_REQUEST: 'GET_ONBOARDING_OCCUPATION_REQUEST',
  GET_ONBOARDING_OCCUPATION_SUCCESS: 'GET_ONBOARDING_OCCUPATION_SUCCESS',
  GET_ONBOARDING_OCCUPATION_FAILED: 'GET_ONBOARDING_OCCUPATION_FAILED',
};
export const getOnboardingQuestionTypes = {
  GET_ONBOARDING_QUESTION_REQUEST: 'GET_ONBOARDING_QUESTION_REQUEST',
  GET_ONBOARDING_QUESTION_SUCCESS: 'GET_ONBOARDING_QUESTION_SUCCESS',
  GET_ONBOARDING_QUESTION_FAILURE: 'GET_ONBOARDING_QUESTION_FAILURE',
};
export const insertAnswerTypes = {
  INSERT_ANSWER_REQUEST: 'INSERT_ANSWER_REQUEST',
  INSERT_ANSWER_SUCCESS: 'INSERT_ANSWER_SUCCESS',
  INSERT_ANSWER_FAILURE: 'INSERT_ANSWER_FAILURE',
};
export const getOnboardingAnswersTypes = {
  GET_ONBOARDING_ANSWERS_REQUEST: 'GET_ONBOARDING_ANSWERS_REQUEST',
  GET_ONBOARDING_ANSWERS_SUCCESS: 'GET_ONBOARDING_ANSWERS_SUCCESS',
  GET_ONBOARDING_ANSWERS_FAILURE: 'GET_ONBOARDING_ANSWERS_FAILURE',
};
