import type { NoodlingObject } from 'commons/types';
import { getNoodlingByIdTypes } from './types';

export const noodlingActions = {
  getNoodlingByIdRequest: (payload: string) => ({
    type: getNoodlingByIdTypes.REQUEST,
    payload,
  }),
  getNoodlingByIdSuccess: (payload: NoodlingObject) => ({
    type: getNoodlingByIdTypes.SUCCESS,
    payload,
  }),
  getNoodlingByIdFailed: (payload: string) => ({
    type: getNoodlingByIdTypes.FAILED,
    payload,
  }),
};
