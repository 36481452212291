// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import { getUser, getOtherUser } from 'ducks/user/selector';
import { alertTypesConstants } from 'commons/constants';
import type { RequestConnectionPayload, UserConnectionType } from 'commons/types';
import { userConnectionActions } from './actions';
import { connectionTypes } from './types';
import { getSuccessConnectionMessage } from './selector';

const { DANGER, SUCCESS } = alertTypesConstants;
export function* getUserConnectionsSaga(): Saga {
  try {
    const connections: UserConnectionType[] = yield call([
      api.userConnection,
      'getUserConnections',
    ]);
    yield put(userConnectionActions.getUserConnectionsSuccess(connections));
  } catch (error) {
    yield put(userConnectionActions.getUserConnectionsFailed(error));
  }
}
export function* requestConnectionSaga({ payload }: RequestConnectionPayload): Saga {
  try {
    const sourceUser = yield select(getUser);
    const targetUser = yield select(getOtherUser);
    const connection = yield call(
      [api.userConnection, 'requestConnection'],
      payload.entityId,
      sourceUser,
      payload.targetUser || targetUser,
    );

    if (connection) {
      const { profile } = payload.targetUser || targetUser;
      yield put(userConnectionActions.postUserConnectionSuccess(connection));
      yield put(
        alertActions.setAlert({
          message: getSuccessConnectionMessage(profile),
          type: SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(userConnectionActions.postUserConnectionFailed(error));
    yield put(
      alertActions.setAlert({
        message: 'We cannot send your request now. Try again later',
        type: DANGER,
      }),
    );
  }
}
export function* acceptConnectionSaga({ payload }: { payload: UserConnectionType }): Saga {
  try {
    const connection = yield call([api.userConnection, 'acceptConnection'], payload);

    if (connection) {
      yield put(userConnectionActions.acceptUserConnectionSuccess(connection));
      yield put(
        alertActions.setAlert({
          message: 'Connection request accepted',
          type: SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(userConnectionActions.acceptUserConnectionFailed(error));
    yield put(
      alertActions.setAlert({
        message: 'Failed to accept the connection request',
        type: DANGER,
      }),
    );
  }
}
export function* rejectConnectionSaga({ payload }: { payload: UserConnectionType }): Saga {
  try {
    const connection = yield call([api.userConnection, 'rejectConnection'], payload);

    if (connection) {
      yield put(userConnectionActions.rejectCancelUserConnectionSuccess(connection));
      yield put(
        alertActions.setAlert({
          message: 'Connection request declined',
          type: SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(userConnectionActions.rejectCancelUserConnectionFailed(error));
    yield put(
      alertActions.setAlert({
        message: 'Failed to decline the connection request',
        type: DANGER,
      }),
    );
  }
}
export default function* userSaga(): Iterable<any> {
  yield takeEvery(connectionTypes.REQUEST, getUserConnectionsSaga);
  yield takeEvery(connectionTypes.CONNECTION_REQUEST, requestConnectionSaga);
  yield takeEvery(connectionTypes.ACCEPT_CONNECTION_REQUEST, acceptConnectionSaga);
  yield takeEvery(connectionTypes.REJECT_CANCEL_CONNECTION_REQUEST, rejectConnectionSaga);
}
