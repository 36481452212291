import type {
  MeetingDetailsType,
  TASchedulerBookingPayload,
  JoinSplitPayload,
} from 'commons/types/teacherAssistantType';
import {
  getMeetingDetailTypes,
  joinSplitCostTypes,
  getSchedulerAvailabilityTypes,
  createTASchedulerBookingTypes,
  schedulerBookingTypes,
} from './types';

export const teacherAssistantActions = {
  getMeetingDetailRequest: (payload: string) => ({
    type: getMeetingDetailTypes.GET_MEETING_DETAIL_REQUEST,
    payload,
  }),
  getMeetingDetailSuccess: (payload: MeetingDetailsType) => ({
    type: getMeetingDetailTypes.GET_MEETING_DETAIL_SUCCESS,
    payload,
  }),
  getMeetingDetailFailure: (payload: string) => ({
    type: getMeetingDetailTypes.GET_MEETING_DETAIL_FAILURE,
    payload,
  }),
  joinSplitCostRequest: (payload: JoinSplitPayload) => ({
    type: joinSplitCostTypes.JOIN_SPLIT_COST_REQUEST,
    payload,
  }),
  joinSplitCostSuccess: () => ({
    type: joinSplitCostTypes.JOIN_SPLIT_COST_SUCCESS,
  }),
  joinSplitCostFailure: (payload: string) => ({
    type: joinSplitCostTypes.JOIN_SPLIT_COST_FAILURE,
    payload,
  }),
  resetJoinSplitCostStatus: () => ({
    type: joinSplitCostTypes.JOIN_SPLIT_COST_RESET,
  }),
  getTASchedulerAvailability: (payload: string) => ({
    type: getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_REQUEST,
    payload,
  }),
  getTASchedulerAvailabilitySuccess: (payload: any[]) => ({
    type: getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_SUCCESS,
    payload,
  }),
  getTASchedulerAvailabilityFailure: (payload: string) => ({
    type: getSchedulerAvailabilityTypes.GET_TA_SCHEDULER_AVAILABILITY_FAILURE,
    payload,
  }),
  createTASchedulerBooking: (payload: TASchedulerBookingPayload) => ({
    type: createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_REQUEST,
    payload,
  }),
  createTASchedulerBookingSuccess: (payload: Record<string, any>) => ({
    type: createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_SUCCESS,
    payload,
  }),
  createTASchedulerBookingFailure: (payload: string) => ({
    type: createTASchedulerBookingTypes.CREATE_TA_SCHEDULER_BOOKING_FAILURE,
    payload,
  }),
  resetBookingSchedulerStatus: () => ({
    type: schedulerBookingTypes.RESET_BOOKING_STATUS,
  }),
};
