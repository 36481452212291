import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useWidth } from 'utils/customHooks';
import Skeleton from 'components/Skeleton/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants';
import { HomeContainer, HomeInnerContainer, HomeWrapper } from 'screens/Dashboard/Home/Home.styles';
import { FeedContainer } from 'screens/Dashboard/Home/GetStreamFeed/GetStreamFeed.styles';
import { SectionFeed, StreamFeed, SidebarWrapper } from './HomeSkeleton.style';

export const renderSkeletonPost = (): React.ReactElement<any> => <Skeleton type='post' />;
export const renderSkeletonComment = (): React.ReactElement<any> => <Skeleton type='comment' />;
export const SidebarSkeleton = (): React.ReactElement<any> => (
  <SidebarWrapper role='region' aria-label='User information'>
    <div>
      <Skeleton type='block' />
      <Skeleton type='user' />
      <Skeleton type='user' />
      <Skeleton type='user' />
    </div>
  </SidebarWrapper>
);

const HomeSkeleton = (): React.ReactElement<any> => {
  const width = useWidth();

  const renderGetStreamFeed = (): React.ReactElement<any> => (
    <FeedContainer role='feed' aria-label='Post feed'>
      <StreamFeed>
        <Skeleton type='empty-card' />
        {renderSkeletonPost()}
      </StreamFeed>
    </FeedContainer>
  );

  const renderSectionFeed = (): React.ReactElement<any> => (
    <FeedContainer role='feed' aria-label='Dashboard Feed'>
      <SectionFeed>
        <h1>My Dashboard</h1>
        <Skeleton type='dashboard-card' />
      </SectionFeed>
    </FeedContainer>
  );

  const renderHome = () => (
    <Switch>
      <Route
        exact
        path='/'
        render={() => (
          <>
            {width > TABLET_SMALL_WIDTH && <SidebarSkeleton />}
            <HomeContainer role='main' id='main-id'>
              <HomeInnerContainer>
                {renderSectionFeed()}
                {renderGetStreamFeed()}
              </HomeInnerContainer>
            </HomeContainer>
          </>
        )}
      />
      <Route
        exact
        path='/tab/team'
        render={() =>
          width < TABLET_SMALL_WIDTH && (
            <HomeInnerContainer team>
              <SidebarSkeleton />
            </HomeInnerContainer>
          )
        }
      />
    </Switch>
  );

  return <HomeWrapper>{renderHome()}</HomeWrapper>;
};

export default HomeSkeleton;
