export const createLandingPageTypes = {
  REQUEST: 'CREATE_LANDING_PAGE_REQUEST',
  SUCCESS: 'CREATE_LANDING_PAGE_SUCCESS',
  FAILED: 'CREATE_LANDING_PAGE_FAILED',
  SET_UP: 'CREATE_LANDING_PAGE_SET_UP',
};
export const getLandingPageTypes = {
  REQUEST: 'GET_LANDING_PAGE_REQUEST',
  SUCCESS: 'GET_LANDING_PAGE_SUCCESS',
  FAILED: 'GET_LANDING_PAGE_FAILED',
};
export const getParentsSuggestionsTypes = {
  REQUEST: 'GET_LANDING_PAGES_PARENTS_REQUEST',
  SUCCESS: 'GET_LANDING_PAGES_PARENTS_SUCCESS',
  FAILED: 'GET_LANDING_PAGES_PARENTS_FAILED',
};
export const landingPageListTypes = {
  REQUEST: 'LANDING_PAGE_GET_LIST_REQUEST',
  SUCCESS: 'LANDING_PAGE_GET_LIST_SUCCESS',
  FAILED: 'LANDING_PAGE_GET_LIST_FAILED',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
};
export const updateLandingPageTypes = {
  REQUEST: 'UPDATE_LANDING_PAGE_REQUEST',
  SUCCESS: 'UPDATE_LANDING_PAGE_SUCCESS',
  FAILED: 'UPDATE_LANDING_PAGE_FAILED',
};
export const updateCriteriaTypes = {
  REQUEST: 'UPDATE_LANDING_PAGE_CRITERIA_REQUEST',
  SUCCESS: 'UPDATE_LANDING_PAGE_CRITERIA_SUCCESS',
  FAILED: 'UPDATE_LANDING_PAGE_CRITERIA_FAILED',
};
export const landingPageSuggestionsTypes = {
  CLEAR: 'CLEAR_LANDING_PAGE_SUGGESTIONS',
  FILTER: 'FILTER_LANDING_PAGE_SUGGESTIONS',
};
export const landingPageEditModeTypes = {
  CHANGE: 'CHANGE_LANDING_PAGE_EDIT_MODE',
};
export const landingPageCriteriaTypes = {
  EDIT_MODE: 'CHANGE_LANDING_PAGE_CRITERIA_EDIT_MODE',
};
