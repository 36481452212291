// @ts-nocheck
/* eslint-disable import/no-cycle */

/* eslint-disable class-methods-use-this */
import get from 'lodash.get';
import humps from 'humps';
import JsonApi from 'utils/jsonApi';
import {
  Course as CourseType,
  CoursePreviewPayload,
  EnrolledSections,
  GetSavedCourses,
  LMS,
  UserFlags,
} from 'commons/types/courseTypes';
import { axios } from '../index';

export default class Course {
  jsonApi: JsonApi;

  entity = 'entities.course';

  basePath = '/v2/courses';

  basePathTeams = '/teams';

  basePathSections = '/sections';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getCoursePreview(payload: CoursePreviewPayload): Promise<CourseType> {
    const response = await this.jsonApi.get(
      `${this.basePath}/${
        payload.courseSlug
      }?include=surveys,sections&exclude_attributes=${payload?.excludeAttributes?.join(', ')}`,
      false,
    );
    const course = get(response, 'data', {});
    const userFlags: UserFlags = get(response, 'data.userFlags', {});
    const courseResponse = {
      ...course,
      teacherAssistantsCollection: course.teacherAssistantsCollection?.items || [],
      instructorsCollection: course.instructorsCollection?.items || [],
      userFlags,
    };
    return courseResponse;
  }

  async getMyCourses({
    userId,
    partnerId,
    include,
  }: {
    userId?: string;
    partnerId?: string;
    include?: string;
  }): Promise<CourseType[]> {
    const queryParams = new URLSearchParams();
    if (userId) {
      queryParams.set('user_id', userId);
    }
    if (partnerId) {
      queryParams.set('partner_id', partnerId);
    }
    if (include) {
      queryParams.set('include', include);
    }
    const response = await this.jsonApi.get(
      `${this.basePath}/my_courses?${queryParams.toString()}`,
      false,
    );
    const coursesArr = get(response, 'data', []);
    const uniques = [...new Map(coursesArr.map((item) => [item.sys.id, item])).values()];
    return uniques;
  }

  async getCourses(): Promise<CourseType> {
    const response = await this.jsonApi.get(this.basePath, false);
    const coursesArr = get(response, 'data', {});
    return coursesArr?.map((course) => ({
      ...course,
      teacherAssistantsCollection: course.teacherAssistantsCollection?.items,
      instructorsCollection: course.instructorsCollection?.items,
    }));
  }

  async getEnrolledSections({
    brightspaceOrgUnitId,
    moodleCourseId,
    canvasCourseId,
    lms,
  }: {
    moodleCourseId?: number;
    brightspaceOrgUnitId?: number;
    canvasCourseId?: number;
    lms: CourseType['lms'];
  }): Promise<EnrolledSections> {
    const queryParams = new URLSearchParams();
    const lmsQueryParamMapping = {
      [LMS.Brightspace]: ['brightspace_org_unit_id', brightspaceOrgUnitId],
      [LMS.Moodle]: ['moodle_course_id', moodleCourseId],
      [LMS.Canvas]: ['canvas_course_id', canvasCourseId],
    };

    queryParams.set(lmsQueryParamMapping[lms][0], lmsQueryParamMapping[lms][1]);

    const response = await this.jsonApi.get(
      `${this.basePathSections}/my_section?${queryParams.toString()}`,
      false,
    );

    const responseObj = get(response, 'data.attributes', {});

    return responseObj;
  }

  async getSavedCourses(): Promise<GetSavedCourses[]> {
    const filter = '[{"name":"content_type","op":"eq","val":"course"}]';
    const urlRequest = `${this.basePathTeams}/saved-courses?filter=${filter}`;
    const response = await this.jsonApi.get(urlRequest, false);
    const responseObj = get(response, 'data', {});
    const courses = responseObj.map((course) => ({ ...course?.attributes, id: course?.id }));
    return courses;
  }

  async removeCourse(saveContentId: number): Promise<void> {
    await this.jsonApi.delete(`${this.basePathTeams}/saved-content/${saveContentId}`, false);
  }

  async getCertificateByUUID(uuid: string): Promise<Record<string, any>> {
    const response = await axios.get(`/certificates/${uuid}`);
    return humps.camelizeKeys(response.data.data.attributes);
  }

  async downloadCertificate(downloadURL: string): Promise<Record<string, any>> {
    const response = await axios.get(downloadURL, {
      responseType: 'blob',
    });
    return response.data;
  }
}
