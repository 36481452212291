import {
  getSessionStorageData,
  setSessionStorageData,
  removeSessionStorageData,
} from './sessionStorage';

interface UtmValuesInterface {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
}

export const UTM_VALUES = 'utm_values';

export const heapIdentify = (id: string): void => {
  // @ts-ignore
  if (!window.heap?.loaded || !window.heap?.identify) {
    console.error('Heap is not initialized.');
    return;
  }

  // @ts-ignore
  window.heap.identify(id);
};

const heapAddUserProperties = (props: Record<string, string | null>): void => {
  const shouldNotCallHeap = !props || Object.keys(props).length <= 1;

  // @ts-ignore
  if (!window.heap?.loaded || !window.heap?.addUserProperties) {
    console.error('Heap is not initialized.');
    return;
  }

  if (shouldNotCallHeap) return;
  // @ts-ignore
  window.heap.addUserProperties(props);
};

/**
 * Extracts UTM parameters from a URL or the current window location
 * @param url Optional URL string to parse UTM parameters from. If not provided, uses current window location
 * @returns Object containing UTM parameter values, or null if no UTM parameters found
 */
export const getUtmValuesFromUrl = (url?: string): UtmValuesInterface | null => {
  const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const urlSearchParams = new URLSearchParams(url || window.location.search);
  const values: UtmValuesInterface = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
  };

  params.forEach((param) => {
    values[param] = urlSearchParams.get(param);
    if (!values[param]) delete values[param];
  });

  return Object.keys(values).length > 0 ? values : null;
};

/**
 * Saves UTM values from the current URL to session storage.
 * If UTM values are present in the URL, they will be stored under the UTM_VALUES key in session storage.
 *
 * @remarks
 * This function relies on `getUtmValuesFromUrl()` to extract UTM parameters and
 * `setSessionStorageData()` to persist the values in session storage.
 *
 * @returns void
 */
export const saveUtmValues = (): void => {
  const values = getUtmValuesFromUrl();

  if (values) {
    setSessionStorageData(UTM_VALUES, values);
  }
};

export const clearUtmValues = (): void => {
  removeSessionStorageData(UTM_VALUES);
};

export const addUtmToHeap = (userId: string): void => {
  const utmValues = getSessionStorageData(UTM_VALUES);
  heapAddUserProperties({ ...utmValues, userId });
  clearUtmValues();
};

export const resetHeapIdentify = () => {
  // @ts-ignore
  if (!window.heap?.loaded || !window.heap?.resetIdentity) {
    console.error('Heap is not initialized.');
    return;
  }

  // @ts-ignore
  window.heap.resetIdentity();
};
