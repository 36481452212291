import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type {
  EntityConnection,
  UserConnectionType,
  NormalizedJsonApi,
  RawEntityConnection,
} from 'commons/types';

export default class UserConnection {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  static parseConnectionsData(response: NormalizedJsonApi): UserConnectionType[] {
    const entities = get(response, 'entities.entity', {});
    const entityUsers = get(response, 'entities.user', {});
    const entityConnections = get(response, 'entities.entityConnections', {});
    const entitiesFiltered: RawEntityConnection = (Object.values(entities) as any)
      .filter(({ type, user }) => type === 'user' && user[0])
      .reduce((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
      }, {});
    const entityConnectionsFiltered: EntityConnection[] = (
      Object.values(entityConnections) as any
    ).filter(({ targetEntityId }) => Object.keys(entitiesFiltered).includes(targetEntityId));
    const connections = entityConnectionsFiltered.map((entityConnection) => {
      const { id, targetEntityId, entityId, status, updatedAt } = entityConnection;
      const targetUser = get(entityUsers, `${entitiesFiltered[targetEntityId].user[0].id}`, {});
      const sourceUser = get(entityUsers, `${entitiesFiltered[entityId].user[0].id}`, {});
      targetUser.entity = entitiesFiltered[targetEntityId];
      sourceUser.entity = entitiesFiltered[entityId];
      return {
        id,
        sourceUser,
        targetUser,
        status,
        updatedAt,
      };
    });
    return connections;
  }

  static parseOneConnectionResponse(
    connectionJson: NormalizedJsonApi,
    sourceUser: any,
    targetUser: any,
  ): UserConnectionType | null {
    try {
      const entityConnections = get(connectionJson, 'entities.entityConnections', {});
      const connections = Object.keys(entityConnections).map((itemConnection) => {
        const { id } = entityConnections[itemConnection];
        return {
          id,
          sourceUser,
          targetUser,
          status: entityConnections[itemConnection].status,
          updatedAt: entityConnections[itemConnection].updatedAt,
        };
      });

      if (connections.length === 1) {
        return connections[0];
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  async getUserConnections(): Promise<UserConnectionType[]> {
    const response = await this.jsonApi.get(
      '/connections?include=connection_to.user,connection_from.user&page[size]=0',
    );
    return UserConnection.parseConnectionsData(response);
  }

  async requestConnection(
    otherEntityId: string,
    sourceUser: any,
    targetUser: any,
  ): Promise<UserConnectionType | null> {
    const connectionResponse = await this.jsonApi.post(
      '/connections?include=connection_to.user,connection_from.user',
      {
        payload: {
          target_entity_id: otherEntityId,
        },
      },
    );
    return UserConnection.parseOneConnectionResponse(connectionResponse, sourceUser, targetUser);
  }

  async acceptConnection(connection: UserConnectionType): Promise<UserConnectionType | null> {
    const { sourceUser, targetUser } = connection;
    const { entityObj } = sourceUser;
    const { id: targetEntityId } = entityObj;
    const connectionResponse = await this.jsonApi.post('/connections', {
      payload: {
        target_entity_id: targetEntityId,
      },
    });
    return UserConnection.parseOneConnectionResponse(connectionResponse, sourceUser, targetUser);
  }

  async rejectConnection(connection: UserConnectionType): Promise<UserConnectionType | null> {
    const { sourceUser, targetUser } = connection;
    const { entityObj } = sourceUser;
    const { id: targetEntityId } = entityObj;
    const connectionResponse = await this.jsonApi.delete(
      '/connections',
      {
        data: {
          target_entity_id: targetEntityId,
        },
      },
      true,
    );
    return UserConnection.parseOneConnectionResponse(connectionResponse, sourceUser, targetUser);
  }
}
