import * as React from 'react';
import LeftNavWrapper from './LeftNav.style';
import type { IconProps, ItemProps, LeftNavProps } from './LeftNav.types';

class LeftNav extends React.Component<LeftNavProps> {
  static Icon = ({ children }: IconProps) => <div className='lnav__icon'>{children}</div>;

  static Item = ({ children }: ItemProps) => <div className='lnav__item'>{children}</div>;

  static Title = ({ children }: LeftNavProps) => <h5 className='lnav__title'>{children}</h5>;

  render() {
    const { children } = this.props;
    return <LeftNavWrapper>{children}</LeftNavWrapper>;
  }
}

export default LeftNav;
