import { likePostTypes } from './types';

export const likeActions = {
  likePostRequest: (id: number, objectType: string) => ({
    type: likePostTypes.REQUEST,
    payload: {
      id,
      objectType,
    },
  }),
  likePostSuccess: (payload: { id: number; objectType: string }) => ({
    type: likePostTypes.SUCCESS,
    payload,
  }),
  likePostFail: (payload: string) => ({
    type: likePostTypes.FAILED,
    payload,
  }),
  deleteLikePostRequest: (id: number, likeId: number, objectType: string) => ({
    type: likePostTypes.DELETE_REQUEST,
    payload: {
      id,
      likeId,
      objectType,
    },
  }),
  deleteLikePostSuccess: (payload: { id: number; likeId: number; objectType: string }) => ({
    type: likePostTypes.DELETE_SUCCESS,
    payload,
  }),
  deleteLikePostFail: (payload: string) => ({
    type: likePostTypes.DELETE_FAILED,
    payload,
  }),
};
