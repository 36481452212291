// @ts-nocheck
import styled, { CSSObject } from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import TextField from '@mui/material/TextField';
import HelperText from 'components/HelperText/index';

export const Wrapper = styled.div(
  (): CSSObject => ({
    borderRadius: 8,
  }),
);

export const CustomTextField: StatelessFunctionalComponent<any> = styled(TextField)`
  && {
    border: ${({ error, theme }) =>
      error ? '1px solid red' : `1px solid ${theme.palette.neutral.forty}`};
    border-radius: 8px;
    width: 100%;

    input::placeholder {
      ${({ theme }) => theme.noodera.fontTypes.font4};
      color: ${({ theme }) => theme.palette.neutral.hundred};
      opacity: 1;
    }

    .MuiInputBase-root {
      height: 48px;
      width: 100% !important;
    }

    .MuiOutlinedInput-root {
      border: 0 !important;
    }

    .MuiInputBase-root {
      width: 392px;
      border: 1px solid ${({ theme }) => theme.palette.neutral.ten};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      ${({ theme }) => theme.noodera.fontTypes.font4};
      color: ${({ theme }) => theme.palette.neutral.hundred};

      & > input,
      textarea {
        color: ${({ theme }) => theme.palette.greyscale.black};
        background: transparent;
      }

      & > input::placeholder,
      textarea::placeholder {
        color: ${({ theme }) => theme.palette.neutral.seventy};
        ${({ theme }) => theme.noodera.fontTypes.font5};
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      content: none;
    }

    .MuiInputAdornment-root > p {
      ${({ theme }) => theme.noodera.fontTypes.font4};
      color: ${({ theme }) => theme.palette.neutral.hundred};
    }
  }
`;
export const ErrorText: StatelessFunctionalComponent<any> = styled(HelperText)`
  color: ${({ theme }) => theme.palette.warning.hundred};
  margin-top: ${({ theme }) => theme.noodera.spacing.x7};
`;
export const TopHelperText: StatelessFunctionalComponent<any> = styled.div`
  ${({ theme }) => theme.noodera.fontTypes.font4Medium};
  color: ${({ theme }) => theme.palette.neutral.eighty};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x7};
`;
export const StyledText: StatelessFunctionalComponent<any> = styled.span`
  && {
    ${({ theme }) => theme.noodera.fontTypes.font5};
    color: ${({ theme }) => theme.palette.neutral.eighty};
    display: flex;
    justify-content: end;
    margin-top: ${({ theme }) => theme.noodera.spacing.x7};
  }
`;
