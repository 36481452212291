// @ts-nocheck
import {
  sendSurveyAnswersActionTypes,
  sendSurveyAnswerActionTypes,
  getOnboardingSurveyActionTypes,
} from './types';

const initialState = {
  sendSurveyAnswers: {
    error: null,
    data: null,
    isLoading: false,
  },
  sendSurveyAnswer: {
    error: null,
    data: null,
    isLoading: false,
  },
  onboardingSurvey: {
    error: null,
    data: null,
    isLoading: false,
  },
};

const surveyReducer = (state: any = initialState, action: any) => {
  const { payload, type } = action;

  switch (type) {
    case sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_REQUEST: {
      return { ...state, sendSurveyAnswers: { ...state.sendSurveyAnswers, isLoading: true } };
    }

    case sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...state,
        sendSurveyAnswers: { ...state.sendSurveyAnswers, data: payload, isLoading: false },
      };
    }

    case sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_FAILURE: {
      return {
        ...state,
        sendSurveyAnswers: { ...state.sendSurveyAnswers, error: payload, isLoading: false },
      };
    }

    case sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_REQUEST: {
      return { ...state, sendSurveyAnswer: { ...state.sendSurveyAnswer, isLoading: true } };
    }

    case sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_SUCCESS: {
      let newAnswers = [...state?.onboardingSurvey?.data?.answers];
      newAnswers = newAnswers.filter(
        (answer) => +answer.question_id !== +payload?.data?.question_id,
      );
      newAnswers.push(payload?.data);
      return {
        ...state,
        sendSurveyAnswer: { ...state.sendSurveyAnswer, data: payload?.data, isLoading: false },
        onboardingSurvey: {
          ...state.onboardingSurvey,
          data: {
            ...state.onboardingSurvey.data,
            answers: [...newAnswers],
          },
        },
      };
    }

    case sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_FAILURE: {
      return {
        ...state,
        sendSurveyAnswer: { ...state.sendSurveyAnswer, error: payload, isLoading: false },
      };
    }

    case getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_REQUEST: {
      return { ...state, onboardingSurvey: { ...state.onboardingSurvey, isLoading: true } };
    }

    case getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_SUCCESS: {
      return {
        ...state,
        onboardingSurvey: { ...state.onboardingSurvey, data: payload, isLoading: false },
      };
    }

    case getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_FAILURE: {
      return {
        ...state,
        onboardingSurvey: { ...state.onboardingSurvey, error: payload, isLoading: false },
      };
    }

    default:
      return state;
  }
};

export default surveyReducer;
