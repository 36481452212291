import * as React from 'react';
import { ReactComponent as NoodleLogo } from 'assets/svg/noodle_logo.svg';
import { ReactComponent as NoodleSmallLogo } from 'assets/images/isotype.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/svg/linked_in.svg';
import heroLearningImg from 'assets/images/hero-learning@2x.webp';

export const apiUrl = {
  base: process.env.REACT_APP_BASE_API,
};
export const baseUrl = window.location.origin;
export const moodleUrl = process.env.REACT_APP_MOODLE_URL;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const cloudfrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
export const socialToolUrl = process.env.REACT_APP_SOCIAL_TOOL_URL;
export const getStreamConfig = {
  appId: process.env.REACT_APP_GET_STREAM_APP_ID,
  apiKey: process.env.REACT_APP_GET_STREAM_API_KEY,
};

export const devConfig = {
  partnerId: process.env.REACT_APP_DEV_PARTNER_ID || null,
  frontDomain: process.env.REACT_APP_DEV_FRONT_DOMAIN || null,
};

export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: `${baseUrl}/login`,
  responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
  realm: process.env.REACT_APP_AUTH0_REALM,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  userMetadataKey: process.env.REACT_APP_AUTH0_USER_METADATA_KEY,
};

export const images = {
  defaults: {
    avatar: {
      user: `${cloudfrontUrl}/defaults/user_avatar.png`,
      article: `${cloudfrontUrl}/defaults/noodle_default_image.jpg`,
    },
    homeBanner: `${baseUrl}${heroLearningImg}`,
  },
  noodleLogo: `${cloudfrontUrl}/Noodle-150x150.png`,
};

export const isEnabledPremiumEnroll = process.env.REACT_APP_ENABLE_PREMIUM_ENROLL === 'true';
export const isAllowAdminObjectsManagement =
  process.env.REACT_APP_ALLOW_ADMIN_OBJECTS_MANAGEMENT === 'true';
export const PARTNER_ID_NOODLE = 'NOODLE';

export const baseSettings = {
  [PARTNER_ID_NOODLE]: {
    partnerId: PARTNER_ID_NOODLE,
    parentPartnerId: null,
    companyName: 'Noodle',
    companySlogan: 'The Smarter Way to Search for Learning',
    companyLogo: <NoodleLogo />,
    companyReverseLogo: null,
    companySmallLogo: <NoodleSmallLogo />,
    tableauLink: '',
    copyright: {
      text: '© Noodle.com',
      terms: {
        TEXT: 'Terms of Service',
        LINK: `/terms-of-service`,
      },
      privacy: {
        TEXT: 'Privacy Policy',
        LINK: `/privacy-policy`,
      },
      cookie: {
        TEXT: 'Cookie Policy',
        LINK: `/cookie-policy`,
      },
      sellInfo: {
        TEXT: 'Do Not Sell My Info',
        LINK: `/do-not-sell-my-info`,
      },
    },
    socialMedia: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/noodleeducation',
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/noodleeducation',
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/noodleeducation/',
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/noodleeducation/',
      },
      {
        name: 'YouTube',
        url: 'https://www.youtube.com/c/NoodleEducation',
      },
    ],
    signup: {
      terms: `<a href='/terms-of-service' target='_blank'>Noodle's Terms and Services</a>`,
      privacy: `<a href='/privacy-policy' target='_blank'>Privacy Policy</a>`,
      text: `By clicking the “Sign Up” button, you agree that a representative of Noodle Partners, Inc.
          and or its Education Network can contact you regarding education-related offers at the
          e-mail address provided.`,
    },
    footer: {
      trending: {
        TEXT: 'Trending Grad Schools Articles',
        beneathItems: [
          {
            TEXT: 'Master’s in Supply Chain Management',
            LINK: `/articles/is-a-masters-in-supply-chain-management-worth-it-mscm-degree`,
          },
          {
            TEXT: 'Master’s in Computer Science ',
            LINK: `/articles/is-a-masters-in-computer-science-worth-it`,
          },
          {
            TEXT: 'Master’s in Public Health',
            LINK: `/articles/is-a-masters-in-public-health-mph-degree-worth-it`,
          },
          {
            TEXT: 'Master’s in Education',
            LINK: `/articles/what-can-i-do-with-a-masters-in-education`,
          },
          {
            TEXT: 'Master’s in Social Work',
            LINK: `/articles/most-affordable-online-msw-programs-masters-in-social-work-degree`,
          },
          {
            TEXT: 'Master’s in Healthcare Administration',
            LINK: `/articles/masters-in-healthcare-administration-worth-it`,
          },
          {
            TEXT: 'Master’s in Information Technology',
            LINK: `/articles/is-a-masters-in-information-technology-worth-it`,
          },
          {
            TEXT: 'Master’s in Teaching',
            LINK: `/articles/7-unexpected-jobs-you-can-get-with-a-masters-in-teaching`,
          },
        ],
      },
      students: {
        TEXT: 'For Students',
        beneathItems: [
          {
            TEXT: 'College Search',
            LINK: `/schools/browse`,
          },
          {
            TEXT: 'MBA Program Search',
            LINK: `/schools/browse`,
          },
          {
            TEXT: 'Law School Search',
            LINK: `/schools/browse`,
          },
          {
            TEXT: 'Articles',
            LINK: `/resources/browse`,
          },
          {
            TEXT: 'Counselors',
            LINK: `/schedule`,
          },
          {
            TEXT: 'Log In',
            LINK: `/login`,
          },
        ],
      },
      schools: {
        TEXT: 'For Schools',
        beneathItems: [
          {
            TEXT: 'Our Approach',
            LINK: `https://highered.noodle.com/noodle-services`,
          },
          {
            TEXT: 'How We’re Different',
            LINK: `https://highered.noodle.com/why/better-than-opms`,
          },
          {
            TEXT: 'Programs & Schools',
            LINK: `https://highered.noodle.com/our-network`,
          },
          {
            TEXT: 'Economics',
            LINK: `https://employers.noodle.com/contact-us`,
          },
        ],
      },
      programs: {
        TEXT: 'Areas of Interest',
        beneathItems: [
          {
            TEXT: 'Business & Management',
            LINK: `/interests/business-management`,
          },
          {
            TEXT: 'IT & Engineering',
            LINK: `/interests/information-technology-engineering`,
          },
          {
            TEXT: 'Nursing & Healthcare',
            LINK: `/interests/nursing-healthcare`,
          },
          {
            TEXT: 'Criminal Justice & Law',
            LINK: `/interests/criminal-justice-legal-studies`,
          },
          {
            TEXT: 'Education ',
            LINK: `/interests/education-teaching`,
          },
          {
            TEXT: 'Social Work & Psychology',
            LINK: `/interests/social-work-counseling-psychoogy`,
          },
        ],
      },
      company: {
        TEXT: 'Company',
        beneathItems: [
          {
            TEXT: 'About Noodle',
            LINK: `https://about.noodle.com/`,
          },
          {
            TEXT: 'News',
            LINK: 'https://about.noodle.com/press/releases',
          },
          {
            TEXT: 'Team',
            LINK: 'https://about.noodle.com/team',
          },
          {
            TEXT: 'Careers',
            LINK: 'https://about.noodle.com/careers',
          },
          {
            TEXT: 'Contact Us',
            LINK: 'https://about.noodle.com/contact-us',
          },
        ],
      },
      icons: [
        {
          ICON: <FacebookIcon />,
          LINK: 'https://www.facebook.com/noodleeducation',
          NAME: 'Facebook',
        },
        {
          ICON: <TwitterIcon />,
          LINK: 'https://twitter.com/noodleeducation',
          NAME: 'Twitter',
        },
        {
          ICON: <InstagramIcon />,
          LINK: 'https://www.instagram.com/noodleeducation',
          NAME: 'Instagram',
        },
        {
          ICON: <LinkedInIcon />,
          LINK: 'https://www.linkedin.com/company/noodleeducation/',
          NAME: 'LinkedIn',
        },
        {
          ICON: 'fab fa-reddit',
          LINK: 'https://www.linkedin.com/company/noodleeducation/',
          NAME: 'Reddit',
        },
      ],
    },
    environments: {
      qa: {
        auth0ClientId: 'pQZxMeGuqM9FYv6fNkFaAoR5wMyEyNJG',
      },
      staging2: {
        auth0ClientId: 'BU3qP4kzZ4u9ivjDDxFb7a30Ymu5iarQ',
      },
      prod: {
        auth0ClientId: 'hCT452BbfDmtyr6T9Jat58US9yN2mOwF',
      },
    },
  },
};
