// @ts-nocheck
import * as React from 'react';
import PlanItem from 'components/PlanItem';
import NooderaButton from 'components/NooderaButton';
import type { PlanCardProps } from './PlanCard.types';
import { Container, TitleWrapper, Title, Price } from './PlanCard.styles';

const renderPlanDetails = (details: string[]): React.ReactElement<any>[] =>
  details.map((item) => {
    if (typeof item === 'string') {
      return <PlanItem key={item} text={item} />;
    }
    return <PlanItem key={item.text} text={item.text} icon={item.icon} />;
  });

const PlanCard = ({
  title,
  price,
  onEnroll,
  ctaText,
  details,
  invertColor,
  icon,
}: PlanCardProps) => (
  <Container invertColor={invertColor}>
    <TitleWrapper>
      {icon}
      <Title data-testid='PlanCard-title'>{title}</Title>
    </TitleWrapper>
    {renderPlanDetails(details)}
    <Price data-testid='PlanCard-price'>{price}</Price>
    <div>
      {invertColor ? (
        <NooderaButton onClick={onEnroll} isFullWidth data-testid='enroll-plan-card-button'>
          {ctaText}
        </NooderaButton>
      ) : (
        <NooderaButton.CTA onClick={onEnroll} isFullWidth data-testid='enroll-plan-card-button'>
          {ctaText}
        </NooderaButton.CTA>
      )}
    </div>
  </Container>
);

export default PlanCard;
