import type { ReplaceWithConditionParams } from './String.types';

export const replaceValueConditions = {
  GREATER_THAN: '>',
  GREATER_EQUAL_THAN: '>=',
  LESS_THAN: '<',
  LESS_EQUAL_THAN: '<=',
  EQUAL: '=',
};
const { GREATER_THAN, GREATER_EQUAL_THAN, LESS_THAN, LESS_EQUAL_THAN, EQUAL } =
  replaceValueConditions;

/**
 * @description
 * Mask user input. Replaces the entire value using a condition
 */
export const replaceValueWithCondition = ({
  value,
  condition,
  compareWith,
  replaceWith = '',
}: ReplaceWithConditionParams): string => {
  let comparison;
  const valueNumber = Number(value);
  const compareWithNumber = Number(compareWith);

  switch (condition) {
    case GREATER_THAN:
      comparison = valueNumber > compareWithNumber;
      break;

    case GREATER_EQUAL_THAN:
      comparison = valueNumber >= compareWithNumber;
      break;

    case LESS_THAN:
      comparison = valueNumber < compareWithNumber;
      break;

    case LESS_EQUAL_THAN:
      comparison = valueNumber <= compareWithNumber;
      break;

    case EQUAL:
      comparison = valueNumber === compareWithNumber;
      break;

    default:
      comparison = false;
      break;
  }

  return comparison ? replaceWith : value;
};
