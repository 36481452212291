import type {
  ActivityQueryInterface,
  GetTeamFeedPostsPayload,
  CreatePostPayload,
  GetMoreCommentsPayload,
  GetUserFeedPostsPayload,
} from 'commons/types/getStreamTypes';
import {
  createPostTypes,
  getStreamTypes,
  getTeamFeedPostsTypes,
  getUserFeedPostsTypes,
  getUserTokenTypes,
  updateActivityCommentsTypes,
  getUserNotificationsTypes,
  getMoreCommentsTypes,
  getMorePostsTypes,
} from './types';

export const getStreamActions = {
  getUserTokenRequest: () => ({
    type: getUserTokenTypes.GET_USER_TOKEN_REQUEST,
  }),
  getUserTokenSuccess: (payload: string) => ({
    type: getUserTokenTypes.GET_USER_TOKEN_SUCCESS,
    payload,
  }),
  getUserTokenFailure: (payload: string) => ({
    type: getUserTokenTypes.GET_USER_TOKEN_FAILURE,
    payload,
  }),
  getUserFeedPostsRequest: (payload?: ActivityQueryInterface) => ({
    type: getUserFeedPostsTypes.GET_USER_FEED_POSTS_REQUEST,
    payload,
  }),
  getUserFeedPostsSuccess: (payload: Record<string, any>[]) => ({
    type: getUserFeedPostsTypes.GET_USER_FEED_POSTS_SUCCESS,
    payload,
  }),
  getUserFeedPostsFailure: (payload: string) => ({
    type: getUserFeedPostsTypes.GET_USER_FEED_POSTS_FAILURE,
    payload,
  }),
  getTeamFeedPostsRequest: (payload: GetTeamFeedPostsPayload) => ({
    type: getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_REQUEST,
    payload,
  }),
  getMoreCommentsSuccess: (payload: Record<string, any>[]) => ({
    type: getMoreCommentsTypes.GET_MORE_COMMENTS_SUCCESS,
    payload,
  }),
  getMoreCommentsFailure: (payload: string) => ({
    type: getMoreCommentsTypes.GET_MORE_COMMENTS_FAILURE,
    payload,
  }),
  getMoreCommentsRequest: (payload: GetMoreCommentsPayload) => ({
    type: getMoreCommentsTypes.GET_MORE_COMMENTS_REQUEST,
    payload,
  }),
  getMorePostsSuccess: (payload: Record<string, any>[]) => ({
    type: getMorePostsTypes.GET_MORE_POSTS_SUCCESS,
    payload,
  }),
  getMorePostsFailure: (payload: string) => ({
    type: getMorePostsTypes.GET_MORE_POSTS_FAILURE,
    payload,
  }),
  getMorePostsRequest: (payload: GetTeamFeedPostsPayload | GetUserFeedPostsPayload) => ({
    type: getMorePostsTypes.GET_MORE_POSTS_REQUEST,
    payload,
  }),
  getTeamFeedPostsSuccess: (payload: Record<string, any>[]) => ({
    type: getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_SUCCESS,
    payload,
  }),
  getTeamFeedPostsFailure: (payload: string) => ({
    type: getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_FAILURE,
    payload,
  }),
  updateActivityCommentsRequest: (payload: Record<string, any>) => ({
    type: updateActivityCommentsTypes.UPDATE_ACTIVITY_COMMENTS_REQUEST,
    payload,
  }),
  updateActivityCommentsSuccess: (payload: Record<string, any>) => ({
    type: updateActivityCommentsTypes.UPDATE_ACTIVITY_COMMENTS_SUCCESS,
    payload,
  }),
  updateActivityCommentsFailure: (payload: string) => ({
    type: updateActivityCommentsTypes.UPDATE_ACTIVITY_COMMENTS_FAILURE,
    payload,
  }),
  createPostRequest: (payload: CreatePostPayload) => ({
    type: createPostTypes.CREATE_POST_REQUEST,
    payload,
  }),
  createPostSuccess: (payload: number) => ({
    type: createPostTypes.CREATE_POST_SUCCESS,
    payload,
  }),
  createPostFailure: (payload: string) => ({
    type: createPostTypes.CREATE_POST_FAILURE,
    payload,
  }),
  toggleCreatePostModal: (payload: boolean) => ({
    type: getStreamTypes.TOGGLE_CREATE_POST_MODAL,
    payload,
  }),
  getUserNotificationsRequest: () => ({
    type: getUserNotificationsTypes.GET_USER_NOTIFICATIONS_REQUEST,
  }),
  // TODO: improve typing after Demo
  getUserNotificationsSuccess: (payload: any) => ({
    type: getUserNotificationsTypes.GET_USER_NOTIFICATIONS_SUCCESS,
    payload,
  }),
  getUserNotificationsFailure: (payload: string) => ({
    type: getUserNotificationsTypes.GET_USER_NOTIFICATIONS_FAILURE,
    payload,
  }),
  startPollingNotifications: () => ({
    type: getStreamTypes.START_POLLING_NOTIFICATIONS,
  }),
};
