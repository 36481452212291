// @ts-nocheck
/**
 * Theres a bug that makes Safari unable to parse the Date
 * if it doesn't have a T but comes with timezone difference
 *
 * @param dateString
 * @returns {string}
 */
import moment from 'moment';

export const parseDateToIsoFormat = (dateString = ''): string => {
  let dateParsed;

  try {
    const correctedDateString = dateString ? dateString.replace(' ', 'T') : '';
    dateParsed = correctedDateString ? new Date(correctedDateString).toISOString() : '';
  } catch (error) {
    dateParsed = '';
  }

  return dateParsed;
};

/**
 * Returns a date formatted like 'Septmber 10, 2019'
 * Pass monthShort for 'Sep 10, 2019'
 * @param dateString
 * @param monthShort
 * @param utc
 * @returns {string}
 */
export const getFormattedDate = (
  dateString: string,
  monthShort?: Boolean,
  utc?: Boolean,
): string => {
  const date = new Date(dateString);
  return `${date.toLocaleString('en-us', {
    timezone: utc ? 'UTC' : null,
    month: monthShort ? 'short' : 'long',
  })} ${utc ? date.getUTCDate() : date.getDate()}, ${date.getFullYear()}`;
};
export const convertToLocalDateFromUTC = (dateString: string): Date | number => {
  const date = new Date(dateString);

  if (dateString && dateString.includes('Z')) {
    date.toISOString().replace('Z', '').replace('T', '');
    return date;
  }

  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
};

const getMilliseconds = (date: string): number => {
  const today = new Date();
  return today - new Date(date);
};

const getMinutes = (milliseconds: number): number => Math.round((milliseconds % 86400000) / 60000);

const getHours = (milliseconds: number): number => Math.floor(milliseconds / 36e5);

const getDays = (hours: number): number => Math.round(hours / 24);

const getWeeks = (days: number): number => Math.round(days / 7);

const getMonths = (days: number): number => Math.round(days / 30);

const getMonthsMessage = (months: number): string => {
  if (months === 1) {
    return `${months} month ago`;
  }

  return `${months} months ago`;
};

const getWeeksMessage = (weeks: number): string => {
  if (weeks === 1) {
    return `${weeks} week ago`;
  }

  return `${weeks} weeks ago`;
};

const getDaysMessage = (days: number): string => {
  if (days === 1) {
    return 'yesterday';
  }

  return `${days}d ago`;
};

const getHoursMessage = (hours: number): string => `${hours}h`;

const getMinutesMessage = (minutes: number): string => `${minutes}m`;

const getTimeAgoMessageMonthsWeeksDays = (months: number, weeks: number, days: number): string => {
  let finalMessage = '';

  if (months) {
    finalMessage = getMonthsMessage(months);
  } else if (weeks) {
    finalMessage = getWeeksMessage(weeks);
  } else if (days) {
    finalMessage = getDaysMessage(days);
  }

  return finalMessage;
};

const getTimeAgoMessageHoursMinutes = (hours: number, minutes: number): string => {
  let finalMessage;

  if (hours > 0) {
    finalMessage = `${getHoursMessage(hours)} ago`;
  } else if (minutes >= 2) {
    finalMessage = `${getMinutesMessage(minutes)} ago`;
  } else {
    finalMessage = 'just now';
  }

  return finalMessage;
};

/**
 * GOOD TO HAVE: 'on sunday', 'on monday' for 2/3/4/5 days ago
 * GOOD TO HAVE: use moment for the calculations
 */
const timeAgoHandler = (date: string): string => {
  const milliseconds = getMilliseconds(date);
  const minutes = getMinutes(milliseconds);
  const hours = getHours(milliseconds);
  const days = getDays(hours);
  const weeks = getWeeks(days);
  const months = getMonths(days);
  let finalMessage;

  if (months || weeks || days) {
    finalMessage = getTimeAgoMessageMonthsWeeksDays(months, weeks, days);
  } else {
    finalMessage = getTimeAgoMessageHoursMinutes(hours, minutes);
  }

  return finalMessage;
};

export default timeAgoHandler;
export const calculateDate = (
  date: string,
): {
  dateTitle: string;
  btnTitle: string;
} => {
  const startingDate = moment(new Date(date)).startOf('day');
  const currentDate = moment(new Date()).startOf('day');
  const diffDays = startingDate.diff(currentDate, 'days');

  if (diffDays === 0) {
    return {
      dateTitle: 'Starting Today',
      btnTitle: 'Start',
    };
  }

  if (diffDays < 0) {
    return {
      dateTitle: `Started ${moment(date).format('MMM DD')}`,
      btnTitle: 'Continue',
    };
  }

  const daySuffix = diffDays === 1 ? 'day' : 'days';
  return {
    dateTitle: `Starting in ${diffDays} ${daySuffix}`,
    btnTitle: 'View Content',
  };
};

/**
 * Returns a future date formatted like '01.21.2023'
 *
 * @param dateString
 * @returns {string}
 */
export const calculateFutureDate = (dateString: Date, months: number) =>
  moment(dateString).add(months, 'months');

export const calculateNumberToDate = (value: number) => {
  const date = new Date(value * 1000);
  return new Intl.DateTimeFormat().format(date);
};

/**
 * Returns a date formatted date string - depending on the locale received
 * if no localeString is received it will use the local timezone, e.g.:
 * en-US localeString it will produce mm-dd-YYYY
 *
 * @param dateString
 * @param localeString
 * @returns {string}
 */
let dateFormatter;
export const getFormattedDateFromString = (dateString: string, localeString?: string): string => {
  if (!dateFormatter) {
    dateFormatter = new Intl.DateTimeFormat(localeString);
  } else if (localeString) {
    const options = dateFormatter.resolvedOptions();
    if (options.locale !== localeString) {
      dateFormatter = new Intl.DateTimeFormat(localeString);
    }
  }
  return dateFormatter.format(new Date(dateString));
};
