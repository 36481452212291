import * as React from 'react';
import TagWrapper from './Tag.style';
import type { TagProps } from './Tag.types';

const Tag = ({
  children,
  backgroundColor = '#DFDFE1',
  inline = false,
  className,
}: TagProps): React.ReactElement<any> => (
  <TagWrapper className={className} backgroundColor={backgroundColor} inline={inline}>
    <span className='tag__text'>{children}</span>
  </TagWrapper>
);

export default Tag;
