import { getExploreItemsTypes } from './types';

export const exploreActions = {
  getExploreItemsRequest: () => ({
    type: getExploreItemsTypes.REQUEST,
  }),
  getExploreItemsSuccess: (payload: any) => ({
    type: getExploreItemsTypes.SUCCESS,
    payload,
  }),
  getExploreItemsFailed: (payload: string) => ({
    type: getExploreItemsTypes.FAILED,
    payload,
  }),
};
