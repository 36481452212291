import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dashboardActions from 'ducks/dashboard/actions';
import NooderaModal from 'components/NooderaModal';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const JoinedWaitlistModal: React.FC = () => {
  const dashboardAction = useSelector((state) => state.dashboard?.action);
  const dispatch = useDispatch();
  return (
    <NooderaModal.Small
      className='addedCourseWaitlistModalId'
      title={"You've Joined the Waitlist"}
      isOpen={dashboardAction === 'userAddedToWaitlist'}
      icon={<CheckCircleOutlineOutlinedIcon />}
      description="You'll receive an email notification if a spot becomes available for this course"
      btnText='Got it'
      onBtnClick={() => dispatch(dashboardActions.setModalAction(null))}
    />
  );
};

const AlreadyOnWaitlistModal: React.FC = () => {
  const dashboardAction = useSelector((state) => state.dashboard?.action);
  const dispatch = useDispatch();
  return (
    <NooderaModal.Small
      className='addedCourseWaitlistModalId'
      title={"You're Already On the Waitlist"}
      isOpen={dashboardAction === 'userAlreadyOnWaitlist'}
      icon={<CheckCircleOutlineOutlinedIcon />}
      description="You'll receive an email notification if a spot becomes available for this course"
      btnText='Got it'
      onBtnClick={() => dispatch(dashboardActions.setModalAction(null))}
    />
  );
};

export const CourseWaitlistModals: React.FC = () => (
  <>
    <JoinedWaitlistModal />
    <AlreadyOnWaitlistModal />
  </>
);
