import alertTypes from './types';

const initState = null;

const alertReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case alertTypes.SET_ALERT:
      return { ...payload, timeout: 5000 };

    case alertTypes.REMOVE_ALERT:
      return null;

    default:
      return state;
  }
};

export default alertReducer;
