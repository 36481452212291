// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import { paymentConstants } from 'commons/constants/payment';
import api from 'lib/Api';
import { checkForExistingPaymentError } from 'utils/apiHelpers';
import {
  paymentGetStripeClientSecretFailure,
  paymentGetStripeClientSecretForCourseRequest,
  paymentGetStripeClientSecretRequest,
  paymentGetStripeClientSecretSuccess,
  paymentSetUserAlreadyPaid,
} from './reducer';

const { DANGER, SUCCESS } = alertTypesConstants;
type GetStripeClientSecretPayload = {
  payload: Record<string, any>;
};
type GetStripeClientSecretByCoursePayload = {
  payload: string;
};
export function* getStripeClientSecretSaga({ payload }: GetStripeClientSecretPayload): Saga<void> {
  try {
    const response = yield call([api.payment, 'getStripeClientSecret'], payload);
    yield put(paymentGetStripeClientSecretSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: paymentConstants.getStripeClientSecretErrorMessage,
        type: DANGER,
      }),
    );
    yield put(paymentGetStripeClientSecretFailure(error));
  }
}
export function* getStripeClientSecretByCourseSaga({
  payload,
}: GetStripeClientSecretByCoursePayload): Saga<void> {
  try {
    const response = yield call([api.payment, 'getStripeClientSecretByCourse'], payload);
    yield put(paymentGetStripeClientSecretSuccess(response));
  } catch (error) {
    const userAlreadyPaid = checkForExistingPaymentError(error);
    if (userAlreadyPaid) {
      yield put(paymentSetUserAlreadyPaid(true));
      yield put(
        alertActions.setAlert({
          message: paymentConstants.alreadyPaidMessage,
          type: SUCCESS,
        }),
      );
    } else {
      yield put(
        alertActions.setAlert({
          message: paymentConstants.getStripeClientSecretErrorMessage,
          type: DANGER,
        }),
      );
      yield put(paymentGetStripeClientSecretFailure(error));
    }
  }
}
export default function* paymentSaga(): Saga<void> {
  // Tip: We can just return the action creator, TS will infer the type without pulling the `.type` property
  yield takeLatest(paymentGetStripeClientSecretRequest, getStripeClientSecretSaga);
  yield takeLatest(paymentGetStripeClientSecretForCourseRequest, getStripeClientSecretByCourseSaga);
}
