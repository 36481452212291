// @ts-nocheck
import styled from 'styled-components';
import MaterialCard from 'components/MaterialCard';
import type { StatelessFunctionalComponent } from 'react';
import { CELLPHONE_LARGE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from 'commons/constants';
import { getTheme } from 'settings/theme';

const {
  noodera: { spacing, fontTypes },
  palette: { greyscale, neutral, primary },
} = getTheme();

export const FeedTitleContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .post-feed-button': {
    padding: `2px ${spacing.x6} !important`,
    height: 'max-content !important',
  },
}));

export const FeedTitle = styled.span`
  ${fontTypes.font4Medium}
  color: ${neutral.hundred}
`;

export const ActivityContainer = styled(MaterialCard)`
  ${fontTypes.font4};
  margin: ${spacing.x5} 0;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    box-shadow: none;
    &:nth-child(2) {
      border-top: 3px solid #f5f5f5;
    }
    &:not(:first-child) {
      border-bottom: 3px solid #f5f5f5;
    }
  }

  .raf-activity {
    padding: ${spacing.x5} ${spacing.x4};

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      padding: ${spacing.x5};
    }

    & > div {
      padding: 0 !important;
    }

    &__content {
      & > div {
        padding: ${spacing.x7} 0 !important;
        ${fontTypes.font4};
      }

      & .raf-card__url {
        ${fontTypes.font6MediumCaps};
        color: ${primary.hundred};
      }
    }
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    margin: ${spacing.x5} -${spacing.x5};
  }
`;

export const ActivityWrapper = styled.div`
  && {
    padding: ${spacing.x5} ${spacing.x4};
    display: flex;

    & > div:first-child > div:nth-child(2) > div {
      ${fontTypes.font5};
      color: ${neutral.eighty};
      background-color: ${neutral.twenty};
      border-radius: 12px;
      width: fit-content;
      padding: 2px ${spacing.x6};
    }
  }
`;

export const CommentItemWrapper = styled.div`
  && {
    padding: 0 ${spacing.x4};

    p {
      ${fontTypes.font5Medium};
      color: ${neutral.hundred};

      & > span {
        ${fontTypes.font5Medium};
        color: ${primary.hundred};
      }
    }

    small {
      ${fontTypes.font6};
      color: ${neutral.eighty};
    }

    & .raf-flex {
      margin: 0 !important;
    }

    & .raf-comment-item {
      padding: ${spacing.x5} 0;
    }
  }
`;

export const CommentWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    padding: ${spacing.x5} ${spacing.x4};

    & > div {
      margin-top: ${spacing.x5};
    }

    .raf-comment-item {
      padding: 0;
    }

    .raf-comment-field {
      height: 32px;
      margin: 0;

      textarea {
        margin-left: 0;
        padding: 0 ${spacing.x6};
        border: 1px solid ${neutral.forty};
        border-radius: 8px 0 0 8px;
        background-color: ${greyscale.white};
      }

      button {
        background: ${(props) => props.theme.gradients.noodera};
        border-radius: 0 ${spacing.x7} ${spacing.x7} 0;
      }

      .raf-textarea__textarea::placeholder {
        color: ${neutral.seventy};
      }
    }
  }
`;

export const FeedContainer = styled.div`
  padding: 0 ${spacing.x3} ${spacing.x3};
  flex: 1;
  width: 100%;
  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    padding: ${spacing.x4};
  }
  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${spacing.x5};
  }
`;
export const LoadMoreButtonHolder = styled.div`
  margin-top: ${spacing.x5};
`;

export const TimeWrapper = styled.div`
  && {
    ${fontTypes.font5};
    color: ${neutral.eighty};
  }
`;
