import type { IsMatchRegexParams, ValidationFieldError } from './Validation.types';

const isMatchRegex = ({
  name,
  value,
  outputName = 'field',
  regex,
  help,
}: IsMatchRegexParams): ValidationFieldError => {
  const errors = {};
  const parsedHelp = help ? ` - (${help})` : '';
  errors[name] = !regex.test(value)
    ? {
        errorMsg: `Not a valid ${outputName}${parsedHelp}`,
      }
    : {};
  return errors;
};

export default isMatchRegex;
