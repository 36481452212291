// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import type {
  PartnerProgramRequestInfoPayload,
  PartnerProgramObject,
  RequestedProgramsObject,
  SorterOption,
  GetPartnerProgramsRequest,
} from 'commons/types';
import {
  getProgramsTypes,
  getProgramGroupsTypes,
  requestInfoTypes,
  getPartnerProgramsTypes,
  getSavedProgramsTypes,
} from './types';
import { partnerProgramsActions } from './actions';

const { DANGER, SUCCESS } = alertTypesConstants;

const requestedPrograms = (selectedPrograms: PartnerProgramObject[]): RequestedProgramsObject => {
  const requestedProgramsObject = {};
  selectedPrograms.forEach((p) => {
    requestedProgramsObject[p.id] = true;
  });
  return requestedProgramsObject;
};

export function* getProgramsSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const programs = yield call([api.partnerPrograms, 'getPrograms'], payload);
    yield put(partnerProgramsActions.getProgramsSuccess(programs));
  } catch (error) {
    const message = 'There was an error fetching the programs data.';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(partnerProgramsActions.getProgramsFailed(error));
  }
}
export function* getPartnerProgramsSaga({
  payload,
}: {
  payload?: GetPartnerProgramsRequest;
}): Saga<void> {
  try {
    const programs = yield call([api.partnerPrograms, 'getPartnerPrograms'], payload);
    yield put(partnerProgramsActions.getPartnerProgramsSuccess(programs));
  } catch (error) {
    const message = 'There was an error fetching the partner programs.';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(partnerProgramsActions.getPartnerProgramsFailure(error));
  }
}
export function* getProgramGroupsSaga({ payload }: { payload: SorterOption[] }): Saga<void> {
  try {
    const programGroups = yield call([api.partnerPrograms, 'getProgramGroups'], payload);
    yield put(partnerProgramsActions.getProgramGroupsSuccess(programGroups));
  } catch (error) {
    const message = 'There was an error fetching the programs data.';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(partnerProgramsActions.getProgramGroupsFailed(error));
  }
}
export function* requestInfoSaga({
  payload,
}: {
  payload: PartnerProgramRequestInfoPayload;
}): Saga<void> {
  try {
    const { selectedPrograms, userInfo } = payload;
    const apiPayload = {
      ...userInfo,
      programs: selectedPrograms.map(({ programId }) => programId),
    };
    const message = 'Information successfully sent';
    yield call([api.partnerPrograms, 'requestInfo'], apiPayload);
    yield put(partnerProgramsActions.requestInfoSuccess(requestedPrograms(selectedPrograms)));
    yield put(
      alertActions.setAlert({
        message,
        type: SUCCESS,
      }),
    );
  } catch (error) {
    const message = 'There was an error sending the information.';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(partnerProgramsActions.requestInfoFailed(error));
  }
}
export function* getSavedProgramsSaga(): Saga<void> {
  try {
    const response = yield call([api.partnerPrograms, 'getSavedPrograms']);
    yield put(partnerProgramsActions.getSavedProgramsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching saved programs',
        type: DANGER,
      }),
    );
    yield put(partnerProgramsActions.getSavedProgramsFailure(error));
  }
}
export default function* partnerProgramsSaga(): Saga<void> {
  yield takeLatest(getProgramsTypes.REQUEST, getProgramsSaga);
  yield takeLatest(getPartnerProgramsTypes.REQUEST, getPartnerProgramsSaga);
  yield takeLatest(getProgramGroupsTypes.REQUEST, getProgramGroupsSaga);
  yield takeLatest(requestInfoTypes.REQUEST, requestInfoSaga);
  yield takeLatest(getSavedProgramsTypes.GET_SAVED_PROGRAMS_REQUEST, getSavedProgramsSaga);
}
