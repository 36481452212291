export const getProgramsTypes = {
  REQUEST: 'GET_PROGRAMS_REQUEST',
  SUCCESS: 'GET_PROGRAMS_SUCCESS',
  FAILED: 'GET_PROGRAMS_FAILED',
};
export const getPartnerProgramsTypes = {
  REQUEST: 'GET_PARTNER_PROGRAMS_REQUEST',
  SUCCESS: 'GET_PARTNER_PROGRAMS_SUCCESS',
  FAILURE: 'GET_PARTNER_PROGRAMS_FAILURE',
};
export const getProgramGroupsTypes = {
  REQUEST: 'GET_PROGRAM_GROUPS_REQUEST',
  SUCCESS: 'GET_PROGRAM_GROUPS_SUCCESS',
  FAILED: 'GET_PROGRAM_GROUPS_FAILED',
};
export const requestInfoTypes = {
  REQUEST: 'REQUEST_INFO_REQUEST',
  SUCCESS: 'REQUEST_INFO_SUCCESS',
  FAILED: 'REQUEST_INFO_FAILED',
  TOGGLE_MODAL: 'REQUEST_INFO_TOGGLE_MODAL',
};
export const selectProgramTypes = {
  SELECT: 'SELECT_PROGRAM',
  RESET: 'RESET_PROGRAM_LIST',
};
export const updateSorterOptionsTypes = {
  UPDATE: 'SORTER_OPTIONS_UPDATE',
};
export const programTypes = {
  SET_PROGRAMS: 'SET_PROGRAMS',
};
export const getSavedProgramsTypes = {
  GET_SAVED_PROGRAMS_REQUEST: 'GET_SAVED_PROGRAMS_REQUEST',
  GET_SAVED_PROGRAMS_SUCCESS: 'GET_SAVED_PROGRAMS_SUCCESS',
  GET_SAVED_PROGRAMS_FAILURE: 'GET_SAVED_PROGRAMS_FAILURE',
};
