import { getExploreItemsTypes } from './types';

const initialState = {
  isLoading: false,
  error: '',
  data: [],
};

const getOrderedExploreItems = (payload) =>
  payload
    .map((exploreItem) => {
      if (exploreItem.programTypes) {
        exploreItem.programTypes.sort((a, b) => a.title.localeCompare(b.title));
        return exploreItem;
      }

      return exploreItem;
    })
    .sort((a, b) => a.title.localeCompare(b.title));

const exploreReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getExploreItemsTypes.REQUEST: {
      return { ...state, isLoading: true, error: '', data: [] };
    }

    case getExploreItemsTypes.SUCCESS: {
      return { ...state, data: getOrderedExploreItems(payload), isLoading: false };
    }

    case getExploreItemsTypes.FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    default:
      return state;
  }
};

export default exploreReducer;
