const getContentTypeDisplayName = (contentType: string): string => {
  switch (contentType) {
    case 'team':
      return 'Teams';

    case 'school':
      return 'Schools';

    case 'area_of_interest':
      return 'Areas of Interest';

    case 'counselor':
      return 'Counselors';

    case 'program_type':
      return 'Programs';

    case 'course':
      return 'Courses';

    case 'partner_programs':
      return 'Degree Programs';

    default:
      return 'More';
  }
};

export default getContentTypeDisplayName;
