// @ts-nocheck
import {
  getProgramsTypes,
  getProgramGroupsTypes,
  requestInfoTypes,
  selectProgramTypes,
  updateSorterOptionsTypes,
  programTypes,
  getPartnerProgramsTypes,
  getSavedProgramsTypes,
} from './types';

const initialState = {
  isLoading: false,
  programGroups: [],
  requestInfo: {
    requestedPrograms: {},
  },
  selectedPrograms: {},
  partnerPrograms: {
    isLoading: false,
    data: null,
    error: '',
  },
  savedPrograms: {
    isLoading: false,
    data: [],
    error: '',
  },
};

const partnerProgramsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getProgramsTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getProgramsTypes.SUCCESS: {
      return { ...state, isLoading: false, programs: payload };
    }

    case getProgramsTypes.FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case getPartnerProgramsTypes.REQUEST: {
      return {
        ...state,
        partnerPrograms: {
          isLoading: true,
        },
      };
    }

    case getPartnerProgramsTypes.SUCCESS: {
      return {
        ...state,
        partnerPrograms: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getPartnerProgramsTypes.FAILURE: {
      return {
        ...state,
        partnerPrograms: {
          isLoading: false,
          error: payload,
        },
      };
    }

    case getProgramGroupsTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getProgramGroupsTypes.SUCCESS: {
      return {
        ...state,
        programGroups: payload?.programGroups,
        // Don't keep resetting sorterOptions when we already have it.
        sorterOptions: !state.sorterOptions?.length ? payload?.sorterOptions : state.sorterOptions,
        isLoading: false,
      };
    }

    case getProgramGroupsTypes.FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case requestInfoTypes.REQUEST: {
      return {
        ...state,
        requestInfo: { ...state.requestInfo, isModalOpen: false, isRequesting: true },
      };
    }

    case requestInfoTypes.SUCCESS: {
      return {
        ...state,
        requestInfo: {
          ...state.requestInfo,
          isRequesting: false,
          requestedPrograms: { ...state.requestInfo.requestedPrograms, ...payload },
        },
        selectedPrograms: {},
      };
    }

    case requestInfoTypes.FAILED: {
      return {
        ...state,
        requestInfo: { ...state.requestInfo, isRequesting: false },
        error: payload,
      };
    }

    case requestInfoTypes.TOGGLE_MODAL: {
      return {
        ...state,
        requestInfo: {
          isModalOpen: !state.requestInfo.isModalOpen,
        },
      };
    }

    case selectProgramTypes.SELECT: {
      const { selectedPrograms } = state;
      return { ...state, selectedPrograms: { ...selectedPrograms, [payload.id]: payload } };
    }

    case selectProgramTypes.RESET: {
      return { ...state, selectedPrograms: {} };
    }

    case updateSorterOptionsTypes.UPDATE: {
      return { ...state, sorterOptions: payload };
    }

    case programTypes.SET_PROGRAMS: {
      return { ...state, programs: payload };
    }

    case getSavedProgramsTypes.GET_SAVED_PROGRAMS_REQUEST: {
      return { ...state, savedPrograms: { ...initialState.savedPrograms, isLoading: true } };
    }

    case getSavedProgramsTypes.GET_SAVED_PROGRAMS_SUCCESS: {
      return {
        ...state,
        savedPrograms: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getSavedProgramsTypes.GET_SAVED_PROGRAMS_FAILURE: {
      return { ...state, savedPrograms: { ...initialState.savedPrograms, error: payload } };
    }

    default:
      return state;
  }
};

export default partnerProgramsReducer;
