// @ts-nocheck
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertTypesConstants } from 'commons/constants';
import type { FormAlert, PageAlert } from 'commons/types';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CloseIcon from '@mui/icons-material/Close';
import authActions from 'ducks/auth/actions';
import { NAlert, NAlertFixed, NAlertClose } from './NoodleToast.styles';
import type { NoodleToastFixedProps } from './NoodleToast.types';

const getIcon = (name: string): React.ReactElement<any> | string => {
  switch (name) {
    case alertTypesConstants.SUCCESS:
      return <CheckIcon />;

    case alertTypesConstants.WARNING:
      return <WarningIcon />;

    case alertTypesConstants.DANGER:
      return <ErrorIcon />;

    default:
      return <NotificationsNoneIcon />;
  }
};

export const NoodleToast = ({ type, children }: FormAlert) => {
  const dispatch = useDispatch();
  const alertMsg = useSelector((state) => state.auth.message.text);

  const handleClose = () => {
    dispatch(authActions.loginFailed(null));
  };

  return alertMsg || children ? (
    <NAlert type={type}>
      {getIcon(type)}
      <div>
        <span>{children}</span>
        {type === 'danger' ? <CloseIcon onClick={handleClose} /> : null}
      </div>
    </NAlert>
  ) : null;
};
export class NoodleToastFixed extends React.Component<NoodleToastFixedProps> {
  componentDidMount() {
    const {
      alert: { timeout },
      onCloseAlert,
    } = this.props;

    if (onCloseAlert) {
      this.timeoutId = setTimeout(() => onCloseAlert(), timeout);
    }
  }

  componentDidUpdate(prevProps: PageAlert) {
    const {
      alert: { message, timeout },
      onCloseAlert,
    } = this.props;
    const {
      alert: { message: msg },
    } = prevProps;

    if (msg !== message) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => onCloseAlert(), timeout);
    }
  }

  componentWillUnmount() {
    const { onCloseAlert } = this.props;
    onCloseAlert();
    clearTimeout(this.timeoutId);
  }

  timeoutId: any;

  render() {
    const {
      alert: { message, type },
      onCloseAlert,
    } = this.props;
    return (
      <NAlertFixed type={type}>
        {getIcon(type)}
        <span className='alert__text'>{message}</span>
        <NAlertClose onClick={() => onCloseAlert()}>X</NAlertClose>
      </NAlertFixed>
    );
  }
}
