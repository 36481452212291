// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import { TABLET_SMALL_WIDTH } from 'commons/constants';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale },
  fontColors,
  spacing,
} = getTheme();
const NavbarWrapper: StatelessFunctionalComponent<any> = styled.nav`
  background-color: ${greyscale.white};
  height: 6.4rem;
  padding: 1rem 0;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;

  .navbar {
    &__content {
      padding: 0 ${spacing.x3};
    }

    &__search-mobile-link {
      & a {
        color: ${fontColors.primary};
      }

      & i {
        font-size: 2.4rem;
      }
    }

    &__menu {
      &__container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        float: right;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      & > div {
        align-self: center;
      }
    }

    &__icon {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      min-width: 30px;
      outline-style: none;
    }

    &__logo {
      width: 4rem;
    }
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding-left: ${spacing.x4};
    padding-right: ${spacing.x4};

    .navbar {
      &__content {
        padding: 0;
      }
    }
  }
`;
export default NavbarWrapper;
