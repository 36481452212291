import type {
  GroupObject,
  JoinGroupPayload,
  PostType,
  GroupPostPayloadType,
  GetGroupsRequestPayload,
  SetCurrentPagePayload,
} from 'commons/types';
import {
  getGroupByIdTypes,
  getGroupsTypes,
  groupsCurrentPageTypes,
  joinGroupTypes,
  leaveGroupTypes,
  postGroupTypes,
  getGroupPostsTypes,
} from './types';

const buildFeedPayload = (feed) => {
  const [lastPost] = feed;
  return {
    type: getGroupPostsTypes.SUCCESS,
    payload: {
      lastUpdate: lastPost && lastPost.updatedAt,
      feed,
    },
  };
};

export const groupsActions = {
  setCurrentPage: (payload: SetCurrentPagePayload) => ({
    type: groupsCurrentPageTypes.SET_CURRENT_PAGE,
    payload,
  }),
  getGroupsRequest: (payload: GetGroupsRequestPayload) => ({
    type: getGroupsTypes.REQUEST,
    payload,
  }),
  getGroupsSuccess: (payload: string) => ({
    type: getGroupsTypes.SUCCESS,
    payload,
  }),
  getGroupsFailed: (payload: string) => ({
    type: getGroupsTypes.FAILED,
    payload,
  }),
  getGroupByIdRequest: (payload: number) => ({
    type: getGroupByIdTypes.REQUEST,
    payload,
  }),
  getGroupByIdSuccess: (payload: GroupObject) => ({
    type: getGroupByIdTypes.SUCCESS,
    payload,
  }),
  getGroupByIdFailed: (payload: string) => ({
    type: getGroupByIdTypes.FAILED,
    payload,
  }),
};
export const postGroupActions = {
  postGroupRequest: (payload: {
    id: number;
    message: string;
    lastUpdate?: string;
  }): {
    type: string;
    payload: {
      postGroup: GroupPostPayloadType;
      lastUpdate?: string;
    };
  } => ({
    type: postGroupTypes.REQUEST,
    payload: {
      postGroup: {
        groupId: payload.id,
        content: {
          body: payload.message,
        },
      },
      lastUpdate: payload.lastUpdate,
    },
  }),
  postGroupSuccess: (
    feed: Array<PostType>,
  ): {
    type: string;
    payload: {
      feed: Array<PostType>;
      lastUpdate: string;
    };
  } => buildFeedPayload(feed),
  postGroupFail: (): {
    type: string;
  } => ({
    type: postGroupTypes.FAILED,
  }),
  getGroupPostsRequest: (
    groupId: number,
  ): {
    type: string;
    payload: {
      groupId: number;
    };
  } => ({
    type: getGroupPostsTypes.REQUEST,
    payload: {
      groupId,
    },
  }),
  getGroupPostsSuccess: (
    feed: Array<PostType>,
  ): {
    type: string;
    payload: {
      feed: Array<PostType>;
      lastUpdate: string;
    };
  } => buildFeedPayload(feed),
  getGroupPostsFail: (): {
    type: string;
  } => ({
    type: getGroupPostsTypes.FAILED,
  }),
};
export const groupJoinLeaveActions = {
  joinGroupRequest: (payload: JoinGroupPayload) => ({
    type: joinGroupTypes.REQUEST,
    payload,
  }),
  joinGroupSuccess: (groupId: number) => ({
    type: joinGroupTypes.SUCCESS,
    payload: groupId,
  }),
  joinGroupFailed: (payload: string) => ({
    type: joinGroupTypes.FAILED,
    payload,
  }),
  leaveGroupRequest: (payload: JoinGroupPayload) => ({
    type: leaveGroupTypes.REQUEST,
    payload,
  }),
  leaveGroupSuccess: (groupId: number) => ({
    type: leaveGroupTypes.SUCCESS,
    payload: groupId,
  }),
  leaveGroupFailed: (payload: string) => ({
    type: leaveGroupTypes.FAILED,
    payload,
  }),
};
