// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import { CELLPHONE_WIDTH, CELLPHONE_SMALL_WIDTH } from 'commons/constants';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale, noodle },
} = getTheme();
export const ImagePickerWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  min-height: 20rem;

  .no-image-container {
    width: 100%;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem 0;
      cursor: pointer;
    }

    &__placeholder-image {
      width: 7.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .article-cover {
    margin: 0 0 5rem 0;
    width: 100%;
    height: 31.4rem;
    position: relative;

    &__image {
      width: 100%;
      height: 31.4rem;
      border-radius: 0.8rem;
      object-fit: cover;
    }

    &__delete-cover-container {
      border: 0.8rem ${noodle.secondary} solid;
      border-radius: 0.8rem;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__delete-cover-button {
      color: ${greyscale.white};
      background-color: ${greyscale.smoke};
      border-radius: 0.8rem;
      width: 14.5rem;
      height: 4.5rem;
      position: absolute;
      bottom: -6rem;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      padding: 0 1rem;

      .icon-close_clear {
        color: ${greyscale.white};
        font-size: 2rem;
      }
    }
  }

  @media only screen and (max-width: ${CELLPHONE_WIDTH}px) {
    .article-cover {
      width: 37.4rem;
      height: 19.64rem;

      &__image {
        height: 19.64rem;
      }
    }
  }

  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    .article-cover {
      width: 28rem;
      height: 14.7rem;

      &__image {
        height: 14.7rem;
      }
    }
  }
`;
export const ImagePickerContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;

  .dialog {
    padding: 1.5rem 0;

    &__input-container {
      align-items: center;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border: 0.1rem ${greyscale.silver} solid;
      border-radius: 0.6rem;
      padding: 0.3rem 1rem;

      .input-icon {
        height: 1.8rem;
      }
    }

    &__input {
      border: none;
      width: 100%;
      margin-left: 0.6rem;
    }

    &__add-container {
      margin-left: 1.5rem;
    }

    &__no-results-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }

    &__loader-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 45rem;
    }

    .icon-tasks {
      color: ${noodle.secondary};
      font-size: 4rem;
    }
  }

  @media only screen and (max-width: ${CELLPHONE_WIDTH}px) {
    .dialog {
      &__input {
        font-size: 1.6rem;
      }

      &__result-img {
        width: 10rem;
        height: 10rem;
      }

      &__result-img-selected-container {
        width: 10rem;
        height: 10rem;
      }
    }
  }

  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    .dialog {
      &__result-img {
        width: 8rem;
        height: 8rem;
      }

      &__result-img-selected-container {
        width: 8rem;
        height: 8rem;
      }
    }
  }
`;
