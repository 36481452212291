import validateField from './validate-field';
import type { ValidationFieldError } from './Validation.types';
import type { FormStructure, FormValidatorObject } from '../form/Form.types';

const validateForm = (
  form: FormStructure,
  formValidators: FormValidatorObject,
): ValidationFieldError => {
  let errors = {};
  const fields = Object.keys(form.errors);
  fields.forEach((field) => {
    const { [field]: value } = form;
    const { [field]: validators } = formValidators;
    errors = { ...errors, [field]: validateField(field, value, validators)[field] };
  });
  return errors;
};

export default validateForm;
