import * as React from 'react';
import { StyledTitle, TeamImageContainer, StyledImagePicker } from './TeamImage.styles';
import type { TeamImageProps } from './TeamImage.types';

const TeamImage = ({ onChange, data }: TeamImageProps): React.ReactElement<any> | null => {
  const handleSelectImage = (mediaObj: any): void => {
    onChange(mediaObj);
  };

  return (
    <TeamImageContainer>
      <StyledTitle>Select Team Image</StyledTitle>
      <StyledImagePicker
        placeholder="Search for your team's image"
        onSelectImage={handleSelectImage}
        selectedPhoto={data}
      />
    </TeamImageContainer>
  );
};

export default TeamImage;
