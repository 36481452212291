// @ts-nocheck
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BellNotificationIcon } from 'assets/svg/bell_notification.svg';
import { Container, Badge } from './Bell.style';
import type { BellProps } from './Bell.type';

const Notifications = 'Notifications';

const Bell = ({ count }: BellProps): React.ReactElement<any> => (
  <Link
    aria-label={Notifications}
    title={Notifications}
    to={{
      pathname: '/notifications',
      state: {
        prevPath: window.location.pathname,
      },
    }}
  >
    <Container>
      {Boolean(count) && <Badge>{count}</Badge>}
      <BellNotificationIcon />
    </Container>
  </Link>
);

export default Bell;
