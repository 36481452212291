// @ts-nocheck
import * as React from 'react';
import type { NooderaSwiperProps } from './NooderaSwiper.types';
import { NavigationArrow, NooderaSwiperContainer, StyledSwiper } from './NooderaSwiper.styles';

const NooderaSwiper = ({
  id,
  children,
  showArrows,
  className,
  breakpoints,
  ...otherProps
}: NooderaSwiperProps): React.ReactElement<any> => {
  const swiperRef = React.useRef(null);

  const renderArrow = (direction: string): React.ReactElement<any> | null => {
    if (!showArrows) {
      return null;
    }

    if (direction === 'left') {
      return (
        <NavigationArrow
          icon='icon-arrow_left'
          onClick={() => swiperRef?.current?.swiper?.slidePrev()}
        />
      );
    }

    return (
      <NavigationArrow
        icon='icon-arrow_right'
        onClick={() => swiperRef?.current?.swiper?.slideNext()}
      />
    );
  };

  return (
    <NooderaSwiperContainer id={id} className={className}>
      {renderArrow('left')}
      <StyledSwiper ref={swiperRef} breakpoints={breakpoints} {...otherProps}>
        {children}
      </StyledSwiper>
      {renderArrow('right')}
    </NooderaSwiperContainer>
  );
};

export default NooderaSwiper;
