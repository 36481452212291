import * as React from 'react';
import type { IconProps } from './Icon.types';

const Icon = ({ icon, fontSize, color, className = '', onClick }: IconProps) =>
  onClick ? (
    <i
      className={`${icon} ${className}`}
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
      role='button'
      style={{
        fontSize,
        color,
      }}
    />
  ) : (
    <i
      className={`${icon} ${className}`}
      style={{
        fontSize,
        color,
      }}
    />
  );

export default Icon;
