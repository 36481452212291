// @ts-nocheck
import * as React from 'react';
import { Link } from 'react-router-dom';
import isotype from 'assets/images/isotype.svg';
// import { images } from 'settings/';
// import Avatar from 'components/uielements/Avatar';
import Navbar from 'components/Navbar';
// import Messages from 'components/Navbar/NavbarSignedIn/Menu/Messages';
// import UserMenu from 'components/Navbar/NavbarSignedIn/UserMenu';
import SearchBarTopNav from 'components/SearchBarTopNav';
import HamburgerMenu from 'components/HamburgerMenu';
import type { DesktopMenuProps } from './DesktopMenu.types';

const DesktopMenu = ({ onInputChange, onSearch, onCleanSearch, search }: DesktopMenuProps) => {
  const renderNavActions = (): React.ReactElement<any> => (
    <>
      <Navbar.Center className='navbar__desktop'>
        <SearchBarTopNav
          autoFocus
          onInputChange={onInputChange}
          onSearch={onSearch}
          onCleanSearch={onCleanSearch}
          search={search}
        />
      </Navbar.Center>
      <Navbar.Right className='navbar__desktop'>
        <div className='navbar__menu__container'>
          <HamburgerMenu />
        </div>
      </Navbar.Right>
    </>
  );

  return (
    <div className='navbar__container'>
      <Navbar.Left>
        <Link to='/'>
          <img
            src={isotype}
            className='navbar__logo'
            alt='Noodle Logo'
            aria-label='Noodle Logo'
            title='Noodle Logo'
            loading='lazy'
            width='100%'
            height='100%'
          />
        </Link>
      </Navbar.Left>
      {renderNavActions()}
    </div>
  );
};

export default DesktopMenu;
