import * as React from 'react';
import Text from 'components/uielements/Text';
import LoadingBar from 'components/uielements/LoadingBar';
import type {
  LoadingBarsProps,
  LoadingBarTitleObject,
} from 'components/LoadingBars/LoadingBars.types';
import { LoadingBarsContainer, LoadingBarsWrapper } from './LoadingBars.style';

const renderBars = (
  titles: LoadingBarTitleObject[],
  fontSize: number,
  transitionDuration: number,
): React.ReactElement<any>[] =>
  titles.map((title: LoadingBarTitleObject, idx: number) => (
    <LoadingBarsContainer key={title.title}>
      <Text size={fontSize}>{title.title}</Text>
      <LoadingBar
        index={idx}
        percentage={title.percentage}
        transitionDuration={transitionDuration}
      />
    </LoadingBarsContainer>
  ));

const LoadingBars = ({
  titles,
  fontSize,
  transitionDuration,
}: LoadingBarsProps): React.ReactElement<any> => (
  <LoadingBarsWrapper>{renderBars(titles, fontSize, transitionDuration)}</LoadingBarsWrapper>
);

export default LoadingBars;
