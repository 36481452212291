// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';
import { getTheme } from 'settings/theme/index';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

const {
  palette: { neutral, greyscale, primary },
  noodera: { fontTypes, spacing },
} = getTheme();
export const StyledAppBar: StatelessFunctionalComponent<any> = styled(AppBar)`
  && {
    height: 56px;
    box-shadow: 0 1px 0 ${neutral.twenty};
    background-color: ${greyscale.white};

    & > .MuiToolbar-root {
      min-height: 56px;
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      background-color: ${primary.hundred};
      padding: 0;
      box-shadow: none;

      & .MuiButtonBase-root {
        border: 1px solid ${greyscale.white};
      }

      & > .MuiToolbar-root {
        min-height: 48px;
        padding: ${spacing.x6};
      }
    }
  }
`;
export const StyledTitle: StatelessFunctionalComponent<any> = styled(Typography)`
  && {
    flex: 1;
    ${fontTypes.font3Medium};
    color: ${neutral.hundred};

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      ${fontTypes.font4Medium};
      color: ${greyscale.white};
    }
  }
`;
