// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale, noodle },
} = getTheme();
const NavbarSignedInWrapper: StatelessFunctionalComponent<any> = styled.div`
  .center {
    align-self: center;
  }

  .icon {
    color: ${noodle.primary};
    cursor: pointer;
    font-size: 2.7rem;
    outline-style: none;

    &--responsive {
      color: ${greyscale.white};
    }
  }

  .badge {
    align-self: flex-start;
    margin: 0.5rem 0.5rem 0 -0.5rem;
  }

  .iconAndMenuContainer {
    margin-left: 3rem;
    flex-direction: column;
    cursor: pointer;
  }

  .marginVertical {
    margin: 0.5rem 0;
  }
`;
export default NavbarSignedInWrapper;
