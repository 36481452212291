// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';
import {
  TABLET_SMALL_WIDTH,
  TABLET_WIDTH,
  CELLPHONE_LARGE_WIDTH,
  CELLPHONE_SMALL_WIDTH,
} from 'commons/constants/size';

export const GuestHomePrivateLabelWrapper: StatelessFunctionalComponent<any> = styled.div`
  overflow-y: auto;
`;
export const GuestHomePrivateLabelHeroSection: StatelessFunctionalComponent<any> = styled.div`
  background-color: ${(props) => props.theme.palette.primary.hundredTwenty};
  overflow-y: auto;
  height: auto;
  margin-top: ${({ theme }) => theme.noodera.spacing.x2};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    min-height: 373px;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 100%;
    overflow-x: hidden;
    padding: ${({ theme }) => theme.noodera.spacing.x5};
    min-height: 400px;
  }
`;
export const GuestHeroSectionContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.noodera.spacing.x5};
  flex-grow: 1;
  min-height: 373px;
  max-width: 1024px;

  & > * {
    display: flex;
    flex-grow: 1;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding-left: ${({ theme }) => theme.noodera.spacing.x5};
    padding-right: ${({ theme }) => theme.noodera.spacing.x5};
  }

  @media only screen and (max-width: 1024px) {
    padding: 0;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    padding-left: ${({ theme }) => theme.noodera.spacing.x7};
    padding-right: ${({ theme }) => theme.noodera.spacing.x7};
  }
`;

export const GuestHomeHeroSvgWrapper: StatelessFunctionalComponent<any> = styled(SvgImageWrapper)`
  & svg path {
    stroke: ${(props) => props.theme.palette.greyscale.white};
  }
  & svg circle {
    fill: ${(props) => props.theme.palette.primary.eighty};
  }
  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    padding-left: ${({ theme }) => theme.noodera.spacing.x5};
    padding-right: ${({ theme }) => theme.noodera.spacing.x5};
  }
`;
export const LeftHeroContent: StatelessFunctionalComponent<any> = styled.div`
  flex-direction: column;
  max-width: 512px;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    justify-content: center;
    align-items: center;
  }
`;
export const BannerTitle: StatelessFunctionalComponent<any> = styled.h1`
  ${({ theme }) => theme.fontTypes.font2Medium};
  color: ${({ theme }) => theme.palette.greyscale.white};
  margin: 0 0 ${({ theme }) => theme.noodera.spacing.x5};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin: 0;
    padding-right: ${({ theme }) => theme.noodera.spacing.x3};
  }
  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    margin: 0;
    padding: 0 ${({ theme }) => theme.noodera.spacing.x3} 0;
  }
`;
export const HeroSection: StatelessFunctionalComponent<any> = styled.div`
  padding: ${({ theme }) => theme.noodera.spacing.x5};

  @media only screen and (max-width: ${CELLPHONE_SMALL_WIDTH}px) {
    padding: ${({ theme }) => theme.noodera.spacing.x3} 0;
  }
`;
export const GuestHomeSvgWrapper: StatelessFunctionalComponent<any> = styled(SvgImageWrapper)`
  & svg path {
    stroke: ${(props) => props.theme.palette.primary.eighty};
  }
  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-top: 0rem;
  }
`;
export const FirstBlockSection: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${({ theme }) => theme.noodera.spacing.x4};
  margin-top: ${({ theme }) => theme.noodera.spacing.x5};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x2};
  padding: ${({ theme }) => theme.noodera.spacing.x5} ${({ theme }) => theme.noodera.spacing.x3};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.noodera.spacing.x7} ${({ theme }) => theme.noodera.spacing.x5};
    margin-bottom: ${({ theme }) => theme.noodera.spacing.x1};
  }
`;
export const SectionTitle: StatelessFunctionalComponent<any> = styled.h2`
  ${({ theme }) => theme.noodera.fontTypes.font3Medium};
  color: ${({ theme }) => theme.palette.neutral.hundred};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x4};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    text-align: center;
  }
`;
export const SectionSubtitle: StatelessFunctionalComponent<any> = styled.h3`
  ${({ theme }) => theme.noodera.fontTypes.font3};
  color: ${({ theme }) => theme.palette.neutral.hundred};
  margin: 0 0 ${({ theme }) => theme.noodera.spacing.x4};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    text-align: center;
    padding: ${({ theme }) => theme.noodera.spacing.x5};
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    padding: 0;
  }
`;
export const CourseSectionWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.greyscale.white};
  width: 100%;
  padding: ${({ theme }) => theme.noodera.spacing.x5} ${({ theme }) => theme.noodera.spacing.x3};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    text-align: left;
    padding: ${({ theme }) => theme.noodera.spacing.x5};
  }
`;
export const CourseSectionContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.noodera.spacing.x5};
  margin-top: ${({ theme }) => theme.noodera.spacing.x6};
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x4};
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
  }
`;
export const CourseCardContainer: StatelessFunctionalComponent<any> = styled.div`
  max-width: 32rem;
  width: 100%;
  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    max-width: 40rem;
  }
`;
