import * as React from 'react';
import Icon from 'components/uielements/Icon';
import ScrollSliderWrapper from './ScrollSlider.style';
import type { ScrollSliderProps } from './ScrollSlider.types';

const SCROLL_INCREMENT = 200;

const ScrollSlider = ({ children, className }: ScrollSliderProps) => {
  const [containerRef, setContainerRef] = React.useState(null);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  // Doesn't refresh if using containerRef as dependency.
  // Probably cuz it doesn't do a deep assertion sooo... leaving as is
  // eslint-disable-next-line
  React.useEffect(() => {
    if (containerRef && containerRef.scrollWidth) {
      setScrollWidth(containerRef.scrollWidth);
    }
  });
  const onRefChange = React.useCallback((node: HTMLElement | null) => {
    setContainerRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const handleIconClick = (direction: string): void => {
    if (!containerRef) {
      return;
    }

    const { scrollLeft } = containerRef;

    switch (direction) {
      case 'left':
        containerRef.scrollLeft = scrollLeft - SCROLL_INCREMENT;
        break;

      case 'right':
        containerRef.scrollLeft = scrollLeft + SCROLL_INCREMENT;
        break;

      default:
        break;
    }
  };

  const renderNavBtn = (
    direction: string,
  ): (React.ReactElement<any> | null | undefined) | (null | null | undefined) => {
    if (containerRef && scrollWidth > containerRef.clientWidth) {
      switch (direction) {
        case 'left':
          return (
            <div
              tabIndex={0}
              role='button'
              className='scroll-slider__navigation-icon-left'
              onKeyPress={() => handleIconClick('left')}
              onClick={() => handleIconClick('left')}
            >
              <Icon icon='icon-arrow_left' />
            </div>
          );

        case 'right':
          return (
            <div
              tabIndex={0}
              role='button'
              className='scroll-slider__navigation-icon-right'
              onKeyPress={() => handleIconClick('right')}
              onClick={() => handleIconClick('right')}
            >
              <Icon icon='icon-arrow_right' />
            </div>
          );

        default:
          break;
      }
    }

    return null;
  };

  return (
    <ScrollSliderWrapper className={className}>
      {renderNavBtn('left')}
      <div className='scroll-slider__container' ref={onRefChange}>
        <div className='scroll-slider__children'>{children}</div>
      </div>
      {renderNavBtn('right')}
    </ScrollSliderWrapper>
  );
};

export default ScrollSlider;
