import type { ProductData, ProductState } from 'commons/types/productTypes';
import { getProductTypes } from './types';

export const initialState: ProductState = {
  product: {
    isLoading: false,
    data: [],
    error: '',
  },
};
type Action = {
  type: string;
  payload?: ProductData[];
};

const productReducer = (state: ProductState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getProductTypes.GET_PRODUCTS_REQUEST: {
      return { ...state, product: { ...initialState.product, isLoading: true } };
    }

    case getProductTypes.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        product: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getProductTypes.GET_PRODUCTS_FAILURE: {
      return { ...state, product: { ...initialState.product, error: payload } };
    }

    default:
      return state;
  }
};

export default productReducer;
