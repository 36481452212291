// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme';

const { fontTypes } = getTheme();
const TagWrapper: StatelessFunctionalComponent<any> = styled.div`
  background: ${(props) => props.backgroundColor};
  padding: 0.3rem 0.4rem;
  border-radius: 0.6rem;
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  .tag {
    &__text {
      ${fontTypes.font5};
    }
  }
`;
export default TagWrapper;
