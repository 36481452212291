import { loginTypes, socialTypes } from 'ducks/user/types';
import { modalTypes } from './types';

const initialState = {
  activeView: null,
  action: null,
  isSelectedTeamModalOpen: false,
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case socialTypes.SUCCESS:
    case loginTypes.SUCCESS: {
      return { ...state, activeView: payload.activeView };
    }

    case modalTypes.SELECTED_TEAM_MODAL_OPEN: {
      return { ...state, isSelectedTeamModalOpen: payload };
    }

    case modalTypes.SET_MODAL_ACTION: {
      return { ...state, action: payload };
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
