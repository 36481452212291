import { modalTypes } from './types';

const dashboardActions = {
  selectTeamModalOpen: (payload: boolean) => ({
    type: modalTypes.SELECTED_TEAM_MODAL_OPEN,
    payload,
  }),
  setModalAction: (payload: string | null) => ({
    type: modalTypes.SET_MODAL_ACTION,
    payload,
  }),
};
export default dashboardActions;
