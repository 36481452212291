import { getCounselorTypes } from './types';

const initialState = {
  isLoading: false,
  error: '',
  data: {},
};

const getCounselorReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getCounselorTypes.REQUEST: {
      return { ...state, isLoading: true, error: '', data: {} };
    }

    case getCounselorTypes.SUCCESS: {
      return { ...state, data: payload, isLoading: false };
    }

    case getCounselorTypes.FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    default:
      return state;
  }
};

export default getCounselorReducer;
