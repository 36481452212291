import * as React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const courseCardConstants = {
  COMMENT: 'COMMENT',
  SHARE: 'SHARE',
  MIN: 'min',
  READ: 'read',
  SKILL: 'skill',
  ICON_POSITION_CUSTOM_LINK: 'right',
  SVG_SHARE_IMG: 'share',
  CARD_WIDTH: '34.3rem',
  ADD_ICON_SVG: 'add',
  DONE_ICON_SVG: 'done',
};
export const PROGRAM_MODALITY = {
  online: {
    text: 'Online',
    icon: <LanguageIcon />,
  },
  'on-campus': {
    text: 'On Campus',
    icon: <AccountBalanceIcon />,
  },
  hybrid: {
    text: 'Hybrid',
    icon: <ShuffleIcon />,
  },
};
export const PROGRAM_DEGREE_TYPE = {
  masters: "Master's",
  bachelors: "Bachelor's",
  doctorate: 'Doctorate',
  'certificates-diplomas': 'Certificate',
};
