// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import type { Saga } from 'redux-saga';
import { alertTypesConstants } from 'commons/constants';
import {
  createTimekitProjectTypes,
  deleteTimekitProjectTypes,
  getTimekitProjectListTypes,
  getTimekitProjectTypes,
  updateTimekitProjectTypes,
} from './types';
import { adminSchedulerActions } from './actions';

const { DANGER, SUCCESS } = alertTypesConstants;
export function* getTimekitProjectListSaga(): Saga<void> {
  try {
    const response = yield call([api.adminScheduler, 'getTimekitProjects']);
    yield put(adminSchedulerActions.getTimekitProjectListSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the project list!',
        type: DANGER,
      }),
    );
    yield put(adminSchedulerActions.getTimekitProjectListFailed(error));
  }
}
export function* getTimekitProjectSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminScheduler, 'getTimekitProject'], payload);
    yield put(adminSchedulerActions.getTimekitProjectSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the project!',
        type: DANGER,
      }),
    );
    yield put(adminSchedulerActions.getTimekitProjectFailed(error));
  }
}
export function* deleteTimekitProjectSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminScheduler, 'deleteTimekitProject'], payload);
    yield put(adminSchedulerActions.deleteTimekitProjectSuccess(response));
    yield put(push('/admin/timekit'));
    yield put(
      alertActions.setAlert({
        message: 'Delete operation has been successful!',
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error deleting this project!',
        type: DANGER,
      }),
    );
    yield put(adminSchedulerActions.deleteTimekitProjectFailed(error));
  }
}
export function* createTimekitProjectSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminScheduler, 'createTimekitProject'], payload);
    yield put(adminSchedulerActions.createTimekitProjectSuccess(response));
    yield put(push('/admin/timekit'));
    yield put(
      alertActions.setAlert({
        message: 'Create operation has been successful!',
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the scheduler!',
        type: DANGER,
      }),
    );
    yield put(adminSchedulerActions.createTimekitProjectFailed(error));
  }
}
export function* updateTimekitProjectSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminScheduler, 'updateTimekitProject'], payload);
    yield put(adminSchedulerActions.updateTimekitProjectSuccess(response));
    yield put(push('/admin/timekit'));
    yield put(
      alertActions.setAlert({
        message: 'Update successful!',
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the scheduler!',
        type: DANGER,
      }),
    );
    yield put(adminSchedulerActions.updateTimekitProjectFailed(error));
  }
}
export default function* schedulerSaga(): Saga<void> {
  yield takeLatest(getTimekitProjectListTypes.REQUEST, getTimekitProjectListSaga);
  yield takeLatest(getTimekitProjectTypes.REQUEST, getTimekitProjectSaga);
  yield takeLatest(deleteTimekitProjectTypes.REQUEST, deleteTimekitProjectSaga);
  yield takeLatest(createTimekitProjectTypes.REQUEST, createTimekitProjectSaga);
  yield takeLatest(updateTimekitProjectTypes.REQUEST, updateTimekitProjectSaga);
}
