import JsonApi from 'utils/jsonApi';

export default class Explore {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getExploreItems(): Promise<any> {
    try {
      const response = await this.jsonApi.get('/v2/interests?with_program_types=True', false);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  }
}
