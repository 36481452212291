import type { IsBetweenValuesParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Utility function that a value is between others two
 * Pre: fromValue < toValue
 */
const isBetweenRange = ({
  name,
  value,
  outputName,
  fromValue,
  toValue,
}: IsBetweenValuesParams): ValidationFieldError => {
  const errors = {};
  const valueAsNumber = Number(value);
  const condition = value !== '' && (valueAsNumber < fromValue || valueAsNumber > toValue);
  errors[name] = condition
    ? {
        errorMsg: `${outputName} must be between ${fromValue} and ${toValue}`,
      }
    : {};
  return errors;
};

export default isBetweenRange;
