// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import NooderaButton from 'components/NooderaButton/index';

const {
  noodera: { spacing },
} = getTheme();

export const StyledNooderaButton: StatelessFunctionalComponent<any> = styled(NooderaButton)`
  & svg {
    height: 16px;
    width: 16px;
    margin-left: ${spacing.x7};
  }
`;

export const StyledNooderaCTA: StatelessFunctionalComponent<any> = styled(NooderaButton.CTA)`
  & svg {
    height: 16px;
    width: 16px;
    margin-left: ${spacing.x7};
  }
`;
