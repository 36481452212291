/**
 * @description
 * Converts underdash_case text to camelCase
 * Pre: Text must be in underdash_case case
 * @param {String} text Text to be parsed
 */
const underdashToCamelCase = (text: string): string =>
  text.replace(/(_[a-z])/g, (x) => x.toUpperCase().replace('_', ''));

export default underdashToCamelCase;
