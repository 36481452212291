import {
  getOnboardingSurveyActionTypes,
  sendSurveyAnswersActionTypes,
  sendSurveyAnswerActionTypes,
} from './types';

export const surveyActions = {
  sendSurveyAnswersRequest: (payload: {
    questions: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    }[];
    surveyId: number;
  }) => ({
    type: sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_REQUEST,
    payload,
  }),
  sendSurveyAnswersSuccess: (payload: Record<string, any>) => ({
    type: sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_SUCCESS,
    payload,
  }),
  sendSurveyAnswersFailure: (payload: string) => ({
    type: sendSurveyAnswersActionTypes.SEND_SURVEY_ANSWERS_FAILURE,
    payload,
  }),
  getOnboardingSurveyRequest: () => ({
    type: getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_REQUEST,
  }),
  getOnboardingSurveySuccess: (payload: object) => ({
    type: getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_SUCCESS,
    payload,
  }),
  getOnboardingSurveyFail: (payload: string) => ({
    type: getOnboardingSurveyActionTypes.FETCH_ONBOARDING_SURVEY_FAILURE,
    payload,
  }),
  sendSurveyAnswerRequest: (payload: {
    answer: {
      questionId: number;
      optionId?: number;
      answerText?: string;
    };
    surveyId: number;
  }) => ({
    type: sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_REQUEST,
    payload,
  }),
  sendSurveyAnswerSuccess: (payload: Record<string, any>) => ({
    type: sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_SUCCESS,
    payload,
  }),
  sendSurveyAnswerFailure: (payload: string) => ({
    type: sendSurveyAnswerActionTypes.SEND_SURVEY_ANSWER_FAILURE,
    payload,
  }),
};
