import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Breadcrumb from 'components/Breadcrumb';
import NooderaDatePicker from 'components/NooderaDatePicker';
import { useSelector } from 'react-redux';
import { useUpdateUserProfileMutation } from 'lib/Api';
import NoodleSelect from 'components/NoodleSelect';
import { Country } from 'country-state-city';
import NooderaButton from 'components/NooderaButton';
import PhoneNumberTextField from 'screens/Profile/UpdateUser/components/PhoneNumberTextField';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import { ICountry } from 'country-state-city/dist/lib/interface';
import {
  FormHeaderTitle,
  HeaderContainer,
  HeaderTitle,
  StyledWrapper,
  InputContainer,
  StyledInputHeader,
  StyledNoodleTextField,
  UpdateUserContainer,
  Label,
  ButtonContainer,
  StyledContainer,
  HintTextContainer,
} from './UpdateUser.styles';

const schema = yup.object({
  firstName: yup
    .string()
    .min(1, 'First Name should have at least one letter')
    .max(16, 'First Name cannot be longer than 16 letters')
    .required(),
  middleName: yup.string().max(16, 'Middle Name cannot be longer than 16 letters'),
  lastName: yup
    .string()
    .min(1, 'Last Name should have at least one letter')
    .max(16, 'Last Name cannot be longer than 16 letters')
    .required(),
  phoneNumber: yup
    .string()
    .nullable()
    .test(
      'phone-number-test',
      'Phone number is invalid',
      (value, ctx) => !value || isPossiblePhoneNumber(value, ctx?.parent?.country || 'US'),
    ),
  address: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  state: yup.string(),
  country: yup.string(),
});

const setDefaultFormFields = (profile) => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  middleName: profile?.middleName,
  dateOfBirth: profile?.dateOfBirth,
  phoneNumber: profile?.phoneNumber,
  address: profile?.address?.address,
  postalCode: profile?.address?.postalCode,
  city: profile?.address?.city,
  state: profile?.address?.state,
  country: profile?.address?.country,
});

const UpdateUser: React.FC = () => {
  const history = useHistory();
  const { profile } = useSelector((state) => state.user);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: setDefaultFormFields(profile),
  });

  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();

  const onSubmit = (profileData: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
  }) => {
    updateUserProfile(profileData);
  };

  return (
    <UpdateUserContainer>
      <HeaderContainer>
        <Breadcrumb
          breadcrumbTrail={[
            {
              title: 'Back',
              // @ts-ignore
              url: history.location.state?.prevPath ? history.location.state?.prevPath : '/',
            },
          ]}
        />
        <HeaderTitle>Update Personal Information</HeaderTitle>
      </HeaderContainer>
      <StyledWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledContainer>
            <FormHeaderTitle>Contact Information</FormHeaderTitle>
            <InputContainer aria-live='polite'>
              <Label htmlFor='firstname'>
                <StyledInputHeader>First Name*</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='firstname'
                control={control}
                name='firstName'
                variant='outlined'
                autoComplete='off'
                error={errors?.firstName?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='middlename'>
                <StyledInputHeader>Middle Name</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='middlename'
                control={control}
                name='middleName'
                variant='outlined'
                autoComplete='off'
                error={errors?.middleName?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='lastname'>
                <StyledInputHeader>Last Name*</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='lastname'
                control={control}
                name='lastName'
                variant='outlined'
                autoComplete='off'
                error={errors?.lastName?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='dateofbirth'>
                <StyledInputHeader>Date Of Birth</StyledInputHeader>
              </Label>
              <Controller
                control={control}
                name='dateOfBirth'
                defaultValue={null}
                render={({ field }) => (
                  <NooderaDatePicker
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='phonenumber'>
                <StyledInputHeader>Phone Number</StyledInputHeader>
              </Label>
              <PhoneInputWithCountry
                id='phonenumber'
                control={control}
                name='phoneNumber'
                defaultValue='US'
                error={errors?.phoneNumber?.message.toString()}
                inputComponent={PhoneNumberTextField}
                rules={{ required: false }}
              />
            </InputContainer>
            <HintTextContainer>*Required field</HintTextContainer>
            <FormHeaderTitle>Address</FormHeaderTitle>
            <InputContainer aria-live='polite'>
              <Label htmlFor='address'>
                <StyledInputHeader>Address</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='address'
                control={control}
                name='address'
                variant='outlined'
                autoComplete='off'
                error={errors?.address?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='city'>
                <StyledInputHeader>City</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='city'
                control={control}
                name='city'
                variant='outlined'
                autoComplete='off'
                error={errors?.city?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='state'>
                <StyledInputHeader>State / Region</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='state'
                control={control}
                name='state'
                variant='outlined'
                autoComplete='off'
                error={errors?.state?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='postalcode'>
                <StyledInputHeader>Postal Code</StyledInputHeader>
              </Label>
              <StyledNoodleTextField
                id='postalcode'
                control={control}
                name='postalCode'
                variant='outlined'
                autoComplete='off'
                error={errors?.postalCode?.message.toString()}
              />
            </InputContainer>
            <InputContainer aria-live='polite'>
              <Label htmlFor='country'>
                <StyledInputHeader>Country</StyledInputHeader>
              </Label>
              <Controller
                name='country'
                control={control}
                render={({ field }) => (
                  <NoodleSelect
                    items={Country.getAllCountries().map((country: ICountry) => ({
                      name: country?.name,
                      value: country?.isoCode,
                    }))}
                    onChange={(country) => field.onChange(country)}
                    value={field.value}
                    placeholder='Select...'
                  />
                )}
              />
            </InputContainer>
            <ButtonContainer>
              <NooderaButton.CTA
                onClick={handleSubmit(onSubmit)}
                isDisabled={isLoading}
                aria-label='SaveChanges'
              >
                Save changes
              </NooderaButton.CTA>
            </ButtonContainer>
          </StyledContainer>
        </form>
      </StyledWrapper>
    </UpdateUserContainer>
  );
};

export default UpdateUser;
