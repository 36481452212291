import {
  createBookingTypes,
  getAoISchedulerTypes,
  getSchedulerAvailabilityTypes,
  getSchedulerListTypes,
  getSchedulerTypes,
  schedulerTypes,
  meetingTypes,
} from './types';

export const initialState = {
  isLoading: false,
  aoiScheduler: {},
  availability: [],
  booking: {
    success: false,
    isLoading: false,
    error: '',
  },
  meetings: {},
  scheduler: {},
  schedulers: [],
  timezone: {
    isUSBased: false,
  },
  errorMessage: '',
};

const schedulerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getSchedulerTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getSchedulerTypes.SUCCESS: {
      return { ...state, isLoading: false, scheduler: payload };
    }

    case getSchedulerTypes.FAILED: {
      return { ...state, errorMessage: payload, isLoading: false };
    }

    case getAoISchedulerTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getAoISchedulerTypes.SUCCESS: {
      return { ...state, isLoading: false, aoiScheduler: payload };
    }

    case getAoISchedulerTypes.FAILED: {
      return { ...state, errorMessage: payload, isLoading: false };
    }

    case getSchedulerListTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getSchedulerListTypes.SUCCESS: {
      return { ...state, isLoading: false, schedulers: payload };
    }

    case getSchedulerListTypes.FAILED: {
      return { ...state, errorMessage: payload, isLoading: false };
    }

    case getSchedulerAvailabilityTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getSchedulerAvailabilityTypes.SUCCESS: {
      return { ...state, isLoading: false, availability: payload };
    }

    case getSchedulerAvailabilityTypes.FAILED: {
      return { ...state, errorMessage: payload, isLoading: false };
    }

    case createBookingTypes.REQUEST: {
      return {
        ...state,
        booking: {
          isLoading: true,
        },
      };
    }

    case createBookingTypes.SUCCESS: {
      return {
        ...state,
        booking: {
          success: true,
          isLoading: false,
        },
      };
    }

    case createBookingTypes.FAILURE: {
      return {
        ...state,
        booking: {
          success: false,
          error: payload,
          isLoading: false,
        },
      };
    }

    case createBookingTypes.RESET: {
      return { ...state, booking: initialState.booking };
    }

    case schedulerTypes.SET_SCHEDULER_TIMEZONE: {
      return { ...state, timezone: payload };
    }

    case meetingTypes.GET_MEETING_REQUEST: {
      return { ...state, isLoading: true };
    }

    case meetingTypes.GET_MEETING_SUCCESS: {
      return { ...state, isLoading: false, meetings: payload };
    }

    case meetingTypes.GET_MEETING_FAILURE: {
      return { ...state, error: payload, isLoading: false };
    }

    default:
      return state;
  }
};

export default schedulerReducer;
