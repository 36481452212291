import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AccessIconWrapper, BaseCardContainer, CourseInfoContainer } from './BaseCard.styles';

interface BaseCardProps {
  isInverted?: boolean;
  backgroundImage?: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  onClick?: (args: any) => void;
  role?: string;
  tabIndex?: number;
}

const BaseCard: React.FC<BaseCardProps> = ({
  isInverted,
  icon,
  children,
  backgroundImage,
  onClick,
  role = 'button',
  tabIndex = 0,
}) => (
  <BaseCardContainer
    backgroundImage={backgroundImage}
    onClick={(args) => {
      if (onClick) {
        onClick(args);
      }
    }}
    role={role}
    tabIndex={tabIndex}
  >
    {icon}
    <CourseInfoContainer>{children}</CourseInfoContainer>
    <AccessIconWrapper isInverted={isInverted} aria-hidden='true'>
      <ChevronRightIcon />
    </AccessIconWrapper>
  </BaseCardContainer>
);

export default BaseCard;
