import type { SectionsState } from '../../commons/types/sectionTypes';
import {
  createSectionTypes,
  getMySectionsTypes,
  getSectionPreviewTypes,
  sectionTypes,
  getSectionsTypes,
  joinSectionTypes,
  joinSelfEnrolledSectionTypes,
  removeJoinSectionTypes,
  removeJoinSelfEnrolledSectionTypes,
} from './types';

export const initialState: SectionsState = {
  createSection: {
    isLoading: false,
    success: false,
    error: '',
  },
  joinSection: {
    isLoading: false,
    success: false,
    error: '',
  },
  joinSelfEnrolledSection: {
    isLoading: false,
    success: false,
    error: '',
  },
  mySections: {
    isLoading: false,
    data: [],
    error: '',
  },
  preview: {
    isLoading: false,
    data: [],
    error: '',
  },
  sections: {
    isLoading: false,
    data: [],
    error: '',
  },
  selectedSection: undefined,
  teacherAssistant: {},
};
type Action = {
  type: string;
  payload?: any;
};

const sectionReducer = (state: SectionsState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getMySectionsTypes.GET_MY_SECTIONS_REQUEST: {
      return { ...state, mySections: { ...initialState.mySections, isLoading: true } };
    }

    case getMySectionsTypes.GET_MY_SECTIONS_SUCCESS: {
      return {
        ...state,
        mySections: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getMySectionsTypes.GET_MY_SECTIONS_FAILURE: {
      return { ...state, mySections: { ...initialState.mySections, error: payload } };
    }

    case getSectionPreviewTypes.GET_SECTION_PREVIEW_REQUEST: {
      return { ...state, preview: { ...initialState.preview, isLoading: true } };
    }

    case getSectionPreviewTypes.GET_SECTION_PREVIEW_SUCCESS: {
      return {
        ...state,
        preview: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getSectionPreviewTypes.GET_SECTION_PREVIEW_FAILURE: {
      return { ...state, preview: { ...initialState.preview, error: payload } };
    }

    case createSectionTypes.CREATE_SECTION_REQUEST: {
      return { ...state, createSection: { ...initialState.createSection, isLoading: true } };
    }

    case createSectionTypes.CREATE_SECTION_SUCCESS: {
      return {
        ...state,
        createSection: {
          isLoading: false,
          success: payload,
        },
      };
    }

    case createSectionTypes.CREATE_SECTION_FAILURE: {
      return {
        ...state,
        createSection: {
          ...initialState.createSection,
          isLoading: false,
          error: payload,
          success: false,
        },
      };
    }

    case createSectionTypes.CREATE_SECTION_RESET: {
      return { ...state, createSection: initialState.createSection };
    }

    case sectionTypes.SELECT_SECTION: {
      return {
        ...state,
        selectedSection: payload,
        preview: { ...initialState.preview, isLoading: false },
      };
    }

    case getSectionsTypes.GET_SECTIONS_REQUEST: {
      return { ...state, sections: { ...initialState.sections, isLoading: true } };
    }

    case getSectionsTypes.GET_SECTIONS_SUCCESS: {
      return { ...state, sections: { ...initialState.sections, isLoading: false, data: payload } };
    }

    case getSectionsTypes.GET_SECTIONS_FAILURE: {
      return { ...state, sections: { ...initialState.sections, isLoading: false, error: payload } };
    }

    case joinSectionTypes.JOIN_SECTION_REQUEST: {
      return { ...state, joinSection: { ...initialState.joinSection, isLoading: true } };
    }

    case joinSectionTypes.JOIN_SECTION_SUCCESS: {
      return {
        ...state,
        joinSection: {
          isLoading: false,
          success: true,
        },
      };
    }

    case joinSectionTypes.JOIN_SECTION_FAILURE: {
      return {
        ...state,
        joinSection: { ...initialState.joinSection, error: payload, success: false },
      };
    }

    case joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_REQUEST: {
      return {
        ...state,
        joinSelfEnrolledSection: { ...initialState.joinSelfEnrolledSection, isLoading: true },
      };
    }

    case joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_SUCCESS: {
      return {
        ...state,
        joinSelfEnrolledSection: {
          isLoading: false,
          success: true,
          error: '',
        },
      };
    }

    case joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_FAILURE: {
      return {
        ...state,
        joinSelfEnrolledSection: {
          ...initialState.joinSelfEnrolledSection,
          error: payload,
          success: false,
        },
      };
    }

    case removeJoinSectionTypes.REMOVE_JOIN_SECTION: {
      return {
        ...state,
        joinSelfEnrolledSection: { ...initialState.joinSelfEnrolledSection, isLoading: true },
      };
    }

    case removeJoinSelfEnrolledSectionTypes.REMOVE_SELF_ENROLLED_JOIN_SECTION: {
      return {
        ...state,
        joinSelfEnrolledSection: initialState.joinSelfEnrolledSection,
      };
    }

    default:
      return state;
  }
};

export default sectionReducer;
