export const getGlobalSearchTypes = {
  REQUEST: 'GLOBAL_SEARCH_GET_RESULTS_REQUEST',
  SUCCESS: 'GLOBAL_SEARCH_GET_RESULTS_SUCCESS',
  FAILED: 'GLOBAL_SEARCH_GET_RESULTS_FAILED',
};
export const globalSearchTypes = {
  RESULTS_PAGE: 'GLOBAL_SEARCH_RESULTS_CHANGE_PAGE',
  SET_CURRENT_TAXONOMY: 'GLOBAL_SEARCH_SET_CURRENT_TAXONOMY',
  RESET_SEARCH: 'RESET_SEARCH',
};
