// @ts-nocheck
import type { DropdownItem } from 'commons/types';
import type { ValidationFieldError, ValidatorObject } from './Validation.types';

const validateField = (
  name: string,
  data: string | DropdownItem,
  validators: ValidatorObject[] = [],
): ValidationFieldError => {
  let error = {
    [name]: {},
  };
  const value = typeof data === 'string' ? data : data.value;
  const quantity = validators.length;

  for (let i = 0; i < quantity && !error[name].errorMsg; i += 1) {
    error = validators[i].validator({
      name,
      value,
      ...validators[i],
    });
  }

  return error;
};

export default validateField;
