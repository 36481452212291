// @ts-nocheck
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoaderInline from 'components/uielements/LoaderInline/index';
// noinspection ES6PreferShortImport
import { NoodleToast } from 'components/uielements/NoodleToast';
import { alertTypesConstants } from 'commons/constants/general';
// noinspection ES6PreferShortImport
import { baseUrl, stripePublishableKey } from 'settings/index';
import {
  paymentGetStripeClientSecretRequest,
  paymentGetStripeClientSecretReset,
  paymentGetStripeClientSecretForCourseRequest,
  paymentSetUserAlreadyPaid,
} from 'ducks/payment/reducer';
import type { PaymentFormProps, StripePaymentProps } from './StripePayment.types';
import { StyledPaymentElement } from './StripePayment.styles';
import Billing from './Billing/index';

const stripePromise = loadStripe(stripePublishableKey);

const PaymentForm = ({
  onSubmit = () => {},
  onPaymentSuccess = () => {},
  submitElement,
}: PaymentFormProps): React.ReactElement<any> | null => {
  const stripe = useStripe();
  const elements = useElements();
  const userEmail = useSelector((state) => state.user.email);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessPayment, setIsSuccessPayment] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [billing, setBilling] = React.useState({
    firstName: '',
    lastName: '',
    address: '',
    state: '',
    city: '',
    country: '',
  });
  const showState = billing.country === 'US';
  React.useEffect(() => {
    if (stripe) {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret',
      );

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment Accepted');
            break;

          case 'processing':
            setMessage('Payment Processing');
            break;

          case 'requires_payment_method':
            setMessage(`Payment was not successful (${paymentIntent.last_payment_error.message})`);
            break;

          default:
            setMessage(`Payment Failed (${paymentIntent.last_payment_error.message})`);
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { firstName, lastName, address, state, city, country } = billing;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: baseUrl,
        payment_method_data: {
          billing_details: {
            address: {
              city,
              state: showState ? state : '',
              country,
              line1: address,
            },
            email: userEmail,
            name: `${firstName} ${lastName}`,
          },
        },
      },
      redirect: 'if_required',
    });

    if (!error) {
      setMessage('Payment Accepted');
      setIsLoading(false);
      setIsSuccessPayment(true);
      onPaymentSuccess();
      return;
    }

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(`Payment Failed (${error.message})`);
    }

    setIsLoading(false);
  };

  const defineIfSubmitDisabled = (): boolean => {
    const checkIfValueNotExist = (key: string): boolean => {
      const value = billing[key];

      if (key === 'state') {
        return showState && !value;
      }

      return !value;
    };

    return Object.keys(billing).some(checkIfValueNotExist) || isSuccessPayment;
  };

  const renderSubmitElement = (): React.ReactElement<any> | null => {
    if (isLoading) {
      return <LoaderInline />;
    }

    if (submitElement && React.isValidElement(submitElement)) {
      return React.cloneElement(submitElement, {
        onClick: handleSubmit,
        isDisabled: defineIfSubmitDisabled(),
      });
    }

    return null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledPaymentElement>
        <PaymentElement
          options={{
            fields: {
              billingDetails: {
                address: {
                  country: 'never',
                },
              },
            },
          }}
        />
      </StyledPaymentElement>
      <Billing data={billing} showState={showState} onChange={(data) => setBilling(data)} />
      {message && <NoodleToast type={alertTypesConstants.SUCCESS}>{message}</NoodleToast>}
      {renderSubmitElement()}
    </form>
  );
};

const StripePayment = ({
  paymentData,
  paymentType,
  submitElement,
  className,
  onSubmit = () => {},
  onPaymentSuccess = () => {},
}: StripePaymentProps): React.ReactElement<any> | null => {
  const dispatch = useDispatch();
  const clientSecret = useSelector((state) => state.payment.clientSecret.data) || '';
  const isClientSecretLoading = useSelector((state) => state.payment.clientSecret.isLoading);
  const userAlreadyPaid = useSelector((state) => state.payment.userAlreadyPaid);
  const handleSubmit = (): void => {
    onSubmit();
  };

  React.useEffect(() => {
    if (!isClientSecretLoading && !clientSecret) {
      switch (paymentType) {
        case 'course':
          dispatch(paymentGetStripeClientSecretForCourseRequest(paymentData));
          break;

        default:
          dispatch(paymentGetStripeClientSecretRequest(paymentData));
          break;
      }
    }
  }, [clientSecret, isClientSecretLoading]);
  React.useEffect(() => {
    if (userAlreadyPaid) {
      onPaymentSuccess();
    }
    return () => {
      dispatch(paymentSetUserAlreadyPaid(false));
    };
  }, [userAlreadyPaid]);
  React.useEffect(() => () => dispatch(paymentGetStripeClientSecretReset()), []);
  return clientSecret ? (
    <div>
      <Elements
        className={className}
        stripe={stripePromise}
        options={{
          clientSecret,
        }}
      >
        <PaymentForm
          onPaymentSuccess={onPaymentSuccess}
          onSubmit={handleSubmit}
          submitElement={submitElement}
        />
      </Elements>
    </div>
  ) : (
    <LoaderInline />
  );
};

export default StripePayment;
