import * as React from 'react';
import { useSelector } from 'react-redux';
import isotype from 'assets/images/isotype.svg';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import LoaderWrapper from './LoaderInline.style';
import type { LoaderInlineProps } from './LoaderInline.types';

const LoaderInline = ({ width = '7rem', height = 'auto' }: LoaderInlineProps) => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const appSettings = getAppSettings();
  const { companyName, companySmallLogo } = appSettings || globalPartner;
  const imgAltText = `${companyName} Logo`;
  return (
    <LoaderWrapper>
      <img
        // @ts-expect-error
        src={isNonNoodleInstance ? companySmallLogo : isotype}
        alt={imgAltText}
        aria-label={imgAltText}
        title={imgAltText}
        loading='lazy'
        style={{
          width,
          height,
        }} // Probably also want to check whether the logo is a react component or link?
      />
    </LoaderWrapper>
  );
};

export default LoaderInline;
