// @ts-nocheck
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { useController } from 'react-hook-form';
import HelperText from 'components/HelperText/index';
import { Wrapper, StyledTextarea, StyledText } from './NoodleTextArea.style';
import type { NoodleTextAreaProps } from './NoodleTextArea.types';

const NoodleTextArea = ({
  label,
  value = '',
  placeholder,
  maxLength,
  helperText,
  onChange,
  ...rest
}: NoodleTextAreaProps): React.ReactElement<any> | null => (
  <Wrapper>
    {label && <InputLabel>{label}</InputLabel>}
    {helperText && <HelperText {...helperText} />}
    <StyledTextarea
      aria-label={label}
      minRows={3}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      {...(rest as any)}
    />
    {maxLength ? <StyledText>{maxLength - value?.length} characters left</StyledText> : null}
  </Wrapper>
);

const ControlledNoodleTextArea = ({
  label,
  placeholder,
  maxLength,
  helperText,
  control,
  name,
  ...rest
}: NoodleTextAreaProps): React.ReactElement<any> | null => {
  const { field } = useController({
    control,
    name,
  });
  return (
    <Wrapper>
      {label && <InputLabel>{label}</InputLabel>}
      {helperText && <HelperText {...helperText} />}
      <StyledTextarea
        aria-label={label}
        minRows={3}
        placeholder={placeholder}
        onChange={(e: Record<string, any>) => field.onChange(e.target.value)}
        maxLength={maxLength}
        {...(rest as any)}
        {...field}
      />
      {maxLength ? (
        <StyledText>{maxLength - (field.value?.length || 0)} characters left</StyledText>
      ) : null}
    </Wrapper>
  );
};

NoodleTextArea.Controlled = ControlledNoodleTextArea;
export default NoodleTextArea;
