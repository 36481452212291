import * as React from 'react';

import MaterialCard from 'components/MaterialCard/index';
import NooderaTag from 'components/NooderaTag/index';
import ProgressBar from 'components/ProgressBar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';

import NooderaButton from 'components/NooderaButton/index';
import SaveAction from 'components/SaveAction/index';
import { PROGRAM_DEGREE_TYPE, PROGRAM_MODALITY } from 'commons/constants/program';
import { useTheme } from 'styled-components';
import { capitalize } from 'utils/string';

import {
  ProgressContainer,
  CourseLogo,
  MaterialCardStyle,
  StyledLink,
  StyledA,
  StyledTitle,
  StyledMaterialCardImg,
} from './CourseCard.styles';

const ActiveTag: React.FC<{ isActive: boolean | undefined }> = ({ isActive = undefined }) => {
  const theme = useTheme();

  if (isActive === undefined) {
    return null;
  }

  const activeLabel = isActive === true ? 'visible' : 'hidden';

  return (
    <NooderaTag
      aria-label={`${activeLabel}-tag`}
      title={capitalize(activeLabel)}
      text={capitalize(activeLabel)}
      background={isActive === true ? theme.palette.green.yellowGreen : theme.palette.warning.sixty}
    />
  );
};

interface CourseCardProps {
  id: string;
  title: string;
  image: string;
  tagName?: string;
  courseLogo?: string;
  slug?: string;
  url?: string;
  hideLearnMore?: boolean;
  hideSave?: boolean;
  learnersCount?: number;
  checked?: boolean;
  className?: string;
  progressValue?: string;
  progressTotal?: string;
  linkOut?: boolean;
  onChange?: (...args: Array<any>) => any;
  isDisabled?: boolean;
  modality?: string;
  degreeType?: string;
  schoolName?: string;
  isAdminView?: boolean;
  instanceCount?: number;
  totalInstances?: number;
  isAddedToFederatedNetwork?: boolean;
  isNetworkView?: boolean;
  isActive?: boolean | undefined;
}

const CourseCard: React.FC<CourseCardProps> = ({
  id,
  title,
  image,
  tagName,
  courseLogo,
  slug = '',
  url,
  hideLearnMore,
  hideSave,
  learnersCount,
  checked = false,
  onChange = () => {},
  className,
  progressValue,
  progressTotal,
  linkOut,
  isDisabled = false,
  modality,
  degreeType,
  schoolName = 'University',
  isAdminView = false,
  instanceCount,
  totalInstances,
  isAddedToFederatedNetwork = false,
  isNetworkView = false,
  isActive = undefined,
}) => {
  const showResourceDetails = (titleStr): React.ReactElement<any> => (
    <>
      <CourseLogo
        height='32'
        width='200'
        src={courseLogo}
        alt={schoolName}
        aria-label={schoolName}
        title={schoolName}
      />
      <StyledTitle>{titleStr}</StyledTitle>
    </>
  );

  const renderProgressBar = (value, total): React.ReactElement<any> => (
    <ProgressContainer>
      <ProgressBar value={Number(value)} total={Number(total)} />
    </ProgressContainer>
  );

  const renderTags = () => (
    <MaterialCard.Tags>
      {PROGRAM_DEGREE_TYPE[degreeType] ? (
        <NooderaTag
          aria-label={PROGRAM_DEGREE_TYPE[degreeType]}
          title={PROGRAM_DEGREE_TYPE[degreeType]}
          text={PROGRAM_DEGREE_TYPE[degreeType]}
        />
      ) : null}
      {modality ? (
        <NooderaTag
          aria-label={PROGRAM_MODALITY[modality].text}
          title={PROGRAM_MODALITY[modality].text}
          text={PROGRAM_MODALITY[modality].text}
          icon={PROGRAM_MODALITY[modality].icon}
        />
      ) : null}
      {learnersCount !== undefined && !isAdminView && (
        <NooderaTag
          aria-label='Learners'
          title='Learners'
          text={learnersCount.toString()}
          icon={<GroupsIcon />}
        />
      )}
      {!isAdminView && <ActiveTag isActive={isActive} />}
      {tagName ? <NooderaTag aria-label={tagName} title={tagName} text={tagName} /> : null}
      {linkOut ? <NooderaTag text={<OpenInNewIcon />} /> : null}
    </MaterialCard.Tags>
  );

  const renderNetworkTags = (): React.ReactElement<any> => (
    <MaterialCard.Tags>
      {!isNetworkView && isAddedToFederatedNetwork && (
        <NooderaTag
          aria-label='Is Added To Network'
          title='Is Added To Network'
          text='Shared'
          icon={<LanguageIcon />}
        />
      )}

      {instanceCount > 0 && (
        <NooderaTag
          aria-label='Total Enrolled Instances'
          title='Total Enrolled Instances'
          text={`${instanceCount} out of ${totalInstances} instances`}
        />
      )}
    </MaterialCard.Tags>
  );

  const renderCardContent = (): React.ReactElement<any> => (
    <>
      <StyledMaterialCardImg width='421' height='120' src={`${image}?w=480`} alt='' />
      <MaterialCard.ContentContainer>
        {showResourceDetails(title)}
        {renderTags()}
        <MaterialCard.Actions>
          {!hideLearnMore && <NooderaButton.CTA size='medium'>Learn More</NooderaButton.CTA>}
          {!hideSave && (
            <SaveAction
              isSaved={checked}
              onClick={(e) => {
                e.preventDefault();
                onChange(id);
              }}
            />
          )}
        </MaterialCard.Actions>
        {(isAdminView || isNetworkView) && renderNetworkTags()}
      </MaterialCard.ContentContainer>
      {progressTotal && progressValue && renderProgressBar(progressValue, progressTotal)}
    </>
  );

  const child = (
    <MaterialCardStyle className={className} isDisabled={isDisabled}>
      {renderCardContent()}
    </MaterialCardStyle>
  );

  const disableClickCard = (e: React.MouseEvent<HTMLAnchorElement>): void =>
    isDisabled && e.preventDefault();

  return linkOut ? (
    <StyledA target='_blank' href={url} isDisabled={isDisabled} onClick={disableClickCard}>
      {child}
    </StyledA>
  ) : (
    <StyledLink
      width='100%'
      to={url || `/courses/${slug}`}
      key={id}
      isDisabled={isDisabled}
      onClick={disableClickCard}
    >
      {child}
    </StyledLink>
  );
};

export default CourseCard;
