// @ts-nocheck
import { loginTypes } from './types';

const initState = {
  error: null,
  isLoading: false,
  isLoggedIn: false,
  message: {
    type: null,
    text: null,
  },
};

const authReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case loginTypes.REQUEST:
      return { ...state, isLoading: true, isLoggedIn: false };

    case loginTypes.SUCCESS: {
      return { ...state, isLoading: false, isLoggedIn: Boolean(payload.user) };
    }

    case loginTypes.RESET: {
      return { ...state, isLoading: false, isLoggedIn: false };
    }

    case loginTypes.FAILED: {
      return {
        ...state,
        userId: null,
        isLoggedIn: false,
        isLoading: false,
        message: {
          type: 'danger',
          text: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default authReducer;
