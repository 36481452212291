import { connect } from 'react-redux';
import get from 'lodash.get';
import SignedIn from 'components/Navbar/NavbarSignedIn';
import Guest from 'components/Navbar/NavbarGuest';
import { userConnectionActions } from 'ducks/userConnection/actions';

const mapStateToProps = (state) => ({
  userId: get(state, 'user.id'),
  profile: get(state, 'user.profile') || {},
  selectedAccount: get(state, 'user.selectedAccount') || {},
});

const mapDispatchToProps = {
  onAcceptUserConnection: (connection) =>
    userConnectionActions.acceptUserConnectionRequest(connection),
  onRejectUserConnection: (connection) =>
    userConnectionActions.rejectCancelUserConnectionRequest(connection),
};
export const NavBarSignedIn = connect(mapStateToProps, mapDispatchToProps)(SignedIn);
export const NavBarGuest = Guest;
