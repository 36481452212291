import { percentage } from '../math';

const percentageValue = (value: number, total: number, fractionDigits = 0): string => {
  const parsedValue = percentage(value, total);
  const parsedValueString = Number.isNaN(parsedValue)
    ? '0'
    : parsedValue.toLocaleString('en-US', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      });
  return `${parsedValueString}%`;
};

export default percentageValue;
