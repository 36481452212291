// @ts-nocheck
import type { PartnerInstanceState } from 'commons/types/partnerInstanceTypes';
import {
  getPartnerInviteTypes,
  getPartnerMembershipsTypes,
  verifyInvitationTokenTypes,
  getPartnerInstancesTypes,
  getPartnerInstanceTypes,
  uploadCSVTypes,
  createPartnerInstanceTypes,
  updatePartnerInstanceTypes,
  getPartnerCoursesTypes,
  updatePartnerCourseTypes,
  getSectionByIdTypes,
  getPartnerCourseTypes,
  createOrUpdateSectionTypes,
  getEngagementReportTypes,
  getLearnerStatusReportTypes,
  sendPartnerInvitesTypes,
  getSurveyReportTypes,
  getPaginatedPartnerCoursesTypes,
  getCertificateReportTypes,
  getCompletionReportByPartnerTypes,
  getCompletionReportByProgramTypes,
  createPartnerInstanceMembershipTypes,
  patchPartnerInstanceMembershipTypes,
  getPaginatedPartnerInstancesTypes,
  getPaginatedPartnerMembershipsTypes,
  getPaginatedSectionLearnersByIdTypes,
  getPaginatedCourseSectionsTypes,
  addMembersToSectionTypes,
  addSoloMembersToCourseTypes,
  getGlobalPartnerTypes,
  setupStripeAccountTypes,
  getStripeAccountTypes,
  createStripeInvoiceTypes,
  requestSeatsTypes,
  getStripeInvoiceTypes,
  getStripeInvoiceByIdTypes,
  getPaginatedStripeInvoiceTypes,
  reviewSeatRequestTypes,
  getStripeInvoiceTableDataTypes,
  getStripeReceiptsTableDataTypes,
  getStripePartnerRevenueDataTypes,
  getPartnerSeatRequestTypes,
} from './types';

export const initialState: PartnerInstanceState = {
  partnerInvites: {
    error: null,
    data: null,
    isLoading: false,
  },
  paginatedSections: {
    data: null,
    error: null,
    isLoading: false,
  },
  createPartnerInstanceMembership: {
    error: null,
    data: null,
    isLoading: false,
  },
  patchPartnerInstanceMembership: {
    error: null,
    data: null,
    isLoading: false,
  },
  memberships: {
    error: null,
    data: null,
    isLoading: false,
  },
  paginatedMemberships: {
    error: null,
    data: { data: [], meta: { count: 0 } },
    isLoading: false,
  },
  courses: {
    error: null,
    data: [],
    isLoading: false,
  },
  paginatedCourses: {
    error: null,
    data: { data: [], meta: { count: 0 } },
    isLoading: false,
  },
  course: {
    error: null,
    data: null,
    isLoading: false,
  },
  updateCourse: {
    error: null,
    data: null,
    isLoading: false,
  },
  sendPartnerInvites: {
    error: null,
    data: {},
    isLoading: false,
  },
  partnerInstances: {
    error: null,
    data: [],
    isLoading: false,
  },
  paginatedPartnerInstances: {
    error: null,
    data: { data: [], meta: { count: 0 } },
    isLoading: false,
  },
  partnerInstance: {
    error: null,
    data: {},
    isLoading: false,
  },
  uploadCSV: {
    error: null,
    data: {},
    isLoading: false,
  },
  createPartnerInstance: {
    error: null,
    success: false,
    isLoading: false,
  },
  updatePartnerInstance: {
    error: null,
    success: false,
    isLoading: false,
  },
  sections: {
    data: null,
    isLoading: false,
    error: null,
  },
  section: {
    data: null,
    isLoading: false,
    error: null,
  },
  addMembersToSection: {
    data: null,
    isLoading: false,
    error: null,
  },
  addSoloMembersToCourse: {
    data: null,
    isLoading: false,
    error: null,
  },
  paginatedSectionLearners: {
    data: null,
    isLoading: false,
    error: null,
  },
  createOrUpdateSection: {
    error: null,
    success: false,
    isLoading: false,
  },
  reports: {
    engagement: {
      isLoading: false,
      data: {},
      error: null,
    },
    learnerStatus: {
      isLoading: false,
      data: {},
      error: null,
    },
    surveys: {
      isLoading: false,
      data: [],
      error: null,
    },
    completion: {
      partner: {
        data: {},
        isLoading: false,
        error: '',
      },
      program: {
        data: {},
        isLoading: false,
        error: '',
      },
    },
    certificate: {
      data: [],
      isLoading: false,
      error: '',
    },
  },
  finances: {
    stripeAccount: {
      isLoading: false,
      data: {},
      error: '',
    },
    setupStripeAccount: {
      isLoading: false,
      success: false,
      error: '',
    },
    requestSeats: {
      isLoading: false,
      data: {},
      error: '',
    },
    seatRequests: {
      isLoading: false,
      data: {},
      error: '',
    },
    reviewSeatRequest: {
      isLoading: false,
      data: {},
      error: '',
    },
    stripeInvoices: {
      isLoading: false,
      data: {},
      error: '',
    },
    createStripeInvoice: {
      isLoading: false,
      data: {},
      error: '',
    },
    stripeInvoice: {
      isLoading: false,
      data: null,
      error: '',
    },
    paginatedStripeInvoices: {
      isLoading: false,
      data: {},
      error: '',
    },
    invoicesTableData: {
      isLoading: false,
      data: null,
      invoices: [],
      error: '',
    },
    receiptsTableData: {
      isLoading: false,
      data: null,
      receipts: [],
      error: '',
    },
    partnerRevenueData: {
      isLoading: false,
      data: {},
      error: '',
    },
  },
  globalPartner: {
    error: null,
    data: {},
    isLoading: true,
  },
};
type Action = {
  type: string;
  payload?: any;
};

const partnerInstanceReducer = (
  state: PartnerInstanceState = initialState,
  action: Action,
): PartnerInstanceState => {
  const { type, payload } = action;

  switch (type) {
    case getPartnerInviteTypes.GET_PARTNER_INVITE_REQUEST: {
      return { ...state, partnerInvites: { ...initialState.partnerInvites, isLoading: true } };
    }

    case getPartnerInviteTypes.GET_PARTNER_INVITE_SUCCESS: {
      return {
        ...state,
        partnerInvites: {
          ...initialState.partnerInvites,
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPartnerInviteTypes.GET_PARTNER_INVITE_FAILURE: {
      return {
        ...state,
        partnerInvites: {
          ...initialState.partnerInvites,
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPartnerInstancesTypes.GET_PARTNER_INSTANCES_REQUEST: {
      return { ...state, partnerInstances: { ...initialState.partnerInstances, isLoading: true } };
    }

    case getPartnerInstancesTypes.GET_PARTNER_INSTANCES_SUCCESS: {
      return {
        ...state,
        partnerInstances: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPartnerInstancesTypes.GET_PARTNER_INSTANCES_FAILURE: {
      return {
        ...state,
        partnerInstances: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_REQUEST: {
      return {
        ...state,
        paginatedPartnerInstances: {
          isLoading: true,
          data: null,
          error: null,
        },
      };
    }

    case getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_SUCCESS: {
      return {
        ...state,
        paginatedPartnerInstances: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_FAILURE: {
      return {
        ...state,
        paginatedPartnerInstances: {
          isLoading: false,
          data: null,
          error: payload,
        },
      };
    }

    case getSectionByIdTypes.GET_SECTION_BY_ID_REQUEST: {
      return { ...state, section: { ...initialState.section, isLoading: true } };
    }

    case getSectionByIdTypes.GET_SECTION_BY_ID_SUCCESS: {
      return {
        ...state,
        section: {
          error: null,
          isLoading: false,
          data: payload,
        },
      };
    }

    case getSectionByIdTypes.GET_SECTION_BY_ID_FAILURE: {
      return {
        ...state,
        section: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_REQUEST: {
      return {
        ...state,
        paginatedSectionLearners: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    }

    case getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_SUCCESS: {
      return {
        ...state,
        paginatedSectionLearners: {
          error: null,
          isLoading: false,
          data: payload,
        },
      };
    }

    case getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_FAILURE: {
      return {
        ...state,
        paginatedSectionLearners: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPartnerInstanceTypes.GET_PARTNER_INSTANCE_REQUEST: {
      return { ...state, partnerInstance: { ...initialState.partnerInstance, isLoading: true } };
    }

    case getPartnerInstanceTypes.GET_PARTNER_INSTANCE_SUCCESS: {
      return {
        ...state,
        partnerInstance: {
          ...initialState.partnerInstance,
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPartnerInstanceTypes.GET_PARTNER_INSTANCE_FAILURE: {
      return {
        ...state,
        partnerInstance: {
          ...initialState.partnerInstance,
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPartnerInstanceTypes.GET_PARTNER_INSTANCE_RESET: {
      return { ...state, partnerInstance: initialState.partnerInstance };
    }

    case sendPartnerInvitesTypes.SEND_PARTNER_INVITES_REQUEST: {
      return {
        ...state,
        sendPartnerInvites: { ...initialState.sendPartnerInvites, isLoading: true },
      };
    }

    case sendPartnerInvitesTypes.SEND_PARTNER_INVITES_SUCCESS: {
      return {
        ...state,
        sendPartnerInvites: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case sendPartnerInvitesTypes.SEND_PARTNER_INVITES_FAILURE: {
      return {
        ...state,
        sendPartnerInvites: {
          data: {},
          error: payload,
          isLoading: false,
        },
      };
    }

    case sendPartnerInvitesTypes.SEND_PARTNER_INVITES_RESET: {
      return { ...state, sendPartnerInvites: initialState.sendPartnerInvites };
    }

    case uploadCSVTypes.UPLOAD_CSV_REQUEST: {
      return { ...state, uploadCSV: { ...initialState.uploadCSV, isLoading: true } };
    }

    case uploadCSVTypes.UPLOAD_CSV_SUCCESS: {
      return {
        ...state,
        uploadCSV: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case uploadCSVTypes.UPLOAD_CSV_FAILURE: {
      return {
        ...state,
        uploadCSV: {
          data: false,
          error: payload,
          isLoading: false,
        },
      };
    }

    case uploadCSVTypes.UPLOAD_CSV_RESET: {
      return {
        ...state,
        uploadCSV: {
          data: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_REQUEST: {
      return { ...state, memberships: { data: null, error: null, isLoading: true } };
    }

    case getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_SUCCESS: {
      return {
        ...state,
        memberships: { isLoading: false, data: payload, error: null },
      };
    }

    case getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_FAILURE: {
      return {
        ...state,
        memberships: { isLoading: false, data: null, error: payload },
      };
    }

    case getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_REQUEST: {
      return {
        ...state,
        paginatedMemberships: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    }

    case getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_SUCCESS: {
      return {
        ...state,
        paginatedMemberships: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_FAILURE: {
      return {
        ...state,
        paginatedMemberships: {
          isLoading: false,
          data: null,
          error: payload,
        },
      };
    }

    case getPartnerCoursesTypes.GET_PARTNER_COURSES_REQUEST: {
      return { ...state, courses: { ...initialState.courses, isLoading: true } };
    }

    case getPartnerCoursesTypes.GET_PARTNER_COURSES_SUCCESS: {
      return {
        ...state,
        courses: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPartnerCoursesTypes.GET_PARTNER_COURSES_FAILURE: {
      return {
        ...state,
        courses: {
          data: [],
          isLoading: false,
          error: payload,
        },
      };
    }

    case updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_REQUEST: {
      return { ...state, updateCourse: { ...initialState.updateCourse, isLoading: true } };
    }

    case updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_SUCCESS: {
      return {
        ...state,
        updateCourse: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_FAILURE: {
      return {
        ...state,
        updateCourse: {
          data: {},
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_REQUEST: {
      return {
        ...state,
        paginatedCourses: {
          error: null,
          data: null,
          isLoading: true,
        },
      };
    }

    case getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_SUCCESS: {
      return {
        ...state,
        paginatedCourses: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_FAILURE: {
      return {
        ...state,
        paginatedCourses: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPartnerCourseTypes.GET_PARTNER_COURSE_REQUEST: {
      return { ...state, course: { ...initialState.course, isLoading: true } };
    }

    case getPartnerCourseTypes.GET_PARTNER_COURSE_SUCCESS: {
      return {
        ...state,
        course: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPartnerCourseTypes.GET_PARTNER_COURSE_FAILURE: {
      return {
        ...state,
        course: {
          data: {},
          isLoading: false,
          error: payload,
        },
      };
    }

    case getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_REQUEST: {
      return {
        ...state,
        paginatedSections: {
          data: null,
          error: null,
          isLoading: true,
        },
      };
    }

    case getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_SUCCESS: {
      return {
        ...state,
        paginatedSections: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_FAILURE: {
      return {
        ...state,
        paginatedSections: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_REQUEST: {
      return {
        ...state,
        invitationTokenVerification: {
          isLoading: true,
        },
      };
    }

    case verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_SUCCESS: {
      return {
        ...state,
        invitationTokenVerification: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_FAILURE: {
      return {
        ...state,
        invitationTokenVerification: {
          data: false,
          isLoading: false,
          error: payload,
        },
      };
    }

    case createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_REQUEST: {
      return {
        ...state,
        createPartnerInstance: {
          isLoading: true,
        },
      };
    }

    case createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_SUCCESS: {
      return {
        ...state,
        createPartnerInstance: {
          success: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_FAILURE: {
      return {
        ...state,
        createPartnerInstance: {
          success: false,
          error: payload,
          isLoading: false,
        },
      };
    }

    case createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_REQUEST: {
      return {
        ...state,
        createPartnerInstanceMembership: {
          isLoading: true,
          data: null,
          error: null,
        },
      };
    }

    case createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        createPartnerInstanceMembership: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_FAILURE: {
      return {
        ...state,
        createPartnerInstanceMembership: {
          data: null,
          error: payload,
          isLoading: false,
        },
      };
    }

    case patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_REQUEST: {
      return {
        ...state,
        patchPartnerInstanceMembership: {
          isLoading: true,
          data: null,
          error: null,
        },
      };
    }

    case patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        patchPartnerInstanceMembership: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_FAILURE: {
      return {
        ...state,
        patchPartnerInstanceMembership: {
          data: null,
          error: payload ?? true,
          isLoading: false,
        },
      };
    }

    case createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_RESET: {
      return {
        ...state,
        createPartnerInstance: {
          success: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_REQUEST: {
      return {
        ...state,
        updatePartnerInstance: {
          isLoading: true,
        },
      };
    }

    case updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_SUCCESS: {
      return {
        ...state,
        updatePartnerInstance: {
          success: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_FAILURE: {
      return {
        ...state,
        updatePartnerInstance: {
          success: false,
          error: payload,
          isLoading: false,
        },
      };
    }

    case updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_RESET: {
      return {
        ...state,
        updatePartnerInstance: {
          success: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_REQUEST: {
      return {
        ...state,
        createOrUpdateSection: {
          isLoading: true,
        },
      };
    }

    case createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_SUCCESS: {
      return {
        ...state,
        createOrUpdateSection: {
          success: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_FAILURE: {
      return {
        ...state,
        createOrUpdateSection: {
          success: false,
          error: payload,
          isLoading: false,
        },
      };
    }

    case createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_RESET: {
      return {
        ...state,
        createOrUpdateSection: {
          success: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case getEngagementReportTypes.GET_ENGAGEMENT_REPORT_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          engagement: {
            isLoading: true,
            data: {},
            error: null,
          },
        },
      };
    }

    case getEngagementReportTypes.GET_ENGAGEMENT_REPORT_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          engagement: {
            data: payload,
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getEngagementReportTypes.GET_ENGAGEMENT_REPORT_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          engagement: {
            data: {},
            error: payload,
            isLoading: false,
          },
        },
      };
    }

    case getEngagementReportTypes.GET_ENGAGEMENT_REPORT_RESET: {
      return {
        ...state,
        reports: {
          ...state.reports,
          engagement: {
            data: {},
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          learnerStatus: {
            data: {},
            isLoading: true,
            error: null,
          },
        },
      };
    }

    case getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          learnerStatus: {
            data: payload,
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          learnerStatus: {
            data: {},
            error: payload,
            isLoading: false,
          },
        },
      };
    }

    case getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_RESET: {
      return {
        ...state,
        reports: {
          ...state.reports,
          learnerStatus: {
            data: {},
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getSurveyReportTypes.GET_SURVEY_REPORT_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          surveys: {
            data: [],
            isLoading: true,
            error: null,
          },
        },
      };
    }

    case getSurveyReportTypes.GET_SURVEY_REPORT_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          surveys: {
            data: payload,
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getSurveyReportTypes.GET_SURVEY_REPORT_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          surveys: {
            data: [],
            error: payload,
            isLoading: false,
          },
        },
      };
    }

    case getSurveyReportTypes.GET_SURVEY_REPORT_RESET: {
      return {
        ...state,
        reports: {
          ...state.reports,
          surveys: {
            data: {},
            error: null,
            isLoading: false,
          },
        },
      };
    }

    case getCertificateReportTypes.GET_CERTIFICATE_REPORT_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          certificate: {
            data: [],
            isLoading: true,
            error: '',
          },
        },
      };
    }

    case getCertificateReportTypes.GET_CERTIFICATE_REPORT_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          certificate: {
            data: payload,
            error: '',
            isLoading: false,
          },
        },
      };
    }

    case getCertificateReportTypes.GET_CERTIFICATE_REPORT_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          certificate: {
            data: [],
            error: payload,
            isLoading: false,
          },
        },
      };
    }

    case getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            partner: {
              data: {},
              isLoading: true,
              error: null,
            },
          },
        },
      };
    }

    case getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            partner: {
              data: payload,
              isLoading: false,
              error: null,
            },
          },
        },
      };
    }

    case getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            partner: {
              data: {},
              isLoading: false,
              error: null,
            },
          },
        },
      };
    }

    case getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_REQUEST: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            program: {
              data: {},
              isLoading: true,
              error: null,
            },
          },
        },
      };
    }

    case getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_SUCCESS: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            program: {
              data: payload,
              isLoading: false,
              error: null,
            },
          },
        },
      };
    }

    case getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_FAILURE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          completion: {
            ...state.reports.completion,
            program: {
              data: {},
              isLoading: false,
              error: null,
            },
          },
        },
      };
    }

    case addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_REQUEST: {
      return {
        ...state,
        addMembersToSection: { ...initialState.addMembersToSection, isLoading: true },
      };
    }

    case addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_SUCCESS: {
      return {
        ...state,
        addMembersToSection: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_FAILURE: {
      return {
        ...state,
        addMembersToSection: {
          data: null,
          error: payload,
          isLoading: false,
        },
      };
    }

    case addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_RESET: {
      return {
        ...state,
        addMembersToSection: { ...initialState.addMembersToSection, isLoading: true },
      };
    }

    case addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_REQUEST: {
      return {
        ...state,
        addSoloMembersToCourse: { ...initialState.addSoloMembersToCourse, isLoading: true },
      };
    }

    case addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_SUCCESS: {
      return {
        ...state,
        addSoloMembersToCourse: {
          data: payload,
          error: null,
          isLoading: false,
        },
      };
    }

    case addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_FAILURE: {
      return {
        ...state,
        addSoloMembersToCourse: {
          data: null,
          error: payload,
          isLoading: false,
        },
      };
    }

    case addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_RESET: {
      return {
        ...state,
        addSoloMembersToCourse: { ...initialState.addSoloMembersToCourse, isLoading: true },
      };
    }
    case addMembersToSectionTypes.RESET_ADD_MEMBERS_TO_SECTION: {
      return {
        ...state,
        addMembersToSection: initialState.addMembersToSection,
      };
    }

    case getGlobalPartnerTypes.GET_GLOBAL_PARTNER_REQUEST: {
      return { ...state, globalPartner: { ...state.globalPartner, isLoading: true } };
    }

    case getGlobalPartnerTypes.GET_GLOBAL_PARTNER_SUCCESS: {
      return {
        ...state,
        globalPartner: {
          isLoading: false,
          data: payload,
          error: null,
        },
      };
    }

    case getGlobalPartnerTypes.GET_GLOBAL_PARTNER_FAILURE: {
      return {
        ...state,
        globalPartner: {
          data: null,
          isLoading: false,
          error: payload,
        },
      };
    }

    case setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          setupStripeAccount: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          setupStripeAccount: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          setupStripeAccount: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripeAccountTypes.GET_STRIPE_ACCOUNT_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeAccount: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case getStripeAccountTypes.GET_STRIPE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeAccount: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case getStripeAccountTypes.GET_STRIPE_ACCOUNT_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeAccount: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          createStripeInvoice: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          createStripeInvoice: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          createStripeInvoice: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_RESET: {
      return {
        ...state,
        finances: {
          ...state.finances,
          createStripeInvoice: { isLoading: false, data: {}, error: '' },
        },
      };
    }

    case getStripeInvoiceTypes.GET_STRIPE_INVOICE_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoices: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case getStripeInvoiceTypes.GET_STRIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoices: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case getStripeInvoiceTypes.GET_STRIPE_INVOICE_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoices: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoice: { isLoading: true, data: null, error: '' },
        },
      };
    }

    case getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoice: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          stripeInvoice: {
            isLoading: false,
            data: null,
            error: payload,
          },
        },
      };
    }

    case getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          paginatedStripeInvoices: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          paginatedStripeInvoices: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          paginatedStripeInvoices: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          invoicesTableData: { ...state.finances.invoicesTableData, isLoading: true },
        },
      };
    }

    case getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          invoicesTableData: {
            isLoading: false,
            data: payload,
            error: '',
            invoices: [...state.finances.invoicesTableData.invoices, ...payload.invoices],
          },
        },
      };
    }

    case getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          invoicesTableData: {
            ...state.finances.invoicesTableData,
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripeInvoiceTableDataTypes.RESET_STRIPE_INVOICE_TABLE_DATA: {
      return {
        ...state,
        finances: {
          ...state.finances,
          invoicesTableData: {
            invoices: [],
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          receiptsTableData: { ...state.finances.receiptsTableData, isLoading: true },
        },
      };
    }

    case getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          receiptsTableData: {
            isLoading: false,
            data: payload,
            receipts: [...state.finances.receiptsTableData.receipts, ...payload.receipts],
            error: '',
          },
        },
      };
    }

    case getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          receiptsTableData: {
            ...state.finances.receiptsTableData,
            data: {},
            isLoading: false,
            error: payload,
          },
        },
      };
    }

    case getStripeReceiptsTableDataTypes.RESET_STRIPE_RECEIPTS_TABLE_DATA: {
      return {
        ...state,
        finances: {
          ...state.finances,
          receiptsTableData: {
            receipts: [],
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          partnerRevenueData: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          partnerRevenueData: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          partnerRevenueData: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case requestSeatsTypes.REQUEST_SEATS_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          requestSeats: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case requestSeatsTypes.REQUEST_SEATS_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          requestSeats: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case requestSeatsTypes.REQUEST_SEATS_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          requestSeats: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          reviewSeatRequest: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          reviewSeatRequest: {
            isLoading: false,
            data: payload,
            error: '',
          },
        },
      };
    }

    case reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          reviewSeatRequest: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    case getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_REQUEST: {
      return {
        ...state,
        finances: {
          ...state.finances,
          seatRequests: { isLoading: true, data: {}, error: '' },
        },
      };
    }

    case getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_SUCCESS: {
      return {
        ...state,
        finances: {
          ...state.finances,
          seatRequests: {
            isLoading: false,
            data: payload.data,
            error: '',
          },
        },
      };
    }

    case getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_FAILURE: {
      return {
        ...state,
        finances: {
          ...state.finances,
          seatRequests: {
            isLoading: false,
            data: {},
            error: payload,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default partnerInstanceReducer;
