// @ts-nocheck
import * as React from 'react';
import { ReactComponent as DoneIcon } from 'assets/svg/done.svg';
import type { ImageItemProps } from './ImageItem.types';
import {
  ImgItem,
  ImageItemContainer,
  SelectedImageOverlay,
  CheckIconWrapper,
  SelectedImageContainer,
} from './ImageItem.styles';

const renderImage = (
  image: Record<string, any>,
  selectedPhoto: Record<string, any> | null,
): React.ReactElement<any> | null =>
  selectedPhoto && selectedPhoto.id === image.id ? (
    <SelectedImageContainer>
      <ImgItem
        isSelected
        src={image.urls.thumb}
        alt='ImagePicker'
        loading='lazy'
        width='100%'
        height='100%'
      />
      <SelectedImageOverlay />
      <CheckIconWrapper>
        <DoneIcon />
      </CheckIconWrapper>
    </SelectedImageContainer>
  ) : (
    <ImgItem src={image.urls.thumb} alt='ImagePicker' loading='lazy' width='100%' height='100%' />
  );

const ImageItem = ({ image, selectedPhoto, selectPhoto }: ImageItemProps) => {
  const photoClickHandler = () => selectPhoto(image);

  return (
    <ImageItemContainer
      onClick={photoClickHandler}
      onKeyPress={photoClickHandler}
      role='button'
      tabIndex='0'
    >
      {renderImage(image, selectedPhoto)}
    </ImageItemContainer>
  );
};

export default ImageItem;
