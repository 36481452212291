import { modalTypes } from './types';

const initState = {
  isOpen: false,
};

const modalReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case modalTypes.SET_MODAL:
      return { ...payload, isOpen: true };

    case modalTypes.REMOVE_MODAL:
      return initState;

    default:
      return state;
  }
};

export default modalReducer;
