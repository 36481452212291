import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { socialToolUrl } from 'settings/index';

const SocialTool = () => {
  useEffect(() => {
    const appContainer = document.createElement('div');
    appContainer.id = 'noodle-social-app';

    document.getElementById('noodle-social-app-inject-settings')?.appendChild(appContainer);

    const loadAssets = async () => {
      try {
        const response = await fetch(`${socialToolUrl}/asset-manifest.json`);
        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
          return;
        }
        const data = await response.json();

        const entrypoints = Object.values(data?.entrypoints);

        // Define window.React and window.ReactDOM before loading the social tool scripts
        // This ensures the social tool uses the same React instance as the main app
        (window as any).React = React;
        (window as any).ReactDOM = ReactDOM;

        entrypoints.forEach((entrypoint: string) => {
          if (entrypoint.includes('css')) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = `${socialToolUrl}/${entrypoint}`;
            document.head.appendChild(link);
          }
          if (entrypoint.includes('js')) {
            const script = document.createElement('script');
            script.src = `${socialToolUrl}/${entrypoint}`;
            document.head.appendChild(script);
          }
        });

        const fontLink = document.createElement('link');
        fontLink.rel = 'stylesheet';
        fontLink.href =
          'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700&display=swap';
        document.head.appendChild(fontLink);
      } catch (error) {
        console.error('Error loading social tool assets:', error);
      }
    };

    loadAssets();

    return () => {
      if (appContainer) {
        ReactDOM.unmountComponentAtNode(appContainer);
        appContainer.remove();
      }
    };
  }, []);

  return <div id='noodle-social-app-inject-settings' data-disable-navigation='true' />;
};

export default SocialTool;
