export const connectionTypes = {
  REQUEST: 'CONNECTION_GET_LIST_REQUEST',
  SUCCESS: 'CONNECTION_GET_LIST_SUCCESS',
  FAILED: 'CONNECTION_GET_LIST_FAILED',
  CONNECTION_REQUEST: 'CONNECTION_REQUEST_REQUEST',
  CONNECTION_SUCCESS: 'CONNECTION_REQUEST_SUCCESS',
  CONNECTION_FAILED: 'CONNECTION_REQUEST_FAILED',
  ACCEPT_CONNECTION_REQUEST: 'CONNECTION_ACCEPT_REQUEST',
  ACCEPT_CONNECTION_SUCCESS: 'CONNECTION_ACCEPT_SUCCESS',
  ACCEPT_CONNECTION_FAILED: 'CONNECTION_ACCEPT_FAILED',
  REJECT_CANCEL_CONNECTION_REQUEST: 'CONNECTION_REJECT_CANCEL_REQUEST',
  REJECT_CANCEL_CONNECTION_SUCCESS: 'CONNECTION_REJECT_CANCEL_SUCCESS',
  REJECT_CANCEL_CONNECTION_FAILED: 'CONNECTION_REJECT_CANCEL_FAILED',
};
