import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Auth from 'components/Auth';
import MetaTags from 'components/MetaTags/index';
import Separator from 'components/uielements/Separator';

import { getQueryStringParams } from 'utils/string';
import { useAuth } from 'utils/customHooks/auth';
import { isNonNoodleInstance } from 'utils/privateLabel';
import { getIsPrivateLabel } from 'index';

import { partnerInstanceActions } from 'ducks/partnerInstance/actions';
import authActions from 'ducks/auth/actions';

import { baseUrl, images } from 'settings/index';
import { robotsConstants } from 'commons/constants/general';

import { AUTH_HELP_TEXT } from 'commons/constants/auth';
import { TitleContainer } from './Login.styles';

const LoginMetaTags: React.FC = () => {
  const { pathname } = useLocation();

  const currentPathname = `${baseUrl}${pathname}`;
  const title = `Login`;
  const description = `Login to and access your Noodle Learning Platform account.`;
  return (
    <MetaTags
      title={title}
      image={images.defaults.homeBanner}
      description={description}
      ogTitle={title}
      twitterCard='Noodle'
      ogUrl={currentPathname}
      robots={robotsConstants.INDEX_FOLLOW}
    />
  );
};

const Login: React.FC = () => {
  const { configureAuth0Lock, lockEmailField, setAuthHelpTextHTML, logout, isAuthenticated } =
    useAuth();
  const returnUrl = getQueryStringParams('returnUrl');
  const emailQuery = getQueryStringParams('email');

  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const { isLoading: isGlobalPartnerLoading, data: globalPartner } = useSelector(
    (state: any) => state.partnerInstance.globalPartner,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isGlobalPartnerLoading && globalPartner) {
      const auth0Lock = configureAuth0Lock();

      if (!isLoggedIn) {
        dispatch(
          authActions.loginRequest({
            guestRoute: true,
            pathname: returnUrl ? decodeURIComponent(returnUrl) : '/',
            logout,
          }),
        );
      }

      if (!isLoggedIn && isAuthenticated(globalPartner?.partnerId)) {
        return;
      }

      auth0Lock.hide();
      auth0Lock.show({
        auth: {
          params: {
            state: JSON.stringify({
              authType: 'login',
              returnUrl,
            }),
          },
          redirectUrl: returnUrl,
        },
      });

      // Keeping public labels as noodle, so not locking emails after clicking on auth0 email
      if (emailQuery && getIsPrivateLabel()) {
        lockEmailField();
      }

      auth0Lock.on('signin ready', (error) => {
        if (error) {
          console.error('🚀 ~ file: auth.js ~ line 95 ~ AuthProvider ~ error', error);
        }

        // Removing tabs and adding small text below submit button.
        if (getIsPrivateLabel()) {
          const submitBtn: Record<string, any> = document.querySelector('.auth0-lock-submit');
          const tabsContainer: Record<string, any> = document.querySelector(
            '.auth0-lock-tabs-container',
          );
          const loginTerms: Record<string, any> = document.querySelector('.auth0-lock-login-terms');

          tabsContainer.remove();

          if (!loginTerms) {
            submitBtn.insertAdjacentHTML(
              'afterend',
              "<p class='auth0-lock-login-terms'>If you don’t have the credentials to this site, contact its admin to request your access.</p>",
            );
          }
        }

        if (isNonNoodleInstance) {
          const formContainer: Record<string, any> =
            document.querySelector('.auth0-lock-input-email')?.parentElement;
          formContainer.classList.add('auth0-lock-form-container');

          if (returnUrl?.includes('cartSessionId')) {
            setAuthHelpTextHTML(AUTH_HELP_TEXT.LOGIN);
          }
        }
      });
    }
  }, [isGlobalPartnerLoading, globalPartner]);

  React.useEffect(() => {
    if (!globalPartner) {
      dispatch(partnerInstanceActions.getGlobalPartnerRequest());
    }
  }, []);

  return (
    <Auth role='main' id='main-id'>
      <LoginMetaTags />
      <Auth.Logo />
      <Separator />
      {getIsPrivateLabel() ? (
        <TitleContainer>
          <span>Log In</span>
        </TitleContainer>
      ) : null}
      <Auth.Body />
    </Auth>
  );
};

export default Login;
