import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  noodera: { spacing, fontTypes },
  palette: { greyscale },
} = getTheme();

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 33%;
  min-width: 336px;
  width: 336px;
  height: 100%;
  background: ${greyscale.white};
  align-content: flex-start;
  padding-right: ${spacing.x5};

  & > div {
    display: flex;
    flex-grow: 1;
  }

  & > div:nth-child(1) {
    height: max-content;
    flex-direction: column;
  }

  & > div:last-child {
    flex-direction: column;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    && {
      padding: 0 !important;
      margin: 0;
      margin-bottom: 16px;
      min-width: 100%;
      box-shadow: none;
    }
  }
`;

export const ButtonContainer = styled.div`
  padding: ${spacing.x7} ${spacing.x4} ${spacing.x5} ${spacing.x4};

  & button {
    margin-bottom: ${spacing.x6};
  }
`;

export const Header = styled.div`
  padding: ${spacing.x6} ${spacing.x4};
`;

export const StyledTitle = styled.h4`
  ${fontTypes.font4Medium};
`;

export const FeedWrapper = styled.div<{ marginTop?: boolean; marginBottom?: boolean }>`
  ${({ marginTop }) => (marginTop ? `margin-top: ${spacing.x3};` : '')}
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${spacing.x3};` : '')}
`;
