import styled from 'styled-components';
import { getTheme } from 'settings/theme';

export default (Base: (...args: Array<any>) => any) => styled(Base)`
  font-style: normal;
  font-weight: normal;
  color: ${getTheme().palette.greyscale.outerSpace};

  &.header1 {
    font-family: ${getTheme().fonts.header};
    font-size: 3.2rem;
    line-height: 4rem;
    letter-spacing: -0.3px;
    color: ${getTheme().palette.greyscale.mirage};
  }

  &.header2 {
    font-family: ${getTheme().fonts.header};
    font-size: 2.4rem;
    line-height: 3rem;
  }

  &.header3 {
    font-family: ${getTheme().fonts.primary};
    font-size: 2rem;
    line-height: 3.2rem;
  }

  &.header4 {
    font-family: ${getTheme().fonts.primary};
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &.body1 {
    font-family: ${getTheme().fonts.primary};
    font-size: 1.6rem;
    line-height: 3rem;
  }

  &.body2 {
    font-family: ${getTheme().fonts.primary};
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &.body3 {
    font-family: ${getTheme().fonts.primary};
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`;
