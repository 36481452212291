// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  palette: { neutral, primary, greyscale },
  noodera: { fontTypes, spacing },
  boxShadow,
} = getTheme();
export const Container: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  background: ${(props) => (props.invertColor ? greyscale.white : primary.ten)};
  padding: ${spacing.x4};
  box-shadow: ${boxShadow.default};
  border-radius: 8px;
  margin-bottom: ${spacing.x3};
  position: relative;
  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
`;
export const TitleWrapper: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x6};
`;
export const Title: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font3Medium}
  color: ${neutral.hundred};
  margin-left: 4px;
  text-transform: capitalize;
`;
export const Price: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font2}
  color:${primary.hundred};
  margin-top: ${spacing.x6};
  margin-bottom: ${spacing.x4};
`;
