// @ts-nocheck
import { bookmarkTypes } from 'ducks/bookmarks/types';
import { getNoodlingByIdTypes } from './types';

const initialState = {};

const updateBookmarkItem = (keys, groups, payload) => {
  const resultObject = {};
  keys.forEach((key) => {
    resultObject[key] = groups[key];
    resultObject[key].data = groups[key].data.map((item) =>
      item.id === payload.bookmarkedObjectId
        ? { ...item, bookmarkId: payload.id, bookmarkedByUser: payload.id !== null }
        : item,
    );
  });
  return resultObject;
};

const updateBookmarks = (state, payload) => {
  if (state.noodling.id === payload.bookmarkedObjectId) {
    return {
      ...state,
      noodling: { ...state.noodling, bookmarkId: payload.id, bookmarkedByUser: !!payload.id },
    };
  }

  const schoolsKeys = Object.keys(state.noodling.schoolGroups);
  const institutionKeys = Object.keys(state.noodling.institutionGroups);
  let newState = { ...state };

  if (schoolsKeys.length) {
    newState = {
      ...state,
      noodling: {
        ...state.noodling,
        schoolGroups: updateBookmarkItem(schoolsKeys, state.noodling.schoolGroups, payload),
      },
    };
  }

  if (institutionKeys.length) {
    newState = {
      ...state,
      noodling: {
        ...state.noodling,
        institutionGroups: updateBookmarkItem(
          institutionKeys,
          state.noodling.institutionGroups,
          payload,
        ),
      },
    };
  }

  return newState;
};

const noodlingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getNoodlingByIdTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case getNoodlingByIdTypes.SUCCESS: {
      return { ...state, noodling: payload, isLoading: false };
    }

    case getNoodlingByIdTypes.FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case bookmarkTypes.SUCCESS: {
      if (state.noodling) {
        const newState = updateBookmarks(state, payload);
        return newState;
      }

      return state;
    }

    default:
      return state;
  }
};

export default noodlingReducer;
