import type { PaymentState } from 'commons/types/paymentTypes';
import { createSlice } from '@reduxjs/toolkit';

export const initialState: PaymentState = {
  clientSecret: {
    isLoading: false,
    data: '',
    error: '',
  },
  userAlreadyPaid: false,
  paymentForm: {
    step: 1,
    invoiceId: null,
  },
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // Action name is inferred from the reducer key here:
    paymentGetStripeClientSecretRequest(state) {
      state.clientSecret.isLoading = true;
    },
    paymentGetStripeClientSecretForCourseRequest(state) {
      state.clientSecret.isLoading = true;
    },
    paymentGetStripeClientSecretSuccess(state, action) {
      state.clientSecret.data = action.payload;
      state.clientSecret.isLoading = false;
    },
    paymentGetStripeClientSecretFailure(state, action) {
      state.clientSecret.error = action.payload;
      state.clientSecret.isLoading = false;
      state.clientSecret.data = '';
    },
    paymentGetStripeClientSecretReset(state) {
      state.clientSecret.error = '';
      state.clientSecret.isLoading = false;
      state.clientSecret.data = '';
    },
    paymentSetUserAlreadyPaid(state, action) {
      state.userAlreadyPaid = action.payload;
    },
    paymentSetUserPurchaseFormStep(state, action) {
      state.paymentForm.step = action.payload;
    },
    paymentResetUserPurchaseFormStep(state) {
      state.paymentForm.step = 1;
    },
    paymentSetFormInvoice(state, action) {
      state.paymentForm.invoiceId = action.payload;
    },
  },
});

// We export all the auto inferred actions from the slice to use for dispatches and
// redux saga
export const {
  paymentGetStripeClientSecretRequest,
  paymentGetStripeClientSecretSuccess,
  paymentGetStripeClientSecretFailure,
  paymentGetStripeClientSecretForCourseRequest,
  paymentGetStripeClientSecretReset,
  paymentSetUserAlreadyPaid,
  paymentSetUserPurchaseFormStep,
  paymentResetUserPurchaseFormStep,
  paymentSetFormInvoice,
} = paymentSlice.actions;

// We export the reducer from the slice
export default paymentSlice.reducer;
