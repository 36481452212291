// External dependencies
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

// Internal APIs and services
import api from 'lib/Api';
import { getIsPrivateLabel } from 'index';

// Redux/state management
import { getStreamActions } from 'ducks/getStream/actions';
import { loginTypes } from './types';
import authActions from './actions';
import { getGlobalPartner } from './selector';

// Utilities
import { getCookie, getPartnerCookie } from 'utils/cookies';
import { setPrivateInstanceCookies } from 'utils/auth';
import { getSessionStorageData } from 'utils/sessionStorage';
import { UTM_VALUES } from 'utils/heap';
import { NOODLE_CART_SESSION_ID_COOKIE_KEY } from 'commons/types/shoppingCartTypes';

export function* loginRequestSaga({ payload }) {
  let { pathname } = payload;
  const { logout, guestRoute } = payload;
  const { data: globalPartner } = yield select(getGlobalPartner);

  try {
    if (!getPartnerCookie(`access_token`, globalPartner?.partnerId)) {
      yield put(authActions.loginFailed(null));
      return;
    }

    // Should only log UTMs if the user in on the vanity URL domain if it exists, otherwise on the .noodle.com domain
    const shouldLogUTMs =
      !globalPartner?.vanityUrlEnabled || !window.location.hostname.includes('.noodle.com');

    const userData = yield api.user.getOwnUser(pathname, shouldLogUTMs);

    const expiresIn = Number(getCookie('expires_at'));

    setPrivateInstanceCookies(expiresIn, globalPartner);

    if (pathname === '/onboarding') {
      const rolesArray: any[] = Object.values(userData?.roles);
      if (
        !!rolesArray.filter((r) => r?.name === 'instance-admin').length ||
        !!rolesArray.filter((r) => r?.name === 'parent-instance-admin').length
      ) {
        pathname = '/';
      }
    }

    yield put(
      authActions.loginSuccess({
        user: userData,
        pathname,
      }),
    );
    yield put(getStreamActions.getUserTokenRequest());

    // After logging in into Auth0, set cookies for both domains to persist the session using the Authorize page.
    if (
      globalPartner?.vanityUrlEnabled &&
      window.location.hostname.includes('.noodle.com') &&
      window.location.pathname.includes('login')
    ) {
      const utmParamsObj = getSessionStorageData(UTM_VALUES);
      const utmParams = utmParamsObj ? new URLSearchParams(utmParamsObj) : null;

      const cartSessionId = getCookie(NOODLE_CART_SESSION_ID_COOKIE_KEY);
      const cartSessionIdQueryString = cartSessionId ? `&cart_session_id=${cartSessionId}` : '';

      const pathnameUtmLeadingSymbol = pathname.includes('?') ? '&' : '?';
      const utmQueryString = utmParams ? `${pathnameUtmLeadingSymbol}${utmParams.toString()}` : '';

      // Encoding the return URL to ensure query params are preserved
      const returnUrl = encodeURIComponent(`${pathname}${utmQueryString}`);
      const url = `${globalPartner.vanityUrl}/authorize?access_token=${getCookie(
        'access_token',
      )}&expires_in=${expiresIn}${cartSessionIdQueryString}&return_url=${returnUrl}`;

      window.location.replace(url);
    } else if (pathname?.includes('http')) {
      window.location.replace(pathname);
    } else if (pathname !== window.location.pathname) {
      yield put(push(pathname));
    }
  } catch (err) {
    // Push the user to our forbidden page if it's a private label and they don't have access
    if (err && err.response?.status === 403 && getIsPrivateLabel()) {
      yield put(push('/forbidden'));
    } else if (guestRoute) {
      yield put(authActions.loginFailed(null));
    } else {
      yield call(logout, { partnerId: globalPartner?.partnerId });
    }
  }
}

export default function* authSaga() {
  // @ts-ignore
  yield takeEvery(loginTypes.REQUEST, loginRequestSaga);
}
