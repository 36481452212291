/* eslint-disable no-unused-vars */
import * as React from 'react';
import InviteMembersInput from 'components/InviteMembersInput/index';
import { inviteMembersLabels } from 'commons/constants/createTeam';
import {
  Container,
  Wrapper,
  Title,
  TitleWrapper,
  SubTitle,
  InputChipsContainer,
} from './InviteMembers.styles';
import type { InviteMembersProps } from './InviteMembers.types';

const InviteMembers = ({ data, onChange }: InviteMembersProps): React.ReactElement<any> | null => (
  <Wrapper>
    <Container>
      <TitleWrapper>
        <Title>{inviteMembersLabels.title}</Title>
        <SubTitle>{inviteMembersLabels.subtitle}</SubTitle>
      </TitleWrapper>
      <InputChipsContainer>
        <InviteMembersInput data={data} onChange={onChange} />
      </InputChipsContainer>
    </Container>
  </Wrapper>
);

export default InviteMembers;
