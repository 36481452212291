// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();
export const TitleContainer: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font3Medium};
  background: ${greyscale.white};
  padding: ${spacing.x5};
`;
