import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type {
  CreateRequestType,
  CreateBodyType,
  DeleteRequestType,
  DeleteObjectRequestType,
} from './like.types';

export default class Like {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async createLike({ id, endpoint, type }: CreateRequestType): Promise<any> {
    const body = {
      type,
    };
    return this.jsonApi.post(`/${endpoint}/${id}/likes`, body, false);
  }

  async createArticleLike({ id }: CreateBodyType): Promise<any> {
    const response = await this.createLike({
      id,
      endpoint: 'articles',
      type: 'article_like',
    });
    const articleLike = get(response, 'entities.articleLike', {});
    return articleLike;
  }

  async createInternalArticleLike({ id }: CreateBodyType): Promise<any> {
    const response = await this.createLike({
      id,
      endpoint: 'internal_articles',
      type: 'internal_article_like',
    });
    const articleLike = get(response, 'entities.internalArticleLike', {});
    return articleLike;
  }

  async createPostLike({ id }: CreateBodyType): Promise<any> {
    const response = await this.createLike({
      id,
      endpoint: 'posts',
      type: 'post_like',
    });
    const postLike = get(response, 'entities.postLike', {});
    return postLike;
  }

  async deleteLike({ entityId, likeId, endpoint }: DeleteRequestType): Promise<any> {
    return this.jsonApi.delete(`${endpoint}/${entityId}/likes/${likeId}`, {});
  }

  async deleteArticleLike({ id, likeId }: DeleteObjectRequestType) {
    return this.deleteLike({
      entityId: id,
      likeId,
      endpoint: 'articles',
    });
  }

  async deleteInternalArticleLike({ id, likeId }: DeleteObjectRequestType) {
    return this.deleteLike({
      entityId: id,
      likeId,
      endpoint: 'internal_articles',
    });
  }

  async deletePostLike({ id, likeId }: DeleteObjectRequestType) {
    return this.deleteLike({
      entityId: id,
      likeId,
      endpoint: 'posts',
    });
  }
}
