import { autosuggestTypes } from './types';

const initState = {
  isLoading: false,
  suggestions: [],
  userInput: '',
};

const autosuggestReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case autosuggestTypes.UPDATE: {
      return { ...state, userInput: payload };
    }

    case autosuggestTypes.CLEAR: {
      return { ...state, suggestions: [] };
    }

    case autosuggestTypes.CLEAR_USER_INPUT: {
      return { ...state, userInput: '' };
    }

    default:
      return state;
  }
};

export default autosuggestReducer;
