// @ts-nocheck
import * as React from 'react';
import type { NooderaTagProps } from './NooderaTag.types';
import { Container, ContainerIcon, Text } from './NooderaTag.styles';

const NooderaTag: React.StatelessFunctionalComponent<NooderaTagProps> = ({
  icon,
  text,
  background,
  textColor,
  enableHover,
  onClick,
  className,
  ...otherProps
}) => (
  <Container
    {...otherProps}
    className={className}
    onClick={onClick}
    background={background}
    enableHover={enableHover}
    hasText={Boolean(text)}
  >
    {icon && <ContainerIcon hasText={Boolean(text)}>{icon}</ContainerIcon>}
    {text && <Text textColor={textColor}>{text}</Text>}
  </Container>
);

export default NooderaTag;
