import type {
  Bookmarkable,
  BookmarkObject,
  GetBookmarkedItemsSuccessPayload,
  GetBookmarkedItemsType,
} from 'commons/types';
import {
  bookmarkTypes,
  bookmarkedItemsTypes,
  saveArticleTypes,
  removeArticleTypes,
  getSavedItemsTypes,
} from './types';

export const bookmarkActions = {
  bookmarkRequest: (payload: Bookmarkable) => ({
    type: bookmarkTypes.REQUEST,
    payload,
  }),
  bookmarkSuccess: (payload: BookmarkObject) => ({
    type: bookmarkTypes.SUCCESS,
    payload,
  }),
  bookmarkFailure: (payload: string) => ({
    type: bookmarkTypes.FAILURE,
    payload,
  }),
  getBookmarkedItemsRequest: (payload: GetBookmarkedItemsType) => ({
    type: bookmarkedItemsTypes.REQUEST,
    payload,
  }),
  getBookmarkedItemsSuccess: (payload: GetBookmarkedItemsSuccessPayload) => ({
    type: bookmarkedItemsTypes.SUCCESS,
    payload,
  }),
  getBookmarkedItemsFailure: (payload: string) => ({
    type: bookmarkedItemsTypes.FAILURE,
    payload,
  }),
  getSavedItems: (payload: any) => ({
    type: getSavedItemsTypes.REQUEST,
    payload,
  }),
  getSavedItemsSuccess: (payload: any) => ({
    type: getSavedItemsTypes.SUCCESS,
    payload,
  }),
  getSavedItemsFailure: (payload: any) => ({
    type: getSavedItemsTypes.FAILURE,
    payload,
  }),
  saveArticle: (payload: any) => ({
    type: saveArticleTypes.SAVE_ARTICLE_REQUEST,
    payload,
  }),
  saveArticleSuccess: (payload: any) => ({
    type: saveArticleTypes.SAVE_ARTICLE_SUCCESS,
    payload,
  }),
  saveArticleFailure: (payload: string) => ({
    type: saveArticleTypes.SAVE_ARTICLE_FAILURE,
    payload,
  }),
  removeArticle: (payload: any) => ({
    type: removeArticleTypes.REMOVE_ARTICLE_REQUEST,
    payload,
  }),
  removeArticleSuccess: (payload: any) => ({
    type: removeArticleTypes.REMOVE_ARTICLE_SUCCESS,
    payload,
  }),
  removeArticleFailure: (payload: string) => ({
    type: removeArticleTypes.REMOVE_ARTICLE_FAILURE,
    payload,
  }),
};
