import type { Section, CreateSectionPayload } from 'commons/types/sectionTypes';
import {
  createSectionTypes,
  getMySectionsTypes,
  getSectionPreviewTypes,
  sectionTypes,
  getSectionsTypes,
  joinSectionTypes,
  joinSelfEnrolledSectionTypes,
  removeJoinSectionTypes,
  removeJoinSelfEnrolledSectionTypes,
} from './types';
import { JoinSelfEnrolledSectionPayload } from './saga';

export const sectionActions = {
  getMySectionsRequest: () => ({
    type: getMySectionsTypes.GET_MY_SECTIONS_REQUEST,
  }),
  getMySectionsSuccess: (payload: Section[]) => ({
    type: getMySectionsTypes.GET_MY_SECTIONS_SUCCESS,
    payload,
  }),
  getMySectionsFailure: (payload: string) => ({
    type: getMySectionsTypes.GET_MY_SECTIONS_FAILURE,
    payload,
  }),
  selectSection: (payload: Section | null) => ({
    type: sectionTypes.SELECT_SECTION,
    payload,
  }),
  getSectionPreviewRequest: (payload: number) => ({
    type: getSectionPreviewTypes.GET_SECTION_PREVIEW_REQUEST,
    payload,
  }),
  getSectionPreviewSuccess: (payload: Section) => ({
    type: getSectionPreviewTypes.GET_SECTION_PREVIEW_SUCCESS,
    payload,
  }),
  getSectionPreviewFailure: (payload: string) => ({
    type: getSectionPreviewTypes.GET_SECTION_PREVIEW_FAILURE,
    payload,
  }),
  createSectionRequest: (payload: CreateSectionPayload) => ({
    type: createSectionTypes.CREATE_SECTION_REQUEST,
    payload,
  }),
  createSectionSuccess: (payload: boolean) => ({
    type: createSectionTypes.CREATE_SECTION_SUCCESS,
    payload,
  }),
  createSectionFailure: (payload: string) => ({
    type: createSectionTypes.CREATE_SECTION_FAILURE,
    payload,
  }),
  createSectionReset: () => ({
    type: createSectionTypes.CREATE_SECTION_RESET,
  }),
  getSectionsRequest: (payload?: any) => ({
    type: getSectionsTypes.GET_SECTIONS_REQUEST,
    payload,
  }),
  getSectionsSuccess: (payload: any) => ({
    type: getSectionsTypes.GET_SECTIONS_SUCCESS,
    payload,
  }),
  getSectionsFailure: (payload: string) => ({
    type: getSectionsTypes.GET_SECTIONS_FAILURE,
    payload,
  }),
  joinSectionRequest: (payload: any) => ({
    type: joinSectionTypes.JOIN_SECTION_REQUEST,
    payload,
  }),
  joinSectionSuccess: (payload: any) => ({
    type: joinSectionTypes.JOIN_SECTION_SUCCESS,
    payload,
  }),
  joinSectionFailure: (payload: string) => ({
    type: joinSectionTypes.JOIN_SECTION_FAILURE,
    payload,
  }),
  joinSelfEnrolledSectionRequest: (payload: JoinSelfEnrolledSectionPayload['payload']) => ({
    type: joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_REQUEST,
    payload,
  }),
  joinSelfEnrolledSectionSuccess: (payload: any) => ({
    type: joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_SUCCESS,
    payload,
  }),
  joinSelfEnrolledSectionFailure: (payload: string) => ({
    type: joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_FAILURE,
    payload,
  }),
  resetJoinSection: () => ({
    type: removeJoinSectionTypes.REMOVE_JOIN_SECTION,
  }),
  resetJoinSelfEnrolledSection: () => ({
    type: removeJoinSelfEnrolledSectionTypes.REMOVE_SELF_ENROLLED_JOIN_SECTION,
  }),
};
