import * as React from 'react';
import { useSelector } from 'react-redux';
import HamburgerMenu from 'components/HamburgerMenu/index';
import NotificationHeader from 'components/NotificationHeader';
import Bell from 'components/Bell';
import { useAutoSelect, useIsAdmin } from 'utils/customHooks';
import { accessibilityConstants, relConstants } from 'commons/constants/general';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { isMainTitleAllowedRoute, redirectWithUtmQueryStrings } from 'utils/urls/index';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import NooderaModal from 'components/NooderaModal/index';
import LoaderInline from 'components/uielements/LoaderInline/index';
import NooderaButton from 'components/NooderaButton/index';
import AdminAction from 'components/AdminAction';
import { getIsPrivateLabel } from 'index';
import { useGetShoppingCartQuery } from 'lib/Api';
import ShoppingCartIcon from 'components/ShoppingCartIcon';
import { getSessionStorageData } from 'utils/sessionStorage';
import { UTM_VALUES } from 'utils/heap';
import {
  BreadcrumbWrapper,
  HeaderActionsContainer,
  HeaderTitleWrapper,
  HeaderContainer,
  SearchLink,
  MainTitle,
  StyledExternalLinksList,
  StyledLogo,
  LoadingContainer,
  LoadingText,
  ActionsContainer,
} from './Header.styles';

export type HeaderProps = {
  title: {
    value: string;
    type: string;
    url?: string;
  };
};

const Header: React.FC<HeaderProps> = ({ title }) => {
  const isAdmin = useIsAdmin();
  const { pathname } = window.location;
  const myTeams = useSelector((state) => state.team.myTeams.data);
  const mySections = useSelector((state) => state.section.mySections.data);
  const myTeamsLoading = useSelector((state) => state.team.myTeams.isLoading);
  const mySectionsLoading = useSelector((state) => state.section.mySections.isLoading);
  const { data: teams, isLoading: isTeamsLoading } = useSelector((state) => state.team.teams);
  const { data: sections, isLoading: isSectionsLoading } = useSelector(
    (state) => state.section.sections,
  );
  const unseenNotifications = useSelector((state) => state.getStream.notifications.data?.unseen);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const selectedTeam = useSelector((state) => state.team.selectedTeam);
  const selectedSection = useSelector((state) => state.section.selectedSection);
  const { data: globalPartner, isLoading: isGlobalPartnerLoading } = useSelector(
    (state) => state.partnerInstance.globalPartner,
  );
  const { data: shoppingCartData } = useGetShoppingCartQuery({ isTemporary: false });

  const [isLoadingModalOpen, setIsLoadingModalOpen] = React.useState(false);
  const [loadingModalText, setLoadingModalText] = React.useState('');

  const utmParams: Record<string, string> = getSessionStorageData(UTM_VALUES);

  const myTotalLength = myTeams?.length + mySections?.length;
  const totalTeamLength = teams?.length + sections?.length;
  const shouldAutoSelect = isAdmin
    ? !isTeamsLoading && !isSectionsLoading && totalTeamLength === 1
    : !myTeamsLoading && !mySectionsLoading && myTotalLength === 1;
  const showLongLogo = !isLoggedIn && pathname === '/';
  const showDropdown =
    pathname.includes('/resources/saved') ||
    pathname.includes('/courses/saved') ||
    pathname === '/';
  const shouldRenderSimpleHeader =
    shouldAutoSelect &&
    showDropdown &&
    !pathname.includes('programs') &&
    !pathname.includes('interests');
  const isMainTitleAllowed = isMainTitleAllowedRoute(pathname);
  const isShoppingCartEnabled = globalPartner?.isShoppingCartEnabled;

  useAutoSelect();

  const renderTitle = (): React.ReactElement | null => {
    const autoSelectedName = selectedTeam?.name || selectedSection?.name;

    if (showLongLogo) {
      return null;
    }

    if (pathname === '/notifications') {
      return <NotificationHeader />;
    }

    if (shouldRenderSimpleHeader && autoSelectedName) {
      // Adding this since we're having 2 types of titles for now. Partial redesign
      if (isMainTitleAllowed) {
        return <h2>{autoSelectedName}</h2>;
      }

      return <MainTitle>{autoSelectedName}</MainTitle>;
    }

    if (title.type === 'hyperlink') {
      return (
        <BreadcrumbWrapper>
          <a
            rel={relConstants.NOREFERRER_NOOPENER_NOFOLLOW}
            href={title.url}
            aria-label={title.value}
          >
            {title.value}
          </a>
        </BreadcrumbWrapper>
      );
    }

    return null;
  };

  const ExternalLinks = (): React.ReactElement =>
    !isLoggedIn &&
    !isNonNoodleInstance && (
      <StyledExternalLinksList>
        <a
          href='https://highered.noodle.com/'
          target='_blank'
          rel='noreferrer'
          aria-label='For Universities'
        >
          For Universities
        </a>
        <a
          href='https://employers.noodle.com/'
          target='_blank'
          rel='noreferrer'
          aria-label='For Employers'
        >
          For Employers
        </a>
        <a href='https://about.noodle.com/' target='_blank' rel='noreferrer' aria-label='About'>
          About
        </a>
      </StyledExternalLinksList>
    );

  const Logo = (): React.ReactElement => {
    const { companyLogo } = getAppSettings() || globalPartner;
    if (React.isValidElement(companyLogo)) {
      return companyLogo;
    }

    return <StyledLogo src={companyLogo} alt='' title='Noodle' />;
  };

  const PrivateLabelHeaderMenu = (): React.ReactElement => {
    const isPrivateLabel = getIsPrivateLabel();

    return (
      <ActionsContainer>
        {isShoppingCartEnabled && <ShoppingCartIcon shoppingCartData={shoppingCartData} />}
        <NooderaButton
          size='medium'
          tabIndex={0}
          aria-label='Log in'
          onClick={() => {
            redirectWithUtmQueryStrings(`${globalPartner.frontDomain}/login`, utmParams);
          }}
        >
          Log In
        </NooderaButton>
        {!isPrivateLabel && (
          <NooderaButton.CTA
            size='medium'
            tabIndex={0}
            aria-label='Sign Up'
            onClick={() => {
              redirectWithUtmQueryStrings(`${globalPartner.frontDomain}/signup`, utmParams);
            }}
          >
            Sign Up
          </NooderaButton.CTA>
        )}
      </ActionsContainer>
    );
  };

  return (
    <HeaderContainer showLongLogo={showLongLogo}>
      {showLongLogo && !isGlobalPartnerLoading && (
        <a href='/' aria-label='Go to Home'>
          <Logo />
        </a>
      )}
      <HeaderTitleWrapper>{renderTitle()}</HeaderTitleWrapper>
      <ExternalLinks />
      {!isNonNoodleInstance || isLoggedIn ? (
        <HeaderActionsContainer>
          <AdminAction
            setIsLoadingModalOpen={setIsLoadingModalOpen}
            setLoadingModalText={setLoadingModalText}
          />
          {!isNonNoodleInstance && <Bell count={unseenNotifications} />}
          {!isNonNoodleInstance && (
            <SearchLink
              title={accessibilityConstants.globalSearchLink.title}
              aria-label={accessibilityConstants.globalSearchLink.ariaLabel}
              to={{
                pathname: '/search',
                state: {
                  prevPath: window.location.pathname,
                },
              }}
            >
              <SearchIcon />
            </SearchLink>
          )}
          {isShoppingCartEnabled && <ShoppingCartIcon shoppingCartData={shoppingCartData} />}
          <HamburgerMenu />
        </HeaderActionsContainer>
      ) : (
        <PrivateLabelHeaderMenu />
      )}
      <NooderaModal hasAppBar={false} isOpen={isLoadingModalOpen}>
        <LoadingContainer>
          <LoaderInline width='9.6rem' />
          <LoadingText>{loadingModalText}</LoadingText>
        </LoadingContainer>
      </NooderaModal>
    </HeaderContainer>
  );
};

export default Header;
