// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import 'moment-timezone';
import { Provider, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import SwiperCore, { Navigation } from 'swiper';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'react-activity-feed/dist/index.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
// Swiper CSS
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { GlobalStyles } from 'GlobalStyles';
import { HeaderProvider } from 'utils/customHooks';
import { AuthProvider } from 'utils/customHooks/auth';
import { createBrowserHistory } from 'history';

import { PARTNER_INSTANCE_MODE } from 'commons/constants/partnerInstance';
import { isNonNoodleInstance } from 'utils/privateLabel';
import SocialTool from './components/SocialTool';

import store, { history } from './ducks/store';
import Routes from './router';
import './noodle.css';
import Theme from './theme';

// NOTE: For some reason exporting an `isProduction` constant from `settings/index.js` and importing it here isn't
// working, hence this solution
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react'); // eslint-disable-line global-require

  axe(React, ReactDOM, 1000);
}

TagManager.initialize({
  gtmId: window.location.href.includes('noodle-partners') ? 'GTM-M9G5WR4' : 'GTM-NTJ6DST',
});

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } = process.env;

if (
  REACT_APP_SENTRY_DSN &&
  REACT_APP_SENTRY_ENVIRONMENT &&
  !['dev'].includes(REACT_APP_SENTRY_ENVIRONMENT)
) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

// eslint-disable-next-line func-names
moment.fn.zoneName = function () {
  const timezoneNames = {
    EST: 'Eastern Time',
    EDT: 'Eastern Time',
    CST: 'Central Time',
    CDT: 'Central Time',
    MST: 'Mountain Time',
    MDT: 'Mountain Time',
    PST: 'Pacific Time',
    PDT: 'Pacific Time',
    AKST: 'Alaska Time',
    HST: 'Hawaii Time',
  };
  const abbr = this.zoneAbbr();
  return timezoneNames[abbr] || abbr;
};

// TODO: Find a way to have this util be in a util file?
// It's not working in other files because of crazy circular dependencies 😔
export const getIsPrivateLabel = () => {
  const {
    partnerInstance: {
      globalPartner: { data: globalPartner },
    },
  } = store.getState();

  return isNonNoodleInstance && globalPartner?.mode === PARTNER_INSTANCE_MODE.PRIVATE;
};

SwiperCore.use([Navigation]);

const SocialToolContainer = () => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance?.globalPartner);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  if (!globalPartner?.isSocialToolEnabled || !isLoggedIn) {
    return null;
  }

  return <SocialTool />;
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Suspense fallback={<div />}>
        <AuthProvider>
          <Theme>
            <HeaderProvider>
              <Routes />
              <GlobalStyles />
              <SocialToolContainer />
            </HeaderProvider>
          </Theme>
        </AuthProvider>
      </React.Suspense>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
