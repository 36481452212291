// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_WIDTH } from 'commons/constants/size';

const {
  palette: { greyscale, neutral, primary },
  noodera: { fontTypes, spacing },
} = getTheme();

const getSearchBarCss = (isDisabled: boolean) =>
  isDisabled
    ? `
  border-color: ${neutral.ten};
  background: ${neutral.ten};
  label {
    input {
      background: ${neutral.ten};
       
    }
  }

  @media only screen and (max-width: ${TABLET_WIDTH}px) { 
    border-color: ${primary.sixty};
    background: ${primary.eighty};
     label {
      input { 
        background: ${primary.eighty};
      }
    }
  }
`
    : `
  background: ${greyscale.white};
  label {
    input {
      background: ${greyscale.white};
    }
  }

  @media only screen and (max-width: ${TABLET_WIDTH}px) { 
    background: ${neutral.sixty};
    border-color: ${primary.sixty};
    label {
      input {
        background: ${neutral.sixty};
      }
    }
  }
`;

export const SearchBarWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 ${getTheme().noodera.spacing.x4};
  border-radius: 16px;
  border: 1px solid ${neutral.sixty};
  ${(props) => getSearchBarCss(props.isDisabled)}
  ${(props) => props.focused && `border-color: ${neutral.hundred};`}

  label {
    width: calc(100% - 32px);
    height: 100%;
    margin-bottom: unset;
    input {
      width: 100%;
      height: 100%;
      border: none;
      color: ${neutral.hundred};
      ${fontTypes.font4};
      caret-color: ${primary.hundred};
      ::placeholder {
        color: ${neutral.seventy};
        ${fontTypes.font5};
        text-transform: uppercase;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: ${spacing.x7};
    path {
      fill: ${neutral.sixty};
    }
  }

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    ${(props) => props.focused && `border-color: ${greyscale.white};`}

    label {
      input {
        color: ${greyscale.white};
        caret-color: ${primary.forty};
        ::placeholder {
          color: ${primary.ten};
        }
      }
    }
    svg {
      path {
        fill: ${greyscale.white};
      }
    }
  }
`;
