import { loginTypes, clickOnNavBarTypes } from './types';

// Get latest Search
const getLastUpdatedView = ({ user }) => {
  if (!user) {
    return -1;
  }

  const { searches } = user;

  if (!searches) {
    return -1;
  }

  const keys = Object.keys(searches);

  if (!keys.length) {
    return -1;
  }

  const search = keys.reduce((a, b) => (searches[a].updatedAt > searches[b].updatedAt ? a : b));
  return search ? searches[search] : null;
};

const authActions = {
  loginRequest: (payload) => ({
    type: loginTypes.REQUEST,
    payload,
  }),
  loginSuccess: (payload) => ({
    type: loginTypes.SUCCESS,
    payload: { ...payload, activeView: getLastUpdatedView(payload) },
  }),
  loginFailed: (error) => ({
    type: loginTypes.FAILED,
    payload: error,
  }),
  loginReset: () => ({
    type: loginTypes.RESET,
  }),
  clickOnHomeIcon: () => ({
    type: clickOnNavBarTypes.CLICK_HOME,
  }),
  clickOnSearchIcon: () => ({
    type: clickOnNavBarTypes.CLICK_SEARCH,
  }),
  clickOnLoginButton: () => ({
    type: clickOnNavBarTypes.CLICK_LOGIN,
  }),
  clickOnSignUpButton: () => ({
    type: clickOnNavBarTypes.CLICK_SIGN_UP,
  }),
};
export default authActions;
