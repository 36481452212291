export const guestHomeHeroBlock = {
  TITLE: "Let's Learn. Together",
  PARAGRAPH:
    'Discover the best educational content for your career aspirations, get support from real experts, and upskill in a more social way.',
};
export const guestHomeFirstBlock = {
  TITLE: 'Flexibility is the Key to Your Dreams',
  PARAGRAPH:
    'With online learning, you set the pace and choose your study environment. Embrace a tailored approach that fits your lifestyle, allowing you to balance work, family, and passions while pursuing knowledge. Unlock boundless possibilities at your fingertips.',
};
