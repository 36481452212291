import * as React from 'react';
import {
  IconForButton,
  StyledButton,
  StyledCTA,
  StyledRound,
  StyledRoundCTA,
  StyledRoundCTADark,
  TextLinkButton,
} from './NooderaButton.styles';
import type { NooderaButtonProps, CTAProps, TextLinkProps } from './NooderaButton.types';

const NooderaButton = ({
  size = 'default',
  children,
  isDisabled,
  isActive,
  icon = null,
  ...otherProps
}: NooderaButtonProps): React.ReactElement<any> => (
  <StyledButton
    {...otherProps}
    isActive={isActive}
    size={size}
    isDisabled={isDisabled}
    role='button'
  >
    {icon && <IconForButton>{icon}</IconForButton>}
    {children}
  </StyledButton>
);

const CTA = ({
  size = 'default',
  children,
  isDisabled,
  icon = null,
  ...otherProps
}: CTAProps): React.ReactElement<any> => (
  <StyledCTA
    {...otherProps}
    size={size}
    isDisabled={isDisabled}
    disabled={isDisabled}
    role='button'
  >
    {icon && <IconForButton>{icon}</IconForButton>}
    {children}
  </StyledCTA>
);

const Round = ({
  size = 'default',
  children,
  isDisabled,
  ...otherProps
}: CTAProps): React.ReactElement<any> => (
  <StyledRound {...otherProps} size={size} isDisabled={isDisabled} role='button'>
    {children}
  </StyledRound>
);

const RoundCTA = ({
  size = 'default',
  children,
  isDisabled,
  ...otherProps
}: CTAProps): React.ReactElement<any> => (
  <StyledRoundCTA {...otherProps} size={size} isDisabled={isDisabled} role='button'>
    {children}
  </StyledRoundCTA>
);

const RoundCTADark = ({
  size = 'default',
  children,
  isDisabled,
  ...otherProps
}: CTAProps): React.ReactElement<any> => (
  <StyledRoundCTADark {...otherProps} size={size} isDisabled={isDisabled} role='button'>
    {children}
  </StyledRoundCTADark>
);

const TextLink = ({
  children,
  isDisabled,
  ...otherProps
}: TextLinkProps): React.ReactElement<any> => (
  <TextLinkButton {...otherProps} isDisabled={isDisabled} role='button'>
    {children}
  </TextLinkButton>
);

NooderaButton.CTA = CTA;
NooderaButton.Round = Round;
NooderaButton.RoundCTA = RoundCTA;
NooderaButton.RoundCTADark = RoundCTADark;
NooderaButton.TextLink = TextLink;
export default NooderaButton;
