// @ts-nocheck
import JsonApi from 'utils/jsonApi';
import get from 'lodash.get';
import type { SchedulerObject } from 'commons/types';

export default class AdminScheduler {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getTimekitProjects(): Promise<SchedulerObject[]> {
    const response = await this.jsonApi.get('/timekit_projects');
    const timekitProjectsObj = get(response, 'entities.timekitProject', {});
    const timekitProjects: Record<string, any>[] = Object.values(timekitProjectsObj);
    return timekitProjects;
  }

  async getTimekitProject(payload: string): Promise<SchedulerObject> {
    const response = await this.jsonApi.get(`/timekit_projects/${payload}`);
    const schedulerObj = get(response, 'entities.timekitProject', {});
    const scheduler: Record<string, any> = Object.values(schedulerObj)[0];
    return scheduler;
  }

  async createTimekitProject(payload: any): Promise<SchedulerObject> {
    const response = await this.jsonApi.post(
      '/timekit_projects',
      {
        attributes: payload,
        type: 'timekit_project',
      },
      false,
    );
    const scheduler = get(response, 'entities.timekitProject.1', {});
    return scheduler;
  }

  async updateTimekitProject(payload: any): Promise<SchedulerObject> {
    const { id, ...rest } = payload;
    const response = await this.jsonApi.patch(`/timekit_projects/${id}`, {
      id,
      attributes: { ...rest },
      type: 'timekit_project',
    });
    const scheduler = get(response, 'entities.timekitProject.1', {});
    return scheduler;
  }

  async deleteTimekitProject(timekitId: string): Promise<SchedulerObject> {
    const response = await this.jsonApi.delete(`/timekit_projects/${timekitId}`);
    const scheduler = get(response, 'entities.timekitProject.1', {});
    return scheduler;
  }
}
