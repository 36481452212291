import * as React from 'react';
import { Form } from 'reactstrap';
import NForm from './Form.styles';
import type { FormProps } from './Form.types';

const NoodleForm = ({ children, ...rest }: FormProps) => (
  <NForm>
    <Form {...rest}>{children}</Form>
  </NForm>
);

NoodleForm.displayName = 'NoodleForm';
export default NoodleForm;
