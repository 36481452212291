// @ts-nocheck
import * as React from 'react';
import StripePayment from 'components/StripePayment/index';
import SectionInformation from 'components/SectionInformation';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import NooderaButton from 'components/NooderaButton';
import type { PaymentProps } from './Payment.types';
import { Container, Title, Wrapper } from './Payment.styles';

const Payment = ({
  onNextStep,
  ctaText,
  courseSlug,
}: PaymentProps): React.ReactElement<any> | null => (
  <Wrapper>
    <Container>
      <SectionInformation
        icon={<ShieldOutlinedIcon />}
        title='How Noodle keeps my information safe?'
        description='Every course on Noodle is intended to be taken with likeminded learners. You’ll be able to chat, share notes, and learn material together. Select a Team below to join and get started!'
      />
      <Title>Payment Information</Title>
      <StripePayment
        submitElement={
          <NooderaButton.CTA isFullWidth id='finish-paid-course-enrollment' type='submit'>
            {ctaText}
          </NooderaButton.CTA>
        }
        onPaymentSuccess={onNextStep}
        paymentData={courseSlug}
        paymentType='course'
      />
    </Container>
  </Wrapper>
);

export default Payment;
