import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { useIsInstanceAdmin, useIsUniversityAdmin } from 'utils/customHooks';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { isNonNoodleInstance } from 'utils/privateLabel';
import { StyledNooderaButton, StyledNooderaCTA } from './AdminAction.styles';

type AdminActionProps = {
  setIsLoadingModalOpen: Dispatch<SetStateAction<boolean>>;
  setLoadingModalText: Dispatch<SetStateAction<string>>;
};

const AdminAction = ({
  setIsLoadingModalOpen,
  setLoadingModalText,
}: AdminActionProps): React.ReactElement<any> | null => {
  const history = useHistory();
  const isInstanceAdmin = useIsInstanceAdmin();
  const isUniAdmin = useIsUniversityAdmin();
  const { pathname } = window.location;

  const handleLoaderChange = (url: string, modalText: string): void => {
    setIsLoadingModalOpen(true);
    setLoadingModalText(modalText);
    setTimeout(() => {
      history.push(url);
      setIsLoadingModalOpen(false);
    }, 2000);
  };

  const renderAdminAction = (): React.ReactElement<any> | null => {
    if (!isInstanceAdmin || (isUniAdmin && isNonNoodleInstance)) {
      return null;
    }

    if (pathname.includes('/instance')) {
      return (
        <StyledNooderaButton
          onClick={() => handleLoaderChange('/', 'Loading Learning Platform...')}
          size='medium'
          aria-label='Go to Learning Platform'
        >
          Learning Platform <LaunchOutlinedIcon />
        </StyledNooderaButton>
      );
    }

    return (
      <StyledNooderaCTA
        onClick={() => handleLoaderChange('/instance', 'Loading Admin Hub...')}
        size='medium'
        aria-label='Go to Admin Hub'
      >
        Admin <LaunchOutlinedIcon />
      </StyledNooderaCTA>
    );
  };
  return <>{renderAdminAction()}</>;
};

export default AdminAction;
