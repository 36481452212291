// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';

export const StyledBadge: StatelessFunctionalComponent<any> = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ offsetRight }) => offsetRight}px;
  margin-top: ${({ offsetTop }) => offsetTop}px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 0 ${({ theme }) => theme.spacing.x8} 0 ${({ theme }) => theme.spacing.x8};
  background: ${({ theme }) => theme.palette.red.flushMahogany};
  color: ${({ theme }) => theme.palette.greyscale.white};
  ${({ theme }) => theme.noodera.fontTypes.font6MediumCaps};
`;
