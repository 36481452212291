export const getPartnerInviteTypes = {
  GET_PARTNER_INVITE_REQUEST: 'GET_PARTNER_INVITE_REQUEST',
  GET_PARTNER_INVITE_SUCCESS: 'GET_PARTNER_INVITE_SUCCESS',
  GET_PARTNER_INVITE_FAILURE: 'GET_PARTNER_INVITE_FAILURE',
};

export const sendPartnerInvitesTypes = {
  SEND_PARTNER_INVITES_REQUEST: 'SEND_PARTNER_INVITES_REQUEST',
  SEND_PARTNER_INVITES_SUCCESS: 'SEND_PARTNER_INVITES_SUCCESS',
  SEND_PARTNER_INVITES_FAILURE: 'SEND_PARTNER_INVITES_FAILURE',
  SEND_PARTNER_INVITES_RESET: 'SEND_PARTNER_INVITES_RESET',
};

export const getPartnerMembershipsTypes = {
  GET_PARTNER_MEMBERSHIPS_REQUEST: 'GET_PARTNER_MEMBERSHIPS_REQUEST',
  GET_PARTNER_MEMBERSHIPS_SUCCESS: 'GET_PARTNER_MEMBERSHIPS_SUCCESS',
  GET_PARTNER_MEMBERSHIPS_FAILURE: 'GET_PARTNER_MEMBERSHIPS_FAILURE',
};

export const getPaginatedPartnerMembershipsTypes = {
  GET_PAGINATED_PARTNER_MEMBERSHIPS_REQUEST: 'GET_PAGINATED_PARTNER_MEMBERSHIPS_REQUEST',
  GET_PAGINATED_PARTNER_MEMBERSHIPS_SUCCESS: 'GET_PAGINATED_PARTNER_MEMBERSHIPS_SUCCESS',
  GET_PAGINATED_PARTNER_MEMBERSHIPS_FAILURE: 'GET_PAGINATED_PARTNER_MEMBERSHIPS_FAILURE',
};

export const createPartnerInstanceMembershipTypes = {
  CREATE_PARTNER_INSTANCE_MEMBERSHIP_REQUEST: 'CREATE_PARTNER_INSTANCE_MEMBERSHIP_REQUEST',
  CREATE_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS: 'CREATE_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS',
  CREATE_PARTNER_INSTANCE_MEMBERSHIP_FAILURE: 'CREATE_PARTNER_INSTANCE_MEMBERSHIP_FAILURE',
};

export const patchPartnerInstanceMembershipTypes = {
  PATCH_PARTNER_INSTANCE_MEMBERSHIP_REQUEST: 'PATCH_PARTNER_INSTANCE_MEMBERSHIP_REQUEST',
  PATCH_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS: 'PATCH_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS',
  PATCH_PARTNER_INSTANCE_MEMBERSHIP_FAILURE: 'PATCH_PARTNER_INSTANCE_MEMBERSHIP_FAILURE',
};

export const getPartnerCoursesTypes = {
  GET_PARTNER_COURSES_REQUEST: 'GET_PARTNER_COURSES_REQUEST',
  GET_PARTNER_COURSES_SUCCESS: 'GET_PARTNER_COURSES_SUCCESS',
  GET_PARTNER_COURSES_FAILURE: 'GET_PARTNER_COURSES_FAILURE',
};

export const getPaginatedPartnerCoursesTypes = {
  GET_PAGINATED_PARTNER_COURSES_REQUEST: 'GET_PAGINATED_PARTNER_COURSES_REQUEST',
  GET_PAGINATED_PARTNER_COURSES_SUCCESS: 'GET_PAGINATED_PARTNER_COURSES_SUCCESS',
  GET_PAGINATED_PARTNER_COURSES_FAILURE: 'GET_PAGINATED_PARTNER_COURSES_FAILURE',
};

export const getPartnerCourseTypes = {
  GET_PARTNER_COURSE_REQUEST: 'GET_PARTNER_COURSE_REQUEST',
  GET_PARTNER_COURSE_SUCCESS: 'GET_PARTNER_COURSE_SUCCESS',
  GET_PARTNER_COURSE_FAILURE: 'GET_PARTNER_COURSE_FAILURE',
};

export const updatePartnerCourseTypes = {
  UPDATE_PARTNER_COURSE_REQUEST: 'UPDATE_PARTNER_COURSE_REQUEST',
  UPDATE_PARTNER_COURSE_SUCCESS: 'UPDATE_PARTNER_COURSE_SUCCESS',
  UPDATE_PARTNER_COURSE_FAILURE: 'UPDATE_PARTNER_COURSE_FAILURE',
};

export const getPaginatedCourseSectionsTypes = {
  GET_PAGINATED_COURSE_SECTIONS_REQUEST: 'GET_PAGINATED_COURSE_SECTIONS_REQUEST',
  GET_PAGINATED_COURSE_SECTIONS_SUCCESS: 'GET_PAGINATED_COURSE_SECTIONS_SUCCESS',
  GET_PAGINATED_COURSE_SECTIONS_FAILURE: 'GET_PAGINATED_COURSE_SECTIONS_FAILURE',
};

export const verifyInvitationTokenTypes = {
  VERIFY_INVITATION_TOKEN_REQUEST: 'VERIFY_INVITATION_TOKEN_REQUEST',
  VERIFY_INVITATION_TOKEN_SUCCESS: 'VERIFY_INVITATION_TOKEN_SUCCESS',
  VERIFY_INVITATION_TOKEN_FAILURE: 'VERIFY_INVITATION_TOKEN_FAILURE',
};

export const getPartnerInstancesTypes = {
  GET_PARTNER_INSTANCES_REQUEST: 'GET_PARTNER_INSTANCES_REQUEST',
  GET_PARTNER_INSTANCES_SUCCESS: 'GET_PARTNER_INSTANCES_SUCCESS',
  GET_PARTNER_INSTANCES_FAILURE: 'GET_PARTNER_INSTANCES_FAILURE',
};

export const getPaginatedPartnerInstancesTypes = {
  GET_PAGINATED_PARTNER_INSTANCES_REQUEST: 'GET_PAGINATED_PARTNER_INSTANCES_REQUEST',
  GET_PAGINATED_PARTNER_INSTANCES_SUCCESS: 'GET_PAGINATED_PARTNER_INSTANCES_SUCCESS',
  GET_PAGINATED_PARTNER_INSTANCES_FAILURE: 'GET_PAGINATED_PARTNER_INSTANCES_FAILURE',
};

export const getPartnerInstanceTypes = {
  GET_PARTNER_INSTANCE_REQUEST: 'GET_PARTNER_INSTANCE_REQUEST',
  GET_PARTNER_INSTANCE_SUCCESS: 'GET_PARTNER_INSTANCE_SUCCESS',
  GET_PARTNER_INSTANCE_FAILURE: 'GET_PARTNER_INSTANCE_FAILURE',
  GET_PARTNER_INSTANCE_RESET: 'GET_PARTNER_INSTANCE_RESET',
};

export const uploadCSVTypes = {
  UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_CSV_FAILURE: 'UPLOAD_CSV_FAILURE',
  UPLOAD_CSV_RESET: 'UPLOAD_CSV_RESET',
};

export const createPartnerInstanceTypes = {
  CREATE_PARTNER_INSTANCE_REQUEST: 'CREATE_PARTNER_INSTANCE_REQUEST',
  CREATE_PARTNER_INSTANCE_SUCCESS: 'CREATE_PARTNER_INSTANCE_SUCCESS',
  CREATE_PARTNER_INSTANCE_FAILURE: 'CREATE_PARTNER_INSTANCE_FAILURE',
  CREATE_PARTNER_INSTANCE_RESET: 'CREATE_PARTNER_INSTANCE_RESET',
};

export const updatePartnerInstanceTypes = {
  UPDATE_PARTNER_INSTANCE_REQUEST: 'UPDATE_PARTNER_INSTANCE_REQUEST',
  UPDATE_PARTNER_INSTANCE_SUCCESS: 'UPDATE_PARTNER_INSTANCE_SUCCESS',
  UPDATE_PARTNER_INSTANCE_FAILURE: 'UPDATE_PARTNER_INSTANCE_FAILURE',
  UPDATE_PARTNER_INSTANCE_RESET: 'UPDATE_PARTNER_INSTANCE_RESET',
};

export const getSectionByIdTypes = {
  GET_SECTION_BY_ID_REQUEST: 'GET_SECTION_BY_ID_REQUEST',
  GET_SECTION_BY_ID_SUCCESS: 'GET_SECTION_BY_ID_SUCCESS',
  GET_SECTION_BY_ID_FAILURE: 'GET_SECTION_BY_ID_FAILURE',
};

export const getPaginatedSectionLearnersByIdTypes = {
  GET_PAGINATED_SECTION_LEARNERS_BY_ID_REQUEST: 'GET_PAGINATED_SECTION_LEARNERS_BY_ID_REQUEST',
  GET_PAGINATED_SECTION_LEARNERS_BY_ID_SUCCESS: 'GET_PAGINATED_SECTION_LEARNERS_BY_ID_SUCCESS',
  GET_PAGINATED_SECTION_LEARNERS_BY_ID_FAILURE: 'GET_PAGINATED_SECTION_LEARNERS_BY_ID_FAILURE',
};

export const createOrUpdateSectionTypes = {
  CREATE_OR_UPDATE_SECTION_REQUEST: 'CREATE_OR_UPDATE_SECTION_REQUEST',
  CREATE_OR_UPDATE_SECTION_SUCCESS: 'CREATE_OR_UPDATE_SECTION_SUCCESS',
  CREATE_OR_UPDATE_SECTION_FAILURE: 'CREATE_OR_UPDATE_SECTION_FAILURE',
  CREATE_OR_UPDATE_SECTION_RESET: 'CREATE_OR_UPDATE_SECTION_RESET',
};

export const getEngagementReportTypes = {
  GET_ENGAGEMENT_REPORT_REQUEST: 'GET_ENGAGEMENT_REPORT_REQUEST',
  GET_ENGAGEMENT_REPORT_SUCCESS: 'GET_ENGAGEMENT_REPORT_SUCCESS',
  GET_ENGAGEMENT_REPORT_FAILURE: 'GET_ENGAGEMENT_REPORT_FAILURE',
  GET_ENGAGEMENT_REPORT_RESET: 'GET_ENGAGEMENT_REPORT_RESET',
};

export const getLearnerStatusReportTypes = {
  GET_LEARNER_STATUS_REPORT_REQUEST: 'GET_LEARNER_STATUS_REPORT_REQUEST',
  GET_LEARNER_STATUS_REPORT_SUCCESS: 'GET_LEARNER_STATUS_REPORT_SUCCESS',
  GET_LEARNER_STATUS_REPORT_FAILURE: 'GET_LEARNER_STATUS_REPORT_FAILURE',
  GET_LEARNER_STATUS_REPORT_RESET: 'GET_LEARNER_STATUS_REPORT_RESET',
};

export const getSurveyReportTypes = {
  GET_SURVEY_REPORT_REQUEST: 'GET_SURVEY_REPORT_REQUEST',
  GET_SURVEY_REPORT_SUCCESS: 'GET_SURVEY_REPORT_SUCCESS',
  GET_SURVEY_REPORT_FAILURE: 'GET_SURVEY_REPORT_FAILURE',
  GET_SURVEY_REPORT_RESET: 'GET_SURVEY_REPORT_RESET',
};

export const getCertificateReportTypes = {
  GET_CERTIFICATE_REPORT_REQUEST: 'GET_CERTIFICATE_REPORT_REQUEST',
  GET_CERTIFICATE_REPORT_SUCCESS: 'GET_CERTIFICATE_REPORT_SUCCESS',
  GET_CERTIFICATE_REPORT_FAILURE: 'GET_CERTIFICATE_REPORT_FAILURE',
};

export const getCompletionReportByPartnerTypes = {
  GET_COMPLETION_REPORT_BY_PARTNER_REQUEST: 'GET_COMPLETION_REPORT_BY_PARTNER_REQUEST',
  GET_COMPLETION_REPORT_BY_PARTNER_SUCCESS: 'GET_COMPLETION_REPORT_BY_PARTNER_SUCCESS',
  GET_COMPLETION_REPORT_BY_PARTNER_FAILURE: 'GET_COMPLETION_REPORT_BY_PARTNER_FAILURE',
};

export const getCompletionReportByProgramTypes = {
  GET_COMPLETION_REPORT_BY_PROGRAM_REQUEST: 'GET_COMPLETION_REPORT_BY_PROGRAM_REQUEST',
  GET_COMPLETION_REPORT_BY_PROGRAM_SUCCESS: 'GET_COMPLETION_REPORT_BY_PROGRAM_SUCCESS',
  GET_COMPLETION_REPORT_BY_PROGRAM_FAILURE: 'GET_COMPLETION_REPORT_BY_PROGRAM_FAILURE',
};

export const addMembersToSectionTypes = {
  ADD_MEMBERS_TO_SECTION_REQUEST: 'ADD_MEMBERS_TO_SECTION_REQUEST',
  ADD_MEMBERS_TO_SECTION_SUCCESS: 'ADD_MEMBERS_TO_SECTION_SUCCESS',
  ADD_MEMBERS_TO_SECTION_FAILURE: 'ADD_MEMBERS_TO_SECTION_FAILURE',
  ADD_MEMBERS_TO_SECTION_RESET: 'ADD_MEMBERS_TO_SECTION_RESET',
  RESET_ADD_MEMBERS_TO_SECTION: 'RESET_ADD_MEMBERS_TO_SECTION',
};

export const addSoloMembersToCourseTypes = {
  ADD_SOLO_MEMBERS_TO_COURSE_REQUEST: 'ADD_SOLO_MEMBERS_TO_COURSE_REQUEST',
  ADD_SOLO_MEMBERS_TO_COURSE_SUCCESS: 'ADD_SOLO_MEMBERS_TO_COURSE_SUCCESS',
  ADD_SOLO_MEMBERS_TO_COURSE_FAILURE: 'ADD_SOLO_MEMBERS_TO_COURSE_FAILURE',
  ADD_SOLO_MEMBERS_TO_COURSE_RESET: 'ADD_SOLO_MEMBERS_TO_COURSE_RESET',
};

export const downloadInstanceReportTypes = {
  DOWNLOAD_INSTANCE_REPORT: 'DOWNLOAD_INSTANCE_REPORT',
};

export const downloadProgramReportTypes = {
  DOWNLOAD_PROGRAM_REPORT: 'DOWNLOAD_PROGRAM_REPORT',
};

export const getGlobalPartnerTypes = {
  GET_GLOBAL_PARTNER_REQUEST: 'GET_GLOBAL_PARTNER_REQUEST',
  GET_GLOBAL_PARTNER_SUCCESS: 'GET_GLOBAL_PARTNER_SUCCESS',
  GET_GLOBAL_PARTNER_FAILURE: 'GET_GLOBAL_PARTNER_FAILURE',
};

export const setupStripeAccountTypes = {
  SETUP_STRIPE_ACCOUNT_REQUEST: 'SETUP_STRIPE_ACCOUNT_REQUEST',
  SETUP_STRIPE_ACCOUNT_SUCCESS: 'SETUP_STRIPE_ACCOUNT_SUCCESS',
  SETUP_STRIPE_ACCOUNT_FAILURE: 'SETUP_STRIPE_ACCOUNT_FAILURE',
};

export const getStripeAccountTypes = {
  GET_STRIPE_ACCOUNT_REQUEST: 'GET_STRIPE_ACCOUNT_REQUEST',
  GET_STRIPE_ACCOUNT_SUCCESS: 'GET_STRIPE_ACCOUNT_SUCCESS',
  GET_STRIPE_ACCOUNT_FAILURE: 'GET_STRIPE_ACCOUNT_FAILURE',
};

export const createStripeInvoiceTypes = {
  CREATE_STRIPE_INVOICE_REQUEST: 'CREATE_STRIPE_INVOICE_REQUEST',
  CREATE_STRIPE_INVOICE_SUCCESS: 'CREATE_STRIPE_INVOICE_SUCCESS',
  CREATE_STRIPE_INVOICE_FAILURE: 'CREATE_STRIPE_INVOICE_FAILURE',
  CREATE_STRIPE_INVOICE_RESET: 'CREATE_STRIPE_INVOICE_RESET',
};

export const requestSeatsTypes = {
  REQUEST_SEATS_REQUEST: 'REQUEST_SEATS_REQUEST',
  REQUEST_SEATS_SUCCESS: 'REQUEST_SEATS_SUCCESS',
  REQUEST_SEATS_FAILURE: 'REQUEST_SEATS_FAILURE',
};

export const getStripeInvoiceTypes = {
  GET_STRIPE_INVOICE_REQUEST: 'GET_STRIPE_INVOICE_REQUEST',
  GET_STRIPE_INVOICE_SUCCESS: 'GET_STRIPE_INVOICE_SUCCESS',
  GET_STRIPE_INVOICE_FAILURE: 'GET_STRIPE_INVOICE_FAILURE',
};

export const getStripeInvoiceByIdTypes = {
  GET_STRIPE_INVOICE_BY_ID_REQUEST: 'GET_STRIPE_INVOICE_BY_ID_REQUEST',
  GET_STRIPE_INVOICE_BY_ID_SUCCESS: 'GET_STRIPE_INVOICE_BY_ID_SUCCESS',
  GET_STRIPE_INVOICE_BY_ID_FAILURE: 'GET_STRIPE_INVOICE_BY_ID_FAILURE',
};

export const getPaginatedStripeInvoiceTypes = {
  GET_PAGINATED_STRIPE_INVOICE_REQUEST: 'GET_PAGINATED_STRIPE_INVOICE_REQUEST',
  GET_PAGINATED_STRIPE_INVOICE_SUCCESS: 'GET_PAGINATED_STRIPE_INVOICE_SUCCESS',
  GET_PAGINATED_STRIPE_INVOICE_FAILURE: 'GET_PAGINATED_STRIPE_INVOICE_FAILURE',
};

export const getStripeInvoiceTableDataTypes = {
  GET_STRIPE_INVOICE_TABLE_DATA_REQUEST: 'GET_STRIPE_INVOICE_TABLE_DATA_REQUEST',
  GET_STRIPE_INVOICE_TABLE_DATA_SUCCESS: 'GET_STRIPE_INVOICE_TABLE_DATA_SUCCESS',
  GET_STRIPE_INVOICE_TABLE_DATA_FAILURE: 'GET_STRIPE_INVOICE_TABLE_DATA_FAILURE',
  RESET_STRIPE_INVOICE_TABLE_DATA: 'RESET_STRIPE_INVOICE_TABLE_DATA',
};

export const getStripeReceiptsTableDataTypes = {
  GET_STRIPE_RECEIPTS_TABLE_DATA_REQUEST: 'GET_STRIPE_RECEIPTS_TABLE_DATA_REQUEST',
  GET_STRIPE_RECEIPTS_TABLE_DATA_SUCCESS: 'GET_STRIPE_RECEIPTS_TABLE_DATA_SUCCESS',
  GET_STRIPE_RECEIPTS_TABLE_DATA_FAILURE: 'GET_STRIPE_RECEIPTS_TABLE_DATA_FAILURE',
  RESET_STRIPE_RECEIPTS_TABLE_DATA: 'RESET_STRIPE_RECEIPTS_TABLE_DATA',
};

export const getStripePartnerRevenueDataTypes = {
  GET_STRIPE_PARTNER_REVENUE_DATA_REQUEST: 'GET_STRIPE_PARTNER_REVENUE_DATA_REQUEST',
  GET_STRIPE_PARTNER_REVENUE_DATA_SUCCESS: 'GET_STRIPE_PARTNER_REVENUE_DATA_SUCCESS',
  GET_STRIPE_PARTNER_REVENUE_DATA_FAILURE: 'GET_STRIPE_PARTNER_REVENUE_DATA_FAILURE',
};

export const reviewSeatRequestTypes = {
  REVIEW_SEAT_REQUEST_REQUEST: 'REVIEW_SEAT_REQUEST_REQUEST',
  REVIEW_SEAT_REQUEST_SUCCESS: 'REVIEW_SEAT_REQUEST_SUCCESS',
  REVIEW_SEAT_REQUEST_FAILURE: 'REVIEW_SEAT_REQUEST_FAILURE',
};

export const getPartnerSeatRequestTypes = {
  GET_PARTNER_SEAT_REQUESTS_REQUEST: 'GET_PARTNER_SEAT_REQUESTS_REQUEST',
  GET_PARTNER_SEAT_REQUESTS_SUCCESS: 'GET_PARTNER_SEAT_REQUESTS_SUCCESS',
  GET_PARTNER_SEAT_REQUESTS_FAILURE: 'GET_PARTNER_SEAT_REQUESTS_FAILURE',
};
