// @ts-nocheck
import type { Saga } from 'redux-saga';
import get from 'lodash.get';
import { delay, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import type { GroupPostPayloadType, GetGroupsRequestPayload } from 'commons/types';
import {
  getGroupByIdTypes,
  getGroupPostsTypes,
  getGroupsTypes,
  joinGroupTypes,
  leaveGroupTypes,
  postGroupTypes,
} from './types';
import { groupsActions, groupJoinLeaveActions, postGroupActions } from './actions';

const { DANGER, SUCCESS } = alertTypesConstants;
export const getUserIdSelector = (state: any) => get(state, 'user.id', '');
export const getSearchIdSelector = (state: any) => get(state, 'dashboard.activeView.id', '');
export function* getGroupByIdSaga({ payload }: { payload: number }): Saga {
  try {
    const group = yield api.group.getById(payload);

    if (group.id) {
      yield put(groupsActions.getGroupByIdSuccess(group));
    } else {
      yield put(
        alertActions.setAlert({
          message: 'Group not found.',
          type: DANGER,
        }),
      );
      yield put(groupsActions.getGroupByIdFailed('Group not found.'));
      yield put(push('/groups/browse'));
    }
  } catch (error) {
    yield put(groupsActions.getGroupByIdFailed('There was an error fetching the group data.'));
    yield put(push('/groups/browse'));
  }
}
export function* getGroupsSaga({ payload }: { payload: GetGroupsRequestPayload }): Saga {
  try {
    yield delay(300);
    const userId = yield select(getUserIdSelector);
    const searchId = yield select(getSearchIdSelector);
    const response = yield call([api.group, 'getAll'], { ...payload, searchId, userId });
    const { tab, page } = payload;
    const currentPage = page - 1;
    yield put(groupsActions.getGroupsSuccess({ ...response, tab, currentPage }));
  } catch (error) {
    const message = 'An error occurred while getting the groups';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(groupsActions.getGroupsFailed(message));
  }
}
export function* joinGroupSaga({
  payload,
}: {
  payload: {
    currentPage: number;
    groupId: number;
    groupName: string;
  };
}): Saga {
  try {
    yield call([api.group, 'joinGroup'], payload);
    yield put(groupJoinLeaveActions.joinGroupSuccess(payload.groupId));
    yield put(
      alertActions.setAlert({
        message: `You are now a member of ${payload.groupName}.`,
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: `Failed to join the group ${payload.groupName}.`,
        type: DANGER,
      }),
    );
    yield put(groupJoinLeaveActions.joinGroupFailed(error));
  }
}
export function* leaveGroupSaga({
  payload,
}: {
  payload: {
    currentPage: number;
    groupId: number;
    groupName: string;
  };
}): Saga {
  try {
    yield call([api.group, 'leaveGroup'], payload);
    yield put(groupJoinLeaveActions.leaveGroupSuccess(payload.groupId));
    yield put(
      alertActions.setAlert({
        message: `You have left ${payload.groupName}.`,
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: `Failed to leave ${payload.groupName}.`,
        type: DANGER,
      }),
    );
    yield put(groupJoinLeaveActions.leaveGroupFailed(error));
  }
}
export function* postToGroupSaga({
  payload,
}: {
  payload: {
    postGroup: GroupPostPayloadType;
    lastUpdate?: string;
  };
}): Saga {
  try {
    const { postGroup } = payload;
    const posts = yield call([api.post, 'createGroupPost'], postGroup);
    yield put(postGroupActions.postGroupSuccess(posts));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'Failed to post message.',
        type: DANGER,
      }),
    );
    yield put(postGroupActions.postGroupFail());
  }
}
export function* getGroupPostsSaga({
  payload,
}: {
  payload: {
    groupId: number;
  };
}): Saga {
  try {
    const { groupId } = payload;
    const posts = yield call([api.post, 'getGroupPosts'], groupId);
    yield put(postGroupActions.getGroupPostsSuccess(posts));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'Failed to get post messages.',
        type: DANGER,
      }),
    );
    yield put(postGroupActions.getGroupPostsFail());
  }
}
export default function* groupSaga(): Saga {
  yield takeEvery(getGroupByIdTypes.REQUEST, getGroupByIdSaga);
  yield takeLatest(getGroupsTypes.REQUEST, getGroupsSaga);
  yield takeEvery(joinGroupTypes.REQUEST, joinGroupSaga);
  yield takeEvery(leaveGroupTypes.REQUEST, leaveGroupSaga);
  yield takeEvery(postGroupTypes.REQUEST, postToGroupSaga);
  yield takeEvery(getGroupPostsTypes.REQUEST, getGroupPostsSaga);
}
