import { sendCalendarSessionTypes } from './types';

const initialState = {};

const adminGeneralReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case sendCalendarSessionTypes.SUCCESS: {
      return { ...state, isCalendarSessionTokenReady: true };
    }

    case sendCalendarSessionTypes.FAILED: {
      return { ...state, errorMessage: payload };
    }

    default:
      return state;
  }
};

export default adminGeneralReducer;
