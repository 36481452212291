// @ts-nocheck
import { likePostTypes } from 'ducks/like/types';
import { joinGroupTypes, leaveGroupTypes } from 'ducks/groups/types';
import { connectionTypes } from 'ducks/userConnection/types';
import { cardTypesConstants, ALL_BOOKMARKS } from 'commons/constants';
import { bookmarkTypes, getSavedItemsTypes } from 'ducks/bookmarks/types';
import {
  bookmarkedItemsTypes,
  saveArticleTypes,
  removeArticleTypes,
  requestInfoTypes,
} from './types';

export const initState = {
  bookmarkedItems: [],
  filter: ALL_BOOKMARKS,
  saveArticle: {
    isLoading: false,
    success: false,
    error: '',
  },
  removeArticle: {
    isLoading: false,
    success: false,
    error: '',
  },
};

const updateFeedSchool = (state, payload) => {
  const feedCopy = state && state.feedItems ? [...state.feedItems] : [];
  const index = feedCopy.findIndex(
    (feedItem) =>
      feedItem && feedItem.cardType === 'SCHOOL' && String(feedItem.id) === String(payload),
  );

  if (index >= 0) {
    feedCopy[index].sendLeadEnabled = false;
  }

  return feedCopy;
};

const updateFeedItem = (state, payload) => {
  const feedCopy = state && state.feedItems ? Object.values(state.feedItems) : [];
  const index = feedCopy.findIndex(
    (feedItem) =>
      feedItem.cardType === 'EXPERT' && feedItem.entityObj.id === payload.targetUser.entityObj.id,
  );

  if (index >= 0) {
    feedCopy[index] = { ...feedCopy[index], connectionStatus: 'REQUESTED' };
  }

  return [...feedCopy];
};

const mapFeedItems = (feedItems, payload, isLikedByUser, diff) => {
  const { id, likeId, objectType } = payload;
  return feedItems.map((item) =>
    (item.id === id || item.postId === id || item.slug === id) && item.cardType === objectType
      ? {
          ...item,
          likeId: likeId || item.likeId,
          likesCount: (item.likesCount || 0) + diff,
          likedByUser: isLikedByUser,
        }
      : item,
  );
};

const onLikeUpdate = (state, payload, isLikedByUser, diff) => {
  let newState = state;

  if (state && state.bookmarkedItems) {
    const { bookmarkedItems = [] } = state;
    newState = {
      ...state,
      bookmarkedItems: mapFeedItems(bookmarkedItems, payload, isLikedByUser, diff),
    };
  }

  return newState;
};

const updateBookmarkItems = (items, payload) => {
  const { id: bookmarkId, bookmarkedObjectId } = payload;
  return items.map((item) =>
    item.id === bookmarkedObjectId
      ? { ...item, bookmarkId, bookmarkedByUser: bookmarkId !== null }
      : item,
  );
};

const bookmarkReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case connectionTypes.CONNECTION_SUCCESS: {
      const bookmarkedItems = updateFeedItem(state, payload);
      return { ...state, bookmarkedItems };
    }

    case bookmarkedItemsTypes.REQUEST: {
      const { page, filter } = payload;
      return {
        ...state,
        error: null,
        isLoading: page === 1,
        isLoadingNextPage: page !== 1,
        reachedEnd: false,
        page: 0,
        filter,
      };
    }

    case bookmarkedItemsTypes.FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        isLoadingNextPage: false,
        filter: ALL_BOOKMARKS,
      };
    }

    case requestInfoTypes.SUCCESS: {
      const bookmarkedItems = updateFeedSchool(state, payload);
      return { ...state, bookmarkedItems };
    }

    case bookmarkedItemsTypes.SUCCESS: {
      const { bookmarkedItems, page, reachedEnd, filter } = payload;
      return {
        ...state,
        isLoading: false,
        page,
        bookmarkedItems:
          page === 1 ? bookmarkedItems : [...state.bookmarkedItems, ...bookmarkedItems],
        reachedEnd,
        isLoadingNextPage: false,
        filter,
      };
    }

    case bookmarkTypes.SUCCESS: {
      const updatedItems = updateBookmarkItems(state.bookmarkedItems, payload);
      return { ...state, bookmarkedItems: updatedItems };
    }

    case joinGroupTypes.SUCCESS:
    case leaveGroupTypes.SUCCESS: {
      let newState = state;

      if (state && state.bookmarkedItems) {
        const { bookmarkedItems = [] } = state;
        newState = {
          ...state,
          bookmarkedItems: bookmarkedItems.map((item) =>
            item.cardType && item.cardType === cardTypesConstants.GROUP && item.id === payload
              ? { ...item, connected: type === joinGroupTypes.SUCCESS }
              : item,
          ),
        };
      }

      return newState;
    }

    case likePostTypes.SUCCESS: {
      return onLikeUpdate(state, action.payload, true, 1);
    }

    case likePostTypes.DELETE_SUCCESS: {
      return onLikeUpdate(state, action.payload, false, -1);
    }

    case getSavedItemsTypes.SUCCESS: {
      return { ...state, savedItems: payload };
    }

    case saveArticleTypes.SAVE_ARTICLE_REQUEST: {
      return { ...state, saveArticle: { ...initState.saveArticle, isLoading: true } };
    }

    case saveArticleTypes.SAVE_ARTICLE_SUCCESS: {
      return {
        ...state,
        saveArticle: {
          isLoading: false,
          success: payload,
        },
      };
    }

    case saveArticleTypes.SAVE_ARTICLE_FAILURE: {
      return {
        ...state,
        saveArticle: { ...initState.saveArticle, error: payload, success: false },
      };
    }

    case removeArticleTypes.REMOVE_ARTICLE_REQUEST: {
      return { ...state, removeArticle: { ...initState.removeArticle, isLoading: true } };
    }

    case removeArticleTypes.REMOVE_ARTICLE_SUCCESS: {
      return {
        ...state,
        removeArticle: {
          isLoading: false,
          success: true,
        },
      };
    }

    case removeArticleTypes.REMOVE_ARTICLE_FAILURE: {
      return {
        ...state,
        removeArticle: { ...initState.removeArticle, error: payload, success: false },
      };
    }

    default: {
      return state;
    }
  }
};

export default bookmarkReducer;
