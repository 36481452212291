import {
  PartnerInstance,
  PartnerInstanceSection,
  Membership,
  PartnerInstanceMembership,
  PartnerInstanceMembershipPatch,
  PartnerInstanceCourse,
} from 'commons/types/partnerInstanceTypes';
import { PaginatedResponse } from 'commons/types/paginationTypes';
import {
  getPartnerInviteTypes,
  getPartnerMembershipsTypes,
  getPartnerInstancesTypes,
  getPartnerInstanceTypes,
  uploadCSVTypes,
  verifyInvitationTokenTypes,
  createPartnerInstanceTypes,
  updatePartnerInstanceTypes,
  getPartnerCoursesTypes,
  getPartnerCourseTypes,
  updatePartnerCourseTypes,
  createOrUpdateSectionTypes,
  getSectionByIdTypes,
  getEngagementReportTypes,
  getLearnerStatusReportTypes,
  sendPartnerInvitesTypes,
  getPaginatedPartnerCoursesTypes,
  getSurveyReportTypes,
  downloadInstanceReportTypes,
  downloadProgramReportTypes,
  getCertificateReportTypes,
  getCompletionReportByPartnerTypes,
  getCompletionReportByProgramTypes,
  createPartnerInstanceMembershipTypes,
  patchPartnerInstanceMembershipTypes,
  getPaginatedPartnerMembershipsTypes,
  getPaginatedPartnerInstancesTypes,
  getPaginatedSectionLearnersByIdTypes,
  getPaginatedCourseSectionsTypes,
  addMembersToSectionTypes,
  addSoloMembersToCourseTypes,
  getGlobalPartnerTypes,
  setupStripeAccountTypes,
  getStripeAccountTypes,
  createStripeInvoiceTypes,
  requestSeatsTypes,
  getStripeInvoiceTypes,
  getStripeInvoiceByIdTypes,
  getPaginatedStripeInvoiceTypes,
  reviewSeatRequestTypes,
  getStripeInvoiceTableDataTypes,
  getStripeReceiptsTableDataTypes,
  getStripePartnerRevenueDataTypes,
  getPartnerSeatRequestTypes,
} from './types';

interface PatchPartnerInstanceMembershipRequestFunction {
  (
    payload: Pick<PartnerInstanceMembershipPatch, 'role'> & {
      id: number | string;
      partnerId: string;
    },
  ): any;

  (
    payload: Pick<PartnerInstanceMembershipPatch, 'status'> & {
      id: number | string;
      partnerId: string;
    },
  ): any;

  (payload: PartnerInstanceMembershipPatch & { id: number | string; partnerId: string }): any;
}

const patchPartnerInstanceMembershipRequest: PatchPartnerInstanceMembershipRequestFunction = (
  payload: PartnerInstanceMembershipPatch & { id: number | string; partnerId: string },
) => ({
  type: patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_REQUEST,
  payload,
});

export const partnerInstanceActions = {
  getPartnerInvitesRequest: (payload?: { partnerId: string }) => ({
    type: getPartnerInviteTypes.GET_PARTNER_INVITE_REQUEST,
    payload,
  }),
  getPartnerInvitesSuccess: (payload: Record<string, any>) => ({
    type: getPartnerInviteTypes.GET_PARTNER_INVITE_SUCCESS,
    payload,
  }),
  getPartnerInvitesFailure: (payload: string) => ({
    type: getPartnerInviteTypes.GET_PARTNER_INVITE_FAILURE,
    payload,
  }),
  getPartnerInstancesRequest: (payload?: string) => ({
    type: getPartnerInstancesTypes.GET_PARTNER_INSTANCES_REQUEST,
    payload,
  }),
  getPartnerInstancesSuccess: (payload: PartnerInstance[]) => ({
    type: getPartnerInstancesTypes.GET_PARTNER_INSTANCES_SUCCESS,
    payload,
  }),
  getPartnerInstancesFailure: (payload: string) => ({
    type: getPartnerInstancesTypes.GET_PARTNER_INSTANCES_FAILURE,
    payload,
  }),
  getPaginatedPartnerInstancesRequest: (payload: { actualPage?: number }) => ({
    type: getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_REQUEST,
    payload,
  }),
  getPaginatedPartnerInstancesSuccess: (payload: PartnerInstance[]) => ({
    type: getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_SUCCESS,
    payload,
  }),
  getPaginatedPartnerInstancesFailure: (payload: string) => ({
    type: getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_FAILURE,
    payload,
  }),
  getPartnerInstanceRequest: (payload?: string) => ({
    type: getPartnerInstanceTypes.GET_PARTNER_INSTANCE_REQUEST,
    payload,
  }),
  getPartnerInstanceSuccess: (payload: PartnerInstance) => ({
    type: getPartnerInstanceTypes.GET_PARTNER_INSTANCE_SUCCESS,
    payload,
  }),
  getPartnerInstanceFailure: (payload: string) => ({
    type: getPartnerInstanceTypes.GET_PARTNER_INSTANCE_FAILURE,
    payload,
  }),
  getPartnerInstanceReset: () => ({
    type: getPartnerInstanceTypes.GET_PARTNER_INSTANCE_RESET,
  }),
  getSectionByIdRequest: (payload: { sectionId: string }) => ({
    type: getSectionByIdTypes.GET_SECTION_BY_ID_REQUEST,
    payload,
  }),
  getSectionByIdSuccess: (payload: PartnerInstanceSection) => ({
    type: getSectionByIdTypes.GET_SECTION_BY_ID_SUCCESS,
    payload,
  }),
  getSectionByIdFailure: (payload: string) => ({
    type: getSectionByIdTypes.GET_SECTION_BY_ID_FAILURE,
    payload,
  }),
  getPaginatedSectionLearnersByIdRequest: (payload: {
    sectionId: string;
    pageSize?: number;
    actualPage: number;
    search?: string;
    includeAttributes?: string;
  }) => ({
    type: getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_REQUEST,
    payload,
  }),
  getPaginatedSectionLearnersByIdSuccess: (payload: PartnerInstanceSection) => ({
    type: getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_SUCCESS,
    payload,
  }),
  getPaginatedSectionLearnersByIdFailure: (payload: string) => ({
    type: getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_FAILURE,
    payload,
  }),
  createPartnerInstanceMembershipRequest: (payload: PartnerInstanceMembership) => ({
    type: createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_REQUEST,
    payload,
  }),
  createPartnerInstanceMembershipSuccess: (payload: any) => ({
    type: createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS,
    payload,
  }),
  createPartnerInstanceMembershipFailure: (payload: any) => ({
    type: createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_FAILURE,
    payload,
  }),
  patchPartnerInstanceMembershipRequest,
  patchPartnerInstanceMembershipSuccess: (payload: any) => ({
    type: patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_SUCCESS,
    payload,
  }),
  patchPartnerInstanceMembershipFailure: (payload: any) => ({
    type: patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_FAILURE,
    payload,
  }),
  sendPartnerInvitesRequest: (payload: {
    emails: string[];
    resendAction?: boolean;
    partnerId?: string;
  }) => ({
    type: sendPartnerInvitesTypes.SEND_PARTNER_INVITES_REQUEST,
    payload,
  }),
  sendPartnerInvitesSuccess: (payload: Record<string, any>) => ({
    type: sendPartnerInvitesTypes.SEND_PARTNER_INVITES_SUCCESS,
    payload,
  }),
  sendPartnerInvitesFailure: (payload: string) => ({
    type: sendPartnerInvitesTypes.SEND_PARTNER_INVITES_FAILURE,
    payload,
  }),
  sendPartnerInvitesReset: () => ({
    type: sendPartnerInvitesTypes.SEND_PARTNER_INVITES_RESET,
  }),
  uploadCSVRequest: (payload: Record<string, any>) => ({
    type: uploadCSVTypes.UPLOAD_CSV_REQUEST,
    payload,
  }),
  uploadCSVSuccess: (payload: Record<string, any>) => ({
    type: uploadCSVTypes.UPLOAD_CSV_SUCCESS,
    payload,
  }),
  uploadCSVFailure: (payload: string) => ({
    type: uploadCSVTypes.UPLOAD_CSV_FAILURE,
    payload,
  }),
  uploadCSVReset: () => ({
    type: uploadCSVTypes.UPLOAD_CSV_RESET,
  }),
  getPartnerMembershipsRequest: (payload?: { partnerId?: string; memberId?: string }) => ({
    type: getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_REQUEST,
    payload,
  }),
  getPartnerMembershipsSuccess: (payload: Membership[]) => ({
    type: getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_SUCCESS,
    payload,
  }),
  getPartnerMembershipsFailure: (payload: string) => ({
    type: getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_FAILURE,
    payload,
  }),
  getPaginatedPartnerMembershipsRequest: (payload?: {
    partnerId?: string;
    memberId?: string;
    actualPage: number;
    pageSize?: number;
    search?: string;
    courseSlug?: string;
    includeRoles?: boolean;
    includeInactive?: boolean;
  }) => ({
    type: getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_REQUEST,
    payload,
  }),
  getPaginatedPartnerMembershipsSuccess: (payload: PaginatedResponse<Membership>) => ({
    type: getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_SUCCESS,
    payload,
  }),
  getPaginatedPartnerMembershipsFailure: (payload: string) => ({
    type: getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_FAILURE,
    payload,
  }),
  getPartnerCoursesRequest: (payload?: { partnerId: string; pageSize?: number }) => ({
    type: getPartnerCoursesTypes.GET_PARTNER_COURSES_REQUEST,
    payload,
  }),
  getPartnerCoursesSuccess: (payload: Record<string, any>[]) => ({
    type: getPartnerCoursesTypes.GET_PARTNER_COURSES_SUCCESS,
    payload,
  }),
  getPartnerCoursesFailure: (payload: string) => ({
    type: getPartnerCoursesTypes.GET_PARTNER_COURSES_FAILURE,
    payload,
  }),
  getPaginatedPartnerCoursesRequest: (payload: {
    actualPage: number;
    pageSize?: number;
    isAdminView?: boolean;
    isAdmin?: boolean;
    search?: string;
  }) => ({
    type: getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_REQUEST,
    payload,
  }),
  getPaginatedPartnerCoursesSuccess: (payload: PaginatedResponse<PartnerInstanceCourse>) => ({
    type: getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_SUCCESS,
    payload,
  }),
  getPaginatedPartnerCoursesFailure: (payload: string) => ({
    type: getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_FAILURE,
    payload,
  }),
  getPartnerCourseRequest: (payload: {
    slug: string;
    partnerId: string;
    redirectOnFail?: boolean;
  }) => ({
    type: getPartnerCourseTypes.GET_PARTNER_COURSE_REQUEST,
    payload,
  }),
  getPartnerCourseSuccess: (payload: Record<string, any>) => ({
    type: getPartnerCourseTypes.GET_PARTNER_COURSE_SUCCESS,
    payload,
  }),
  getPartnerCourseFailure: (payload: string) => ({
    type: getPartnerCourseTypes.GET_PARTNER_COURSE_FAILURE,
    payload,
  }),
  updatePartnerCourseRequest: (payload: {
    partnerId: string;
    slug: string;
    priceInCents: string;
    contentfulCourseId: string;
  }) => ({
    type: updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_REQUEST,
    payload,
  }),
  updatePartnerCourseSuccess: (payload: Record<string, any>) => ({
    type: updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_SUCCESS,
    payload,
  }),
  updatePartnerCourseFailure: (payload: string) => ({
    type: updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_FAILURE,
    payload,
  }),
  getPaginatedCourseSectionsRequest: (payload: {
    slug: string;
    partnerId: string;
    actualPage: number;
    pageSize?: number;
    getSoloLearners?: boolean;
    searchByAdmin?: string;
  }) => ({
    type: getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_REQUEST,
    payload,
  }),
  getPaginatedCourseSectionsSuccess: (payload: Record<string, any>) => ({
    type: getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_SUCCESS,
    payload,
  }),
  getPaginatedCourseSectionsFailure: (payload: string) => ({
    type: getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_FAILURE,
    payload,
  }),
  verifyInvitationTokenRequest: (payload: string) => ({
    type: verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_REQUEST,
    payload,
  }),
  verifyInvitationTokenSuccess: (payload: Record<string, any>) => ({
    type: verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_SUCCESS,
    payload,
  }),
  verifyInvitationTokenFailure: (payload: string) => ({
    type: verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_FAILURE,
    payload,
  }),
  updatePartnerInstanceRequest: (payload: Record<string, any>) => ({
    type: updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_REQUEST,
    payload,
  }),
  updatePartnerInstanceSuccess: (payload: boolean) => ({
    type: updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_SUCCESS,
    payload,
  }),
  updatePartnerInstanceFailure: (payload: string) => ({
    type: updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_FAILURE,
    payload,
  }),
  updatePartnerInstanceReset: () => ({
    type: updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_RESET,
  }),
  createPartnerInstanceRequest: (payload: Record<string, any>) => ({
    type: createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_REQUEST,
    payload,
  }),
  createPartnerInstanceSuccess: (payload: boolean) => ({
    type: createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_SUCCESS,
    payload,
  }),
  createPartnerInstanceFailure: (payload: string) => ({
    type: createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_FAILURE,
    payload,
  }),
  createPartnerInstanceReset: () => ({
    type: createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_RESET,
  }),
  createOrUpdateSectionRequest: (payload: Record<string, any>) => ({
    type: createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_REQUEST,
    payload,
  }),
  createOrUpdateSectionSuccess: (payload: boolean) => ({
    type: createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_SUCCESS,
    payload,
  }),
  createOrUpdateSectionFailure: (payload: string) => ({
    type: createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_FAILURE,
    payload,
  }),
  createOrUpdateSectionReset: () => ({
    type: createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_RESET,
  }),
  getEngagementReportRequest: (payload: string) => ({
    type: getEngagementReportTypes.GET_ENGAGEMENT_REPORT_REQUEST,
    payload,
  }),
  getEngagementReportSuccess: (payload: Record<string, any>) => ({
    type: getEngagementReportTypes.GET_ENGAGEMENT_REPORT_SUCCESS,
    payload,
  }),
  getEngagementReportFailure: (payload: string) => ({
    type: getEngagementReportTypes.GET_ENGAGEMENT_REPORT_FAILURE,
    payload,
  }),
  getEngagementReportReset: () => ({
    type: getEngagementReportTypes.GET_ENGAGEMENT_REPORT_RESET,
  }),
  getLearnerStatusReportRequest: (payload: Record<string, any>) => ({
    type: getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_REQUEST,
    payload,
  }),
  getLearnerStatusReportSuccess: (payload: Record<string, any>) => ({
    type: getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_SUCCESS,
    payload,
  }),
  getLearnerStatusReportFailure: (payload: string) => ({
    type: getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_FAILURE,
    payload,
  }),
  getLearnerStatusReportReset: () => ({
    type: getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_RESET,
  }),
  getSurveyReportRequest: (payload: Record<string, any>) => ({
    type: getSurveyReportTypes.GET_SURVEY_REPORT_REQUEST,
    payload,
  }),
  getSurveyReportSuccess: (payload: Record<string, any>[]) => ({
    type: getSurveyReportTypes.GET_SURVEY_REPORT_SUCCESS,
    payload,
  }),
  getSurveyReportFailure: (payload: string) => ({
    type: getSurveyReportTypes.GET_SURVEY_REPORT_FAILURE,
    payload,
  }),
  getSurveyReportReset: () => ({
    type: getSurveyReportTypes.GET_SURVEY_REPORT_RESET,
  }),
  getCertificatesReportRequest: (payload: string) => ({
    type: getCertificateReportTypes.GET_CERTIFICATE_REPORT_REQUEST,
    payload,
  }),
  getCertificatesReportSuccess: (payload: Record<string, any>[]) => ({
    type: getCertificateReportTypes.GET_CERTIFICATE_REPORT_SUCCESS,
    payload,
  }),
  getCertificatesReportFailure: (payload: string) => ({
    type: getCertificateReportTypes.GET_CERTIFICATE_REPORT_FAILURE,
    payload,
  }),
  getCompletionReportByPartnerRequest: (payload: Record<string, any>) => ({
    type: getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_REQUEST,
    payload,
  }),
  getCompletionReportByPartnerSuccess: (payload: Record<string, any>) => ({
    type: getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_SUCCESS,
    payload,
  }),
  getCompletionReportByPartnerFailure: (payload: string) => ({
    type: getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_FAILURE,
    payload,
  }),
  getCompletionReportByProgramRequest: (payload: Record<string, any>) => ({
    type: getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_REQUEST,
    payload,
  }),
  getCompletionReportByProgramSuccess: (payload: Record<string, any>) => ({
    type: getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_SUCCESS,
    payload,
  }),
  getCompletionReportByProgramFailure: (payload: string) => ({
    type: getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_FAILURE,
    payload,
  }),
  // Simple actions for these since we're literally just downloading files and
  // do not depend on the success/failure state of this to do anything in the app
  downloadInstanceReport: (payload: Record<string, any>) => ({
    type: downloadInstanceReportTypes.DOWNLOAD_INSTANCE_REPORT,
    payload,
  }),
  downloadProgramReport: (payload: Record<string, any>) => ({
    type: downloadProgramReportTypes.DOWNLOAD_PROGRAM_REPORT,
    payload,
  }),
  addMembersToSectionRequest: (payload: Record<string, any>) => ({
    type: addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_REQUEST,
    payload,
  }),
  addMembersToSectionSuccess: (payload: Record<string, any>) => ({
    type: addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_SUCCESS,
    payload,
  }),
  addMembersToSectionFailure: (payload: string) => ({
    type: addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_FAILURE,
    payload,
  }),
  addMembersToSectionReset: () => ({
    type: addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_RESET,
  }),
  addSoloMembersToCourseRequest: (payload: Record<string, any>) => ({
    type: addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_REQUEST,
    payload,
  }),
  addSoloMembersToCourseSuccess: (payload: Record<string, any>) => ({
    type: addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_SUCCESS,
    payload,
  }),
  addSoloMembersToCourseFailure: (payload: string) => ({
    type: addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_FAILURE,
    payload,
  }),
  addSoloMembersToCourseReset: () => ({
    type: addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_RESET,
  }),
  resetAddMembersToSection: () => ({
    type: addMembersToSectionTypes.RESET_ADD_MEMBERS_TO_SECTION,
  }),
  getGlobalPartnerRequest: () => ({
    type: getGlobalPartnerTypes.GET_GLOBAL_PARTNER_REQUEST,
  }),
  getGlobalPartnerSuccess: (payload: Record<string, any>) => ({
    type: getGlobalPartnerTypes.GET_GLOBAL_PARTNER_SUCCESS,
    payload,
  }),
  getGlobalPartnerFailure: (payload: string) => ({
    type: getGlobalPartnerTypes.GET_GLOBAL_PARTNER_FAILURE,
    payload,
  }),
  setupStripeAccountRequest: (payload: string) => ({
    type: setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_REQUEST,
    payload,
  }),
  setupStripeAccountSuccess: (payload: Record<string, any>) => ({
    type: setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_SUCCESS,
    payload,
  }),
  setupStripeAccountFailure: (payload: string) => ({
    type: setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_FAILURE,
    payload,
  }),
  getStripeAccountRequest: (payload: string) => ({
    type: getStripeAccountTypes.GET_STRIPE_ACCOUNT_REQUEST,
    payload,
  }),
  getStripeAccountSuccess: (payload: Record<string, any>) => ({
    type: getStripeAccountTypes.GET_STRIPE_ACCOUNT_SUCCESS,
    payload,
  }),
  getStripeAccountFailure: (payload: string) => ({
    type: getStripeAccountTypes.GET_STRIPE_ACCOUNT_FAILURE,
    payload,
  }),
  createStripeInvoiceRequest: (payload: Record<string, any>) => ({
    type: createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_REQUEST,
    payload,
  }),
  createStripeInvoiceSuccess: (payload: Record<string, any>) => ({
    type: createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_SUCCESS,
    payload,
  }),
  createStripeInvoiceFailure: (payload: string) => ({
    type: createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_FAILURE,
    payload,
  }),
  createStripeInvoiceReset: () => ({
    type: createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_RESET,
  }),
  requestSeatsRequest: (payload: Record<string, any>) => ({
    type: requestSeatsTypes.REQUEST_SEATS_REQUEST,
    payload,
  }),
  requestSeatsSuccess: (payload: Record<string, any>) => ({
    type: requestSeatsTypes.REQUEST_SEATS_SUCCESS,
    payload,
  }),
  requestSeatsFailure: (payload: string) => ({
    type: requestSeatsTypes.REQUEST_SEATS_FAILURE,
    payload,
  }),
  getStripeInvoiceRequest: (payload: string) => ({
    type: getStripeInvoiceTypes.GET_STRIPE_INVOICE_REQUEST,
    payload,
  }),
  getStripeInvoiceSuccess: (payload: Record<string, any>) => ({
    type: getStripeInvoiceTypes.GET_STRIPE_INVOICE_SUCCESS,
    payload,
  }),
  getStripeInvoiceFailure: (payload: string) => ({
    type: getStripeInvoiceTypes.GET_STRIPE_INVOICE_FAILURE,
    payload,
  }),
  getStripeInvoiceByIdRequest: (payload: Record<string, any>) => ({
    type: getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_REQUEST,
    payload,
  }),
  getStripeInvoiceByIdSuccess: (payload: Record<string, any>) => ({
    type: getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_SUCCESS,
    payload,
  }),
  getStripeInvoiceByIdFailure: (payload: string) => ({
    type: getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_FAILURE,
    payload,
  }),
  getPaginatedStripeInvoiceRequest: (payload: {
    partnerId?: string;
    pageSize?: number;
    filterDrafts?: boolean;
    invoiceId?: string;
    amount?: string;
    status?: string;
    customerName?: string;
    fromDate?: number;
    toDate?: number;
    childPartnerId?: string;
    customerPartnerId?: string;
    lastCustomerInvoice?: string;
    lastPartnerInvoice?: string;
  }) => ({
    type: getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_REQUEST,
    payload,
  }),
  getPaginatedStripeInvoiceSuccess: (payload: Record<string, any>) => ({
    type: getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_SUCCESS,
    payload,
  }),
  getPaginatedStripeInvoiceFailure: (payload: string) => ({
    type: getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_FAILURE,
    payload,
  }),
  reviewSeatRequestRequest: (payload: Record<string, any>) => ({
    type: reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_REQUEST,
    payload,
  }),
  reviewSeatRequestSuccess: (payload: Record<string, any>) => ({
    type: reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_SUCCESS,
    payload,
  }),
  reviewSeatRequestFailure: (payload: string) => ({
    type: reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_FAILURE,
    payload,
  }),
  getStripeInvoicesTableDataRequest: (payload: {
    partnerId?: string;
    pageSize?: number;
    filterDrafts?: boolean;
    invoiceId?: string;
    amount?: string;
    customerName?: string;
    fromDate?: number;
    toDate?: number;
    childPartnerId?: string;
    customerPartnerId?: string;
    lastCustomerInvoice?: string;
    lastPartnerInvoice?: string;
  }) => ({
    type: getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_REQUEST,
    payload: { ...payload, status: 'open' },
  }),
  getStripeInvoicesTableDataSuccess: (payload: Record<string, any>) => ({
    type: getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_SUCCESS,
    payload,
  }),
  getStripeInvoicesTableDataFailure: (payload: string) => ({
    type: getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_FAILURE,
    payload,
  }),
  resetStripeInvoicesTableData: () => ({
    type: getStripeInvoiceTableDataTypes.RESET_STRIPE_INVOICE_TABLE_DATA,
  }),
  getStripeReceiptsTableDataRequest: (payload: {
    partnerId?: string;
    pageSize?: number;
    filterDrafts?: boolean;
    invoiceId?: string;
    amount?: string;
    customerName?: string;
    fromDate?: number;
    toDate?: number;
    childPartnerId?: string;
    customerPartnerId?: string;
    lastCustomerInvoice?: string;
    lastPartnerInvoice?: string;
  }) => ({
    type: getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_REQUEST,
    payload: { ...payload, status: 'paid' },
  }),
  getStripeReceiptsTableDataSuccess: (payload: Record<string, any>) => ({
    type: getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_SUCCESS,
    payload,
  }),
  getStripeReceiptsTableDataFailure: (payload: string) => ({
    type: getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_FAILURE,
    payload,
  }),
  resetStripeReceiptsTableData: () => ({
    type: getStripeReceiptsTableDataTypes.RESET_STRIPE_RECEIPTS_TABLE_DATA,
  }),
  getStripePartnerRevenueDataRequest: (payload: string) => ({
    type: getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_REQUEST,
    payload,
  }),
  getStripePartnerRevenueDataSuccess: (payload: string) => ({
    type: getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_SUCCESS,
    payload,
  }),
  getStripePartnerRevenueDataFailure: (payload: string) => ({
    type: getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_FAILURE,
    payload,
  }),
  getStripePartnerSeatRequestsRequest: (payload: {
    partnerId: string;
    childPartnerId: string;
  }) => ({
    type: getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_REQUEST,
    payload,
  }),
  getStripePartnerSeatRequestsSuccess: (payload: Record<string, any>) => ({
    type: getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_SUCCESS,
    payload,
  }),
  getStripePartnerSeatRequestsFailure: (payload: string) => ({
    type: getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_FAILURE,
    payload,
  }),
};
