import { baseSettings, devConfig, PARTNER_ID_NOODLE } from 'settings/index';

const DOMAIN_PARTNER_ID_MAPPINGS = {
  // Noodle
  localhost: devConfig.partnerId || PARTNER_ID_NOODLE,
  'dev.noodle.com': PARTNER_ID_NOODLE,
  'qa.noodle.com': PARTNER_ID_NOODLE,
  'staging.noodle.com': PARTNER_ID_NOODLE,
  'staging2.noodle.com': PARTNER_ID_NOODLE,
  'www.noodle.com': PARTNER_ID_NOODLE,
  'noodle.com': PARTNER_ID_NOODLE,
  'my.noodle.com': PARTNER_ID_NOODLE,
  'socialwork.noodle.com': PARTNER_ID_NOODLE,
  'business.noodle.com': PARTNER_ID_NOODLE,
  'tech.noodle.com': PARTNER_ID_NOODLE,
  'teach.noodle.com': PARTNER_ID_NOODLE,
};

const NOODLE_INSTANCE_DOMAINS = [
  devConfig.partnerId === 'NOODLE' ? 'localhost' : undefined,
  'dev.noodle.com',
  'qa.noodle.com',
  'staging.noodle.com',
  'staging2.noodle.com',
  'www.noodle.com',
  'noodle.com',
  'my.noodle.com',
  'socialwork.noodle.com',
  'business.noodle.com',
  'tech.noodle.com',
  'teach.noodle.com',
  'local.noodle.com',
];

export const getPartnerId = (): string => {
  const { hostname } = window.location;
  const partnerId = DOMAIN_PARTNER_ID_MAPPINGS[hostname];
  return partnerId || null;
};

export const getUniversityPartnerId = (selectedPartnerId?: string) => {
  const partnerId = selectedPartnerId ?? getPartnerId();

  const [universityPartnerId, corporatePartnerId] = partnerId.split('-');

  return { corporatePartnerId, universityPartnerId };
};

export const getIsCorporateInstance = (selectedPartnerId?: string) => {
  const { corporatePartnerId } = getUniversityPartnerId(selectedPartnerId);

  return !!corporatePartnerId;
};

export const getIsAdminExperience = () => {
  const isAdminExperience = window.location.href.includes('/instance');
  return isAdminExperience;
};

export const isNonNoodleInstance = !NOODLE_INSTANCE_DOMAINS.includes(window.location.hostname);

export const getAppSettings = () => (isNonNoodleInstance ? null : baseSettings[PARTNER_ID_NOODLE]);

// could alternatively update DOMAIN_PARTNER_ID_MAPPINGS so the value is an object but i like the idea of centralizing
// configs in one place for now
export const getAppSettingsForEnvironment = () => {
  const { hostname } = window.location;
  const appSettings = getAppSettings();
  const {
    // @ts-ignore
    environments: { qa, staging2, prod },
  } = appSettings;

  if (hostname.startsWith('qa') || hostname === 'localhost') {
    return qa;
  }

  if (hostname.startsWith('staging2') || hostname.startsWith('demo-')) {
    return staging2;
  }

  return prod;
};

export const getAppSettingForEnvironment = (name: string, defaultValue?: string) => {
  const appSettingsForEnvironment = getAppSettingsForEnvironment();
  return appSettingsForEnvironment[name] || defaultValue;
};
