// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  palette: { neutral, greyscale, primary },
  noodera: { fontTypes, spacing },
} = getTheme();
export const Header: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  height: 96px;
  background: ${primary.hundred};
  display: flex;
  justify-content: center;
  align-items: center;
  ${fontTypes.font3Medium}
  color: ${greyscale.white};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    text-align: center;
  }
`;
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  background: ${greyscale.white};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
export const Container: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  padding-top: ${spacing.x3};
  max-width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Title: StatelessFunctionalComponent<any> = styled.p`
  color: ${neutral.hundred};
  margin-top: ${spacing.x3};
  ${fontTypes.font4Medium}

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
