import * as React from 'react';
import debounce from 'lodash.debounce';
import NooderaSearchBar from 'components/NooderaSearchBar/index';
import { ImagePickerWrapper, ImagePickerContainer } from './ImagePicker.styles';
import type { ImagePickerProps } from './ImagePicker.types';
import ImagePickerResults from './ImagePickerResults/index';
import { useLazyGetUnsplashImagesQuery } from 'lib/Api';

const ImagePicker = ({
  onSelectImage,
  selectedPhoto,
  placeholder,
  className,
}: ImagePickerProps): React.ReactElement<any> => {
  const [
    getUnsplashImages,
    { data: { coverImages, pageNumber } = { coverImages: [], pageNumber: 0 }, isLoading },
  ] = useLazyGetUnsplashImagesQuery();

  const [searchTerm, setSearchTerm] = React.useState('');
  const [internalSelectedPhoto, setInternalSelectedPhoto] = React.useState(selectedPhoto ?? null);

  const selectPhotoAndSendToParent = (image: Record<string, any> | null) => {
    onSelectImage(image);
    setInternalSelectedPhoto(image);
  };

  const selectPhoto = (image: Record<string, any>): void => {
    if (!internalSelectedPhoto || internalSelectedPhoto.id !== image.id) {
      selectPhotoAndSendToParent(image);
    } else {
      selectPhotoAndSendToParent(null);
    }
  };

  const handleSearchOtherPage = (searchPage = 1): void => {
    getUnsplashImages({ searchTerm, pageNumber: searchPage });
  };

  const updateSearchTermAndSearch = (search: string): void => {
    setSearchTerm(search);
  };

  React.useEffect(() => {
    if (searchTerm.length > 2) {
      debounce(() => {
        getUnsplashImages({ searchTerm, pageNumber: 1 });
      }, 300)();
    }
  }, [searchTerm, getUnsplashImages]);

  const renderDialogChoose = (): React.ReactElement<any> => (
    <>
      <NooderaSearchBar
        id='image-picker-search'
        placeholder={placeholder}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateSearchTermAndSearch(e.target.value)
        }
      />
      {coverImages && coverImages?.length > 0 && (
        <ImagePickerResults
          isLoading={isLoading}
          searchTerm={searchTerm}
          selectedPhoto={internalSelectedPhoto}
          coverImages={coverImages}
          coverImagesPage={pageNumber}
          onSearchOtherPage={handleSearchOtherPage}
          onSelectPhoto={selectPhoto}
        />
      )}
    </>
  );

  return (
    <ImagePickerWrapper className={className}>
      <ImagePickerContainer>{renderDialogChoose()}</ImagePickerContainer>
    </ImagePickerWrapper>
  );
};

export default ImagePicker;
