// @ts-nocheck
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
// noinspection ES6PreferShortImport
import { getTheme } from 'settings/theme/index';
import { TABLET_WIDTH, TABLET_SMALL_WIDTH } from 'commons/constants';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale, neutral },
  noodera: { spacing, fontTypes },
} = getTheme();
export const DashboardWrapper: StatelessFunctionalComponent<any> = styled.div`
  background-color: ${greyscale.snow};
  min-height: 100vh;

  .app-container {
    background-color: ${greyscale.snowWhite};
    display: flex;
    min-height: inherit;
  }

  .right-side__container {
    margin-bottom: 2rem;
  }

  .dashboard {
    &__navigation {
      position: sticky;
      top: 0;
      z-index: 2;
      order: 1;

      background-color: ${greyscale.white};
      max-width: 8rem;
      min-width: 8rem;
      box-shadow: 1px 0 0 ${neutral.twenty};
      height: 100%;
    }

    &__right {
      padding-top: 2rem;
    }

    &__footer {
      bottom: 0;
      color: ${greyscale.smoke};
      font-size: 1.4rem;
      position: absolute;
      margin-left: 1.03rem;

      a {
        color: ${greyscale.smoke};
      }

      a:hover {
        color: ${greyscale.black};
      }
    }

    &__recommended-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 30rem;
      justify-content: space-evenly;
      text-align: center;
    }

    &__separator {
      bottom: 15rem;
      position: absolute;
      width: 100%;
    }

    &__workspace {
      background-color: transparent;
    }

    &__wrapper {
      overflow-x: auto;
      height: 86vh;
      position: relative;
    }
  }

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    .app-container {
      margin-top: 0;
      padding: 0;
    }

    .col-md-3,
    .col-lg-3,
    .col-lg-6,
    .col-md-6,
    .col-md-9 {
      padding: 0;
    }

    .dashboard__navigation {
      display: none;
    }
  }
`;

export const ContentWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  order: 2;
  ${(props) => (!props.isLoggedIn ? `background: ${greyscale.white};` : '')};
  width: calc(99% - 8rem);
  border-top: 0;
`;

export const ContentSection: StatelessFunctionalComponent<any> = styled(Row)`
  height: 100%;
  flex-wrap: nowrap;
`;

export const MainContentSection: StatelessFunctionalComponent<any> = styled(Col)`
  padding: ${spacing.x3};
  background: ${greyscale.white};
  height: 100%;

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    padding: 0;
  }
`;

export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  @media (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${spacing.x5};
  }
`;

export const StyledTitle: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4Medium};
  color: ${neutral.hundred};
  margin-left: ${spacing.x7};
`;

export const TitleWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: ${spacing.x7} 0;

  & > * {
    display: flex;
  }

  &:hover {
    background: ${neutral.ten};
    cursor: pointer;
  }
`;

export const StyledModalSubtitle: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4};
`;

export const ContainerHeaderTabsMobile: StatelessFunctionalComponent<any> = styled.div`
  height: fit-content;
`;

export const MainTitle: StatelessFunctionalComponent<any> = styled.h1`
  ${fontTypes.font3Medium};
  margin: 0 0 ${spacing.x3} 0;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    margin: ${spacing.x5};
  }
`;

export const MainContainer: StatelessFunctionalComponent<{
  isInstance?: boolean;
}> = styled.div`
  ${({ isInstance }) => (isInstance ? 'width: 100%' : 'max-width: 1024px')};
  height: 100%;
  margin: 0 auto;
`;
