// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing },
} = getTheme();
export const StyledPaymentElement: StatelessFunctionalComponent<any> = styled.div`
  margin-bottom: ${spacing.x3};
`;
