// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { neutral },
  noodera: {
    fontTypes: { font4, font5 },
  },
  gradients,
} = getTheme();
const BAR_HEIGHT = '0.5rem';
export const ProgressBarWrapper: StatelessFunctionalComponent<any> = styled.div`
  && {
    margin-top: 1rem;
    ${font4}
  }
`;
export const ContainerProgress = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 1rem;
    div {
      flex: auto;
    }
  }
`;
export const ProgressBarBackground = styled.div`
  && {
    border-radius: 0.25rem;
    height: ${BAR_HEIGHT};
    background-color: ${neutral.sixty};
    width: 100%;
  }
`;
export const ProgressBarValue: StatelessFunctionalComponent<any> = styled.div`
  && {
    background: ${gradients.noodera};
    position: relative;
    width: ${({ value }) => value};
    height: ${BAR_HEIGHT};
  }
`;
export const PercentStyle: StatelessFunctionalComponent<any> = styled.div`
  ${font5}
`;
