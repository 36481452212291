// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  palette: { neutral },
  noodera: { spacing, fontTypes },
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-top: ${spacing.x3};
  display: flex;
  justify-content: center;
`;
export const Container: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Title: StatelessFunctionalComponent<any> = styled.p`
  color: ${neutral.hundred};
  ${fontTypes.font4Medium}
`;
export const TitleWrapper: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  margin-bottom: ${spacing.x6};
`;
export const SubTitle: StatelessFunctionalComponent<any> = styled.p`
  color: ${neutral.hundred};
  ${fontTypes.font4}
`;
export const InputChipsContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
`;
