import * as React from 'react';
import { relConstants } from 'commons/constants/general';
import { useWidth } from 'utils/customHooks';
import { TABLET_SMALLER_WIDTH } from 'commons/constants';
import { Link } from 'react-router-dom';
import {
  BreadcrumbIcon,
  BreadcrumbIconMobile,
  BreadcrumbLink,
  MobileBreadcrumbContainer,
} from 'components/Breadcrumb/Breadcrumb.styles';
import type { BreadcrumbProps } from './Breadcrumb.types';

const Breadcrumb = ({ breadcrumbTrail = [] }: BreadcrumbProps): React.ReactElement<any> | null => {
  const lastBreadcrumbTrail = breadcrumbTrail.length ? breadcrumbTrail.at(-1) : {};

  const width = useWidth();
  const isMobile = width < TABLET_SMALLER_WIDTH;

  const fullBreadcrumbs = breadcrumbTrail.map(
    (
      item: {
        title: string;
        url: string;
      },
      idx: number,
    ) => (
      <span key={item.title}>
        <BreadcrumbLink
          to={item.url}
          rel={relConstants.NOREFERRER_NOOPENER_NOFOLLOW}
          aria-label={`Navigate to ${item.title}`}
          aria-current={idx === breadcrumbTrail.length - 1 ? 'page' : undefined}
        >
          {item.title}
        </BreadcrumbLink>
        {idx !== breadcrumbTrail.length - 1 && <BreadcrumbIcon icon='icon-arrow_right' />}
      </span>
    ),
  );

  const mobileBreadcrumbs = (
    <MobileBreadcrumbContainer key={lastBreadcrumbTrail.title} aria-label='Mobile BreadCrumbs'>
      <Link
        to={lastBreadcrumbTrail.url}
        rel={relConstants.NOREFERRER_NOOPENER_NOFOLLOW}
        aria-label={`Navigate back to ${lastBreadcrumbTrail.title}`}
      >
        <BreadcrumbIconMobile icon='icon-arrow_left' />
      </Link>
      <BreadcrumbLink
        to={lastBreadcrumbTrail.url}
        rel={relConstants.NOREFERRER_NOOPENER_NOFOLLOW}
        aria-label={`Navigate to ${lastBreadcrumbTrail.title}`}
        aria-current={breadcrumbTrail.length === 1 ? 'page' : undefined}
      >
        {lastBreadcrumbTrail.title}
      </BreadcrumbLink>
    </MobileBreadcrumbContainer>
  );

  return isMobile ? (
    mobileBreadcrumbs
  ) : (
    <nav aria-label='Breadcrumb navigation'>{fullBreadcrumbs}</nav>
  );
};

export default Breadcrumb;
