import validator from 'validator';
import type { ValidationFunctionParams, ValidationFieldError } from './Validation.types';

/**
 @description
 Validator function to check that a value is required
 It validates that the value exists
 @param {string} name the name of the field
 @param {string} value the value of field
 @param {string} outputName the output field name to display to the user
 */
const isRequired = ({ name, value }: ValidationFunctionParams): ValidationFieldError => {
  const errors = {};
  errors[name] = validator.isEmpty(value)
    ? {
        errorMsg: 'Required field',
      }
    : {};
  return errors;
};

export default isRequired;
