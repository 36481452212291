import { getUniversitiesTypes, getUniversityTypes } from './types';

export const universityActions = {
  getUniversitiesRequest: () => ({
    type: getUniversitiesTypes.REQUEST,
  }),
  getUniversitiesSuccess: (payload: Record<string, any>[]) => ({
    type: getUniversitiesTypes.SUCCESS,
    payload,
  }),
  getUniversitiesFailure: (payload: string) => ({
    type: getUniversitiesTypes.FAILURE,
    payload,
  }),
  getUniversityRequest: (payload: string) => ({
    type: getUniversityTypes.REQUEST,
    payload,
  }),
  getUniversitySuccess: (payload: Record<string, any>) => ({
    type: getUniversityTypes.SUCCESS,
    payload,
  }),
  getUniversityFailure: (payload: string) => ({
    type: getUniversityTypes.FAILURE,
    payload,
  }),
};
