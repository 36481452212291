export const getMySectionsTypes = {
  GET_MY_SECTIONS_REQUEST: 'GET_MY_SECTIONS_REQUEST',
  GET_MY_SECTIONS_SUCCESS: 'GET_MY_SECTIONS_SUCCESS',
  GET_MY_SECTIONS_FAILURE: 'GET_MY_SECTIONS_FAILURE',
};
export const getSectionPreviewTypes = {
  GET_SECTION_PREVIEW_REQUEST: 'GET_SECTION_PREVIEW_REQUEST',
  GET_SECTION_PREVIEW_SUCCESS: 'GET_SECTION_PREVIEW_SUCCESS',
  GET_SECTION_PREVIEW_FAILURE: 'GET_SECTION_PREVIEW_FAILURE',
};
export const createSectionTypes = {
  CREATE_SECTION_REQUEST: 'CREATE_SECTION_REQUEST',
  CREATE_SECTION_SUCCESS: 'CREATE_SECTION_SUCCESS',
  CREATE_SECTION_FAILURE: 'CREATE_SECTION_FAILURE',
  CREATE_SECTION_RESET: 'CREATE_SECTION_RESET',
};
export const sectionTypes = {
  SELECT_SECTION: 'SELECT_SECTION',
};
export const getSectionsTypes = {
  GET_SECTIONS_REQUEST: 'GET_SECTIONS_REQUEST',
  GET_SECTIONS_SUCCESS: 'GET_SECTIONS_SUCCESS',
  GET_SECTIONS_FAILURE: 'GET_SECTIONS_FAILURE',
};
export const joinSectionTypes = {
  JOIN_SECTION_REQUEST: 'JOIN_SECTION_REQUEST',
  JOIN_SECTION_SUCCESS: 'JOIN_SECTION_SUCCESS',
  JOIN_SECTION_FAILURE: 'JOIN_SECTION_FAILURE',
};
export const joinSelfEnrolledSectionTypes = {
  JOIN_SELF_ENROLLED_SECTION_REQUEST: 'JOIN_SELF_ENROLLED_SECTION_REQUEST',
  JOIN_SELF_ENROLLED_SECTION_SUCCESS: 'JOIN_SELF_ENROLLED_SECTION_SUCCESS',
  JOIN_SELF_ENROLLED_SECTION_FAILURE: 'JOIN_SELF_ENROLLED_SECTION_FAILURE',
};
export const removeJoinSectionTypes = {
  REMOVE_JOIN_SECTION: 'REMOVE_JOIN_SECTION',
};
export const removeJoinSelfEnrolledSectionTypes = {
  REMOVE_SELF_ENROLLED_JOIN_SECTION: 'REMOVE_SELF_ENROLLED_JOIN_SECTION',
};
