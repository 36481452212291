import humps from 'humps';
import get from 'lodash.get';

import { getAppSettings } from 'utils/privateLabel/index';
import JsonApi from 'utils/jsonApi';
import { getMembersFromResponse } from 'utils/apiHelpers';

import type { NormalizedJsonApi } from 'commons/types';
import type {
  CreateTeamPayload,
  GetTeamSavedResourcesPayload,
  JoinTeamByInviteAPIPayload,
  SaveContentPayload,
  Team as TeamType,
  TeamInvite,
  TeamInvitePayload,
  TeamPreviewPayload,
  TeamResource,
  TeamUserFlags,
} from 'commons/types/teamTypes';

import { JoinSelfEnrolledSectionPayload } from 'ducks/section/saga';
import { axios } from '../index';

export default class Team {
  jsonApi: JsonApi;

  entity = 'entities.team';

  basePath = '/teams';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getTeams(): Promise<TeamType[]> {
    const response = await this.jsonApi.get(
      `${this.basePath}?sort=-created_at&filter=[{"name":"team_type","op":"eq","val":null}]`,
      false,
    );
    const teamsArr = get(response, 'data', {});
    return teamsArr.map((team) => ({
      ...team?.attributes,
      id: team?.id,
      userFlags: team?.userFlags,
    }));
  }

  async getTeam(payload: string): Promise<TeamType> {
    const response = await this.jsonApi.get(`${this.basePath}/${payload}`);
    return get(response, this.entity, {});
  }

  async getMyTeams(): Promise<TeamType[]> {
    const response = await this.jsonApi.get(
      `${this.basePath}?my_teams=1&filter=[{"name":"team_type","op":"eq","val":null}]&sort_method=recently_joined`,
    );
    const teamsObj = get(response, this.entity, {});
    return (Object.values(teamsObj) as any) || [];
  }

  async getTeamPreview(payload: TeamPreviewPayload): Promise<TeamType> {
    const adminResponse = await this.jsonApi.get(
      `${this.basePath}/${payload.teamId}?fields[team]=user_id`,
    );
    const adminId = get(adminResponse, `entities.team[${payload.teamId}].userId`, -1);
    const userIsAdmin = adminId === Number(payload.userId);
    const pendingMembersPath = userIsAdmin
      ? ',pending_join_requests,pending_internal_invites,pending_external_invites'
      : '';
    const acceptedMembers = payload.fetchAcceptedMembers ? ',accepted_members' : '';
    const response = await this.jsonApi.get(
      `${this.basePath}/${payload.teamId}?include=admin${acceptedMembers}${pendingMembersPath}`,
      false,
    );
    const team = get(response, 'data.attributes', {});
    const userFlags: TeamUserFlags = get(response, 'data.userFlags', {});
    return {
      id: Number(payload.teamId),
      ...team,
      ...getMembersFromResponse(response, userIsAdmin),
      userFlags,
    };
  }

  async approveOrJoinTeam(payload: JoinTeamByInviteAPIPayload): Promise<number> {
    try {
      const { teamId, userId } = payload;
      const teamResponse = await this.jsonApi.get(`${this.basePath}/${teamId}`);
      const teamObj = get(teamResponse, this.entity, {});
      const { teamType } = teamObj[teamId];
      const isSection = teamType === 'section';
      const fullPath = `${isSection ? '/sections' : this.basePath}/${teamId}/relationships/members`;
      await this.jsonApi.post(
        fullPath,
        {
          type: 'user',
          id: userId.toString(),
        },
        false,
      );
      return teamId;
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ line 46 ~ Team ~ joinTeamByInvite ~ err', err);
      throw err;
    }
  }

  async denyJoinTeam(payload: JoinTeamByInviteAPIPayload): Promise<number> {
    try {
      const { teamId, userId } = payload;
      await this.jsonApi.delete(`${this.basePath}/${teamId}/relationships/members`, {
        data: {
          type: 'user',
          id: userId.toString(),
        },
      });
      return teamId;
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ ~ Team ~ denyJoinTeam ~ err', err);
      throw err;
    }
  }

  async createTeam(payload: CreateTeamPayload): Promise<TeamType> {
    try {
      const partnerId = getAppSettings()?.partnerId;
      const {
        createDetails: { name, about },
        privacyScreen: { privacy, visibility },
        teamImage,
      } = payload;
      const apiPayload = {
        type: 'team',
        attributes: {
          name,
          description: about,
          image: teamImage?.urls?.regular,
          public: privacy.public,
          visible: visibility.visible,
          partner_id: partnerId,
        },
      };
      const response = await this.jsonApi.post(this.basePath, apiPayload, false);
      // unfortunately, typing this as any seem to be the best approach
      // flow doesn't understand that when you call Object.values() in an interface like
      // { [number]: string } will return string[] cause every value in your interface is a string
      // check https://github.com/facebook/flow/issues/2221 for more info
      // moving to TypeScript would solve this issue
      const teams: any = Object.values(response.entities.team);
      return teams[0];
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 83 ~ Team ~ createTeam ~ err', err);
      // We're probably gonna add sentry here
      throw err;
    }
  }

  async getTeamSavedResources(payload?: GetTeamSavedResourcesPayload): Promise<TeamResource[]> {
    const filter = '[{"name":"content_type","op":"eq","val":"article"}]';
    let urlRequest;

    if (payload?.teamId) {
      urlRequest = `${this.basePath}/saved-content?team_id=${payload.teamId}&filter=${filter}`;
    } else {
      urlRequest = `${this.basePath}/saved-articles?filter=${filter}`;
    }

    const response = await this.jsonApi.get(urlRequest, false);
    const responseObj = get(response, 'data', {});
    return responseObj.map((resource) => ({ ...resource?.attributes, id: resource?.id }));
  }

  async inviteMembers(payload?: TeamInvitePayload): Promise<string[]> {
    try {
      const apiPayload = payload?.invitees.map((invitee) => ({
        type: 'team_invite',
        attributes: {
          team_id: payload?.teamId,
          invitee_email: invitee,
        },
      }));
      const queryParams = payload?.partnerId
        ? new URLSearchParams({
            partner_id: payload?.partnerId,
          })
        : null;

      const response = await this.jsonApi.post(
        `${this.basePath}/invites${queryParams ? `?${queryParams.toString()}` : ''}`,
        apiPayload,
        false,
      );
      const teamInviteObj = response?.entities?.teamInvite;
      // using any here is the best solution due to flow limitations
      // check https://github.com/facebook/flow/issues/2221 for more info
      // moving to TypeScript would solve this issue
      const teamInvites: any = teamInviteObj ? Object.values(teamInviteObj) : [];

      return teamInvites ? teamInvites.map((emailObj: TeamInvite) => emailObj?.inviteeEmail) : [];
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 105 ~ Team ~ inviteMembers ~ err', err);
      // We're probably going to add sentry here
      throw err;
    }
  }

  async saveContent(payload: SaveContentPayload): Promise<NormalizedJsonApi> {
    const { slug, teamId, type } = payload;
    const data = {
      type: 'team_content',
      attributes: {
        slug,
        content_type: type,
      },
    };
    const filter = '[{"name":"content_type","op":"eq","val":"course"}]';
    return this.jsonApi.post(
      `${this.basePath}/saved-content?team_id=${teamId}&filter=${filter}`,
      data,
      false,
    );
  }

  async joinSelfEnrolledSection(payload: JoinSelfEnrolledSectionPayload['payload']): Promise<any> {
    const { selectedSectionId, userId, partnerId, courseSlug, sectionType } = payload;

    const requestPayload = {
      user_ids: [userId],
      team_id: selectedSectionId || null,
      section_type: sectionType,
    };

    const response = await axios.post(
      `${this.basePath}/${courseSlug}/learners?generate_invoice=false&partner_id=${partnerId}`,
      requestPayload,
    );
    return humps.camelizeKeys(response?.data);
  }
}
