// @ts-nocheck
import type { Saga } from 'redux-saga';
import { put, takeLatest, call } from 'redux-saga/effects';
import type { GlobalSearchPayload } from 'commons/types';
import alertActions from 'ducks/alert/actions';
import api from 'lib/Api';
import { alertTypesConstants } from 'commons/constants/index';
import { getGlobalSearchTypes } from './types';
import globalSearchActions from './actions';

const { DANGER } = alertTypesConstants;
export function* getGlobalSearchSaga({ payload }: { payload: GlobalSearchPayload }): Saga<void> {
  try {
    const searchResults = yield call([api.globalSearch, 'getAll'], payload);
    yield put(globalSearchActions.getGlobalSearchSuccess({ ...searchResults, ...payload }));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the search results!',
        type: DANGER,
      }),
    );
    yield put(
      globalSearchActions.getGlobalSearchFail('There was an error fetching the search results.'),
    );
  }
}
export default function* globalSearchSaga(): Saga<void> {
  yield takeLatest(getGlobalSearchTypes.REQUEST, getGlobalSearchSaga);
}
