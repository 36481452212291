import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type { OnboardingOccupation as OnboardingOccupationType } from 'commons/types/onboardingTypes';

export default class Onboarding {
  jsonApi: JsonApi;

  entity = 'entities.occupations';

  basePath = '';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getOnboardingQuestion(): Promise<any[]> {
    try {
      const response = await this.jsonApi.get(`${this.basePath}/onboarding_questions`);
      const questions = get(response, 'entities.onboardingQuestion', {});
      return (Object.values(questions) as any) || [];
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ Managed Articles ~ getArticles ~ err', err);
      throw err;
    }
  }

  async getOnboardingOccupations(): Promise<OnboardingOccupationType[]> {
    try {
      const response = await this.jsonApi.get(`/occupations`);
      const responseObj = get(response, 'entities.occupations', {});
      const occupationsObj: OnboardingOccupationType[] = Object.values(responseObj) as any;
      return occupationsObj;
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ Onboarding ~ getOnboardingOccupations ~ err', err);
      throw err;
    }
  }

  async insertAnswer(payload: any): Promise<any> {
    const { id, answer, answers } = payload;
    const isExist = answers.find((item) => item.questionId === id);
    let response;

    if (isExist) {
      const data = {
        type: 'onboarding_question_answer',
        id: isExist.id.toString(),
        attributes: {
          answer,
        },
      };
      response = await this.jsonApi.patch(
        `${this.basePath}/onboarding_question_answers/${isExist.id}`,
        data,
      );
    } else {
      const data = {
        type: 'onboarding_question_answer',
        attributes: {
          question_id: id,
          answer,
        },
      };
      response = await this.jsonApi.post(
        `${this.basePath}/onboarding_question_answers`,
        data,
        false,
      );
    }

    const responseObj = Object.values(get(response, 'entities.onboardingQuestionAnswer', {}));
    return responseObj;
  }

  async getOnboardingAnswers(): Promise<any[]> {
    try {
      const response = await this.jsonApi.get(`${this.basePath}/onboarding_question_answers`);
      const responseObj = get(response, 'entities.onboardingQuestionAnswer', {});
      const answers = Object.values(responseObj);
      return answers;
    } catch (err) {
      // We're probably gonna add sentry here
      console.error('🚀 ~ file: index.js ~ Managed Articles ~ getArticles ~ err', err);
      throw err;
    }
  }
}
