export const getPartnerProgramTypes = {
  REQUEST: 'GET_PARTNER_PROGRAM_REQUEST',
  SUCCESS: 'GET_PARTNER_PROGRAM_SUCCESS',
  FAILED: 'GET_PARTNER_PROGRAM_FAILED',
};
export const getPartnerProgramTypeListTypes = {
  REQUEST: 'GET_PARTNER_PROGRAM_TYPE_LIST_REQUEST',
  SUCCESS: 'GET_PARTNER_PROGRAM_TYPE_LIST_SUCCESS',
  FAILED: 'GET_PARTNER_PROGRAM_TYPE_LIST_FAILED',
};
export const deletePartnerProgramTypeTypes = {
  REQUEST: 'DELETE_PARTNER_PROGRAM_TYPE_REQUEST',
  SUCCESS: 'DELETE_PARTNER_PROGRAM_TYPE_SUCCESS',
  FAILED: 'DELETE_PARTNER_PROGRAM_TYPE_FAILED',
};
export const getPartnerProgramListTypes = {
  REQUEST: 'GET_PARTNER_PROGRAM_LIST_REQUEST',
  SUCCESS: 'GET_PARTNER_PROGRAM_LIST_SUCCESS',
  FAILED: 'GET_PARTNER_PROGRAM_LIST_FAILED',
};
export const addPartnerProgramTypeTypes = {
  REQUEST: 'ADD_PARTNER_PROGRAM_TYPE_REQUEST',
  SUCCESS: 'ADD_PARTNER_PROGRAM_TYPE_SUCCESS',
  FAILED: 'ADD_PARTNER_PROGRAM_TYPE_FAILED',
};
export const adminPartnerProgramTypes = {
  FILTER_SUGGESTIONS: 'ADMIN_PARTNER_PROGRAM_FILTER_SUGGESTIONS',
  CLEAR_FILTER: 'ADMIN_PARTNER_PROGRAM_CLEAR_FILTER',
};
