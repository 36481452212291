import * as React from 'react';
import { AvatarImage } from './Avatar.styles';

// Since we're passing in restProps, we extend from the AvatarImage styled component
// so we get proper typing for our own props and the element's props
interface AvatarProps extends React.ComponentProps<typeof AvatarImage> {
  img: string;
  alt: string;
  objectFit?: string;
}

const Avatar: React.FC<AvatarProps> = ({ img, alt, objectFit, ...restProps }) => (
  <AvatarImage
    {...restProps}
    referrerPolicy='no-referrer'
    objectFit={objectFit}
    src={img}
    alt={alt}
  />
);

export default Avatar;
