import * as React from 'react';
import capitalize from 'utils/string/capitalize';
import {
  CustomTextField,
  ErrorText,
  Wrapper,
} from 'screens/Profile/UpdateUser/components/PhoneNumberTextField/PhoneNumberTextField.styles';

interface PhoneNumberTextFieldProps {
  error?: string;
}

const PhoneNumberTextField = React.forwardRef(
  ({ error, ...props }: PhoneNumberTextFieldProps, ref) => (
    <Wrapper>
      <CustomTextField inputRef={ref} {...props} fullWidth error={!!error} />
      {!!error && <ErrorText type='left-aligned' text={capitalize(error)} />}
    </Wrapper>
  ),
);

export default PhoneNumberTextField;
