// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const {
  noodera: { fontTypes, spacing },
  palette,
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled(Box)`
  && {
    & .MuiInputLabel-root {
      ${fontTypes.font4Medium};
      color: ${palette.neutral.eighty};
      margin-bottom: ${spacing.x7};
    }
  }
`;
export const StyledTextarea: StatelessFunctionalComponent<any> = styled(TextareaAutosize)`
  && {
    ${fontTypes.font4};
    padding: ${spacing.x6};
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${palette.neutral.forty};
    color: ${palette.greyscale.black};
    max-height: 96px;
    resize: none;

    &::placeholder {
      ${fontTypes.font4};
      color: ${palette.neutral.seventy};
    }
  }
`;
export const StyledText: StatelessFunctionalComponent<any> = styled.span`
  && {
    ${fontTypes.font5};
    color: ${palette.neutral.eighty};
    display: flex;
    justify-content: end;
    margin-top: ${spacing.x7};
  }
`;
