// @ts-nocheck
import { delay, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import type { Saga } from 'redux-saga';
import api from 'lib/Api';
import { validateCriteriaFormat } from 'utils/validation';
import { alertTypesConstants, landingPagesErrorMessages } from 'commons/constants';
import alertActions from 'ducks/alert/actions';
import type { LandingPageObject, GetLandingPagesPayload } from 'commons/types';
import landingPagesActions from './actions';
import {
  createLandingPageTypes,
  getLandingPageTypes,
  getParentsSuggestionsTypes,
  landingPageListTypes,
  updateLandingPageTypes,
  updateCriteriaTypes,
} from './types';

const { DANGER, SUCCESS } = alertTypesConstants;
const { INVALID_CRITERIA_OBJECT } = landingPagesErrorMessages;

function* validateCriteria(criteria: string) {
  if (criteria) {
    validateCriteriaFormat(criteria);
    const { count } = yield call(() => 1);

    if (typeof count !== 'number' || count <= 0) {
      throw Error(INVALID_CRITERIA_OBJECT);
    }
  }
}

export const getParentsSuggestionsSelector = (state: Record<string, any>) =>
  state.landingPages.parentsSuggestions;
export function* getLandingPageSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const landingPage = yield call([api.landingPage, 'getBySlug'], payload);
    yield put(landingPagesActions.getLandingPageSuccess(landingPage));
  } catch (error) {
    yield put(
      landingPagesActions.getLandingPageFail('There was an error fetching the landing page data.'),
    );
    yield put(push('/404'));
  }
}
export function* getLandingPagesSaga({ payload }: { payload: GetLandingPagesPayload }): Saga<void> {
  try {
    yield delay(300);
    const response = yield call([api.landingPage, 'getAll'], payload);
    const currentPage = payload.page - 1;
    yield put(landingPagesActions.getLandingPagesSuccess({ ...response, currentPage }));
  } catch (error) {
    yield put(
      landingPagesActions.getLandingPagesFail(
        'There was an error fetching the landing pages data.',
      ),
    );
  }
}
export function* getParentsSuggestionsSaga(): Saga<void> {
  try {
    let parentsSuggestions = yield select(getParentsSuggestionsSelector);

    if (parentsSuggestions.length === 0) {
      const response = yield call([api.landingPage, 'getAll'], {
        pageSize: 0,
      });
      parentsSuggestions = response.list.map(({ id, slug }) => ({
        id,
        name: `${id} - ${slug}`,
      }));
    }

    yield put(
      landingPagesActions.getParentsSuggestionsSuccess({
        parentsSuggestions,
      }),
    );
  } catch (error) {
    yield put(
      landingPagesActions.getParentsSuggestionsFailed(
        'There was an error fetching the parents suggestions data.',
      ),
    );
  }
}
export function* createLandingPageSaga({ payload }: { payload: LandingPageObject }): Saga<void> {
  try {
    const landingPage = yield call([api.landingPage, 'create'], payload);
    yield put(
      alertActions.setAlert({
        message: 'Landing Page created',
        type: SUCCESS,
      }),
    );
    yield put(landingPagesActions.createLandingPageSuccess(landingPage));
    yield put(push(`/admin/landing-pages/${landingPage.landingPage.slug}`));
  } catch (error) {
    let message;

    if (error.message === INVALID_CRITERIA_OBJECT) {
      message = INVALID_CRITERIA_OBJECT;
    } else if (!error.message.includes(400)) {
      message = 'There was an error creating the landing page data.';
      yield put(landingPagesActions.changeEditMode(false));
    } else {
      message = 'Slug already exists in the database, use a different one.';
    }

    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(landingPagesActions.createLandingPageFailed(message));
  }
}
export function* updateLandingPageSaga({ payload }: { payload: LandingPageObject }): Saga<void> {
  try {
    const landingPage = yield call([api.landingPage, 'update'], payload);
    yield put(
      alertActions.setAlert({
        message: 'Landing Page saved',
        type: SUCCESS,
      }),
    );
    yield put(landingPagesActions.updateLandingPageSuccess(landingPage));
  } catch (error) {
    let message;

    if (error.message === INVALID_CRITERIA_OBJECT) {
      message = INVALID_CRITERIA_OBJECT;
    } else if (!error.message.includes(400)) {
      message = 'There was an error updating the landing page data.';
      yield put(landingPagesActions.changeEditMode(false));
    } else {
      message = 'Slug already exists in the database, use a different one.';
    }

    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(landingPagesActions.updateLandingPageFailed(message));
  }
}
export function* updateCriteriaSaga({ payload }: { payload: LandingPageObject }): Saga<void> {
  try {
    const { criteria } = payload;
    yield validateCriteria(criteria);
    const landingPage = yield call([api.landingPage, 'update'], payload);
    yield put(
      alertActions.setAlert({
        message: "Landing Page's criteria saved",
        type: SUCCESS,
      }),
    );
    yield put(landingPagesActions.updateCriteriaSuccess(landingPage));
  } catch (error) {
    const message = 'Wrong criteria format';
    yield put(
      alertActions.setAlert({
        message,
        type: DANGER,
      }),
    );
    yield put(landingPagesActions.updateCriteriaFailed(message));
  }
}
export default function* landingPageSaga(): Saga<void> {
  yield takeEvery(createLandingPageTypes.REQUEST, createLandingPageSaga);
  yield takeEvery(getLandingPageTypes.REQUEST, getLandingPageSaga);
  yield takeEvery(getParentsSuggestionsTypes.REQUEST, getParentsSuggestionsSaga);
  yield takeLatest(landingPageListTypes.REQUEST, getLandingPagesSaga);
  yield takeEvery(updateLandingPageTypes.REQUEST, updateLandingPageSaga);
  yield takeEvery(updateCriteriaTypes.REQUEST, updateCriteriaSaga);
}
