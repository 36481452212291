// @ts-nocheck
import * as React from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import NooderaButton from 'components/NooderaButton/index';
import { images, baseUrl } from 'settings/index';
import Loader from 'components/uielements/Loader/index';
import getStartedImg from 'assets/images/get-started.webp';
import { ReactComponent as CoachSvg } from 'assets/images/coach.svg';
import { ReactComponent as DiplomaSvg } from 'assets/images/diploma.svg';
import { ReactComponent as SchoolSvg } from 'assets/images/school.svg';
import heroLearningWebp from 'assets/images/hero-learning@2x.webp';
import { useWidth } from 'utils/customHooks/index';
import { setUrlParams } from 'utils/urls/index';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants/size';
import { partnerProgramsActions } from 'ducks/partnerPrograms/actions';
import { universityActions } from 'ducks/university/actions';
import { robotsConstants } from 'commons/constants/general';
import { getAppSettings } from 'utils/privateLabel';
import {
  BannerTitle,
  GuestHomeContainer,
  HeroSection,
  HeroSectionContainer,
  SectionSubtitle,
  SectionTitle,
  StyledLink,
  GuestHomeWrapper,
  BannerSubtitle,
  BannerImageContainer,
  BannerImage,
  AoIContainer,
  Section,
  SectionsWrapper,
  SectionsContainer,
  SectionContent,
  OnYourGoalsContainer,
  DoItTogetherContainer,
  StyledImage,
  DoItTogetherSectionText,
  OnYourGoalsSectionText,
  StyledCourseCard,
  StyledInternalLink,
  LeftContent,
  StyledSearchInput,
  LeftSection,
  NoodleOnYourGoalsTitle,
  StyledNooderaSwiper,
  DoItTogetherSectionTitle,
} from './GuestHome.styles';

const MetaTags = React.lazy(() => import('components/MetaTags'));
const CompleteFooter = React.lazy(() => import('components/CompleteFooter'));
const UniversityLogos = React.lazy(() => import('components/UniversityLogos'));
const AreaOfInterestList = React.lazy(() => import('components/AreaOfInterestList/index'));

const GuestHome = (): React.ReactElement<any> => {
  const dispatch = useDispatch();
  const history = useHistory();
  const width = useWidth();
  const pathname = useSelector((state) => state.router.location.pathname || '');
  const partnerPrograms = useSelector((state) => state.partnerPrograms.partnerPrograms?.data);
  const universities = useSelector((state) => state.university.universities?.data);
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  React.useEffect(() => {
    dispatch(
      partnerProgramsActions.getPartnerProgramsRequest({
        sort: 'random',
      }),
    );
    dispatch(universityActions.getUniversitiesRequest());
  }, []);

  const handleSearch = (value: string): void => {
    history.push(`/search/${value}`);
  };

  const renderMetaTags = (): React.ReactElement<any> => {
    const currentPathname = `${baseUrl}${pathname}`;
    const { companyName, companySlogan } = getAppSettings() || globalPartner;
    const title = `${companyName}: ${companySlogan}`;
    const description = `Discover schools, degree programs, courses, connect with education experts, and find helpful information on Noodle—the most efficient, transparent, and personalized way to search for your education.`;
    return (
      <MetaTags
        title={title}
        image={images.defaults.homeBanner}
        description={description}
        ogTitle={title}
        twitterCard='Noodle'
        ogUrl={currentPathname}
        robots={robotsConstants.INDEX_FOLLOW}
      />
    );
  };

  const renderCoursesSection = (): React.ReactElement<any> | null => {
    if (!partnerPrograms?.length) {
      return null;
    }

    return (
      <Section>
        <SectionContent>
          <SectionTitle>More Than A Piece Of Paper</SectionTitle>
          <SectionSubtitle>
            100% online learning experiences that go beyond the certificate
          </SectionSubtitle>
          <StyledNooderaSwiper
            showArrows={width > CELLPHONE_LARGE_WIDTH}
            spaceBetween={16}
            slidesPerView='3'
            shouldSwiperUpdate
            breakpoints={{
              '1199': {
                slidesPerView: 3,
              },
              '991': {
                slidesPerView: 2,
              },
              '768': {
                slidesPerView: 2,
              },
              '320': {
                slidesPerView: 1.1,
              },
            }}
          >
            {partnerPrograms.map(
              ({
                school: { university },
                coverImageFile,
                programName,
                url,
                modality,
                degreeType,
                sys: { id },
              }) => {
                const utmUrl = setUrlParams({
                  url,
                  urlParams: {
                    utm_source: 'noodle_linkout_prospect',
                    utm_medium: 'affiliate_cpl',
                    utm_term: 'card',
                  },
                });
                return (
                  <SwiperSlide>
                    <StyledCourseCard
                      key={id}
                      id={id}
                      title={programName}
                      image={coverImageFile?.url}
                      description={university?.name || 'University'}
                      schoolName={university?.name}
                      courseLogo={university?.logo?.url || university?.image?.url}
                      url={utmUrl}
                      linkOut
                      onChange={() => history.push('/signup')}
                      modality={modality}
                      degreeType={degreeType}
                    />
                  </SwiperSlide>
                );
              },
            )}
          </StyledNooderaSwiper>
          <StyledInternalLink to='/courses'>
            <NooderaButton.CTA>See All Programs</NooderaButton.CTA>
          </StyledInternalLink>
        </SectionContent>
      </Section>
    );
  };

  return (
    <GuestHomeWrapper role='main'>
      <React.Suspense fallback={<Loader />}>{renderMetaTags()}</React.Suspense>
      <GuestHomeContainer>
        <HeroSection>
          <HeroSectionContainer>
            <LeftContent>
              <LeftSection>
                <BannerTitle>Learn. Together.</BannerTitle>
                <BannerSubtitle>
                  Discover the best educational content for your career aspirations, get support
                  from real experts, and upskill in a more social way - all powered by the best
                  universities in the world.
                </BannerSubtitle>
              </LeftSection>
              <LeftSection>
                <StyledSearchInput
                  onSearch={handleSearch}
                  placeholder='Find resources, courses, or programs'
                />
              </LeftSection>
            </LeftContent>
            <BannerImageContainer>
              <BannerImage
                width={480}
                height={480}
                aria-label='hero-learning'
                src={heroLearningWebp}
                alt=''
              />
            </BannerImageContainer>
          </HeroSectionContainer>
        </HeroSection>
        <SectionsWrapper>
          <SectionsContainer>
            <Section>
              <SectionContent>
                <SectionTitle>Learn. With the best.</SectionTitle>
                <SectionSubtitle>
                  Noodle partners with leading universities and companies to help upskill learners
                  from every walk of life. Check out our nearly 100 online degree, and non-degree
                  programs - we’re adding more everyday!
                </SectionSubtitle>
                <React.Suspense fallback={<Loader />}>
                  <UniversityLogos universities={universities} />
                </React.Suspense>
                <StyledLink
                  data-testid='our-programs-link'
                  href='/courses'
                  rel='noopener noreferrer'
                >
                  <NooderaButton.CTA>Visit Our Programs</NooderaButton.CTA>
                </StyledLink>
              </SectionContent>
            </Section>
            <Section>
              <SectionContent>
                <SectionTitle>Learn. For Free.</SectionTitle>
                <SectionSubtitle>
                  Explore our library of thousands of free resources based on your career goals
                </SectionSubtitle>
                <AoIContainer>
                  <React.Suspense fallback={<Loader />}>
                    <AreaOfInterestList />
                  </React.Suspense>
                </AoIContainer>
              </SectionContent>
            </Section>
            {renderCoursesSection()}
            <Section>
              <SectionContent>
                <DoItTogetherContainer>
                  <div>
                    <DoItTogetherSectionTitle>
                      Do-It-Together or Do-It-Yourself
                    </DoItTogetherSectionTitle>
                    <DoItTogetherSectionText>
                      Whether you prefer to search and learn by yourself, or with a community of
                      passionate educators and learners to help you stay motivated— Noodle can help
                      you reach your goals.
                    </DoItTogetherSectionText>
                    <StyledInternalLink to='/signup'>
                      <NooderaButton.CTA>Get Started</NooderaButton.CTA>
                    </StyledInternalLink>
                  </div>
                  <div>
                    <StyledImage width='432' height='300' src={getStartedImg} alt='' />
                  </div>
                </DoItTogetherContainer>
              </SectionContent>
            </Section>
            <Section>
              <SectionContent>
                <NoodleOnYourGoalsTitle>Achieve Your Goals The Noodle Way </NoodleOnYourGoalsTitle>
                <OnYourGoalsContainer>
                  <div>
                    <CoachSvg />
                    <OnYourGoalsSectionText>
                      Work with a Noodle Coach to find the right educational pathway for your career
                      goals
                    </OnYourGoalsSectionText>
                  </div>
                  <div>
                    <SchoolSvg />
                    <OnYourGoalsSectionText>
                      Pursue that pathway by enrolling into degree and non-degree programs from top
                      institutions in the country
                    </OnYourGoalsSectionText>
                  </div>
                  <div>
                    <DiplomaSvg />
                    <OnYourGoalsSectionText>
                      Earn credentials and build competency in a more social, fun experience
                    </OnYourGoalsSectionText>
                  </div>
                </OnYourGoalsContainer>
              </SectionContent>
            </Section>
          </SectionsContainer>
        </SectionsWrapper>
      </GuestHomeContainer>
      <React.Suspense fallback={<Loader />}>
        <CompleteFooter />
      </React.Suspense>
    </GuestHomeWrapper>
  );
};

export default GuestHome;
