export const bookmarkTypes = {
  REQUEST: 'BOOKMARK_REQUEST',
  SUCCESS: 'BOOKMARK_SUCCESS',
  FAILURE: 'BOOKMARK_FAILURE',
};
export const bookmarkedItemsTypes = {
  REQUEST: 'BOOKMARKED_ITEMS_REQUEST',
  SUCCESS: 'BOOKMARKED_ITEMS_SUCCESS',
  FAILURE: 'BOOKMARKED_ITEMS_FAILURE',
};
export const getSavedItemsTypes = {
  REQUEST: 'GET_SAVED_ITEMS_REQUEST',
  SUCCESS: 'GET_SAVED_ITEMS_SUCCESS',
  FAILURE: 'GET_SAVED_ITEMS_FAILURE',
};
export const saveArticleTypes = {
  SAVE_ARTICLE_REQUEST: 'SAVE_ARTICLE_REQUEST',
  SAVE_ARTICLE_SUCCESS: 'SAVE_ARTICLE_SUCCESS',
  SAVE_ARTICLE_FAILURE: 'SAVE_ARTICLE_FAILURE',
};
export const removeArticleTypes = {
  REMOVE_ARTICLE_REQUEST: 'REMOVE_ARTICLE_REQUEST',
  REMOVE_ARTICLE_SUCCESS: 'REMOVE_ARTICLE_SUCCESS',
  REMOVE_ARTICLE_FAILURE: 'REMOVE_ARTICLE_FAILURE',
};
export const requestInfoTypes = {
  TOGGLE: 'TOGGLE_REQUEST_INFO_MODAL',
  REQUEST: 'MORE_INFO_REQUEST',
  SUCCESS: 'MORE_INFO_SUCCESS',
  FAILED: 'MORE_INFO_FAILED',
};
