import { baseUrl } from '../../settings';

import { CHROME_COOKIE_MAX_AGE } from 'commons/constants';
import type { AuthResult } from 'commons/types/authTypes';
import { GlobalPartner } from 'commons/types/partnerInstanceTypes';

import { deleteCookie, getPartnerCookie, setCookie, setPartnerCookie } from 'utils/cookies/index';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';

export const getPartnerAuthCookies = (
  globalPartner: GlobalPartner,
): {
  accessToken: string | undefined;
  expiresIn: string | undefined;
  expiredTime: string | undefined;
} => {
  const partnerId = globalPartner?.partnerId;

  const accessToken = getPartnerCookie('access_token', partnerId);
  const expiresIn = getPartnerCookie('expires_at', partnerId);
  const expiredTime = getPartnerCookie('expired_time', partnerId);

  return {
    accessToken,
    expiresIn,
    expiredTime,
  };
};

export const setPrivateInstanceCookies = (
  expiresIn: number,
  globalPartner?: GlobalPartner,
): void => {
  const appSettings = getAppSettings();
  const settings = globalPartner || appSettings;

  // TODO: We might want to move this into a function that's called on route change instead of
  // page visit to try to ensure direct access to the LMS has the correct cookies set?
  if (globalPartner) {
    const {
      accessToken,
      expiredTime,
      expiresIn: currentTokenExpiresIn,
    } = getPartnerAuthCookies(globalPartner);

    setCookie('access_token', accessToken, currentTokenExpiresIn);
    setCookie('expires_at', currentTokenExpiresIn, currentTokenExpiresIn);
    setCookie('expired_time', expiredTime, currentTokenExpiresIn);
  }

  if (settings) {
    setCookie('partnerId', String(settings?.partnerId), expiresIn);
    // Setting this to max chrome cookie age as this is being used for Moodle authentication
    // purposes, and does not impact auth whatsoever
    setCookie('partnerUrl', String(baseUrl), CHROME_COOKIE_MAX_AGE);

    if (settings.parentPartnerId) {
      setCookie('parentPartnerId', String(settings.parentPartnerId), expiresIn);
    } else {
      deleteCookie('parentPartnerId');
    }
  }

  // We might want this to be more descriptive in the future? e.g. "Noodle", "Open" and "Private"?
  setCookie(
    'is_private_label',
    isNonNoodleInstance.toString(),
    new Date().getTime() + expiresIn * 1000,
  );
};

export const setSession = (authResult: AuthResult | any, partnerId: string): void => {
  setPrivateInstanceCookies(authResult.expiresIn);

  // Set the time that the Access Token will expire at
  const expiredTime = new Date().getTime() + authResult.expiresIn * 1000;

  // Set latest access token
  setCookie('access_token', authResult.accessToken, authResult.expiresIn);
  setCookie('expires_at', String(authResult.expiresIn), authResult.expiresIn);
  setCookie('expired_time', String(expiredTime), authResult.expiresIn);

  // Save access token for the partner
  setPartnerCookie(partnerId, 'access_token', authResult.accessToken, authResult.expiresIn);
  setPartnerCookie(partnerId, 'expires_at', String(authResult.expiresIn), authResult.expiresIn);
  setPartnerCookie(partnerId, 'expired_time', String(expiredTime), authResult.expiresIn);
};
