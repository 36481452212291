// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes, spacing },
} = getTheme();
const generalContainer = `
  display: flex;
  flex-direction: column;
  gap: ${spacing.x3};
`;
export const Section: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4Medium};
`;
export const ParagraphTwo: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font5};
`;
export const GeneralContainer: StatelessFunctionalComponent<any> = styled.span`
  ${generalContainer}
`;
export const HintContainer: StatelessFunctionalComponent<any> = styled.div`
  margin-top: ${spacing.x3};
`;
export const TermsConditionsContainer: StatelessFunctionalComponent<any> = styled.div`
  margin-top: ${spacing.x3};
  a {
    ${fontTypes.font4Medium}
  }
`;
