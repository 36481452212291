export const paymentConstants = {
  getStripeClientSecretErrorMessage: "There was an error getting your purchase's client secret",
  alreadyPaidMessage: "You've already paid for this course",
};

export enum PartnerStripeErrors {
  NOT_CONFIGURED = 'Stripe account not configured for this partner',
}

export enum StripeAccountVerificationStatus {
  PENDING = 'VERIFICATION_PENDING',
  VERIFIED = 'VERIFIED',
  FAILED = 'VERIFICATION_FAILED',
  ONBOARDING_PENDING = 'ONBOARDING_TO_BE_COMPLETED',
}

export enum InvoiceReviewAction {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export enum StripeInvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
}

export enum SeatRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const STRIPE_DASHBOARD_URL = `https://dashboard.stripe.com/`;
