// @ts-nocheck
import get from 'lodash.get';
import JsonApi from 'utils/jsonApi';
import type { Bookmarkable, BookmarkObject, FetchBookmarkedItemPayload } from 'commons/types';
import {
  ALL_BOOKMARKS,
  cardTypesConstants,
  feedConstants,
  NOODLING_BOOKMARK,
} from 'commons/constants';
import type { Course as CourseType } from 'commons/types/courseTypes';

const normalizeNoodlingItem = (item: Bookmarkable): Bookmarkable => {
  switch (item.noodlingType) {
    case 'SCHOOL':
      return { ...item, cardType: 'SCHOOL' };

    case 'INSTITUTION':
      return { ...item, cardType: 'INSTITUTION' };

    default:
      return item;
  }
};

export default class Bookmark {
  jsonApi: JsonApi;

  teamPath = '/teams';

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async bookmark(item: Bookmarkable): Promise<BookmarkObject> {
    let type;
    let endpoint = '';
    let entity = '';
    const { id } = item;
    const normalizedItem = normalizeNoodlingItem(item);

    switch (normalizedItem.cardType) {
      case cardTypesConstants.EXPERT:
        type = 'expert_bookmark';
        endpoint = 'experts';
        entity = 'expertBookmark';
        break;

      case cardTypesConstants.INSTITUTION:
        type = 'school_bookmark';
        endpoint = 'schools';
        entity = 'schoolBookmark';
        break;

      case cardTypesConstants.SCHOOL:
        type = 'grad_school_bookmark';
        endpoint = 'grad_schools';
        entity = 'gradSchoolBookmark';
        break;

      case cardTypesConstants.GROUP:
        type = 'group_bookmark';
        endpoint = 'groups';
        entity = 'groupBookmark';
        break;

      case NOODLING_BOOKMARK:
      case cardTypesConstants.NOODLING:
        type = 'noodling_bookmark';
        endpoint = 'noodlings';
        entity = 'noodlingBookmark';
        break;

      default:
        break;
    }

    const response = await this.jsonApi.post(
      `/${endpoint}/${id}/bookmarks`,
      {
        type,
      },
      false,
    );
    let bookmark = get(response, `entities.${entity}`, {});
    bookmark = bookmark[Object.keys(bookmark)[0]];
    return { ...bookmark, bookmarkedObjectId: item.id };
  }

  async unbookmark(item: Bookmarkable): Promise<BookmarkObject> {
    let endpoint = '';
    const { id } = item;
    const normalizedItem = normalizeNoodlingItem(item);
    const { cardType, bookmarkId } = normalizedItem;

    switch (cardType) {
      case cardTypesConstants.EXPERT:
        endpoint = 'experts';
        break;

      case cardTypesConstants.SCHOOL:
        endpoint = 'grad_schools';
        break;

      case cardTypesConstants.INSTITUTION:
        endpoint = 'schools';
        break;

      case cardTypesConstants.GROUP:
        endpoint = 'groups';
        break;

      case NOODLING_BOOKMARK:
      case cardTypesConstants.NOODLING:
        endpoint = 'noodlings';
        break;

      default:
        break;
    }

    await this.jsonApi.delete(`/${endpoint}/${id}/bookmarks/${bookmarkId || 0}`, {});
    return {
      id: null,
      bookmarkedObjectId: item.id,
      bookmarkedObjectType: `BookmarkableObjectsEnum.${cardType}`,
      createdAt: '',
      updatedAt: '',
    };
  }

  async fetchBookmarkedItems({ userId, page, filter }: FetchBookmarkedItemPayload): Promise<any> {
    let url = `/users/${userId}/bookmarks?page[number]=${page}&page[size]=${feedConstants.ELEMENTS_PER_PAGE}`;

    if (filter !== ALL_BOOKMARKS) {
      url += `&filter=[{"name": "bookmarked_object_type", "op": "eq", "val": "${filter}"}]`;
    }

    const response = await this.jsonApi.get(url);
    const bookmarks = get(response, 'entities.bookmarks', {
      count: 0,
    });
    const count = get(response, 'entities.count', 0);
    const bookmarkedItems = bookmarks[Object.keys(bookmarks)[0]].results;
    return {
      bookmarkedItems,
      count,
    };
  }

  async getSavedItems(): Promise<CourseType> {
    const response = await this.jsonApi.get(
      `/teams/saved-content?filter=[{"name":"content_type","op":"eq","val":"article"}]`,
      false,
    );
    const coursesArr = get(response, 'data', {});
    return coursesArr.map((course) => ({ ...course?.attributes, id: course?.id }));
  }
}
