const THERE_WAS_AN_ERROR = 'There was an error';
const YOU_HAVE_SUCCESSFULLY = 'You have successfully';
export const TEAM = {
  teamsErrorMessage: `${THERE_WAS_AN_ERROR} fetching all teams`,
  myTeamsErrorMessage: `${THERE_WAS_AN_ERROR} fetching my teams`,
  teamErrorMessage: `${THERE_WAS_AN_ERROR} fetching current team`,
  joinTeamErrorMessage: `${THERE_WAS_AN_ERROR} joining you to this team`,
  joinTeamSuccessMessage: `${YOU_HAVE_SUCCESSFULLY} joined`,
  joinTeamRequestErrorMessage: `${THERE_WAS_AN_ERROR} sending the join request`,
  joinTeamRequestSuccessMessage: `You have requested to join this team`,
  cancelTeamRequestErrorMessage: `${THERE_WAS_AN_ERROR} canceling the join request`,
  cancelTeamRequestSuccessMessage: `You have canceled this team request`,
  createTeamRequestErrorMessage: `${THERE_WAS_AN_ERROR} creating the team.`,
  inviteMemberSuccessMessage: 'Invitation Sent',
  inviteMembersSuccessMessage: 'Invitations Sent',
  inviteMembersFailureMessage:
    'Invite was not sent successfully, this user is already a team member',
  getAllTeamsSavedResourcesErrorMessage: `${THERE_WAS_AN_ERROR} getting saved resources from all teams`,
  getTeamSavedResourcesErrorMessage: `${THERE_WAS_AN_ERROR} getting saved resources from team`,
  approveTeamMembership: `Invite Accepted`,
  approveTeamMembershipErrorMessage: `${THERE_WAS_AN_ERROR} approving team membership`,
  denyTeamMembership: `Invite Denied`,
  denyTeamMembershipErrorMessage: `${THERE_WAS_AN_ERROR} denying team membership`,
};
export const browseTeamsConstants = {
  NEWEST_TEAMS: 'Newest Teams',
  POPULAR_TEAMS: 'Popular Teams',
  NOODLE_AI: 'Noodle.AI',
  SPONSORED: 'Sponsored',
  CONTENT_PREVIEW_TITLE: 'Noodle Coaches are free!',
  CONTENT_PREVIEW_DESCRIPTION:
    'If you’re feeling overwhelmed, a Noodle certified coach can help you find answers and point you in the right direction, whether you’re searching for a school, a program or a course.',
  CONTENT_PREVIEW_TEXT:
    'Not sure where to start with your Data Science Search? Speak with a Noodle Coach!',
  POST_PROMO_BUTTON_TEXT: 'Speak with a coach',
};
export const teamAccessTypes = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};
export const teamCardCTAConstants = {
  JOIN_TEAM: 'Join Team',
  MEMBER: 'Member',
  OWNER: 'Owner',
  CANCEL_REQUEST: 'Cancel Request',
  JOIN_THE_TEAM: 'Join The Team',
  REQUEST_SEND: 'Request Sent',
};
export const teamPreviewConstants = {
  TEAM_MEMBERS: 'Members',
};
