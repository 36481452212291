/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';
import moment from 'moment';
import humps from 'humps';
import JsonApi from 'utils/jsonApi';
import { camelToUnderdashCase } from 'utils/string';
import type { PartnerInvite, SendPartnerInvitesResponse } from 'commons/types/partnerInvitesTypes';
import { getPartnerId } from 'utils/privateLabel/index';
import get from 'lodash.get';
// eslint-disable-next-line import/no-cycle
import {
  PartnerInstanceCourse,
  Learner,
  Membership,
  PaginatedSection,
  PartnerInstanceMembership,
  PartnerInstanceMembershipPatch,
  PartnerInstance as PartnerInstanceType,
} from 'commons/types/partnerInstanceTypes';
import { PaginatedResponse } from 'commons/types/paginationTypes';
import { LMS } from 'commons/types/courseTypes';
import { getSessionStorageData } from 'utils/sessionStorage';
import { UTM_VALUES } from 'utils/heap';
import { axios } from '../index';
import store from '../../../ducks/store';

interface CreateSectionPayload {
  name: string;
  description: string;
  course: {
    slug: string;
    lms: LMS;
    moodleCourseId?: string;
    canvasCourseId?: string;
    brightspaceOrgUnitId?: string;
  };
  partnerId: string;
  startingDate: string;
}

export default class PartnerInstance {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getPartnerInvitations(payload?: {
    partnerId: string;
    token?: string;
  }): Promise<PartnerInvite[]> {
    try {
      const queryParams = new URLSearchParams({
        'page[size]': '0',
        sort: '-created_at',
      });
      const filters = [];

      if (payload?.token) {
        filters.push({
          name: 'token',
          op: 'eq',
          val: payload.token,
        });
      }

      if (payload?.partnerId) {
        filters.push({
          name: 'partner_id',
          op: 'eq',
          val: payload?.partnerId,
        });
      }

      queryParams.append('filter', `${JSON.stringify(filters)}`);
      // $FlowFixMe flow being dumb below
      const response = await this.jsonApi.get(`/partner_invites?${queryParams.toString()}`, false);
      return response?.data.map((invitation) => invitation?.attributes);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async createPartnerInstanceMembership(payload: PartnerInstanceMembership) {
    const body = { ...payload };

    delete body.partnerId;

    return axios.post(`/partner_instance_memberships?partner_id=${payload.partnerId}`, {
      data: body,
    });
  }

  async patchPartnerInstanceMembership({
    payload,
  }: {
    payload: PartnerInstanceMembershipPatch & { id: number; partnerId: string };
  }) {
    const patchId = payload.id;
    const patchPartnerId = payload.partnerId;
    const patchPayload = payload;

    delete patchPayload.id;
    delete patchPayload.partnerId;

    return axios.patch(
      `/partner_instance_memberships/${patchId}`,
      {
        data: patchPayload,
      },
      {
        params: {
          partner_id: patchPartnerId,
        },
      },
    );
  }

  async getPartnerInstances(token?: string): Promise<{ data: PartnerInstance[] }> {
    try {
      const queryParams = new URLSearchParams({
        sort: '-created_at',
      });

      if (token) {
        const filterObject = {
          name: 'token',
          op: 'eq',
          val: token,
        };
        queryParams.append('filter', `[${JSON.stringify(filterObject)}]`);
      }

      const response: Record<string, any> = await this.jsonApi.get(
        `/partners?${queryParams.toString()}`,
        false,
      );
      return response?.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPaginatedPartnerInstances({
    actualPage,
    pageSize = 10,
  }: {
    actualPage: number;
    pageSize?: number;
  }): Promise<PaginatedResponse<PartnerInstanceType>> {
    try {
      const queryParams = new URLSearchParams({
        'page[number]': actualPage.toString(),
        'page[size]': pageSize.toString(),
        sort: '-created_at',
      });

      const response = await this.jsonApi.get(`/partners?${queryParams.toString()}`, false);
      return response;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getByPartnerId(partnerId?: string): Promise<Record<string, any>[]> {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        `/partners/${String(partnerId)}`,
        false,
      );
      return response?.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getSectionById({ sectionId }: { sectionId: string }) {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        `/sections/${sectionId}?include=admin`,
        false,
      );

      // using the included object coming from the section id to get the members data
      return {
        ...response?.data.attributes,
        id: response?.data.id,
      };
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPaginatedSectionLearnersById({
    sectionId,
    actualPage,
    pageSize = 10,
    search = '',
    includeAttributes = '',
  }: {
    sectionId: string;
    pageSize?: number;
    actualPage: number;
    search?: string;
    includeAttributes?: string;
  }): Promise<PaginatedResponse<Learner>> {
    try {
      const queryParams = new URLSearchParams({
        'page[number]': actualPage.toString(),
        'page[size]': pageSize.toString(),
        include: 'admin,accepted_members',
        search,
        include_attributes: includeAttributes,
      });

      const response: Record<string, any> = await this.jsonApi.get(
        `/sections/${sectionId}?${queryParams.toString()}`,
        false,
      );

      return {
        data: response.included.map(({ attributes, id, type }) => ({ ...attributes, id, type })),
        meta: response.meta,
      };
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async sendPartnerInvites({
    emails,
    partnerId,
  }: {
    emails: string[];
    partnerId?: string;
  }): Promise<SendPartnerInvitesResponse> {
    try {
      if (!emails.length) {
        throw new Error('No emails were provided');
      }

      const queryParams = partnerId
        ? new URLSearchParams({
            partner_id: partnerId,
          })
        : null;
      // $FlowFixMe flow being dumb below
      const response = await this.jsonApi.post(
        `/partner_invites${queryParams ? `?${queryParams.toString()}` : ''}`,
        {
          emails,
        },
        false,
      );
      return humps.camelizeKeys(response);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw err;
    }
  }

  async uploadCSV({ file, partnerId }: Record<string, any>): Promise<Record<string, any>> {
    try {
      const formData = new FormData();
      formData.append('memberships', file);
      // Using axios since the JSONAPI is doing all kinds of weird
      const response: Record<string, any> = await axios.post(
        '/partner_instance_memberships',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            partner_id: partnerId,
          },
        },
      );
      return humps.camelizeKeys(response?.data?.data);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data?.Error || err.response?.data?.errors[0]?.detail);
      } else {
        throw new Error(err);
      }
    }
  }

  async createPartnerInstance(payload: Record<string, any>): Promise<Record<string, any>> {
    const {
      partnerInstance: {
        globalPartner: { data: globalPartner },
      },
    } = store.getState();
    const partnerId = payload.partnerId || getPartnerId() || globalPartner.partnerId;
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('mode', payload.mode);
    formData.append('parent_partner_id', partnerId);

    const optionalPayloadKeys = [
      'seats',
      'seatPrice',
      'primaryColor',
      'secondaryColor',
      'heroTitle',
      'heroParagraph',
      'firstBlockTitle',
      'firstBlockParagraph',
      'termsOfUseLink',
      'privacyPolicyLink',
      'cookiePolicyLink',
      'contactSupportLinkAdmin',
      'contactSupportLinkLearners',
      'instanceLogo',
      'instanceIcon',
      'instanceFavicon',
      'noodleRevenuePercentage',
      'noodleRevenueMax',
      'vanityUrl',
      'isMicrositeEnabled',
      'micrositeLandingPageUrl',
      'micrositeCoursesUrl',
      'isShoppingCartEnabled',
      'isSocialToolEnabled',
      'isCertificateGenerationEnabled',
    ];
    Object.values(optionalPayloadKeys).forEach((val) => {
      if (payload[val] !== null && payload[val] !== undefined && payload[val] !== '') {
        formData.append(camelToUnderdashCase(val), payload[val]);
      }
    });

    // Using axios since the JSONAPI is doing all kinds of weird
    const response: Record<string, any> = await axios.post('/partners', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async updatePartnerInstance(payload: Record<string, any>): Promise<Record<string, any>> {
    const formData = new FormData();
    formData.append('name', payload.name);

    const optionalPayloadKeys = [
      'seatPrice',
      'primaryColor',
      'secondaryColor',
      'heroTitle',
      'heroParagraph',
      'firstBlockTitle',
      'firstBlockParagraph',
      'termsOfUseLink',
      'privacyPolicyLink',
      'cookiePolicyLink',
      'contactSupportLinkAdmin',
      'contactSupportLinkLearners',
      'instanceLogo',
      'instanceIcon',
      'instanceFavicon',
      'noodleRevenuePercentage',
      'noodleRevenueMax',
      'vanityUrl',
      'isMicrositeEnabled',
      'micrositeLandingPageUrl',
      'micrositeCoursesUrl',
      'isShoppingCartEnabled',
      'isSocialToolEnabled',
      'isCertificateGenerationEnabled',
    ];
    Object.values(optionalPayloadKeys).forEach((val) => {
      if (payload[val] !== null && payload[val] !== undefined && payload[val] !== '') {
        formData.append(camelToUnderdashCase(val), payload[val]);
      }
    });

    // Using axios since the JSONAPI is doing all kinds of weird
    const response: Record<string, any> = await axios.patch(
      `/partners/${payload.partnerId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response;
  }

  async getPartnerMemberships({
    partnerId,
    memberId,
  }: {
    partnerId?: string;
    memberId?: string;
  }): Promise<Record<string, any>[]> {
    try {
      const queryParams = new URLSearchParams({
        'page[size]': '0',
        sort: '-created_at',
      });
      const filters = [];

      if (partnerId) {
        filters.push({
          name: 'partner_id',
          op: 'eq',
          val: partnerId,
        });
      }

      if (memberId) {
        filters.push({
          name: 'user_id',
          op: 'eq',
          val: memberId,
        });
      }

      if (filters.length) {
        queryParams.append('filter', JSON.stringify(filters));
      }

      const response: Record<string, any> = await this.jsonApi.get(
        `/partner_instance_memberships?${queryParams.toString()}`,
        false,
      );
      return response?.data.map((membership) => ({
        id: membership.id,
        ...membership?.attributes,
      }));
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPaginatedPartnerMemberships({
    partnerId,
    memberId,
    actualPage,
    pageSize = 10,
    search,
    courseSlug,
    includeRoles,
    includeInactive,
  }: {
    partnerId?: string;
    memberId?: string;
    pageSize?: number;
    actualPage: number;
    search?: string;
    courseSlug?: string;
    includeRoles?: boolean;
    includeInactive?: boolean;
  }): Promise<PaginatedResponse<Membership>> {
    try {
      const queryParams = new URLSearchParams({
        'page[size]': pageSize.toString(),
        'page[number]': actualPage.toString(),
        sort: '-created_at',
      });
      const filters = [];

      if (partnerId) {
        filters.push({
          name: 'partner_id',
          op: 'eq',
          val: partnerId,
        });
      }

      if (memberId) {
        filters.push({
          name: 'user_id',
          op: 'eq',
          val: memberId,
        });
      }

      if (filters.length) {
        queryParams.append('filter', JSON.stringify(filters));
      }

      if (search) {
        queryParams.append('search', search);
      }

      if (courseSlug) {
        queryParams.append('exclude_members_from_course_slug', courseSlug);
      }

      if (includeRoles) {
        queryParams.append('include_member_user_roles', 'include_member_user_roles');
      }

      queryParams.append('include_inactive', includeInactive ? '1' : '0');

      const response: Record<string, any> = await this.jsonApi.get(
        `/partner_instance_memberships?${queryParams.toString()}`,
        false,
      );
      const responseObject: PaginatedResponse<Membership> = {
        meta: response?.meta ?? { meta: { count: 0 } },
        data:
          response?.data.map((membership) => ({
            id: membership.id,
            ...membership?.attributes,
          })) ?? [],
      };
      return responseObject;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPartnerCourses({
    partnerId,
    actualPage,
    pageSize,
  }: {
    partnerId: string;
    actualPage?: number;
    pageSize?: number;
  }): Promise<Record<string, any>[]> {
    try {
      const queryParams = new URLSearchParams({
        partner_id: partnerId,
      });
      if (pageSize) {
        queryParams.append('page[size]', pageSize.toString());
      }
      if (actualPage) {
        queryParams.append('page[number]', actualPage.toString());
      }
      const response: Record<string, any> = await this.jsonApi.get(
        `/v2/courses${partnerId ? `?${queryParams.toString()}` : ''}`,
        false,
      );
      return response?.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPaginatedPartnerCourses({
    partnerId,
    pageSize = 10,
    actualPage,
    isAdminView = false,
    isAdmin = false,
    search,
  }: {
    partnerId: string;
    pageSize?: number;
    actualPage: number;
    isAdminView: boolean;
    isAdmin: boolean;
    search: string;
  }): Promise<PaginatedResponse<PartnerInstanceCourse>> {
    try {
      const queryParams = new URLSearchParams({
        partner_id: partnerId,
        'page[size]': pageSize.toString(),
        'page[number]': actualPage.toString(),
        is_admin_view: isAdminView.toString(),
        is_admin: isAdmin.toString(),
      });

      if (search) {
        queryParams.append('search', search);
      }

      const response: PaginatedResponse<PartnerInstanceCourse> = await this.jsonApi.get(
        `/v2/courses${partnerId ? `?${queryParams.toString()}` : ''}`,
        false,
      );

      return response;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async getPartnerCourse({
    slug,
    partnerId,
  }: {
    slug: string;
    partnerId: string;
  }): Promise<Record<string, any>[]> {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        `/v2/courses/${slug}?include=sections&partner_id=${partnerId}`,
        false,
      );
      const sections = response?.data?.sections?.map((section) => ({
        ...section?.attributes,
        id: section?.id,
      }));
      return { ...response?.data, sections };
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data.status);
      } else {
        throw new Error(err);
      }
    }
  }

  async updatePartnerCourse({
    partnerId,
    slug,
    priceInCents,
    contentfulCourseId,
  }: {
    partnerId: string;
    slug: string;
    priceInCents: string;
    contentfulCourseId: string;
  }): Promise<Record<string, any>[]> {
    const response: Record<string, any> = await axios.patch(
      `/v2/courses/${slug}?include=sections&partner_id=${partnerId}`,
      { data: { price_in_cents: priceInCents, contentful_id: contentfulCourseId } },
    );
    const sections = response?.data?.sections?.map((section) => ({
      ...section?.attributes,
      id: section?.id,
    }));
    return { ...humps.camelizeKeys(response.data), sections };
  }

  async getPaginatedCourseSections({
    slug,
    partnerId,
    actualPage,
    pageSize = 10,
    getSoloLearners = false,
    searchByAdmin = '',
  }: {
    slug: string;
    partnerId: string;
    actualPage: number;
    pageSize?: number;
    getSoloLearners?: boolean;
    searchByAdmin?: string;
  }): Promise<PaginatedResponse<PaginatedSection>> {
    try {
      const queryParams = new URLSearchParams({
        include: getSoloLearners ? 'solo-sections' : 'sections',
        partner_id: partnerId,
        search_by_admin: searchByAdmin,
        'page[size]': pageSize.toString(),
        'page[number]': actualPage.toString(),
      });

      const response: Record<string, any> = await this.jsonApi.get(
        `/v2/courses/${slug}?${queryParams.toString()}`,
        false,
      );

      return {
        data: response.data?.sections?.map((section) => ({
          ...section.attributes,
          id: section.id,
        })),
        meta: response.meta,
      };
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data.status);
      } else {
        throw new Error(err);
      }
    }
  }

  async validateInvitationToken(token: string): Promise<Record<string, any>[]> {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        `/partner_invites/token/${token}`,
        false,
      );
      return humps.camelizeKeys(response?.data?.attributes);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw new Error(err);
    }
  }

  async createSection(payload: CreateSectionPayload): Promise<any> {
    const apiPayload: any = {
      attributes: {
        name: payload.name,
        description: payload.description,
        image: '',
        public: true,
        visible: true,
        brightspaceCourseSlug: payload.course.slug,
        partnerId: payload.partnerId,
        startDate: payload.startingDate,
        endDate: moment(payload.startingDate).add(1, 'y').format('YYYY-MM-DD'),
      },
      type: 'section',
    };

    if (payload.course.lms === LMS.Moodle) {
      apiPayload.attributes.moodleCourseId = payload.course?.moodleCourseId;
    } else if (payload.course.lms === LMS.Canvas) {
      apiPayload.attributes.canvasCourseId = payload.course?.canvasCourseId;
    } else {
      apiPayload.attributes.brightspaceOrgUnitId = Number(payload.course.brightspaceOrgUnitId);
    }

    const response = await this.jsonApi.post(
      `/sections?lms=${payload.course.lms}`,
      apiPayload,
      false,
    );

    return Object.values(get(response, 'entities.section', {}))[0];
  }

  async editSection(payload: Record<string, any>): Promise<any> {
    try {
      const apiPayload = {
        attributes: {
          name: payload.name,
          description: payload.description,
        },
        type: 'section',
        id: String(payload.id),
      };
      const response = await this.jsonApi.patch(`/sections/${payload.id}`, apiPayload);
      return Object.values(get(response, 'entities.section', {}))[0];
    } catch (err) {
      console.error('🚀 ~ file: index.js ~ line 83 ~ PartnerInstance ~ editSection ~ err', err);
      Sentry.captureException(err);
      throw err;
    }
  }

  // This is the report endpoints so we may want to actually create a new duck for reports instead of aggregating everything here.
  async getLoginReport(partnerId: string): Promise<Record<string, any>[]> {
    try {
      // Hardcoded. We might want to make this dynamic in the future?
      const momentFormat = 'YYYY-MM-DD';
      const today = moment().format(momentFormat);
      const fiveDaysAgo = moment().subtract(5, 'd').format(momentFormat);
      const response: Record<string, any> = await this.jsonApi.get(
        `/reports/logins_stats/${partnerId}?start_date=${fiveDaysAgo}&end_date=${today}`,
        false,
      );
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data.detail);
      } else {
        throw new Error(err);
      }
    }
  }

  async getViewedContentReport(partnerId: string): Promise<Record<string, any>[]> {
    try {
      // Hardcoded. We might want to make this dynamic in the future?
      const momentFormat = 'YYYY-MM-DD';
      const today = moment().format(momentFormat);
      const fiveDaysAgo = moment().subtract(5, 'd').format(momentFormat);
      const response: Record<string, any> = await this.jsonApi.get(
        `/reports/viewed_content/${partnerId}?start_date=${fiveDaysAgo}&end_date=${today}`,
        false,
      );
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data.detail);
      } else {
        throw new Error(err);
      }
    }
  }

  async getLearnerStatusReport(payload: Record<string, any>): Promise<Record<string, any>[]> {
    try {
      const { courseSlug, partnerId } = payload;
      const response: Record<string, any> = await this.jsonApi.get(
        `/reports/learners_status/${courseSlug}?partner_id=${partnerId}`,
        false,
      );
      return response?.data;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.data) {
        throw new Error(err.response.data.detail);
      } else {
        throw new Error(err);
      }
    }
  }

  async getSurveyReport(payload: Record<string, any>): Promise<Record<string, any>[]> {
    try {
      const { partnerId, contentfulProgramId } = payload;
      const queryParams = new URLSearchParams();

      if (contentfulProgramId) {
        queryParams.set('contentful_program_id', contentfulProgramId);
      } else {
        queryParams.set('survey', `skills-check-${partnerId}`);
      }

      const response: Record<string, any> = await this.jsonApi.get(
        `/reports/surveys/skills_check/${partnerId}?${queryParams.toString()}`,
        false,
      );
      return humps.camelizeKeys(response?.questions);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.status) {
        throw new Error(err.response.status);
      } else {
        throw new Error(err);
      }
    }
  }

  async getCertificateReport(payload: string): Promise<Record<string, any>[]> {
    try {
      const response: Record<string, any> = await axios.get(`/reports/certificates-obtained`, {
        params: {
          partner_id: payload,
        },
      });
      return humps.camelizeKeys(response.data.data);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.status) {
        throw new Error(err.response.status);
      } else {
        throw new Error(err);
      }
    }
  }

  async getCompletionReport(payload: Record<string, any>): Promise<Record<string, any>[]> {
    try {
      const { partnerId, courseSlug } = payload;
      const response: Record<string, any> = await axios.get(`/reports/completion-rate`, {
        params: {
          partner_id: partnerId,
          course_slug: courseSlug,
        },
      });
      return humps.camelizeKeys(response.data);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);

      if (err?.response?.status) {
        throw new Error(err.response.status);
      } else {
        throw new Error(err);
      }
    }
  }

  async downloadInstanceReport(partnerId: string): Promise<Blob> {
    const response: Record<string, any> = await axios.post(
      `/reports/instance-level/download`,
      {},
      {
        params: {
          partner_id: partnerId,
        },
        responseType: 'blob',
      },
    );
    return response.data;
  }

  async downloadProgramReport(payload: { partnerId: string; courseSlug: string }): Promise<Blob> {
    const { partnerId, courseSlug } = payload;
    const response: Record<string, any> = await axios.post(
      `/reports/program-level/download`,
      {},
      {
        params: {
          partner_id: partnerId,
          course_slug: courseSlug,
        },
        responseType: 'blob',
      },
    );
    return response.data;
  }

  async addMembersToSection(payload): Promise<any> {
    const { teamId, partnerId, userIds, generateInvoice, skipPayment } = payload;

    const response: Record<string, any> = await axios.post(
      `/teams/${teamId}/members?partner_id=${partnerId}&generate_invoice=${
        generateInvoice ? '1' : '0'
      }&skip_payment=${skipPayment}`,
      { data: { user_ids: userIds } },
    );

    return response.data;
  }

  async addSoloMembersToCourse(payload): Promise<any> {
    const { slug, partnerId, soloLearnersPayload, generateInvoice, skipPayment } = payload;

    const response: Record<string, any> = await axios.post(
      `/teams/${slug}/learners`,
      soloLearnersPayload,
      {
        params: {
          partner_id: partnerId,
          generate_invoice: generateInvoice,
          skip_payment: skipPayment,
        },
      },
    );

    return response.data;
  }

  async getGlobalPartner(payload: { domain: string }): Promise<any> {
    const response: Record<string, any> = await axios.get(
      `/partners/domain/${encodeURIComponent(payload.domain)}`,
    );

    return response.data;
  }

  async setupStripeAccount(payload: string): Promise<any> {
    const response: Record<string, any> = await axios.post(`/partners/${payload}/stripe/setup`);

    return humps.camelizeKeys(response.data);
  }

  async getStripeAccount(payload: string): Promise<any> {
    const response: Record<string, any> = await axios.get(`/partners/${payload}/stripe/account`);

    return humps.camelizeKeys(response.data);
  }

  async createStripeInvoice(payload: Record<string, any>): Promise<any> {
    const { partnerId, paidSeats, freeSeats, childPartnerId, purchaseType } = payload;

    const response: Record<string, any> = await axios.post(
      `/partners/${partnerId}/stripe/invoices`,
      humps.decamelizeKeys({ paidSeats, freeSeats, childPartnerId, purchaseType }),
    );

    return humps.camelizeKeys(response.data);
  }

  async createStripeCourseInvoice(payload: Record<string, any>): Promise<any> {
    const {
      partnerId,
      courseSlug,
      isAddedFromFedNet,
      publisherPartnerId,
      isAdminEnrollment,
      quantity,
    } = payload;
    const queryParams = new URLSearchParams();
    const utmParams = getSessionStorageData(UTM_VALUES);

    if (isAddedFromFedNet) {
      queryParams.set('is_federated_network_course', isAddedFromFedNet);
      queryParams.set('publisher_partner_id', publisherPartnerId);
    }

    const response: Record<string, any> = await axios.post(
      `/partners/${partnerId}/stripe/invoices/courses?${queryParams ? queryParams.toString() : ''}`,
      humps.decamelizeKeys({ courseSlug, quantity, isAdminEnrollment, utmParameters: utmParams }),
    );

    return humps.camelizeKeys(response.data);
  }

  async requestSeats(payload: Record<string, any>): Promise<any> {
    const { partnerId, paidSeats, purchaseType } = payload;

    const response: Record<string, any> = await axios.post(
      `/partners/${partnerId}/stripe/invoices?draft=1`,
      humps.decamelizeKeys({ paidSeats, purchaseType }),
    );
    return humps.camelizeKeys(response.data);
  }

  async getPartnerStripeInvoices(partnerId: string): Promise<any> {
    const response: Record<string, any> = await axios.get(`/partners/${partnerId}/stripe/invoices`);
    return humps.camelizeKeys(response.data);
  }

  async getPartnerStripeInvoiceById(payload: Record<string, any>): Promise<any> {
    const { partnerId, invoiceId, isFederatedNetwork, publisherPartnerId } = payload;
    const queryParams = new URLSearchParams();

    if (isFederatedNetwork) {
      queryParams.set('is_federated_network_course', isFederatedNetwork);
      queryParams.set('publisher_partner_id', publisherPartnerId);
    }
    const response: Record<string, any> = await axios.get(
      `/partners/${partnerId}/stripe/invoices/${invoiceId}?${
        queryParams ? queryParams.toString() : ''
      }`,
    );
    return humps.camelizeKeys(response.data);
  }

  async getPaginatedStripeInvoices({
    partnerId,
    filterDrafts,
    invoiceId,
    invoiceAmount,
    status,
    customer,
    fromDate,
    toDate,
    pageSize = 10,
    childPartnerId,
    customerPartnerId,
    currentPage,
    isFedNetSelected,
  }: {
    partnerId: string;
    pageSize?: number;
    filterDrafts?: string;
    invoiceId?: string;
    invoiceAmount?: string;
    status?: string;
    customer?: string;
    fromDate?: any;
    toDate?: any;
    childPartnerId?: string;
    customerPartnerId?: string;
    currentPage?: number;
    isFedNetSelected?: boolean;
  }): Promise<PaginatedResponse<any>> {
    const queryParams = new URLSearchParams({
      'page[size]': pageSize.toString(),
    });

    if (filterDrafts && filterDrafts !== '') {
      queryParams.set('filter_drafts', filterDrafts);
    }

    if (invoiceId && invoiceId !== '') {
      queryParams.set('number', invoiceId);
    }
    if (invoiceAmount && invoiceAmount !== '') {
      queryParams.set('total', invoiceAmount);
    }
    if (customer && customer !== '') {
      queryParams.set('name', customer);
    }
    if (status && status !== 'all') {
      queryParams.set('status', status);
    }
    if (fromDate instanceof Date) {
      fromDate.setUTCHours(0);
      fromDate.setUTCMinutes(0);
      fromDate.setUTCSeconds(0);
      const date = fromDate.getTime() / 1000;
      queryParams.set('from_date', date.toString());
    }
    if (toDate instanceof Date) {
      toDate.setUTCHours(23);
      toDate.setUTCMinutes(59);
      toDate.setUTCSeconds(59);
      const date = toDate.getTime() / 1000;
      queryParams.set('to_date', date.toString());
    }
    if (childPartnerId && childPartnerId !== '') {
      queryParams.set('child_partner_id', childPartnerId);
    }
    if (customerPartnerId && customerPartnerId !== '') {
      queryParams.set('customer_partner_id', customerPartnerId);
    }
    if (isFedNetSelected) {
      queryParams.set('filter_federated_network_only', isFedNetSelected.toString());
    }
    if (currentPage) {
      queryParams.set('current_page', currentPage.toString());
    }

    const response = await axios.get(
      `/partners/${partnerId}/stripe/invoices?${queryParams.toString()}`,
    );
    return humps.camelizeKeys(response.data);
  }

  async reviewSeatRequest(payload: Record<string, any>): Promise<any> {
    const { reviewAction, partnerId, invoiceId } = payload;

    const response: Record<string, any> = await axios.post(
      `/partners/${partnerId}/stripe/invoices/${invoiceId}/review`,
      humps.decamelizeKeys({ reviewAction }),
    );
    return humps.camelizeKeys(response.data);
  }

  async getStripePartnerRevenue(partnerId: string): Promise<any> {
    const response: Record<string, any> = await axios.get(`/partners/${partnerId}/stripe/revenue`);
    return humps.camelizeKeys(response.data);
  }

  async getPartnerSeatRequests({
    partnerId,
    childPartnerId,
  }: {
    partnerId: string;
    childPartnerId: string;
  }): Promise<any> {
    const response: Record<string, any> = await axios.get(
      `/partners/${partnerId}/stripe/seat_requests/${childPartnerId}`,
    );
    return humps.camelizeKeys(response.data);
  }
}
