// @ts-nocheck
import type { StatelessFunctionalComponent } from 'react';
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import { alertTypesConstants } from 'commons/constants';

const {
  noodera: { fontTypes, spacing },
  palette,
  ellipsis,
} = getTheme();

const backgroundColor = (type: string): string => {
  switch (type) {
    case alertTypesConstants.DANGER:
      return palette.red.flushMahogany;

    case alertTypesConstants.SUCCESS:
      return palette.greyscale.trout;

    case alertTypesConstants.WARNING:
      return palette.greyscale.trout;

    default:
      return palette.greyscale.white;
  }
};

const Alert: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4Medium};
  align-items: center;
  background: ${(props) => backgroundColor(props.type)};
  border-radius: 0.8rem;
  color: ${palette.greyscale.white};
  display: flex;
  font-size: 1.6rem;
  padding: ${spacing.x6} ${spacing.x5};

  i {
    color: ${palette.greyscale.white};
    font-size: 2rem;
    margin-right: 1.3rem;
  }

  & .MuiSvgIcon-root {
    cursor: pointer;

    &:first-child {
      margin-right: ${spacing.x6};
      font-size: 16px;
      cursor: initial;
    }
  }
`;
export const NAlert: StatelessFunctionalComponent<any> = styled(Alert)`
  && {
    margin: ${spacing.x6} 0;

    & > div {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
export const NAlertFixed: StatelessFunctionalComponent<any> = styled(Alert)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 30rem;
  max-width: 60rem;
  z-index: 1400;

  .alert {
    &__text {
      flex: 1;
      letter-spacing: 0.025rem;
      line-height: 2.1rem;
      text-align: center;
      ${ellipsis(6)}
    }

    &__icon {
      font-size: 3.3rem;
      margin-right: 0;
    }
  }
`;
export const NAlertClose: StatelessFunctionalComponent<any> = styled.button`
  background: none;
  border: none;
  color: ${palette.greyscale.white};
`;
