/* eslint-disable no-useless-escape */
export const DATE_REGEX = /^$|^(0[1-9]|1[0-2])[/](0[1-9]|[1-2][0-9]|3[0-1])[/][0-9]{4}$/;
export const EMAIL_REGEX =
  /^$|^(?=[A-Z0-9][A-Z0-9@._%+-]{5,253}$)[A-Z0-9._%+-]{1,64}@(?:(?=[A-Z0-9-]{1,63}\.)[A-Z0-9]+(?:-[A-Z0-9]+)*\.){1,8}[A-Z]{2,63}$/i;
export const EMAIL_RFC2822_REGEX = /^.+@.+\..+/;
export const NAME_REGEX = /^$|^[\p{L}\p{M} ,.'-]{1,50}$/iu;
export const TRIM_PHONE_REGEX = /[+-.() ]/g;
export const YEAR_REGEX = /^$|^(19[5-9]\d|20[0-4]\d)$/;
export const FIND_URL_REGEX =
  /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.+\b))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/g;
export const ONLY_NUMBERS_MASK = /[^\d]/g;
export const ONLY_NUMBERS_WITH_DECIMALS_MASK = /[^.0-9]|([0-9]+[.][0-9]+[.])|[.][0-9]+[.]|[.]{2,}/g;
export const PHONE_MASK = /([^\d])|(^[0-1])/g;
export const SLUG_MASK = /([^0-9A-Z-])/gi;
export const EMPTY_MASK = /^$/;
export const NO_LEADING_ZERO_MASK = /^0+/g;
export const NO_SPACES = /\s/g;
export const ALPHANUMERIC_REGEX = /^\w+( \w+)*$/;
export const HEX_COLOR_FORMAT = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
