import * as React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { calculateFutureDate } from 'utils/dateHelpers/index';
import type { NooderaDatePickerProps } from './NooderaDatePicker.types';
import { StyledDatePicker } from './NooderaDatePicker.styles';

const NooderaDatePicker = ({
  value,
  variant = 'inline',
  onChange,
  onClose,
  ...rest
}: NooderaDatePickerProps) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <div aria-live='polite'>
      <StyledDatePicker
        maxDate={calculateFutureDate(new Date(), 6)}
        autoOk
        InputLabelProps={{
          shrink: true,
        }}
        inputVariant='outlined'
        id='date-picker-dialog'
        variant={variant}
        format='MM/DD/yyyy'
        value={value}
        onChange={onChange}
        onClose={onClose}
        {...(rest as any)}
      />
    </div>
  </MuiPickersUtilsProvider>
);

export default NooderaDatePicker;
