import styled from 'styled-components';
import { getTheme } from 'settings/theme';

import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

import loginErrorX from 'assets/images/login-errorx.png';
import loginSuccessx from 'assets/images/login-successx.png';

const {
  fontTypes,
  fonts,
  noodera: { spacing, fontTypes: fontTypesNoodera },
  palette: { greyscale, primary, neutral, accent },
  gradients,
} = getTheme();

export const AuthWrapper = styled.div`
  /**
     File contains overrides for default styles of auth0 lib
     Had to use !important in order to override default styles
     Otherwise, selectors could be with 3-5 nesting levels
     */

  justify-content: center !important;

  .auth0-lock-form-container {
    padding: 0 ${spacing.x5};
  }

  .auth0-lock-header {
    display: none !important;
  }

  .auth0-lock-center {
    padding: 0 !important;
  }

  .auth0-lock-widget {
    width: 36rem !important;
  }

  /* Help text for microsite to NLP jump */
  &&& {
    .auth-help-text {
      ${({ theme }) => theme.noodera.fontTypes.font5};
      color: ${neutral.eighty};
      margin-bottom: ${({ theme }) => theme.noodera.spacing.x4};
    }
  }

  @media screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    justify-content: flex-start !important;

    .auth0-lock,
    .auth0-lock-widget {
      width: 100% !important;
    }

    .auth0-lock-content-wrapper {
      flex-grow: unset !important;
    }
  }

  .auth0-lock-form {
    padding: 0 !important;
  }

  .auth0-lock-cred-pane-internal-wrapper {
    padding-bottom: ${spacing.x5} !important;
  }

  /**
     Tabs login / signup
     */

  .auth0-lock-tabs-container {
    margin: 0 0 ${spacing.x3} 0 !important;
    padding: ${spacing.x5} 0 0 0 !important;
  }

  &&& {
    .auth0-lock-tabs-container-with-help-text {
      margin: 0 0 ${spacing.x7} 0 !important;
      padding: ${spacing.x5} 0 0 0 !important;
    }
  }

  .auth0-lock-tabs {
    padding: ${spacing.x8} ${spacing.x5} ${spacing.x6} ${spacing.x5} !important;
    margin: auto !important;
    background-color: unset !important;
    box-sizing: border-box !important;
    display: flex;
    box-shadow: none !important;
  }

  .auth0-lock-tabs li {
    border: 1px solid ${primary.hundred};
    box-sizing: border-box;
    flex: 1;
  }

  .auth0-lock-tabs li:first-child {
    border-radius: ${spacing.x6} 0 0 ${spacing.x6};
  }

  .auth0-lock-tabs li:last-child {
    border-radius: 0 ${spacing.x6} ${spacing.x6} 0;
  }

  .auth0-lock-tabs a,
  .auth0-lock-tabs span {
    line-height: ${spacing.x4};
    padding: 0 !important;
    font-weight: 700 !important;
  }

  .auth0-lock-tabs a {
    color: ${primary.hundred} !important;
  }

  .auth0-lock-tabs li.auth0-lock-tabs-current {
    background: ${gradients.noodera};
    color: ${greyscale.white} !important;
  }

  .auth0-lock-tabs li.auth0-lock-tabs-current span {
    color: ${greyscale.white} !important;
  }

  .auth0-lock-tabs-current {
    box-shadow: unset !important;
  }

  /**
     Body
     */

  .auth-lock-social-buttons-pane {
    padding: 0 ${spacing.x7};
  }

  .auth0-lock-form p span {
    color: ${neutral.eighty};
  }

  .auth0-lock-pane-separator + div {
    padding: 0 ${spacing.x5};
  }

  &&& {
    .auth0-lock-password-strength {
      background: ${greyscale.trout};
    }
  }

  .auth0-lock-password-strength li.auth0-lock-checked > span {
    background-image: url(${loginSuccessx});
  }

  .auth0-lock-password-strength li span {
    background-image: url(${loginErrorX});
  }

  .auth0-lock-password-strength li span,
  .auth0-lock-password-strength li.auth0-lock-checked > span {
    background-size: 15px 15px;
    color: ${greyscale.white};
    ${fontTypesNoodera.font4};
  }

  .auth0-lock-input-wrap {
    background-color: unset !important;
    border: 1px solid ${neutral.forty} !important;
    border-radius: ${spacing.x7} !important;

    &:focus-within {
      box-sizing: border-box;
      box-shadow: 0 0 2px 2px ${({ theme }) => theme.palette.primary.sixty};
    }
  }

  &&&& {
    .auth0-global-message-error {
      text-transform: none;
      ${fontTypesNoodera.font4Medium};
      background: ${accent.one};
    }
  }

  .auth0-lock-error-invalid-hint {
    ${fontTypesNoodera.font5};

    text-align: right;
    color: ${accent.one};
    white-space: normal;
  }

  .auth0-lock-alternative-link,
  .auth0-lock-terms a,
  .auth0-lock-terms a {
    transition: background-size 0.1s ease-in;
    background-image: linear-gradient(
      120deg,
      ${({ theme }) => theme.palette.primary.hundred} 0%,
      ${({ theme }) => theme.palette.primary.hundred} 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 109%;

    &:hover {
      background-size: 100% 109%;
      background-image: linear-gradient(
        120deg,
        ${({ theme }) => theme.palette.primary.twenty} 0%,
        ${({ theme }) => theme.palette.primary.twenty} 100%
      );
      color: #005f81;
    }
  }

  .auth0-lock-input {
    border-radius: ${spacing.x7} !important;
    padding: 0 ${spacing.x5} !important;
  }

  .auth0-lock-input-wrap-with-icon {
    padding-left: ${spacing.x3} !important;
  }

  .auth0-lock-input-wrap-with-icon .auth0-lock-input {
    padding: 0 ${spacing.x5} 0 ${spacing.x7} !important;
  }

  .auth0-lock-input::placeholder {
    color: ${neutral.seventy};
  }

  /**
     Terms
     */

  .auth0-lock-terms {
    ${fontTypes.font5};
    margin: 1rem !important;
    padding: 0 ${spacing.x5} !important;
    color: ${neutral.eighty} !important;
    background-color: unset !important;
    text-align: left !important;
  }

  .auth0-lock-terms label {
    font-size: 1.2rem !important;
    display: flex;
    align-items: center !important;
    font-weight: 600 !important;
  }

  .auth0-lock-login-terms {
    ${fontTypes.font4};
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    padding: 0 ${spacing.x5} !important;
    color: ${neutral.eighty} !important;
    background-color: unset !important;
    text-align: center !important;
    margin-top: ${spacing.x4};
  }

  .auth0-lock-terms div {
    text-align: center;
    margin: ${spacing.x6} 0;
  }

  .auth0-lock-alternative a,
  .auth0-lock-terms a {
    color: ${({ theme }) => theme.palette.primary.hundred} !important;
  }

  .auth0-lock-alternative {
    margin: ${spacing.x3} 0 ${spacing.x6} 0 !important;
  }

  /*
     Submit
     */

  .auth0-lock-submit {
    &:hover {
      background: ${({ theme }) => theme.palette.secondary.hundred} !important;
    }

    &:focus {
      background: ${({ theme }) => theme.palette.secondary.hundred} !important;
      box-shadow: ${({ theme }) => theme.boxShadow.focus} !important;
    }

    &:active {
      background: ${({ theme }) => theme.palette.primary.hundred} !important;
      box-shadow: unset !important;
    }

    transition: none !important;
    background: ${({ theme }) => theme.gradients.noodera} !important;
    border-radius: ${({ theme }) => theme.noodera.spacing.x6} !important;
    margin: 0 ${({ theme }) => theme.noodera.spacing.x5} !important;
    width: auto !important;
    padding: 0 !important;
    line-height: ${({ theme }) => theme.noodera.spacing.x2} !important;
  }

  .auth0-label-submit {
    color: white !important;
    text-transform: none !important;
    ${({ theme }) => theme.fontTypes.font4Semi};
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    height: 2.4rem !important;
  }

  .auth0-label-submit svg {
    display: none !important;
  }

  @media screen and (min-width: ${CELLPHONE_LARGE_WIDTH}px) {
    .auth0-lock-cred-pane {
      border-radius: 0 0 6px 6px !important;
    }
  }

  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${greyscale.athensGray};

  @media screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    background-color: ${greyscale.white};
    &&&& {
      .auth0-lock-cred-pane-internal-wrapper {
        height: 100%;
      }
    }
  }

  .auth {
    &__all-fields-required {
      color: rgba(0, 0, 0, 0.34);
      font-size: 1.4rem;
      letter-spacing: -0.016rem;
    }

    &__content {
      align-items: center;
      display: flex;
      flex: 1;
      flex-basis: 50%;
      flex-direction: column;
      position: relative;

      &__container {
        max-width: 436px;
      }

      form {
        width: 100%;
      }

      h1 {
        color: ${greyscale.black};
        font-size: 3.2rem;
        font-weight: 600;
        letter-spacing: -0.024rem;
        line-height: 4rem;
        margin-bottom: 1.6rem;
        text-align: center;
      }

      label {
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: -0.018rem;
        line-height: 2.4rem;
        margin-bottom: 0.4rem;
      }

      &-social-btn-wrp {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: ${spacing.x3};

        button {
          flex: 1;
          margin: 0.5rem;
        }
      }

      &-social {
        color: ${greyscale.snowWhite};
        font-size: 1.6rem;
        margin-top: 1.6rem;
        text-align: center;

        span {
          color: ${greyscale.smoke};
          font-size: 1.4rem;
          font-weight: 400;
          letter-spacing: -0.018rem;
          line-height: 2.4rem;
          text-align: center;
          margin-left: 0.5rem;
        }
      }

      &-signup {
        bottom: 5rem;
        color: ${greyscale.smoke};
        font-size: 1.4rem;
        letter-spacing: -0.012rem;
        line-height: 1.7rem;
        text-align: center;
        margin-bottom: ${spacing.x5};

        span {
          font-weight: 600;
          margin-left: 0.5rem;
        }
      }

      .password-wrp {
        display: flex;
        justify-content: space-between;

        &__legend {
          color: ${greyscale.smoke};
          font-weight: 400;
          font-size: 1.6rem;
          letter-spacing: -0.018rem;
          line-height: 2.4rem;
        }
      }
    }

    &__footer {
      bottom: 2rem;
      color: ${greyscale.smoke};
      display: flex;
      font-size: 1.4rem;
      justify-content: space-around;
      position: absolute;
      width: 100%;

      .black {
        color: ${greyscale.smoke};
        font-weight: 600;
      }

      .light {
        color: rgba(0, 0, 0, 0.19);
      }
    }

    &__reset-password {
      .auth0-lock-form > div {
        margin-bottom: ${spacing.x5};
        padding: ${spacing.x5};

        & > p {
          margin-bottom: ${spacing.x6};
        }
      }

      &__no-padding {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 70rem) {
      &__bg {
        display: none;
      }
    }
  }

  .signup-agreement {
    color: ${greyscale.smoke};
    font-family: ${fonts.primary};
    font-size: 1.4rem;
    letter-spacing: -0.016rem;
    line-height: 1.7rem;
    text-align: center;

    a {
      color: ${primary.hundred};
      font-weight: 600;
      margin-left: 0.5rem;
      text-decoration: none;
    }
  }

  .password-reset-text {
    color: ${greyscale.smoke};
    font-size: 1.6rem;
    letter-spacing: -0.018rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;

export const StyledLogo = styled.div<{ isNonNoodleInstance: boolean }>`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  background: ${greyscale.white};
  width: 36rem;

  @media screen and (min-width: ${CELLPHONE_LARGE_WIDTH}px) {
    border-radius: 6px 6px 0 0;
  }

  @media screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 100% !important;
    margin-top: 0;
  }

  & svg {
    height: 32px;
  }

  img {
    width: ${({ isNonNoodleInstance }) => (isNonNoodleInstance ? 180 : 82)}px;
    height: auto;
  }
`;

export const StyledHeader = styled.div`
  height: 76px;
  margin: auto;
  background: ${primary.hundred};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContainerAuth = styled.div`
  width: 100%;
  min-height: 810px;
  max-width: 1024px;
  padding: ${spacing.x3};
  margin: 0 auto;
  background: transparent;
  justify-content: center;
  background-size: cover;
  display: flex;
  align-self: flex-start;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    justify-content: center;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    display: block;
    padding: 0;
    background-image: none;
  }
`;
