import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

// Components
import Loader from 'components/uielements/Loader';
import HomeSkeleton from 'components/Skeletons/HomeSkeleton';

// Utils
import { isNonNoodleInstance, getIsCorporateInstance } from 'utils/privateLabel/index';
import { useIsUniversityAdmin } from 'utils/customHooks/index';
import { getIsPrivateLabel } from 'index';

// Screens
import MyRecommendationsLoading from 'screens/MyRecommendationsLoading/index';
import UpdateUser from 'screens/Profile/UpdateUser';
import CourseWaitlist from './CourseWaitlist';

// Lazy loaded screens
const Home = React.lazy(() => import('./Home'));
const ShoppingCartRouter = React.lazy(() => import('./ShoppingCartRouter'));
const Teams = React.lazy(() => import('./Teams'));
const Programs = React.lazy(() => import('./Programs'));
const Meeting = React.lazy(() => import('./Meeting'));
const Notifications = React.lazy(() => import('./Notifications'));
const University = React.lazy(() => import('./University'));

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const DashboardRouter: React.FC = () => {
  const { pathname } = useLocation();
  const isUniversityAdmin = useIsUniversityAdmin();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { isLoading, data: globalPartner } = useSelector(
    (state) => state.partnerInstance.globalPartner,
  );
  const isCorporateInstance = getIsCorporateInstance(globalPartner?.partnerId || '');

  return (
    <React.Suspense fallback={<Loader />}>
      {/* We should refactor this to allow all the skeletons, for now let's keep it as is
         as nesting the suspense components seems to be breaking everything
      */}
      <Switch>
        <SentryRoute
          exact
          path='/404'
          component={Loadable({
            loader: () => import('../NotFound'),
            loading: Loader,
          })}
        />
        {/*   // Redirecting the university admin to the admin dashboard. Preventing them of seeing the learner experience */}
        {!isLoading &&
        isNonNoodleInstance &&
        isUniversityAdmin &&
        !isCorporateInstance &&
        !pathname.includes('/instance') ? (
          <Redirect to='/instance' />
        ) : null}
        <SentryRoute
          exact
          path='/'
          render={() => {
            if (
              isNonNoodleInstance &&
              !isLoading &&
              !isLoggedIn &&
              globalPartner?.isMicrositeEnabled &&
              !pathname.includes('/courses') &&
              !pathname.includes('/cart') &&
              !pathname.includes('/waitlist')
            ) {
              window.location.replace(globalPartner?.micrositeLandingPageUrl);
              return null;
            }
            return (
              <React.Suspense fallback={<HomeSkeleton />}>
                <Home />
              </React.Suspense>
            );
          }}
        />
        <SentryRoute
          exact
          path='/dashboard'
          render={() => (
            <React.Suspense fallback={<HomeSkeleton />}>
              <Home />
            </React.Suspense>
          )}
        />
        <SentryRoute
          path='/cart'
          render={() => (
            <React.Suspense fallback={<HomeSkeleton />}>
              <ShoppingCartRouter />
            </React.Suspense>
          )}
        />
        <SentryRoute
          exact
          path='/waitlist/:slug'
          render={() => (
            <React.Suspense fallback={<HomeSkeleton />}>
              <CourseWaitlist />
            </React.Suspense>
          )}
        />
        <SentryRoute
          exact
          path='/tab/team'
          render={() => (
            <React.Suspense fallback={<HomeSkeleton />}>
              <Home />
            </React.Suspense>
          )}
        />

        <SentryRoute
          exact
          path='/bookmarks'
          component={Loadable({
            loader: () => import('./Bookmarks'),
            loading: Loader,
          })}
        />

        <SentryRoute
          path='/courses'
          render={() => {
            const excludedMicrositeRedirectKeywords = ['/instance', '/enroll'];
            const isExcludedUrl = excludedMicrositeRedirectKeywords.some((keyword) =>
              pathname.includes(keyword),
            );

            if (globalPartner?.micrositeCoursesUrl && !isExcludedUrl) {
              window.location.replace(globalPartner?.micrositeCoursesUrl);
            }

            return <Programs />;
          }}
        />

        <SentryRoute
          path='/instance'
          component={Loadable({
            loader: () => import('./InstanceDashboard'),
            loading: Loader,
          })}
        />

        <SentryRoute exact path='/groups' render={() => <Redirect to='/groups/browse' />} />
        <SentryRoute
          exact
          path='/groups/:tab'
          component={Loadable({
            loader: () => import('./Groups'),
            loading: Loader,
          })}
        />
        <SentryRoute
          exact
          path='/groups/details/:id'
          component={Loadable({
            loader: () => import('./Group'),
            loading: Loader,
          })}
        />

        <SentryRoute path='/meeting/:bookingId' render={() => <Meeting />} />

        <SentryRoute
          exact
          path='/noodling/:id'
          component={Loadable({
            loader: () => import('./Noodling'),
            loading: Loader,
          })}
        />

        <SentryRoute
          path='/notifications'
          render={() => (isLoggedIn ? <Notifications /> : <Redirect to='/signup' />)}
        />

        <SentryRoute
          path='/update-personal-information'
          render={() =>
            isLoggedIn ? (
              <UpdateUser />
            ) : (
              <Redirect to='/login?returnUrl=/update-personal-information' />
            )
          }
        />

        <SentryRoute
          exact
          path='/onboarding/loading'
          component={isNonNoodleInstance ? () => <Redirect to='/404' /> : MyRecommendationsLoading}
        />

        {/* Removed route, redirect to home */}
        <SentryRoute exact path='/schools' render={() => <Redirect to='/' />} />
        {/* Removed route, redirect to home */}
        <SentryRoute exact path='/schools/:tab' render={() => <Redirect to='/' />} />

        <SentryRoute
          path='/teams'
          component={getIsPrivateLabel() ? () => <Redirect to='/404' /> : Teams}
        />

        <SentryRoute path='/universities/:slug' component={University} />
        <SentryRoute
          path='/'
          component={Loadable({
            loader: () => import('../NotFound'),
            loading: Loader,
          })}
        />
      </Switch>
    </React.Suspense>
  );
};

export default DashboardRouter;
