import * as React from 'react';
import {
  HelperTextSpan,
  HelperTextContainer,
  RightAlignedHelperTextSpan,
} from './HelperText.styles';
import type { HelperTextProps } from './HelperText.types';

const HelperText = ({ text, type = 'right-aligned', className }: HelperTextProps) =>
  type === 'right-aligned' ? (
    <HelperTextContainer className={className}>
      <RightAlignedHelperTextSpan>{text}</RightAlignedHelperTextSpan>
    </HelperTextContainer>
  ) : (
    <HelperTextSpan className={className}>{text}</HelperTextSpan>
  );

export default HelperText;
