// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import NoodleButton from 'components/uielements/Button/index';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

const {
  palette: { noodle, primary },
  noodera: { spacing },
} = getTheme();
export const NoodleButtonSchedule: StatelessFunctionalComponent<any> = styled(NoodleButton)`
  &&&& {
    ${(props) =>
      props.isSelected && `background: ${primary.twenty}; border-color: ${primary.hundred}`};
  }
`;
const ScheduleContainer: StatelessFunctionalComponent<any> = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;

  .new-scheduler {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 0 2.7rem;
      text-align: left;
      width: 100%;
    }

    &__bottom-container {
      max-width: 45rem;
      width: 100%;
    }

    &__more-times-btn {
      padding: 0 !important;
      margin-top: 2.4rem;
    }

    &__hour-btn {
      min-width: 100% !important;
    }

    &__timezone-container {
      display: flex;
      flex-direction: column;
      margin-top: 2.4rem;

      & > button {
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }

    &__timezone-select {
      margin-top: 1.6rem;
      margin-bottom: 2.4rem;

      &__btn-container {
        display: flex;

        &__cancelBtn {
          color: ${noodle.primary} !important;
        }
      }
    }

    &__date-select {
      width: 100%;

      & button {
        margin-right: 1rem;
        font-size: 1.6rem !important;
      }
    }

    &__time-select {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      margin-bottom: ${spacing.x4};

      & > button {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    .new-scheduler {
      &__container {
        padding: 0 ${spacing.x5};
      }

      &__bottom-container {
        max-width: 100%;
      }

      &__date-select {
        display: block;
        overflow-y: hidden;
        white-space: nowrap;
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__booking-form {
        &__container {
          max-width: 100%;
        }

        &__scheduler-btn {
          width: 100% !important;
          height: 4.2rem !important;
          margin: 3.2rem 0;
        }
      }
    }
  }
`;
export default ScheduleContainer;
