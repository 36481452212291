import type { HasMinLengthParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a value is greater or equal to a given length
 */
const hasMinLength = ({
  name,
  value,
  outputName = 'This field',
  minLength,
  inputType = 'characters',
}: HasMinLengthParams): ValidationFieldError => {
  const errors = {};
  errors[name] =
    value && value.length < minLength
      ? {
          errorMsg: `${outputName} must be ${minLength} ${inputType} minimum`,
        }
      : {};
  return errors;
};

export default hasMinLength;
