import { likePostTypes } from './types';

const initState = {};

const likeReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case likePostTypes.FAILED:
    case likePostTypes.DELETE_FAILED: {
      return { ...state, error: payload };
    }

    default: {
      return state;
    }
  }
};

export default likeReducer;
