import * as React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { NavBarSignedIn, NavBarGuest } from 'screens/NavBar';
import Loader from 'components/uielements/Loader';
import authActions from 'ducks/auth/actions';
import { getPartnerCookie } from 'utils/cookies';
import { AuthContext } from 'utils/customHooks/auth';
import type { GuestRouteProps } from './Route.types';

class GuestRoute extends React.Component<GuestRouteProps> {
  componentDidMount() {
    const { isLoggedIn, pathname, onFetchUserDetails, isGlobalPartnerLoading, globalPartner } =
      this.props;

    if (
      !isLoggedIn &&
      getPartnerCookie(`access_token`, globalPartner?.partnerId) &&
      !isGlobalPartnerLoading
    ) {
      const { logout } = this.context;
      onFetchUserDetails(pathname, logout);
    }
  }

  getNavbar() {
    const { isLoggedIn } = this.props;
    return isLoggedIn ? <NavBarSignedIn /> : <NavBarGuest />;
  }

  render() {
    const { isLoading, children, isGlobalPartnerLoading } = this.props;
    return isLoading || isGlobalPartnerLoading ? <Loader /> : <>{children}</>;
  }
}

const mapStateToProps = (state) => {
  const pathname = get(state, 'router.location.pathname');
  const search = get(state, 'router.location.search');

  return {
    user: get(state, 'user'),
    isLoggedIn: get(state, 'auth.isLoggedIn'),
    isLoading: get(state, 'auth.isLoading'),
    isGlobalPartnerLoading: get(state, 'partnerInstance.globalPartner.isLoading'),
    globalPartner: get(state, 'partnerInstance.globalPartner.data'),
    pathname: pathname + search,
  };
};

const mapDispatchToProps = {
  onFetchUserDetails: (pathname, logout) =>
    authActions.loginRequest({
      guestRoute: true,
      pathname,
      logout,
    }),
};

GuestRoute.contextType = AuthContext;
export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute);
