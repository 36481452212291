import JsonApi from 'utils/jsonApi';
import get from 'lodash.get';

export default class Product {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getProduct() {
    const response = await this.jsonApi.get('/products', false);
    const data = get(response, 'data', {});
    return data.map((p) => p?.attributes);
  }
}
