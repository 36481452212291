// @ts-nocheck
import * as React from 'react';
import LeftNav from 'components/LeftNav/index';
import { getNavLinksPerRole } from 'utils/navigation';
import NavLink from 'components/NavLink/index';
import { COMPUTER_SMALL_WIDTH } from 'commons/constants';
import { useWidth, useIsInstanceAdmin, useIsUniversityAdmin } from 'utils/customHooks';
import NavbarSeparator from 'components/NavbarSeparator/index';
import { useSelector } from 'react-redux';

import { getPartnerId, getAppSettings } from 'utils/privateLabel/index';
import { baseUrl } from 'settings/index';
import { LogoLink, LinksWrapper } from './DashboardNav.styles';

const COACH_ITEM_NAME = 'Coach';

const DashboardNav = (): React.ReactElement<any> => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const selected = useSelector((state) => state.router.location.pathname);
  const pathname = useSelector((state) => state.router.location.pathname);
  const width = useWidth();
  const isInstanceAdmin = useIsInstanceAdmin();
  const isUniversityAdmin = useIsUniversityAdmin();
  const showLongLogo = !isLoggedIn && pathname === '/';
  // This way it plays nicer on localhost :)
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const partnerMicrosite = globalPartner?.micrositeLandingPageUrl;
  const partnerMicrositeEnabled = globalPartner?.isMicrositeEnabled;
  const partnerId = getPartnerId() || globalPartner?.partnerId;
  const regexpPartnerId = /\/instance\/([a-zA-Z0-9-]+)\/?/;
  const match = typeof pathname === 'string' && pathname.match(regexpPartnerId);
  const partnerMatch = match && match[1];

  const filterNavItemByWidth = (navItem: any, navItemName: string) =>
    navItem.name !== navItemName || (navItem.name === navItemName && width <= COMPUTER_SMALL_WIDTH);

  const renderItem = (item: any): React.ReactElement<any> =>
    item.name === 'separator' ? (
      <NavbarSeparator key='nav-separator' />
    ) : (
      <NavLink key={item.name} selected={selected} item={item} />
    );

  const renderLinks = (): Array<React.ReactElement<any> | null | undefined> => {
    let navLinks = getNavLinksPerRole(
      isInstanceAdmin && pathname.includes('/instance') ? 'admin' : undefined,
      isUniversityAdmin,
    );

    if (
      !isLoggedIn &&
      partnerMicrosite &&
      partnerMicrositeEnabled &&
      pathname.includes('/courses')
    ) {
      navLinks = navLinks.filter((navLink) => navLink.name !== 'Home');
    }

    if (isInstanceAdmin && pathname.includes('/instance')) {
      const pathPartnerId = partnerMatch || partnerId;
      navLinks = navLinks.map((navLink) => ({
        ...navLink,
        path: navLink.path.replace(':partnerId', pathPartnerId),
      }));
    }
    return navLinks
      .filter((link: any) => filterNavItemByWidth(link, COACH_ITEM_NAME))
      .map(renderItem);
  };

  const renderLogo = (): React.ReactElement<any> => {
    const { companyName, companySmallLogo } = getAppSettings() || globalPartner;
    let customHref = baseUrl;
    let customTarget = '_self';

    if (partnerMicrosite && partnerMicrositeEnabled) {
      customHref = partnerMicrosite;
      customTarget = '_blank';
    }
    const imgAltText = `${companyName} Logo`;

    if (React.isValidElement(companySmallLogo)) {
      return companySmallLogo;
    }

    return (
      <LogoLink href={customHref} target={customTarget}>
        <img
          src={companySmallLogo}
          alt={imgAltText}
          aria-label={imgAltText}
          title={imgAltText}
          loading='lazy'
          width='100%'
          height='100%'
        />
      </LogoLink>
    );
  };

  return (
    <LeftNav>
      {!showLongLogo && renderLogo()}
      <LinksWrapper showLongLogo={showLongLogo}>{renderLinks()}</LinksWrapper>
    </LeftNav>
  );
};

export default DashboardNav;
