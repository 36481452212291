import {
  addPartnerProgramTypeTypes,
  adminPartnerProgramTypes,
  getPartnerProgramListTypes,
  getPartnerProgramTypeListTypes,
  getPartnerProgramTypes,
} from './types';

const initialState = {
  isLoading: false,
  errorMessage: '',
  partnerProgram: {
    isLoading: false,
    data: {},
  },
  suggestions: [],
  partnerProgramTypes: {
    isLoading: false,
    data: [],
  },
};

const convertToSuggestions = (arr) =>
  arr.map((item) => ({
    id: item.id,
    name: item.title,
    label: item.title,
    value: item.id,
  }));

const AdminPartnerProgramReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getPartnerProgramListTypes.REQUEST: {
      return {
        ...state,
        partnerPrograms: {
          isLoading: true,
        },
      };
    }

    case getPartnerProgramListTypes.SUCCESS: {
      return {
        ...state,
        partnerPrograms: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getPartnerProgramListTypes.FAILED: {
      return {
        ...state,
        partnerPrograms: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case getPartnerProgramTypeListTypes.REQUEST: {
      return {
        ...state,
        partnerProgramTypes: {
          isLoading: true,
        },
      };
    }

    case getPartnerProgramTypeListTypes.SUCCESS: {
      return {
        ...state,
        partnerProgramTypes: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getPartnerProgramTypeListTypes.FAILED: {
      return {
        ...state,
        partnerProgramTypes: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case getPartnerProgramTypes.REQUEST: {
      return {
        ...state,
        partnerProgram: {
          isLoading: true,
        },
      };
    }

    case getPartnerProgramTypes.SUCCESS: {
      return {
        ...state,
        partnerProgram: {
          data: payload,
          isLoading: false,
        },
      };
    }

    case getPartnerProgramTypes.FAILED: {
      return {
        ...state,
        partnerProgram: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case addPartnerProgramTypeTypes.SUCCESS: {
      return { ...state, isLoading: false };
    }

    case adminPartnerProgramTypes.FILTER_SUGGESTIONS: {
      return {
        ...state,
        suggestions: convertToSuggestions(
          state.partnerProgramTypes.data.filter((item) =>
            item.title.toLowerCase().includes(payload.toLowerCase()),
          ),
        ),
      };
    }

    case adminPartnerProgramTypes.CLEAR_FILTER: {
      return { ...state, suggestions: convertToSuggestions(state.partnerProgramTypes.data) };
    }

    default:
      return state;
  }
};

export default AdminPartnerProgramReducer;
