import * as React from 'react';
import cs from 'classnames';
import TextWrapper from './Text.styles';
import type { TextProps, HeaderProps } from './Text.types';

const Header = ({ children, className, size }: HeaderProps): React.ReactElement<any> => {
  switch (size) {
    case 1:
      return <h1 className={cs(className, 'header1')}>{children}</h1>;

    case 2:
      return <h2 className={cs(className, 'header2')}>{children}</h2>;

    case 3:
      return <h3 className={cs(className, 'header3')}>{children}</h3>;

    default:
      return <h4 className={cs(className, 'header4')}>{children}</h4>;
  }
};

const Text = ({
  className,
  children,
  isHeader = false,
  isParagraph = false,
  size = 1,
}: TextProps): React.ReactElement<any> => {
  if (isHeader) {
    return (
      <Header className={className} size={size}>
        {children}
      </Header>
    );
  }

  if (isParagraph) {
    return <p className={cs(className, `body${size}`)}>{children}</p>;
  }

  return <span className={cs(className, `body${size}`)}>{children}</span>;
};

export default TextWrapper(Text);
