// @ts-nocheck
import * as React from 'react';
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import {
  backgroundColor,
  socialButton,
  buttonSize,
  backgroundAndBorderColor,
  defaultButtonStyle,
} from 'components/uielements/styles/mixins';

const {
  palette: { blue, green, greyscale, noodle, red, social, primary, neutral },
  fontColors,
} = getTheme();

const ButtonWrapper = (ComponentName: React.ReactElement<any>) => styled(ComponentName)`
  &&& {
    /* Remove outline added by Reactstrap */
    box-shadow: none;
    ${defaultButtonStyle()}
    ${(props) => buttonSize(props.size)}

    &.btn-primary {
      ${backgroundAndBorderColor(noodle.primary, noodle.primary)};
      color: ${greyscale.white};

      &:focus,
      &:hover {
        ${backgroundAndBorderColor(primary.hundredTwenty, primary.hundredTwenty)};
      }

      &:active {
        ${backgroundAndBorderColor(primary.eighty, primary.eighty)};
      }
    }

    &.btn-success {
      ${backgroundAndBorderColor(green.default, green.default)};
      color: ${greyscale.white};

      &:focus,
      &:hover {
        ${backgroundAndBorderColor(green.hover, green.hover)};
      }
    }

    &.btn-secondary {
      ${backgroundAndBorderColor(greyscale.white, primary.hundred)};
      color: ${primary.hundred};

      &:focus,
      &:hover {
        ${backgroundAndBorderColor(primary.ten, primary.hundred)};
      }

      &:active {
        ${backgroundAndBorderColor(primary.twenty, primary.hundred)};
      }
    }

    &.btn-success-secondary {
      ${backgroundAndBorderColor(greyscale.white, green.default)};
      color: ${green.default};

      &:focus,
      &:hover {
        ${backgroundAndBorderColor(greyscale.snow, green.hover)};
        color: ${green.hover};
      }
    }

    &.btn-pending,
    &.btn-pending:focus,
    &.btn-pending:hover {
      ${backgroundAndBorderColor('transparent', 'transparent')};
      color: ${noodle.primary};
      cursor: initial;
    }

    &.btn-danger {
      ${backgroundAndBorderColor(red.default, red.default)};
      color: ${greyscale.white};

      &:focus,
      &:hover {
        ${backgroundAndBorderColor(red.hover, red.hover)};
      }
    }

    &.disabled,
    &.disabled:hover {
      color: ${neutral.eighty} !important;
      background-color: ${neutral.twenty} !important;
      border-color: ${neutral.twenty} !important;
      opacity: 1;
    }

    &.disabled-secondary,
    &.disabled-secondary:hover {
      color: ${neutral.eighty} !important;
      background-color: ${greyscale.white} !important;
      border-color: ${neutral.twenty} !important;
      opacity: 1;
    }

    &.disabled-transparent,
    &.disabled-transparent:hover {
      color: ${neutral.eighty} !important;
      opacity: 1;
    }

    &.btn-transparent {
      ${backgroundColor('transparent', 'transparent')};
      color: ${primary.hundred};
      border: none;
      outline: none;

      &:hover,
      &:focus {
        color: ${primary.hundredTwenty};
      }

      &:active {
        color: ${primary.eighty};
      }
    }

    &.btn-transparent-secondary {
      ${backgroundColor('transparent', 'transparent')};
      color: ${blue.riverBed};
      text-transform: uppercase;
      letter-spacing: 0.13rem;
      line-height: 1.2rem;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: ${noodle.primary};
      }
    }

    &.btn-google-plus {
      ${socialButton()};
      ${backgroundAndBorderColor(social.gplus, social.gplus05)};

      i {
        margin-right: 0.5rem;
      }
    }

    &.btn-facebook {
      ${socialButton()};
      ${backgroundAndBorderColor(social.fb, social.fb05)};

      i {
        margin-right: 0.5rem;
      }
    }

    &.btn-linkedin {
      ${socialButton()};
      ${backgroundAndBorderColor(social.linkedin, social.linkedin05)};

      i {
        margin-right: 0.5rem;
      }
    }

    &.btn-round-noodle {
      ${backgroundAndBorderColor(primary.hundred, primary.hundred)};
      color: ${greyscale.white};
      border-radius: 3rem;
      height: 4.2rem;
      min-width: 8.3rem;
      padding: 0 1.6rem;

      &:hover {
        ${backgroundAndBorderColor(primary.hundredTwenty, primary.hundredTwenty)}
        color: ${greyscale.white}
      }

      &:focus {
        ${backgroundAndBorderColor(primary.hundredTwenty, primary.hundredTwenty)}
        color: ${greyscale.white}
      }

      &:active {
        ${backgroundAndBorderColor(primary.eighty, primary.eighty)}
        color: ${greyscale.white}
      }
    }

    &.btn-round-noodle-transparent {
      color: ${fontColors.primary};
      border: 1px solid ${greyscale.pewter};
      border-radius: 3rem;
      height: 4.2rem;
      max-width: fit-content;
      min-width: 8.3rem;
      font-size: 1.6rem;
      padding: 0 1.6rem;

      &:hover {
        ${backgroundAndBorderColor(noodle.primary, noodle.primary)}
        color: ${greyscale.white}
      }
    }

    &.btn-ghost-primary {
      color: ${noodle.primary};
      border: 1px solid ${noodle.primary};
      height: 4.2rem;
      min-width: 4.2rem;
      font-size: 1.6rem;

      &:hover {
        ${backgroundAndBorderColor(noodle.primary, noodle.primary)}
        color: ${greyscale.white}
      }
    }

    &.btn-onboarding {
      ${backgroundAndBorderColor(noodle.primary, noodle.primary)};
      color: ${greyscale.white};
      width: 100%;
      max-width: 62rem;
    }

    &.btn-onboarding-secondary {
      ${backgroundAndBorderColor(noodle.primary, noodle.primary)};
      color: ${greyscale.white};
      width: 30rem;
      max-width: 62rem;
      height: 6.4rem;
      margin-top: 3.2rem;
    }

    &.btn-onboarding-input {
      ${backgroundAndBorderColor(noodle.primary, noodle.primary)};
      margin-left: 0.8rem;
      height: 100%;
      min-width: 3.78rem;
      color: ${greyscale.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default ButtonWrapper;
