// @ts-nocheck
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants } from 'commons/constants';
import api from 'lib/Api';
import { PRODUCT } from 'commons/constants/product';
import { productActions } from './actions';
import { getProductTypes } from './types';

const { DANGER } = alertTypesConstants;
export function* getProductSaga(): Saga<void> {
  try {
    const response = yield call([api.product, 'getProduct']);
    yield put(productActions.getProductSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PRODUCT.productErrorMessage,
        type: DANGER,
      }),
    );
    yield put(productActions.getProductFailure(error));
  }
}
export default function* productsSaga(): Saga<void> {
  yield takeLatest(getProductTypes.GET_PRODUCTS_REQUEST, getProductSaga);
}
