import type { OnboardingOccupation, InsertAnswer } from 'commons/types/onboardingTypes';
import {
  getOnboardingOccupationTypes,
  getOnboardingQuestionTypes,
  insertAnswerTypes,
  getOnboardingAnswersTypes,
} from './types';

export const onboardingActions = {
  getOnboardingQuestionRequest: () => ({
    type: getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_REQUEST,
  }),
  getOnboardingQuestionSuccess: (payload: any[]) => ({
    type: getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_SUCCESS,
    payload,
  }),
  getOnboardingQuestionFailed: (payload: string) => ({
    type: getOnboardingQuestionTypes.GET_ONBOARDING_QUESTION_FAILURE,
    payload,
  }),
  getOnboardingOccupationRequest: () => ({
    type: getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_REQUEST,
  }),
  getOnboardingOccupationSuccess: (payload: OnboardingOccupation[]) => ({
    type: getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_SUCCESS,
    payload,
  }),
  getOnboardingOccupationFailure: (payload: string) => ({
    type: getOnboardingOccupationTypes.GET_ONBOARDING_OCCUPATION_FAILED,
    payload,
  }),
  insertAnswerRequest: (payload: InsertAnswer) => ({
    type: insertAnswerTypes.INSERT_ANSWER_REQUEST,
    payload,
  }),
  insertAnswerSuccess: (payload: any[]) => ({
    type: insertAnswerTypes.INSERT_ANSWER_SUCCESS,
    payload,
  }),
  insertAnswerFailure: (payload: string) => ({
    type: insertAnswerTypes.INSERT_ANSWER_FAILURE,
    payload,
  }),
  getOnboardingAnswers: () => ({
    type: getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_REQUEST,
  }),
  getOnboardingAnswersSuccess: (payload: any[]) => ({
    type: getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_SUCCESS,
    payload,
  }),
  getOnboardingAnswersFailure: (payload: string) => ({
    type: getOnboardingAnswersTypes.GET_ONBOARDING_ANSWERS_FAILURE,
    payload,
  }),
};
