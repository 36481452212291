import type { CourseState } from 'commons/types/courseTypes';
import {
  courseTypes,
  getCoursePreviewTypes,
  enrollTypes,
  getMyCoursesTypes,
  getCoursesTypes,
  getSavedCoursesTypes,
  getEnrolledSectionsTypes,
  unsaveCourseTypes,
  getCertificateTypes,
} from './types';

export const initialState: CourseState = {
  courses: {
    isLoading: false,
    data: [],
    error: '',
  },
  enrollCourse: {
    isOpenModal: false,
  },
  enrolledSections: {
    isLoading: false,
    data: null,
    error: '',
  },
  myCourses: {
    isLoading: false,
    data: [],
    error: '',
  },
  preview: {
    isLoading: false,
    data: null,
    error: '',
  },
  savedCourses: {
    isLoading: false,
    data: [],
    error: '',
  },
  unsaveCourse: {
    isLoading: false,
    success: false,
    error: '',
  },
  selectedCourse: {},
  certificate: {
    isLoading: false,
    data: {},
    error: '',
  },
};
type Action = {
  type: string;
  payload?: any;
};

const courseReducer = (state: CourseState = initialState, action: Action): CourseState => {
  const { type, payload } = action;

  switch (type) {
    case courseTypes.SELECT_COURSE: {
      return {
        ...state,
        selectedCourse: payload,
        preview: { ...initialState.preview, isLoading: false },
      };
    }

    case getCoursePreviewTypes.GET_COURSE_PREVIEW_REQUEST: {
      return { ...state, preview: { ...initialState.preview, isLoading: true } };
    }

    case getCoursePreviewTypes.GET_COURSE_PREVIEW_SUCCESS: {
      return {
        ...state,
        preview: {
          isLoading: false,
          error: '',
          data: payload,
        },
      };
    }

    case getCoursePreviewTypes.GET_COURSE_PREVIEW_FAILURE: {
      return { ...state, preview: { ...initialState.preview, error: payload } };
    }

    case enrollTypes.ENROLL_MODAL_REQUEST:
    case enrollTypes.ENROLL_MODAL_RESET:
    case enrollTypes.ENROLL_MODAL_FAILURE: {
      return { ...state, enrollCourse: { ...initialState.enrollCourse } };
    }

    case enrollTypes.ENROLL_MODAL_SUCCESS: {
      return {
        ...state,
        enrollCourse: {
          isOpenModal: true,
        },
      };
    }

    case getMyCoursesTypes.GET_MY_COURSES_REQUEST: {
      return { ...state, myCourses: { error: '', data: [], isLoading: true } };
    }

    case getMyCoursesTypes.GET_MY_COURSES_SUCCESS: {
      return {
        ...state,
        myCourses: {
          isLoading: false,
          data: payload,
          error: '',
        },
      };
    }

    case getMyCoursesTypes.GET_MY_COURSES_FAILURE: {
      return {
        ...state,
        myCourses: { data: [], isLoading: false, error: payload },
      };
    }

    case getCoursesTypes.GET_COURSES_REQUEST: {
      return { ...state, courses: { ...initialState.courses, isLoading: true } };
    }

    case getCoursesTypes.GET_COURSES_SUCCESS: {
      return {
        ...state,
        courses: {
          isLoading: false,
          error: '',
          data: payload,
        },
      };
    }

    case getCoursesTypes.GET_COURSES_FAILURE: {
      return { ...state, courses: { ...initialState.courses, error: payload } };
    }

    case getSavedCoursesTypes.GET_SAVED_COURSES_REQUEST: {
      return { ...state, savedCourses: { ...initialState.savedCourses, isLoading: true } };
    }

    case getSavedCoursesTypes.GET_SAVED_COURSES_SUCCESS: {
      return {
        ...state,
        savedCourses: {
          isLoading: false,
          error: '',
          data: payload,
        },
      };
    }

    case getSavedCoursesTypes.GET_SAVED_COURSES_FAILURE: {
      return { ...state, savedCourses: { ...initialState.savedCourses, error: payload } };
    }

    case getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_REQUEST: {
      return { ...state, enrolledSections: { ...initialState.enrolledSections, isLoading: true } };
    }

    case getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_SUCCESS: {
      return {
        ...state,
        enrolledSections: {
          isLoading: false,
          error: '',
          data: payload,
        },
      };
    }

    case getEnrolledSectionsTypes.GET_ENROLLED_SECTIONS_FAILURE: {
      return { ...state, enrolledSections: { ...initialState.enrolledSections, error: payload } };
    }

    case unsaveCourseTypes.UNSAVE_COURSE_REQUEST: {
      return { ...state, unsaveCourse: { ...initialState.unsaveCourse, isLoading: true } };
    }

    case unsaveCourseTypes.UNSAVE_COURSE_SUCCESS: {
      return {
        ...state,
        unsaveCourse: {
          isLoading: false,
          error: '',
          success: true,
        },
      };
    }

    case unsaveCourseTypes.UNSAVE_COURSE_FAILURE: {
      return {
        ...state,
        unsaveCourse: { ...initialState.unsaveCourse, error: payload, success: false },
      };
    }

    case getCertificateTypes.GET_CERTIFICATE_REQUEST: {
      return { ...state, certificate: { ...initialState.certificate, isLoading: true } };
    }

    case getCertificateTypes.GET_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        certificate: {
          isLoading: false,
          data: payload,
          error: '',
        },
      };
    }

    case getCertificateTypes.GET_CERTIFICATE_FAILURE: {
      return {
        ...state,
        certificate: {
          error: payload,
          data: {},
          isLoading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default courseReducer;
