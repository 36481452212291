import * as React from 'react';
import { Link } from 'react-router-dom';
import LeftNav from 'components/LeftNav';
import { baseUrl } from 'settings/index';
import type { NavLinkProps, MobileNavLinkProps } from './NavLink.types';
import {
  NavIconWrapper,
  MobileItemName,
  MobileNavLinkContainer,
  NavItemText,
} from './NavLink.style';

interface NavLinkSubcomponents {
  Mobile: React.FC<MobileNavLinkProps>;
}

const getIconState = (
  selected: boolean,
  icon?: React.ReactElement<any>,
  solidIcon?: React.ReactElement<any>,
): Record<string, any> =>
  selected
    ? {
        color: (props) => props.theme.palette.primary.hundred,
        icon: solidIcon,
      }
    : {
        color: (props) => props.theme.palette.neutral.sixty,
        icon,
      };

const MobileNavLink: React.FC<MobileNavLinkProps> = ({ item, selected = false }) => {
  const linkTo = item.path ? `${item.path}` : '';
  const iconState = getIconState(selected, item.icon, item.solidIcon);
  const isExternalUrl = item?.isExternalUrl;
  const containerProps = isExternalUrl
    ? { as: 'a', rel: 'noopener noreferrer', href: linkTo }
    : {
        to: linkTo,
      };

  return (
    <MobileNavLinkContainer {...containerProps} selected={selected}>
      <NavIconWrapper color={iconState.color}>{iconState.icon}</NavIconWrapper>
      <MobileItemName>{item.name}</MobileItemName>
    </MobileNavLinkContainer>
  );
};

const NavLink: React.FC<NavLinkProps> & NavLinkSubcomponents = ({ item, selected }) => {
  const { path = '', name } = item;
  const pathname = `${path}`;
  const isSelected =
    name !== 'Home'
      ? selected.includes(pathname) && selected.indexOf(pathname) === 0
      : selected === pathname;
  const linkTo = path === 'mysearch' ? `${baseUrl}/${path}` : `${path}`;
  const iconState = getIconState(isSelected, item.icon, item.solidIcon);
  const isExternalUrl = item?.isExternalUrl;

  const content = (
    <LeftNav.Item>
      <LeftNav.Icon>
        <NavIconWrapper color={iconState.color}>{iconState.icon}</NavIconWrapper>
      </LeftNav.Icon>
      <NavItemText selected={isSelected}>{item.name}</NavItemText>
    </LeftNav.Item>
  );

  return isExternalUrl ? (
    <a href={linkTo} className='lnav-link' rel='noopener noreferrer'>
      {content}
    </a>
  ) : (
    <Link to={linkTo} className='lnav-link'>
      {content}
    </Link>
  );
};

NavLink.Mobile = MobileNavLink;
export default NavLink;
