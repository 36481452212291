// @ts-nocheck
import { connectionTypes } from 'ducks/userConnection/types';
import { requestInfoTypes } from 'ducks/bookmarks/types';
import {
  loginTypes,
  socialTypes,
  otherUserTypes,
  profileUpdateTypes,
  roleTypes,
  getUserDetailsTypes,
  getOwnUserTypes,
} from './types';

const updateOtherUser = (otherUser, payload) => {
  const otherUserCopy = { ...otherUser };

  if (payload.targetUser && payload.targetUser.id === otherUserCopy.id) {
    otherUserCopy.connectionStatus = 'REQUESTED';
  }

  return otherUserCopy;
};

const initialState = {
  otherUser: {},
  userDetails: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getOwnUserTypes.SUCCESS:
    case socialTypes.SUCCESS:
    case loginTypes.SUCCESS: {
      const userPayload = payload.user;

      if (userPayload && !userPayload.profile) {
        userPayload.profile = {};
      }

      return { ...state, ...userPayload };
    }

    case loginTypes.FAILED:
    case socialTypes.FAILED: {
      return state;
    }

    case profileUpdateTypes.SUCCESS: {
      return { ...state, profile: { ...state.profile, ...payload } };
    }

    case otherUserTypes.REQUEST: {
      return {
        ...state,
        otherUser: {
          isLoading: true,
        },
      };
    }

    case otherUserTypes.SUCCESS: {
      return { ...state, otherUser: { ...payload, isLoading: false } };
    }

    case requestInfoTypes.SUCCESS: {
      return { ...state, didRequestInfo: true };
    }

    case otherUserTypes.FAILED: {
      return {
        ...state,
        otherUser: {
          error: payload,
          isLoading: false,
        },
      };
    }

    case roleTypes.STUDENT: {
      return { ...state, isExpert: false };
    }

    case roleTypes.EXPERT: {
      return { ...state, isExpert: true };
    }

    case connectionTypes.CONNECTION_SUCCESS: {
      const otherUser = updateOtherUser(state.otherUser, payload);
      return { ...state, otherUser };
    }

    case getUserDetailsTypes.REQUEST: {
      return {
        ...state,
        userDetails: {
          isLoading: true,
          error: null,
          data: null,
        },
      };
    }

    case getUserDetailsTypes.SUCCESS: {
      return {
        ...state,
        userDetails: {
          isLoading: false,
          error: null,
          data: payload,
        },
      };
    }

    case getUserDetailsTypes.FAILURE: {
      return {
        ...state,
        userDetails: {
          isLoading: false,
          error: payload,
          data: null,
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
