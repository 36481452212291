const externalIdRegex = /(E[0-9]+)/g;
const specialCharsRegex = /[&/\\#,+()@^[\]|$=_`~%.'":*?!<>{}]/g;

const normalizeSlug = (slug: string): string => slug.toLowerCase().replace(specialCharsRegex, '');

const getIdFromSlug = (slug: string): string | null => {
  const id = slug.match(externalIdRegex);
  return id ? id[0].slice(1) : null;
};

const getUtmContent = (pathname = ''): string => {
  if (pathname.toLowerCase().includes('/courses/')) {
    return pathname.replace('/courses/', '');
  }

  if (pathname.toLowerCase().includes('/universities/')) {
    return pathname.replace('/universities/', '');
  }

  return pathname.slice(1).replace(/\//g, '-');
};

export { getIdFromSlug, normalizeSlug, getUtmContent };
