// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import SearchInput from 'components/SearchInput';
import heroBackGroundWepp from 'assets/images/hero-background.webp';
import { getTheme } from 'settings/theme/index';
import {
  TABLET_SMALL_WIDTH,
  TABLET_WIDTH,
  CELLPHONE_LARGE_WIDTH,
  COMPUTER_SMALL_WIDTH,
} from 'commons/constants/size';
import CourseCard from 'components/CourseCard/index';
import NooderaSwiper from 'components/NooderaSwiper/index';
import { Link } from 'react-router-dom';

const {
  noodera: { spacing, fontTypes },
  palette: { greyscale, primary, neutral },
} = getTheme();
export const GuestHomeWrapper: StatelessFunctionalComponent<any> = styled.div`
  overflow-y: auto;
`;
export const GuestHomeContainer: StatelessFunctionalComponent<any> = styled.div`
  background: ${greyscale.white};
`;
export const HeroSection: StatelessFunctionalComponent<any> = styled.div`
  background-image: url(${heroBackGroundWepp});
  background-color: ${primary.hundredTwenty};
  height: 640px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    min-height: 320px;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    height: 732px;
  }
`;
export const HeroSectionContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 480px;
  max-width: 1024px;

  & > * {
    display: flex;
    flex-grow: 1;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding-left: ${spacing.x5};
    padding-right: ${spacing.x5};
  }

  @media only screen and (max-width: 1024px) {
    padding: 0;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const LeftContent: StatelessFunctionalComponent<any> = styled.div`
  flex-direction: column;
  max-width: 512px;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    & > div {
      padding: ${spacing.x5} ${spacing.x4};
    }
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    min-width: 100%;
    margin: 0;
  }
`;
export const LeftSection: StatelessFunctionalComponent<any> = styled.div`
  padding: ${spacing.x5} ${spacing.x3};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${spacing.x5} ${spacing.x4};
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    && {
      padding: ${spacing.x5};
    }
  }
`;
export const StyledSearchInput: StatelessFunctionalComponent<any> = styled(SearchInput)`
  display: flex;
`;
export const SectionsContainer: StatelessFunctionalComponent<any> = styled.div`
  max-width: 1024px;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > div {
    display: flex;
    flex-grow: 1;
  }

  @media only screen and (max-width: ${COMPUTER_SMALL_WIDTH}px) {
    max-width: 100%;
  }
`;
export const SectionsWrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: center;
`;
export const Section: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  margin: ${spacing.x2} 0;
  width: 100%;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    margin: ${spacing.x4} 0;
  }
`;
export const SectionContent: StatelessFunctionalComponent<any> = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${greyscale.white};
  width: 100%;
  padding: ${spacing.x5} ${spacing.x3};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    text-align: left;
    padding: ${spacing.x5};
  }
`;
export const OurHistoryContainer: StatelessFunctionalComponent<any> = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing.x3};
  background: ${greyscale.white};
  margin-bottom: 60px;
  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    margin-bottom: ${spacing.x3};
  }
`;
export const OurHistory: StatelessFunctionalComponent<any> = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    flex: 100%;
  }
`;
export const History: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4};
  line-height: 28px;
  max-width: 80%;
  margin-bottom: ${spacing.x3};
  color: ${neutral.hundred};
`;
export const Heading: StatelessFunctionalComponent<any> = styled.h1`
  ${fontTypes.headline1};
  margin-bottom: ${spacing.x3};
  span:last-of-type {
    color: ${primary.hundred};
  }
`;
export const ImageContainer: StatelessFunctionalComponent<any> = styled.div`
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    flex: 100%;
  }
`;
export const BannerTitle: StatelessFunctionalComponent<any> = styled.h1`
  ${fontTypes.font2Medium};
  color: ${greyscale.white};
  margin: 0 0 ${spacing.x5};
`;
export const BannerSubtitle: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font3};
  color: ${greyscale.white};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    ${fontTypes.font4Article};
  }
`;
export const BannerImageContainer: StatelessFunctionalComponent<any> = styled.div`
  justify-content: flex-end;

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 100%;
    justify-content: center;
  }
`;
export const BannerImage: StatelessFunctionalComponent<any> = styled.img`
  width: 480px;
  height: 480px;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    width: 372px;
    height: 360px;
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 375px;
    height: 375px;
  }
`;
export const SectionTitle: StatelessFunctionalComponent<any> = styled.h2`
  ${fontTypes.font3Medium};
  color: ${neutral.hundred};
  margin-bottom: ${spacing.x5};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    align-self: flex-start;
  }
`;
export const NoodleOnYourGoalsTitle: StatelessFunctionalComponent<any> = styled(SectionTitle)`
  text-align: center;

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    align-self: center;
  margin: 0;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    padding: ${spacing.x5};
  }
`;
export const SectionSubtitle: StatelessFunctionalComponent<any> = styled.h3`
  ${fontTypes.font3};
  color: ${neutral.hundred};
  margin: 0 0 ${spacing.x5};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    ${fontTypes.font4Article};
    text-align: left;
    padding: ${spacing.x5};
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    padding: 0;
  }
`;
export const SectionText: StatelessFunctionalComponent<any> = styled.p`
  ${fontTypes.font4Article};
  color: ${neutral.hundred};
`;
export const StyledLink: StatelessFunctionalComponent<any> = styled.a`
  margin-top: ${spacing.x5};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
`;
export const StyledInternalLink: StatelessFunctionalComponent<any> = styled(Link)`
  margin-top: ${spacing.x5};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
`;
export const AoIContainer: StatelessFunctionalComponent<any> = styled.div`
  flex-wrap: wrap;
  display: grid;
  grid-gap: ${spacing.x5};
  width: 100%;
  text-align: left;
  grid-template-columns: repeat(5, minmax(140px, 1fr));
  padding: ${spacing.x5} ${spacing.x3};

  @media only screen and (max-width: ${COMPUTER_SMALL_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(140px, 1fr));
  }

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    grid-template-columns: repeat(1, minmax(140px, 100%));
    row-gap: ${getTheme().noodera.spacing.x7};
    padding: 0;
  }
`;
export const OnYourGoalsContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${spacing.x6};
  text-align: center;
  padding: ${spacing.x5} ${spacing.x3};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    flex-wrap: wrap;
  }
`;
export const OnYourGoalsSectionText: StatelessFunctionalComponent<any> = styled(SectionText)`
  ${fontTypes.font3};
  margin-top: ${spacing.x5};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    ${fontTypes.font4Article};
  }
`;
export const DoItTogetherContainer: StatelessFunctionalComponent<any> = styled.div`
  display: grid;
  gap: ${spacing.x3};
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: left;
  padding: ${spacing.x3};

  @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;
export const DoItTogetherSectionText: StatelessFunctionalComponent<any> = styled(SectionText)`
  margin-bottom: ${spacing.x3};
`;
export const DoItTogetherSectionTitle: StatelessFunctionalComponent<any> = styled(SectionText)`
  ${fontTypes.font3Medium};
  margin-bottom: ${spacing.x3};
`;
export const StyledImage: StatelessFunctionalComponent<any> = styled.img`
  border-radius: 8px;
  width: 100%;
  height: auto;
`;
export const StyledCourseCard: StatelessFunctionalComponent<any> = styled(CourseCard)`
  text-align: left;
`;
export const StyledNooderaSwiper: StatelessFunctionalComponent<any> = styled(NooderaSwiper)`
  padding: ${spacing.x5} 0;

  .swiper-slide {
    height: 100%;
    min-height: 100%;
  }
`;
