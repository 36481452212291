import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MaterialCard from 'components/MaterialCard';

interface MaterialCardStyleProps {
  isDisabled?: boolean;
  className?: string;
}
interface StyledLinkProps {
  isDisabled?: boolean;
  width?: string;
}
interface StyledAProps {
  isDisabled?: boolean;
  width?: string;
}

export const ProgressContainer = styled.div`
  background: ${({ theme }) => theme.palette.neutral.five};
  padding: 1.2rem 2.4rem 1.2rem 2.4rem;
`;

export const CourseLogo = styled.img`
  height: 3.2rem;
  object-fit: contain;
  object-position: left center;
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x5};
`;

export const MaterialCardStyle = styled(MaterialCard)<MaterialCardStyleProps>`
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};
  min-height: 360px;

  && {
    height: 100%;

    & .deTitR {
      background: transparent;
    }
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: block;
  color: unset;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  width: ${({ width }) => width};
  &:hover {
    color: unset;
  }
`;

export const StyledA = styled.a<StyledAProps>`
  display: block;
  color: unset;
  width: ${({ width }) => width};
  &:hover {
    color: unset;
  }
`;

export const StyledTitle = styled(MaterialCard.Title)`
  ${({ theme }) => theme.ellipsis(3)};
`;

export const StyledMaterialCardImg = styled(MaterialCard.Img)`
  & img {
    min-height: 12rem;
    max-height: 12rem;
  }
`;
