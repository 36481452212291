// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_WIDTH } from 'commons/constants';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { spacing },
} = getTheme();
export const TabsNavigationContainer: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: ${spacing.x3};

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    margin-bottom: ${spacing.x5};
    display: block;
    padding: 0 ${spacing.x5};
  }
`;
export const TabsExtraActions: StatelessFunctionalComponent<any> = styled.div`
  margin-left: auto;
  grid-column-start: 3;
`;
export const TabsContainer: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: center;
    grid-column-start: 2;
    height: 32px;
    width: 300px;
    margin: 0 auto;

    @media screen and (max-width: ${TABLET_WIDTH}px) {
      height: 24px;
      width: auto;
    }

    & > div {
      width: 100%;

      & > a:first-child div {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      & > a:last-child div {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
`;
