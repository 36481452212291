// @ts-nocheck
import * as React from 'react';
import NoodleSelect from 'components/NoodleSelect/index';
import NoodleTextArea from 'components/NoodleTextArea/index';
import { Wrapper } from './Purpose.styles';
import type { PurposeProps } from './Purpose.types';

const Purpose = ({ data, items, onChange }: PurposeProps) => {
  const [form, setForm] = React.useState({
    sectionType: '',
    textDetails: '',
  });
  const CHARACTER_LIMIT = 440;

  const handleChange = (text, key) => {
    const newObj = { ...data };
    newObj[key] = text;
    setForm({ ...form, ...newObj });
    onChange({ ...form, ...newObj });
  };

  return (
    <Wrapper>
      <NoodleSelect
        value={form.sectionType}
        label='Session Purpose'
        placeholder='Select an Option'
        items={items}
        onChange={(e) => handleChange(e.target.value, 'sectionType')}
      />
      <NoodleTextArea
        label='Details About This Session'
        value={form.textDetails}
        placeholder='Tell your TA what topics you want to cover. If you select a public session, this description will be visible to your team mates, and will help them decide if they want to join.'
        maxLength={CHARACTER_LIMIT}
        onChange={(e) => handleChange(e.target.value, 'textDetails')}
      />
    </Wrapper>
  );
};

export default Purpose;
