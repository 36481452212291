/**
 * @description
 * Adds the Http procotol to image url if not present
 * @param {String} image Url of the image
 */
const addHttpsToUrl = (imageUrl?: string | null): void | string => {
  let imageWithProtocol;

  if (imageUrl) {
    const hasProtocol = imageUrl.includes('https://') || imageUrl.includes('http://');

    if (imageUrl.includes('//') && !hasProtocol) {
      imageWithProtocol = `https:${imageUrl}`;
    } else if (!imageUrl.includes('//') && !hasProtocol) {
      imageWithProtocol = `https://${imageUrl}`;
    } else {
      imageWithProtocol = imageUrl;
    }
  }

  return imageWithProtocol;
};

export default addHttpsToUrl;
