// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { greyscale },
} = getTheme();
const SeparatorWrapper: StatelessFunctionalComponent<any> = styled.div`
  background-color: ${({ color = greyscale.cloud }) => color};
  min-height: 0.1rem;
  width: 100%;
`;
export default SeparatorWrapper;
