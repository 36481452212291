/**
 * @description
 * Converts camelCase text to underdash-case
 * Pre: Text must be in camelCase case
 * @param {String} text Text to be parsed
 */
const camelToUnderdashCase = (text: string): string =>
  text.replace(/([A-Z])/g, (x) => `_${x.toLowerCase()}`);

export default camelToUnderdashCase;
