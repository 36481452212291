import {
  getUserRolesTypes,
  getUsersTypes,
  getUserByIdTypes,
  updateUserRolesTypes,
  updateUserTypes,
} from './types';

const initialState = {
  users: {
    searchTerm: '',
    list: {},
    loading: false,
    errorMessage: '',
    currentPage: 1,
    roles: {},
  },
  loadingUserRoles: false,
};

const adminUserReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getUsersTypes.REQUEST: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          currentPage: payload.page,
          searchTerm: payload.email,
        },
      };
    }

    case getUsersTypes.SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          list: payload.users,
          count: payload.count,
          roles: payload.roles,
        },
      };
    }

    case getUsersTypes.FAILED: {
      return { ...state, users: { ...state.users, loading: false, errorMessage: payload } };
    }

    case getUserByIdTypes.REQUEST: {
      return { ...state, isLoading: true, selectedUser: {} };
    }

    case getUserByIdTypes.SUCCESS: {
      return { ...state, isLoading: false, selectedUser: payload };
    }

    case getUserByIdTypes.FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case updateUserTypes.REQUEST:
    case updateUserTypes.SUCCESS: {
      return { ...state };
    }

    case updateUserTypes.FAILED: {
      return { ...state, error: payload };
    }

    case getUserRolesTypes.REQUEST:
    case updateUserRolesTypes.REQUEST: {
      return { ...state, loadingUserRoles: true };
    }

    case getUserRolesTypes.SUCCESS: {
      return { ...state, loadingUserRoles: false, roles: payload };
    }

    case updateUserRolesTypes.SUCCESS: {
      return { ...state, loadingUserRoles: false, selectedUser: payload };
    }

    case updateUserRolesTypes.FAILED:
    case getUserRolesTypes.FAILED: {
      return { ...state, loadingUserRoles: false, error: payload };
    }

    default:
      return state;
  }
};

export default adminUserReducer;
