import get from 'lodash.get';

import JsonApi from 'utils/jsonApi';
import { getSessionStorageData } from 'utils/sessionStorage';
import { getUtmValuesFromUrl, UTM_VALUES } from 'utils/heap';

import type { UserProfile, UserDetails, PersonaDimensionsObject } from 'commons/types';

export default class User {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  static getIsAdmin = (user: any) =>
    user.roles ? Object.values(user.roles).some((role: any) => role.name === 'admin') : false;

  static setPersonaDimensions = (
    usersData: Record<string, any>,
    search: Record<string, any>,
  ): PersonaDimensionsObject | null => {
    const { id } = search.persona;
    return id ? usersData.entities.persona[id] : null;
  };

  static setPersonasOnSearches = (usersData: Record<string, any>, user: any): any | null => {
    const userWithPersonas = user;
    Object.keys(user.searches).forEach((searchKey) => {
      userWithPersonas.searches[searchKey].personaDimensions = User.setPersonaDimensions(
        usersData,
        user.searches[searchKey],
      );
    });
    return userWithPersonas;
  };

  // eslint-disable-next-line class-methods-use-this
  async getUserDetails({
    partnerId,
    userId,
  }: {
    partnerId: string;
    userId: string | number;
  }): Promise<UserDetails> {
    try {
      const response: Record<string, any> = await this.jsonApi.get(
        `/users/${userId}/details?partner_id=${partnerId}`,
        false,
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async getById(id: string): Promise<any> {
    const usersData = await this.jsonApi.get(`/users/${id}?include=entity,roles&fields[role]=name`);

    if (!usersData.entities) {
      // Not user found
      throw new Error('User does not exist');
    }

    const user = get(usersData, `entities.user.${id}`, {});
    user.searches = get(usersData, 'entities.search', {});
    user.roles = get(usersData, 'entities.role', {});
    User.setPersonasOnSearches(usersData, user);
    user.isAdmin = User.getIsAdmin(user);
    const entity = get(usersData, 'entities.entity', {});
    const [userEntity] = Object.keys(entity).map((idEntity) => ({
      id: idEntity,
      type: `${entity[idEntity].type}`,
    }));
    user.entity = userEntity;
    return user;
  }

  async getOwnUser(pathname?: string, shouldLogUTMs = true): Promise<any> {
    const sessionStorageUTMs = getSessionStorageData(UTM_VALUES);

    const utmValues = sessionStorageUTMs || getUtmValuesFromUrl(pathname);

    const utmParams = utmValues ? new URLSearchParams(utmValues) : null;
    const utmParamsQueryString = utmParams && shouldLogUTMs ? `?${utmParams.toString()}` : '';

    const usersData = await this.jsonApi.get(`/users/me${utmParamsQueryString}`);

    const userObject = get(usersData, `entities.user`, {});
    const user: any = Object.values(userObject)[0];
    user.searches = get(usersData, 'entities.search', {});
    user.roles = get(usersData, 'entities.role', {});
    User.setPersonasOnSearches(usersData, user);
    user.isAdmin = User.getIsAdmin(user);
    const entity = get(usersData, 'entities.entity', {});
    const [userEntity] = Object.keys(entity).map((idEntity) => ({
      id: idEntity,
      type: `${entity[idEntity].type}`,
    }));
    user.entity = userEntity;

    return user;
  }

  async updateProfile(user: { id: string; profile: UserProfile }): Promise<UserProfile> {
    const userData = await this.jsonApi.post('/users', {
      user,
    });
    return get(userData, `entities.user.${user.id}.profile`);
  }
}
