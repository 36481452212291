export const extractErrorMessage = (response: any): string => {
  let message: string;
  // show one error at a time in case of multiple errors
  if (response?.data?.detail && typeof response?.data?.detail === 'object') {
    const errorKey = Object.keys(response?.data?.detail)[0];
    message = response?.data?.detail[errorKey][0];
  } else {
    message = JSON.stringify(response?.data?.detail);
  }
  return message;
};
