// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  palette: { neutral },
  noodera: { fontTypes, spacing },
} = getTheme();
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-top: 3.2rem;
  display: flex;
  justify-content: center;
`;
export const Container: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const TitleWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin: 4px 0 4px 0;
`;
export const Title: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font5Medium}
  color: ${neutral.eighty};
`;
export const FieldWrapper: StatelessFunctionalComponent<any> = styled.div`
  margin-bottom: ${spacing.x3};
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const HelpText: StatelessFunctionalComponent<any> = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    ${fontTypes.font5};
    color: ${neutral.eighty};
    margin-top: ${spacing.x8};
  }
`;
