// @ts-nocheck
import * as React from 'react';
import { getTheme } from 'settings/theme';
import { ReactComponent as TablePlaceholder } from 'assets/svg/table_placeholder.svg';
import {
  Block,
  Card,
  DashboardCard,
  Element,
  EmptyCard,
  MiniCard,
  Post,
  User,
} from './Skeleton.style';
import type { SkeletonProps } from './Skeleton.types';

const {
  noodera: { spacing },
} = getTheme();

const Skeleton = ({ type, container, header }: SkeletonProps): React.ReactElement<any> => {
  const Container = container || Block;

  const renderSkeleton = () => {
    switch (type) {
      case 'card':
        return (
          <Card>
            <Element elHeight='16rem' noMargin />
            <div className='skeleton__lines-wrap'>
              <Element elWidth='255px' />
              <Element elWidth='255px' />
              <Element elWidth='125px' noMargin />
            </div>
          </Card>
        );

      case 'section-card':
        return (
          <Container>
            <div className='skeleton__lines-wrap' style={{ gap: '12px', flexDirection: 'column' }}>
              <Element elWidth='200px' />
              <Element elWidth='150px' />
              <Element elWidth='150px' />
            </div>
            <div className='skeleton__lines-wrap' style={{ gap: '2rem' }}>
              <Element elHeight='48px' elWidth='48px' circle />
              <div className='skeleton__lines-wrap'>
                <Element elHeight='18px' elWidth='116px' />
                <Element elHeight='18px' elWidth='116px' />
              </div>
            </div>
            <Element elHeight='3rem' elWidth='100%' className='skeleton__btn' />
          </Container>
        );

      case 'small-card':
        return (
          <Card>
            <Element elHeight='172px' noMargin />
            <div className='skeleton__lines-wrap'>
              <Element elWidth='140px' />
              <Element elWidth='140px' noMargin />
            </div>
          </Card>
        );

      case 'mini-card':
        return (
          <MiniCard>
            <Element elWidth='20%' elHeight='100%' noMargin />
            <div className='skeleton__lines-wrap'>
              <Element noMargin />
            </div>
          </MiniCard>
        );

      case 'dashboard-card':
        return (
          <DashboardCard>
            <Element elWidth={spacing.x4} elHeight={spacing.x4} circle />
            <Element elWidth='144px' elHeight={spacing.x5} />
            <Element elHeight={spacing.x5} />
            <Element elHeight={spacing.x5} noMargin />
            <div className='skeleton__btn'>
              <Element elWidth='96px' elHeight={spacing.x4} />
            </div>
          </DashboardCard>
        );

      case 'empty-card':
        return <EmptyCard />;

      case 'comment':
        return (
          <Block>
            <Element elWidth='240px' />
            <Element />
          </Block>
        );

      case 'post':
        return (
          <Post>
            <Skeleton type='user' />
            <Element />
            <Element elWidth='240px' />
            <Element elHeight='240px' />
          </Post>
        );

      case 'user':
        return (
          <User>
            <Element elWidth={spacing.x2} elHeight={spacing.x2} noMargin circle />
            <div className='skeleton__lines-wrap'>
              <Element elWidth='144px' />
              <Element elWidth='96px' />
            </div>
          </User>
        );

      case 'block':
        return (
          <Block>
            <Element elWidth='144px' />
            <Element elWidth='288px' />
            <Element elWidth='288px' />
            <Element elWidth='144px' />
          </Block>
        );

      case 'form':
        return (
          <Container>
            {header && header()}
            <Element elWidth='144px' />
            <Element elHeight='48px' />
            <Element elWidth='144px' />
            <Element elHeight='48px' />
            <Element elWidth='144px' />
            <Element elHeight='48px' />
            <Element elWidth='144px' />
            <Element elHeight='48px' />
            <Element elWidth='144px' />
            <Element elHeight='48px' />
          </Container>
        );

      case 'table':
        return <TablePlaceholder />;

      case 'text':
        return <Element elWidth='75px' />;

      default:
        return <></>;
    }
  };

  return renderSkeleton();
};

export default Skeleton;
