import * as React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import Loader from 'components/uielements/Loader';
import authActions from 'ducks/auth/actions';
import { AuthContext } from 'utils/customHooks/auth';
import { Redirect } from 'react-router-dom';
import type { ProtectedRouteProps } from './Route.types';

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  componentDidMount() {
    const { isLoggedIn, pathname, onFetchUserDetails, isGlobalPartnerLoading, isAuthLoading } =
      this.props;

    if (!isLoggedIn && !isGlobalPartnerLoading && !isAuthLoading) {
      const { logout } = this.context;
      onFetchUserDetails(pathname, logout);
    }
  }

  render() {
    const { children, isGlobalPartnerLoading, isAuthLoading, globalPartner } = this.props;
    const { isAuthenticated } = this.context;

    if (!isAuthenticated(globalPartner?.partnerId)) {
      return <Redirect to='/login' />;
    }

    if (isGlobalPartnerLoading || isAuthLoading) {
      return <Loader />;
    }
    return <>{children}</>;
  }
}

const mapStateToProps = (state) => {
  const pathname = get(state, 'router.location.pathname');
  const search = get(state, 'router.location.search');

  return {
    isLoggedIn: get(state, 'auth.isLoggedIn'),
    isAuthLoading: get(state, 'auth.isLoading'),
    pathname: pathname + search,
    isGlobalPartnerLoading: get(state, 'partnerInstance.globalPartner.isLoading'),
    globalPartner: get(state, 'partnerInstance.globalPartner.data'),
  };
};

const mapDispatchToProps = {
  onFetchUserDetails: (pathname, logout = null) =>
    authActions.loginRequest({
      guestRoute: false,
      pathname,
      logout,
    }),
};

ProtectedRoute.contextType = AuthContext;
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
