// @ts-nocheck
import type { JsonApiFormat } from './generalTypes';
import type { AuthResult } from './authTypes';
import type { PartnerInstanceSection } from './partnerInstanceTypes';

export type ExpertObject = {
  id: number;
  connected: boolean;
  connectionStatus: string;
  image?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  profile?: {
    firstName: string;
    lastName: string;
  };
};
export type GetExpertsRequestPayload = {
  page: number;
  search?: string;
  tab: string;
};
export type GetExpertsPayload = {
  list: ExpertObject[];
  count: number;
};
export type OwnerInfo = {
  id: number;
  name: string;
  email: string;
};
export type UserAddress = {
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  state?: string;
};
export type UserProfile = {
  id?: number;
  address?: UserAddress;
  avatar?: string;
  badge?: boolean;
  bio?: string;
  birthdate?: string;
  cover?: string;
  firstName?: string;
  highLevelEducation?: string;
  highSchoolGedYear?: string;
  lastName?: string;
  middleName?: string;
  name?: string;
  phoneNumber?: string;
  picture?: string;
  socialMedia?: {
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  termsOfService?: boolean;
  tagline?: string;
  title?: string;
  zipCode?: string;
};
export type UserDetails = {
  certificatesObtained: number;
  completionRate: number;
  sections: PartnerInstanceSection[];
  purchases: any[];
};
export type EntityObject = {
  id: string;
  type: string;
};
export type RoleType = {
  id: number;
  name: string;
};
export type RoleTypeListObject = Record<string, RoleType>;

export type UserObject = {
  id: number;
  active: boolean;
  auth0Id: string;
  connectionStatus: string;
  email: string;
  emailVerified: boolean;
  isSocialUser: boolean;
  profile: UserProfile;
  roles: RoleTypeListObject;
  searches: any;
  entityObj: EntityObject;
  name: string;
  avatar?: string;
  status: string;
  bookmarkId?: number;
  bookmarkedByUser: boolean;
};

export type CustomUserObject = any & {
  roles: RoleType[];
};

export type UserListObject = Record<string, any>;
export type ConnectWithItem = {
  id: number;
  image?: string;
  images?: {
    avatar: string;
    cover: string;
  };
  media?: {
    urls: {
      regular: string;
    };
  };
  name: string;
  email?: string;
  profile?: UserProfile;
  ownerInfo?: OwnerInfo;
  tagNames?: string[];
  affiliationName?: string;
  title?: string;
  authorName?: string;
  authorTitle?: string;
  avatar?: string;
  cityName?: string;
  stateCode?: string;
  score?: number;
  tagline: string;
  user?: JsonApiFormat;
  userId?: string;
};
export type UserLogin = {
  authResult: AuthResult;
};
export type RequestConnectionPayload = {
  payload: {
    entityId: string;
    targetUser?: UserObject;
  };
};
export const UserConnectionStatusEnum = Object.freeze({
  connected: 'CONNECTED',
  requested: 'REQUESTED',
  disconnected: 'DISCONNECTED',
});
export type UserConnectionStatus = any;
export type UserConnectionType = {
  id: number;
  sourceUser: UserObject;
  targetUser: UserObject;
  status: UserConnectionStatus;
  updatedAt: string;
};
export type UserSignup = {
  authResult: AuthResult;
  profile: any;
};
export type GetUsersBodyType = {
  page: number;
  email: string;
};
export type EntityConnection = {
  entityId: string;
  id: number;
  status: UserConnectionStatus;
  targetEntityId: string;
  updatedAt: string;
};
export type RawEntityConnection = Record<
  string,
  {
    id: number;
    type: string;
    user: {
      id: number;
    };
  }
>;
