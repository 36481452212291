// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Link } from 'react-router-dom';

export const StyledLink: StatelessFunctionalComponent<any> = styled(Link)`
  margin-left: ${({ theme }) => theme.noodera.spacing.x7};
`;

export const ShoppingCartIconContainer: StatelessFunctionalComponent<any> = styled.div`
  margin: 0 ${({ theme }) => theme.noodera.spacing.x7};
  position: relative;
`;

export const StyledShoppingCartIcon: StatelessFunctionalComponent<any> = styled(
  ShoppingCartOutlinedIcon,
)`
  color: ${({ theme }) => theme.palette.neutral.hundred};
  width: 32px !important;
  height: 32px !important;
`;
