// @ts-nocheck
import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import * as moment from 'moment';
import { push } from 'connected-react-router';
import type { Saga } from 'redux-saga';
import alertActions from 'ducks/alert/actions';
import { alertTypesConstants, invoiceProductType } from 'commons/constants';
import { ADMIN_ENROLLMENT_TYPE, PARTNER_INSTANCE } from 'commons/constants/partnerInstance';
import api, { rtkQApi } from 'lib/Api';
import humps from 'humps';
import { navigateWithPageReload } from 'utils/urls/index';
import { downloadFile } from 'utils/browser/index';
import {
  getMemberCreationError,
  getSectionCreationUpdateErrorMessage,
  getParterCreationErroMessage,
  checkForNumSeatsError,
} from 'utils/apiHelpers/index';
import { PartnerInstanceMembership } from 'commons/types/partnerInstanceTypes';
import { SectionType } from 'commons/constants/section';
import { InvoiceReviewAction, PartnerStripeErrors } from 'commons/constants/payment';
import { devConfig } from 'settings';
import { partnerInstanceActions } from './actions';
import {
  getPartnerInviteTypes,
  getPartnerMembershipsTypes,
  verifyInvitationTokenTypes,
  getPartnerInstancesTypes,
  getPartnerInstanceTypes,
  uploadCSVTypes,
  createPartnerInstanceTypes,
  updatePartnerInstanceTypes,
  getPartnerCoursesTypes,
  updatePartnerCourseTypes,
  getSectionByIdTypes,
  getPartnerCourseTypes,
  createOrUpdateSectionTypes,
  getEngagementReportTypes,
  getLearnerStatusReportTypes,
  sendPartnerInvitesTypes,
  getSurveyReportTypes,
  downloadInstanceReportTypes,
  downloadProgramReportTypes,
  getCompletionReportByPartnerTypes,
  getCompletionReportByProgramTypes,
  getCertificateReportTypes,
  createPartnerInstanceMembershipTypes,
  getPaginatedPartnerInstancesTypes,
  patchPartnerInstanceMembershipTypes,
  getPaginatedPartnerMembershipsTypes,
  getPaginatedPartnerCoursesTypes,
  getPaginatedSectionLearnersByIdTypes,
  getPaginatedCourseSectionsTypes,
  addMembersToSectionTypes,
  addSoloMembersToCourseTypes,
  getGlobalPartnerTypes,
  setupStripeAccountTypes,
  getStripeAccountTypes,
  createStripeInvoiceTypes,
  requestSeatsTypes,
  getStripeInvoiceTypes,
  getStripeInvoiceByIdTypes,
  getPaginatedStripeInvoiceTypes,
  reviewSeatRequestTypes,
  getStripeInvoiceTableDataTypes,
  getStripeReceiptsTableDataTypes,
  getStripePartnerRevenueDataTypes,
  getPartnerSeatRequestTypes,
} from './types';
import { getPartnerInstance } from './selector';

const { DANGER, SUCCESS } = alertTypesConstants;
const DATE_FORMAT = 'YYYY-MM-DD hh.mm.ssA';
export function* getPartnerInviteSaga({
  payload,
}: {
  payload?: {
    partnerId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerInvitations'], payload);
    yield put(partnerInstanceActions.getPartnerInvitesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerInvitesFailure(error));
  }
}
export function* getPartnerInstancesSaga(): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerInstances']);
    yield put(partnerInstanceActions.getPartnerInstancesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerInstancesFailure(error));
  }
}
export function* getPaginatedPartnerInstancesSaga({ payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedPartnerInstances'], payload);
    yield put(partnerInstanceActions.getPaginatedPartnerInstancesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPaginatedPartnerInstancesFailure(error));
  }
}
export function* getPartnerInstanceByPartnerIdSaga({
  payload,
}: {
  payload: { partnerId: string };
}): Saga<void> {
  try {
    const partnerInstance = yield call([api.partnerInstance, 'getByPartnerId'], payload);
    yield put(partnerInstanceActions.getPartnerInstanceSuccess(partnerInstance));
  } catch (error) {
    yield put(partnerInstanceActions.getPartnerInstanceFailure(error));
  }
}
export function* createPartnerInstanceMembershipSaga({
  payload,
}: {
  payload: PartnerInstanceMembership;
}) {
  try {
    const partnerInstanceMembership = yield call(
      [api.partnerInstance, 'createPartnerInstanceMembership'],
      payload,
    );
    yield put(
      partnerInstanceActions.createPartnerInstanceMembershipSuccess(partnerInstanceMembership),
    );
    yield put(
      alertActions.setAlert({
        message: 'Member added successfully!',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  } catch (err) {
    const errorMessage = getMemberCreationError(err, payload);
    yield put(
      alertActions.setAlert({
        message: errorMessage,
        type: alertTypesConstants.DANGER,
      }),
    );
    yield put(partnerInstanceActions.createPartnerInstanceMembershipFailure(err));
  }
}
export function* patchPartnerInstanceMembershipSaga({
  payload,
}: {
  payload: PartnerInstanceMembership & { id: number };
}) {
  try {
    const partnerInstanceMembershipPatch = yield call(
      [api.partnerInstance, 'patchPartnerInstanceMembership'],
      { payload },
    );
    yield put(
      partnerInstanceActions.patchPartnerInstanceMembershipSuccess(partnerInstanceMembershipPatch),
    );
    yield put(
      alertActions.setAlert({
        message: 'Member edited successfully!',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  } catch (err) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error editing the member.',
        type: alertTypesConstants.DANGER,
      }),
    );
    yield put(partnerInstanceActions.patchPartnerInstanceMembershipFailure(err));
  }
}
export function* sendPartnerInvitesSaga({
  payload: { emails, partnerId, resendAction },
}: {
  payload: {
    emails: string[];
    resendAction?: boolean;
    partnerId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'sendPartnerInvites'], {
      emails,
      partnerId,
    });
    yield put(partnerInstanceActions.sendPartnerInvitesSuccess(response));

    if (resendAction) {
      yield put(
        alertActions.setAlert({
          message: 'Invitation Resent',
          type: alertTypesConstants.SUCCESS,
        }),
      );
    }

    // Easier to just refetch the partner instance. Doesn't seem too slow either.
    yield call(getPartnerInstanceByPartnerIdSaga, {
      payload: partnerId,
    });
    // this type of function calling fools flow, flow does not check the function type neither the argument type you provided, this caused bug NDC-3007
    yield call(getPartnerInviteSaga, {
      payload: {
        partnerId,
      },
    });
  } catch (error) {
    if (error && error.response && error.response.status === 403) {
      const roles = yield select((state) => state.user.roles);
      const rolesArray: any[] = Object.values(roles || {});

      if (rolesArray.find((role) => role?.name === 'parent-instance-admin')) {
        yield put(
          alertActions.setAlert({
            message: PARTNER_INSTANCE.universityAdminForbiddenInviteErrorMessage,
            type: alertTypesConstants.DANGER,
          }),
        );
      } else if (rolesArray.find((role) => role?.name === 'instance-admin')) {
        yield put(
          alertActions.setAlert({
            message: PARTNER_INSTANCE.instanceAdminForbiddenInviteErrorMessage,
            type: alertTypesConstants.DANGER,
          }),
        );
      } else {
        yield put(
          alertActions.setAlert({
            message: PARTNER_INSTANCE.sendInviteErrorMessage,
            type: alertTypesConstants.DANGER,
          }),
        );
      }
    } else {
      yield put(
        alertActions.setAlert({
          message: PARTNER_INSTANCE.sendInviteErrorMessage,
          type: alertTypesConstants.DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.sendPartnerInvitesFailure(error));
  }
}
export function* uploadCSVSaga({
  payload,
}: {
  payload: {
    file: string[];
    partnerId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'uploadCSV'], payload);

    if (response) {
      yield put(partnerInstanceActions.uploadCSVSuccess(response));
    }
  } catch (error) {
    if (error?.message) {
      yield put(
        alertActions.setAlert({
          message: error?.message,
          type: alertTypesConstants.DANGER,
        }),
      );
    } else {
      yield put(
        alertActions.setAlert({
          message: PARTNER_INSTANCE.sendInviteErrorMessage,
          type: alertTypesConstants.DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.uploadCSVFailure(error?.message));
  }
}
export function* getPartnerMembershipsSaga({
  payload,
}: {
  payload: {
    partnerId?: string;
    memberId?: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerMemberships'], payload ?? {});
    yield put(partnerInstanceActions.getPartnerMembershipsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerMembershipsFailure(error));
  }
}
export function* getPaginatedPartnerMembershipsSaga({
  payload,
}: {
  payload: {
    partnerId?: string;
    memberId?: string;
    actualPage: number;
    pageSize: number;
    search?: string;
    courseSlug?: string;
    includeInactive?: boolean;
  };
}): Saga<void> {
  try {
    const response = yield call(
      [api.partnerInstance, 'getPaginatedPartnerMemberships'],
      payload ?? { actualPage: 1, pageSize: 10 },
    );
    yield put(partnerInstanceActions.getPaginatedPartnerMembershipsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPaginatedPartnerMembershipsFailure(error));
  }
}
export function* getPartnerCoursesSaga({
  payload,
}: {
  payload: {
    partnerId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerCourses'], payload);
    yield put(partnerInstanceActions.getPartnerCoursesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerCoursesFailure(error));
  }
}
export function* getPaginatedPartnerCoursesSaga({
  payload,
}: {
  payload: {
    pageSize?: number;
    actualPage: number;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedPartnerCourses'], payload);
    yield put(partnerInstanceActions.getPaginatedPartnerCoursesSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPaginatedPartnerCoursesFailure(error));
  }
}
export function* getPartnerCourseSaga({
  payload,
}: {
  payload: {
    slug: string;
    partnerId: string;
    redirectOnFail?: boolean;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerCourse'], payload);
    yield put(
      partnerInstanceActions.getPartnerCourseSuccess({
        ...response,
        partnerId: payload?.partnerId,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerCourseFailure(error.message));
    const partnerInstance = yield select(getPartnerInstance);

    if (partnerInstance && payload?.redirectOnFail) {
      yield put(push(`/instance/${partnerInstance.partnerId}/programs`));
    }
  }
}
export function* updatePartnerCourseSaga({
  payload,
}: {
  payload: {
    partnerId: string;
    slug: string;
    priceInCents: string;
    contentfulCourseId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'updatePartnerCourse'], payload);
    yield put(partnerInstanceActions.updatePartnerCourseSuccess(response));
    yield put(
      alertActions.setAlert({
        message: 'You Changed the Price for this Instance',
        type: SUCCESS,
      }),
    );
    yield put(
      partnerInstanceActions.getPartnerCourseRequest({
        slug: payload.slug,
        partnerId: payload.partnerId,
      }),
    );
    yield put(push(`/instance/${payload.partnerId}/programs/${payload.slug}`));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error updating the course',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.updatePartnerCourseFailure(error));
  }
}
export function* getPaginatedCourseSectionsSaga({
  payload,
}: {
  payload: {
    slug: string;
    partnerId: string;
    actualPage: number;
    pageSize?: number;
    getSoloLearners?: boolean;
    searchByAdmin?: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedCourseSections'], payload);
    yield put(partnerInstanceActions.getPaginatedCourseSectionsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.partnerInstanceErrorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPaginatedCourseSectionsFailure(error.message));
  }
}

export function* verifyInvitationTokenSaga({
  payload,
}: {
  payload: {
    payload: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'validateInvitationToken'], payload);
    yield put(partnerInstanceActions.verifyInvitationTokenSuccess(response));
    const authUrl = response?.isUser ? '/login' : '/signup';
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.tokenVerificationSuccessMsg,
        type: SUCCESS,
      }),
    );
    // We need a page reload to have the email prefill work correctly. Not ideal, but it's the more robust solution here.
    yield call(navigateWithPageReload, `${authUrl}?email=${response?.inviteeEmail}`);
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.tokenVerificationErrorMsg,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPartnerMembershipsFailure(error));
    yield put(push('/forbidden'));
  }
}

export function* createPartnerInstanceSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'createPartnerInstance'], payload);
    if (response) {
      yield put(partnerInstanceActions.createPartnerInstanceSuccess(true));
      yield call(getPartnerInstancesSaga, { payload });
    }
  } catch (error) {
    const errorMessage = getParterCreationErroMessage(error);
    yield put(
      alertActions.setAlert({
        message: errorMessage,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.createPartnerInstanceFailure(error));
  }
}

export function* updatePartnerInstanceSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'updatePartnerInstance'], payload);

    if (response) {
      yield put(partnerInstanceActions.updatePartnerInstanceSuccess(true));
      yield put(
        partnerInstanceActions.getPartnerInstanceSuccess(humps.camelizeKeys(response.data.data)),
      );
    }
  } catch (error) {
    console.error('🚀 ~ file: saga.ts:339 ~ error:', error.response);
    if (checkForNumSeatsError(error)) {
      yield put(
        alertActions.setAlert({
          message: PARTNER_INSTANCE.negativeSeatsError,
          type: DANGER,
        }),
      );
      yield put(partnerInstanceActions.updatePartnerInstanceFailure(error.response?.data?.error));
    } else {
      yield put(partnerInstanceActions.updatePartnerInstanceFailure(error.response?.data?.detail));
    }
  }
}
export function* getSectionByIdSaga({
  payload: { sectionId },
}: {
  payload: {
    sectionId: string;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getSectionById'], {
      sectionId,
    });
    yield put(partnerInstanceActions.getSectionByIdSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.sectionDetailsErrorMessge,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getSectionByIdSuccess(error));
  }
}
export function* getPaginatedSectionLearnersByIdSaga({
  payload,
}: {
  payload: {
    sectionId: string;
    pageSize?: number;
    actualPage: number;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedSectionLearnersById'], payload);
    yield put(partnerInstanceActions.getPaginatedSectionLearnersByIdSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: PARTNER_INSTANCE.sectionDetailsErrorMessge,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getPaginatedSectionLearnersByIdSuccess(error));
  }
}
export function* createOrUpdateSectionSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    let response;
    // We can assume that courses are available because section creation is always being called
    // in the CreateSectionModal, if for some reason we want to use this saga to create sections
    // outside of that modal. Either the component needs to call the courses endpoint or we can call it here.
    const courses = yield select((state) => state.partnerInstance.courses.data);
    const selectedCourseObj = courses.find((course) => course.slug === payload.course);
    const formattedPayload = { ...payload, course: selectedCourseObj };

    if (payload.operation === 'edit') {
      response = yield call([api.partnerInstance, 'editSection'], formattedPayload);
    } else {
      response = yield call([api.partnerInstance, 'createSection'], formattedPayload);
    }

    if (response) {
      yield put(partnerInstanceActions.createOrUpdateSectionSuccess(true));

      if (payload.operation === 'edit') {
        yield call(getSectionByIdSaga, {
          payload: {
            sectionId: response.id,
          },
        });
      } else if (payload?.partnerId && response?.brightspaceCourseSlug && response?.id) {
        // Redirect to section view
        yield put(
          push(
            `/instance/${payload.partnerId}/programs/${response?.brightspaceCourseSlug}/sections/${response.id}`,
          ),
        );
      }
    }
  } catch (error) {
    const errorMessage = getSectionCreationUpdateErrorMessage(error);
    yield put(
      alertActions.setAlert({
        message: errorMessage,
        type: alertTypesConstants.DANGER,
      }),
    );

    yield put(partnerInstanceActions.createOrUpdateSectionFailure(error));
  }
}
export function* getEngagementReportSaga({ payload: partnerId }: { payload: string }): Saga<void> {
  try {
    const { login, viewedContent } = yield all({
      login: call([api.partnerInstance, 'getLoginReport'], partnerId),
      viewedContent: call([api.partnerInstance, 'getViewedContentReport'], partnerId),
    });
    yield put(
      partnerInstanceActions.getEngagementReportSuccess({
        login,
        viewedContent,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.message,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getEngagementReportFailure(error));
  }
}
export function* getLearnerStatusReportSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getLearnerStatusReport'], payload);
    yield put(partnerInstanceActions.getLearnerStatusReportSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.message,
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getLearnerStatusReportFailure(error));
  }
}
export function* getSurveyReportSaga({ payload }: { payload: Record<string, any> }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getSurveyReport'], payload);
    yield put(partnerInstanceActions.getSurveyReportSuccess(response));
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.getSurveyReportFailure(error));
  }
}
export function* getCertificateReportSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getCertificateReport'], payload);
    yield put(partnerInstanceActions.getCertificatesReportSuccess(response));
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.getCertificatesReportFailure(error));
  }
}
export function* getCompletionReportByPartnerSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getCompletionReport'], payload);
    yield put(partnerInstanceActions.getCompletionReportByPartnerSuccess(response));
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.getCompletionReportByPartnerFailure(error));
  }
}
export function* getCompletionReportByProgramSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getCompletionReport'], payload);
    yield put(partnerInstanceActions.getCompletionReportByProgramSuccess(response));
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }

    yield put(partnerInstanceActions.getCompletionReportByProgramFailure(error));
  }
}
export function* downloadInstanceReportSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const { partnerId, name } = payload;
    // Using now here since the saga gets run on every dispatch.
    const now = yield call(moment);
    const formattedNow = yield call([now, 'format'], DATE_FORMAT);
    const response = yield call([api.partnerInstance, 'downloadInstanceReport'], partnerId);
    yield call(downloadFile, response, `${name} ${formattedNow}.xlsx`);
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }
  }
}
export function* downloadProgramReportSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const {
      partner: { partnerId, name },
      course: { slug, shortTitle },
    } = payload;
    // Using now here since the saga gets run on every dispatch.
    const now = yield call(moment);
    const formattedNow = yield call([now, 'format'], DATE_FORMAT);
    const response = yield call([api.partnerInstance, 'downloadProgramReport'], {
      partnerId,
      courseSlug: slug,
    });
    yield call(downloadFile, response, `${name}, ${shortTitle} ${formattedNow}.xlsx`);
  } catch (error) {
    if (error.message !== '404') {
      yield put(
        alertActions.setAlert({
          message: error.message,
          type: DANGER,
        }),
      );
    }
  }
}

export function* addMembersToSectionSaga({
  payload,
}: {
  payload: {
    partnerId: string;
    teamId: string;
    userIds: string[];
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'addMembersToSection'], payload);

    yield put(partnerInstanceActions.addMembersToSectionSuccess(response));
    // After members have been added refresh the learners shown
    yield call(getPaginatedSectionLearnersByIdSaga, {
      payload: {
        sectionId: payload.teamId,
        actualPage: 1,
        includeAttributes: 'course_progress',
      },
    });
    yield put(rtkQApi.util.invalidateTags(['PaginatedSectionLearners', 'Course']));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message:
          error.response?.data?.detail || 'There was an error adding the members to the team.',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.addMembersToSectionFailure(error.response?.data?.detail));
  }
}

export function* addSoloMembersToCourseSaga({
  payload,
}: {
  payload: {
    partnerId: string;
    slug: string;
    soloLearnersPayload: string[];
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'addSoloMembersToCourse'], payload);
    yield put(partnerInstanceActions.addSoloMembersToCourseSuccess(response));

    yield call(getPaginatedCourseSectionsSaga, {
      payload: {
        slug: payload.slug,
        partnerId: payload.partnerId,
        actualPage: 1,
        getSoloLearners: true,
      },
    });
    yield put(rtkQApi.util.invalidateTags(['Course', 'PaginatedCourseSections']));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message:
          error.response?.data?.detail ||
          'There was an error adding the solo learners to the course.',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.addSoloMembersToCourseFailure(error.response?.data?.detail));
  }
}

export function* getGlobalPartnerSaga(): Saga<void> {
  try {
    const domain = devConfig.frontDomain || window.location.hostname;
    const response = yield call([api.partnerInstance, 'getGlobalPartner'], { domain });
    yield put(partnerInstanceActions.getGlobalPartnerSuccess(response));
  } catch (error) {
    yield put(partnerInstanceActions.getGlobalPartnerFailure());
  }
}

export function* setupStripeAccountSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'setupStripeAccount'], payload);

    yield put(partnerInstanceActions.setupStripeAccountSuccess(response));
    // Redirect to onboardingUrl after setting up stripe account
    window.location.replace(response?.onboardingUrl);
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message:
          error.response?.data?.detail || 'There was an error setting up your stripe account',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.setupStripeAccountFailure(error.response?.data?.detail));
  }
}

export function* getStripeAccountSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getStripeAccount'], payload);

    yield put(partnerInstanceActions.getStripeAccountSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.source;

    if (errorMessage !== PartnerStripeErrors.NOT_CONFIGURED) {
      yield put(
        alertActions.setAlert({
          message: errorMessage || 'There was an error fetching your stripe account',
          type: DANGER,
        }),
      );
    }
    yield put(partnerInstanceActions.getStripeAccountFailure(error.response?.data?.source));
  }
}

export function* createStripeInvoiceSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const apiEndpoint =
      payload.invoiceType === invoiceProductType.SEATS_PURCHASE
        ? 'createStripeInvoice'
        : 'createStripeCourseInvoice';
    const response = yield call([api.partnerInstance, apiEndpoint], payload);

    yield put(partnerInstanceActions.createStripeInvoiceSuccess(response));
    // Redirect to invoice payment screen if seat purchase
    if (payload.invoiceType === invoiceProductType.SEATS_PURCHASE) {
      yield put(push(`/instance/${payload.partnerId}/finances/invoices/${response.invoice.id}`));
    }
    if (payload.invoiceType === invoiceProductType.COURSE_PURCHASE && response.alreadyPaid) {
      // Only show this alert if it's a paid course:
      if (response?.amountDue || response?.amountPaid) {
        yield put(
          alertActions.setAlert({
            message: "You've already paid for this course",
            type: SUCCESS,
          }),
        );
      }

      // If it's a free course checkout for an admin enrollment, we want to additionally call these actions
      // after the invoice has been created. Wondering if this should actually be part of the BE though...
      // This is the way we're doing it on the FE currently.
      if (payload?.isAdminEnrollment) {
        const enrollmentType = payload?.metadata?.enrollmentType;

        if (enrollmentType === ADMIN_ENROLLMENT_TYPE.TEAM_ENROLLMENT) {
          const userIds = payload?.metadata?.learners.map((learner) => learner.userId);

          yield put(
            partnerInstanceActions.addMembersToSectionRequest({
              teamId: payload?.metadata?.teamId,
              userIds,
              partnerId: payload?.partnerId,
              generateInvoice: false,
              skipPayment: false,
            }),
          );
        } else if (enrollmentType === ADMIN_ENROLLMENT_TYPE.SOLO_LEARNER_ENROLLMENT) {
          const soloLearnersPayload = {
            team_id: null,
            section_type: SectionType.SOLO,
            user_ids: payload?.metadata?.learners.map((learner) => learner.userId),
          };

          yield put(
            partnerInstanceActions.addSoloMembersToCourseRequest({
              soloLearnersPayload,
              partnerId: payload?.partnerId,
              slug: payload?.courseSlug,
              generateInvoice: false,
              skipPayment: false,
            }),
          );
        }
      }
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error creating the stripe invoice',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.createStripeInvoiceFailure(
        error.response?.data?.detail || 'There was an error creating the stripe invoice',
      ),
    );
  }
}

export function* createStripeCourseInvoiceSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'createStripeCourseInvoice'], payload);

    yield put(partnerInstanceActions.createStripeInvoiceSuccess(response));
    // Redirect to invoice payment screen
    yield put(push(`/instance/${payload.partnerId}/finances/invoices/${response.invoice.id}`));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error creating the stripe invoice',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.createStripeInvoiceFailure(
        error.response?.data?.detail || 'There was an error creating the stripe invoice',
      ),
    );
  }
}

export function* requestSeatsSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'requestSeats'], payload);
    yield put(partnerInstanceActions.requestSeatsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error requesting seats',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.requestSeatsFailure(error.response?.data?.detail));
  }
}

export function* getStripeInvoiceSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerStripeInvoices'], payload);
    yield put(partnerInstanceActions.getStripeInvoiceSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error fetching your invoices',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getStripeInvoiceFailure(error.response?.data?.source));
  }
}

export function* getStripeInvoiceByIdSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerStripeInvoiceById'], payload);
    yield put(partnerInstanceActions.getStripeInvoiceByIdSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: error.response?.data?.detail || 'There was an error fetching your invoice by Id',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.getStripeInvoiceByIdFailure(error.response?.data?.source));
  }
}

export function* getPaginatedStripeInvoiceSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedStripeInvoices'], payload);
    yield put(partnerInstanceActions.getPaginatedStripeInvoiceSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching your invoices',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.getPaginatedStripeInvoiceFailure(error.response?.data?.source),
    );
  }
}

export function* getStripeInvoicesSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedStripeInvoices'], payload);
    yield put(partnerInstanceActions.getStripeInvoicesTableDataSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching your invoices',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.getStripeInvoicesTableDataFailure(error.response?.data?.source),
    );
  }
}

export function* getStripeReceiptsSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPaginatedStripeInvoices'], payload);
    yield put(partnerInstanceActions.getStripeReceiptsTableDataSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching your receipts',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.getStripeReceiptsTableDataFailure(error.response?.data?.source),
    );
  }
}

export function* getPartnerSeatRequestsSaga({ payload }: { payload }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getPartnerSeatRequests'], payload);
    yield put(partnerInstanceActions.getStripePartnerSeatRequestsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching your seat requests',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.getStripePartnerSeatRequestsFailure(error.response?.data?.source),
    );
  }
}

export function* getStripePartnerRevenueSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.partnerInstance, 'getStripePartnerRevenue'], payload);
    yield put(partnerInstanceActions.getStripePartnerRevenueDataSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching your invoices',
        type: DANGER,
      }),
    );
    yield put(
      partnerInstanceActions.getStripePartnerRevenueDataFailure(error.response?.data?.source),
    );
  }
}

export function* reviewSeatRequestSaga({
  payload,
}: {
  payload: {
    payload: Record<string, any>;
  };
}): Saga<void> {
  try {
    const partnerId = payload?.partnerId;
    const reviewAction = payload?.reviewAction;
    const response = yield call([api.partnerInstance, 'reviewSeatRequest'], payload);
    const invoiceId = response?.invoice?.id;
    const parentPartner = yield select(
      (state) => state.partnerInstance.partnerInstance?.data?.parent,
    );

    if (reviewAction === InvoiceReviewAction.APPROVE) {
      yield put(partnerInstanceActions.reviewSeatRequestSuccess(response));
      // Redirect to invoice payment screen
      yield put(push(`/instance/${parentPartner?.partnerId}/finances/invoices/${invoiceId}`));
    } else {
      yield put(partnerInstanceActions.reviewSeatRequestSuccess({ success: true }));
      yield call(getPartnerSeatRequestsSaga, {
        payload: {
          partnerId: parentPartner?.partnerId,
          childPartnerId: partnerId,
        },
      });
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message:
          error.response?.data?.detail || 'There was an error approving or rejecting this request',
        type: DANGER,
      }),
    );
    yield put(partnerInstanceActions.reviewSeatRequestFailure(error.response?.data?.detail));
  }
}

export default function* partnerInstanceSaga(): Saga<void> {
  yield takeLatest(getPartnerInviteTypes.GET_PARTNER_INVITE_REQUEST, getPartnerInviteSaga);
  yield takeLatest(sendPartnerInvitesTypes.SEND_PARTNER_INVITES_REQUEST, sendPartnerInvitesSaga);
  yield takeLatest(getPartnerInstancesTypes.GET_PARTNER_INSTANCES_REQUEST, getPartnerInstancesSaga);
  yield takeLatest(
    getPaginatedPartnerInstancesTypes.GET_PAGINATED_PARTNER_INSTANCES_REQUEST,
    getPaginatedPartnerInstancesSaga,
  );
  yield takeLatest(
    getPartnerInstanceTypes.GET_PARTNER_INSTANCE_REQUEST,
    getPartnerInstanceByPartnerIdSaga,
  );
  yield takeLatest(uploadCSVTypes.UPLOAD_CSV_REQUEST, uploadCSVSaga);
  yield takeLatest(
    getPartnerMembershipsTypes.GET_PARTNER_MEMBERSHIPS_REQUEST,
    getPartnerMembershipsSaga,
  );
  yield takeLatest(
    getPaginatedPartnerMembershipsTypes.GET_PAGINATED_PARTNER_MEMBERSHIPS_REQUEST,
    getPaginatedPartnerMembershipsSaga,
  );
  yield takeLatest(getPartnerCoursesTypes.GET_PARTNER_COURSES_REQUEST, getPartnerCoursesSaga);
  yield takeLatest(
    getPaginatedPartnerCoursesTypes.GET_PAGINATED_PARTNER_COURSES_REQUEST,
    getPaginatedPartnerCoursesSaga,
  );
  yield takeLatest(getPartnerCourseTypes.GET_PARTNER_COURSE_REQUEST, getPartnerCourseSaga);
  yield takeLatest(updatePartnerCourseTypes.UPDATE_PARTNER_COURSE_REQUEST, updatePartnerCourseSaga);
  yield takeLatest(
    getPaginatedCourseSectionsTypes.GET_PAGINATED_COURSE_SECTIONS_REQUEST,
    getPaginatedCourseSectionsSaga,
  );
  yield takeLatest(
    verifyInvitationTokenTypes.VERIFY_INVITATION_TOKEN_REQUEST,
    verifyInvitationTokenSaga,
  );
  yield takeLatest(
    createPartnerInstanceTypes.CREATE_PARTNER_INSTANCE_REQUEST,
    createPartnerInstanceSaga,
  );
  yield takeLatest(
    updatePartnerInstanceTypes.UPDATE_PARTNER_INSTANCE_REQUEST,
    updatePartnerInstanceSaga,
  );
  yield takeLatest(getSectionByIdTypes.GET_SECTION_BY_ID_REQUEST, getSectionByIdSaga);
  yield takeLatest(
    getPaginatedSectionLearnersByIdTypes.GET_PAGINATED_SECTION_LEARNERS_BY_ID_REQUEST,
    getPaginatedSectionLearnersByIdSaga,
  );
  yield takeLatest(
    createOrUpdateSectionTypes.CREATE_OR_UPDATE_SECTION_REQUEST,
    createOrUpdateSectionSaga,
  );
  yield takeLatest(getEngagementReportTypes.GET_ENGAGEMENT_REPORT_REQUEST, getEngagementReportSaga);
  yield takeLatest(
    getLearnerStatusReportTypes.GET_LEARNER_STATUS_REPORT_REQUEST,
    getLearnerStatusReportSaga,
  );
  yield takeLatest(getSurveyReportTypes.GET_SURVEY_REPORT_REQUEST, getSurveyReportSaga);
  yield takeLatest(
    downloadInstanceReportTypes.DOWNLOAD_INSTANCE_REPORT,
    downloadInstanceReportSaga,
  );
  yield takeLatest(downloadProgramReportTypes.DOWNLOAD_PROGRAM_REPORT, downloadProgramReportSaga);
  yield takeLatest(
    getCertificateReportTypes.GET_CERTIFICATE_REPORT_REQUEST,
    getCertificateReportSaga,
  );
  yield takeLatest(
    getCompletionReportByPartnerTypes.GET_COMPLETION_REPORT_BY_PARTNER_REQUEST,
    getCompletionReportByPartnerSaga,
  );
  yield takeLatest(
    getCompletionReportByProgramTypes.GET_COMPLETION_REPORT_BY_PROGRAM_REQUEST,
    getCompletionReportByProgramSaga,
  );
  yield takeLatest(
    createPartnerInstanceMembershipTypes.CREATE_PARTNER_INSTANCE_MEMBERSHIP_REQUEST,
    createPartnerInstanceMembershipSaga,
  );
  yield takeLatest(
    patchPartnerInstanceMembershipTypes.PATCH_PARTNER_INSTANCE_MEMBERSHIP_REQUEST,
    patchPartnerInstanceMembershipSaga,
  );
  yield takeLatest(
    addMembersToSectionTypes.ADD_MEMBERS_TO_SECTION_REQUEST,
    addMembersToSectionSaga,
  );
  yield takeLatest(
    addSoloMembersToCourseTypes.ADD_SOLO_MEMBERS_TO_COURSE_REQUEST,
    addSoloMembersToCourseSaga,
  );
  yield takeLatest(getGlobalPartnerTypes.GET_GLOBAL_PARTNER_REQUEST, getGlobalPartnerSaga);
  yield takeLatest(setupStripeAccountTypes.SETUP_STRIPE_ACCOUNT_REQUEST, setupStripeAccountSaga);
  yield takeLatest(getStripeAccountTypes.GET_STRIPE_ACCOUNT_REQUEST, getStripeAccountSaga);
  yield takeLatest(createStripeInvoiceTypes.CREATE_STRIPE_INVOICE_REQUEST, createStripeInvoiceSaga);
  yield takeLatest(requestSeatsTypes.REQUEST_SEATS_REQUEST, requestSeatsSaga);
  yield takeLatest(getStripeInvoiceTypes.GET_STRIPE_INVOICE_REQUEST, getStripeInvoiceSaga);
  yield takeLatest(
    getStripeInvoiceByIdTypes.GET_STRIPE_INVOICE_BY_ID_REQUEST,
    getStripeInvoiceByIdSaga,
  );
  yield takeLatest(
    getPaginatedStripeInvoiceTypes.GET_PAGINATED_STRIPE_INVOICE_REQUEST,
    getPaginatedStripeInvoiceSaga,
  );
  yield takeLatest(
    getStripeInvoiceTableDataTypes.GET_STRIPE_INVOICE_TABLE_DATA_REQUEST,
    getStripeInvoicesSaga,
  );
  yield takeLatest(
    getStripeReceiptsTableDataTypes.GET_STRIPE_RECEIPTS_TABLE_DATA_REQUEST,
    getStripeReceiptsSaga,
  );
  yield takeLatest(
    getStripePartnerRevenueDataTypes.GET_STRIPE_PARTNER_REVENUE_DATA_REQUEST,
    getStripePartnerRevenueSaga,
  );
  yield takeLatest(reviewSeatRequestTypes.REVIEW_SEAT_REQUEST_REQUEST, reviewSeatRequestSaga);
  yield takeLatest(
    getPartnerSeatRequestTypes.GET_PARTNER_SEAT_REQUESTS_REQUEST,
    getPartnerSeatRequestsSaga,
  );
}
