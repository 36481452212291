import styled from 'styled-components';
import SvgImageWrapper from 'components/SvgImage/SvgImage.style';

export const UserTagWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const UserTagImageContainer = styled.div<{ size: string }>`
  ${({ size }) => `
    width: ${size};
    height: ${size};
    min-width: ${size};
  `}
`;

export const UserTagDetailsContainer = styled.div`
  margin-left: ${({ theme }) => theme.noodera.spacing.x6};
`;

export const UserTagTitle = styled.p<{ customFont?: string; colorTitle?: string }>`
  ${({ theme, customFont }) => customFont || theme.noodera.fontTypes.font4Medium};

  color: ${({ theme, colorTitle }) => colorTitle || theme.palette.neutral.hundred};
  margin-bottom: 0;
`;

export const UserTagSubtitle = styled.div`
  ${({ theme }) => theme.noodera.fontTypes.font5};

  color: ${({ theme }) => theme.palette.neutral.eighty};

  i {
    color: ${({ theme }) => theme.palette.neutral.sixty};
  }
`;

export const StyledSvgImageWrapper = styled(SvgImageWrapper)`
  height: 4.8rem;
  width: 4.8rem;

  & svg,
  & span {
    height: 100%;
    width: 100%;
  }
`;
