// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme/index';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALLER_WIDTH } from 'commons/constants/size';

const {
  palette: { neutral, primary, greyscale },
  noodera: { fontTypes, spacing },
} = getTheme();
export const Container: StatelessFunctionalComponent<{
  isEditing: boolean;
}> = styled.div`
  border: 1px solid ${neutral.forty};
  border-radius: ${spacing.x7};
  ${(props) =>
    props.isEditing ? `padding: ${spacing.x6} ${spacing.x5};` : `border-color:${neutral.forty};`}
  background: ${greyscale.white};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  gap: 8px;
  .MuiChip-root {
    height: 24px;
    background: linear-gradient(90deg, ${primary.hundred} 0%, #367d5d 100%);
  }

  .MuiChip-label {
    padding-left: ${spacing.x5};
    color: ${greyscale.white};
    ${fontTypes.font5Medium}
  }

  && .MuiChip-deleteIcon {
    z-index: 100;
    width: 10px;
    margin-left: -6px;
    margin-right: ${spacing.x6};
    font-size: unset;
  }

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    .MuiChip-deleteIcon {
      height: unset;
    }
  }
`;
export const ActionContainer: StatelessFunctionalComponent<any> = styled.div`
  flex-grow: 1;
`;
export const Input: StatelessFunctionalComponent<any> = styled.input`
  border: none;
  height: 24px;
  width: 100%;
  background: ${greyscale.white};
  caret-color: ${primary.hundred};
  padding: 0;

  &:focus {
    outline: none;
  }
  ${fontTypes.font5Medium}
`;
export const PlaceholderWrapper: StatelessFunctionalComponent<any> = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  cursor: text;
  align-items: center;
`;
export const Placeholder: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font5}
  color: ${neutral.seventy};
  padding-left: ${spacing.x5};
`;
