// @ts-nocheck
import styled from 'styled-components';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes, spacing },
  palette: { neutral, primary },
} = getTheme();

export const HomeTitleContainer = styled.div(() => ({
  display: 'flex',
  gap: spacing.x5,
  alignItems: 'center',
  paddingBottom: spacing.x3,
  [`@media only screen and (max-width: ${TABLET_SMALL_WIDTH}px)`]: {
    padding: spacing.x5,
    paddingBottom: spacing.x4,
    justifyContent: 'space-between',
  },
}));

export const MainTitle = styled.h1`
  ${fontTypes.font3Medium};
  color: ${neutral.hundred};
  margin: 0;
`;

export const NavigationContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.x7,
  '& > svg': {
    fontSize: '24px',
    color: neutral.eighty,
    cursor: 'pointer',
    '&:hover': {
      color: primary.hundred,
    },
  },
}));

export const DropdownContainer: StatelessFunctionalComponent<any> = styled.div`
  border: 1px solid ${(props) => props.theme.palette.primary.hundred};
  color: ${(props) => props.theme.palette.primary.hundred};
  height: 'max-content';
  padding: 2px ${(props) => props.theme.noodera.spacing.x6};
  border-radius: ${(props) => props.theme.noodera.spacing.x5};
`;
