// @ts-nocheck
import * as React from 'react';
import NooderaButton from 'components/NooderaButton/index';
import type { ImagePickerPaginationProps } from './ImagePickerPagination.types';
import { PaginationContainer } from './ImagePickerPagination.styles';

const COVER_IMAGES_NEXT_PAGE = 'Next Page';
const COVER_IMAGES_PREVIOUS_PAGE = 'Previous Page';
const COVER_IMAGES_MAX_PER_PAGE = 30;
const COVER_IMAGES_MAX_PAGE_COUNT = 2;

const ImagePickerPagination = ({
  coverImagesPage,
  onSearchOtherPage,
  amountOfImages,
  wrapperRef,
}: ImagePickerPaginationProps) => {
  const prevEnabled = coverImagesPage > 1;
  const nextEnabled =
    coverImagesPage < COVER_IMAGES_MAX_PAGE_COUNT && amountOfImages === COVER_IMAGES_MAX_PER_PAGE;

  const handleClick = (increment: number) => (): void => {
    onSearchOtherPage(coverImagesPage + increment);

    if (wrapperRef) {
      wrapperRef.scrollTo(0, 0);
    }
  };

  return (
    <PaginationContainer>
      <NooderaButton.CTA size='medium' isDisabled={!prevEnabled} onClick={handleClick(-1)}>
        {COVER_IMAGES_PREVIOUS_PAGE}
      </NooderaButton.CTA>
      <span className='text-content-body'> {coverImagesPage}</span>
      <NooderaButton.CTA size='medium' isDisabled={!nextEnabled} onClick={handleClick(1)}>
        {COVER_IMAGES_NEXT_PAGE}
      </NooderaButton.CTA>
    </PaginationContainer>
  );
};

export default ImagePickerPagination;
