import { call, put, takeLatest, select } from 'redux-saga/effects';
import type { SagaIterator } from 'redux-saga';
import api, { rtkQApi } from 'lib/Api';

import { alertTypesConstants } from 'commons/constants';
import { SECTION } from 'commons/constants/section';
import type { CreateSectionPayload } from 'commons/types/sectionTypes';

import alertActions from 'ducks/alert/actions';
import { inviteMembersSaga } from 'ducks/team/saga';
import { teamActions } from '../team/actions';
import { sectionActions } from './actions';

import {
  createSectionTypes,
  getMySectionsTypes,
  getSectionPreviewTypes,
  getSectionsTypes,
  joinSectionTypes,
  joinSelfEnrolledSectionTypes,
} from './types';

const { DANGER } = alertTypesConstants;

type GetSectionSagaPayload = {
  payload: number;
};
type CreateSectionSagaPayload = {
  payload: CreateSectionPayload;
};

export function* getMySectionsSaga(): SagaIterator<void> {
  try {
    const response = yield call([api.section, 'getMySections']);
    yield put(sectionActions.getMySectionsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: SECTION.sectionsErrorMessage,
        type: DANGER,
      }),
    );
    yield put(sectionActions.getMySectionsFailure(error));
  }
}
export function* getSectionPreviewSaga({ payload }: GetSectionSagaPayload): SagaIterator<void> {
  try {
    const userId = yield select((state) => state.user.id);
    // @ts-ignore
    const response = yield call([api.section, 'getSectionPreview'], {
      sectionId: Number(payload),
      userId,
    });
    yield put(sectionActions.getSectionPreviewSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: SECTION.sectionsErrorMessage,
        type: DANGER,
      }),
    );
    yield put(sectionActions.getSectionPreviewFailure(error));
  }
}
export function* createSectionSaga({ payload }: CreateSectionSagaPayload): SagaIterator<void> {
  try {
    const response = yield call([api.section, 'createSection'], payload);
    yield put(sectionActions.createSectionSuccess(!!response));
    yield put(teamActions.selectTeam(null));
    yield put(sectionActions.selectSection(response));
    yield call(getSectionPreviewSaga, {
      payload: response?.id,
    });

    if (response && payload?.inviteMembers?.length) {
      const inviteMembersPayload = {
        payload: {
          invitees: payload?.inviteMembers,
          teamId: response?.id,
        },
      };
      yield call(inviteMembersSaga, inviteMembersPayload);
    }
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: SECTION.createSectionErrorMessage,
        type: DANGER,
      }),
    );
    yield put(sectionActions.createSectionFailure(error));
  }
}
export function* getSectionsSaga({ payload }: any): SagaIterator<void> {
  try {
    const response = yield call([api.section, 'getSections'], payload);
    yield put(sectionActions.getSectionsSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: SECTION.sectionsErrorMessage,
        type: DANGER,
      }),
    );
    yield put(sectionActions.getSectionsFailure(error));
  }
}
export function* joinSectionSaga({ payload }: any): SagaIterator<void> {
  const { selectedSection } = payload;

  try {
    const response = yield call([api.section, 'joinSection'], payload);
    yield put(sectionActions.joinSectionSuccess(response));
    // Invalidate the shopping cart tag to refetch and the just enrolled course on success of this saga
    yield put(
      rtkQApi.util.invalidateTags(['ShoppingCart', 'CourseWaitlist', 'UsersCourseWaitlists']),
    );

    yield put(teamActions.selectTeam(null));
    yield put(sectionActions.selectSection(selectedSection));
    yield call(getSectionPreviewSaga, {
      payload: selectedSection?.id,
    });
  } catch (error) {
    yield put(sectionActions.joinSectionFailure(error));
  }
}

export interface JoinSelfEnrolledSectionPayload {
  payload: {
    userId: number;
    selectedSectionId: number | string;
    partnerId: string;
    courseSlug: string;
    sectionType: string;
  };
}

export function* joinSelfEnrolledSectionSaga({
  payload,
}: JoinSelfEnrolledSectionPayload): SagaIterator<void> {
  const { selectedSectionId } = payload;

  try {
    const response = yield call([api.team, 'joinSelfEnrolledSection'], payload);
    const addedToSectionId = response?.data?.[0]?.id;

    yield put(sectionActions.joinSelfEnrolledSectionSuccess(response));

    if (selectedSectionId || addedToSectionId) {
      yield put(teamActions.selectTeam(null));
      yield put(sectionActions.selectSection(selectedSectionId || addedToSectionId));
      yield call(getSectionPreviewSaga, {
        payload: selectedSectionId || addedToSectionId,
      });
    }
  } catch (error) {
    yield put(sectionActions.joinSelfEnrolledSectionFailure(error));
  }
}

export default function* sectionSaga(): SagaIterator<void> {
  yield takeLatest(getMySectionsTypes.GET_MY_SECTIONS_REQUEST, getMySectionsSaga);
  // @ts-ignore
  yield takeLatest(getSectionPreviewTypes.GET_SECTION_PREVIEW_REQUEST, getSectionPreviewSaga);
  // @ts-ignore
  yield takeLatest(createSectionTypes.CREATE_SECTION_REQUEST, createSectionSaga);
  yield takeLatest(getSectionsTypes.GET_SECTIONS_REQUEST, getSectionsSaga);
  yield takeLatest(joinSectionTypes.JOIN_SECTION_REQUEST, joinSectionSaga);
  yield takeLatest(
    // @ts-ignore
    joinSelfEnrolledSectionTypes.JOIN_SELF_ENROLLED_SECTION_REQUEST,
    joinSelfEnrolledSectionSaga,
  );
}
