import {
  getStreamTypes,
  getTeamFeedPostsTypes,
  getUserFeedPostsTypes,
  getUserTokenTypes,
  updateActivityCommentsTypes,
  getUserNotificationsTypes,
  getMoreCommentsTypes,
  getMorePostsTypes,
} from './types';

export const initialState = {
  isLoading: false,
  error: '',
  feedPosts: {
    data: [],
    isLoading: false,
    error: '',
  },
  createCommentSuccess: false,
  isCreatePostModalOpen: false,
  notifications: {
    isLoading: false,
    data: null,
    error: '',
  },
};
type Action = {
  type: string;
  payload?: any;
};

const getStreamReducer = (state: any = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case getUserTokenTypes.GET_USER_TOKEN_REQUEST: {
      return { ...state, isLoading: true };
    }

    case getUserTokenTypes.GET_USER_TOKEN_SUCCESS: {
      return { ...state, isLoading: false };
    }

    case getUserTokenTypes.GET_USER_TOKEN_FAILURE: {
      return { ...state, isLoading: false, error: payload };
    }

    case getUserFeedPostsTypes.GET_USER_FEED_POSTS_REQUEST:
    case getMorePostsTypes.GET_MORE_POSTS_REQUEST:
    case getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_REQUEST: {
      return { ...state, feedPosts: { ...state.feedPosts, isLoading: true, error: '' } };
    }

    case getUserFeedPostsTypes.GET_USER_FEED_POSTS_SUCCESS:
    case getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_SUCCESS: {
      return {
        ...state,
        feedPosts: {
          isLoading: false,
          isMore: !!payload?.next,
          data: payload?.results,
          error: '',
        },
      };
    }

    case getMorePostsTypes.GET_MORE_POSTS_SUCCESS: {
      return {
        ...state,
        feedPosts: {
          isLoading: false,
          isMore: !!payload?.next,
          data: state.feedPosts.data.concat(payload?.results),
          error: '',
        },
      };
    }

    case getUserFeedPostsTypes.GET_USER_FEED_POSTS_FAILURE:
    case getTeamFeedPostsTypes.GET_TEAM_FEED_POSTS_FAILURE: {
      return { ...state, feedPosts: { ...state.feedPosts, isLoading: false, error: payload } };
    }

    case getMoreCommentsTypes.GET_MORE_COMMENTS_REQUEST: {
      return {
        ...state,
        feedPosts: {
          ...state.feedPosts,
          data: state.feedPosts.data?.map((feedPost) =>
            feedPost.id === payload?.activityId
              ? {
                  ...feedPost,
                  comments: {
                    isLoading: true,
                    results: feedPost.comments.results,
                  },
                }
              : feedPost,
          ),
        },
      };
    }

    case getMoreCommentsTypes.GET_MORE_COMMENTS_SUCCESS: {
      return {
        ...state,
        feedPosts: {
          ...state.feedPosts,
          data: state.feedPosts.data?.map((feedPost) =>
            feedPost.id === payload?.activityId
              ? {
                  ...feedPost,
                  comments: {
                    isLoading: false,
                    results: feedPost.comments.results.concat(payload?.results),
                  },
                }
              : feedPost,
          ),
        },
      };
    }

    case updateActivityCommentsTypes.UPDATE_ACTIVITY_COMMENTS_SUCCESS: {
      return {
        ...state,
        feedPosts: {
          ...state.feedPosts,
          data: state.feedPosts.data?.map((feedPost) =>
            feedPost.id === payload?.activityId
              ? {
                  ...feedPost,
                  comments: {
                    results: payload?.results
                      .concat(feedPost.comments.results)
                      .filter(
                        (value, index, self) => self.map((x) => x.id).indexOf(value.id) === index,
                      ),
                  },
                }
              : feedPost,
          ),
        },
      };
    }

    case getStreamTypes.TOGGLE_CREATE_POST_MODAL: {
      return { ...state, isCreatePostModalOpen: payload };
    }

    case getUserNotificationsTypes.GET_USER_NOTIFICATIONS_REQUEST: {
      return { ...state, notifications: { ...initialState.notifications, isLoading: true } };
    }

    case getUserNotificationsTypes.GET_USER_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: {
          isLoading: false,
          data: payload,
        },
      };
    }

    case getUserNotificationsTypes.GET_USER_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notifications: { ...initialState.notifications, isLoading: false, error: payload },
      };
    }

    default:
      return state;
  }
};

export default getStreamReducer;
