// @ts-nocheck
/* eslint-disable no-bitwise */
import { NOODLE_URL, FIND_URL_REGEX } from 'commons/constants';
import { mainTitleAllowedRoutes } from 'commons/constants/general';
import { getUtmContent } from 'utils/slug/index';

/**
 * Finds all the URLs in a given string
 *
 * @param content
 * @returns {null|*}
 */
const getUrls = (content: string | null): string[] | null => {
  if (content) {
    return content.match(FIND_URL_REGEX);
  }

  return null;
};

/**
 * This is for all the URLs no matter how they start, http, www, or nothing
 * it will always go to noodle.com then split by / and from there we can be sure
 * the params will be in order being for e.g.
 * [0] -> 'noodle.com' - [1] -> 'courses' - [2] -> 'slug'
 *
 * @param url
 * @param paramNumber
 * @returns {*}
 */
const getNoodleUrlParam = (url: string, paramNumber: number) =>
  url.slice(url.indexOf(NOODLE_URL)).split('/')[paramNumber];

/**
 * Checks if the URL belongs to noodle and returns what type it is
 * for e.g. school, profile, courses
 *
 * @param url
 * @returns {boolean}
 */
const getUrlType = (url: string): string | null =>
  url.includes(NOODLE_URL) ? getNoodleUrlParam(url, 1) : null;

const getLastPartOfUrl = (url: string): string => url.substring(url.lastIndexOf('/') + 1);

const isMainTitleAllowedRoute = (pathname: string): boolean =>
  mainTitleAllowedRoutes.some((path) => pathname.includes(path));

/**
 * This method sets URL query parameters using the proper URL JS constructor
 * It will preserve parameters that already exist on the URL unless `overwrite` prop is set to true
 * It will return a string URL ready to use
 *
 * @param url - The base URL you want to use as a string
 * @param urlParams - An object containing a [key, value] set of query parameters
 * @param overwrite - A flag that allows the method to overwrite existing query parameters
 * @returns {string} - A string containing the URL with the defined query parameters
 */
const setUrlParams = ({
  urlParams,
  url,
  overwrite,
}: {
  urlParams: Record<string, any>;
  url: string;
  overwrite?: boolean;
}): string => {
  const urlObj = new URL(url);
  Object.entries(urlParams).forEach((urlParamEntry) => {
    const [urlParamKey, urlParamValue] = urlParamEntry;

    if (!urlObj.searchParams.has(urlParamKey) || overwrite) {
      urlObj.searchParams.set(urlParamKey, String(urlParamValue));
    }
  });
  return urlObj.toString();
};

/**
 * This method sets UTM URL query parameters mainly for ProgramCards
 * It will preserve parameters that already exist on the URL
 * It will return a string URL ready to use
 *
 * @param url - The base URL you want to use as a string
 * @param programTaxonomy - The program taxonomy - optional
 * @param pathname - The URL pathname to be used in UTM params
 * @param utmTerm - The UTM term to be used in utm_term query parameter
 * @returns {string} - A string containing the URL with the UTM query parameters
 */
const getUtmUrl = ({
  url,
  programTaxonomy,
  pathname,
  utmTerm,
}: {
  url: string;
  programTaxonomy: string;
  pathname: string;
  utmTerm: string;
}): string => {
  const urlParams = {
    utm_source: 'noodle_linkout_prospect',
    utm_medium: 'affiliate_cpl',
    utm_campaign: programTaxonomy || 'university_landing_page',
    utm_content: getUtmContent(pathname),
    utm_term: utmTerm,
  };
  return setUrlParams({
    urlParams,
    url,
  });
};

/**
 * This method navigates to a URL with a page reload
 *
 * @param url - The base URL you want to navigate to with a page reload
 */
const navigateWithPageReload = (url: { url: string }): void => {
  window.location.assign(url);
};

/**
 * Redirects to the provided URL with UTM query strings appended.
 *
 * @param url - The URL to redirect to.
 * @param utmParams - An object containing UTM parameters as key-value pairs from the result of getSessionStorageData(UTM_VALUES).
 */
const redirectWithUtmQueryStrings = (
  url: string,
  utmParams: Record<string, string>,
  preprendedSymbol: '?' | '&' | '' = '?',
): void => {
  const utmQueryString = utmParams ? new URLSearchParams(utmParams).toString() : null;
  window.location.replace(`${url}${utmQueryString ? `${preprendedSymbol}${utmQueryString}` : ''}`);
};

export {
  getNoodleUrlParam,
  getUrls,
  getUrlType,
  getLastPartOfUrl,
  getUtmUrl,
  isMainTitleAllowedRoute,
  setUrlParams,
  navigateWithPageReload,
  redirectWithUtmQueryStrings,
};
