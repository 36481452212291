/* eslint-disable import/no-cycle */
import Axios from 'axios';
import get from 'lodash.get';
import humps from 'humps';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiUrl } from 'settings/';
import {
  CartItemInterface,
  DeletedCartItemResponseInterface,
  DeletedCouponResponseInterface,
  DonationAskData,
  NOODLE_CART_SESSION_ID_COOKIE_KEY,
  ShoppingCartCheckoutInterface,
  ShoppingCartInterface,
  ShoppingCartInvoiceInterface,
} from 'commons/types/shoppingCartTypes';
import {
  AddOrRemoveUsersInterface,
  CourseWaitlistInterface,
  CourseWaitlistOperationEnum,
  UsersCourseWaitlistsInterface,
} from 'commons/types/courseWaitlistTypes';
import { Course as CourseType } from 'commons/types/courseTypes';
import { CreateStripeCourseInvoiceResponse } from 'commons/types/paymentTypes';
import { CustomerInterface, InvoiceInterface } from 'commons/types/stripeTypes';
import { InstructorInterface } from 'commons/types/instructorTypes';
import { Section as SectionType } from 'commons/types/sectionTypes';
import { ContentProviderInterface } from 'commons/types/contentProviderTypes';
import type { TeamUserFlags } from 'commons/types';

// Legacy API service imports
import AdminGeneral from './AdminGeneral';
import AdminNoodling from './AdminNoodling';
import AdminPartnerProgram from './AdminPartnerProgram';
import AdminScheduler from './AdminScheduler';
import AdminUser from './AdminUser';
import Bookmark from './Bookmark';
// eslint-disable-next-line import/no-cycle
import Counselor from './Counselor';
import Course from './Course';
import Explore from './Explore';
// eslint-disable-next-line import/no-cycle
import GetStream from './GetStream';
import GlobalSearch from './GlobalSearch';
import Group from './Group';
import LandingPage from './LandingPage';
import Like from './Like';
import Noodling from './Noodling';
import Onboarding from './Onboarding';
import PartnerInstance from './PartnerInstance';
import PartnerPrograms from './PartnerPrograms';
import Payment from './Payment';
import Post from './Post';
import Product from './Product/index';
import Scheduler from './Scheduler';
import Section from './Section';
import Survey from './Survey';
import TeacherAssistant from './TeacherAssistant';
import Team from './Team';
import University from './University';
// eslint-disable-next-line import/no-cycle
import User from './User';
import UserConnection from './UserConnection';
import { getCookie, getPartnerCookie } from 'utils/cookies';
import { getMembersFromResponse } from 'utils/apiHelpers';
import { isAuthenticated } from 'utils/customHooks/auth';
import JsonApi from 'utils/jsonApi';
import { getAppSettings } from 'utils/privateLabel/index';
import { getSessionStorageData } from 'utils/sessionStorage';
import { UTM_VALUES } from 'utils/heap';
import store from 'ducks/store';

export const axios = Axios.create({
  baseURL: apiUrl.base,
});

axios.interceptors.request.use(async (req) => {
  const { getState } = store;
  const { partnerInstance } = getState();
  const appSettings = getAppSettings();

  if (!req.headers['X-Noodle-Partner-Id']) {
    req.headers['X-Noodle-Partner-Id'] = (
      appSettings || partnerInstance.globalPartner.data
    )?.partnerId;
  }

  const partnerId = partnerInstance?.globalPartner?.data?.partnerId;
  const token = getPartnerCookie('access_token', partnerId);

  if (token && isAuthenticated(partnerId)) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

const jsonApi = new JsonApi(axios);

const queryBaseSettings = {
  baseUrl: apiUrl.base,
  prepareHeaders: (headers, { getState }) => {
    const queryParams = new URLSearchParams(window.location.search);

    const { partnerInstance }: any = getState();
    const appSettings = getAppSettings();

    const noodleCartSessionId =
      queryParams.get('cartSessionId') || getCookie(NOODLE_CART_SESSION_ID_COOKIE_KEY);

    if (!headers.get('X-Noodle-Partner-Id')) {
      headers.set(
        'X-Noodle-Partner-Id',
        `${appSettings?.partnerId || partnerInstance.globalPartner.data?.partnerId}`,
      );
    }

    if (!headers.get('X-Noodle-Cart-Session-Id') && noodleCartSessionId) {
      headers.set('X-Noodle-Cart-Session-Id', noodleCartSessionId);
    }

    const partnerId = partnerInstance?.globalPartner?.data?.partnerId;
    const token = getPartnerCookie('access_token', partnerId);

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
  },
};

// RTK-Query recommends only one createApi across the whole app if possible.
// That would mean adding all API calls in this file.
// RTK-Query will handle the state almost entirely, there's no need to create
// new reducers, actions or sagas unless we need to persist specific data,
// then we can create a slice.
export const rtkQApi = createApi({
  reducerPath: 'rtkQApi',
  baseQuery: fetchBaseQuery(queryBaseSettings),
  tagTypes: [
    'ChildInstancesCourseStatus',
    'NetworkCourses',
    'CoursesFromNetwork',
    'Course',
    'Team',
    'PartnerPaidSeatPrice',
    'Partner',
    'Section',
    'ShoppingCart',
    'StripeInvoice',
    'CourseWaitlist',
    'UsersCourseWaitlists',
    'OwnUserStripeCustomer',
    'DonationAsks',
    'PaginatedSectionLearners',
    'PaginatedCourseSections',
    'PaginatedInstructors',
    'PaginatedContentProviders',
  ],
  endpoints: (builder) => ({
    getChildInstancesCourseStatus: builder.query<any, string>({
      query: (courseContentfulId) => `courses/${courseContentfulId}/instances`,
      providesTags: ['ChildInstancesCourseStatus'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getPartnerPaidSeatPrice: builder.query<any, string>({
      query: (partnerId) => `partners/${partnerId}/stripe/seat_price`,
      providesTags: ['PartnerPaidSeatPrice'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    updateChildInstanceCourses: builder.mutation({
      query: ({ data, courseContentfulId, isOwnCourse, lms }) => ({
        url: `courses/${courseContentfulId}/instances`,
        method: 'POST',
        body: humps.decamelizeKeys({ data, isOwnCourse, lms }),
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
      invalidatesTags: ['ChildInstancesCourseStatus'],
    }),
    shareCourseToFedNetwork: builder.mutation<any, { slug: string; priceInCents: number }>({
      query: ({ slug, priceInCents }) => ({
        url: `courses/${slug}/share`,
        method: 'POST',
        body: { price_in_cents: priceInCents },
      }),
      invalidatesTags: ['Course'],
      transformResponse: (response: any, meta, { slug }) =>
        humps.camelizeKeys({ ...response?.data, slug }),
    }),
    unshareCourseFromFedNetwork: builder.mutation<any, { slug: string }>({
      query: ({ slug }) => ({
        url: `courses/${slug}/unshare`,
        method: 'POST',
      }),
      invalidatesTags: ['Course'],
      transformResponse: (response: any, meta, { slug }) =>
        humps.camelizeKeys({ ...response?.data, slug }),
    }),
    getNetworkCourses: builder.query<any, any>({
      query: ({ partnerId, pageSize = 10, actualPage }) => {
        const queryParams = new URLSearchParams({
          partnerId,
          'page[size]': pageSize.toString(),
          'page[number]': actualPage.toString(),
        });
        return `/v2/courses/federated_network/market?${queryParams.toString()}`;
      },
      providesTags: ['NetworkCourses'],
      transformResponse: (response: any) => ({
        ...response,
        data: humps.camelizeKeys(response?.data),
      }),
    }),
    updateCourseNetworkPrice: builder.mutation<
      any,
      {
        slug: string;
        price: any;
        contentfulCourseId: string;
      }
    >({
      query: ({ slug, price, contentfulCourseId }) => ({
        url: `v2/courses/${slug}/set_network_price`,
        method: 'PATCH',
        body: { price_in_cents: price, contentful_id: contentfulCourseId },
      }),
      invalidatesTags: ['Course'],
      transformResponse: (response: any, meta, { slug }) =>
        humps.camelizeKeys({ ...response?.data, slug }),
    }),
    uploadAddLearnersCSV: builder.mutation<
      any,
      {
        file: File;
        partnerId: string;
        teamId?: string;
        slug?: string;
      }
    >({
      query: ({ file, partnerId, teamId, slug }) => {
        const formData = new FormData();
        formData.append('members', file);

        return {
          url:
            !slug && teamId
              ? `teams/${teamId}/members/upload_csv?partner_id=${partnerId}`
              : `teams/courses/${slug}/upload_csv?partner_id=${partnerId}`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getPartnerCoursePreview: builder.query<
      CourseType,
      {
        partnerId?: string;
        slug: string;
        isNetworkPreview?: boolean;
        excludeAttributes?: string;
        include?: string;
      }
    >({
      query: ({ partnerId, slug, isNetworkPreview, excludeAttributes, include }) => {
        const queryParams = new URLSearchParams();
        if (partnerId) {
          queryParams.append('partner_id', partnerId);
        }
        if (excludeAttributes) {
          queryParams.append('exclude_attributes', excludeAttributes);
        }
        if (include) {
          queryParams.append('include', include);
        }
        return isNetworkPreview
          ? `/v2/courses/${slug}/federated_network?${queryParams.toString()}`
          : `/v2/courses/${slug}?${queryParams.toString()}`;
      },
      providesTags: ['Course'],
      transformResponse: (response: any) =>
        humps.camelizeKeys({
          ...response?.data,
          instructorsCollection: response?.data?.instructorsCollection?.items || [],
        }),
    }),
    getCoursesFromNetwork: builder.query<any, { pageSize?: number; pageNumber?: number }>({
      query: ({ pageSize = 10, pageNumber }) => {
        const queryParams = new URLSearchParams({
          'page[size]': pageSize.toString(),
          'page[number]': pageNumber.toString(),
        });
        return `v2/courses/federated_network/added_from_network?${queryParams.toString()}`;
      },
      providesTags: ['CoursesFromNetwork'],
      transformResponse: (response: any) => ({
        ...response,
        data: humps.camelizeKeys(response?.data),
      }),
    }),
    updatePartnerCourse: builder.mutation<
      any,
      {
        partnerId: string;
        slug: string;
        priceInCents: number | string;
        contentfulCourseId: string;
      }
    >({
      query: ({ partnerId, slug, priceInCents, contentfulCourseId }) => ({
        url: `/v2/courses/${slug}?include=sections&partner_id=${partnerId}`,
        method: 'PATCH',
        body: { data: { price_in_cents: priceInCents, contentful_id: contentfulCourseId } },
      }),
      invalidatesTags: ['Course'],
      transformResponse: (response: any) => {
        const sections = response?.data?.sections?.map((section) => ({
          ...section?.attributes,
          id: section?.id,
        }));

        return { ...humps.camelizeKeys(response?.data), sections };
      },
    }),
    getTeamPreview: builder.query<any, any>({
      queryFn: async ({ teamId, userId, fetchAcceptedMembers }) => {
        const adminResponse = await axios.get(`/teams/${teamId}?fields[team]=user_id`);
        const adminResponseData = humps.camelizeKeys(adminResponse?.data);

        const acceptedMembers = fetchAcceptedMembers ? ',accepted_members' : '';
        const teamResponse = await axios.get(`/teams/${teamId}?include=admin${acceptedMembers}`);
        const teamResponseData = humps.camelizeKeys(teamResponse?.data);

        const team = get(teamResponseData, 'data.attributes', {});
        const userFlags: TeamUserFlags = get(teamResponseData, 'data.userFlags', {});

        const adminId = get(adminResponseData, `entities.team[${teamId}].userId`, -1);
        const userIsAdmin = adminId === Number(userId);
        return {
          data: {
            id: Number(teamId),
            ...team,
            ...getMembersFromResponse(teamResponseData, userIsAdmin),
            userFlags,
          },
        };
      },
      providesTags: ['Team'],
    }),
    getPartnerById: builder.query<any, { partnerId: string }>({
      query: ({ partnerId }) => `/partners/${partnerId}`,
      providesTags: ['Partner'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getSectionById: builder.query<SectionType, { sectionId: number }>({
      query: ({ sectionId }) => `/sections/${sectionId}`,
      providesTags: ['Section'],
      transformResponse: (response: any) =>
        humps.camelizeKeys({
          ...response?.data.attributes,
          id: response?.data?.id,
        }),
    }),
    getShoppingCart: builder.query<ShoppingCartInterface, { isTemporary: boolean }>({
      query: ({ isTemporary }) => `/cart?is_temporary=${isTemporary}`,
      providesTags: ['ShoppingCart'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    addCartItemToShoppingCart: builder.mutation<
      CartItemInterface,
      {
        shoppingCartId: number;
        externalId: string;
        stripeProductId: string;
        quantity: number;
        cartItemMetadata: any;
        itemType: string;
      }
    >({
      query: ({
        externalId,
        quantity,
        cartItemMetadata,
        shoppingCartId,
        stripeProductId,
        itemType,
      }) => ({
        url: `/cart/${shoppingCartId}/cart_items`,
        method: 'POST',
        body: {
          external_id: externalId,
          stripe_product_id: stripeProductId,
          quantity,
          cart_item_metadata: humps.decamelizeKeys(cartItemMetadata),
          item_type: itemType,
        },
      }),
      invalidatesTags: ['ShoppingCart', 'DonationAsks'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    updateCartItemFromShoppingCart: builder.mutation<
      CartItemInterface | DeletedCartItemResponseInterface,
      { shoppingCartId: number; cartItemId: number; quantity?: number; isDepositPayment?: boolean }
    >({
      query: ({ shoppingCartId, cartItemId, quantity = null, isDepositPayment = null }) => ({
        url: `/cart/${shoppingCartId}/cart_items/${cartItemId}`,
        method: 'PATCH',
        body: {
          quantity,
          is_deposit_payment: isDepositPayment,
        },
      }),
      invalidatesTags: ['ShoppingCart'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    deleteCartItemFromShoppingCart: builder.mutation<
      CartItemInterface | DeletedCartItemResponseInterface,
      { shoppingCartId: number; cartItemId: number }
    >({
      query: ({ shoppingCartId, cartItemId }) => ({
        url: `/cart/${shoppingCartId}/cart_items/${cartItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ShoppingCart', 'DonationAsks'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    deleteCouponFromShoppingCart: builder.mutation<
      DeletedCouponResponseInterface,
      { shoppingCartId: number; customerPromotionCode: string }
    >({
      query: ({ shoppingCartId, customerPromotionCode }) => ({
        url: `/cart/${shoppingCartId}/coupons/${customerPromotionCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ShoppingCart'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    createInvoiceFromShoppingCart: builder.mutation<
      ShoppingCartInvoiceInterface,
      { shoppingCartId: number; stripeCustomerId: string }
    >({
      query: ({ shoppingCartId, stripeCustomerId }) => {
        const utmParams = getSessionStorageData(UTM_VALUES);

        return {
          url: `/cart/${shoppingCartId}/stripe/invoice`,
          method: 'POST',
          body: {
            utm_parameters: utmParams,
            stripe_customer_id: stripeCustomerId,
          },
        };
      },
      invalidatesTags: ['ShoppingCart', 'StripeInvoice', 'DonationAsks'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    completeShoppingCartCheckout: builder.mutation<
      ShoppingCartCheckoutInterface,
      { shoppingCartId: number; invoiceId: string }
    >({
      query: ({ shoppingCartId, invoiceId }) => ({
        url: `/cart/${shoppingCartId}/checkout`,
        method: 'POST',
        body: {
          invoice_id: invoiceId,
        },
      }),
      invalidatesTags: ['ShoppingCart', 'DonationAsks'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    applyCouponToShoppingCart: builder.mutation<
      CartItemInterface,
      { shoppingCartId: number; couponCode: string }
    >({
      query: ({ shoppingCartId, couponCode }) => ({
        url: `/cart/${shoppingCartId}/apply_coupon`,
        method: 'POST',
        body: {
          promotion_code: couponCode,
        },
      }),
      invalidatesTags: ['ShoppingCart'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getCourseWaitlist: builder.query<CourseWaitlistInterface, void>({
      query: (courseContentfulId) => `/course_waitlist/${courseContentfulId}`,
      providesTags: ['CourseWaitlist'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getUsersCourseWaitlistRecords: builder.query<
      UsersCourseWaitlistsInterface,
      { courseContentfulIds?: string[] }
    >({
      query: ({ courseContentfulIds }) => {
        const queryParams = new URLSearchParams();
        if (courseContentfulIds) {
          queryParams.append('course_contentful_ids', courseContentfulIds.toString());
        }
        return `/course_waitlist/my_waitlists?${queryParams}`;
      },
      providesTags: ['UsersCourseWaitlists'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    addUserToCourseWaitlist: builder.mutation<
      CourseWaitlistInterface,
      {
        courseContentfulId: string;
        userId: number;
      }
    >({
      query: ({ courseContentfulId, userId }) => ({
        url: `/course_waitlist/${courseContentfulId}`,
        method: 'POST',
        body: {
          users: [{ user_id: userId, add_or_remove_user: CourseWaitlistOperationEnum.Add }],
        },
      }),
      invalidatesTags: ['CourseWaitlist', 'UsersCourseWaitlists'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    addUsersToCourseWaitlist: builder.mutation<
      CourseWaitlistInterface,
      {
        courseContentfulId: string;
        users: AddOrRemoveUsersInterface;
      }
    >({
      query: ({ courseContentfulId, users }) => ({
        url: `/course_waitlist/${courseContentfulId}`,
        method: 'POST',
        body: {
          users,
        },
      }),
      invalidatesTags: ['CourseWaitlist', 'UsersCourseWaitlists'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    removeUserFromCourseWaitlist: builder.mutation<
      CourseWaitlistInterface,
      {
        courseContentfulId: string;
        userId: number;
      }
    >({
      query: ({ courseContentfulId, userId }) => ({
        url: `/course_waitlist/${courseContentfulId}`,
        method: 'POST',
        body: {
          users: [{ user_id: userId, add_or_remove_user: CourseWaitlistOperationEnum.Remove }],
        },
      }),
      invalidatesTags: ['CourseWaitlist', 'UsersCourseWaitlists'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    removeUsersFromCourseWaitlist: builder.mutation<
      CourseWaitlistInterface,
      {
        courseContentfulId: string;
        users: AddOrRemoveUsersInterface;
      }
    >({
      query: ({ courseContentfulId, users }) => ({
        url: `/course_waitlist/${courseContentfulId}`,
        method: 'POST',
        body: {
          users,
        },
      }),
      invalidatesTags: ['CourseWaitlist', 'UsersCourseWaitlists'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    getPartnerStripeInvoiceById: builder.query<
      InvoiceInterface,
      {
        partnerId: string;
        invoiceId: string;
        isFederatedNetwork: boolean;
        publisherPartnerId: string;
      }
    >({
      query: ({ partnerId, invoiceId, isFederatedNetwork, publisherPartnerId }) => {
        const queryParams = new URLSearchParams();

        if (isFederatedNetwork) {
          queryParams.set('is_federated_network_course', String(isFederatedNetwork));
          queryParams.set('publisher_partner_id', publisherPartnerId);
        }

        return `/partners/${partnerId}/stripe/invoices/${invoiceId}?${
          queryParams ? queryParams.toString() : ''
        }`;
      },
      providesTags: ['StripeInvoice'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    /* This doesn't take into account admin purchases, this is solely for user self enrollments */
    createStripeCourseInvoice: builder.mutation<
      CreateStripeCourseInvoiceResponse,
      {
        partnerId: string;
        courseSlug: string;
        isAddedFromFedNet: boolean;
        publisherPartnerId: string;
        stripeCustomerId: string;
        quantity?: number;
      }
    >({
      query: ({
        partnerId,
        courseSlug,
        isAddedFromFedNet,
        publisherPartnerId,
        stripeCustomerId,
        quantity = 1,
      }) => {
        const queryParams = new URLSearchParams();
        const utmParams = getSessionStorageData(UTM_VALUES);

        if (isAddedFromFedNet) {
          queryParams.set('is_federated_network_course', String(isAddedFromFedNet));
          queryParams.set('publisher_partner_id', publisherPartnerId);
        }
        return {
          url: `/partners/${partnerId}/stripe/invoices/courses?${
            queryParams ? queryParams.toString() : ''
          }`,
          method: 'POST',
          body: humps.decamelizeKeys({
            courseSlug,
            quantity,
            isAdminEnrollment: false,
            utmParameters: utmParams,
            stripeCustomerId,
          }),
        };
      },
      invalidatesTags: ['StripeInvoice'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getOwnUserStripeCustomer: builder.query<CustomerInterface, string | void>({
      query: (stripeAccountPartnerId = null) =>
        `/users/me/stripe/customers${
          stripeAccountPartnerId ? `?stripe_account_partner_id=${stripeAccountPartnerId}` : ''
        }`,
      providesTags: ['OwnUserStripeCustomer'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    createOrUpdateOwnUserStripeCustomer: builder.mutation<
      CustomerInterface,
      {
        email: string;
        name: string;
        address: {
          line1: string;
          line2?: string;
          city: string;
          state?: string;
          postalCode?: string;
          country: string;
        };
        stripeAccountPartnerId?: string;
      }
    >({
      query: ({ email, name, address, stripeAccountPartnerId = null }) => ({
        url: `/users/me/stripe/customers${
          stripeAccountPartnerId ? `?stripe_account_partner_id=${stripeAccountPartnerId}` : ''
        }`,
        method: 'POST',
        body: humps.decamelizeKeys({
          email,
          name,
          address,
        }),
      }),
      invalidatesTags: ['OwnUserStripeCustomer'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateUserProfile: builder.mutation<
      void,
      {
        firstName: string;
        middleName: string;
        lastName: string;
        dateOfBirth: string;
        phoneNumber: string;
        address: string;
        postalCode: string;
        city: string;
        state: string;
        country: string;
      }
    >({
      query: ({
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        phoneNumber,
        address,
        postalCode,
        city,
        state,
        country,
      }) => ({
        url: `/users/profile`,
        method: 'PATCH',
        body: {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          date_of_birth: dateOfBirth,
          phone_number: phoneNumber,
          address: {
            address,
            postal_code: postalCode,
            city,
            state,
            country,
          },
        },
      }),
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getDonationAsks: builder.query<DonationAskData[], { courseSlug?: string }>({
      query: ({ courseSlug }) => {
        const queryParams = new URLSearchParams();
        if (courseSlug) {
          queryParams.set('course_slug', courseSlug);
        }
        return `/v2/donation_asks?${queryParams ? queryParams.toString() : ''}`;
      },
      providesTags: ['DonationAsks'],
      transformResponse: (response: any) => humps.camelizeKeys(response?.data),
    }),
    resendMembershipEmails: builder.mutation<
      {
        sentInvitations: boolean;
      },
      { userIds?: string[]; bulkResendNeverLoggedInUsers?: boolean; partnerId: string }
    >({
      query: ({ userIds = [], bulkResendNeverLoggedInUsers = false, partnerId }) => {
        const queryParams = new URLSearchParams();
        queryParams.set('partner_id', partnerId);
        const requestPayload: { [key: string]: any } = {};

        if (userIds?.length > 0) {
          requestPayload.user_ids = userIds;
        } else if (bulkResendNeverLoggedInUsers) {
          requestPayload.bulk_resend_never_logged_in_users = bulkResendNeverLoggedInUsers;
        } else {
          return null;
        }

        return {
          url: `/partner-instance-memberships/resend-invitations${
            partnerId ? `?${queryParams.toString()}` : ''
          }`,
          method: 'POST',
          body: requestPayload,
        };
      },
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getPaginatedSectionLearners: builder.query<
      any,
      {
        includeAttributes?: string;
        pageSize?: number;
        pageNumber?: number;
        sectionId: string;
        search?: string;
      }
    >({
      query: ({ includeAttributes = '', pageSize = 10, pageNumber, search = '', sectionId }) => {
        const queryParams = new URLSearchParams({
          'page[number]': pageNumber.toString(),
          'page[size]': pageSize.toString(),
          include: 'admin,accepted_members',
          search,
          include_attributes: includeAttributes,
        });
        return `/sections/${sectionId}?${queryParams.toString()}`;
      },
      providesTags: ['PaginatedSectionLearners'],
      transformResponse: (response: any) => ({
        ...response,
        data: humps.camelizeKeys(
          response.included.map(({ attributes, id, type }) => ({ ...attributes, id, type })),
        ),
        meta: response.meta,
      }),
    }),
    getPaginatedCourseSections: builder.query<
      any,
      {
        slug: string;
        partnerId: string;
        pageSize?: number;
        pageNumber?: number;
        searchByAdmin?: string;
        getSoloLearners?: boolean;
      }
    >({
      query: ({
        slug,
        partnerId,
        pageNumber,
        pageSize = 10,
        getSoloLearners = false,
        searchByAdmin = '',
      }) => {
        const queryParams = new URLSearchParams({
          include: getSoloLearners ? 'solo-sections' : 'sections',
          partner_id: partnerId,
          search_by_admin: searchByAdmin,
          'page[size]': pageSize.toString(),
          'page[number]': pageNumber.toString(),
        });
        return `/v2/courses/${slug}?${queryParams.toString()}`;
      },
      providesTags: ['PaginatedCourseSections'],
      transformResponse: (response: any) => ({
        data: response.data?.sections?.map((section) =>
          humps.camelizeKeys({
            ...section.attributes,
            id: section.id,
          }),
        ),
        meta: response.meta,
      }),
    }),
    deleteUserCourseEnrollment: builder.mutation<
      void,
      { userId: number; courseSlug: string; partnerId: string }
    >({
      query: ({ courseSlug, userId, partnerId }) => {
        const queryParams = new URLSearchParams({
          partnerId,
        });
        return {
          url: `/courses/${courseSlug}/users/${userId}?${queryParams.toString()}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PaginatedSectionLearners', 'Course', 'PaginatedCourseSections'],
    }),
    getPaginatedInstructors: builder.query<
      {
        data: InstructorInterface[];
        pagination: {
          page: number;
          perPage: number;
          total: number;
        };
      },
      {
        partnerId: string;
        perPage?: number;
        page?: number;
        search?: string;
        getSoloLearners?: boolean;
        sort?: string;
      }
    >({
      query: ({ partnerId, page, perPage = 10, search = '', sort = '' }) => {
        const queryParams = new URLSearchParams({
          partner_id: partnerId,
          search,
          per_page: perPage.toString(),
          page: page.toString(),
          sort,
        });
        return `/instructors?${queryParams.toString()}`;
      },
      providesTags: ['PaginatedInstructors'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    createInstructor: builder.mutation<
      InstructorInterface,
      { payload: InstructorInterface; partnerId: string }
    >({
      query: ({ payload, partnerId }) => {
        const formData = new FormData();
        const keys = Object.keys(payload);
        keys.forEach((key) => {
          if (payload[key]) {
            formData.append(humps.decamelize(key), payload[key]);
          }
        });

        return {
          url: `/instructors?partner_id=${partnerId}`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['PaginatedInstructors'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getUnsplashImages: builder.query<any, { searchTerm: string; pageNumber: number }>({
      query: ({ searchTerm, pageNumber }) => ({
        url: `/unsplash/images?filter=[{"name": "search", "op": "eq", "val": "${searchTerm}"}]&page[number]=${pageNumber}&page[size]=30`,
      }),
      transformResponse: (response: any, _, { pageNumber }) => ({
        coverImages: humps.camelizeKeys(
          response?.data?.map(({ attributes, id }) => ({ ...attributes, id })),
        ),
        pageNumber,
      }),
    }),
    getPaginatedContentProviders: builder.query<
      {
        data: ContentProviderInterface[];
        pagination: {
          page: number;
          perPage: number;
          total: number;
        };
      },
      {
        partnerId: string;
        perPage?: number;
        page?: number;
        search?: string;
        sort?: string;
      }
    >({
      query: ({ partnerId, page, perPage = 10, search = '', sort = '' }) => {
        const queryParams = new URLSearchParams({
          partner_id: partnerId,
          search,
          per_page: perPage.toString(),
          page: page.toString(),
          sort,
        });
        return `/content_providers?${queryParams.toString()}`;
      },
      providesTags: ['PaginatedContentProviders'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    updateInstructor: builder.mutation<any, { payload: InstructorInterface; partnerId: string }>({
      query: ({ payload, partnerId }) => {
        const formData = new FormData();
        const keys = Object.keys(payload);
        keys.forEach((key) => {
          formData.append(humps.decamelize(key), payload[key]);
        });

        return {
          url: `/instructors/${payload.id}?partner_id=${partnerId}`,
          method: 'PUT',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['PaginatedInstructors'],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    deleteInstructor: builder.mutation<any, { instructorId: number; partnerId: string }>({
      query: ({ instructorId, partnerId }) => ({
        url: `/instructors/${instructorId}?partner_id=${partnerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaginatedInstructors'],
    }),
    deleteContentProvider: builder.mutation<void, { contentProviderId: number; partnerId: string }>(
      {
        query: ({ contentProviderId, partnerId }) => ({
          url: `/content_providers/${contentProviderId}?partner_id=${partnerId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['PaginatedContentProviders'],
      },
    ),
  }),
});

// Auto generated hooks that are created based on the endpoints on createApi.
export const {
  useGetChildInstancesCourseStatusQuery,
  useUpdateChildInstanceCoursesMutation,
  useShareCourseToFedNetworkMutation,
  useUnshareCourseFromFedNetworkMutation,
  useGetNetworkCoursesQuery,
  useUpdateCourseNetworkPriceMutation,
  useGetPartnerCoursePreviewQuery,
  useGetCoursesFromNetworkQuery,
  useUploadAddLearnersCSVMutation,
  useGetTeamPreviewQuery,
  useGetPartnerPaidSeatPriceQuery,
  useGetPartnerByIdQuery,
  useGetSectionByIdQuery,
  useUpdatePartnerCourseMutation,
  useGetShoppingCartQuery,
  useAddCartItemToShoppingCartMutation,
  useUpdateCartItemFromShoppingCartMutation,
  useDeleteCartItemFromShoppingCartMutation,
  useCreateInvoiceFromShoppingCartMutation,
  useCompleteShoppingCartCheckoutMutation,
  useApplyCouponToShoppingCartMutation,
  useGetCourseWaitlistQuery,
  useGetUsersCourseWaitlistRecordsQuery,
  useAddUserToCourseWaitlistMutation,
  useRemoveUserFromCourseWaitlistMutation,
  useAddUsersToCourseWaitlistMutation,
  useRemoveUsersFromCourseWaitlistMutation,
  useDeleteCouponFromShoppingCartMutation,
  useGetPartnerStripeInvoiceByIdQuery,
  useCreateOrUpdateOwnUserStripeCustomerMutation,
  useGetOwnUserStripeCustomerQuery,
  useUpdateUserProfileMutation,
  useCreateStripeCourseInvoiceMutation,
  useGetDonationAsksQuery,
  useResendMembershipEmailsMutation,
  useDeleteUserCourseEnrollmentMutation,
  useGetPaginatedSectionLearnersQuery,
  useGetPaginatedCourseSectionsQuery,
  useGetPaginatedInstructorsQuery,
  useCreateInstructorMutation,
  useLazyGetUnsplashImagesQuery,
  useGetPaginatedContentProvidersQuery,
  useUpdateInstructorMutation,
  useDeleteInstructorMutation,
  useDeleteContentProviderMutation,
} = rtkQApi;

export default {
  adminGeneral: new AdminGeneral(jsonApi),
  adminNoodling: new AdminNoodling(jsonApi),
  adminUser: new AdminUser(jsonApi),
  adminScheduler: new AdminScheduler(jsonApi),
  adminPartnerProgram: new AdminPartnerProgram(jsonApi),
  bookmark: new Bookmark(jsonApi),
  counselor: new Counselor(jsonApi),
  explore: new Explore(jsonApi),
  getStream: new GetStream(jsonApi),
  globalSearch: new GlobalSearch(jsonApi),
  group: new Group(jsonApi),
  landingPage: new LandingPage(jsonApi),
  like: new Like(jsonApi),
  noodling: new Noodling(jsonApi),
  onboarding: new Onboarding(jsonApi),
  payment: new Payment(jsonApi),
  partnerPrograms: new PartnerPrograms(jsonApi),
  post: new Post(jsonApi),
  scheduler: new Scheduler(jsonApi),
  team: new Team(jsonApi),
  section: new Section(jsonApi),
  university: new University(jsonApi),
  user: new User(jsonApi),
  userConnection: new UserConnection(jsonApi),
  survey: new Survey(jsonApi),
  course: new Course(jsonApi),
  product: new Product(jsonApi),
  teacherAssistant: new TeacherAssistant(jsonApi),
  partnerInstance: new PartnerInstance(jsonApi),
};
