import * as React from 'react';
import type { PlanItemProp } from './PlanItem.types';
import { CheckmarkIcon, Item, Wrapper, IconContainer, ItemContainer } from './PlanItem.styles';

const PlanItem = ({ text, icon }: PlanItemProp) => (
  <Wrapper>
    <IconContainer>{icon ?? <CheckmarkIcon />}</IconContainer>
    <ItemContainer>
      <Item>{text} </Item>
    </ItemContainer>
  </Wrapper>
);

export default PlanItem;
