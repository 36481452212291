// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes, spacing },
} = getTheme();
export const Section: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.x6};

  & .MuiFormGroup-root {
    & > div {
      margin-top: ${spacing.x6};
    }
  }
`;
export const Title: StatelessFunctionalComponent<any> = styled.div`
  ${fontTypes.font4Medium};
`;
