// @ts-nocheck
import {
  filterAdminNoodlingsTypes,
  getAdminNoodlingByIdTypes,
  getAdminNoodlingsTypes,
  getSuggestionsTypes,
} from './types';

const initialState = {
  filters: {},
  noodlings: {},
};

const adminNoodlingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getAdminNoodlingByIdTypes.REQUEST: {
      return {
        ...state,
        noodling: {
          isLoading: true,
        },
      };
    }

    case getAdminNoodlingByIdTypes.SUCCESS: {
      return {
        ...state,
        noodling: {
          noodling: payload,
          isLoading: false,
        },
      };
    }

    case getAdminNoodlingByIdTypes.FAILED: {
      return { ...state, noodling: { ...state.noodling, isLoading: false }, error: payload };
    }

    case getAdminNoodlingsTypes.REQUEST: {
      return { ...state, noodlings: { ...state.noodlings, isLoading: true } };
    }

    case getAdminNoodlingsTypes.SUCCESS: {
      return { ...state, noodlings: { ...state.noodlings, isLoading: false, ...payload } };
    }

    case getAdminNoodlingsTypes.FAILED: {
      return { ...state, noodlings: { ...state.noodlings, isLoading: false }, error: payload };
    }

    case getAdminNoodlingsTypes.SET_CURRENT_PAGE: {
      return { ...state, noodlings: { ...state.noodlings, currentPage: payload } };
    }

    case filterAdminNoodlingsTypes.UPDATE_FILTERS: {
      return { ...state, filters: payload };
    }

    case getSuggestionsTypes.REQUEST: {
      return { ...state, suggestions: { ...state.suggestions, isLoading: true } };
    }

    case getSuggestionsTypes.SUCCESS: {
      return { ...state, suggestions: { ...state.suggestions, list: payload, isLoading: false } };
    }

    case getSuggestionsTypes.FAILED: {
      return { ...state, suggestions: { ...state.suggestions, isLoading: false }, error: payload };
    }

    case getSuggestionsTypes.CLEAR: {
      return { ...state, suggestions: { ...state.suggestions, list: [] } };
    }

    default:
      return state;
  }
};

export default adminNoodlingReducer;
