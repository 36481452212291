// @ts-nocheck
import * as React from 'react';
import { useSelector } from 'react-redux';
import NoodleForm from 'components/uielements/Form';
import NoodleLogo from 'assets/svg/noodle_logo.svg';
import { accessibilityConstants } from 'commons/constants/general';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import { AuthWrapper, ContainerAuth, StyledHeader, StyledLogo } from './Auth.styles';
import type { AuthProps, AuthSubClassProps, AuthSubClassFormProps } from './Auth.types';

const Auth = ({ children, ...restProps }: AuthProps): React.ReactElement<any> => (
  <AuthWrapper {...restProps} role='main' id='main-id'>
    <ContainerAuth>
      <div>{children}</div>
    </ContainerAuth>
  </AuthWrapper>
);

const Logo = (): React.ReactElement<any> => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const appSettings = getAppSettings();
  const { companyLogo, companyReverseLogo, companyName } = appSettings || globalPartner;
  const imgAltText = `${companyName} Logo`;
  return (
    <StyledLogo isNonNoodleInstance={isNonNoodleInstance}>
      {/* auth0 doesn't detect a reload when using react to refresh the page, so we need
      to use an a tag in order for the custom code to work */}
      <a
        title={accessibilityConstants.getLoginHomeLink(companyName).title}
        aria-label={accessibilityConstants.getLoginHomeLink(companyName).ariaLabel}
        href='/'
      >
        <img
          src={isNonNoodleInstance ? companyReverseLogo || companyLogo : NoodleLogo}
          alt={imgAltText}
          aria-label={imgAltText}
          title={imgAltText}
          loading='lazy'
          width='100%'
          height='100%'
        />
      </a>
    </StyledLogo>
  );
};

const Header = ({ children }: AuthSubClassProps): React.ReactElement<any> => (
  <StyledHeader role='banner'>{children}</StyledHeader>
);

const Body = React.forwardRef(
  ({ children, className = '' }: AuthSubClassProps, ref: any): React.ReactElement<any> => (
    <div id='auth-content' className={`auth__content ${className}`} ref={ref}>
      <div className='auth__content__container'>{children}</div>
    </div>
  ),
);

const Social = ({ children }: AuthSubClassProps): React.ReactElement<any> => (
  <div className='auth__content-social-btn-wrp' role='region' aria-label='Social media'>
    {children}
  </div>
);

const Footer = ({ children }: AuthSubClassProps): React.ReactElement<any> => (
  <div className='auth__footer'>{children}</div>
);

const Form = ({ children, onSubmit }: AuthSubClassFormProps): React.ReactElement<any> => (
  <NoodleForm onSubmit={onSubmit} role='form'>
    {children}
  </NoodleForm>
);

Auth.Logo = Logo;
Auth.Header = Header;
Auth.Body = Body;
Auth.Social = Social;
Auth.Footer = Footer;
Auth.Form = Form;
export default Auth;
