import * as React from 'react';
import { StyledBadge } from './Badge.styles';

type BadgeProps = {
  children?: React.ReactNode | string;
  offsetTop?: number;
  offsetRight?: number;
};

// This component has to be added to a container and it will float on the top right hand side.
const Badge = ({
  children,
  offsetRight = -1,
  offsetTop = -1,
}: BadgeProps): React.ReactElement<any> => (
  <StyledBadge offsetRight={offsetRight} offsetTop={offsetTop}>
    {children}
  </StyledBadge>
);

export default Badge;
