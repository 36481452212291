// @ts-nocheck
import * as React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { TABLET_SMALLER_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from 'commons/constants/size';
import type { OptionalSteps } from 'commons/types';
import { teamActions } from 'ducks/team/actions';
import { sectionActions } from 'ducks/section/actions';
import { courseActions } from 'ducks/courses/actions';
import { partnerProgramsActions } from 'ducks/partnerPrograms/actions';
import dashboardActions from 'ducks/dashboard/actions';
import { PAGINATION_LIMIT } from 'commons/constants';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import { PARTNER_ID_NOODLE } from 'settings';
import type { PartnerInstance } from 'commons/types/partnerInstanceTypes';
import { PaginatedResponse } from 'commons/types/paginationTypes';

/*
  Custom hook copied from Dan Abramov personal blog
  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
*/
export const useInterval = (callback: (...args: Array<any>) => any, delay: number | null) => {
  const savedCallback = React.useRef<(...args: Array<any>) => any>();
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);
};

export const useIsCurrentUser = ({ userId }: { userId: number }) => {
  const { id: currentUserId }: { id: number } = useSelector((state: any) => state.user);

  return currentUserId === userId;
};

export const useWidth = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);
  return width;
};

export const useIsDesktop = () => {
  const width = useWidth();
  return width > TABLET_WIDTH;
};

/*
  Custom hook from https://usehooks.com/useOnClickOutside by Gabe Ragland
*/
export const useOnClickOutside = (ref: any, handler: (...args: Array<any>) => any) => {
  React.useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent): void => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }

      handler(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useReceiveCalendlyMessage = () => {
  const [message, setMessage] = React.useState();

  const handleReceiveMessage = (e) => setMessage(e.data && e.data.event);

  React.useEffect(() => {
    window.addEventListener('message', handleReceiveMessage);
    return () => window.removeEventListener('message', handleReceiveMessage);
  }, [message]);
  return message;
};

export const useCarouselItemsPerRow = () => {
  const width = useWidth();

  if (width > TABLET_SMALL_WIDTH) {
    return 3;
  }

  if (width > TABLET_SMALLER_WIDTH) {
    return 2;
  }

  return 1;
};
export const useQuery = () => new URLSearchParams(useLocation().search);
export const useStepper = (steps: number, optionalSteps: OptionalSteps = []) => {
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step: number) => optionalSteps.includes(step);

  const isStepSkipped = (step) => skipped.has(step);

  const isFirsStep = (): boolean => activeStep === 1;

  const isLastStep = (): boolean => activeStep === steps;

  const handleBack = () => {
    if (isFirsStep()) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (isLastStep()) {
      return;
    }

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(1);
  };

  return {
    activeStep,
    handleNext,
    handleBack,
    isFirsStep,
    isLastStep,
    setActiveStep,
    isStepOptional,
    handleSkip,
    handleReset,
  };
};

const HeaderContext = React.createContext<{
  headerTitle: { type: string; value: string };
  setHeaderTitle: (titleObj: { type: string; value: string }) => void;
}>({
  headerTitle: {},
  setHeaderTitle: () => {},
});

export const HeaderProvider = ({ title, children }: { title: {}; children: any }) => {
  const [headerTitle, setHeaderTitle] = React.useState(title);

  const providerValue = React.useMemo(
    () => ({
      headerTitle,
      setHeaderTitle,
    }),
    [headerTitle, setHeaderTitle],
  );

  return <HeaderContext.Provider value={providerValue}>{children}</HeaderContext.Provider>;
};

export const useHeader = () => {
  const context = React.useContext(HeaderContext);
  React.useEffect(() => () => context.setHeaderTitle(null), []);
  return context;
};

export function useServerPagination<T>({
  response,
  startPage = 1,
  pageSize = 10,
}: {
  response: PaginatedResponse<T> | undefined | null;
  startPage?: number;
  pageSize?: number;
}) {
  const count = response?.meta?.count ?? 0;
  const data = response?.data ?? [];
  const [actualPage, setActualPage] = React.useState(startPage);
  const pageCount = Math.ceil(count / pageSize);

  const changePage = (page: number) => {
    if (page < 1) {
      console.error('Cannot set actual page to less than 1');
    } else if (page > pageCount && page > 1) {
      console.error('Cannot set actual page to more than total pages');
    } else {
      setActualPage(page);
    }
  };

  const totalItems = count;

  const pageContent = data;

  return {
    pageContent,
    pageCount,
    pageItemsCount: data.length,
    actualPage,
    changePage,
    pageSize,
    hidePagination: pageCount === 1,
    totalItems,
    isEmpty: count === 0 || !pageContent,
  };
}

export const usePagination = (items: Array<any>) => {
  const [pages, setPages] = React.useState(0);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [actualPage, setActualPage] = React.useState(0);
  const [pageContent, setPageContent] = React.useState([]);
  const count = React.useMemo(() => items?.length, [items]);

  React.useEffect(() => {
    if (!items || !items.length) {
      setIsEmpty(true);
      setActualPage(0);
      setPages(0);
    } else {
      setIsEmpty(false);
      setActualPage(1);
      setPages(Math.ceil(items?.length / PAGINATION_LIMIT));
    }
  }, [items]);

  React.useEffect(() => {
    if (actualPage !== 0) {
      setPageContent(
        // $FlowFixMe flow being dumb below
        items?.slice(PAGINATION_LIMIT * (actualPage - 1), PAGINATION_LIMIT * actualPage - 1),
      );
    }
  }, [actualPage, items]);

  const changePage = (page: number) => {
    if (page < 0) {
      console.error('Cannot set actual page to less than 0');
    } else if (page > pages) {
      console.error('Cannot set actual page to more than total pages');
    } else {
      setActualPage(page);
    }
  };

  return {
    pages,
    isEmpty,
    actualPage,
    pageContent,
    changePage,
    count,
  };
};

const useRoleCheck = (role: string): boolean => {
  const roles = useSelector((state: any) => state?.user?.roles) || {};
  const rolesArray: any[] = Object.values(roles);
  return !!rolesArray.filter((r) => r?.name === role).length;
};

export const useIsInstanceAdmin = () => useRoleCheck('instance-admin');

export const useIsUniversityAdmin = () => useRoleCheck('parent-instance-admin');

export const useIsAdmin = () => useIsInstanceAdmin() || useIsUniversityAdmin();

export const useIsNoodleInstance = (
  partnerInstance: PartnerInstance | undefined,
  isLoading: boolean,
): boolean => {
  const { data: globalPartner } = useSelector((state: any) => state.partnerInstance.globalPartner);

  if (isLoading) {
    return false; // Return false while isLoading is true
  }

  return (
    partnerInstance?.parentPartnerId === null || globalPartner?.partnerId === PARTNER_ID_NOODLE
  );
};

export const useIsCorporateInstance = ({
  partnerInstance,
}: {
  partnerInstance?: PartnerInstance;
}) =>
  !(
    partnerInstance?.parentPartnerId === PARTNER_ID_NOODLE ||
    partnerInstance?.parentPartnerId === null ||
    partnerInstance?.parentPartnerId === undefined
  );

export const useIsUniversityInstance = () => {
  const { data: globalPartner } = useSelector((state: any) => state.partnerInstance.globalPartner);
  return globalPartner?.parentPartnerId === PARTNER_ID_NOODLE;
};

export const useGetTeamsInfo = () => {
  const isAdmin = useIsAdmin();

  const myTeams = useSelector((state) => state.team.myTeams.data);
  const mySections = useSelector((state) => state.section.mySections.data);
  const { data: teams } = useSelector((state) => state.team.teams);
  const { data: sections } = useSelector((state) => state.section.sections);

  const myTotalLength = myTeams?.length + mySections?.length;
  const totalTeamLength = teams?.length + sections?.length;

  const hasMultipleTeams = isAdmin ? totalTeamLength > 1 : myTotalLength > 1;

  return { myTeams, mySections, teams, sections, myTotalLength, totalTeamLength, hasMultipleTeams };
};

export const useGetSelectedTeamInfo = () => {
  const { data: globalPartner } = useSelector((state: any) => state.partnerInstance.globalPartner);

  const partnerName = getAppSettings()?.companyName || globalPartner?.companyName;

  const selectedTeam = useSelector((state) => state.team.selectedTeam);
  const selectedSection = useSelector((state) => state.section.selectedSection);
  const selectedFilterTeam = useSelector((state) => state.team.selectedFilterTeam);

  const autoSelectedName = selectedTeam?.name || selectedSection?.name;
  const defaultTeamName = isNonNoodleInstance ? 'Loading...' : 'All Teams';
  const teamName = selectedFilterTeam?.name || autoSelectedName || defaultTeamName;

  const isAllTeams = teamName === 'All Teams';
  const isGeneralTeam = selectedTeam?.name.includes(`Team ${partnerName}`) && isNonNoodleInstance;

  return { teamName, selectedTeam, selectedSection, selectedFilterTeam, isAllTeams, isGeneralTeam };
};

export const useAutoSelect = () => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const selectedTeam = useSelector((state) => state.team?.selectedTeam);
  const selectedSection = useSelector((state) => state.section?.selectedSection);
  const myTeams = useSelector((state) => state.team.myTeams.data);
  const mySections = useSelector((state) => state.section.mySections.data);
  const myTeamsLoading = useSelector((state) => state.team.myTeams.isLoading);
  const mySectionsLoading = useSelector((state) => state.section.mySections.isLoading);
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const { data: teams, isLoading: isTeamsLoading } = useSelector((state) => state.team.teams);
  const myTeamsLength = myTeams?.length ?? 0;
  const mySectionsLength = mySections?.length ?? 0;
  const myTotalLength = myTeamsLength + mySectionsLength;

  const getShouldAutoSelect = (): boolean => {
    // Always want to autoselect if it's private label
    // There will always be 1 team (general team) on private labels
    if (isNonNoodleInstance) {
      return !myTeamsLoading && !mySectionsLoading && !isTeamsLoading;
    }

    return !myTeamsLoading && !mySectionsLoading && myTotalLength === 1;
  };

  const handleAutoSelect = (): void => {
    // Get current instances company name
    const { companyName } = getAppSettings() || globalPartner;
    const teamList = isAdmin ? teams : myTeams;
    // Pattern to match with the general team
    const generalTeam = teamList?.find((team) => team.name.includes(`Team ${companyName}`));

    if (isNonNoodleInstance) {
      dispatch(teamActions.selectTeam(generalTeam));
    } else if (myTeams?.length === 1) {
      dispatch(teamActions.selectTeam(myTeams[0]));
    } else {
      dispatch(sectionActions.selectSection(mySections[0]));
    }
  };

  React.useEffect(() => {
    if (getShouldAutoSelect() && !selectedSection && !selectedTeam) {
      handleAutoSelect();
    }
  }, [teams, myTeams, mySections]);
};

export const useSelectFromUrl = () => {
  const dispatch = useDispatch();
  const myTeams = useSelector((state) => state.team.myTeams.data);
  const mySections = useSelector((state) => state.section.mySections.data);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlTeamID = urlParams.get('selectedTeam');
  const urlSectionID = urlParams.get('selectedSection');
  // We need to make sure that if there's teams/sections, the hook can call them from here
  React.useEffect(() => {
    if (!mySections?.length || !myTeams?.length) {
      dispatch(teamActions.getMyTeamsRequest());
      dispatch(sectionActions.getMySectionsRequest());
    }
  }, []);
  React.useEffect(() => {
    if (urlTeamID && myTeams?.length) {
      const searchedTeam = myTeams.find((team) => team.id === parseInt(urlTeamID, 10));
      dispatch(teamActions.selectTeam(searchedTeam));
    }

    if (urlSectionID && mySections?.length) {
      const searchedSection = mySections.find(
        (section) => section.id === parseInt(urlSectionID, 10),
      );
      dispatch(sectionActions.selectSection(searchedSection));
    }
  }, [myTeams, mySections]);
};

export const useSavedProgramsWatch = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { success: isRemoveSuccess } = useSelector((state) => state.courses.unsaveCourse);
  const { success: isSaveSuccess } = useSelector((state) => state.team.saveContent);
  React.useEffect(() => {
    // Saved Courses is exclusive to Non-PrivateLabel
    if (isLoggedIn && !isNonNoodleInstance) {
      dispatch(courseActions.getSavedCoursesRequest());
      dispatch(partnerProgramsActions.getSavedProgramsRequest());
    }
  }, []);
  React.useEffect(() => {
    if (isRemoveSuccess) {
      dispatch(partnerProgramsActions.getSavedProgramsRequest());
      dispatch(courseActions.getSavedCoursesRequest());
    }
  }, [isRemoveSuccess]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(partnerProgramsActions.getSavedProgramsRequest());
      dispatch(courseActions.getSavedCoursesRequest());
    }
  }, [isSaveSuccess]);
};

export const useClearCourses = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(courseActions.selectCourse(null));
    dispatch(sectionActions.selectSection(null));
    dispatch(teamActions.selectTeam(null));
  }, []);
};

export const useUserHasOneTeam = () => {
  const myTeams = useSelector((state) => state.team.myTeams.data);
  const mySections = useSelector((state) => state.section.mySections.data);
  return myTeams.length + mySections.length > 1;
};

export const useSaveContent = (slug: string | null | typeof undefined) => {
  const dispatch = useDispatch();
  const currentTeam = useSelector((state) => state.team.selectedTeam);
  const currentSection = useSelector((state) => state.section?.selectedSection);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  const dashboardAction = useSelector((state) => state.dashboard.action);
  const { data: savedCourses } = useSelector((state) => state.courses.savedCourses);
  const { data: savedPrograms } = useSelector((state) => state.partnerPrograms.savedPrograms);
  const isMoreThanOneTeam = useUserHasOneTeam();
  const isSaveAction = dashboardAction === 'saveCourse' || dashboardAction === 'saveProgram';
  React.useEffect(() => {
    const teamId = currentSection?.id || currentTeam?.id;

    if (slug && teamId && isSaveAction) {
      const itemId = [...savedCourses, ...savedPrograms]
        ?.find((item) => item?.id === slug)
        ?.saves.find((item) => item.teamId === teamId)?.id;

      if (itemId) {
        dispatch(courseActions.removeCourse(itemId));
      } else {
        dispatch(
          teamActions.saveContent({
            slug,
            teamId,
            type: dashboardAction === 'saveProgram' ? 'program' : 'course',
          }),
        );
      }

      dispatch(courseActions.selectCourse(null));
      dispatch(sectionActions.selectSection(null));

      if (isMoreThanOneTeam) {
        dispatch(teamActions.selectTeam(null));
      }

      dispatch(dashboardActions.setModalAction(null));
    }
  }, [selectedCourse, currentTeam, currentSection]);
};
