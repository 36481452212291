import * as React from 'react';
import { ImageWithFallbackProps } from 'components/ImageWithFallback';
import {
  MaterialCardContainer,
  CardSubtitle,
  CardTitle,
  CardActions,
  ContentContainer,
  CardTags,
  TopRightAction,
  CardImage,
  ContainerImgMaterialCard,
} from './MaterialCard.styles';

interface MaterialCardProps {
  children: React.ReactElement<any> | React.ReactElement<any>[];
  isHoverable?: boolean;
}
interface MaterialCardImgProps {
  className?: string;
  [key: string]: any;
}
interface MaterialCardSubcomponents {
  Title: typeof CardTitle;
  Subtitle: typeof CardSubtitle;
  Actions: typeof CardActions;
  ContentContainer: typeof ContentContainer;
  Tags: typeof CardTags;
  TopRightAction: typeof TopRightAction;
  Img: React.FC<MaterialCardImgProps & ImageWithFallbackProps>;
}

const MaterialCard: React.FC<MaterialCardProps> & MaterialCardSubcomponents = ({
  children,
  isHoverable = true,
  ...otherProps
}) => (
  <MaterialCardContainer {...otherProps} isHoverable={isHoverable}>
    {children}
  </MaterialCardContainer>
);

const MaterialCardImg: React.FC<MaterialCardImgProps & ImageWithFallbackProps> = ({
  className,
  ...restProps
}) => (
  <ContainerImgMaterialCard className={className}>
    <CardImage {...restProps} />
  </ContainerImgMaterialCard>
);

MaterialCard.Title = CardTitle;
MaterialCard.Subtitle = CardSubtitle;
MaterialCard.Actions = CardActions;
MaterialCard.ContentContainer = ContentContainer;
MaterialCard.Tags = CardTags;
MaterialCard.TopRightAction = TopRightAction;
MaterialCard.Img = MaterialCardImg;

export default MaterialCard;
