import * as React from 'react';
import { LoadingBarContainer, LoadingBarFill } from './LoadingBar.style';
import type { LoadingBarProps } from './LoadingBar.types';

const LoadingBar = ({
  percentage = 0,
  transitionDuration = 1000,
  index,
}: LoadingBarProps): React.ReactElement<any> => (
  <LoadingBarContainer>
    <LoadingBarFill index={index} percentage={percentage} transitionDuration={transitionDuration} />
  </LoadingBarContainer>
);

export default LoadingBar;
