import * as React from 'react';
import { Controller } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import HelperText from 'components/HelperText/index';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Wrapper,
  StyledSelect,
  StyledMenuItem,
  Placeholder,
  IconContainer,
  SExpandMoreIcon,
} from './NoodleSelect.styles';
import type { Item, NoodleSelectProps } from './NoodleSelect.types';

const NoodleSelect = ({
  defaultValue,
  label,
  placeholder,
  items,
  onChange,
  className,
  size,
  multiple,
  helperText = '',
  ...rest
}: NoodleSelectProps): React.ReactElement<any> | null => {
  const [selectedValue, setSelectedValue] = React.useState<string | string[]>();

  React.useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e: any) => {
    e.preventDefault();
    if (onChange) {
      onChange(e);
    }
    setSelectedValue(e.target.value);
  };

  const renderSelectedCheck = (item): React.ReactElement => {
    if (multiple) {
      return selectedValue.includes(item.value) || defaultValue?.includes(item.value) ? (
        <CheckCircleIcon />
      ) : null;
    }

    return selectedValue === item.value ? <CheckCircleIcon /> : null;
  };

  return (
    <Wrapper className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledSelect
        IconComponent={() => <SExpandMoreIcon />}
        size={size}
        displayEmpty
        defaultValue={defaultValue}
        label={label}
        onChange={handleChange}
        icon
        multiple={multiple}
        {...rest}
      >
        <StyledMenuItem selected disabled value=''>
          <Placeholder>{placeholder}</Placeholder>
        </StyledMenuItem>
        {items?.length
          ? items.map((item) => (
              <StyledMenuItem key={item.name} value={item.value}>
                <IconContainer>{renderSelectedCheck(item)}</IconContainer>
                {item.name}
              </StyledMenuItem>
            ))
          : null}
      </StyledSelect>
      {helperText && <HelperText text={helperText} />}
    </Wrapper>
  );
};

const ControlledNoodleSelect = ({
  defaultValue = '',
  label,
  placeholder,
  items,
  className,
  size,
  name,
  control,
  helperText = '',
  multiple,
  ...rest
}: NoodleSelectProps): React.ReactElement<any> | null => {
  const renderSelectedCheck = (item: Item, inputValue: string[] | string): React.ReactElement => {
    if (multiple) {
      return inputValue.includes(item.value) || defaultValue?.includes(item.value) ? (
        <CheckCircleIcon />
      ) : null;
    }

    return inputValue === item.value ? <CheckCircleIcon /> : null;
  };

  return (
    <Wrapper className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledSelect
            size={size}
            displayEmpty
            defaultValue={defaultValue}
            label={label}
            multiple={multiple}
            {...rest}
            {...field}
          >
            <StyledMenuItem selected disabled value=''>
              <Placeholder>{placeholder}</Placeholder>
            </StyledMenuItem>
            {items?.length
              ? items.map((item) => (
                  <StyledMenuItem key={item.name} value={item.value}>
                    <IconContainer>{renderSelectedCheck(item, field?.value)}</IconContainer>
                    {item.name}
                  </StyledMenuItem>
                ))
              : null}
          </StyledSelect>
        )}
      />
      {helperText && <HelperText text={helperText} />}
    </Wrapper>
  );
};

NoodleSelect.Controlled = ControlledNoodleSelect;
export default NoodleSelect;
