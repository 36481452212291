// @ts-nocheck
import * as React from 'react';
import NoodleTextField from 'components/NoodleTextField/index';
import NoodleTextArea from 'components/NoodleTextArea/index';
import type { CreateDetailsProps } from './CreateDetails.types';
import {
  Container,
  Wrapper,
  Title,
  TitleWrapper,
  FieldWrapper,
  HelpText,
} from './CreateDetails.styles';
// TODO: this is going to be in createTeam constants, in PR 1495
const createDetailsLabels = {
  name: 'Team Name',
  about: 'About',
  ctaButton: 'Next',
  namePlaceholder: 'Give your Team a name',
  aboutPlaceholder:
    "Write about your Team goals so that anyone who gets sent an invite can understand what it's about, and decide whether or not they want to join.",
};

const CreateDetails = ({ data, onChange }: CreateDetailsProps): React.ReactElement<any> | null => {
  const handleChange = (event): void => {
    const { name, value } = event.currentTarget;
    const newData = { ...data, [name]: value };
    onChange(newData);
  };

  const NAME_LENGTH = 25;
  const ABOUT_LENGTH = 140;
  return (
    <Wrapper>
      <Container>
        <FieldWrapper>
          <TitleWrapper>
            <Title>{createDetailsLabels.name}</Title>
          </TitleWrapper>
          <NoodleTextField
            inputProps={{
              maxLength: NAME_LENGTH,
            }}
            name='name'
            value={data.name}
            variant='outlined'
            autoComplete='off'
            placeholder={createDetailsLabels.namePlaceholder}
            onChange={(event) => handleChange(event)}
          />
          <HelpText>{NAME_LENGTH - data.name?.length} characters left</HelpText>
        </FieldWrapper>
        <FieldWrapper>
          <TitleWrapper>
            <Title>{createDetailsLabels.about}</Title>
          </TitleWrapper>
          <NoodleTextArea
            maxLength={ABOUT_LENGTH}
            placeholder={createDetailsLabels.aboutPlaceholder}
            multiline
            name='about'
            variant='outlined'
            autoComplete='off'
            value={data.about}
            rows={4}
            onChange={(event) => handleChange(event)}
          />
        </FieldWrapper>
      </Container>
    </Wrapper>
  );
};

export default CreateDetails;
