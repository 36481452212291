// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  palette: { neutral },
  noodera: { fontTypes, spacing },
} = getTheme();
export const HelperTextContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  justify-content: flex-end;
  ${fontTypes.font5};
  color: ${neutral.eighty};
  margin-top: ${spacing.x7};
`;
export const RightAlignedHelperTextSpan: StatelessFunctionalComponent<any> = styled.span`
  width: 100%;
  text-align: right;
`;
export const HelperTextSpan: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font5};
  display: inline-block;
  color: ${neutral.eighty};
  margin-bottom: ${spacing.x7};
  margin-top: ${spacing.x7};
`;
