// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';
import type { LoadingBarFillProps } from './LoadingBar.types';

const {
  palette: { blue, noodle },
} = getTheme();

const calculateTransitionDelay = ({ index, transitionDuration }: LoadingBarFillProps) =>
  index === 0 ? '' : `${transitionDuration * index}ms`;

export const LoadingBarContainer: StatelessFunctionalComponent<any> = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 3.2rem;
  height: 1.6rem;
  width: 100%;
  background: ${blue.geyser};
  border-radius: 1.2rem;
`;
export const LoadingBarFill: StatelessFunctionalComponent<any> = styled.span`
  display: block;
  height: 100%;
  width: ${(props) => `${props.percentage}%`};
  background-color: ${noodle.primary};
  border-radius: 1.2rem;
  transition: width ${(props) => `${props.transitionDuration}ms`};
  transition-delay: ${calculateTransitionDelay};
`;
