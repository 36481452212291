// @ts-nocheck
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import dashboardActions from 'ducks/dashboard/actions';
import Header from 'components/Header/index';
import MobileNavbar from 'components/MobileNavbar/index';
import NooderaModal from 'components/NooderaModal';
import TeamListItem from 'components/TeamListItem';
import MobileHeader from 'components/MobileHeader/index';
import TabsNavigation from 'components/TabsNavigation/index';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useHeader, useIsAdmin, useQuery } from 'utils/customHooks';
import { getLastPartOfUrl, isMainTitleAllowedRoute } from 'utils/urls';
import { getCurrentTitleFromNavLinks } from 'utils/navigation/getNavLinksPerRole';
import { TABLET_SMALL_WIDTH, TABLET_SMALLER_WIDTH, TABLET_WIDTH } from 'commons/constants/size';
import allTeamImage from 'assets/images/group-placeholder-bg.png';
import { getStreamActions } from 'ducks/getStream/actions';
import { teamActions } from 'ducks/team/actions';
import { sectionActions } from 'ducks/section/actions';
import { Container, Image, Label } from 'components/TeamListItem/TeamListItem.styles';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { CourseWaitlistModals } from 'components/CourseWatilistModals';
import { HomeTitle } from './components/HomeTitle/HomeTitle';
import GuestHome from './GuestHome/index';
import GuestHomePrivateLabel from './GuestHomePrivateLabel/index';
import {
  ContentSection,
  ContentWrapper,
  DashboardWrapper,
  MainContainer,
  MainContentSection,
  MainTitle,
  StyledModalSubtitle,
  Wrapper,
} from './Dashboard.styles';
import DashboardNav from './DashboardNav';
import DashboardRouter from './DashboardRouter';
import GetSectionFeed from './Home/GetSectionFeed';

const Dashboard = (): React.ReactElement<any> => {
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { headerTitle } = useHeader();
  const isAdmin = useIsAdmin();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${TABLET_WIDTH}px)`,
  });
  const isSmallTablet = useMediaQuery({
    query: `(max-width: ${TABLET_SMALL_WIDTH}px)`,
  });
  const isSmallerTablet = useMediaQuery({
    query: `(max-width: ${TABLET_SMALLER_WIDTH}px)`,
  });
  const dashboardAction = useSelector((state) => state.dashboard?.action);
  const isAdminDashboard = location.pathname.includes('/instance');
  const [privateTeam, setPrivateTeam] = React.useState([]);
  const [publicTeam, setPublicTeam] = React.useState([]);
  const [sectionList, setSectionList] = React.useState([]);
  const [showShoppingCartSuccessModal, setShowShoppingCartSuccessModal] = React.useState(false);
  const [isNotificationsPath, setIsNotificationsPath] = React.useState(false);
  const teamPreviewError = useSelector((state) => state.team.preview.error);
  const teams = [...sectionList, ...privateTeam, ...publicTeam];
  const pathname = useSelector((state) => state.router.location.pathname);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { data: myTeams, isLoading: myTeamsIsLoading } = useSelector((state) => state.team.myTeams);
  const { data: mySections, isLoading: mySectionsIsLoading } = useSelector(
    (state) => state.section.mySections,
  );
  const allTeams = useSelector((state) => state.team.teams.data);
  const allSections = useSelector((state) => state.section.sections.data);
  const selectedTeam = useSelector((state) => state.team.selectedTeam);
  const isSelectTeamModalOpen = useSelector((state) => state.team.isSelectTeamModalOpen);
  const isSelectedTeamModalSaveMode = useSelector(
    (state) => state.team.isSelectedTeamModalSaveMode,
  );
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  const savedItems = useSelector((state) => state.bookmark.savedItems);
  const selectedSection = useSelector((state) => state.section.selectedSection);
  const isTeamModalSaveOrUnsaveMode = useSelector(
    (state) => state.team?.isTeamModalSaveOrUnsaveMode,
  );
  const isCreatePostModalOpen = useSelector((state) => state.getStream.isCreatePostModalOpen);
  const mainTitle = headerTitle || getCurrentTitleFromNavLinks(pathname);
  const isValidURL =
    isLoggedIn &&
    (pathname === '/' ||
      pathname.includes('/courses/saved') ||
      pathname.includes('/interests') ||
      (pathname.includes('/programs') && !pathname.includes('/instance')) ||
      pathname.includes('/universities') ||
      pathname.includes('/articles'));
  const isSavedFilterRoute = pathname.includes('/courses/saved');

  const cookiebotObserver = React.useCallback(() => {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    const cookiebotSetPosition = (cookiebot: React.ElementRef<any>): void => {
      if (cookiebot) {
        if (isDesktop) {
          cookiebot.style.bottom = '12px';
          cookiebot.style.left = '16px';
          cookiebot.style.right = 'auto';
          cookiebot.style.maxWidth = '452px';
        } else {
          cookiebot.style.bottom = '84px';
          cookiebot.style.left = 'auto';
          cookiebot.style.right = '12px';
          cookiebot.style.maxWidth = '452px';
        }
      }
    };

    const observer = new MutationObserver((mutations) => {
      // fired when a mutation occurs
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const cookiebot = document.querySelector('#CookiebotWidget');

          if (cookiebot) {
            cookiebot.style.visibility = 'hidden';
            cookiebotSetPosition(cookiebot);
            cookiebot.style.visibility = 'visible';
          }
        }
      });
    });
    const config = {
      childList: true,
      subtree: true,
    };
    observer.observe(document, config);
  });

  cookiebotObserver();

  React.useEffect(() => {
    if (isValidURL) {
      if (isAdmin) {
        dispatch(teamActions.getTeamsRequest());
        dispatch(sectionActions.getSectionsRequest());
      } else if (!myTeamsIsLoading && !mySectionsIsLoading && !myTeams && !mySections) {
        dispatch(teamActions.getMyTeamsRequest());
        dispatch(sectionActions.getMySectionsRequest());
      }
    }
  }, []);

  React.useEffect(() => {
    if (dashboardAction === 'shoppingCartSuccess') {
      setShowShoppingCartSuccessModal(true);
    }
  }, [dashboardAction]);

  const getTeamFiltered = (teamsToFilter, isPublic, list) => {
    let filteredTeams = teamsToFilter.filter(
      (team) => team.teamType !== 'section' && team.public === isPublic,
    );

    if (list) {
      filteredTeams = filteredTeams.map((team) => {
        const currentSave = list.find((item) => item.teamId === team.id);
        return { ...team, canUnsave: currentSave?.canUserUnsave };
      });
    }

    return filteredTeams;
  };

  React.useEffect(() => {
    if (teamPreviewError) {
      dispatch(teamActions.getMyTeamsRequest());
    }
  }, [teamPreviewError]);

  React.useEffect(() => {
    // TODO: Seems like useSelector might be rerendering the whole dashboard
    // for some reason. Tried using shallow render and createSelector
    // to memoize the value, but it seems it isn't memoizing it, causing
    // the re renders in the admin dashboard. Quick fix but we should address this.
    if (!pathname.includes('/instance')) {
      if (selectedSection?.id) {
        dispatch(sectionActions.getSectionPreviewRequest(selectedSection.id));
      }
    }
  }, [selectedSection]);

  React.useEffect(() => {
    if (!pathname.includes('/instance')) {
      if (selectedTeam?.id) {
        dispatch(
          teamActions.getTeamPreviewRequest({
            teamId: selectedTeam.id,
          }),
        );
      }
    }
  }, [selectedTeam]);

  React.useEffect(() => {
    // Set background for notifications
    const notificationsPath = getLastPartOfUrl(window.location.pathname) === 'notifications';

    if (notificationsPath && !isNotificationsPath) {
      setIsNotificationsPath(notificationsPath);
    }

    if (!notificationsPath && isNotificationsPath) {
      setIsNotificationsPath(notificationsPath);
    }
  }, [pathname]);

  React.useEffect(() => {
    const teamData = isAdmin ? allTeams : myTeams;

    if (teamData) {
      let newPublicTeam;
      let newPrivateTeam;
      const selectedList = selectedCourse?.saves || savedItems;

      if (selectedList) {
        newPublicTeam = getTeamFiltered(teamData, true, selectedList);
        newPrivateTeam = getTeamFiltered(teamData, false, selectedList);
      } else {
        newPublicTeam = getTeamFiltered(teamData, true);
        newPrivateTeam = getTeamFiltered(teamData, false);
      }

      setPublicTeam(newPublicTeam);
      setPrivateTeam(newPrivateTeam);
    }
  }, [myTeams, selectedCourse, savedItems, allTeams]);

  React.useEffect(() => {
    const sectionData = isAdmin ? allSections : mySections;

    if (sectionData) {
      let sections;
      const selectedList = selectedCourse?.saves || savedItems;

      if (selectedList) {
        sections = sectionData.map((section) => {
          const currentSaves = selectedList.find((item) => item.teamId === section.id);
          return { ...section, canUnsave: currentSaves?.canUserUnsave };
        });
      } else {
        sections = sectionData;
      }

      setSectionList(sections);
    }
  }, [mySections, selectedCourse, savedItems, allSections]);

  const handleAllTeams = (): void => {
    dispatch(teamActions.selectTeam(null));
    dispatch(sectionActions.selectSection(null));
    dispatch(
      teamActions.toggleSelectTeamModal({
        isOpen: false,
      }),
    );
    dispatch(teamActions.selectFilter(null));
  };

  const handleTeam = (team): void => {
    if (team) {
      if (!isTeamModalSaveOrUnsaveMode && isSavedFilterRoute) {
        dispatch(teamActions.selectFilter(team));
        dispatch(teamActions.selectTeam(team));
      } else {
        dispatch(teamActions.selectTeam(team));
      }

      dispatch(teamActions.getTeamPreviewRequest({ teamId: team.id }));
    }

    dispatch(sectionActions.selectSection(null));
    dispatch(
      teamActions.toggleSelectTeamModal({
        isOpen: false,
      }),
    );
  };

  const handleSection = (section): void => {
    if (section) {
      if (!isTeamModalSaveOrUnsaveMode && isSavedFilterRoute) {
        dispatch(teamActions.selectFilter(section));
      } else {
        dispatch(sectionActions.selectSection(section));
      }
    }

    dispatch(teamActions.selectTeam(null));
    dispatch(
      teamActions.toggleSelectTeamModal({
        isOpen: false,
      }),
    );
  };

  React.useEffect(() => {
    if (query.get('section')) {
      const queryParamTeam = teams.find((team) => team.id === Number(query.get('section')));

      if (queryParamTeam && selectedSection?.id !== queryParamTeam.id) {
        handleSection(queryParamTeam);
      }

      history.push('/');
    }
  }, [query, selectedSection, teams]);

  const handleModalClose = (): void => {
    if (!isNonNoodleInstance) {
      dispatch(teamActions.selectTeam(null));
      dispatch(sectionActions.selectSection(null));
      dispatch(getStreamActions.toggleCreatePostModal(false));
    }

    // Closing the modal resets everything to "All Teams". Fixing it just for private label
    // as I'm unsure whether this messes up with saving content on non private label...
    dispatch(
      teamActions.toggleSelectTeamModal({
        isOpen: false,
      }),
    );
  };

  const handleShoppingCartModalClose = (): void => {
    setShowShoppingCartSuccessModal(false);
    dispatch(dashboardActions.setModalAction(null));
  };

  const renderDesktopHeader = (): React.ReactElement<any> | null =>
    isDesktop ? <Header title={mainTitle} /> : null;

  const renderMobileElements = (): React.ReactElement<any> | null =>
    !isDesktop ? (
      <>
        <MobileNavbar />
        <MobileHeader />
      </>
    ) : null;

  const renderAllTeams = (): React.ReactElement<any> | null =>
    !isNonNoodleInstance ? (
      <Container onClick={handleAllTeams}>
        <Image src={allTeamImage} alt='All Teams' />
        <div>
          <Label>All Teams</Label>
        </div>
      </Container>
    ) : null;

  const renderModal = (): React.ReactElement<any> => {
    const Modal = !isSmallTablet ? NooderaModal.Medium : NooderaModal;
    const title = isSelectedTeamModalSaveMode ? 'Save Resource to' : 'Select a Team';
    return (
      <Modal
        isOpen={isSelectTeamModalOpen}
        title={title}
        btnText='share'
        onClose={handleModalClose}
      >
        <Wrapper>
          {!isSelectedTeamModalSaveMode && !isCreatePostModalOpen ? renderAllTeams() : null}
          {isSelectedTeamModalSaveMode && (
            <StyledModalSubtitle>Select where you want to save this resource</StyledModalSubtitle>
          )}
          {teams.map((team) => (
            <TeamListItem
              key={team.id}
              data={team}
              canUnsave={team.canUnsave}
              withActionButton={isTeamModalSaveOrUnsaveMode && !isCreatePostModalOpen}
              onClick={() => (team.teamType === 'section' ? handleSection(team) : handleTeam(team))}
            />
          ))}
        </Wrapper>
      </Modal>
    );
  };
  const renderShoppingCartSuccessModal = (): React.ReactElement<any> => (
    <NooderaModal.Small
      className='shoppingCartModalId'
      title='All Set!'
      isOpen={showShoppingCartSuccessModal}
      icon={<CheckCircleOutlineOutlinedIcon />}
      description='You’ve made your purchase! You can access your course content by selecting it from your dashboard.'
      btnText='Continue'
      onBtnClick={handleShoppingCartModalClose}
    />
  );

  const renderContentTabsNavigation = (): React.ReactElement<any> | null =>
    !isSmallTablet &&
    !pathname.includes('/teams/preview') &&
    // Hide the tabs for non noodle instances for now as private label doesn't have save functionality enabled yet
    !isNonNoodleInstance &&
    pathname !== '/' ? (
      <TabsNavigation />
    ) : null;

  const renderHomeTitle = (): React.ReactElement<any> | null => {
    if (pathname === '/' || pathname === '/tab/team') {
      return <HomeTitle />;
    }

    return null;
  };

  const renderMainTitle = (viewport: 'desktop' | 'mobile'): React.ReactElement<any> | null => {
    const isValidViewport = viewport === 'desktop' ? !isSmallerTablet : isSmallerTablet;

    if (isMainTitleAllowedRoute(pathname) && isValidViewport) {
      return <MainTitle>{mainTitle?.value}</MainTitle>;
    }

    return null;
  };

  const renderHome = (): React.ReactElement<any> => {
    if (!isLoggedIn && isNonNoodleInstance && pathname === '/') {
      return <GuestHomePrivateLabel />;
    }
    if (!isLoggedIn && pathname === '/') {
      return <GuestHome />;
    }
    return (
      <ContentSection>
        <MainContentSection isNotificationsPath={isNotificationsPath}>
          <MainContainer isInstance={isAdminDashboard}>
            {renderHomeTitle()}
            {!isDesktop && pathname === '/' && <GetSectionFeed />}
            {renderMainTitle('desktop')}
            {renderContentTabsNavigation()}
            {renderMainTitle('mobile')}
            <DashboardRouter />
          </MainContainer>
        </MainContentSection>
      </ContentSection>
    );
  };

  return (
    <DashboardWrapper>
      {renderMobileElements()}
      {renderModal()}
      {renderShoppingCartSuccessModal()}
      <CourseWaitlistModals />
      <div className='app-container'>
        <ContentWrapper isLoggedIn={isLoggedIn}>
          {renderDesktopHeader()}
          {renderHome()}
        </ContentWrapper>
        <div className='dashboard__navigation'>
          <div>
            <DashboardNav />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
