import { getTheme } from 'settings/theme';

const {
  palette: { blue, green, noodle, red },
} = getTheme();
const CATEGORY_COLORS_MAP = {
  Reach: red.atomicTangerine,
  Target: green.lime,
  Safety: noodle.primary,
  Wildcard: blue.shipCove,
};
const ASSOCIATION_TYPES = {
  INSTITUTION: 'INSTITUTION',
  INSTITUTION_REACH: 'INSTITUTIONREACH',
  INSTITUTION_TARGET: 'INSTITUTIONTARGET',
  INSTITUTION_SAFETY: 'INSTITUTIONSAFETY',
  INSTITUTION_WILDCARD: 'INSTITUTIONWILDCARD',
  SCHOOL: 'SCHOOL',
  SCHOOL_REACH: 'SCHOOLREACH',
  SCHOOL_TARGET: 'SCHOOLTARGET',
  SCHOOL_SAFETY: 'SCHOOLSAFETY',
  SCHOOL_WILDCARD: 'SCHOOLWILDCARD',
};
export const noodlingConstants = {
  ASSOCIATION_TYPES,
  CATEGORY_COLORS_MAP,
  INSTITUTION_KEY: 'institution',
  SCHOOL_KEY: 'school',
};
export const CANDIDATE_STRENGTHS_ENUM = {
  ELITE: 'Elite',
  ASPIRATIONAL: 'Aspirational',
  AVERAGE: 'Average',
  BELOW_AVERAGE: 'Below Average',
};
export const CAREER_ASPIRATIONS_ENUM = {
  COMPETITIVE: 'Competitive',
  LESS_COMPETITIVE: 'Less-Competitive',
};
export const EDUCATION_TYPES_ENUM = {
  PRE_K: 'Pre-K',
  K_12: 'K-12',
  UNDERGRAD: 'undergrad',
  GRADUATE_SCHOOL: 'Graduate School',
  CAREER_BOOTCAMP: 'Career Bootcamp',
  PRE_SCHOOL: 'Pre School',
  CLASSES: 'Classes',
  OTHER: 'Other',
};
export const NOODLING_TYPES = {
  GROUP: 'GROUP',
  EXPERT: 'EXPERT',
  VIDEO: 'VIDEO',
  SCHOOL: 'SCHOOL',
  INSTITUTION: 'INSTITUTION',
};
