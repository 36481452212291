import * as React from 'react';
import { useSelector } from 'react-redux';
import isotype from 'assets/images/isotype.svg';
import { getAppSettings, isNonNoodleInstance } from 'utils/privateLabel/index';
import LoaderWrapper from './Loader.style';
import type { LoaderProps } from './Loader.types';

const Loader = ({ height, position, top, width = '4.2rem', backgroundColor }: LoaderProps) => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const appSettings = getAppSettings();

  const { companyName, companySmallLogo } = appSettings ?? globalPartner;
  return (
    <LoaderWrapper
      backgroundColor={backgroundColor}
      style={{
        height,
        position,
      }}
      role='status'
      aria-label='Loading, Please waite.'
    >
      <img
        style={{
          width,
          top,
          height: '4.2rem',
        }} // Probably also want to check whether the logo is a react component or link?
        // @ts-expect-error
        src={isNonNoodleInstance ? companySmallLogo : isotype}
        alt={`${companyName} logo`}
        aria-label={`${companyName} logo`}
        title={`${companyName} logo`}
        loading='lazy'
        width='100%'
        height='100%'
      />
    </LoaderWrapper>
  );
};

export default Loader;
