import type { UserProfile } from 'commons/types';

/**
 * @description
 * Shows the username, handling the cases where we get first or last or just
 * name
 * @param {User} userProfile User object
 */
export const fullName = (userProfile: UserProfile = {}): string => {
  const { name, firstName, lastName } = userProfile;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (name) {
    return name;
  }

  return 'Noodle User';
};

/**
 * @description
 * Shows the user first name, handling the cases where we get first or last or just
 * name
 * @param {User} userProfile User object
 */
export const formattedFirstName = (userProfile: UserProfile): string => {
  if (userProfile) {
    const { name, firstName } = userProfile;

    if (firstName) {
      return firstName;
    }

    if (name) {
      return name;
    }
  }

  return 'Noodle User';
};
