// @ts-nocheck
import * as React from 'react';
import Loader from 'components/uielements/Loader/index';
import { accessibilityConstants } from 'commons/constants/general';
import { ReactComponent as HamburgerMenuIcon } from 'assets/svg/hamburger_menu.svg';
import HamburgerMenuWrapper from './HamburgerMenu.style';
import type { HamburgerMenuProps, HamburgerMenuState } from './HamburgerMenu.types';

const Menu = React.lazy(() => import('./Menu'));

class HamburgerMenu extends React.Component<HamburgerMenuProps, HamburgerMenuState> {
  constructor(props: HamburgerMenuProps) {
    super(props);
    this.state = {
      open: false,
      openSearch: false,
      triggerAnimation: false,
    };
  }

  componentDidMount() {
    document.addEventListener('resize', this.handleResize);
    document.addEventListener('touchmove', this.handleScroll, {
      passive: false,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('resize', this.handleResize);
    document.removeEventListener('touchmove', this.handleScroll);
  }

  handleScroll = (e: any) => {
    const { open } = this.state;

    if (open && !e.target.className.includes('explore-menu')) {
      e.preventDefault();
    }
  };

  onToggleMenuHandler = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
      triggerAnimation: true,
    }));
  };

  onOpenSearchScreen = () => {
    this.setState((prevState) => ({
      openSearch: !prevState.openSearch,
    }));
  };

  handleResize = () => {
    if (window.innerWidth >= 767) {
      this.setState({
        open: false,
        triggerAnimation: false,
      });
    }
  };

  render() {
    const { open, triggerAnimation } = this.state;
    return (
      <HamburgerMenuWrapper>
        <div
          role='button'
          onClick={this.onToggleMenuHandler}
          onKeyPress={this.onToggleMenuHandler}
          tabIndex={0}
          title={accessibilityConstants.menu.title}
          aria-expanded={open}
          aria-controls='menuId'
          aria-label={accessibilityConstants.menu.ariaLabel}
        >
          <HamburgerMenuIcon />
        </div>
        <React.Suspense fallback={<Loader />}>
          <Menu
            open={open}
            onMenuClose={this.onToggleMenuHandler}
            triggerAnimation={triggerAnimation}
            id='menuId'
          />
        </React.Suspense>
      </HamburgerMenuWrapper>
    );
  }
}

export default HamburgerMenu;
