/**
 * @description
 * Converts dash-case text to camelCase
 * Pre: Text must be in dash-case case
 * @param {String} text Text to be parsed
 */
const dashToCamelCase = (text: string): string =>
  text.replace(/(-[a-z])/g, (x) => x.toUpperCase().replace('-', ''));

export default dashToCamelCase;
