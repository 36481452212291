import React from 'react';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import BaseCard from '../BaseCard';
import { PaidTitle, PaidLink, StarIconWrapper } from './PaidCard.styles';

const PaidCard = ({ onClick }: { onClick: () => void }) => (
  <BaseCard
    icon={
      <StarIconWrapper>
        <StarBorderOutlinedIcon />
      </StarIconWrapper>
    }
  >
    <PaidTitle>Upgrade to Premium</PaidTitle>
    <PaidLink onClick={onClick}>Upgrade</PaidLink>
  </BaseCard>
);

export default PaidCard;
