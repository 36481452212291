// @ts-nocheck
import type { StatelessFunctionalComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { CELLPHONE_LARGE_WIDTH } from 'commons/constants';

// Might be used later. Right now only being used to style the datepicker though :)
export const GlobalStyles: StatelessFunctionalComponent<any> = createGlobalStyle`
  && {
    .MuiDialogActions-root {
      .MuiButton-root {
        ${(props) => props.theme.noodera.fontTypes.font5};
      }
    }

    .PrivatePickersYear-root {
      .PrivatePickersYear-yearButton {
        ${(props) => props.theme.noodera.fontTypes.font5} !important;
      }
    }

    .MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
      display: none;
    }

    .Mui-selected {
      background: none !important;
    }

    .MuiButtonBase-root.MuiMenuItem-root:hover {
      background-color: ${(props) => props.theme.palette.primary.ten};
      color: ${(props) => props.theme.palette.primary.hundred};
    }

    .MuiCalendarPicker-root {
      /* First child under the calendar modal */

      & > div:first-child {
        /* Selecting only prev/next month arros */

        & > div:nth-child(2) {
          .MuiSvgIcon-root {
            font-size: 2.4rem !important;
          }
        }

        /* Selects the year dropdown */

        & > div:first-child {
          ${(props) => props.theme.noodera.fontTypes.font4};
          border-radius: 8px;
          border: 1px solid ${(props) => props.theme.palette.neutral.forty};
          padding: ${(props) => props.theme.noodera.spacing.x8} ${(props) =>
  props.theme.noodera.spacing.x6};
          max-height: fit-content;
        }
      }

      .MuiInputLabel-root {
        ${(props) => props.theme.noodera.fontTypes.font5};
      }

      .MuiInputBase-input {
        ${(props) => props.theme.noodera.fontTypes.font5};
      }

      .MuiButtonBase-root {
        ${(props) => props.theme.noodera.fontTypes.font5};
      }

      .MuiTypography-root {
        ${(props) => props.theme.noodera.fontTypes.font5};
      }
    }

    /* Noodera Date Picker */
    .MuiPaper {
      &-rounded {
        border-radius: 8px !important;
      }

      &-elevation8 {
        box-shadow: 0 2px 10px ${(props) => props.theme.palette.alphas.black20} !important;
      }

      &-root {
        .MuiPickersToolbar-toolbar {
          align-items: center;
          background-color: ${(props) => props.theme.palette.primary.hundred};
          display: flex;
          flex-direction: row;
          height: 64px;
          gap: ${(props) => props.theme.noodera.spacing.x7};
          justify-content: center;
          padding: ${(props) => props.theme.noodera.spacing.x5};

          & > button {
            height: 32px;
            border-radius: 12px;
            background: ${(props) => props.theme.palette.greyscale.white};
            border: 1px solid ${(props) => props.theme.palette.primary.hundred};

            &:first-child {
              width: 73px;
              order: 2;
            }

            &:last-child {
              width: 196px;
            }

            .MuiPickersToolbarText {
              &-toolbarBtnSelected {
                color: ${(props) => props.theme.palette.primary.hundredTwenty};
              }

              &-toolbarTxt {
                ${(props) => props.theme.noodera.fontTypes.font4Medium};
                color: ${(props) => props.theme.palette.primary.hundred};
              }
            }
          }
        }

        .MuiPickersBasePicker {
          &-container {
            width: 312px;
          }

          &-pickerView {
            max-width: 343px;
            margin: auto;

            .MuiSvgIcon-root {
              width: 32px;
              height: 32px;
            }

            .MuiTypography-alignCenter {
              ${(props) => props.theme.noodera.fontTypes.font4};
              color: ${(props) => props.theme.palette.neutral.hundred};
            }

            .MuiPickersCalendar {
              &-transitionContainer {
                margin: 0;
                min-height: 200px;
              }

              &-week {
                height: 40px;
                align-items: center;
              }
            }

            .MuiPickersCalendarHeader {
              &-dayLabel {
                ${(props) => props.theme.noodera.fontTypes.font5};
                color: ${(props) => props.theme.palette.neutral.sixty};
              }

              &-switchHeader {
                padding: ${(props) => props.theme.noodera.spacing.x5} ${(props) =>
  props.theme.noodera.spacing.x5} ${(props) => props.theme.noodera.spacing.x4};
                margin: 0;

                .MuiIconButton-root {
                  padding: 0;
                }
              }

              &-daysHeader {
                min-height: 40px;
              }
            }

            .MuiPickersYearSelection-container {
              padding: ${(props) => props.theme.noodera.spacing.x5};
            }

            .MuiPickersYear {
              &-root {
                ${(props) => props.theme.noodera.fontTypes.font4Medium};
                color: ${(props) => props.theme.palette.primary.hundred};
                border: 1px solid ${(props) => props.theme.palette.primary.hundred};
                border-radius: 12px;
                height: 48px;
                margin: 0 0 ${(props) => props.theme.noodera.spacing.x6};
              }

              &-yearSelected {
                background: ${(props) => props.theme.palette.primary.twenty};
                border: 1px solid ${(props) => props.theme.palette.primary.hundredTwenty}
              }
            }
          }
        }

        .MuiPickersDay {
          &-day {
            color: ${(props) => props.theme.palette.neutral.hundred};

            &:focus {
              background-color: ${(props) => props.theme.palette.primary.ten};
              border: 2px solid ${(props) => props.theme.palette.secondary.hundred};
              color: ${(props) => props.theme.palette.neutral.hundred};
            }

            &:active {
              background: ${(props) => props.theme.palette.primary.ten};
            }
  
            .MuiTypography-root {
              ${(props) => props.theme.noodera.fontTypes.font5};
            }
          }

          &-dayDisabled {
            color: ${(props) => props.theme.palette.neutral.sixty};
          }

          &-daySelected {
            background-color: ${(props) => props.theme.palette.primary.hundred};
            color: ${(props) => props.theme.palette.greyscale.white};
          }
        }
      }
    }

    @media only screen and (max-width: ${CELLPHONE_LARGE_WIDTH}px) {
      .MuiPaper-root {
        width: 100% !important;
        max-width: 100% !important;
        left: 0 !important;

        .MuiPickersBasePicker {
          &-container {
            width: 100%;
          }

          &-pickerView {
            min-width: 100%;
          }
        }

        .MuiPickersToolbar-toolbar > button:last-child {
          width: 100%;
        }
      }

      .MuiPaper-rounded {
        border-radius: unset !important;
      }

      .MuiDialog-paper {
        margin: 0 !important;
      }
    }
  }
`;
