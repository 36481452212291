import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { Course, LMS } from 'commons/types/courseTypes';
import { isNonNoodleInstance } from 'utils/privateLabel';
import NooderaModal from 'components/NooderaModal';
import ExclamationIcon from '@mui/icons-material/Error';
import NooderaTag from 'components/NooderaTag';
import { useSelector } from 'react-redux';
import BaseCard from '../BaseCard';

import {
  CertificateButton,
  CoursePaymentPendingTagContainer,
  CourseSubtext,
  CourseTitle,
} from './CourseCard.styles';

interface CourseCardProps {
  course: Course;
  startDate?: string;
  [key: string]: any;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, startDate, ...extraProps }) => {
  const history = useHistory();
  const [showAwaitingCourseStartModal, setShowAwaitingCourseStartModal] = React.useState(false);
  const [showFailedEnrollmentPendingModal, setShowFailedEnrollmentPendingModal] =
    React.useState(false);

  const { data: globalPartner } = useSelector((state) => state.partnerInstance?.globalPartner);

  const backgroundImage = course.cardImage.url;
  const courseName = course.shortTitle;
  const finalSurvey = course?.surveys?.find(
    (survey) =>
      survey?.percentageComplete === 100 && course?.courseStatus?.toLowerCase() === 'completed',
  );
  const selectedSurvey = course?.surveys?.find(
    (survey) => course?.percentCourseComplete >= survey?.percentageComplete,
  );
  const hasCertificate =
    isNonNoodleInstance &&
    course?.courseStatus?.toLowerCase() === 'completed' &&
    course?.certificateUuid;
  const isViewAccessDateReached = moment().isSameOrAfter(
    // TODO: 21 days as placeholder per UC, this should be dynamic in the future
    moment(startDate).subtract(21, 'days'),
  );

  const redirectToCertificate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    history.push(`/certificates/${course?.certificateUuid}`);
  };

  const getSurveyUrl = (surveyId: number): string => {
    const isFinalSurvey = String(finalSurvey?.id) === String(surveyId);
    // if it's not the final survey redirect to the BS course after finishing the survey
    // otherwise we'll redirect to the certificate from the survey saga
    return `/survey/${course.slug}/${surveyId}${
      !isFinalSurvey
        ? `?redirect_url=${encodeURIComponent(
            course?.lms === LMS.Brightspace ? course?.brightspaceUrl : course?.courseUrl || '/',
          )}`
        : ''
    }`;
  };

  const getRedirectUrl = (): string => {
    if (selectedSurvey) {
      return getSurveyUrl(selectedSurvey.id);
    }

    if (finalSurvey) {
      return getSurveyUrl(finalSurvey.id);
    }

    if (course?.lms) {
      return course?.lms === LMS.Brightspace ? course?.brightspaceUrl : course?.courseUrl;
    }

    return course?.brightspaceUrl ?? course?.courseUrl;
  };

  const renderCourseCardText = (): string => {
    if (hasCertificate && globalPartner?.isCertificateGenerationEnabled) {
      return '';
    }

    if (course?.courseStatus === 'Completed') {
      return 'Completed';
    }

    if (course?.membershipStatus === 'pending' || course?.membershipStatus === 'failed') {
      return 'Pending enrollment';
    }

    if (!startDate) {
      return '';
    }

    const momentStartDate = moment(startDate);

    const hasStarted = momentStartDate.isBefore(moment());

    // if course is not completed or doesn't contain certificateUUID just return the date
    return `${hasStarted ? 'Started' : 'Starting'} ${momentStartDate.format('MMM DD')}`;
  };

  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const redirectUrl = getRedirectUrl();

    // If view access date is not reached, show the awaiting course start date modal
    if (course?.membershipStatus === 'payment_pending') {
      history.push(`/courses/${course?.slug}/enroll`);
    } else if (
      (course?.membershipStatus === 'pending' || !isViewAccessDateReached) &&
      !hasCertificate
    ) {
      setShowAwaitingCourseStartModal(!showAwaitingCourseStartModal);
    } else if (course?.membershipStatus === 'failed') {
      setShowFailedEnrollmentPendingModal(!showFailedEnrollmentPendingModal);
    } else if (selectedSurvey || finalSurvey) {
      history.push(redirectUrl);
    } else {
      window.location.href = redirectUrl;
    }
  };

  return (
    <BaseCard
      isInverted
      backgroundImage={backgroundImage}
      icon={<div />}
      onClick={onClick}
      {...extraProps}
    >
      <CourseTitle>{courseName}</CourseTitle>
      {course?.membershipStatus === 'payment_pending' ? (
        <CoursePaymentPendingTagContainer>
          <NooderaTag
            aria-label='Pending Payment Tag'
            icon={<ExclamationIcon />}
            title='Pending Payment Tag'
            text='Payment Pending'
          />
        </CoursePaymentPendingTagContainer>
      ) : (
        <CourseSubtext>{renderCourseCardText()}</CourseSubtext>
      )}

      {hasCertificate && globalPartner?.isCertificateGenerationEnabled && (
        <CertificateButton size='small' onClick={redirectToCertificate}>
          Download Certificate
        </CertificateButton>
      )}
      <NooderaModal.Small
        title='Awaiting Course Start'
        isOpen={showAwaitingCourseStartModal}
        icon={<InfoIcon />}
        description='Your enrollment is complete. The course will be available in the learning platform soon.'
        btnText='Continue'
        onBtnClick={() => setShowAwaitingCourseStartModal(false)}
      />
      <NooderaModal.Small
        title='Enrollment Pending'
        isOpen={showFailedEnrollmentPendingModal}
        icon={<InfoIcon />}
        description='Your enrollment is pending. The course will be available in the learning platform soon.'
        btnText='Continue'
        onBtnClick={() => setShowFailedEnrollmentPendingModal(false)}
      />
    </BaseCard>
  );
};

export default CourseCard;
