// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { rtkQApi } from 'lib/Api';
import rootSaga from './sagas';
import createRootReducer from './reducers';
import listenerMiddleware from './listeners';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .prepend(listenerMiddleware.middleware)
      .concat(routerMiddleware(history))
      .concat(sagaMiddleware)
      .concat(rtkQApi.middleware),
  reducer: createRootReducer(history),
  devTools: process.env.NODE_ENV !== 'production' && typeof window === 'object',
});
sagaMiddleware.run(rootSaga);

export default store;
