import type {
  AdminNoodlingObject,
  FiltersObject,
  GetAdminNoodlingsSuccessPayload,
  GetAdminNoodlingsRequestPayload,
  GetSuggestionsRequestPayload,
  SuggestionType,
} from 'commons/types';
import {
  filterAdminNoodlingsTypes,
  getAdminNoodlingByIdTypes,
  getAdminNoodlingsTypes,
  getSuggestionsTypes,
} from './types';

export const adminNoodlingActions = {
  getNoodlingByIdRequest: (payload: string) => ({
    type: getAdminNoodlingByIdTypes.REQUEST,
    payload,
  }),
  getNoodlingByIdSuccess: (payload: AdminNoodlingObject) => ({
    type: getAdminNoodlingByIdTypes.SUCCESS,
    payload,
  }),
  getNoodlingByIdFailed: (payload: string) => ({
    type: getAdminNoodlingByIdTypes.FAILED,
    payload,
  }),
  getNoodlingsRequest: (payload: GetAdminNoodlingsRequestPayload) => ({
    type: getAdminNoodlingsTypes.REQUEST,
    payload,
  }),
  getNoodlingsSuccess: (payload: GetAdminNoodlingsSuccessPayload) => ({
    type: getAdminNoodlingsTypes.SUCCESS,
    payload,
  }),
  getNoodlingsFailed: (payload: string) => ({
    type: getAdminNoodlingsTypes.FAILED,
    payload,
  }),
  setNoodlingsCurrentPage: (payload: number) => ({
    type: getAdminNoodlingsTypes.SET_CURRENT_PAGE,
    payload,
  }),
};
export const adminNoodlingFiltersActions = {
  getSuggestionsRequest: (payload: GetSuggestionsRequestPayload) => ({
    type: getSuggestionsTypes.REQUEST,
    payload,
  }),
  getSuggestionsSuccess: (payload: SuggestionType[]) => ({
    type: getSuggestionsTypes.SUCCESS,
    payload,
  }),
  getSuggestionsFailed: (payload: string) => ({
    type: getSuggestionsTypes.FAILED,
    payload,
  }),
  clearSuggestions: () => ({
    type: getSuggestionsTypes.CLEAR,
  }),
  updateFilters: (payload: FiltersObject) => ({
    type: filterAdminNoodlingsTypes.UPDATE_FILTERS,
    payload,
  }),
};
