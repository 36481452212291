import { Course, PaidCourseDepositData, Sys } from './courseTypes';
import { PartnerInstance } from './partnerInstanceTypes';
import { InvoiceInterface } from './stripeTypes';

export interface DonationAskData {
  name: string;
  description: string;
  courses: {
    slug: string;
  }[];
  coverImage: {
    url: string;
  };
  stripeProductId: string;
  stripePriceId: string;
  amountInCents: number;
  sys: Sys;
  partner: PartnerInstance;
}

export enum CouponTypeEnum {
  PERCENT_OFF = 'PERCENT_OFF',
  AMOUNT_OFF = 'AMOUNT_OFF',
}

interface ContentfulData extends Course, DonationAskData {}

export interface CartItemMetadata {
  enrollmentData: {
    teamId: number;
  };
  contentfulData: ContentfulData;
  appliesToCartItemId?: number;
}

export interface CartItemInterface {
  id: number;
  externalId: string;
  quantity: number;
  shoppingCartId: number;
  cartItemMetadata: CartItemMetadata;
  createdAt: string;
  updatedAt: string;
  itemType: string;
  deposit?: PaidCourseDepositData;
  isDepositPayment?: boolean;
}

export interface CouponInterface {
  id: string;
  object: string;
  amountOff: number;
  created: number;
  currency: string;
  duration: string;
  durationInMonths: number;
  livemode: boolean;
  maxRedemptions: number;
  metadata: any;
  name: string;
  percentOff: number;
  redeemBy: string;
  timesRedeemed: number;
  valid: boolean;
  expirationDate: Date;
  appliesTo?: {
    products: string[];
  };
}

export interface PromotionCodeInterface {
  id: string;
  object: string;
  active: boolean;
  code: string;
  coupon: CouponInterface;
  created: number;
  customer: string;
  expiresAt: string;
  livemode: boolean;
  maxRedemptions: number;
  metadata: any;
  // Not part of stripe, added as custom field
  nlpDiscountedAmount?: number;
  restrictions: any;
  timesRedeemed: number;
}

export interface ShoppingCartInterface {
  id: number;
  sessionId: string;
  userId: number;
  partnerId: string;
  cartItems: CartItemInterface[];
  cartItemsCount: number;
  cartUrl: string;
  subtotal: number;
  adjustedSubtotal: number;
  invoiceId?: string;
  couponsData?: PromotionCodeInterface[];
  currency?: string;
}

export interface DeletedCouponResponseInterface {
  deleted: string;
}

export interface DeletedCartItemResponseInterface {
  id: number;
  deleted: boolean;
}

export interface ShoppingCartInvoiceInterface {
  invoice: InvoiceInterface;
  lineItems: any;
  paymentIntent: any;
  isPaid: boolean;
}

interface SuccessfulEnrollmentInterface {
  teamId: number;
  courseId: string;
}

interface AlreadyEnrolledInterface {
  teamId: number;
  courseId: string;
}

interface UnsuccessfulEnrollmentInterface {
  userId: number;
  courseId: string;
}

export interface ShoppingCartCheckoutInterface {
  enrollments: SuccessfulEnrollmentInterface[];
  unsuccessfulEnrollments: UnsuccessfulEnrollmentInterface[];
  alreadyEnrolled: AlreadyEnrolledInterface[];
}

export const NOODLE_CART_SESSION_ID_COOKIE_KEY = 'noodle_cart_session_id';
