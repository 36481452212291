import * as React from 'react';
import { percentageValue } from 'utils/string';
import {
  ContainerProgress,
  PercentStyle,
  ProgressBarBackground,
  ProgressBarValue,
  ProgressBarWrapper,
} from './ProgressBar.style';
import type { ProgressBarProps } from './ProgressBar.types';

const ProgressBar = ({ value, total }: ProgressBarProps) => {
  const parsedValue = percentageValue(value, total);
  return (
    <ProgressBarWrapper>
      <ContainerProgress>
        <PercentStyle>{parsedValue}</PercentStyle>
        <ProgressBarBackground>
          <ProgressBarValue value={parsedValue} />
        </ProgressBarBackground>
      </ContainerProgress>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
