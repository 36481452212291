// @ts-nocheck
import styled from 'styled-components';
import { getTheme } from 'settings/theme';
import type { StatelessFunctionalComponent } from 'react';

const {
  noodera: { spacing },
} = getTheme();
export const PaginationContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: ${spacing.x3};
`;
