import type { HasMaxValueParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a value is lesser or equal to a given value
 */
const hasMaxValue = ({
  name,
  value,
  outputName = 'This field',
  maxValue,
}: HasMaxValueParams): ValidationFieldError => {
  const errors = {};
  const numberValue = Number(value);
  errors[name] =
    numberValue > maxValue
      ? {
          errorMsg: `${outputName} must be less or equal to ${maxValue}`,
        }
      : {};
  return errors;
};

export default hasMaxValue;
