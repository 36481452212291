// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import { Link } from 'react-router-dom';
import { TABLET_WIDTH } from 'commons/constants';

const {
  palette: { primary, greyscale, secondary },
  noodera: { fontTypes },
} = getTheme();
const lightActive = `
        background: linear-gradient(90deg, ${primary.hundred} 0%, #367D5D 100%);
        color: ${greyscale.white};
        pointer-events: none;

        &:hover {
          background: ${secondary.hundred};
          color: ${greyscale.white};
        }
        
        &:active {
          background: ${primary.hundredTwenty};
        }
        
        &:focus {
          background: ${primary.eighty};
        }
      `;
const lightInactive = `
        background: ${greyscale.white};
        color: ${primary.hundred};
        border: 1px solid ${primary.hundred};

        &:hover {
          background: ${primary.ten};
          color: ${primary.hundred};
        }
        
        &:active {
          background: ${primary.twenty};
        }
        
        &:focus {
          background: ${primary.ten};
        }
    `;
const darkActive = `
        background: ${greyscale.white};
        color: ${primary.hundred};
        pointer-events: none;

        &:hover {
          background: ${primary.ten};
          color: ${primary.hundred};
        }
        
        &:active {
          background: ${primary.twenty};
        }
        
        &:focus {
          background: ${primary.ten};
        }
      `;
const darkInactive = `
        background: ${primary.hundred};
        color: ${greyscale.white};
        border: 1px solid ${greyscale.white};

        &:hover {
          background: ${secondary.hundred};
          color: ${greyscale.white};
        }
        
        &:active {
          background: ${primary.hundredTwenty};
        }
        
        &:focus {
          background: ${primary.eighty};
        }
    `;

const getTabStyles = (props: Record<string, any>) => {
  switch (props.theme) {
    case 'light': {
      return props.isActive ? lightActive : lightInactive;
    }

    case 'dark': {
      return props.isActive ? darkActive : darkInactive;
    }

    default:
      return null;
  }
};

export const TabsContainer: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  border-radius: 8px;
  width: fit-content;
  min-width: 25rem;

  background: transparent;

  a:first-child {
    & {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      @media screen and (max-width: ${TABLET_WIDTH}px) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }

  a:last-child {
    & {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;

      @media screen and (max-width: ${TABLET_WIDTH}px) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
`;
export const StyledLink: StatelessFunctionalComponent<any> = styled(Link)`
  display: flex;
  flex: 1;
  ${fontTypes.font5Medium};
  ${(props) => getTabStyles(props)}
  padding: .8rem 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
