// @ts-nocheck
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import { getMessageConnections } from 'ducks/userConnection/selector';
import DesktopMenu from 'components/DesktopMenu';
import Navbar from '..';
import type { NavbarSignedInProps, NavbarSignedInState } from './NavbarSignedIn.types';
import NavbarSignedInWrapper from './NavbarSignedIn.styles';

class NavbarSignedIn extends React.Component<NavbarSignedInProps, NavbarSignedInState> {
  constructor(props: NavbarSignedInProps) {
    super(props);
    const {
      match: {
        params: { term },
      },
    } = this.props;
    this.state = {
      displayProfileMenu: false,
      displayMessagesMenu: false,
      search: term || '',
      triggerAnimation: !!term,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onCleanSearch = (): void => {
    this.setState({
      search: '',
    });
  };

  onInputChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = e;
    this.setState({
      search: value,
    });
  };

  onSearch = (): void => {
    const { search } = this.state;
    const { history } = this.props;

    if (search.length) {
      history.push(`/search/${search}`);
    }
  };

  onEnterSearch = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  };

  onToggleMenu = (menu: string): void => {
    const displayMenu = `display${menu}Menu`;

    const { [displayMenu]: displayMenuValue } = this.state;

    if (displayMenu !== 'displayMessagesMenu') {
      this.setState(
        {
          displayProfileMenu: false,
          displayMessagesMenu: false,
        },
        () =>
          this.setState({
            [displayMenu]: !displayMenuValue,
          }),
      );
    } else {
      this.setState(
        {
          displayProfileMenu: false,
          displayMessagesMenu: true,
        },
        () =>
          this.setState({
            [displayMenu]: true,
          }),
      );
    }
  };

  setMessagesMenuRef = (node: any) => {
    this.messagesMenuRef = node;
  };

  setProfileMenuRef = (node: any) => {
    this.profileMenuRef = node;
  };

  messagesMenuRef: any;

  profileMenuRef: any;

  handleClickOutside = ({ target }: MouseEvent): void => {
    const hideMessagesMenu = this.messagesMenuRef && !this.messagesMenuRef.contains(target);
    const hideProfileMenu = this.profileMenuRef && !this.profileMenuRef.contains(target);

    if (hideMessagesMenu) {
      this.setState({
        displayMessagesMenu: false,
      });
    }

    if (hideProfileMenu) {
      this.setState({
        displayProfileMenu: false,
      });
    }
  };

  render() {
    const { displayMessagesMenu, displayProfileMenu, search, triggerAnimation } = this.state;
    const {
      userId,
      profile,
      selectedAccount,
      onAcceptUserConnection,
      onRejectUserConnection,
      messages,
      isAdmin,
      pendingConnections,
    } = this.props;
    return (
      <Navbar>
        <NavbarSignedInWrapper>
          <DesktopMenu
            isAdmin={isAdmin}
            displayMessagesMenu={displayMessagesMenu}
            displayProfileMenu={displayProfileMenu}
            messages={messages}
            onAcceptUserConnection={onAcceptUserConnection}
            onInputChange={this.onInputChange}
            onRejectUserConnection={onRejectUserConnection}
            onSearch={this.onSearch}
            onToggleMenu={this.onToggleMenu}
            onCleanSearch={this.onCleanSearch}
            pendingConnections={pendingConnections}
            profile={profile}
            search={search}
            selectedAccount={selectedAccount}
            setMessagesMenuRef={this.setMessagesMenuRef}
            setProfileMenuRef={this.setProfileMenuRef}
            triggerAnimation={triggerAnimation}
            userId={userId}
          />
        </NavbarSignedInWrapper>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: [],
  pendingConnections: getMessageConnections(state),
  isAdmin: get(state, 'user.isAdmin', false),
});

export default withRouter(connect(mapStateToProps)(NavbarSignedIn));
