import { PAGINATION_LIMIT } from 'commons/constants';
import type { UrlComposerOptions } from './String.types';

export const urlComposer = (
  endpoint: string,
  {
    filter,
    fieldsEntity,
    fieldsList,
    includes,
    imageWidth,
    page,
    sorting,
  }: UrlComposerOptions = {},
): string => {
  const pageQuery = page ? `page[number]=${page}&page[size]=${PAGINATION_LIMIT}` : '';
  const filterQuery = filter ? `filter=${filter}` : '';
  const fieldsQuery = fieldsEntity && fieldsList ? `fields[${fieldsEntity}]=${fieldsList}` : '';
  const includeQuery = includes ? `include=${includes}` : '';
  const sortingQuery = sorting ? `sort=${sorting}` : '';
  const imageWidthQuery = imageWidth ? `image_w=${imageWidth}` : '';
  const queries = [
    pageQuery,
    filterQuery,
    fieldsQuery,
    includeQuery,
    sortingQuery,
    imageWidthQuery,
  ].filter((query) => query !== '');
  let finalQuery = endpoint;
  queries.forEach((query, i) => {
    const nextQuerySymbol = i === 0 ? '?' : '&';
    finalQuery += nextQuerySymbol + query;
  });
  return finalQuery;
};
export const getQueryStringParams = (param: string): string => {
  const pageUrl = window.location.search.substring(1);
  const urlVariables = pageUrl.split('&');
  let finalParam = '';
  urlVariables.forEach((variable: string) => {
    const parameterName = variable.split('=');

    if (parameterName[0] === param) {
      /* eslint-disable prefer-destructuring */
      finalParam = parameterName[1];
    }
  });
  return finalParam;
};
