const THERE_WAS_AN_ERROR = 'There was an error';
export const SECTION = {
  sectionsErrorMessage: `${THERE_WAS_AN_ERROR} fetching all teams`,
  sectionByIdErrorMessage: `${THERE_WAS_AN_ERROR} fetching team by ID`,
  createSectionErrorMessage: `${THERE_WAS_AN_ERROR} creating your Team`,
};
export const TA = {
  ErrorMessage: `${THERE_WAS_AN_ERROR} fetching teacher asistant by contentfulSlug`,
};
export const SectionType = {
  REGULAR: 'REGULAR',
  SOLO: 'SOLO',
  PENDING_ENROLLMENTS: 'PENDING_ENROLLMENTS',
};
