export const getSchedulerTypes = {
  REQUEST: 'SCHEDULER_GET_REQUEST',
  SUCCESS: 'SCHEDULER_GET_SUCCESS',
  FAILED: 'SCHEDULER_GET_FAILED',
};
export const getAoISchedulerTypes = {
  REQUEST: 'AOI_SCHEDULER_GET_REQUEST',
  SUCCESS: 'AOI_SCHEDULER_GET_SUCCESS',
  FAILED: 'AOI_SCHEDULER_GET_FAILED',
};
export const getSchedulerListTypes = {
  REQUEST: 'SCHEDULER_GET_LIST_REQUEST',
  SUCCESS: 'SCHEDULER_GET_LIST_SUCCESS',
  FAILED: 'SCHEDULER_GET_LIST_FAILED',
};
export const getSchedulerAvailabilityTypes = {
  REQUEST: 'GET_SCHEDULER_AVAILABILITY_REQUEST',
  SUCCESS: 'GET_SCHEDULER_AVAILABILITY_SUCCESS',
  FAILED: 'GET_SCHEDULER_AVAILABILITY_FAILED',
};
export const createBookingTypes = {
  REQUEST: 'CREATE_BOOKING_REQUEST',
  SUCCESS: 'CREATE_BOOKING_SUCCESS',
  FAILURE: 'CREATE_BOOKING_FAILURE',
  RESET: 'CREATE_BOOKING_RESET',
};
export const schedulerTypes = {
  SET_SCHEDULER_TIMEZONE: 'SET_SCHEDULER_TIMEZONE',
  CLICK_MEETING_HOUR: 'CLICK_MEETING_HOUR',
};
export const meetingTypes = {
  GET_MEETING_REQUEST: 'GET_MEETING_REQUEST',
  GET_MEETING_SUCCESS: 'GET_MEETING_SUCCESS',
  GET_MEETING_FAILURE: 'GET_MEETING_FAILURE',
};
