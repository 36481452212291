import type { DropdownItem } from 'commons/types';
// eslint-disable-next-line
export const generateDropdownNumberRange = (from: number, to: number): DropdownItem[] => {
  const items = [];

  for (let i = from; i <= to; i += 1) {
    const value = String(i);
    items.push({
      value,
      label: value,
    });
  }

  return items;
};
