import JsonApi from 'utils/jsonApi';
import type { SendCalendarSessionParams } from 'commons/types';

export default class AdminGeneral {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async sendCalendarSession({
    sessionToken: token,
    expirationDate,
  }: SendCalendarSessionParams): Promise<string> {
    await this.jsonApi.post(
      '/calendar',
      {
        token,
        expirationDate,
      },
      false,
    );
    return 'SUCCESS!';
  }
}
