export const filterAdminNoodlingsTypes = {
  UPDATE_FILTERS: 'ADMIN_NOODLINGS_UPDATE_FILTERS',
};
export const getAdminNoodlingByIdTypes = {
  REQUEST: 'ADMIN_NOODLING_GET_BY_ID_REQUEST',
  SUCCESS: 'ADMIN_NOODLING_GET_BY_ID_SUCCESS',
  FAILED: 'ADMIN_NOODLING_GET_BY_ID_FAILED',
};
export const getAdminNoodlingsTypes = {
  REQUEST: 'ADMIN_NOODLINGS_GET_REQUEST',
  SUCCESS: 'ADMIN_NOODLINGS_GET_SUCCESS',
  FAILED: 'ADMIN_NOODLINGS_GET_FAILED',
  SET_CURRENT_PAGE: 'ADMIN_NOODLINGS_SET_CURRENT_PAGE',
};
export const getSuggestionsTypes = {
  REQUEST: 'ADMIN_NOODLINGS_GET_SUGGESTIONS_REQUEST',
  SUCCESS: 'ADMIN_NOODLINGS_GET_SUGGESTIONS_SUCCESS',
  FAILED: 'ADMIN_NOODLINGS_GET_SUGGESTIONS_FAILED',
  CLEAR: 'ADMIN_NOODLING_CLEAR_SUGGESTIONS',
};
