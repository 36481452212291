import type {
  LandingPageObject,
  LandingPageListResponse,
  SuggestionType,
  GetLandingPagesPayload,
} from 'commons/types';
import {
  createLandingPageTypes,
  getLandingPageTypes,
  getParentsSuggestionsTypes,
  landingPageListTypes,
  updateLandingPageTypes,
  updateCriteriaTypes,
  landingPageSuggestionsTypes,
  landingPageEditModeTypes,
  landingPageCriteriaTypes,
} from './types';

const landingPagesActions = {
  createLandingPageRequest: (payload: LandingPageObject) => ({
    type: createLandingPageTypes.REQUEST,
    payload,
  }),
  createLandingPageSuccess: (payload: LandingPageObject) => ({
    type: createLandingPageTypes.SUCCESS,
    payload,
  }),
  createLandingPageFailed: (payload: string) => ({
    type: createLandingPageTypes.FAILED,
    payload,
  }),
  createLandingPageSetup: () => ({
    type: createLandingPageTypes.SET_UP,
  }),
  getLandingPageRequest: (payload: string) => ({
    type: getLandingPageTypes.REQUEST,
    payload,
  }),
  getLandingPageSuccess: (payload: LandingPageObject) => ({
    type: getLandingPageTypes.SUCCESS,
    payload,
  }),
  getLandingPageFail: (payload: string) => ({
    type: getLandingPageTypes.FAILED,
    payload,
  }),
  getLandingPagesRequest: (payload: GetLandingPagesPayload) => ({
    type: landingPageListTypes.REQUEST,
    payload,
  }),
  getLandingPagesSuccess: (payload: LandingPageListResponse) => ({
    type: landingPageListTypes.SUCCESS,
    payload,
  }),
  getLandingPagesFail: (payload: string) => ({
    type: landingPageListTypes.FAILED,
    payload,
  }),
  getParentsSuggestionsRequest: () => ({
    type: getParentsSuggestionsTypes.REQUEST,
  }),
  getParentsSuggestionsSuccess: (payload: { parentsSuggestions: SuggestionType[] }) => ({
    type: getParentsSuggestionsTypes.SUCCESS,
    payload,
  }),
  getParentsSuggestionsFailed: (payload: string) => ({
    type: getParentsSuggestionsTypes.FAILED,
    payload,
  }),
  setLandingPagesCurrentPage: (payload: number) => ({
    type: landingPageListTypes.SET_CURRENT_PAGE,
    payload,
  }),
  updateLandingPageRequest: (payload: LandingPageObject) => ({
    type: updateLandingPageTypes.REQUEST,
    payload,
  }),
  updateLandingPageSuccess: (payload: LandingPageObject) => ({
    type: updateLandingPageTypes.SUCCESS,
    payload,
  }),
  updateLandingPageFailed: (payload: string) => ({
    type: updateLandingPageTypes.FAILED,
    payload,
  }),
  updateCriteriaRequest: (payload: { id: number; criteria: string }) => ({
    type: updateCriteriaTypes.REQUEST,
    payload,
  }),
  updateCriteriaSuccess: (payload: LandingPageObject) => ({
    type: updateCriteriaTypes.SUCCESS,
    payload,
  }),
  updateCriteriaFailed: (payload: string) => ({
    type: updateCriteriaTypes.FAILED,
    payload,
  }),
  filterSuggestions: (payload: string) => ({
    type: landingPageSuggestionsTypes.FILTER,
    payload,
  }),
  clearSuggestions: () => ({
    type: landingPageSuggestionsTypes.CLEAR,
  }),
  changeEditMode: (payload: boolean) => ({
    type: landingPageEditModeTypes.CHANGE,
    payload,
  }),
  changeCriteriaEditMode: (payload: boolean) => ({
    type: landingPageCriteriaTypes.EDIT_MODE,
    payload,
  }),
};
export default landingPagesActions;
