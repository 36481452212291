export const sendSurveyAnswersActionTypes = {
  SEND_SURVEY_ANSWERS_REQUEST: 'SEND_SURVEY_ANSWERS_REQUEST',
  SEND_SURVEY_ANSWERS_SUCCESS: 'SEND_SURVEY_ANSWERS_SUCCESS',
  SEND_SURVEY_ANSWERS_FAILURE: 'SEND_SURVEY_ANSWERS_FAILURE',
};

export const sendSurveyAnswerActionTypes = {
  SEND_SURVEY_ANSWER_REQUEST: 'SEND_SURVEY_ANSWER_REQUEST',
  SEND_SURVEY_ANSWER_SUCCESS: 'SEND_SURVEY_ANSWER_SUCCESS',
  SEND_SURVEY_ANSWER_FAILURE: 'SEND_SURVEY_ANSWER_FAILURE',
};

export const getOnboardingSurveyActionTypes = {
  FETCH_ONBOARDING_SURVEY_REQUEST: 'FETCH_ONBOARDING_SURVEY_REQUEST',
  FETCH_ONBOARDING_SURVEY_SUCCESS: 'FETCH_ONBOARDING_SURVEY_SUCCESS',
  FETCH_ONBOARDING_SURVEY_FAILURE: 'FETCH_ONBOARDING_SURVEY_FAILURE',
};
