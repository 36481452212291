import { TABLET_SMALL_WIDTH } from 'commons/constants';
import styled from 'styled-components';

export const CardSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.noodera.spacing.x4};
`;

export const CardSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.noodera.spacing.x7};
`;

export const CardSectionTitle = styled.span`
  ${({ theme }) => theme.fontTypes.font4Semi};
`;

export const CardSectionSubtitle = styled.span`
  ${({ theme }) => theme.fontTypes.font5};
`;

export const CardSectionCardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.noodera.spacing.x6};

  & > div {
    width: 312px;
  }

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;
