// @ts-nocheck
import { delay, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import api from 'lib/Api';
import type { GetUsersBodyType, UserObject } from 'commons/types';
import {
  getUsersTypes,
  getUserByIdTypes,
  getUserRolesTypes,
  updateUserTypes,
  updateUserRolesTypes,
} from './types';
import { adminUserActions } from './actions';

export function* getUserRolesSaga(): Saga<void> {
  try {
    const roles = yield call([api.adminUser, 'getUserRoles']);
    yield put(adminUserActions.getUserRolesSuccess(roles));
  } catch (error) {
    yield put(adminUserActions.getUserRolesFailed(error));
  }
}
export function* getUsersSaga({ payload }: { payload: GetUsersBodyType }): Saga<void> {
  try {
    yield delay(300);
    const users = yield call([api.adminUser, 'getAllUsers'], payload);
    yield put(adminUserActions.getUsersSuccess(users));
  } catch (error) {
    yield put(adminUserActions.getUsersFailed(error));
  }
}
export function* getUserByIdSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const user = yield call([api.adminUser, 'getUserById'], payload);
    yield put(adminUserActions.getUserByIdSuccess(user));
    yield put(adminUserActions.getUserRolesRequest());
  } catch (error) {
    yield put(adminUserActions.getUserByIdFailed(error));
  }
}
export function* updateUserSaga({ payload }: { payload: UserObject }): Saga<void> {
  try {
    const user = yield call([api.adminUser, 'updateUser'], payload);
    yield put(adminUserActions.updateUserSuccess(user));
  } catch (error) {
    yield put(adminUserActions.updateUserFailed(error));
  }
}
export function* updateUserRolesSaga({ payload }: { payload: Record<string, any> }): Saga<void> {
  try {
    const { user, roles } = payload;
    const response = yield call([api.adminUser, 'updateRoles'], user, {
      roles,
    });
    yield put(adminUserActions.updateUserRolesSuccess(response));
  } catch (error) {
    yield put(adminUserActions.updateUserRolesFailed(error));
  }
}
export default function* adminUserSaga(): Saga<void> {
  yield takeLatest(getUsersTypes.REQUEST, getUsersSaga);
  yield takeLatest(getUserByIdTypes.REQUEST, getUserByIdSaga);
  yield takeEvery(updateUserTypes.REQUEST, updateUserSaga);
  yield takeEvery(updateUserRolesTypes.REQUEST, updateUserRolesSaga);
  yield takeLatest(getUserRolesTypes.REQUEST, getUserRolesSaga);
}
