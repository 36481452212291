// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';

const SvgImageProps: StatelessFunctionalComponent<any> = styled.span`
  svg path {
    fill: ${({ color }) => color} !important;
  }
`;
export default SvgImageProps;
