import { getGlobalSearchTypes, globalSearchTypes } from './types';

export const initState = {
  searchResults: [],
  counts: {},
  isLoading: false,
  currentTaxonomy: '',
  currentPage: 1,
};

const globalSearchReducer = (state = initState, action) => {
  const { type, payload } = action;
  let result;

  switch (type) {
    case getGlobalSearchTypes.REQUEST: {
      result = { ...state, error: null, isLoading: true };
      break;
    }

    case getGlobalSearchTypes.FAILED: {
      result = { ...state, error: payload, isLoading: false };
      break;
    }

    case getGlobalSearchTypes.SUCCESS: {
      result = {
        ...state,
        ...payload,
        isLoading: false,
        counts: payload.contentType ? state.counts : payload.counts,
      };
      break;
    }

    case globalSearchTypes.RESULTS_PAGE: {
      result = { ...state, currentPage: payload };
      break;
    }

    case globalSearchTypes.SET_CURRENT_TAXONOMY: {
      result = { ...state, currentTaxonomy: payload };
      break;
    }

    case globalSearchTypes.RESET_SEARCH: {
      return initState;
    }

    default:
      return state;
  }

  return result;
};

export default globalSearchReducer;
