// @ts-nocheck
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { enrollActions } from 'ducks/courses/actions';
import University from 'components/University';
import PlanCard from 'components/PlanCard';
import Payment from 'screens/EnrollCourse/Payment';
import NooderaModal from 'components/NooderaModal';
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg';
import { sectionActions } from 'ducks/section/actions';
import { Header, Container, Wrapper, Title } from './BecomePremiumModal.styles';
import type { BecomePremiumModalProps } from './BecomePremiumModal.types';

const BecomePremiumModal = ({
  isOpen,
  icon,
  cardTitle,
  planDetails,
  price,
  courseData,
  sectionId,
}: BecomePremiumModalProps): React.ReactElement<any> => {
  const dispatch = useDispatch();
  const [isPaidFlow, setPaidFlow] = React.useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
  const { shortTitle, school, slug } = courseData ?? {};
  const history = useHistory();

  const handleFinish = (): void => {
    setIsSuccessModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsSuccessModalOpen(false);
    setPaidFlow(false);
    history.push('/');
    dispatch(sectionActions.getSectionPreviewRequest(sectionId));
    dispatch(enrollActions.enrollModalReset());
  };

  const showHeader = (): React.ReactElement<any> | null => (
    <Header>{isPaidFlow ? 'Payment' : shortTitle}</Header>
  );

  const showPlan = (): React.ReactElement<any> | null =>
    !isPaidFlow ? (
      <Wrapper>
        <Container>
          <University university={school?.university} />
          <Title>{}</Title>
          <PlanCard
            icon={icon}
            title={cardTitle}
            details={planDetails}
            onEnroll={() => setPaidFlow(true)}
            price={price}
            ctaText='Upgrade'
          />
        </Container>
      </Wrapper>
    ) : null;

  const renderPaymentScreen = (): React.ReactElement<any> | null =>
    isPaidFlow ? (
      <Payment onNextStep={handleFinish} courseSlug={slug} ctaText={`Pay $${price}`} />
    ) : null;

  return (
    <NooderaModal title='Enroll in Course' isOpen={isOpen} onClose={handleModalClose}>
      {showHeader()}
      {showPlan()}
      {renderPaymentScreen()}
      <NooderaModal.Small
        isOpen={isSuccessModalOpen}
        icon={<SuccessIcon />}
        title='Thank You!'
        description='Order complete and your payment has been processed.'
        btnText='Ok'
        onClose={handleModalClose}
      />
    </NooderaModal>
  );
};

export default BecomePremiumModal;
