import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { getIsCorporateInstance } from 'utils/privateLabel/index';
import Values from 'values.js';
import noodleTheme from 'settings/theme/';
import { ConfigProvider } from 'antd';

const generatePalette = (colorHex: string) => {
  const color = new Values(colorHex);
  return {
    ten: color.tint(90).hexString(),
    twenty: color.tint(80).hexString(),
    forty: color.tint(60).hexString(),
    sixty: color.tint(40).hexString(),
    eighty: color.tint(20).hexString(),
    hundred: colorHex,
    hundredTwenty: color.shade(20).hexString(),
  };
};

export const setTheme = (primaryColor: string, secondaryColor: string, partnerId: string) => {
  /*
    Function implements custom themeing for Private Label. At
    this time, only Corporate Instances support themeing.
    Theme is adjusted per the 'primaryColor' and 'secondaryColor'
    set by Uni admins on corporate instances.

    Updates theme only if 'primaryColor' is set. The 'secondaryColor'
    is not required, but it is used, if present. Defaults to the noodleTheme.
  */
  const theme = { ...noodleTheme };

  if (getIsCorporateInstance(partnerId)) {
    if (!primaryColor && !secondaryColor) {
      return { ...noodleTheme };
    }

    if (primaryColor) {
      theme.palette.primary = generatePalette(primaryColor);
      theme.palette.noodle.primary = primaryColor;
    }

    if (secondaryColor) {
      theme.palette.secondary = generatePalette(secondaryColor);
      theme.palette.noodle.secondary = secondaryColor;
    }

    theme.gradients.noodera = `linear-gradient(90deg, ${theme.palette.noodle.primary} 0%, ${theme.palette.noodle.primary} 100%)`;

    theme.underlineLink = `
      background-image: linear-gradient(120deg, ${theme.palette.primary.forty} 0%, ${theme.palette.primary.forty} 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.2em;
      background-position: 0 109%;
      transition: background-size 0.1s ease-in;
      overflow: auto;
      color: ${theme.palette.noodle.primary};
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;

      &:hover, &:focus {
        background-size: 100% 109%;
        background-image: linear-gradient(120deg, ${theme.palette.primary.twenty} 0%, ${theme.palette.primary.twenty} 100%);
        color: ${theme.palette.noodle.secondary};
      }

      &:active {
        color: ${theme.palette.primary.eighty};
        background-image: none;
      }
    `;

    theme.underlineNeutralLink = `
      background-image: linear-gradient(120deg, ${theme.palette.primary.forty} 0%, ${theme.palette.primary.forty} 100%);
      background-repeat: no-repeat;
      background-size: 100% 0.2em;
      background-position: 0 109%;
      transition: background-size 0.1s ease-in;
      overflow: auto;
      color: #232C3B;
      text-decoration: none;

      &:hover, &:focus {
        background-size: 100% 109%;
        background-image: linear-gradient(120deg, ${theme.palette.primary.twenty} 0%, ${theme.palette.primary.twenty} 100%);
        color: ${theme.palette.noodle.secondary};
      }

      &:active {
        color: ${theme.palette.primary.eighty};
        background-image: linear-gradient(120deg, ${theme.palette.primary.forty} 0%, ${theme.palette.primary.forty} 100%);
      }
    `;
    // This bool determines whether an instance has custom colors set,
    // and is used on components, like the NooderButton.CTA, to determine
    // whether gradients should be used.
    theme.isCustomTheme = true;
  }

  return { ...theme };
};

const Theme = ({ children }) => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  if (!globalPartner?.partnerId) {
    return null;
  }

  const theme = setTheme(
    globalPartner?.primaryColor,
    globalPartner?.secondaryColor,
    globalPartner?.partnerId,
  );
  const antdTheme = {
    token: {
      colorPrimary: globalPartner?.primaryColor,
      secondaryColor: globalPartner?.secondaryColor,
      fontFamily: 'Inter, sans-serif',
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antdTheme}>{children}</ConfigProvider>
    </ThemeProvider>
  );
};

export default Theme;
