// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme/index';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const {
  palette: { neutral, primary },
  noodera: { fontTypes, spacing },
} = getTheme();
export const Item: StatelessFunctionalComponent<any> = styled.span`
  ${fontTypes.font4}
  color: ${neutral.hundred};
  font-size: 16px;
  line-height: ${spacing.x4};
`;
export const CheckmarkIcon: StatelessFunctionalComponent<any> = styled(CheckOutlinedIcon)`
  &.MuiSvgIcon-root {
    font-size: ${spacing.x4};
    color: ${primary.hundred};
  }
`;
export const Wrapper: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${spacing.x5};
`;
export const IconContainer: StatelessFunctionalComponent<any> = styled.div`
  display: inline-flex;
  width: 30px;
  align-items: center;
  &&&& {
    svg {
      font-size: ${spacing.x4};
      color: ${({ theme }) => theme.palette.primary.hundred};
    }
  }
`;
export const ItemContainer: StatelessFunctionalComponent<any> = styled.div`
  display: inline-flex;
  width: calc(100% - 30px);
  align-items: center;
  min-height: ${spacing.x4};
`;
