import type { HasMinValueParams, ValidationFieldError } from './Validation.types';

/**
 * @description
 * Validates if a value is greater or equal to a given value
 */
const hasMinValue = ({
  name,
  value,
  outputName = 'This field',
  minValue,
}: HasMinValueParams): ValidationFieldError => {
  const errors = {};
  const numberValue = Number(value);
  errors[name] =
    numberValue < minValue
      ? {
          errorMsg: `${outputName} must be greater or equal to ${minValue}`,
        }
      : {};
  return errors;
};

export default hasMinValue;
