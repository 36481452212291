// @ts-nocheck
import * as React from 'react';
import { accessibilityConstants } from 'commons/constants/general';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron_left.svg';
import { BackButton, Container, NavigationHeading } from './NotificationHeader.style';

const NotificationHeader = (): React.ReactElement<any> => {
  const history = useHistory();

  const handleBack = (): void => {
    if (history.location.state?.prevPath && history.location.state?.prevPath !== '/notifications') {
      history.push(history.location.state?.prevPath);
    } else {
      history.push('/');
    }
  };

  return (
    <Container>
      <BackButton
        type='button'
        onClick={handleBack}
        title={accessibilityConstants.notifications.title}
        ariaLabel={accessibilityConstants.notifications.ariaLabel}
      >
        <ChevronLeftIcon />
      </BackButton>
      <NavigationHeading>Notifications</NavigationHeading>
    </Container>
  );
};

export default NotificationHeader;
