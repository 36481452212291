// @ts-nocheck
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'lib/Api';
import alertActions from 'ducks/alert/actions';
import type { Saga } from 'redux-saga';
import { alertTypesConstants } from 'commons/constants';
import {
  addPartnerProgramTypeTypes,
  deletePartnerProgramTypeTypes,
  getPartnerProgramListTypes,
  getPartnerProgramTypeListTypes,
  getPartnerProgramTypes,
} from './types';
import { adminPartnerProgramActions } from './actions';

const { DANGER, SUCCESS } = alertTypesConstants;
export function* getPartnerProgramTypeListSaga(): Saga<void> {
  try {
    const response = yield call([api.adminPartnerProgram, 'getPartnerProgramTypes']);
    yield put(adminPartnerProgramActions.getPartnerProgramTypeListSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the partner program list!',
        type: DANGER,
      }),
    );
    yield put(adminPartnerProgramActions.getPartnerProgramTypeListFailed(error));
  }
}
export function* getPartnerProgramListSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminPartnerProgram, 'getPartnerPrograms'], payload);
    yield put(adminPartnerProgramActions.getPartnerProgramListSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the partner program list!',
        type: DANGER,
      }),
    );
    yield put(adminPartnerProgramActions.getPartnerProgramListFailed(error));
  }
}
export function* getPartnerProgramSaga({ payload }: { payload: string }): Saga<void> {
  try {
    const response = yield call([api.adminPartnerProgram, 'getPartnerProgram'], payload);
    yield put(adminPartnerProgramActions.getPartnerProgramSuccess(response));
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error fetching the partner program!',
        type: DANGER,
      }),
    );
    yield put(adminPartnerProgramActions.getPartnerProgramFailed(error));
  }
}
export function* deletePartnerProgramTypeSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const response = yield call([api.adminPartnerProgram, 'deletePartnerProgramType'], payload);
    yield put(adminPartnerProgramActions.deletePartnerProgramTypeSuccess(response));
    yield put(push(`/admin/partner-programs/${payload.partnerProgramId}`));
    yield put(
      alertActions.setAlert({
        message: 'Delete operation has been successful!',
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error deleting this partner program type!',
        type: DANGER,
      }),
    );
    yield put(adminPartnerProgramActions.deletePartnerProgramTypeFailed(error));
  }
}
export function* addPartnerProgramTypeSaga({
  payload,
}: {
  payload: Record<string, any>;
}): Saga<void> {
  try {
    const response = yield call([api.adminPartnerProgram, 'addPartnerProgramType'], payload);
    yield put(adminPartnerProgramActions.addPartnerProgramTypeSuccess(response));
    yield put(push(`/admin/partner-programs/${payload.partnerProgramId}`));
    yield put(
      alertActions.setAlert({
        message: 'Add operation has been successful!',
        type: SUCCESS,
      }),
    );
  } catch (error) {
    yield put(
      alertActions.setAlert({
        message: 'There was an error adding the program type!',
        type: DANGER,
      }),
    );
    yield put(adminPartnerProgramActions.addPartnerProgramTypeFailed(error));
  }
}
export default function* adminPartnerProgramSaga(): Saga<void> {
  yield takeLatest(getPartnerProgramTypeListTypes.REQUEST, getPartnerProgramTypeListSaga);
  yield takeLatest(getPartnerProgramListTypes.REQUEST, getPartnerProgramListSaga);
  yield takeLatest(getPartnerProgramTypes.REQUEST, getPartnerProgramSaga);
  yield takeLatest(deletePartnerProgramTypeTypes.REQUEST, deletePartnerProgramTypeSaga);
  yield takeLatest(addPartnerProgramTypeTypes.REQUEST, addPartnerProgramTypeSaga);
}
