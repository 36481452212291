import alertTypes from './types';

const alertActions = {
  setAlert: (payload: { message: string; type: string }) => ({
    type: alertTypes.SET_ALERT,
    payload,
  }),
  removeAlert: () => ({
    type: alertTypes.REMOVE_ALERT,
  }),
};
export default alertActions;
