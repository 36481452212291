export const formConstants = {
  SAVE_CHANGES: 'Save changes',
  SAVING: 'Saving...',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
};
export const formInputTypes = {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  LINK: 'LINK',
  SELECT: 'SELECT',
  TEXTAREA: 'TEXTAREA',
};
export const formInputFormats = {
  MONEY: 'MONEY',
  PERCENTAGE: 'PERCENTAGE',
  DEFAULT: 'DEFAULT',
};
export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];
