// @ts-nocheck
import { createSelector } from 'reselect';
import { formattedFirstName } from 'utils/user/name';
import { UserConnectionStatusEnum } from 'commons/types';
import { defaultUserProfile } from 'commons/constants';
import type { UserProfile } from 'commons/types';

export const getSuccessConnectionMessage = (profile?: UserProfile) =>
  profile ? `Your request was sent to ${formattedFirstName(profile)}.` : 'Your request was sent';
// In the store we have an Array of Connections,
// and the User for which we are looking at the profile
// Here we are getting only the connections that involve this profile User
export const getOtherUserRelatedConnection = createSelector(
  (state) => state.userConnection.connections,
  (state) => state.user.otherUser,
  (connections, otherUser) => {
    const { id: otherUserId } = otherUser;

    if (connections && connections.length && otherUserId) {
      return connections.find((conn) => {
        if (conn.targetUser) {
          return (
            conn.targetUser.id.toString() === otherUserId.toString() ||
            conn.sourceUser.id.toString() === otherUserId.toString()
          );
        }

        return false;
      });
    }

    return null;
  },
);
export const getPendingConnections = createSelector(
  (state) => state.user,
  (state) => state.userConnection.connections,
  (user, connections) =>
    connections && connections.length > 0 && user && user.id
      ? connections.filter((conn) => {
          if (conn.targetUser) {
            return (
              conn.targetUser.id.toString() === user.id.toString() &&
              conn.status === UserConnectionStatusEnum.requested
            );
          }

          return false;
        })
      : [],
);
export const getMessageConnections = createSelector(
  (state) => getPendingConnections(state),
  (pendingConnections) =>
    pendingConnections.map((connection) => {
      const { sourceUser } = connection;

      if (!sourceUser.profile) {
        sourceUser.profile = defaultUserProfile;
      }

      const messageItem = {
        user: connection.sourceUser,
        date: connection.updatedAt,
        connection,
        description: 'wants to connect',
      };
      return messageItem;
    }),
);
