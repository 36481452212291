// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';
import { getTheme } from '../../settings/theme';

const {
  noodera: { spacing, fontTypes },
  palette: { neutral, greyscale },
} = getTheme();
export const Container: StatelessFunctionalComponent<any> = styled.div`
  &&& {
    margin-left: 0;
    width: 100%;
  }
`;
export const BackButton: StatelessFunctionalComponent<any> = styled.button`
  margin-right: ${spacing.x5};
  background: none;
  border: none;
  padding: 0;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    svg {
      path {
        fill: ${greyscale.white};
      }
    }
  }
`;
export const NavigationHeading: StatelessFunctionalComponent<any> = styled.h1`
  ${fontTypes.font3Medium};
  color: ${neutral.hundred};

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    position: absolute;
    top: 11px;
    margin: 0;
    left: calc(50% - 53px);
    ${fontTypes.font4Medium};
    color: ${greyscale.white};
  }
`;
export const ContainerIcon: StatelessFunctionalComponent<any> = styled.div`
  display: flex;
  margin-right: ${spacing.x8};

  & > span {
    display: flex;
  }
`;
