import JsonApi from 'utils/jsonApi';
import get from 'lodash.get';

export default class Counselor {
  jsonApi: JsonApi;

  constructor(jsonApi: JsonApi) {
    this.jsonApi = jsonApi;
  }

  async getCounselor(payload: string): Promise<any> {
    try {
      const response = await this.jsonApi.get(`/counselors/${payload}`);
      const counselorObj = get(response, 'entities.counselor') || {};
      const counselorData = Object.values(counselorObj)[0] || {};
      return counselorData;
    } catch (err) {
      throw new Error(err);
    }
  }
}
