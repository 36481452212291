const THERE_WAS_AN_ERROR = 'There was an error';
export const cardTypesConstants = {
  GROUP: 'GROUP',
  POST_SEARCH: 'POST_SEARCH',
  POST_GROUP: 'POST_GROUP',
  SCHOOL: 'SCHOOL',
  NOODLING: 'NOODLING',
  INSTITUTION: 'INSTITUTION',
};
export const feedConstants = {
  BOTTOM_OFFSET_HEIGHT: 2000,
  ELEMENTS_PER_PAGE: 10,
};
export const NOODLING_BOOKMARK = 'NOODLING_BOOKMARK';
export const ALL_BOOKMARKS = 'all_bookmarks';
export const bookmarkFilters = [
  {
    value: ALL_BOOKMARKS,
    label: 'All',
  },
  {
    value: cardTypesConstants.GROUP,
    label: 'Groups',
  },
  {
    value: cardTypesConstants.SCHOOL,
    label: 'Schools',
  },
  {
    value: cardTypesConstants.NOODLING,
    label: 'Noodlings',
  },
];

export const bookmarkConstants = {
  saveArticleSuccessMessage: 'Article Saved',
  saveArticleErrorMessage: `${THERE_WAS_AN_ERROR} saving a course`,
  deleteArticleSuccessMessage: 'Article Unsaved',
  deleteArticleErrorMessage: `You can not unsave an article a teammate has saved`,
};
