import styled from '@emotion/styled';
import { getTheme } from 'settings/theme/index';

const {
  noodera: { fontTypes },
  palette: { primary },
  underlineLink,
} = getTheme();

export const TaSessionsIcon = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

export const TaSessionsTitle = styled.span`
  ${fontTypes.font4Medium}
  font-weight: 600;
  color: black;
`;

export const TaSessionsLink = styled.span`
  ${fontTypes.font5Medium}
  ${underlineLink}
  width: max-content;
  font-weight: 700;
  color: ${primary.hundred};
`;
