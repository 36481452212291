export const groupsCurrentPageTypes = {
  SET_CURRENT_PAGE: 'GROUPS_SET_CURRENT_PAGE',
};
export const getGroupByIdTypes = {
  REQUEST: 'GROUP_GET_BY_ID_REQUEST',
  SUCCESS: 'GROUP_GET_BY_ID_SUCCESS',
  FAILED: 'GROUP_GET_BY_ID_FAILED',
};
export const getGroupsTypes = {
  REQUEST: 'GROUP_GET_LIST_REQUEST',
  SUCCESS: 'GROUP_GET_LIST_SUCCESS',
  FAILED: 'GROUP_GET_LIST_FAILED',
};
export const joinGroupTypes = {
  REQUEST: 'GROUP_JOIN_REQUEST',
  SUCCESS: 'GROUP_JOIN_SUCCESS',
  FAILED: 'GROUP_JOIN_FAILED',
};
export const leaveGroupTypes = {
  REQUEST: 'GROUP_LEAVE_REQUEST',
  SUCCESS: 'GROUP_LEAVE_SUCCESS',
  FAILED: 'GROUP_LEAVE_FAILED',
};
export const postGroupTypes = {
  REQUEST: 'POST_GROUPS_REQUEST',
  SUCCESS: 'POST_GROUPS_SUCCESS',
  FAILED: 'POST_GROUPS_FAILED',
};
export const getGroupPostsTypes = {
  REQUEST: 'GROUP_GET_POSTS_REQUEST',
  SUCCESS: 'GROUP_GET_POSTS_SUCCESS',
  FAILED: 'GROUP_GET_POSTS_FAILED',
};
